import { connect } from 'react-redux'

import { featureFlagActions } from '../featureFlagActions'
import EditFeatureFlag from '../components/editFeatureFlag'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      nextHandler: (e, featureFlagDetails) => {
        e.preventDefault()
        dispatch(featureFlagActions.updateFeatureFlag(featureFlagDetails))
      },
      backHandler: e => {
        if (e) {
          e.preventDefault()
        }
        dispatch(featureFlagActions.navigateToManageFeatureFlags())
      }
    }
  )
}

const mapStateToProps = state => {
  return {
    validationErrors: state.featureFlags.validationErrors,
    error: state.featureFlags.error,
    featureFlag: state.featureFlags.clickedFeatureFlag,
    roles: state.common.roles,
    profile: state.common.profile,
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFeatureFlag)
