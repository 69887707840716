import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import AppError from '../../common/components/appError'
import FormField from '../../common/form/formField'

const initialState = {
  selectedFile: null,
  uploading: false,
  errors: []
}

class UploadLocationsModal extends Component {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
  }

  selectFile(file) {
    this.setState({ selectedFile: file })
  }

  close() {
    this.setState(initialState)
    this.props.onClose()
  }

  save() {
    this.setState({ uploading: true })
    this.props.onSave(this.state.selectedFile).then(() => {
      this.props.onClose()
      this.setState(initialState)
    }).catch((err) => {
      this.setState({ uploading: false, errors: err.errors })
    })
  }

  render() {
    const disabled = !this.state.selectedFile
    const errors = this.state.errors || []

    return (
      <Modal show={this.props.visible} onHide={() => this.close()}>
        <Modal.Header>
          <h4>Upload Locations</h4>
        </Modal.Header>
        <Modal.Body>
          <FormField label='Locations File' id='codes' mandatory
                     type='file'
                     onChange={(e) => this.selectFile(e.target.files[0])}
                     uploading={this.state.uploading}
                     help='Upload a .csv file'
                     showFileName={true}
          />
          {errors.map((error, index) => (
            <AppError key={`err-${index}`} errorMessage={error} />
          ))}
          <p>
            Download an <a className='link' href='/files/locations-example.csv' target='_blank'>Example file</a>.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button id='cancel' onClick={() => this.close()}>Cancel</Button>
          <Button id='save' bsStyle='primary'
                  onClick={() => this.save()}
                  disabled={disabled}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default UploadLocationsModal
