import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import ThankYouForm from '../components/ThankYouForm'
import { thankYouActions } from '../thankYouActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      load: () => {
        const { id, auditId } = ownProps.match.params
        if (id) {
          dispatch(thankYouActions.fetchThankYou(id))
          dispatch(thankYouActions.fetchContents(id))
          dispatch(thankYouActions.fetchManifest(id))
        } else {
          dispatch(thankYouActions.newThankYou())
        }
        if (auditId) {
          dispatch(auditActions.fetchAudit(auditId))
        }
      },
      save: (thankYou) => {
        return dispatch(thankYouActions.saveThankYou(thankYou))
      },
      updateContents: (contents) => {
        return dispatch(thankYouActions.updateContents(ownProps.match.params.id, contents))
      },
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/thankyous/${id}/audits/${audit.id}`))
      },
      submit: (thankYou) => {
        dispatch(thankYouActions.submitThankYou(thankYou))
      },
      reject: (thankYou) => {
        dispatch(thankYouActions.rejectThankYou(thankYou))
      },
      publish: (thankYou) => {
        dispatch(thankYouActions.publishThankYou(thankYou))
      },
      pause: (thankYou) => {
        dispatch(thankYouActions.pauseThankYou(thankYou))
      },
      resume: (thankYou) => {
        dispatch(thankYouActions.resumeThankYou(thankYou))
      },
      edit: (thankYou) => {
        dispatch(thankYouActions.editThankYou(thankYou))
      },
      uploadMsisdns: (file) => {
        const id = ownProps.match.params.id
        return dispatch(thankYouActions.uploadMsisdns(id, file))
      },
      searchMsisdn: (msisdn) => {
        const id = ownProps.match.params.id
        return dispatch(thankYouActions.searchMsisdn(id, msisdn))
      },
      listS3Files: () => {
        return dispatch(thankYouActions.listS3Files())
      },
      onSubmitFileForProcessing: (fileName) => {
        const id = ownProps.match.params.id
        return dispatch(thankYouActions.onSubmitFileForProcessing(id, fileName))
      }
    }
  )
}

const mapStateToProps = (state) => {
  return {
    thankYou: state.thankYous.thankYou,
    contentList: state.thankYous.contentList,
    manifest: state.thankYous.manifest,
    status: state.thankYous.status,
    flash: state.common.flash,
    audits: state.audits,
    roles: state.common.roles,
    searchResult: state.thankYous.searchResult,
    s3Files: state.thankYous.listS3Files,
    onSubmitFileForProcessing: state.thankYous.onSubmitFileForProcessing
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouForm)
