import React, { Component } from 'react'
import ValidationIndicator from '../../common/components/validationIndicator'
import DataTable from '../../common/components/dataTable'
import Constants from '../../appConstants'
import { formatDateTime } from '../../common/utils'
import _ from 'lodash'

export default class TriggersTable extends Component {
  _handleDeleteClick(index) {
    this.props.onConfirmationDelete(index)
  }

  _handleEditClick(index) {
    this.props.onEditTrigger(index)
  }

  isValidTrigger(index) {
    if (this.props.validationErrors && this.props.validationErrors.schedule) {
      return this.props.validationErrors.schedule[`triggers[${index}]`] ? 'INVALID' : 'VALID'
    } else {
      return 'VALID'
    }
  }

  render() {
    const triggers = this.props.triggers || {}
    const formDisabled = this.props.formDisabled

    var headers = {
      valid: { name: 'Valid' },
      date: { name: 'Date' },
      rank: { name: 'Rank' },
      status: { name: 'Status' },
      message: { name: 'Message' },
      featured: { name: 'Featured' },
      actions: { name: 'Actions' }
    }
    let table = <div id='no-data'>No Triggers to display</div>
    if (triggers && triggers.length > 0) {
      const tableRows = []
      triggers.forEach((trigger, index) => {
        tableRows.push({
          valid: (<ValidationIndicator status={this.isValidTrigger(index)} />),
          date: formatDateTime(trigger.dateTime),
          rank: trigger.rank,
          status: _.capitalize(trigger.status),
          message: trigger.message,
          featured: <input type='checkbox' checked={trigger.featured} disabled readOnly />,
          actions: (
            <span>
              <a className={['APPLIED', 'FAILED', 'SKIPPED'].includes(trigger.status) || formDisabled ? 'link disabled' : 'link'} id='delete-link' onClick={(e) => this._handleDeleteClick(index)}
                 style={{ marginRight: '10px' }}>Delete</a>
              <a className={['APPLIED', 'FAILED', 'SKIPPED'].includes(trigger.status) || formDisabled ? 'link disabled' : 'link'} id='edit-link' onClick={() => this._handleEditClick(index)}>Edit</a>
            </span>
          )
        })
      })
      table = <DataTable displayPageNo={Constants.DEFAULT_DISPLAY_PAGE_NO} rowsPerPage={Constants.DEFAULT_ROWS_PER_PAGE} headers={headers} data={tableRows} wrapperClassName='triggers-table' />
    }
    return (table)
  }
}
