import _ from 'lodash'
import React from 'react'
import { audienceFeaturedOptions } from '../enums'

import FormField from '../form/formField'

const CategoryTags = ({
  model,
  updateField,
  formDisabled,
  validationErrors,
  options,
  pageType = 'default'
}) => {
  const categories = _.get(model, 'details.categories') || []
  const leftPosition = (pageType ==='OFFERS' || pageType ==='PRIZE_DRAW' )? '90px': '165px'

  return (
    <div style={{ position: 'relative', bottom: '-25px', left: leftPosition, height: '0' }}>
      <FormField label='' id='featured-tags'
                 value={_.get(model, 'details.audience.featuredOverrides', '')}
                 options={options || audienceFeaturedOptions}
                 type='checkboxradiolist'
                 onChange={(value) => updateField('details.audience.featuredOverrides', value)()}
                 disabled={formDisabled || !categories.includes('FEATURED')}
                 error={_.get(validationErrors, 'details.audience.featuredOverrides')}
      />
    </div>
  )
}

export default CategoryTags
