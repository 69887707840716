import { push } from 'connected-react-router'

import http, { config, MERCHANT_DETAILS_V1, MERCHANT_LIST_V1 } from '../services/http'

import { commonActions } from '../common/commonActions'

const navigateToMerchants = () => {
  return push('/merchants')
}

const navigateToNewMerchant = () => {
  return push('/merchants/new')
}

const navigateToMerchantBusinessDetails = (merchantId) => {
  return push(`/merchants/${merchantId}/details`)
}

const navigateToBrands = (merchantId) => {
  return push(`/merchants/${merchantId}/brands`)
}

const merchantsFetching = () => ({
  type: constants.MERCHANTS_FETCHING
})

const merchantsFetched = (merchants) => ({
  type: constants.MERCHANTS_FETCHED,
  merchants
})

const newMerchant = () => ({
  type: constants.MERCHANT_CREATING
})

const discardMerchantChanges = () => ({
  type: constants.MERCHANT_DISCARD_CHANGES
})

const merchantSaving = () => ({
  type: constants.MERCHANT_SAVING
})

const merchantFetching = () => ({
  type: constants.MERCHANT_FETCHING
})

const merchantFetched = (merchant) => ({
  type: constants.MERCHANT_FETCHED,
  merchant
})

const merchantUpdated = (merchant) => ({
  type: constants.MERCHANT_UPDATED,
  merchant
})

const merchantUpdateError = (validationErrors) => ({
  type: constants.MERCHANT_UPDATE_ERROR,
  validationErrors
})

const merchantError = () => ({
  type: constants.MERCHANT_ERROR
})

const merchantsError = () => ({
  type: constants.MERCHANTS_ERROR
})

const clearMessages = () => ({
  type: constants.MESSAGES_CLEAR,
  validationErrors: {}
})

const fetchMerchants = (navigate = false) => {
  return (dispatch) => {
    dispatch(merchantsFetching())
    return http.get(dispatch, `${config.serverUrl}/merchants`, null, {
      Accept: MERCHANT_LIST_V1
    }).then((resp) => {
      dispatch(merchantsFetched(resp))
      if (navigate) {
        dispatch(navigateToMerchants())
      }
    }).catch((err) => {
      dispatch(merchantsError())
      dispatch(commonActions.displayFlash(`Unable to retrieve the list of merchants: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const fetchMerchant = (merchantId) => {
  return (dispatch) => {
    dispatch(merchantFetching())
    return http.get(dispatch, `${config.serverUrl}/merchants/${merchantId}`, null, {
      Accept: MERCHANT_DETAILS_V1
    }).then((resp) => {
      dispatch(merchantFetched(resp))
    }).catch((err) => {
      dispatch(merchantError())
      dispatch(commonActions.displayFlash(`Unable to retrieve the merchant for id ${merchantId}: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const createMerchant = (businessDetails) => {
  return (dispatch) => {
    dispatch(merchantSaving())
    return http.post(dispatch, `${config.serverUrl}/merchants`, businessDetails, {
      Accept: MERCHANT_DETAILS_V1
    }).then((merchant) => {
      dispatch(merchantUpdated(merchant))
      dispatch(navigateToBrands(merchant.id))
    }).catch(handleCreateErrors(dispatch))
  }
}

const updateMerchantBusinessDetails = (merchantId, businessDetails, navigateAction) => {
  return function(dispatch) {
    dispatch(merchantSaving())
    return http.patch(dispatch, `${config.serverUrl}/merchants/${merchantId}/details`, businessDetails, {
      Accept: MERCHANT_DETAILS_V1
    }).then((merchant) => {
      dispatch(merchantUpdated(merchant))
      dispatch(navigateAction)
    }).catch(handleUpdateErrors(dispatch))
  }
}

const handleUpdateErrors = (dispatch) => {
  return (resp) => {
    if (resp.status === 400) {
      const validationErrors = JSON.parse(resp.body).validationErrors
      dispatch(merchantUpdateError(validationErrors))
    } else {
      dispatch(merchantError())
      dispatch(commonActions.displayFlash(`An error occurred updating the merchant: ${resp.status} (${resp.statusText})`, 'error'))
    }
  }
}

const handleCreateErrors = (dispatch) => {
  return (resp) => {
    if (resp.status === 400) {
      const validationErrors = JSON.parse(resp.body).validationErrors
      dispatch(merchantUpdateError(validationErrors))
    } else {
      dispatch(merchantError())
      dispatch(commonActions.displayFlash(`Unable to create the new merchant: ${resp.status} (${resp.statusText})`, 'error'))
    }
  }
}

const createBrand = (merchantId, brand) => {
  return (dispatch) => {
    return http.post(dispatch, `${config.serverUrl}/merchants/${merchantId}/brands`, brand, {
      Accept: MERCHANT_DETAILS_V1
    }).then((merchant) => {
      dispatch(merchantUpdated(merchant))
    }).catch(handleBrandCreateErrors(dispatch))
  }
}

const handleBrandCreateErrors = (dispatch) => {
  return (resp) => {
    if (resp.status === 400) {
      const validationErrors = JSON.parse(resp.body).validationErrors
      dispatch(merchantUpdateError(validationErrors))
      throw validationErrors
    } else {
      dispatch(merchantUpdateError({
        brandName: resp.body
      }))
      throw resp.body
    }
  }
}

export const constants = {
  MERCHANTS_FETCHING: 'MERCHANTS_FETCHING',
  MERCHANTS_FETCHED: 'MERCHANTS_FETCHED',
  MERCHANT_FETCHING: 'MERCHANT_FETCHING',
  MERCHANT_FETCHED: 'MERCHANT_FETCHED',
  MERCHANT_CREATING: 'MERCHANT_CREATING',
  MERCHANT_DISCARD_CHANGES: 'MERCHANT_DISCARD_CHANGES',
  MERCHANT_SAVING: 'MERCHANT_SAVING',
  MERCHANT_UPDATED: 'MERCHANT_UPDATED',
  MERCHANT_UPDATE_ERROR: 'MERCHANT_UPDATE_ERROR',
  MERCHANT_ERROR: 'MERCHANT_ERROR',
  MERCHANTS_ERROR: 'MERCHANTS_ERROR',
  MESSAGES_CLEAR: 'MESSAGES_CLEAR'
}

export const merchantActions = {
  navigateToMerchants,
  navigateToNewMerchant,
  navigateToMerchantBusinessDetails,
  navigateToBrands,
  fetchMerchants,
  merchantsFetching,
  merchantsFetched,
  fetchMerchant,
  newMerchant,
  discardMerchantChanges,
  createMerchant,
  merchantSaving,
  updateMerchantBusinessDetails,
  merchantUpdated,
  merchantUpdateError,
  createBrand,
  clearMessages
}
