import React from 'react'
import { Col, Panel, Row, Button } from 'react-bootstrap'
import _ from 'lodash'
import { featureFlagTypeOptions } from '../../common/enums'
import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import AppFlash from '../../common/components/appFlash'
import Layout from './featureFlagLayout'
import ContentFormBase from '../../common/components/contentFormBase'
import confirm from '../../common/components/confirm'

export default class featureFlagForm extends ContentFormBase {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.featureFlag || {})
    this.model = this.props.featureFlag
    this.state = {
      disableNextButton: this.props.featureFlag.disableNextButton != null ? this.props.featureFlag.disableNextButton : true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.featureFlag, this.props.featureFlag)) {
      this.model = this.props.featureFlag
      this.forceUpdate()
    }
  }

  update(path, value) {
    return (e) => {
      _.set(this.model, path, e ? (path == 'enabled' ? e.target.checked : e.target.value) : value)
      if(this.model.enabled == null){
        _.set(this.model, 'enabled', this.model.isEnabled)
      }
      this.setState({ dirty: true })
      this.setState({
        disableNextButton: false
      })
    }
  }

  componentDidMount() {
    if (this.props.isEditOperation && !this.props.featureFlag.subType) {
      this.props.backHandler()
    }
  }

  render() {
    const {
      nextHandler,
      backHandler,
      flash
    } = this.props
    const validationErrors = this.props.validationErrors || {}
    const featureFlagDetails = this.props.featureFlag || {}
    const dialogTitle = `Update Feature Flag`
    const dialogBody = `Are you sure you wish to update this featureFlag now?`

    return (
      <Layout>
        <Row>
          <Col sm={12} lg={12}>
            {this.props.error || null}
            {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
            <Panel header="Feature Flag Details" id="featureFlagPanel">
              <Form nextButtonText="Save"
                    nextButtonDisabled={this.props.isEditOperation && (this.state.disableNextButton)}
                    nextHandler={(e) => {
                      this.setState({ disableNextButton: true })
                      confirm(dialogTitle, dialogBody).then(() => {
                            nextHandler(e, this.model)
                          }).catch(() => this.setState({ disableNextButton: false }))
                    }}
                    backHandler={(e) => {
                      backHandler(e)
                    }}
              >
                <FormField label="Enabled?"
                           id="featureFlag-enabled"
                           type="checkbox"
                           value={featureFlagDetails.isEnabled}
                           onChange={this.update('enabled')}
                           error={validationErrors.isEnabled}
                />
                <FormField label="Type"
                           id="featureFlag-type"
                           type="dropdown" options={featureFlagTypeOptions}
                           value={featureFlagDetails.subType}
                           error={validationErrors.subType}
                           disabled={this.props.isEditOperation}
                />
                { featureFlagDetails.subType === "IN_APP_RATING" ? (
                <FormField  label="Platform"
                            id="platform"
                            value={featureFlagDetails.platform}
                            disabled={this.props.isEditOperation}
                            error={validationErrors.platform}
                 />
                ): null }
                { featureFlagDetails.subType === "QUEUE_IT" ? (
                <FormField label="Title"
                           id="title"
                           maxLength='50'
                           value={featureFlagDetails.title}
                           onChange={this.update('title')}
                           error={validationErrors.title}
                           mandatory
                />
                ): null }
                { featureFlagDetails.subType === "QUEUE_IT" ? (
                <FormField label="Subtitle"
                           id="subtitle"
                           maxLength='200'
                           type="textArea"
                           value={featureFlagDetails.subtitle}
                           onChange={this.update('subtitle')}
                           error={validationErrors.subtitle}
                           mandatory
                />
                ): null }
                { featureFlagDetails.subType === "QUEUE_IT" ? (
                <FormField label="View Offer CTA Label"
                           id="viewOfferCTALabel"
                           maxLength='30'
                           value={featureFlagDetails.viewOfferCTALabel}
                           onChange={this.update('viewOfferCTALabel')}
                           error={validationErrors.viewOfferCTALabel}
                           mandatory
                  />
                ): null }
                { featureFlagDetails.subType === "QUEUE_IT" ? (
                <FormField label="Tickets Queue CTA Label"
                           id="ticketsQueueCTALabel"
                           maxLength='30'
                           value={featureFlagDetails.ticketsQueueCTALabel}
                           onChange={this.update('ticketsQueueCTALabel')}
                           error={validationErrors.ticketsQueueCTALabel}
                           mandatory
                  />
                ): null }
              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
