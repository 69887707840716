import React, { Component } from 'react'
import { Button, Panel } from 'react-bootstrap'
import TriggersTable from '../../common/components/triggersTable'
import DeleteOrDiscardModal from '../../common/components/deleteOrDiscardModal'

class SchedulePanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deleteOrDiscardModalVisible: false
    }
    this.onConfirmationDelete = this.onConfirmationDelete.bind(this)
    this.onEditTrigger = this.onEditTrigger.bind(this)
  }

  onConfirmationDelete(index) {
    this.setState({ deleteOrDiscardModalVisible: true })
    this.setState({ indexToBeDeleted: index })
  }

  onDelete(indexToBeDeleted) {
    this.model = this.props.model
    this.model.schedule.triggers.splice(indexToBeDeleted, 1)
    this.props.save(this.model)
  }

  onEditTrigger(index) {
    this.props.navigateToEditTicketsTrigger(this.props.model, index)
  }

  render() {
    const schedule = this.props.model.schedule || {}
    const validationErrors = this.props.validationErrors
    const formDisabled = this.props.formDisabled

    return (
      <div>
        <Panel header='Tickets Trigger'>
          <div className='page-header--tools'>
            <Button disabled={this.props.formDisabled} bsStyle='primary' id='create-trigger' onClick={() => this.props.navigateToNewTicketsTrigger(this.props.model)}>New Trigger</Button>
            <div className='triggers-list'>
              <TriggersTable triggers={schedule.triggers}
                             validationErrors={validationErrors}
                             onConfirmationDelete={this.onConfirmationDelete}
                             onEditTrigger={this.onEditTrigger}
                             formDisabled={formDisabled}
              />
            </div>
          </div>
        </Panel>
        <DeleteOrDiscardModal visible={this.state.deleteOrDiscardModalVisible}
                              message='Do you want to delete this Trigger?'
                              onDelete={() => {
                                this.onDelete(this.state.indexToBeDeleted)
                                this.setState({ deleteOrDiscardModalVisible: false })
                              }}
                              onDiscard={() => {
                                this.setState({ deleteOrDiscardModalVisible: false })
                                this.props.navigateToSchedule(this.props.model.id)
                              }}
        />
      </div>
    )
  }
}

export default SchedulePanel
