import { push } from 'connected-react-router'
import http, { config, CONTENT_LIST_V1, GROUP_DETAILS_V1 } from '../services/http'

const newGroup = () => ({
  type: constants.GROUP_NEW,
  group: {
    type: 'GROUP',
    details: {
      description: '',
      theme: 'NONE'
    }
  }
})

const saveGroup = (group) => {
  if (!group.id) {
    return createGroup(group)
  } else {
    return updateGroup(group)
  }
}

const groupFetching = (groupId) => ({
  type: constants.GROUP_FETCHING,
  groupId
})
const groupFetched = (group) => ({
  type: constants.GROUP_FETCHED,
  group
})
const contentsFetching = (groupId) => ({
  type: constants.GROUP_CONTENTS_FETCHING,
  groupId
})
const contentsFetched = (contentList) => ({
  type: constants.GROUP_CONTENTS_FETCHED,
  contentList
})
const groupSaving = (group) => ({
  type: constants.GROUP_SAVING,
  group
})
const groupSaved = (group) => ({
  type: constants.GROUP_SAVED,
  group
})
const groupError = (error) => ({
  type: constants.GROUP_ERROR,
  error: error
})

const fetchGroup = (groupId) => {
  return (dispatch) => {
    dispatch(groupFetching(groupId))
    return http.get(dispatch, `${config.serverUrl}/groups/${groupId}`, null, {
      Accept: GROUP_DETAILS_V1
    }).then((group) => {
      dispatch(groupFetched(group))
    }).catch((err) => {
      dispatch(groupError(`Unable to retrieve the group with id ${groupId}: ${err.status} (${err.statusText})`))
    })
  }
}

const fetchContents = (groupId) => {
  return (dispatch) => {
    dispatch(contentsFetching(groupId))
    return http.get(dispatch, `${config.serverUrl}/groups/${groupId}/contents`, null, {
      Accept: CONTENT_LIST_V1
    }).then((group) => {
      dispatch(contentsFetched(group))
    }).catch((err) => {
      dispatch(groupError(`Unable to fetch the group content with id ${groupId}: ${err.status} (${err.statusText})`))
    })
  }
}

const createGroup = (group) => {
  return (dispatch) => {
    dispatch(groupSaving(group))
    return http.post(dispatch, `${config.serverUrl}/groups`, group, {
      Accept: GROUP_DETAILS_V1
    }).then((resp) => {
      dispatch(groupSaved(resp))
      return resp
    }).catch((err) => {
      dispatch(groupError(`Unable to create the new group: ${err.status} (${err.statusText})`))
    })
  }
}

const updateGroup = (group) => {
  return (dispatch) => {
    dispatch(groupSaving(group))
    return http.put(dispatch, `${config.serverUrl}/groups/${group.id}`, group, {
      Accept: GROUP_DETAILS_V1
    }).then((resp) => {
      dispatch(groupSaved(resp))
      return resp
    }).catch((err) => {
      dispatch(groupError(`Unable to update the group: ${err.status} (${err.statusText})`))
      throw err
    })
  }
}

const contentsSaving = (contentList) => ({
  type: constants.GROUP_CONTENTS_SAVING,
  contentList
})
const contentsSaved = (group) => ({
  type: constants.GROUP_CONTENTS_SAVED,
  group
})
const updateContents = (groupId, contents) => {
  return (dispatch) => {
    dispatch(contentsSaving(contents))
    return http.put(dispatch, `${config.serverUrl}/groups/${groupId}/contents`, contents, {
      Accept: GROUP_DETAILS_V1
    }).then((resp) => {
      dispatch(contentsSaved(resp))
    }).catch((err) => {
      dispatch(groupError(`Unable to update the group contents: ${err.status} (${err.statusText})`))
      throw err
    })
  }
}

const groupSubmitted = (group) => ({
  type: constants.GROUP_SUBMITTED,
  group: group
})
const groupRejected = (group) => ({
  type: constants.GROUP_REJECTED,
  group: group
})
const groupPublished = (group) => ({
  type: constants.GROUP_PUBLISHED,
  group: group
})
const groupPaused = (group) => ({
  type: constants.GROUP_PAUSED,
  group: group
})
const groupResumed = (group) => ({
  type: constants.GROUP_RESUMED,
  group: group
})
const groupEdited = (group) => ({
  type: constants.GROUP_EDITED,
  group
})

const updateGroupStatus = (group, status, successAction) => {
  return (dispatch) => {
    dispatch(groupSaving(group))
    return http.post(dispatch, `${config.serverUrl}/groups/${group.id}/${status}`, null, {
      Accept: GROUP_DETAILS_V1
    }).then((group) => {
      dispatch(successAction(group))
      if (status === 'pause' || status === 'resume' || status === 'publish') {
        dispatch(fetchContents(group.id))
      }
    }).catch((err) => {
      dispatch(groupError(`Unable to ${status} group: ${err.status} (${err.statusText})`))
    })
  }
}

const navigateToOurPicksScheduler = (groupId, index) => {
  return push(`/groups/${groupId}/ourPicksTrigger/${index}`)
}

const navigateToSchedule = (groupId) => {
  return push(`/groups/${groupId}/schedule`)
}

const navigateToNewAlternateTitle = (groupId, index) => {
  return push(`/groups/${groupId}/alternateGroupTitleForm/${index}`)
}

const navigateToGroupAlternateTitle = (props) => {
  const groupId = props.model.id
  const alternateTitleGroupId = props.model.alternateTitleGroupId || 0
  return push(`/groups/${groupId}/alternateGroupTitleForm/${alternateTitleGroupId}`)
}

const submitGroup = (group) => updateGroupStatus(group, 'submit', groupSubmitted)
const rejectGroup = (group) => updateGroupStatus(group, 'reject', groupRejected)
const publishGroup = (group) => updateGroupStatus(group, 'publish', groupPublished)
const pauseGroup = (group) => updateGroupStatus(group, 'pause', groupPaused)
const resumeGroup = (group) => updateGroupStatus(group, 'resume', groupResumed)
const editGroup = (group) => updateGroupStatus(group, 'draft', groupEdited)

export const constants = {
  GROUP_FETCHING: 'GROUP_FETCHING',
  GROUP_FETCHED: 'GROUP_FETCHED',
  GROUP_CONTENTS_FETCHING: 'GROUP_CONTENTS_FETCHING',
  GROUP_CONTENTS_FETCHED: 'GROUP_CONTENTS_FETCHED',
  GROUP_CONTENTS_SAVING: 'GROUP_CONTENTS_SAVING',
  GROUP_CONTENTS_SAVED: 'GROUP_CONTENTS_SAVED',
  GROUP_SAVING: 'GROUP_SAVING',
  GROUP_SAVED: 'GROUP_SAVED',
  GROUP_NEW: 'GROUP_NEW',
  GROUP_ERROR: 'GROUP_ERROR',
  GROUP_SUBMITTED: 'GROUP_SUBMITTED',
  GROUP_REJECTED: 'GROUP_REJECTED',
  GROUP_PUBLISHED: 'GROUP_PUBLISHED',
  GROUP_PAUSED: 'GROUP_PAUSED',
  GROUP_RESUMED: 'GROUP_RESUMED',
  GROUP_EDITED: 'GROUP_EDITED'
}

export const groupActions = {
  newGroup,
  saveGroup,
  fetchGroup,
  submitGroup,
  rejectGroup,
  publishGroup,
  pauseGroup,
  resumeGroup,
  editGroup,
  fetchContents,
  updateContents,
  updateGroupStatus,
  groupSaving,
  navigateToSchedule,
  navigateToOurPicksScheduler,
  navigateToNewAlternateTitle,
  navigateToGroupAlternateTitle
}
