import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { connect } from 'react-redux'

import Layout from './dashboard/containers/dashboard'
import NotFound from './dashboard/components/notFound'
import Login from './accounts/containers/login'

import ContentListing from './content/containers/contentListing'
import SelectRelatedContent from './content/containers/selectRelatedContent'
import SelectGroupItems from './content/containers/selectGroupItems'
import SelectBannerContent from './content/containers/selectBannerContent'
import SelectSeriesItems from './content/containers/selectSeriesItems'
import SelectCalendarItems from './content/containers/selectCalendarItems'
import SelectLocations from './locations/containers/selectLocations'
import SortItems from './content/containers/sortItems'
import FeaturedContentsTitleForm from './content/containers/featuredContentsTitleForm'
import CopyContent from './copy/containers/copyContent'

import OfferForm from './offers/containers/offerForm'
import PrizeDrawForm from './prizedraws/containers/prizedrawForm'
import BannerForm from './banners/containers/bannerForm'
import MusicBannerForm from './musicBanners/containers/musicBannerForm'
import OurPicksMusicBannerTriggerForm from './musicBanners/containers/ourPicksMusicBannerTriggerForm'
import AlternateCampaignTitleForm from './campaigns/containers/alternateCampaignTitleForm'
import AlternateBannerTitleForm from './banners/containers/alternateBannerTitleForm'
import OurPicksBannerTriggerForm from './banners/containers/ourPicksBannerTriggerForm'
import AlternateOfferTitleForm from './offers/containers/alternateOfferTitleForm'
import OfferOurPicksTriggerForm from './offers/containers/offerOurPicksTriggerForm'
import TourTicketsTriggerForm from './tours/containers/tourTicketsTriggerForm'
import OurPicksPrizeDrawTriggerForm from './prizedraws/containers/ourPicksPrizeDrawTriggerForm'
import OurPicksGroupTriggerForm from './groups/containers/ourPicksGroupTriggerForm'
import AlternateGroupTitleForm from './groups/containers/alternateGroupTitleForm'

import TourForm from './tours/containers/tourForm'
import EventDateForm from './eventdates/containers/eventDateForm'
import GroupForm from './groups/containers/groupForm'
import SeriesForm from './series/containers/seriesForm'
import ThankYouForm from './thankyous/containers/thankYouForm'
import CampaignForm from './campaigns/containers/campaignForm'
import ArticleForm from './articles/containers/articleForm'
import ArticleOurPicksTriggerForm from './articles/containers/articleOurPicksTriggerForm'

import MerchantListing from './merchants/containers/merchantListing'
import NewMerchant from './merchants/containers/newMerchant'
import MerchantDetails from './merchants/containers/merchantDetails'
import MerchantBrandListing from './merchants/containers/brandListing'
import BrandForm from './brands/containers/brandForm'
import LocationForm from './locations/containers/locationForm'

import ManageAccounts from './accounts/containers/manageAccounts'
import ManageMsisdn from './preview/containers/manageMsisdn'
import CreateMsisdn from './preview/containers/createMsisdn'
import EditMsisdn from './preview/containers/editMsisdn'
import CreateAccount from './accounts/containers/create'
import EditAccount from './accounts/containers/edit'
import ChangePassword from './accounts/containers/changePassword'
import ResetPasswordNotification from './accounts/containers/resetPasswordNotification'
import ResetPasswordForm from './accounts/containers/resetPasswordForm'
import FaqSectionListing from './faq/containers/faqSectionListing'
import FaqSectionDetails from './faq/containers/faqSectionDetails'
import FaqDetails from './faq/containers/faqDetails'
import FaqListing from './faq/containers/faqListing'
import ManagePreferenceCategories from './preferences/containers/managePreferenceCategories'
import PreferenceCategoryForm from './preferences/containers/preferenceCategoryForm'

import VenueListing from './venues/containers/venueListing'
import VenueForm from './venues/containers/venueForm'

import AuditListing from './audits/containers/auditListing'

import NotificationProfilesList from './notification/containers/notificationProfilesList'
import NotificationProfileForm from './notification/containers/notificationProfileForm'

import ManageSectionHeaders from './sectionHeaders/containers/manageSectionHeaders'
import EditSectionHeader from './sectionHeaders/containers/edit'
import ManageWelcomePosters from './welcomeposter/containers/manageWelcomePosters'
import EditWelcomePoster from './welcomeposter/containers/editWelcomePoster'
import ManageFeatureFlags from './featureFlags/containers/manageFeatureFlags'
import EditFeatureFlag from './featureFlags/containers/editFeatureFlag'

import { commonActions } from './common/commonActions'

let SecureRoute = ({
  component: Component,
  state,
  dispatch,
  requiredRoles,
  ...rest
}) => (
  <Route {...rest} render={props => {
    const { token } = state.common

    if (!token) {
      dispatch(commonActions.updateRefererRoute(rest.path))
      dispatch(commonActions.logoutUser())
      return null
    }

    const roles = state.common.roles
    if (requiredRoles && !roles.some(role => requiredRoles.includes(role))) {
      return <Redirect to='/' />
    }

    return <Component {...props} />
  }} />
)

SecureRoute = connect(
  state => ({ state }),
  dispatch => ({ dispatch })
)(SecureRoute)

const AdminRoute = (props) => (
  <SecureRoute requiredRoles={['ADMIN']} {...props} />
)

const InternalRoute = (props) => (
  <SecureRoute requiredRoles={['ADMIN', 'INTERNAL']} {...props} />
)

const ExternalRoute = (props) => (
  <SecureRoute requiredRoles={['ADMIN', 'INTERNAL', 'EXTERNAL']} {...props} />
)

export const getRoutes = (store) => {
  return (
    <Layout>
      <Switch>
        <ExternalRoute exact path='/' component={ContentListing} />
        <ExternalRoute exact path='/select/related-content' component={SelectRelatedContent} />
        <AdminRoute exact path='/select/banner-content' component={SelectBannerContent} />
        <InternalRoute exact path='/select/group-items' component={SelectGroupItems} />
        <InternalRoute exact path='/select/series-items' component={SelectSeriesItems} />
        <InternalRoute exact path='/select/calendar-items' component={SelectCalendarItems} />
        <InternalRoute exact path='/select/locations' component={SelectLocations} />
        <InternalRoute exact path='/copy' component={CopyContent} />
        <InternalRoute exact path='/sort/:categoryPath' component={SortItems} />
        <AdminRoute exact path='/contents/featured/title' component={FeaturedContentsTitleForm} />

        <InternalRoute exact path='/merchants' component={MerchantListing} />
        <InternalRoute exact path='/merchants/new' component={NewMerchant} />
        <InternalRoute exact path='/merchants/:merchantId/details' component={MerchantDetails} />
        <InternalRoute exact path='/merchants/:merchantId/brands' component={MerchantBrandListing} />
        <InternalRoute exact path='/merchants/:merchantId/brands/:brandId' component={BrandForm} />
        <InternalRoute exact path='/merchants/:merchantId/brands/:brandId/:section' component={BrandForm} />
        <InternalRoute exact path='/merchants/:merchantId/brands/:brandId/locations/new' component={LocationForm} />
        <InternalRoute exact path='/merchants/:merchantId/brands/:brandId/locations/:locationId' component={LocationForm} />

        <InternalRoute exact path='/offers/new' component={OfferForm} />
        <InternalRoute exact path='/offers/:id' component={OfferForm} />
        <InternalRoute exact path='/offers/:id/details' component={OfferForm} />
        <InternalRoute exact path='/offers/:id/triggers/:index' component={OfferOurPicksTriggerForm} />
        <InternalRoute exact path='/offers/:id/alternateOfferTitleForm/:index' component={AlternateOfferTitleForm} />
        <InternalRoute exact path='/offers/:id/:section' component={OfferForm} />
        <ExternalRoute exact path='/offers/:id/:section/:auditId' component={OfferForm} />

        <InternalRoute exact path='/prizedraws/new' component={PrizeDrawForm} />
        <InternalRoute exact path='/prizedraws/:id' component={PrizeDrawForm} />
        <InternalRoute exact path='/prizedraws/:id/details' component={PrizeDrawForm} />
        <InternalRoute exact path='/prizedraws/:id/ourPicksTrigger/:index' component={OurPicksPrizeDrawTriggerForm} />
        <InternalRoute exact path='/prizedraws/:id/:section' component={PrizeDrawForm} />
        <ExternalRoute exact path='/prizedraws/:id/:section/:auditId' component={PrizeDrawForm} />

        <InternalRoute exact path='/banners/new' component={BannerForm} />
        <InternalRoute exact path='/banners/:id' component={BannerForm} />
        <InternalRoute exact path='/banners/:id/ourPicksTrigger/:index' component={OurPicksBannerTriggerForm} />
        <InternalRoute exact path='/banners/:id/alternateBannerTitleForm/:index' component={AlternateBannerTitleForm} />
        <InternalRoute exact path='/banners/:id/:section' component={BannerForm} />
        <ExternalRoute exact path='/banners/:id/:section/:auditId' component={BannerForm} />

        <InternalRoute exact path='/musicBanners/new' component={MusicBannerForm} />
        <InternalRoute exact path='/musicBanners/:id' component={MusicBannerForm} />
        <InternalRoute exact path='/musicBanners/:id/ourPicksTrigger/:index' component={OurPicksMusicBannerTriggerForm} />
        <InternalRoute exact path='/musicBanners/:id/:section' component={MusicBannerForm} />
        <ExternalRoute exact path='/musicBanners/:id/:section/:auditId' component={MusicBannerForm} />

        <InternalRoute exact path='/groups/new' component={GroupForm} />
        <InternalRoute exact path='/groups/:id' component={GroupForm} />
        <InternalRoute exact path='/groups/:id/ourPicksTrigger/:index' component={OurPicksGroupTriggerForm} />
        <InternalRoute exact path='/groups/:id/alternateGroupTitleForm/:index' component={AlternateGroupTitleForm} />
        <InternalRoute exact path='/groups/:id/:section' component={GroupForm} />
        <ExternalRoute exact path='/groups/:id/:section/:auditId' component={GroupForm} />

        <InternalRoute exact path='/thankyous/new' component={ThankYouForm} />
        <InternalRoute exact path='/thankyous/:id' component={ThankYouForm} />
        <InternalRoute exact path='/thankyous/:id/:section' component={ThankYouForm} />
        <ExternalRoute exact path='/thankyous/:id/:section/:auditId' component={ThankYouForm} />

        <InternalRoute exact path='/campaigns/new' component={CampaignForm} />
        <InternalRoute exact path='/campaigns/:id' component={CampaignForm} />
        <InternalRoute exact path='/campaigns/:id/:section' component={CampaignForm} />
        <InternalRoute exact path='/campaigns/:id/alternateCampaignTitleForm/:index' component={AlternateCampaignTitleForm} />
        <ExternalRoute exact path='/campaigns/:id/:section/:auditId' component={CampaignForm} />

        <InternalRoute exact path='/articles/new' component={ArticleForm} />
        <InternalRoute exact path='/articles/:id' component={ArticleForm} />
        <InternalRoute exact path='/articles/:id/details' component={ArticleForm} />
        <InternalRoute exact path='/articles/:id/triggers/:index' component={ArticleOurPicksTriggerForm} />
        <InternalRoute exact path='/articles/:id/:section' component={ArticleForm} />
        <ExternalRoute exact path='/articles/:id/:section/:auditId' component={ArticleForm} />

        <InternalRoute exact path='/series/new' component={SeriesForm} />
        <InternalRoute exact path='/series/:id' component={SeriesForm} />
        <InternalRoute exact path='/series/:id/:section' component={SeriesForm} />
        <ExternalRoute exact path='/series/:id/:section/:auditId' component={SeriesForm} />

        <AdminRoute exact path='/sectionHeaders' component={ManageSectionHeaders} />
        <AdminRoute exact path='/sectionHeaders/:sectionHeaderType/edit' component={EditSectionHeader} />

        <AdminRoute exact path='/welcomePosters' component={ManageWelcomePosters} />
        <AdminRoute exact path='/welcomePosters/:welcomePosterType/edit' component={EditWelcomePoster} />

        <AdminRoute exact path='/featureFlags' component={ManageFeatureFlags} />
        <AdminRoute exact path='/featureFlags/:featureFlagType/edit' component={EditFeatureFlag} />

        <ExternalRoute exact path='/venues' component={VenueListing} />
        <ExternalRoute exact path='/venues/new' component={VenueForm} />
        <ExternalRoute exact path='/venues/:id/details' component={VenueForm} />

        <AdminRoute exact path='/faq' component={FaqSectionListing} />
        <AdminRoute exact path='/faq/createSection' component={FaqSectionDetails} />
        <AdminRoute exact path='/faq/:faqSectionId/edit' component={FaqSectionDetails} />
        <AdminRoute exact path='/faq/:faqSectionId/faqs' component={FaqListing} />
        <AdminRoute exact path='/faq/:faqSectionId/faq/create' component={FaqDetails} />
        <AdminRoute exact path='/faq/:faqSectionId/faq/:faqId/edit' component={FaqDetails} />

        <ExternalRoute exact path='/tours/new' component={TourForm} />
        <ExternalRoute exact path='/tours/:id' component={TourForm} />
        <ExternalRoute exact path='/tours/:id/details' component={TourForm} />
        <ExternalRoute exact path='/tours/:tourId/eventDates/new' component={EventDateForm} />
        <ExternalRoute exact path='/tours/:id/:section' component={TourForm} />
        <ExternalRoute exact path='/tours/:tourId/eventDates/:id' component={EventDateForm} />
        <ExternalRoute exact path='/tours/:tourId/eventDates/:id/:section' component={EventDateForm} />
        <ExternalRoute exact path='/tours/:tourId/eventDates/:id/:section/:auditId' component={EventDateForm} />
        <InternalRoute exact path='/tours/:id/triggers/:index' component={TourTicketsTriggerForm} />
        <ExternalRoute exact path='/tours/:id/:section/:auditId' component={TourForm} />

        <AdminRoute exact path='/accounts' component={ManageAccounts} />
        <AdminRoute exact path='/accounts/create' component={CreateAccount} />
        <AdminRoute exact path='/accounts/:accountId' component={EditAccount} />
        <AdminRoute exact path='/accounts/:accountId/edit' component={EditAccount} />
        <AdminRoute exact path='/msisdn' component={ManageMsisdn} />
        <AdminRoute exact path='/msisdn/create' component={CreateMsisdn} />
        <AdminRoute exact path='/msisdn/:msisdn/edit' component={EditMsisdn} />
        <AdminRoute exact path='/notifications/profiles' component={NotificationProfilesList} />
        <AdminRoute exact path='/notifications/profiles/create' component={NotificationProfileForm} />
        <AdminRoute exact path='/notifications/profiles/:id' component={NotificationProfileForm} />
        <AdminRoute exact path='/audits' component={AuditListing} />
        <AdminRoute exact path='/audits/:auditId' component={AuditListing} />
        <ExternalRoute exact path='/accounts/:accountId/password' component={ChangePassword} />
        <AdminRoute exact path='/preferenceCategories' component={ManagePreferenceCategories} />
        <AdminRoute exact path='/preferenceCategory/create' component={PreferenceCategoryForm} />
        <AdminRoute exact path='/preferenceCategory/:categoryId/edit' component={PreferenceCategoryForm} />

        <Route exact path='/login' component={Login} />
        <Route exact path='/login/resetPasswordNotification' component={ResetPasswordNotification} />
        <Route exact path='/login/resetPassword/:token' component={ResetPasswordForm} />

        <Route exact insecure component={NotFound} />
      </Switch>
    </Layout>
  )
}
