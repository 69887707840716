import { push } from 'connected-react-router'

import http, { config, CONTENT_LIST_V1, PRIZEDRAW_DETAILS_V1 } from '../services/http'
import Constants from '../appConstants'

const createPrizeDraw = (prizedraw) => {
  return (dispatch) => {
    dispatch(prizedrawSaving(prizedraw))
    return http.post(dispatch, `${config.serverUrl}/prizedraws`, prizedraw, {
      Accept: PRIZEDRAW_DETAILS_V1
    }).then((resp) => {
      dispatch(prizedrawAdded(resp))
      return resp
    }).catch((err) => {
      dispatch(prizedrawError(`Unable to create the new prizedraw: ${err.status} (${err.statusText})`))
    })
  }
}

const updatePrizeDraw = (prizedraw) => {
  return (dispatch) => {
    dispatch(prizedrawSaving(prizedraw))
    return http.put(dispatch, `${config.serverUrl}/prizedraws/${prizedraw.id}`, prizedraw, {
      Accept: PRIZEDRAW_DETAILS_V1
    }).then((resp) => {
      dispatch(prizedrawSaved(resp))
      return resp
    }).catch((err) => {
      dispatch(prizedrawError(`Unable to update the prizedraw: ${err.status} (${err.statusText})`))
    })
  }
}

const navigateToSchedule = (prizedrawId) => {
  return push(`/prizedraws/${prizedrawId}/schedule`)
}

const savePrizeDraw = (prizedraw) => {
  if (!prizedraw.id) {
    return createPrizeDraw(prizedraw)
  } else {
    return updatePrizeDraw(prizedraw)
  }
}

const prizedrawAdded = (prizedraw) => ({
  type: constants.PRIZEDRAW_SAVED,
  prizedraw
})

const newPrizeDraw = () => ({
  type: constants.PRIZEDRAW_NEW,
  prizedraw: {
    type: 'PRIZE_DRAW',
    details: {
      platformFilters: [],
      categories: ['FEATURED', 'OFFERS'],
      channels: ['PRIORITY'],
      audience: {}
    }
  }
})

const fetchPrizeDraw = (prizedrawId) => {
  return (dispatch) => {
    dispatch(prizedrawFetching(prizedrawId))
    return http.get(dispatch, `${config.serverUrl}/prizedraws/${prizedrawId}`, null, {
      Accept: PRIZEDRAW_DETAILS_V1
    }).then((resp) => {
      dispatch(prizedrawFetched(resp))
      if (resp.state === 'PUBLISHED' || resp.state === 'CLOSED' || resp.state === 'LIVE') {
        dispatch(getPrizeDrawState(prizedrawId))
      }
    }).catch((err) => {
      dispatch(prizedrawError(`Unable to retrieve the prizedraw with id ${prizedrawId}: ${err.status} (${err.statusText})`))
    })
  }
}

const relatedContentFetching = () => ({
  type: constants.PRIZEDRAW_RELATED_CONTENT_FETCHING
})
const relatedContentFetched = (relatedContent) => ({
  type: constants.PRIZEDRAW_RELATED_CONTENT_FETCHED,
  relatedContent
})
const fetchRelatedContent = (prizedrawId) => {
  return (dispatch) => {
    dispatch(relatedContentFetching())
    return http.get(dispatch, `${config.serverUrl}/prizedraws/${prizedrawId}/relatedContent`, null, {
      Accept: CONTENT_LIST_V1
    }).then((relatedContent) => {
      dispatch(relatedContentFetched(relatedContent))
    }).catch((err) => {
      dispatch(prizedrawError(`Unable to fetch the prize draw related content: ${err.status} (${err.statusText})`))
    })
  }
}

const prizedrawSaving = (prizedraw) => ({
  type: constants.PRIZEDRAW_SAVING,
  prizedraw
})
const prizedrawSaved = (prizedraw) => ({
  type: constants.PRIZEDRAW_SAVED,
  prizedraw
})
const prizedrawFetching = (prizedrawId) => ({
  type: constants.PRIZEDRAW_FETCHING,
  prizedrawId: prizedrawId
})
const prizedrawFetched = (prizedraw) => ({
  type: constants.PRIZEDRAW_FETCHED,
  prizedraw
})
const prizedrawError = (error) => ({
  type: constants.PRIZEDRAW_ERROR,
  error
})
const prizedrawSubmitted = (prizedraw) => ({
  type: constants.PRIZEDRAW_SUBMITTED,
  prizedraw
})
const prizedrawRejected = (prizedraw) => ({
  type: constants.PRIZEDRAW_REJECTED,
  prizedraw
})
const prizedrawPublished = (prizedraw) => ({
  type: constants.PRIZEDRAW_PUBLISHED,
  prizedraw
})
const prizedrawPaused = (prizedraw) => ({
  type: constants.PRIZEDRAW_PAUSED,
  prizedraw
})
const prizedrawResumed = (prizedraw) => ({
  type: constants.PRIZEDRAW_RESUMED,
  prizedraw
})
const prizedrawEdited = (prizedraw) => ({
  type: constants.PRIZEDRAW_EDITED,
  prizedraw
})

const liveEntrantsCountFetched = (liveEntrantsCount) => ({
  type: constants.PRIZEDRAW_LIVE_ENTRANTS_COUNT_FETCHED,
  liveEntrantsCount
})

const prizeDrawStateReceived = (state) => ({
  type: constants.PRIZEDRAW_STATE_RECEIVED,
  state
})

const updatePrizeDrawStatus = (prizedraw, status, successAction) => {
  return (dispatch) => {
    dispatch(prizedrawSaving(prizedraw))
    return http.post(dispatch, `${config.serverUrl}/prizedraws/${prizedraw.id}/${status}`, null, {
      Accept: PRIZEDRAW_DETAILS_V1
    }).then((resp) => {
      dispatch(successAction(resp))
    }).catch((err) => {
      dispatch(prizedrawError(`Unable to ${status} prizedraw: ${err.status} (${err.statusText})`))
    })
  }
}

const getLiveEntrants = (prizedrawId) => {
  return (dispatch) => {
    dispatch(prizedrawFetching(prizedrawId))
    return http.get(dispatch, `${config.serverUrl}/prizedraws/${prizedrawId}/numberOfEntrants`, null)
      .then((resp) => {
        dispatch(liveEntrantsCountFetched(resp.numberOfEntrants))
      }).catch((err) => {
        dispatch(prizedrawError(`Unable to retrieve the live entrants count for prize draw with id ${prizedrawId}: ${err.status} (${err.statusText})`))
      })
  }
}

const getPrizeDrawState = (prizedrawId) => {
  return (dispatch) => {
    dispatch(prizedrawFetching(prizedrawId))
    return http.get(dispatch, `${config.prizeDrawServerUrl}/prizedraws/${prizedrawId}/state`, null)
      .then((resp) => {
        dispatch(prizeDrawStateReceived(resp.state))
      }).catch((err) => {
        dispatch(prizedrawError(`Unable to retrieve the state for prize draw with id ${prizedrawId}: ${err.status} (${err.statusText})`))
      })
  }
}

const downloadEntrants = (prizeDrawId, fileType) => {
  const url = config.prizeDrawServerUrl + '/prizedraws/' + prizeDrawId + '/download/' + fileType
  const fileName = fileType === 'WINNER_FILE' ? Constants.WINNER_FILE_NAME.replace('&id&', prizeDrawId) : Constants.NON_WINNER_FILE_NAME.replace('&id&', prizeDrawId)
  http.download(url, fileName)
}

const navigateToOurPicksScheduler = (prizedrawId, index) => {
  return push(`/prizedraws/${prizedrawId}/ourPicksTrigger/${index}`)
}

const submitPrizeDraw = (prizedraw) => updatePrizeDrawStatus(prizedraw, 'submit', prizedrawSubmitted)
const rejectPrizeDraw = (prizedraw) => updatePrizeDrawStatus(prizedraw, 'reject', prizedrawRejected)
const publishPrizeDraw = (prizedraw) => updatePrizeDrawStatus(prizedraw, 'publish', prizedrawPublished)
const pausePrizeDraw = (prizedraw) => updatePrizeDrawStatus(prizedraw, 'pause', prizedrawPaused)
const resumePrizeDraw = (prizedraw) => updatePrizeDrawStatus(prizedraw, 'resume', prizedrawResumed)
const editPrizeDraw = (prizedraw) => updatePrizeDrawStatus(prizedraw, 'draft', prizedrawEdited)

export const constants = {
  PRIZEDRAW_ERROR: 'PRIZEDRAW_ERROR',
  PRIZEDRAW_NEW: 'PRIZEDRAW_NEW',
  PRIZEDRAW_FETCHING: 'PRIZEDRAW_FETCHING',
  PRIZEDRAW_FETCHED: 'PRIZEDRAW_FETCHED',
  PRIZEDRAW_SAVING: 'PRIZEDRAW_SAVING',
  PRIZEDRAW_SAVED: 'PRIZEDRAW_SAVED',
  PRIZEDRAW_SUBMITTED: 'PRIZEDRAW_SUBMITTED',
  PRIZEDRAW_REJECTED: 'PRIZEDRAW_REJECTED',
  PRIZEDRAW_PUBLISHED: 'PRIZEDRAW_PUBLISHED',
  PRIZEDRAW_PAUSED: 'PRIZEDRAW_PAUSED',
  PRIZEDRAW_RESUMED: 'PRIZEDRAW_RESUMED',
  PRIZEDRAW_EDITED: 'PRIZEDRAW_EDITED',
  PRIZEDRAW_UPLOAD_IMAGE_ERROR: 'PRIZEDRAW_UPLOAD_IMAGE_ERROR',
  PRIZEDRAW_RELATED_CONTENT_FETCHING: 'PRIZEDRAW_RELATED_CONTENT_FETCHING',
  PRIZEDRAW_RELATED_CONTENT_FETCHED: 'PRIZEDRAW_RELATED_CONTENT_FETCHED',
  PRIZEDRAW_LIVE_ENTRANTS_COUNT_FETCHED: 'PRIZEDRAW_LIVE_ENTRANTS_COUNT_FETCHED',
  PRIZEDRAW_ENTRANTS_DOWNLOADED: 'PRIZEDRAW_ENTRANTS_DOWNLOADED',
  PRIZEDRAW_STATE_RECEIVED: 'PRIZEDRAW_STATE_RECEIVED'
}

export const prizedrawActions = {
  newPrizeDraw,
  savePrizeDraw,
  prizedrawSaving,
  prizedrawSaved,
  fetchPrizeDraw,
  prizedrawFetching,
  prizedrawFetched,
  submitPrizeDraw,
  rejectPrizeDraw,
  publishPrizeDraw,
  pausePrizeDraw,
  resumePrizeDraw,
  editPrizeDraw,
  prizedrawError,
  fetchRelatedContent,
  updatePrizeDrawStatus,
  getLiveEntrants,
  liveEntrantsCountFetched,
  getPrizeDrawState,
  prizeDrawStateReceived,
  downloadEntrants,
  navigateToSchedule,
  navigateToOurPicksScheduler
}
