import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'

import Form from '../../common/form/form'
import MsisdnBreadcrumbs from './msisdnBreadcrumbs'
import FormField from '../../common/form/formField'
import Layout from '../../accounts/components/layout'
import AppError from '../../common/components/appError'
import Spinner from '../../common/components/spinner'

export default class EditMsisdn extends Component {
  constructor(props) {
    super(props)
    props.fetchMsisdnHandler()
  }

  _onChangeName(name) {
    this.model['name'] = name.target.value
  }

  _onChangeCheckboxValue(checkboxValue) {
    this.model['enabled'] = checkboxValue.target.checked
  }

  render() {
    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    this.model = this.props.msisdn

    const validationErrors = this.props.validationErrors || {}
    const error = this.props.error ? <AppError errorMessage={this.props.error} /> : null
    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <MsisdnBreadcrumbs match={this.props.match} />
          </Col>
          <Col sm={8} lg={6}>
            {error}
            <Panel header='Edit MSISDN'>
              <Form nextButtonText='Save'
                    nextHandler={(e) => {
                      this.props.nextHandler(e, this.model)
                    }}
                    backButtonText='Cancel'
                    backHandler={(e) => {
                      this.props.backHandler(e)
                    }}
              >
                <FormField label='MSISDN'
                           id='msisdn'
                           value={this.model.msisdn}
                           disabled
                           readOnly
                           mandatory
                />
                <FormField label='Name'
                           id='name'
                           value={this.model.name}
                           error={validationErrors.name}
                           onChange={(e) => {
                             this._onChangeName(e)
                           }}
                           mandatory
                />
                <FormField label='Enabled'
                           id='enabled'
                           type='checkbox'
                           value={this.model.enabled}
                           error={validationErrors.enabled}
                           onChange={(e) => {
                             this._onChangeCheckboxValue(e)
                           }}
                />
              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
