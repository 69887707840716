import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'

import DataTable from '../../common/components/dataTable'
import Constants from '../../appConstants'
import AppError from '../../common/components/appError'
import Layout from './featureFlagLayout'
import Spinner from '../../common/components/spinner'
import { featureFlagTypeOptions } from '../../common/enums'

export default class ManageFeatureFlags extends Component {
  constructor(props) {
    super(props)
    props.fetchFeatureFlagHandler()
  }

  render() {
    let error = <div />
    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }

    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    const headers = {
      type: { name: 'Type', sortable: true },
      title: { name: 'Title', sortable: true },
      enabled: { name: 'Is Feature Flag Enabled?', sortable: false },
      view: { name: 'Actions', sortable: false }
    }

    let table = <div id="no-data">No Feature Flags to display</div>
    if (this.props.featureFlags && this.props.featureFlags.length > 0) {
      const tableRows = this.props.featureFlags.map(featureFlag => {
        return {
          type: featureFlagTypeOptions.filter(option => option.value === featureFlag.subType).map(option => option.label),
          title: featureFlag.title,
          enabled: <input type='checkbox' checked={featureFlag.isEnabled} disabled readOnly />,
          view: (
            <span>
              <a className="link"
                 onClick={(e) => {
                   this.props.setClickedFeatureFlag(featureFlag)
                   this.props.navigateToEdit(e, featureFlag)
                 }}
                 style={{ marginRight: '10px' }}>
                  Edit
              </a>
            </span>
          )
        }
      })
      table = <DataTable displayPageNo={Constants.DEFAULT_DISPLAY_PAGE_NO}
                         rowsPerPage={Constants.DEFAULT_ROWS_PER_PAGE}
                         headers={headers}
                         data={tableRows}
                         wrapperClassName='featureFlags-table'
      />
    }

    return (
      <Layout>
        <Row>
          <Col sm={12} lg={12}>
            {error}
            <Panel header="Feature Flags">
              <div id="featureFlag-listing-panel">
                {table}
              </div>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
