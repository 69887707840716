import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'
import _ from 'lodash'

import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import Breadcrumbs from './breadcrumbs'
import Password from '../../common/form/password'
import Layout from './layout'

import { roleOptions } from '../../common/enums'
import { formatDateTime } from '../../common/utils'
import Label from '../../common/form/label'

export default class AccountForm extends Component {
  constructor(props) {
    super(props)
    this.model = this.props.account
    this.state = {
      passwordValidationError: this.props.validationErrors.password,
      disableNextButton: true
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.account, this.props.account)) {
      this.model = this.props.account
    }
  }

  update(property) {
    return (e) => {
      this.model[property] = e.target.value
    }
  }

  updateRadio(property) {
    return (val) => {
      this.model[property] = val
      this.forceUpdate()
    }
  }

  updateCheckboxValue(property) {
    return (e) => {
      this.model[property] = e.target.checked
    }
  }

  _onChangePassword(password) {
    this.model['password'] = password.value
    this.setState({
      passwordValidationError: '',
      disableNextButton: !password.isMatching
    })
  }

  render() {
    const {
      roles,
      profile,
      nextHandler,
      backHandler
    } = this.props
    const validationErrors = this.props.validationErrors || {}
    const accountDetails = this.props.account || {}
    const othersAccount = profile.id !== this.props.id

    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <Breadcrumbs roles={roles}
                         profile={profile}
                         match={this.props.match}
            />
          </Col>
          <Col sm={8} lg={6}>
            {this.props.error || null}
            <Panel header='Account Details'>
              <Form nextButtonText='Save'
                    nextButtonDisabled={!this.props.isEditOperation && (this.state.disableNextButton)}
                    nextHandler={(e) => {
                      nextHandler(e, this.model)
                    }}
                    backHandler={(e) => {
                      backHandler(e)
                    }}
              >
                <FormField label='First Name'
                           id='first-name'
                           value={accountDetails.firstName}
                           onChange={this.update('firstName')}
                           error={validationErrors.firstName}
                           mandatory
                />
                <FormField label='Last Name'
                           id='last-name'
                           value={accountDetails.lastName}
                           onChange={this.update('lastName')}
                           error={validationErrors.lastName}
                           mandatory
                />
                <FormField label='Email'
                           id='email'
                           value={accountDetails.email}
                           onChange={this.update('email')}
                           error={validationErrors.email}
                           mandatory
                />
                {roles.includes('ADMIN') && othersAccount ? (
                  <FormField label='Permissions'
                             id='role'
                             value={accountDetails.role || 'EXTERNAL'}
                             options={roleOptions}
                             onChange={this.updateRadio('role')}
                             type='radiolist'
                  />
                ) : null}
                {!this.props.isEditOperation ? (
                  <Password error={this.state.passwordValidationError}
                            onChange={(password) => {
                              this._onChangePassword(password)
                            }}
                  />
                ) : null}
                <div id='user-enabled-wrapper' style={{ display: roles.includes('ADMIN') && othersAccount ? 'block' : 'none' }}>
                  <FormField label='Enabled'
                             id='enabled'
                             type='checkbox'
                             value={accountDetails.enabled}
                             onChange={this.updateCheckboxValue('enabled')}
                             error={validationErrors.enabled}
                  />
                </div>
                  {this.props.isEditOperation ? (
                     <div>
                        <Label>Created date</Label> <p>{formatDateTime(accountDetails.createdAt)}</p>
                     </div>
                     ) : null}
              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
