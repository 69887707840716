import http, { config, CONTENT_LIST_V1, LIST_S3_FILES_V1, PROCESS_S3_FILE_V1, THANK_YOU_DETAILS_V1, THANK_YOU_MANIFEST_V1, THANK_YOU_PROFILE_ASSOCIATION_DETAIL_V1 } from '../services/http'

import { commonActions } from '../common/commonActions'

const newThankYou = () => ({
  type: constants.THANK_YOU_NEW,
  thankYou: {
    type: 'THANK_YOU',
    details: {
      description: '',
      theme: 'NONE'
    }
  }
})

const saveThankYou = (thankYou) => {
  if (!thankYou.id) {
    return createThankYou(thankYou)
  } else {
    return updateThankYou(thankYou)
  }
}

const thankYouFetching = (thankYouId) => ({
  type: constants.THANK_YOU_FETCHING,
  thankYouId
})
const thankYouFetched = (thankYou) => ({
  type: constants.THANK_YOU_FETCHED,
  thankYou
})
const contentsFetching = (thankYouId) => ({
  type: constants.THANK_YOU_CONTENTS_FETCHING,
  thankYouId
})
const contentsFetched = (contentList) => ({
  type: constants.THANK_YOU_CONTENTS_FETCHED,
  contentList
})
const thankYouSaving = (thankYou) => ({
  type: constants.THANK_YOU_SAVING,
  thankYou
})
const thankYouSaved = (thankYou) => ({
  type: constants.THANK_YOU_SAVED,
  thankYou
})

const fetchThankYou = (thankYouId) => {
  return (dispatch) => {
    dispatch(thankYouFetching(thankYouId))
    return http.get(dispatch, `${config.serverUrl}/thankyous/${thankYouId}`, null, {
      Accept: THANK_YOU_DETAILS_V1
    }).then((thankYou) => {
      dispatch(thankYouFetched(thankYou))
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to retrieve the thank you with id ${thankYouId}: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const fetchContents = (thankYouId) => {
  return (dispatch) => {
    dispatch(contentsFetching(thankYouId))
    return http.get(dispatch, `${config.serverUrl}/thankyous/${thankYouId}/contents`, null, {
      Accept: CONTENT_LIST_V1
    }).then((contents) => {
      dispatch(contentsFetched(contents))
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to fetch the thank you content with id ${thankYouId}: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const createThankYou = (thankYou) => {
  return (dispatch) => {
    dispatch(thankYouSaving(thankYou))
    return http.post(dispatch, `${config.serverUrl}/thankyous`, thankYou, {
      Accept: THANK_YOU_DETAILS_V1
    }).then((resp) => {
      dispatch(thankYouSaved(resp))
      return resp
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to create the new thank you: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const updateThankYou = (thankYou) => {
  return (dispatch) => {
    dispatch(thankYouSaving(thankYou))
    return http.put(dispatch, `${config.serverUrl}/thankyous/${thankYou.id}`, thankYou, {
      Accept: THANK_YOU_DETAILS_V1
    }).then((resp) => {
      dispatch(thankYouSaved(resp))
      return resp
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to update the thank you: ${err.status} (${err.statusText})`, 'error'))
      throw err
    })
  }
}

const contentsSaving = (contentList) => ({
  type: constants.THANK_YOU_CONTENTS_SAVING,
  contentList
})
const contentsSaved = (thankYou) => ({
  type: constants.THANK_YOU_CONTENTS_SAVED,
  thankYou
})
const updateContents = (thankYouId, contents) => {
  return (dispatch) => {
    dispatch(contentsSaving(contents))
    return http.put(dispatch, `${config.serverUrl}/thankyous/${thankYouId}/contents`, contents, {
      Accept: THANK_YOU_DETAILS_V1
    }).then((resp) => {
      dispatch(contentsSaved(resp))
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to update the thank you contents: ${err.status} (${err.statusText})`, 'error'))
      throw err
    })
  }
}

const thankYouSubmitted = (thankYou) => ({
  type: constants.THANK_YOU_SUBMITTED,
  thankYou
})
const thankYouRejected = (thankYou) => ({
  type: constants.THANK_YOU_REJECTED,
  thankYou
})
const thankYouPublished = (thankYou) => ({
  type: constants.THANK_YOU_PUBLISHED,
  thankYou
})
const thankYouPaused = (thankYou) => ({
  type: constants.THANK_YOU_PAUSED,
  thankYou
})
const thankYouResumed = (thankYou) => ({
  type: constants.THANK_YOU_RESUMED,
  thankYou
})
const thankYouEdited = (thankYou) => ({
  type: constants.THANK_YOU_EDITED,
  thankYou
})
const msisdnsUploading = () => ({
  type: constants.THANK_YOU_MSISDNS_UPLOADING
})
const msisdnsUploaded = (manifest) => ({
  type: constants.THANK_YOU_MSISDNS_UPLOADED,
  manifest
})
const searchResult = (status, profileAssociation, style = 'success') => ({
  type: constants.THANK_YOU_MSISDN_ASSOCIATION_SEARCH,
  status,
  profileAssociation,
  style
})

const foundS3Files = (s3Files) => ({
  type: constants.THANK_YOU_LIST_S3_FILES,
  s3Files
})

const updateThankYouStatus = (thankYou, status, successAction) => {
  return (dispatch) => {
    dispatch(thankYouSaving(thankYou))
    return http.post(dispatch, `${config.serverUrl}/thankyous/${thankYou.id}/${status}`, null, {
      Accept: THANK_YOU_DETAILS_V1
    }).then((thankYou) => {
      dispatch(successAction(thankYou))
      if (status === 'pause' || status === 'resume' || status === 'publish') {
        dispatch(fetchContents(thankYou.id))
      }
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to ${status} thank you: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const manifestFetching = () => ({
  type: constants.THANK_YOU_MANIFEST_FETCHING
})

const manifestFetched = (manifest) => ({
  type: constants.THANK_YOU_MANIFEST_FETCHED,
  manifest
})

const fetchManifest = (thankYouId) => {
  return (dispatch) => {
    dispatch(manifestFetching())
    return http.get(dispatch, `${config.thankYouServerUrl}/groups/${thankYouId}`, null, {
      Accept: THANK_YOU_MANIFEST_V1
    }).then((manifest) => {
      dispatch(manifestFetched(manifest))
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to fetch manifest: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const uploadMsisdns = (thankYouId, file) => {
  var formData = new FormData()
  formData.append('file', file)

  return (dispatch) => {
    dispatch(msisdnsUploading())
    return http.post(dispatch, `${config.thankYouServerUrl}/thankyous/${thankYouId}/uploadMsisdns`, formData)
      .then((resp) => {
        dispatch(msisdnsUploaded(resp))
      }).catch((err) => {
        let message
        try {
          message = JSON.parse(err.body).description
        } catch (e) {
          message = `${err.status} (${err.statusText})`
        }

        throw String(`Unable to upload msisdns: ${message}`)
      })
  }
}

const searchMsisdn = (thankYouId, msisdn) => {
  return (dispatch) => {
    return http.get(dispatch, `${config.thankYouServerUrl}/thankyous/${msisdn}/${thankYouId}`, null, {
      Accept: THANK_YOU_PROFILE_ASSOCIATION_DETAIL_V1
    }).then((profileAssociation) => {
      dispatch(searchResult('FOUND', profileAssociation))
    }).catch((err) => {
      if (err.status === 404) {
        dispatch(searchResult('NOT_FOUND', null, 'danger'))
      }
    })
  }
}

const listS3Files = () => {
  return (dispatch) => {
    return http.get(dispatch, `${config.thankYouServerUrl}/thankyous/listS3Files`, null, {
      Accept: LIST_S3_FILES_V1
    }).then((s3Files) => {
      dispatch(foundS3Files(s3Files))
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to retrieve list of s3 files: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const onSubmitFileForProcessing = (thankYouGroupId, fileName) => {
  return (dispatch) => {
    return http.post(dispatch, `${config.thankYouServerUrl}/thankyous/${thankYouGroupId}/processS3File/${fileName}`
      , null, { Accept: PROCESS_S3_FILE_V1 }
    ).then((resp) => {
      dispatch(msisdnsUploaded(resp))
    }).catch((err) => {
      let message
      try {
        message = JSON.parse(err.body).description
      } catch (e) {
        message = `${err.status} (${err.statusText})`
      }

      throw String(`Unable to submit ${fileName} for processing: ${message}`)
    })
  }
}

const submitThankYou = (thankYou) => updateThankYouStatus(thankYou, 'submit', thankYouSubmitted)
const rejectThankYou = (thankYou) => updateThankYouStatus(thankYou, 'reject', thankYouRejected)
const publishThankYou = (thankYou) => updateThankYouStatus(thankYou, 'publish', thankYouPublished)
const pauseThankYou = (thankYou) => updateThankYouStatus(thankYou, 'pause', thankYouPaused)
const resumeThankYou = (thankYou) => updateThankYouStatus(thankYou, 'resume', thankYouResumed)
const editThankYou = (thankYou) => updateThankYouStatus(thankYou, 'draft', thankYouEdited)

export const constants = {
  THANK_YOU_FETCHING: 'THANK_YOU_FETCHING',
  THANK_YOU_FETCHED: 'THANK_YOU_FETCHED',
  THANK_YOU_MANIFEST_FETCHING: 'THANK_YOU_MANIFEST_FETCHING',
  THANK_YOU_MANIFEST_FETCHED: 'THANK_YOU_MANIFEST_FETCHED',
  THANK_YOU_CONTENTS_FETCHING: 'THANK_YOU_CONTENTS_FETCHING',
  THANK_YOU_CONTENTS_FETCHED: 'THANK_YOU_CONTENTS_FETCHED',
  THANK_YOU_CONTENTS_SAVING: 'THANK_YOU_CONTENTS_SAVING',
  THANK_YOU_CONTENTS_SAVED: 'THANK_YOU_CONTENTS_SAVED',
  THANK_YOU_SAVING: 'THANK_YOU_SAVING',
  THANK_YOU_SAVED: 'THANK_YOU_SAVED',
  THANK_YOU_NEW: 'THANK_YOU_NEW',
  THANK_YOU_SUBMITTED: 'THANK_YOU_SUBMITTED',
  THANK_YOU_REJECTED: 'THANK_YOU_REJECTED',
  THANK_YOU_PUBLISHED: 'THANK_YOU_PUBLISHED',
  THANK_YOU_PAUSED: 'THANK_YOU_PAUSED',
  THANK_YOU_RESUMED: 'THANK_YOU_RESUMED',
  THANK_YOU_EDITED: 'THANK_YOU_EDITED',
  THANK_YOU_MSISDNS_UPLOADING: 'THANK_YOU_MSISDNS_UPLOADING',
  THANK_YOU_MSISDNS_UPLOADED: 'THANK_YOU_MSISDNS_UPLOADED',
  THANK_YOU_MSISDN_ASSOCIATION_SEARCH: 'THANK_YOU_MSISDN_ASSOCIATION_SEARCH',
  THANK_YOU_LIST_S3_FILES: 'THANK_YOU_LIST_S3_FILES'
}

export const thankYouActions = {
  newThankYou,
  saveThankYou,
  fetchThankYou,
  fetchManifest,
  submitThankYou,
  rejectThankYou,
  publishThankYou,
  pauseThankYou,
  resumeThankYou,
  editThankYou,
  fetchContents,
  updateContents,
  updateThankYouStatus,
  thankYouSaving,
  uploadMsisdns,
  searchMsisdn,
  listS3Files,
  onSubmitFileForProcessing
}
