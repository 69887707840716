import { constants } from './merchantActions'
import { constants as brandConstants } from '../brands/brandActions'

export default function merchants(state = {}, action = {}) {
  switch (action.type) {
    case constants.MERCHANTS_FETCHING:
      return Object.assign({}, state, {
        merchants: [],
        validationErrors: {},
        merchantsStatus: 'LOADING'
      })
    case constants.MERCHANTS_FETCHED:
      return Object.assign({}, state, {
        merchants: action.merchants,
        validationErrors: {},
        merchantsStatus: 'SUCCESS'
      })
    case constants.MERCHANTS_ERROR: {
      return Object.assign({}, state, {
        merchantsStatus: 'FAILED'
      })
    }

    case constants.MERCHANT_FETCHING: {
      return Object.assign({}, state, {
        merchant: null,
        validationErrors: {},
        merchantStatus: 'LOADING'
      })
    }
    case constants.MERCHANT_FETCHED: {
      return Object.assign({}, state, {
        merchant: action.merchant,
        validationErrors: {},
        merchantStatus: 'SUCCESS'
      })
    }

    case constants.MERCHANT_DISCARD_CHANGES:
      return Object.assign({}, state, {
        validationErrors: {}
      })
    case constants.MERCHANT_CREATING:
      return Object.assign({}, state, {
        merchant: {},
        validationErrors: {},
        merchantStatus: 'SUCCESS'
      })
    case constants.MERCHANT_SAVING:
      return Object.assign({}, state, {
        validationErrors: {},
        merchantStatus: 'LOADING'
      })
    case constants.MERCHANT_UPDATED: {
      return Object.assign({}, state, {
        merchant: action.merchant,
        validationErrors: {},
        merchantStatus: 'SUCCESS'
      })
    }
    case constants.MERCHANT_UPDATE_ERROR: {
      return Object.assign({}, state, {
        validationErrors: action.validationErrors,
        merchantStatus: 'FAILED'
      })
    }
    case constants.MERCHANT_ERROR: {
      return Object.assign({}, state, {
        merchantStatus: 'FAILED'
      })
    }

    case brandConstants.BRAND_SAVED: {
      const merchant = Object.assign({}, state.merchant)
      const brandIndex = (merchant.brands || []).findIndex(b => b.id === action.brand.id)
      if (brandIndex > -1) {
        merchant.brands[brandIndex] = action.brand
      }

      return Object.assign({}, state, { merchant })
    }
    case constants.MESSAGES_CLEAR:
      return Object.assign({}, state, {
        validationErrors: action.validationErrors
      })

    default:
      return state
  }
}
