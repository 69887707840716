import { connect } from 'react-redux'

import CreateMsisdn from '../components/createMsisdn'
import { msisdnActions } from '../msisdnActions'

const mapDispatchToProps = (dispatch) => {
  return {
    nextHandler: (e, msisdnDetails) => {
      e.preventDefault()
      dispatch(msisdnActions.createMsisdn(msisdnDetails))
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(msisdnActions.navigateToManageMsisdn())
    },
    newMsisdn: () => {
      dispatch(msisdnActions.newMsisdn())
    }
  }
}

const mapStateToProps = (state) => {
  return {
    msisdn: state.msisdns.msisdn || {},
    status: state.msisdns.status,
    validationErrors: state.msisdns.validationErrors || {},
    error: state.msisdns.error
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMsisdn)
