import React from 'react'
import { Pagination } from 'react-bootstrap'

const PaginationWrapper = ({
  totalPages,
  page,
  onSelect
}) => (
  <Pagination first='First'
              last='Last'
              maxButtons={5}
              items={totalPages}
              activePage={page}
              onSelect={onSelect}
  />
)
export default PaginationWrapper
