import React, { Component } from 'react'
import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import Layout from './notificationLayout'
import AppError from '../../common/components/appError'
import { Col, Panel, Row } from 'react-bootstrap'
import NotificationBreadcrumbs from './notificationBreadcrumbs'
import _ from 'lodash'
import Spinner from '../../common/components/spinner'

export default class NotificationProfileForm extends Component {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.notificationProfile || { emailEnabled: true, msisdnEnabled: true })
    props.load()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.match.path, this.props.match.path)) {
      this.props.load()
    }
    if (!_.isEqual(prevProps.notificationProfile, this.props.notificationProfile)) {
      this.model = _.cloneDeep(this.props.notificationProfile || { emailEnabled: true, msisdnEnabled: true })
      this.forceUpdate()
    }
  }

  updateField(path, value) {
    return (e) => {
      _.set(this.model, path, e ? e.target.value : value)
      this.setState({ dirty: true })
    }
  }

  _onChangeCheckboxValue(path, checkbox) {
    this.model[path] = checkbox.target.checked
    this.setState({ dirty: true })
  }

  render() {
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }
    const validationErrors = this.props.validationErrors || {}
    const error = this.props.error ? <AppError errorMessage={this.props.error} /> : null
    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <NotificationBreadcrumbs match={this.props.match}
            />
          </Col>
          <Col sm={8} lg={6}>
            {error}
            <Panel header='Create Notification Profile'>
              <Form nextButtonText='Save'
                    nextHandler={(e) => {
                      this.props.nextHandler(e, this.model)
                    }}
                    backButtonText='Back'
                    backHandler={(e) => {
                      this.props.backHandler(e)
                    }}
              >
                <FormField label='Name'
                           id='name'
                           value={this.model.name}
                           maxLength='40'
                           error={validationErrors.name}
                           onChange={this.updateField('name')}
                />
                <FormField label='MSISDN'
                           id='msisdn'
                           type='number'
                           maxLength='12'
                           value={this.model.msisdn}
                           error={validationErrors.msisdn}
                           onChange={this.updateField('msisdn')}
                />
                <FormField label='MSISDN Notification Enabled'
                           id='msisdnEnabled'
                           type='checkbox'
                           value={this.model.msisdnEnabled}
                           error={validationErrors.msisdnEnabled}
                           onChange={(e) => {
                             this._onChangeCheckboxValue('msisdnEnabled', e)
                           }}
                />
                <FormField label='Email'
                           id='email'
                           value={this.model.email}
                           error={validationErrors.email}
                           onChange={this.updateField('email')}
                />
                <FormField label='Email Notification Enabled'
                           id='emailEnabled'
                           type='checkbox'
                           value={this.model.emailEnabled}
                           error={validationErrors.emailEnabled}
                           onChange={(e) => {
                             this._onChangeCheckboxValue('emailEnabled', e)
                           }}
                />

              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
