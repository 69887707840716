import http, { config, CONTENT_LIST_V1, SERIES_DETAILS_V1 } from '../services/http'

const newSeries = () => ({
  type: constants.SERIES_NEW,
  series: {
    type: 'SERIES',
    details: {
      title: ''
    }
  }
})

const saveSeries = (series) => {
  if (!series.id) {
    return createSeries(series)
  } else {
    return updateSeries(series)
  }
}

const seriesFetching = (seriesId) => ({
  type: constants.SERIES_FETCHING,
  seriesId
})
const seriesFetched = (series) => ({
  type: constants.SERIES_FETCHED,
  series
})
const contentsFetching = (seriesId) => ({
  type: constants.SERIES_CONTENTS_FETCHING,
  seriesId
})
const contentsFetched = (contentList) => ({
  type: constants.SERIES_CONTENTS_FETCHED,
  contentList
})
const seriesSaving = (series) => ({
  type: constants.SERIES_SAVING,
  series
})
const seriesSaved = (series) => ({
  type: constants.SERIES_SAVED,
  series
})
const seriesError = (error) => ({
  type: constants.SERIES_ERROR,
  error: error
})

const fetchSeries = (seriesId) => {
  return (dispatch) => {
    dispatch(seriesFetching(seriesId))
    return http.get(dispatch, `${config.serverUrl}/series/${seriesId}`, null, {
      Accept: SERIES_DETAILS_V1
    }).then((series) => {
      dispatch(seriesFetched(series))
    }).catch((err) => {
      dispatch(seriesError(`Unable to retrieve the series with id ${seriesId}: ${err.status} (${err.statusText})`))
    })
  }
}

const fetchContents = (seriesId) => {
  return (dispatch) => {
    dispatch(contentsFetching(seriesId))
    return http.get(dispatch, `${config.serverUrl}/series/${seriesId}/contents`, null, {
      Accept: CONTENT_LIST_V1
    }).then((series) => {
      dispatch(contentsFetched(series))
    }).catch((err) => {
      dispatch(seriesError(`Unable to fetch the series content with id ${seriesId}: ${err.status} (${err.statusText})`))
    })
  }
}

const createSeries = (series) => {
  return (dispatch) => {
    dispatch(seriesSaving(series))
    return http.post(dispatch, `${config.serverUrl}/series`, series, {
      Accept: SERIES_DETAILS_V1
    }).then((resp) => {
      dispatch(seriesSaved(resp))
      return resp
    }).catch((err) => {
      dispatch(seriesError(`Unable to create the new series: ${err.status} (${err.statusText})`))
    })
  }
}

const updateSeries = (series) => {
  return (dispatch) => {
    dispatch(seriesSaving(series))
    return http.put(dispatch, `${config.serverUrl}/series/${series.id}`, series, {
      Accept: SERIES_DETAILS_V1
    }).then((resp) => {
      dispatch(seriesSaved(resp))
      return resp
    }).catch((err) => {
      dispatch(seriesError(`Unable to update the series: ${err.status} (${err.statusText})`))
      throw err
    })
  }
}

const contentsSaving = (contentList) => ({
  type: constants.SERIES_CONTENTS_SAVING,
  contentList
})
const contentsSaved = (series) => ({
  type: constants.SERIES_CONTENTS_SAVED,
  series
})
const updateContents = (seriesId, contents) => {
  return (dispatch) => {
    dispatch(contentsSaving(contents))
    return http.put(dispatch, `${config.serverUrl}/series/${seriesId}/contents`, contents, {
      Accept: SERIES_DETAILS_V1
    }).then((resp) => {
      dispatch(contentsSaved(resp))
    }).catch((err) => {
      dispatch(seriesError(`Unable to update the series contents: ${err.status} (${err.statusText})`))
      throw err
    })
  }
}

const seriesSubmitted = (series) => ({
  type: constants.SERIES_SUBMITTED,
  series: series
})
const seriesRejected = (series) => ({
  type: constants.SERIES_REJECTED,
  series: series
})
const seriesPublished = (series) => ({
  type: constants.SERIES_PUBLISHED,
  series: series
})
const seriesPaused = (series) => ({
  type: constants.SERIES_PAUSED,
  series: series
})
const seriesResumed = (series) => ({
  type: constants.SERIES_RESUMED,
  series: series
})
const seriesEdited = (series) => ({
  type: constants.SERIES_EDITED,
  series
})

const updateSeriesStatus = (series, status, successAction) => {
  return (dispatch) => {
    dispatch(seriesSaving(series))
      return http.post(dispatch, `${config.serverUrl}/series/${series.id}/${status}`, null, {
        Accept: SERIES_DETAILS_V1
      }).then((series) => {
        dispatch(successAction(series))
        if (status === 'publish') {
          dispatch(fetchContents(series.id))
        }
      }).catch((err) => {
        console.log(err)
        dispatch(seriesError(`Unable to ${status} series: ${err.status} (${err.statusText})`))
      })
    }
  }

const submitSeries = (series) => updateSeriesStatus(series, 'submit', seriesSubmitted)
const rejectSeries = (series) => updateSeriesStatus(series, 'reject', seriesRejected)
const publishSeries = (series) => updateSeriesStatus(series, 'publish', seriesPublished)
const pauseSeries = (series) => updateSeriesStatus(series, 'pause', seriesPaused)
const resumeSeries = (series) => updateSeriesStatus(series, 'resume', seriesResumed)
const editSeries = (series) => updateSeriesStatus(series, 'draft', seriesEdited)

export const constants = {
  SERIES_FETCHING: 'SERIES_FETCHING',
  SERIES_FETCHED: 'SERIES_FETCHED',
  SERIES_CONTENTS_FETCHING: 'SERIES_CONTENTS_FETCHING',
  SERIES_CONTENTS_FETCHED: 'SERIES_CONTENTS_FETCHED',
  SERIES_CONTENTS_SAVING: 'SERIES_CONTENTS_SAVING',
  SERIES_CONTENTS_SAVED: 'SERIES_CONTENTS_SAVED',
  SERIES_SAVING: 'SERIES_SAVING',
  SERIES_SAVED: 'SERIES_SAVED',
  SERIES_NEW: 'SERIES_NEW',
  SERIES_ERROR: 'SERIES_ERROR',
  SERIES_SUBMITTED: 'SERIES_SUBMITTED',
  SERIES_REJECTED: 'SERIES_REJECTED',
  SERIES_PUBLISHED: 'SERIES_PUBLISHED',
  SERIES_PAUSED: 'SERIES_PAUSED',
  SERIES_RESUMED: 'SERIES_RESUMED',
  SERIES_EDITED: 'SERIES_EDITED'
}

export const seriesActions = {
  newSeries,
  saveSeries,
  fetchSeries,
  submitSeries,
  rejectSeries,
  publishSeries,
  pauseSeries,
  resumeSeries,
  editSeries,
  fetchContents,
  updateContents,
  updateSeriesStatus,
  seriesSaving
}
