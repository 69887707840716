import _ from 'lodash'

import { constants } from './contentActions'

const initialState = {
  contentList: [],
  nextOffset: 0,
  limit: 30,
  selectedItems: [],
  currentContent: null,
  selectedFilters: {
    // each of these pages has their own stored set of filters
    sort: {
      type: [],
      state: ['LIVE', 'PUBLISHED', 'PENDING_APPROVAL', 'DISABLED'],
      search: ''
    },
    groupItems: {
      type: ['BANNER', 'OFFER', 'EVENT', 'ARTICLE'],
      state: [],
      search: ''
    },
    seriesItems: {
      type: ['ARTICLE'],
      state: [],
      search: ''
    },
    content: {
      type: [],
      state: [],
      search: ''
    },
    relatedContent: {
      type: [],
      state: [],
      search: ''
    },
    bannerContent: {
      type: ['BANNER'],
      state: [],
      search: ''
    },
    calendarItems: {
      type: ['TOUR', 'OFFER', 'PRIZE_DRAW', 'ARTICLE'],
      state: [],
      search: ''
    }
  }
}

export default function contents(state = initialState, action = {}) {
  if (!action.type) {
    return state
  }
  switch (action.type) {
    case constants.CONTENTS_CLEAR:
      return Object.assign({}, state, {
        contentList: [],
        nextOffset: 0
      })
    case constants.CONTENTS_FETCHING:
      return Object.assign({}, state, {
        error: null,
        status: 'LOADING',
        params: action.params
      })
    case constants.CONTENTS_COPYING:
      return Object.assign({}, state, {
        status: 'SAVING'
      })
    case constants.CONTENTS_COPIED:
      return Object.assign({}, state, {
        status: 'SUCCESS'
      })
    case constants.CONTENTS_FETCHED:
      if (!_.isEqual(action.params, state.params)) {
        return state
      }
      return Object.assign({}, state, {
        contentList: state.contentList.concat(action.contentList),
        status: 'SUCCESS',
        params: null,
        error: null,
        nextOffset: action.nextOffset
      })
    case constants.CONTENTS_CATEGORY_FETCHED:
      return Object.assign({}, state, {
        contentList: action.contentList,
        status: 'SUCCESS',
        error: null,
        nextOffset: state.nextOffset
      })
    case constants.CONTENT_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR',
        params: null
      })
    case constants.CATEGORY_SAVING:
      return Object.assign({}, state, {
        status: 'LOADING',
        contentList: []
      })
    case constants.CATEGORY_SAVED:
      return Object.assign({}, state, {
        status: 'SUCCESS',
        error: null,
        contentList: action.contentList
      })
    case constants.CONTENT_SELECT_CURRENT_CONTENT: {
      return Object.assign({}, state, {
        currentContent: action.currentContent,
        selectedItems: action.selectedItems,
        maxSelectable: action.maxSelectable
      })
    }
    case constants.CONTENT_TOGGLE_SELECTED: {
      const contentItem = action.contentItem
      const index = state.selectedItems.findIndex(item => item.id === contentItem.id)
      const selectedItems = state.selectedItems.slice()
      if (index > -1) {
        selectedItems.splice(index, 1)
      } else {
        selectedItems.push({ id: contentItem.id, type: contentItem.type })
      }

      return Object.assign({}, state, { selectedItems })
    }
    case constants.CONTENT_SET_SELECTED:
      return Object.assign({}, state, {
        selectedItems: action.selectedItems.map(contentItem => {
          return { id: contentItem.id, type: contentItem.type }
        })
      })
    case constants.CONTENT_TOGGLE_ALL_SELECTED: {
      let selectedItems = state.selectedItems.slice()
      const items = action.items.map(item => ({ id: item.id, type: item.type }))
      const ids = items.map(item => item.id)

      if (action.selected) {
        selectedItems = _.unionBy(selectedItems, items, 'id')
      } else {
        selectedItems = _.reject(selectedItems, (item) => ids.indexOf(item.id) > -1)
      }

      return Object.assign({}, state, { selectedItems })
    }
    case constants.CONTENT_SET_FILTERS: {
      const selectedFilters = _.cloneDeep(state.selectedFilters)
      selectedFilters[action.view] = action.filters

      return Object.assign({}, state, {
        selectedFilters
      })
    }
    case constants.CONTENT_SET_SEARCH: {
      const selectedFilters = state.selectedFilters
      selectedFilters[action.view].search = action.search

      return Object.assign({}, state, {
        selectedFilters,
        nextOffset: 0
      })
    }
    case constants.CONTENTS_SELECT_PAGE:
      return Object.assign({}, state, {
        pagination: {
          ...state.pagination,
          page: action.page
        }
      })

    case constants.FEATURED_CONTENTS_TITLE_FETCHING:
      return Object.assign({}, state, {
        featuredContentsTitle: {},
        featuredContentsTitleStatus: 'LOADING',
        validationErrors: {}
      })
    case constants.FEATURED_CONTENTS_TITLE_FETCHED:
      return Object.assign({}, state, {
        featuredContentsTitle: action.featuredContentsTitle,
        featuredContentsTitleStatus: 'SUCCESS'
      })
    case constants.FEATURED_CONTENTS_TITLE_SAVING:
      return Object.assign({}, state, {
        featuredContentsTitleStatus: 'LOADING'
      })
    case constants.FEATURED_CONTENTS_TITLE_SAVED:
      return Object.assign({}, state, {
        featuredContentsTitle: action.featuredContentsTitle,
        featuredContentsTitleStatus: 'SUCCESS',
        validationErrors: {}
      })
    case constants.FEATURED_CONTENTS_TITLE_ERRORS:
      return Object.assign({}, state, {
        validationErrors: action.validationErrors,
        featuredContentsTitleStatus: 'ERROR'
      })
    case constants.FEATURED_CONTENTS_TITLE_ERROR:
      return Object.assign({}, state, {
        featuredContentsTitleStatus: 'ERROR'
      })
    default:
      return state
  }
}
