import _ from 'lodash'
import React, { Component } from 'react'
import { Button, ButtonToolbar, Col, Form, Panel, Row } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import AppFlash from '../../common/components/appFlash'
import LocationsTable from '../../common/components/locationsTable'
import Pagination from '../../common/components/pagination'
import Spinner from '../../common/components/spinner'
import FormField from '../../common/form/formField'
import ImageSelector from '../../common/media/ImageSelector'
import Breadcrumbs from './BrandBreadcrumb'
import UploadLocationsModal from './UploadLocationsModal'

export default class BrandForm extends Component {
  constructor(props) {
    super(props)

    this.model = _.cloneDeep(props.brand || {})
    this.state = {
      saveOrDiscardModalVisible: false,
      uploadLocationsModalOpen: false,
      dirty: false,
      uploading: false,
      imageError: null
    }
    props.load()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.brand, this.props.brand)) {
      this.model = _.cloneDeep(this.props.brand || {})
      this.forceUpdate()
    }
  }

  updateField(path, value) {
    return (e) => {
      _.set(this.model, path, e ? e.target.value : value)

      if (!this.state.dirty) {
        this.setState({ dirty: true })
      }
    }
  }

  save() {
    const model = _.omit(this.model, ['validationErrors'])

    this.props.save(model).then(() => {
      this.setState({ dirty: false })
    })
  }

  navigateToSection(tab) {
    if (tab === 'back') {
      this.props.navigateToMerchant()
    } else {
      this.props.navigateToSection(tab)
    }
  }

  render() {
    const { merchantId, brandId } = this.props.match.params
    const activeTab = this.props.match.params.section || 'locations'
    const brand = this.props.brand || {}
    const locations = this.props.locations || []
    const validationErrors = this.model.validationErrors || {}
    const saveDisabled = !this.state.dirty
    const formDisabled = false

    const loading = this.props.status === 'LOADING' || this.props.locationsStatus === 'LOADING'
    if (loading) {
      return <Spinner />
    }

    const beacon = brand.beacon || {}

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>{brand.name}</h1>
          </div>
          <div className='page-header--tools'>
            {activeTab === 'locations' ? (
              <ButtonToolbar className='status-toolbar'>
                <LinkContainer to={`/merchants/${merchantId}/brands/${brandId}/locations/new`}>
                  <Button id='status-toolbar-add-location'
                          bsStyle='primary'
                  >
                    New Location
                  </Button>
                </LinkContainer>
                <Button id='status-toolbar-add-location'
                        bsStyle='primary'
                        onClick={() => this.setState({ uploadLocationsModalOpen: true })}
                >
                  Upload Locations
                </Button>
              </ButtonToolbar>
            ) : (
              <Button id='status-toolbar-save'
                      bsStyle='primary'
                      onClick={() => this.save()}
                      disabled={saveDisabled}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        <Row>
          <Col sm={4} lg={3}>
            <Breadcrumbs activeTab={activeTab}
                         onNavigate={(tab) => this.navigateToSection(tab)}
                         brand={brand}
                         beacon={beacon}
                         locations={locations}
                         validationErrors={validationErrors}
            />
          </Col>
          <Col sm={8} lg={activeTab === 'details' ? 6 : 9}>
            {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
            {activeTab === 'details' ? (
              <Panel header='Basic Details' id='details'>
                <Form>
                  {brand.id ? (
                    <div className='form-group form-group-inline'>
                      <label className='control-label'>ID:</label>
                      {' '}
                      {brand.id}
                    </div>
                  ) : null}
                  <FormField label='Name' id='name' mandatory
                             onChange={this.updateField('name')}
                             value={this.model.name}
                             error={validationErrors.name}
                             disabled={formDisabled}
                  />

                  <ImageSelector label='Logo Image'
                                 type='logo'
                                 mediaTarget='BRAND_LOGO'
                                 mandatory
                                 defaultImageProperty='logoImageUrl'
                                 parentMediaProperty='logoImageUrl'
                                 width={320} height={320}
                                 model={this.model}
                                 updateParentField={this.updateField.bind(this)}
                                 disabled={formDisabled} />
                </Form>
              </Panel>
            ) : null}
            {activeTab === 'locations' ? (
              <Panel header='Brand Locations'>
                <LocationsTable locations={locations}
                                onClick={(loc) => this.props.navigateToLocation(loc.id)}
                />
                {this.props.pagination.totalPages > 1 ? (
                  <Pagination totalPages={this.props.pagination.totalPages}
                              page={this.props.pagination.page}
                              onSelect={(page) => this.props.selectPage(page)}
                  />
                ) : null}
              </Panel>
            ) : null}
            {activeTab === 'beacon' ? (
              <Panel header='Brand Beacon'>
                <Form>
                  <FormField label='UUID' id='beacon-uuid'
                             onChange={this.updateField('beacon.uuid')}
                             value={this.model.beacon ? this.model.beacon.uuid || '' : ''}
                             error={validationErrors.uuid}
                             disabled={formDisabled}
                  />
                  <FormField label='Major' id='beacon-major'
                             onChange={this.updateField('beacon.major')}
                             value={this.model.beacon ? this.model.beacon.major || '' : ''}
                             error={validationErrors.major}
                             disabled={formDisabled}
                  />
                </Form>
              </Panel>
            ) : null}
          </Col>
        </Row>
        <UploadLocationsModal visible={this.state.uploadLocationsModalOpen}
                              onSave={(file) => this.props.uploadLocations(file)}
                              onClose={() => this.setState({ uploadLocationsModalOpen: false })}
                              validationErrors={validationErrors}
        />
      </div>
    )
  }
}
