import PreferenceCategoryForm from '../components/preferenceCategoryForm'
import { preferenceActions } from '../preferenceActions'
import _ from 'lodash'
import { connect } from 'react-redux'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    nextHandler: (e, preferenceCategory) => {
      e.preventDefault()
      if (!preferenceCategory.id) {
        return dispatch(preferenceActions.createPreferenceCategory(preferenceCategory))
      }
      return dispatch(preferenceActions.updatePreferenceCategory(preferenceCategory))
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(preferenceActions.navigateToManagePreferenceCategories())
    },
    load: (e) => {
      const id = ownProps.match.params.categoryId

      if (!_.isNil(id)) {
        dispatch(preferenceActions.fetchPreferenceCategory(id))
      } else {
        dispatch(preferenceActions.newPreferenceCategory())
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    preferenceCategory: state.preference.preferenceCategory || {},
    status: state.preference.status,
    validationErrors: state.preference.validationErrors || {},
    error: state.preference.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreferenceCategoryForm)
