import React, { Component } from 'react'
import { Radio } from 'react-bootstrap'

class RadioList extends Component {
  render() {
    return (
      <div>
        {this.props.options.map((option, index) => (
          <Radio name={this.props.id}
                 key={`radio-${index}`}
                 value={option.value}
                 checked={this.props.value === option.value}
                 disabled={option.disabled || this.props.disabled}
                 onChange={() => this.props.onChange(option.value)}
                 inline={this.props.inline}
          >
            {option.label}
          </Radio>
        ))}
      </div>
    )
  }
}

export default RadioList
