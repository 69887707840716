import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'

import ValidationIndicator from '../../common/components/validationIndicator'
import { config } from '../../services/http'

export default class Breadcrumbs extends Component {
  getValidationStatus(model, property, validationErrors) {
    if (!model.id) {
      return 'NEW'
    } else if (Object.keys(model[property]).length === 0) {
      return 'NEW'
    } else if (Object.keys(validationErrors[property]).length === 0) {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  getValidationStatuses() {
    const { eventDate, validationErrors } = this.props

    return {
      details: this.getValidationStatus(eventDate, 'details', validationErrors),
      schedule: this.getValidationStatus(eventDate, 'schedule', validationErrors),
      tickets: this.getValidationStatus(eventDate, 'tickets', validationErrors)
    }
  }

  render() {
    const eventDate = this.props.eventDate
    const statuses = this.getValidationStatuses()

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='event-breadcrumbs-back'
                 onClick={() => this.props.onNavigate('back')}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Tour
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='event-breadcrumbs-details'
                 active={this.props.activeTab === 'details'}
                 onClick={() => this.props.onNavigate('details')}
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Basic Details
        </NavItem>
        <NavItem id='event-breadcrumbs-schedule'
                 active={this.props.activeTab === 'schedule'}
                 onClick={() => this.props.onNavigate('schedule')}
        >
          <ValidationIndicator status={statuses.schedule} />
          {' '}
          Schedule
        </NavItem>
        <NavItem id='event-breadcrumbs-tickets'
                 active={this.props.activeTab === 'tickets'}
                 onClick={() => this.props.onNavigate('tickets')}
        >
          <ValidationIndicator status={statuses.tickets} />
          {' '}
          Tickets
        </NavItem>
        {this.props.showCancel ? (
          <NavItem className='nav-item-divider' />
        ) : null}
        {this.props.showCancel ? (
          <NavItem id='event-breadcrumbs-cancel'
                   onClick={this.props.onCancel}
                   disabled={this.props.dirty}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            {eventDate.details.cancelled ? 'Reopen Event' : 'Cancel Event'}
          </NavItem>
        ) : null}
        {config.auditsEnabled ? [
          <NavItem key='a' className='nav-item-divider' />,
          <NavItem key='b'
                   id='tour-breadcrumbs-audits'
                   active={this.props.activeTab === 'audits'}
                   onClick={() => this.props.onNavigate('audits')}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Audit trail
          </NavItem>
        ] : null}
      </Nav>
    )
  }
}
