import React, { Component } from 'react'
import { Button, Glyphicon, Panel, Table } from 'react-bootstrap'
import ContentTypelabel from '../../common/components/contentTypeLabel'
import StatusLabel from '../../common/components/statusLabel'
import { ctaTypeOptions } from '../enums'
import FormField from '../form/formField'
import { formatDateTime } from '../../common/utils'
import Label from '../form/label'
import classNames from 'classnames'

export default class CallToAction extends Component {
  updateField(path) {
    return (e) => {
      this.props.updateParentField(path, e && e.target ? e.target.value : e)()
    }
  }

  render() {
    const typeOptions = this.props.typeOptions ? this.props.typeOptions : ctaTypeOptions
    const calendarItem = this.props.calendarContent
    let calculatedUrlType = this.props.model.callToAction && this.props.model.callToAction.urlType
      ? this.props.model.callToAction.urlType
      : (this.props.defaultUrlType ? this.props.defaultUrlType : 'NONE')
    if (!typeOptions.find(urlType => urlType.value === calculatedUrlType)) {
      calculatedUrlType = this.props.defaultUrlType
      this.updateField('callToAction.urlType', calculatedUrlType)
    }

    return (
      <div>
        <Panel header='CTA Details' id='callToAction'>
          <FormField label='Type'
                    type='radiolist' mandatory inline
                    value={calculatedUrlType}
                    id='urlType'
                    options={this.props.typeOptions ? this.props.typeOptions : ctaTypeOptions}
                    onChange={this.updateField('callToAction.urlType').bind(this)}
                    disabled={this.props.disabled}
                    error={this.props.validationErrors.callToAction ? this.props.validationErrors.callToAction.urlType : null}
          />
          <FormField label='Label' id='text' value={this.props.model.callToAction ? this.props.model.callToAction.text : ''}
                    maxLength='25'
                    onChange={this.updateField('callToAction.text')}
                    disabled={this.props.disabled || calculatedUrlType === 'NONE'}
                    error={this.props.validationErrors.callToAction ? this.props.validationErrors.callToAction.text : null}
          />
          <FormField label='Description' id='description' value={this.props.model.callToAction ? this.props.model.callToAction.description : ''}
                    maxLength='1000'
                    hidden={!(this.props.model.callToAction && calculatedUrlType === 'CALENDAR')}
                    onChange={this.updateField('callToAction.description')}
                    disabled={this.props.disabled || calculatedUrlType === 'NONE'}
                    error={this.props.validationErrors.callToAction ? this.props.validationErrors.callToAction.description : null}
          />
          <FormField label='URL' id='url'
                    type='text' mandatory
                    hidden={!(this.props.model.callToAction && calculatedUrlType === 'URL')}
                    value={this.props.model.callToAction ? this.props.model.callToAction.url : ''}
                    maxLength='2083'
                    onChange={this.updateField('callToAction.url').bind(this)}
                    disabled={this.props.disabled || calculatedUrlType === 'NONE'}
                    error={this.props.validationErrors.callToAction ? this.props.validationErrors.callToAction.url : null}
          />
          <FormField label='Youtube video ID (O2 channel only)' id='youtubeVideoId'
                    hidden={!(this.props.model.callToAction && calculatedUrlType === 'VIDEO')}
                    type='text' mandatory
                    value={this.props.model.callToAction ? this.props.model.callToAction.youtubeVideoId : ''}
                    maxLength='25'
                    onChange={this.updateField('callToAction.youtubeVideoId')}
                    disabled={this.props.disabled || calculatedUrlType === 'NONE'}
                    error={this.props.validationErrors.callToAction ? this.props.validationErrors.callToAction.youtubeVideoId : null}
          />
        </Panel>
        {this.props.model.callToAction && calculatedUrlType === 'CALENDAR'
        ? <Panel header='Calendar Content' id='calendar-items'>
            <div>
              {this.props.model.callToAction.calendarId && calendarItem ? (
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Title</th>
                      <th id='event-start-or-brand-header'>{'Brand'}</th>
                      <th>Visible from</th>
                      <th>Visible to</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key='content-item=0' onClick={() => this.props.viewContent(calendarItem)} className='cursor-pointer'>
                      <td><ContentTypelabel type={calendarItem.type} /></td>
                      <td><StatusLabel status={calendarItem.state} /></td>
                      <td>{calendarItem.details.title || null}</td>
                      <td>{calendarItem.brand ? calendarItem.brand.name : null}</td>
                      <td>{calendarItem.schedule ? formatDateTime(calendarItem.schedule.displayFromDateTime) : null}</td>
                      <td>{calendarItem.schedule ? formatDateTime(calendarItem.schedule.displayToDateTime) : null}</td>
                    </tr>
                  </tbody>
                </Table>
              ) : (
              <p>There are no items yet.</p>
            )}
            </div>
            <p>
              <Button id='modify-group-items'
                      bsStyle='default'
                      onClick={this.props.onSelectCalendarContent}
              >
                Add or remove items
              </Button>
            </p>
            {this.props.validationErrors.callToAction && this.props.validationErrors.callToAction.calendarId && (
              <Label className={classNames('input-error text-danger')}>
                {this.props.validationErrors.callToAction.calendarId}
              </Label>
            )}
          </Panel>
        : null}
      </div>
    )
  }
}
