import { constants } from './musicBannerActions'

export default function musicBanners(state = {}, action = {}) {
  switch (action.type) {
    case constants.MUSIC_BANNER_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.MUSIC_BANNER_SAVING:
      return Object.assign({}, state, {
        musicBanner: action.musicBanner,
        status: 'SAVING'
      })
    case constants.MUSIC_BANNER_NEW:
    case constants.MUSIC_BANNER_FETCHED:
    case constants.MUSIC_BANNER_SAVED:
    case constants.MUSIC_BANNER_SUBMITTED:
    case constants.MUSIC_BANNER_REJECTED:
    case constants.MUSIC_BANNER_PUBLISHED:
    case constants.MUSIC_BANNER_PAUSED:
    case constants.MUSIC_BANNER_RESUMED:
    case constants.MUSIC_BANNER_EDITED:
      return Object.assign({}, state, {
        musicBanner: action.musicBanner,
        status: 'SUCCESS',
        error: null
      })
    case constants.MUSIC_BANNER_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    default:
      return state
  }
}
