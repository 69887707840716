import { constants } from './venueActions'

const initialState = {
  venues: [],
  venue: null,
  validationErrors: {}
}

export default function venues(state = initialState, action = {}) {
  switch (action.type) {
    case constants.VENUES_FETCHING:
      return Object.assign({}, state, {
        venuesStatus: 'LOADING'
      })
    case constants.VENUES_FETCHED:
      return Object.assign({}, state, {
        venues: action.venues,
        venuesStatus: 'SUCCESS'
      })

    case constants.VENUE_FETCHING: {
      return Object.assign({}, state, {
        venue: null,
        validationErrors: {},
        venueStatus: 'LOADING'
      })
    }
    case constants.VENUE_FETCHED:
      return Object.assign({}, state, {
        venue: action.venue,
        venueStatus: 'SUCCESS'
      })
    case constants.VENUE_NEW: {
      return Object.assign({}, state, {
        venue: action.venue,
        validationErrors: {},
        venueStatus: 'SUCCESS'
      })
    }
    case constants.VENUE_SAVING:
      return Object.assign({}, state, {
        venue: action.venue,
        venueStatus: 'LOADING'
      })
    case constants.VENUE_SAVED: {
      return Object.assign({}, state, {
        venue: action.venue,
        validationErrors: {},
        venueStatus: 'SUCCESS'
      })
    }
    case constants.VENUE_UPDATE_ERROR:
      return Object.assign({}, state, {
        venue: action.venue,
        validationErrors: action.validationErrors,
        venueStatus: 'FAILED'
      })
    default:
      return state
  }
}
