import React from 'react'
import { Table } from 'react-bootstrap'

import { locationStatusToLabel } from '../../common/enums'

const LocationsTable = ({
  locations,
  onClick
}) => (
  locations && locations.length > 0 ? (
    <Table striped hover>
      <thead>
      <tr>
        <th>Reference</th>
        <th>Status</th>
        <th>Address</th>
        <th>City</th>
        <th width='90'>Postcode</th>
      </tr>
      </thead>
      <tbody>
      {locations.map((location, index) => {
        return (
          <tr key={`location-${index}`}
              onClick={() => onClick ? onClick(location) : null}
              className={onClick ? 'cursor-pointer' : null}
          >
            <td>{location.location.ref || null}</td>
            <td>{locationStatusToLabel(location.location.status)}</td>
            <td>{location.location.addressLine1 || null}</td>
            <td>{location.location.city || null}</td>
            <td>{location.location.postcode || null}</td>
          </tr>
        )
      })}
      </tbody>
    </Table>
  ) : (
    <p>There are no locations yet.</p>
  )
)

export default LocationsTable
