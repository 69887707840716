import React, { Component } from 'react'
import { Button, Panel, Table } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import _ from 'lodash'

import { formatDateTime } from '../../common/utils'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'

class EventDatesPanel extends Component {
  render() {
    const {
      tour,
      eventDates,
      venues,
      onClick
    } = this.props

    return (
      <Panel header='Event Dates' id='eventDates'>
        {eventDates.length > 0 ? (
          <Table striped hover>
            <thead>
            <tr>
              <th className='content-list--col__status'>Status</th>
              <th className='content-list--col__title'>Venue</th>
              <th className='content-list--col__date'>Event (doors)</th>
              <th className='content-list--col__date'>Presale Start</th>
              <th className='content-list--col__date'>Presale End</th>
            </tr>
            </thead>
            <tbody>
            {eventDates.map((eventDate, idx) => {
              const venue = venues.find(venue => venue.value === eventDate.details.venueId)
              return (
                <tr key={`ed-${idx}`} onClick={(event) => onClick(eventDate, event)} className='cursor-pointer'>
                  <td>
                    <StatusLabel status={eventDate.state} />
                    {eventDate.details.cancelled ? (
                      <StatusLabelCustom className='page-header--state-label margin-left-5' status='cancelled' text='Cancelled' />
                    ) : null}
                  </td>
                  <td>{venue ? venue.label : null}</td>
                  <td>{formatDateTime(_.get(eventDate, 'details.eventDateTime'))}</td>
                  <td>{formatDateTime(_.get(eventDate, 'schedule.redeemFromDateTime'))}</td>
                  <td>{formatDateTime(_.get(eventDate, 'schedule.redeemToDateTime'))}</td>
                </tr>
              )
            })}
            </tbody>
          </Table>
        ) : null}
        <LinkContainer to={`/tours/${tour.id}/eventDates/new`}>
          <Button id='add-date'>
            Add new date
          </Button>
        </LinkContainer>
      </Panel>
    )
  }
}

export default EventDatesPanel
