import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'

import Form from '../../common/form/form'
import FaqBreadcrumbs from './faqBreadcrumbs'
import FormField from '../../common/form/formField'
import Layout from './faqLayout'
import { platformOptions } from '../../common/enums'
import _ from 'lodash'
import Label from '../../common/form/label'
import RichText from '../../common/form/richText'

export default class FaqForm extends Component {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(this.props.faq)
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.faq, this.props.faq)) {
      this.model = _.cloneDeep(this.props.faq)
      this.forceUpdate()
    }
  }

  updateField(path, value) {
    return (e) => {
      if (path === 'question' && e.target.value != null) {
        const noSpecialCharacters = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
        this.model.faqId = noSpecialCharacters.trim().replace(/\s+/g, '-').toLowerCase()
      }
      _.set(this.model, path, e ? e.target.value : value)
      this.setState({ dirty: true })
    }
  }

  _onChangeCheckboxValue(checkboxValue) {
    this.model['enabled'] = checkboxValue.target.checked
    this.setState({ dirty: true })
  }

  render() {
    const validationErrors = this.props.validationErrors || {}
    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <FaqBreadcrumbs match={this.props.match}
                            faqSectionId={this.props.faqSectionId}
                            faqId={this.props.faq.id}
            />
          </Col>
          <Col sm={8} lg={6}>
            {this.props.error || null}
            <Panel header='FAQ'>
              <Form nextButtonText='Save'
                    nextHandler={(e) => {
                      this.state.dirty = false
                      this.props.nextHandler(e, this.props.faqSectionId, this.model)
                    }}
                    nextButtonDisabled={this.state ? !this.state.dirty : true}
                    backButtonText='Back'
                    backHandler={(e) => {
                      this.props.backHandler(e, this.props.faqSectionId)
                    }}
              >
                <FormField label='Question'
                           id='question'
                           type='text' maxLength='200'
                           value={this.model.question ? this.model.question : ''}
                           error={validationErrors.question}
                           mandatory
                           onChange={this.updateField('question')}
                />
                <FormField label='FAQ ID'
                           id='faqId'
                           value={this.model.faqId}
                           readOnly
                />
                 <div>
                   <Label>
                     Answer
                   </Label>
                   <RichText value={this.model.answer || ''}
                             id='answer'
                             onChange={content => this.updateField('answer', content)()}
                             error={validationErrors.answer}
                   />
                 </div>
                <FormField label='Platforms'
                           id='platforms'
                           options={platformOptions}
                           value={this.model.platforms ? this.model.platforms || [] : []}
                           type='checkboxlist'
                           checked
                           disabled={false}
                           error={validationErrors.platforms}
                           onChange={(value) => this.updateField('platforms', value)()}
                           mandatory
                />
                <FormField label='Enabled'
                           id='enabled'
                           type='checkbox'
                           error={validationErrors.enabled}
                           value={this.model.enabled}
                           onChange={(e) => {
                             this._onChangeCheckboxValue(e)
                           }}
                />
              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
