import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'

import DataTable from '../../common/components/dataTable'
import Constants from '../../appConstants'
import AppError from '../../common/components/appError'
import Layout from './welcomePosterLayout'
import Spinner from '../../common/components/spinner'
import { welcomePosterTypeOptions } from '../../common/enums'

export default class ManageWelcomePosters extends Component {
  constructor(props) {
    super(props)
    props.fetchWelcomePosterHandler()
  }

  render() {
    let error = <div />
    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }

    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    const headers = {
      type: { name: 'Type', sortable: true },
      overline: { name: 'Overline', sortable: true },
      title: { name: 'Title', sortable: true },
      edit: { name: 'Actions', sortable: false }
    }

    let table = <div id="no-data">No Welcome Posters to display</div>
    if (this.props.welcomePosters && this.props.welcomePosters.length > 0) {
      const tableRows = this.props.welcomePosters.map(welcomePoster => {
        return {
          type: welcomePosterTypeOptions.filter(option => option.value === welcomePoster.subType).map(option => option.label),
          overline: welcomePoster.overline,
          title: welcomePoster.title,
          edit: (
            <span>
              <a className="link"
                 onClick={(e) => {
                   this.props.setClickedWelcomePoster(welcomePoster)
                   this.props.navigateToEdit(e, welcomePoster)
                 }}
                 style={{ marginRight: '10px' }}>
                  Edit
              </a>
            </span>
          )
        }
      })
      table = <DataTable displayPageNo={Constants.DEFAULT_DISPLAY_PAGE_NO}
                         rowsPerPage={Constants.DEFAULT_ROWS_PER_PAGE}
                         headers={headers}
                         data={tableRows}
                         wrapperClassName='welcomePosters-table'
      />
    }

    return (
      <Layout>
        <Row>
          <Col sm={12} lg={12}>
            {error}
            <Panel header="Welcome Posters">
              <div id="welcomePoster-listing-panel">
                {table}
              </div>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
