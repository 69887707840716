import React from 'react'
import { Button, Table } from 'react-bootstrap'

import AppFlash from '../../common/components/appFlash'
import { locationStatusToLabel } from '../../common/enums'
import Spinner from '../../common/components/spinner'

export default class Listing extends React.Component {
  componentDidMount() {
    this.props.load()
  }

  render() {
    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    const venues = this.props.venues ? this.props.venues.sort((a, b) => {
      return `${a.city}_${a.name}`.toLowerCase() > `${b.city}_${b.name}`.toLowerCase() ? 1 : -1
    }) : []

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>Venues</h1>
          </div>
          <div className='page-header--tools'>
            <Button bsStyle='primary' onClick={() => this.props.newVenue()}>New Venue</Button>
          </div>
        </div>
        {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
        <Table striped hover>
          <thead>
          <tr>
            <th>Name</th>
            <th>Address Line 1</th>
            <th>City</th>
            <th>Postcode</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          {venues.map(venue => (
            <tr className='cursor-pointer' key={venue.id} onClick={() => this.props.onVenueClick(venue)}>
              <td>
                {venue.name}
              </td>
              <td>
                {venue.addressLine1}
              </td>
              <td>
                {venue.city}
              </td>
              <td>
                {venue.postcode}
              </td>
              <td>
                {locationStatusToLabel(venue.status)}
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
