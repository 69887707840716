/* global FormData */

import http, { config, STOCK_V1 } from '../services/http'

const stockError = (error) => ({
  type: constants.STOCK_ERROR,
  error: error
})

const stockValidationError = (validationErrors) => ({
  type: constants.STOCK_VALIDATION_ERROR,
  validationErrors: validationErrors
})

const stockFetching = () => ({
  type: constants.STOCK_FETCHING
})

const stockFetched = (stock) => ({
  type: constants.STOCK_FETCHED,
  stock
})

const fetchStock = (offerId) => {
  return (dispatch) => {
    dispatch(stockFetching())
    return http.get(dispatch, `${config.stockServerUrl}/stock/${offerId}`, null, {
      Accept: STOCK_V1
    }).then((resp) => {
      dispatch(stockFetched(resp))
    }).catch((err) => {
      dispatch(stockError(`Unable to fetch stock: ${err.status} (${err.statusText})`))
    })
  }
}

const updateVolumeRequest = (dispatch, offerId, limit, threshold, volume) => {
  const payload = { limit, threshold, volume }
  return http.post(dispatch, `${config.stockServerUrl}/stock/${offerId}`, payload, {
    Accept: STOCK_V1
  })
}

const updateVolume = (offerId, model, limit, threshold, volume) => {
  return (dispatch) => {
    dispatch(volumeSaving(model))
    return updateVolumeRequest(dispatch, offerId, limit, threshold, volume).then((resp) => {
      dispatch(volumeSaved(resp))
    }).catch(handleErrors(dispatch, model))
  }
}

const handleErrors = (dispatch, stock) => {
  return (resp) => {
    if (resp.status === 400) {
      const validationErrors = JSON.parse(resp.body).validationErrors
      dispatch(stockValidationError(validationErrors))
    } else {
      dispatch(stockError(`Unable to save stock: ${resp.status} (${resp.statusText})`))
    }
  }
}

const volumeSaving = (model) => ({
  type: constants.STOCK_VOLUME_SAVING,
  model
})
const volumeSaved = (stock) => ({
  type: constants.STOCK_VOLUME_SAVED,
  stock
})

const uniqueCodesUploading = (model) => ({
  type: constants.STOCK_UNIQUE_CODES_UPLOADING,
  model
})
const uniqueCodesUploaded = (stock) => ({
  type: constants.STOCK_UNIQUE_CODES_UPLOADED,
  stock
})

const uploadUniqueCodes = (offerId, model, file, scheduledStartDateTime, scheduledEndDateTime) => {
  var formData = new FormData()
  formData.append('file', file)
  scheduledStartDateTime ? formData.append('scheduleStartDateTime', scheduledStartDateTime.toJSON()) : null
  scheduledEndDateTime ? formData.append('scheduleEndDateTime', scheduledEndDateTime.toJSON()) : null

  return (dispatch) => {
    dispatch(uniqueCodesUploading(model))
    return http.post(dispatch, `${config.stockServerUrl}/stock/${offerId}/uniqueCodes`, formData)
      .then((resp) => {
        dispatch(uniqueCodesUploaded(resp))
      }).catch((err) => {
        // TODO Move to http
        let message
        try {
          message = JSON.parse(err.body).description
        } catch (e) {
          message = `${err.status} (${err.statusText})`
        }

        throw String(`Unable to upload codes: ${message}`)
      })
  }
}

const deleteAllStockRequested = () => ({
  type: constants.STOCK_DELETE_REQUESTED
})

const deleteAllStockCompleted = stock => ({
  type: constants.STOCK_DELETE_COMPLETED,
  stock
})

const deleteAllStock = offerId => {
  return dispatch => {
    dispatch(deleteAllStockRequested())
    return http.del(dispatch, `${config.stockServerUrl}/stock/${offerId}/uniqueCodes`, {
      Accept: STOCK_V1
    })
      .then(resp => dispatch(deleteAllStockCompleted(resp)))
      .catch(err => dispatch(stockError(`Unable to remove all vouchers: ${err.status} (${err.statusText})`)))
  }
}

const downloadUniqueCodes = (offerId, id) => {
  const url = `${config.stockServerUrl}/stock/${offerId}/uniqueCodes/${id}`
  const fileName = `unique_voucher_codes_${offerId}.csv`
  http.download(url, fileName)
}

const downloadUnusedCodes = (offerId) => {
  const url = `${config.stockServerUrl}/stock/${offerId}/unusedCodes/`
  const fileName = `unused_unique_voucher_codes_${offerId}.csv`
  http.download(url, fileName)
}

export const constants = {
  STOCK_FETCHING: 'STOCK_FETCHING',
  STOCK_FETCHED: 'STOCK_FETCHED',
  STOCK_VOLUME_SAVING: 'STOCK_VOLUME_SAVING',
  STOCK_VOLUME_SAVED: 'STOCK_VOLUME_SAVED',
  STOCK_UNIQUE_CODES_UPLOADING: 'STOCK_UNIQUE_CODES_UPLOADING',
  STOCK_UNIQUE_CODES_UPLOADED: 'STOCK_UNIQUE_CODES_UPLOADED',
  STOCK_ERROR: 'STOCK_ERROR',
  STOCK_VALIDATION_ERROR: 'STOCK_VALIDATION_ERROR',
  STOCK_DELETE_REQUESTED: 'STOCK_DELETE_REQUESTED',
  STOCK_DELETE_COMPLETED: 'STOCK_DELETE_COMPLETED'
}

export const stockActions = {
  fetchStock,
  updateVolume,
  updateVolumeRequest,
  uploadUniqueCodes,
  downloadUniqueCodes,
  deleteAllStock,
  downloadUnusedCodes
}
