import { connect } from 'react-redux'
import SelectCalendarItems from '../components/selectCalendarItems'
import { contentActions } from '../contentActions'
import { campaignActions } from '../../campaigns/campaignActions'

const mapDispatchToProps = (dispatch) => {
  return {
    load: (currentContent) => {
      dispatch(contentActions.clearContents())
      dispatch(contentActions.setSelected(currentContent.callToAction.calendarId ? [currentContent.callToAction.calendarId] : []))
      const allowedTypes = ['OFFER', 'ARTICLE', 'PRIZE_DRAW', 'TOUR']
      dispatch(contentActions.setFilters('calendarItems', {
        type: allowedTypes,
        state: [],
        search: ''
      }))
    },
    onToggleSelected: (calendarItem) => {
      dispatch(contentActions.toggleSelected(calendarItem))
    },
    backToContentItem: (contentItem) => {
      dispatch(contentActions.navigateToContentItem(contentItem, 'callToAction'))
    },
    updateContent: (contentItem) => {
      dispatch(contentActions.saveContent(contentItem)).then(() => {
        dispatch(contentActions.fetchCalendarContent(contentItem)).then(() => {
          dispatch(contentActions.navigateToContentItem(contentItem, 'callToAction'))
        })
      })
    },
    updateFilters: (filters) => {
      dispatch(contentActions.setFilters('calendarItems', filters))
    },
    updateSearch: (query) => {
      dispatch(contentActions.setSearch('calendarItems', query))
    },
    selectPage: () => {
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contents: state.contents.contentList,
    status: state.contents.status,
    maxSelectable: state.contents.maxSelectable,
    roles: state.common.roles,
    selectedFilters: state.contents.selectedFilters.calendarItems,
    currentContent: state.contents.currentContent,
    selectedItems: state.contents.selectedItems
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCalendarItems)
