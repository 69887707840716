import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import _ from 'lodash'

import Form from '../../common/form/form'
import AppError from '../../common/components/appError'
import AppFlash from '../../common/components/appFlash'
import Password from '../../common/form/password'
import Spinner from '../../common/components/spinner'

export default class ResetPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.model = {
      token: props.token || ''
    }
    this.state = {
      validationErrors: this.props.validationErrors,
      disableNextButton: true
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      this.model.token = this.props.token
      this.forceUpdate()
    }

    if (!_.isEqual(prevProps.validationErrors, this.props.validationErrors)) {
      this.setState({
        validationErrors: this.props.validationErrors
      })
    }
  }

  componentDidMount() {
    this.props.load()
  }

  update(property) {
    return (e) => {
      this.model[property] = e.target.value
    }
  }

  _onChangePassword(password) {
    this.model['password'] = password.value
    this.setState({
      validationErrors: {},
      disableNextButton: !password.isMatching
    })
  }

  render() {
    const nextHandler = this.props.nextHandler
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }

    return (
      <Panel header='Reset Password' style={{ maxWidth: '400px', margin: '30px auto' }}>
        <Form nextButtonText='Reset password'
              nextButtonDisabled={this.state.disableNextButton}
              nextHandler={(e) => {
                nextHandler(e, this.model)
              }}>

          {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
          {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}

          <Password error={this.props.validationErrors.password}
                    onChange={(e) => {
                      this._onChangePassword(e)
                    }}
          />
        </Form>
      </Panel>
    )
  }
}
