import React from 'react'
import { config } from '../../services/http'

const contentTypePathMap = {
  EVENT: '/tickets/',
  OFFER: '/offers/',
  PRIZE_DRAW: '/prizedraws/',
  GROUP: '/groups/',
  SERIES: '/series/',
  THANK_YOU: '/groups/',
  TOUR: '/tickets/',
  EVENT_DATE: '/tickets/',
  ARTICLE: '/articles/'
}

const openInNewWindow = function(event) {
  window.open(event.target.href)
  event.preventDefault()
}

const WebLink = ({ content }) => {
  const url = config.webRoot + contentTypePathMap[content.type] + content.id

  return (
    <a href={url} className='page-header--state-label link' onClick={openInNewWindow}>View on Web</a>
  )
}

export default WebLink
