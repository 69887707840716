import moment from 'moment'

export const getPathName = () => {
  return window.location.pathname
}

export const dateFormatRegex = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/
export const timeFormatRegex = /^[0-9]{1,2}:[0-9]{2}$/
export const dateFormat = 'DD/MM/YYYY'
export const timeFormat = 'H:mm'
export const dateTimeFormat = 'DD/MM/YY HH:mm'
export const fullDateTimeFormat = 'DD/MM/YY HH:mm:ss'

export const formatDate = (date) => {
  return date ? moment(date).format(dateFormat) : ''
}
export const formatTime = (time) => {
  return time ? moment(time).format(timeFormat) : ''
}
export const formatDateTime = (date) => {
  return date ? moment(date).format(dateTimeFormat) : ''
}

export const formatFullDateTime = (date) => {
  return date ? moment(date).format(fullDateTimeFormat) : ''
}

export const possibleNumberToString = (value) => {
  return isNaN(parseInt(value)) ? '' : String(value)
}

export const titleCase = (text = '') => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export const formatNumber = (number) => {
  if (Number.prototype.toLocaleString) {
    return number.toLocaleString()
  } else {
    return number
  }
}

export const parameterize = (value) => {
  return encodeURIComponent(value.toLowerCase().replace(/_/g, ' '))
}

export default {
  getPathName,
  dateFormatRegex,
  timeFormatRegex,
  dateFormat,
  timeFormat,
  formatDate,
  formatTime,
  possibleNumberToString,
  titleCase,
  formatNumber,
  parameterize
}
