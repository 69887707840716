import { connect } from 'react-redux'

import NotificationProfileForm from '../components/notificationProfileForm'
import { notificationActions } from '../notificationActions'
import _ from 'lodash'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    nextHandler: (e, notificationProfile) => {
      e.preventDefault()
      if (!notificationProfile.id) {
        return dispatch(notificationActions.createNotificationProfile(notificationProfile))
      }
      return dispatch(notificationActions.updateNotificationProfile(notificationProfile))
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(notificationActions.navigateToNotificationProfilesList())
    },
    load: (e) => {
      const id = ownProps.match.params.id

      if (_.isNil(id)) {
        dispatch(notificationActions.newNotificationProfile())
      } else {
        dispatch(notificationActions.fetchNotificationProfile(id))
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    notificationProfile: state.notifications.notificationProfile || {},
    status: state.notifications.status,
    validationErrors: state.notifications.validationErrors || {},
    error: state.notifications.error
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationProfileForm)
