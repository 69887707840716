import React, { Component } from 'react'
import { Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

class NotificationBreadcrumbs extends Component {
  render() {
    const { match } = this.props
    const path = match.path

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>

        <LinkContainer to='/notifications/profiles'
                       isActive={() => path === '/notifications/profiles'}
                       id='breadcrumbs-manage-msisdn'
        >
          <NavItem>
            Manage Notification Profiles
          </NavItem>
        </LinkContainer>
        <LinkContainer to='/notifications/profiles/create'
                       isActive={() => path === '/notifications/profiles/create'}
                       id='breadcrumbs-new-msisdn'
        >
          <NavItem>
            Create Notification Profile
          </NavItem>
        </LinkContainer>
      </Nav>
    )
  }
}

export default NotificationBreadcrumbs
