import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'

import ValidationIndicator from '../../common/components/validationIndicator'
import { config } from '../../services/http'
import _ from 'lodash'

export default class Breadcrumbs extends Component {
  isNew(model, property, validationErrors) {
    if (!model.id ||
      (Object.keys(model[property]).length === 0) ||
      (property === 'schedule' && (!model.schedule || _.isNil(model.schedule.triggers)) && _.isEmpty(validationErrors[property]))) {
      return true
    }
    return false
  }

  getValidationStatus(model, property, validationErrors) {
    if (this.isNew(model, property, validationErrors)) {
      return 'NEW'
    } else if (_.isEmpty(validationErrors[property])) {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  eventDatesValidationStatus() {
    const hasEventDates = this.props.eventDates && this.props.eventDates.length > 0

    return hasEventDates ? 'VALID' : 'NEW'
  }

  getValidationStatuses() {
    const { tour, validationErrors } = this.props

    return {
      details: this.getValidationStatus(tour, 'details', validationErrors),
      assets: this.getValidationStatus(tour, 'assets', validationErrors),
      schedule: this.getValidationStatus(tour, 'schedule', validationErrors),
      eventDates: this.eventDatesValidationStatus(),
      relatedContent: this.getValidationStatus(tour, 'relatedContent', validationErrors)
    }
  }

  render() {
    var statuses = this.getValidationStatuses()

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='tour-breadcrumbs-back'
                 onClick={() => this.props.onNavigate('back')}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Home
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='tour-breadcrumbs-details'
                 active={this.props.activeTab === 'details'}
                 onClick={() => this.props.onNavigate('details')}
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Basic Details
        </NavItem>
        <NavItem id='tour-breadcrumbs-assets'
                 active={this.props.activeTab === 'assets'}
                 onClick={() => this.props.onNavigate('assets')}
        >
          <ValidationIndicator status={statuses.assets} />
          {' '}
          Assets
        </NavItem>
        <NavItem id='group-breadcrumbs-schedule'
                 active={this.props.activeTab === 'schedule'}
                 onClick={() => this.props.onNavigate('schedule')}
        >
          <ValidationIndicator status={statuses.schedule} />
          {' '}
          Schedule
        </NavItem>
        <NavItem id='tour-breadcrumbs-eventDates'
                 active={this.props.activeTab === 'eventDates'}
                 onClick={() => this.props.onNavigate('eventDates')}
        >
          <ValidationIndicator status={statuses.eventDates} />
          {' '}
          Event Dates
        </NavItem>
        <NavItem id='tour-breadcrumbs-related-content'
                 active={this.props.activeTab === 'relatedContent'}
                 onClick={() => this.props.onNavigate('relatedContent')}
        >
          <ValidationIndicator status={statuses.relatedContent} />
          {' '}
          Related Content
        </NavItem>
        {config.auditsEnabled ? [
          <NavItem key='a' className='nav-item-divider' />,
          <NavItem key='b'
                   id='tour-breadcrumbs-audits'
                   active={this.props.activeTab === 'audits'}
                   onClick={() => this.props.onNavigate('audits')}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Audit trail
          </NavItem>
        ] : null}
      </Nav>
    )
  }
}
