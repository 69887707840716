import { connect } from 'react-redux'
import { prizedrawActions } from '../prizedrawActions'
import OurPicksLayout from '../../common/components/TriggersLayout'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const id = ownProps.match.params.id
      dispatch(prizedrawActions.fetchPrizeDraw(id))
    },
    save: (prizedraw) => {
      dispatch(prizedrawActions.savePrizeDraw(prizedraw))
      dispatch(prizedrawActions.navigateToSchedule(ownProps.match.params.id))
    },
    backHandler: () => {
      dispatch(prizedrawActions.navigateToSchedule(ownProps.match.params.id))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contentId: ownProps.match.params.id,
    content: state.prizedraws.prizedraw,
    contentType: 'prizedraws',
    index: ownProps.match.params.index,
    status: state.prizedraws.status
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurPicksLayout)
