import { connect } from 'react-redux'

import { contentActions } from '../contentActions'
import { bannerActions } from '../../banners/bannerActions'
import ContentListing from '../components/contentListing'

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(contentActions.clearContents())
      dispatch(contentActions.fetchContents('content'))
    },
    viewContent: (contentItem = {}, event) => {
      dispatch(contentActions.navigateToContentItem(contentItem, 'details', event))
    },
    updateContent: (contentItem) => {
      dispatch(bannerActions.saveBanner(contentItem))
    },
    updateFilters: (filters) => {
      dispatch(contentActions.setFilters('content', filters))
    },
    updateSearch: (query) => {
      dispatch(contentActions.setSearch('content', query))
    },
    selectPage: () => {
      dispatch(contentActions.fetchContents('content'))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contentList: state.contents.contentList,
    nextOffset: state.contents.nextOffset,
    selectedFilters: state.contents.selectedFilters.content,
    status: state.contents.status,
    error: state.contents.error,
    roles: state.common.roles,
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentListing)
