import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import Spinner from '../../common/components/spinner'

import AppError from '../../common/components/appError'
import SelectContentTable from './_selectContentTable'
import ContentFilters from './contentFilters'
import InfiniteScroll from './infiniteScroll'
import { relatedContentFilteredContentTypes } from '../../common/enums.js'

export default class SelectRelatedContent extends InfiniteScroll {
  constructor(props) {
    super(props)
    props.load()
  }

  updateRelatedContent() {
    this.props.updateContent({
      ...this.props.currentContent,
      relatedContent: { ids: this.props.selectedItems }
    })
  }

  render() {
    const status = this.props.status || 'LOADING'
    const contents = this.props.contents.filter((contentItem) => {
      return contentItem.type !== 'BANNER' &&
              contentItem.type !== 'CAMPAIGN' &&
              contentItem.type !== 'THANK_YOU' &&
              contentItem.type !== 'SERIES' &&
              contentItem.id !== this.props.currentContent.id
    })
    const roles = this.props.roles

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>Select related content</h1>
          </div>
          <div className='page-header--tools'>
            <ButtonToolbar>
              <Button id='cancel-related-content' onClick={() => this.props.backToContentItem(this.props.currentContent)}>Cancel</Button>
              <Button id='update-related-content' bsStyle='primary' onClick={() => this.updateRelatedContent()}>Update</Button>
            </ButtonToolbar>
          </div>
        </div>
        <ContentFilters selectedFilters={this.props.selectedFilters}
                        updateFilters={this.props.updateFilters}
                        updateSearch={this.props.updateSearch}
                        contentTypes={relatedContentFilteredContentTypes}
                        typesHidden={!roles.some(role => ['ADMIN', 'INTERNAL'].includes(role))}
        />
        {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
        <div className='content-list'>
          {status === 'LOADING' ? (
            <Spinner />
          ) : null}
          <SelectContentTable items={contents}
                              disabledTypes={['THANK_YOU']}
                              selectedItems={this.props.selectedItems}
                              onToggleSelected={this.props.toggleSelected}
                              selectedFilters={this.props.selectedFilters}
          />
        </div>
      </div>
    )
  }
}
