import React, { Component } from 'react'
import FormField from '../../common/form/formField'

export default class Password extends Component {
  constructor(props) {
    super(props)
    this.model = {}
    this.state = {
      showPasswordError: false,
      validationError: this.props.error || ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({
        validationError: this.props.error
      })
    }
  }

  update(e, property) {
    this.model[property] = e.target.value
    this.setState({
      showPasswordError: this.arePasswordsTypedIn() && !this.arePasswordsMatching(),
      validationError: ''
    })

    const isMatching = this.arePasswordsTypedIn() && this.arePasswordsMatching()
    this.props.onChange({ isMatching: isMatching, value: (isMatching ? this.model.password : '') })
  }

  arePasswordsTypedIn() {
    return !!this.model.password && !!this.model.retypePassword
  }

  arePasswordsMatching() {
    return this.model.password === this.model.retypePassword
  }

  render() {
    return (
      <div>
        <FormField label='Password'
                   id='password'
                   type='password'
                   value={this.model.password}
                   onChange={e => this.update(e, 'password')}
                   error={this.state.validationError}
                   help='Password must have at least 8 characters and must contain letters, numbers and symbols'
                   mandatory
        />
        <FormField label='Retype Password'
                   id='retype-password'
                   type='password'
                   value={this.model.retypePassword}
                   onChange={e => this.update(e, 'retypePassword')}
                   error={this.state.showPasswordError ? 'Password is not matching' : undefined}
                   mandatory
        />
      </div>
    )
  }
}
