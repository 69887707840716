import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

class FaqSectionBreadcrumbs extends Component {
  render() {
    const { match } = this.props
    const { faqSectionId } = this.props
    const path = match.path
    var details = '/faq/createSection'
    var faq = '/faq/createSection'
    if (faqSectionId) {
      details = `/faq/${faqSectionId}/edit`
      faq = `/faq/${faqSectionId}/faqs`
    }
    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <LinkContainer to='/faq'
                       isActive={() => path === '/faq'}
        >
          <NavItem>
            <Glyphicon glyph='chevron-left' />
            {' '}
            Back to FAQ Section Listing
          </NavItem>
        </LinkContainer>
        <NavItem className='nav-item-divider' />
        <LinkContainer to={details}
                       isActive={() => path === { details }}
                       id='faq-breadcrumbs-manage-faq-section'
        >
          <NavItem>
            Basic Details
          </NavItem>
        </LinkContainer>
        <LinkContainer to={faq}
                       isActive={() => path === { faq }}
                       id='faq-breadcrumbs-manage-faqs'
        >
          <NavItem>
            FAQ
          </NavItem>
        </LinkContainer>
      </Nav>
    )
  }
}

export default FaqSectionBreadcrumbs
