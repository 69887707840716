import { push } from 'connected-react-router'
import _ from 'lodash'
import { commonActions } from '../common/commonActions'

import http, { config, SECTION_HEADER_DETAILS_V1, CONTENT_DETAIL_V1, CONFIGURABLE_CONTENTS_LIST_V1 } from '../services/http'

const navigateToDashboard = () => {
  return push('/')
}

const navigateToManageSectionHeaders = () => {
  return push('/sectionHeaders')
}

const navigateToEditSectionHeader = (sectionHeader) => {
  return push(`/sectionHeaders/${sectionHeader.type}/edit`)
}

const sectionHeadersFetching = () => ({
  type: constants.SECTION_HEADERS_FETCHING
})

const sectionHeadersFetched = (sectionHeaders) => ({
  type: constants.SECTION_HEADERS_FETCHED,
  sectionHeaders
})

const sectionHeaderContentFetching = () => ({
  type: constants.SECTION_HEADER_CONTENT_FETCHING
})

const sectionHeaderContentFetched = (sectionHeaderContent) => ({
  type: constants.SECTION_HEADER_CONTENT_FETCHED,
  sectionHeaderContent
})

const sectionHeaderUpdated = (sectionHeader) => ({
  type: constants.SECTION_HEADER_UPDATED,
  sectionHeader
})

const setClickedSectionHeader = (sectionHeader) => ({
  type: constants.CLICKED_SECTION_HEADER,
  sectionHeader
})

const sectionHeaderError = (error) => ({
  type: constants.SECTION_HEADER_ERROR,
  error
})

const sectionHeaderUpdateError = (validationErrors) => ({
  type: constants.SECTION_HEADER_UPDATE_ERROR,
  validationErrors
})

const newSectionHeader = () => ({
  type: constants.SECTION_HEADER_NEW,
  validationErrors: {},
  error: '',
  sectionHeader: {
    title: '',
    subtitle: '',
    type: ''
  }
})

const fetchSectionHeaders = () => {
  return (dispatch) => {
    dispatch(sectionHeadersFetching())
    return http.get(dispatch, `${config.serverUrl}/configurableContents?type=SECTION_HEADER`, null, {
      Accept: CONFIGURABLE_CONTENTS_LIST_V1
    }).then((sectionHeaders) => {
      dispatch(sectionHeadersFetched(sectionHeaders))
      return sectionHeaders
    }).catch((err) => {
      dispatch(sectionHeaderError(`Unable to retrieve the list of sectionHeaders: ${err.status} (${err.statusText})`))
    })
  }
}

const updateSectionHeader = (sectionHeaderDetails) => {
  return (dispatch) => {
    const payload = _.pick(sectionHeaderDetails, [
      'title',
      'subtitle',
      'subType',
      'contentId'
    ])

    return http.put(dispatch, `${config.serverUrl}/configurableContents/sectionHeader`, payload, {
      Accept: SECTION_HEADER_DETAILS_V1
    }).then((sectionHeader) => {
      dispatch(sectionHeaderUpdated(sectionHeader))
      dispatch(setClickedSectionHeader(sectionHeader))
      dispatch(navigateToEditSectionHeader(sectionHeader))
      dispatch(commonActions.displayFlash('Record has been updated.'))
    }).catch(handleErrors(dispatch))
  }
}

const fetchSectionHeaderContent = (sectionHeaderDetails) => {
  return (dispatch) => {
    dispatch(sectionHeaderContentFetching())
    if (sectionHeaderDetails.contentId) {
      const attachedContentId = sectionHeaderDetails.contentId.id
      return http.get(dispatch, `${config.serverUrl}/configurableContents/${attachedContentId}/content`, null, {
        Accept: CONTENT_DETAIL_V1
      }).then((content) => {
        dispatch(sectionHeaderContentFetched(content))
        return content
      }).catch((err) => {
        dispatch(sectionHeaderError(`Unable to retrieve the content of sectionHeader: ${err.status} (${err.statusText})`))
      })
    } else {
      dispatch(sectionHeaderContentFetched(null))
    }
  }
}

const handleErrors = (dispatch) => {
  return (response) => {
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(sectionHeaderUpdateError(json.validationErrors))
    } else {
      if (response.body) {
        dispatch(sectionHeaderError(response.body))
      } else {
        dispatch(sectionHeaderError(response.statusText))
      }
    }
  }
}

export const constants = {
  SECTION_HEADERS_FETCHING: 'SECTION_HEADERS_FETCHING',
  SECTION_HEADERS_FETCHED: 'SECTION_HEADERS_FETCHED',
  SECTION_HEADER_ERROR: 'SECTION_HEADER_ERROR',
  SECTION_HEADER_UPDATE_ERROR: 'SECTION_HEADER_UPDATE_ERROR',
  SECTION_HEADER_UPDATED: 'SECTION_HEADER_UPDATED',
  SECTION_HEADER_NEW: 'SECTION_HEADER_NEW',
  CLICKED_SECTION_HEADER: 'CLICKED_SECTION_HEADER',
  SECTION_HEADER_CONTENT_FETCHING: 'SECTION_HEADER_CONTENT_FETCHING',
  SECTION_HEADER_CONTENT_FETCHED: 'SECTION_HEADER_CONTENT_FETCHED'
}

export const sectionHeaderActions = {
  navigateToDashboard,
  navigateToManageSectionHeaders,
  sectionHeadersFetching,
  sectionHeadersFetched,
  sectionHeaderError,
  sectionHeaderUpdateError,
  fetchSectionHeaders,
  sectionHeaderUpdated,
  newSectionHeader,
  navigateToEditSectionHeader,
  updateSectionHeader,
  setClickedSectionHeader,
  fetchSectionHeaderContent
}
