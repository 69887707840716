import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { brandActions } from '../../brands/brandActions'
import { commonActions } from '../../common/commonActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import PrizedrawForm from '../components/PrizedrawForm'

import { prizedrawActions } from '../prizedrawActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      load: () => {
        const { id, auditId } = ownProps.match.params
        if (id) {
          dispatch(prizedrawActions.fetchPrizeDraw(id))
          dispatch(prizedrawActions.fetchRelatedContent(id))
          dispatch(prizedrawActions.getLiveEntrants(id))
        } else {
          dispatch(brandActions.fetchBrands())
          dispatch(prizedrawActions.newPrizeDraw())
        }
        dispatch(commonActions.fetchPreferenceCategories())
        if (auditId) {
          dispatch(auditActions.fetchAudit(auditId))
        }
      },
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/prizedraws/${id}/audits/${audit.id}`))
      },
      submit: (prizedraw) => {
        dispatch(prizedrawActions.submitPrizeDraw(prizedraw))
      },
      reject: (prizedraw) => {
        dispatch(prizedrawActions.rejectPrizeDraw(prizedraw))
      },
      publish: (prizedraw) => {
        dispatch(prizedrawActions.publishPrizeDraw(prizedraw))
      },
      pause: (prizedraw) => {
        dispatch(prizedrawActions.pausePrizeDraw(prizedraw))
      },
      resume: (prizedraw) => {
        dispatch(prizedrawActions.resumePrizeDraw(prizedraw))
      },
      edit: (prizedraw) => {
        dispatch(prizedrawActions.editPrizeDraw(prizedraw))
      },
      save: (prizedraw) => {
        return dispatch(prizedrawActions.savePrizeDraw(prizedraw))
      },
      getLiveEntrants: () => {
        dispatch(prizedrawActions.getLiveEntrants(ownProps.match.params.id))
      },
      downloadEntrants: (fileType) => {
        prizedrawActions.downloadEntrants(ownProps.match.params.id, fileType)
      },
      navigateToNewOurPicksTrigger: (prizedraw) => {
        let index = 0
        if (prizedraw.schedule && prizedraw.schedule.triggers) {
          index = prizedraw.schedule.triggers.length
        }
        dispatch(prizedrawActions.navigateToOurPicksScheduler(prizedraw.id, index))
      },

      navigateToEditOurPicksTrigger: (prizedrawId, index) => {
        dispatch(prizedrawActions.navigateToOurPicksScheduler(prizedrawId, index))
      },
      navigateToSchedule: () => {
        dispatch(prizedrawActions.navigateToSchedule(ownProps.match.params.id))
      }
    }
  )
}

const mapStateToProps = (state) => {
  return {
    prizedraw: state.prizedraws.prizedraw,
    relatedContent: state.prizedraws.relatedContent,
    status: state.prizedraws.status,
    error: state.prizedraws.error,
    brands: state.brands.brands,
    liveEntrantsCount: state.prizedraws.liveEntrantsCount,
    prizeDrawState: state.prizedraws.prizeDrawState,
    roles: state.common.roles,
    audits: state.audits,
    flash: state.common.flash,
    preferenceCategories: state.common.preferenceCategories
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrizedrawForm)
