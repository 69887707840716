import React from 'react'

const LinkPreview = ({
  linkId,
  url
}) => {
  if (!url) return null

  return (
    <div className='thumbnail'>
      <a id={linkId} href={url} target='_blank' style={{ color: 'blue' }}>
        {url}
      </a>
    </div>
  )
}

export default LinkPreview
