import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { auditActions } from '../auditActions'
import AuditListing from '../components/auditListing'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const { auditId } = ownProps.match.params
      dispatch(auditActions.fetchAudits())
      if (auditId) {
        dispatch(auditActions.fetchAudit(auditId))
      }
    },
    unload: () => {
      dispatch(auditActions.auditFetched(null))
    },
    selectPage: (page) => {
      dispatch(auditActions.clearAudits())
      dispatch(auditActions.selectPage(page))
      dispatch(auditActions.fetchAudits())
    },
    onViewAudit: (audit) => {
      dispatch(auditActions.auditFetched(audit))
      dispatch(push(`/audits/${audit.id}`))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    flash: state.common.flash,
    audits: state.audits,
    roles: state.common.roles,
    profile: state.common.profile
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditListing)
