import { connect } from 'react-redux'

import SelectLocations from '../components/SelectLocations'
import { locationActions } from '../locationActions'
import { contentActions } from '../../content/contentActions'
import { offerActions } from '../../offers/offerActions'

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLocations: (brandId) => {
      dispatch(locationActions.fetchLocations(brandId))
    },
    backToContentItem: (contentItem) => {
      dispatch(contentActions.navigateToContentItem(contentItem, 'locations'))
    },
    updateLocations: (contentItem, selectedItems) => {
      dispatch(offerActions.addLocations(contentItem.brand.id, contentItem.id, selectedItems)).then(() => {
        dispatch(contentActions.navigateToContentItem(contentItem, 'locations'))
      })
    },
    toggleSelected: (location) => {
      dispatch(contentActions.toggleSelected(location))
    },
    toggleAllSelected: (locations, selected) => {
      dispatch(contentActions.toggleAllSelected(locations, selected))
    },
    selectPage(page, brandId) {
      dispatch(locationActions.selectPage(page))
      dispatch(locationActions.fetchLocations(brandId))
    },
    selectAllPages(brandId) {
      dispatch(locationActions.fetchAllLocations(brandId)).then(locations => {
        const selectedItems = locations.items.map(l => ({ id: l.id }))
        dispatch(contentActions.setSelected(selectedItems))
      })
    },
    deselectAllPages() {
      dispatch(contentActions.setSelected([]))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    locations: state.locations.locations,
    pagination: state.locations.pagination,
    currentContent: state.contents.currentContent,
    selectedItems: state.contents.selectedItems,
    status: state.locations.status,
    error: state.locations.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectLocations)
