import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import FormField from '../../common/form/formField'
import LinkPreview from '../components/linkPreview'
import MediaComponentBase from './MediaComponentBase'
import { mapDispatchToProps, mapStateToProps } from './mediaContainer'

class AudioSelector extends MediaComponentBase {
  render() {
    return (
      <div>
        <FormField label={this.props.label} type='file' id={this.mediaType}
                   mandatory={this.props.mandatory}
                   value={this.state.assets[this.mediaType + 'Url']}
                   onChange={(event) => {
                     this.uploadMedia(event.target.files[0], this.props.mediaTarget, this.mediaType + 'Url')
                   }}
                   onError={(err) => {
                     this.addValidationError(this.mediaType + 'Url', err)
                   }}
                   onClear={() => {
                     this.updateField('assets.' + this.mediaType + 'Url', null).bind(this)()
                   }}
                   uploading={this.state.uploading === this.mediaType + 'Url'}
                   error={this.state.errors[this.mediaType + 'Url']}
                   disabled={this.props.disabled}
                   iconClassName='glyphicon-headphones'
                   acceptFileTypes='audio/mp3,audio/mpeg'
        />
        {this.state.assets[this.mediaType + 'Url'] ? (
          <LinkPreview linkId={this.mediaType + 'Link'} url={this.state.assets[this.mediaType + 'Url']} />
        ) : null}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioSelector)

AudioSelector.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['audio']).isRequired,
  mediaTarget: PropTypes.oneOf([
    'AUDIO'
  ]).isRequired,
  model: PropTypes.object,
  updateParentField: PropTypes.func,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool
}

AudioSelector.defaultProps = {
  disabled: false
}
