import React, { Component } from 'react'
import { Checkbox, Radio } from 'react-bootstrap'

// Example Usage:
// <FormField
//     label = "Platform" id = "platformFilters"
//     value = { this.model.details ? this.model.details.platformFilters || [] : []}
//     options = { platformOptions }
//     type = "switchedmulticheckbox"
//     onChange = { (value) => this.updateField("details.platformFilters", value)() }
//     disabled = {formDisabled}
//     error = { validationErrors.details.platFormFilters }
// />

class SwithchedMultiCheckBox extends Component {
  enableCheckboxes() {
    const allValues = this.props.options.map((option) => {
      return option.value
    })
    this.props.onChange(allValues)
  }

  disableCheckboxes() {
    this.props.onChange([])
  }

  onChange(e, option) {
    var value = this.props.value
    if (value.includes(option.value)) {
      var index = value.indexOf(option.value)
      value.splice(index, 1)
    } else {
      value.push(option.value)
    }
    this.props.onChange(value)
  }

  render() {
    return (
      <div>
        <Radio key='radio-1'
               value='all'
               inline
               checked={!this.props.value.length > 0}
               onChange={() => this.disableCheckboxes()}
               disabled={this.props.disabled}
        >
          All
        </Radio>
        <Radio key='radio-2'
               value='limited'
               inline
               checked={this.props.value.length > 0}
               onChange={() => this.enableCheckboxes()}
               disabled={this.props.disabled}
        >
          Limited
        </Radio>
        {this.props.options.map((option, index) => (
          <Checkbox key={`check-${index}`}
                    value={option.value}
                    inline
                    checked={this.props.value.indexOf(option.value) > -1}
                    disabled={this.props.disabled || this.props.value.length === 0}
                    onChange={(e) => this.onChange(e, option)}
          >
            {option.label}
          </Checkbox>
        ))}
      </div>
    )
  }
}

export default SwithchedMultiCheckBox
