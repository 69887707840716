import { connect } from 'react-redux'
import { contentActions } from '../contentActions'
import FeaturedContentsTitleForm from '../components/featuredContentsTitleForm'

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(contentActions.fetchFeaturedContentsTitle())
    },
    save: (e, featuredContentsTitle) => {
      e.preventDefault()
      dispatch(contentActions.saveFeaturedContentsTitle(featuredContentsTitle))
    },
    back: (e) => {
      e.preventDefault()
      dispatch(contentActions.navigateToOurPicks())
    }
  }
}

const mapStateToProps = (state) => {
  return {
    featuredContentsTitle: state.contents.featuredContentsTitle || {},
    validationErrors: state.contents.validationErrors || {},
    status: state.contents.featuredContentsTitleStatus,
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedContentsTitleForm)
