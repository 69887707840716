import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import TriggerBreadcrumbs from './TriggerBreadcrumbs'
import TriggerForm from './TriggerForm'
import Spinner from './spinner'

export default class TriggersLayout extends Component {
  constructor(props) {
    super(props)
    props.load()
  }

  render() {
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }

    const backUrl = `/${this.props.contentType}/${this.props.contentId}/schedule`
    const detailsUrl = `/${this.props.contentType}/${this.props.contentId}/${this.props.content.type === 'TOUR' ? 'triggers' : 'ourPicksTrigger'}/${this.props.index}`

    return (
      <div>
        <div className='page-header'>
        <h1>{this.props.content.type === 'TOUR' ? 'Tickets Trigger' : 'Our Picks Trigger'}</h1>
        </div>
        <Row>
          <Col sm={4} lg={3}>
            <TriggerBreadcrumbs backUrl={backUrl}
                                        detailsUrl={detailsUrl}
            />
          </Col>
          <Col sm={8} lg={6}>
            <TriggerForm index={this.props.index}
                                 content={this.props.content}
                                 save={this.props.save}
                                 backHandler={this.props.backHandler}
                                 formDisabled={this.formDisabled}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
