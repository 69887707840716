import React, { Component } from 'react'
import { Button, Col, Panel, Row } from 'react-bootstrap'
import FormField from '../../common/form/formField'
import { ticketTypes } from '../../common/enums'

class TicketsPanel extends Component {
  toggleSoldOut(field) {
    const tickets = this.props.model.tickets || {}
    const patch = {
      preSaleSoldOut: !!tickets.preSaleSoldOut,
      preSaleStandingSoldOut: !!tickets.preSaleStandingSoldOut,
      preSaleSeatedSoldOut: !!tickets.preSaleSeatedSoldOut
    }
    patch[field] = !tickets[field]
    this.props.updateSoldOut(this.props.model, patch)
  }

  render() {
    const {
      formDisabled,
      validationErrors,
      updateField,
      dirty
    } = this.props

    const tickets = this.props.model.tickets || {}
    tickets.ticketType = tickets.ticketType || 'GENERAL'

    return (
      <Panel header='Tickets' id='tickets'>
        <FormField label='Ticket Type' id='ticketType'
                   type='radiolist' inline
                   options={ticketTypes}
                   value={tickets.ticketType}
                   onChange={(ticketType) => updateField('tickets.ticketType', ticketType)()}
                   disabled={formDisabled}
        />
        {tickets.ticketType === 'GENERAL' ? (
          <div>
            <Row>
              <Col md={9}>
                <FormField label='Pre-sale URL' id='preSaleURL' placeholder='https://'
                           mandatory
                           value={tickets.preSaleURL || ''}
                           onChange={updateField('tickets.preSaleURL')}
                           disabled={formDisabled}
                           error={validationErrors.tickets.preSaleURL}
                />
              </Col>
              <Col md={3} style={{ paddingTop: '25px' }}>
                {tickets.preSaleSoldOut ? (
                  <label id='preSaleURL-soldOut' className='text-danger' style={{ marginRight: '10px' }}>Sold
                    Out</label>
                ) : null}
                <Button onClick={() => this.toggleSoldOut('preSaleSoldOut')} disabled={dirty || !tickets.preSaleURL}>
                  {tickets.preSaleSoldOut ? 'Undo' : 'Sold Out'}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <FormField label='General sale URL' id='generalSaleURL' placeholder='https://'
                           value={tickets.generalSaleURL || ''}
                           onChange={updateField('tickets.generalSaleURL')}
                           disabled={formDisabled}
                           error={validationErrors.tickets.generalSaleURL}
                />
              </Col>
              <Col md={3} style={{ paddingTop: '25px' }}>
                {tickets.generalSaleSoldOut ? (
                  <label id='generalSaleURL-soldOut' className='text-danger' style={{ marginRight: '10px' }}>Sold
                    Out</label>
                ) : null}
                <Button onClick={() => this.toggleSoldOut('generalSaleSoldOut')} disabled={dirty || !tickets.generalSaleURL}>
                  {tickets.generalSaleSoldOut ? 'Undo' : 'Sold Out'}
                </Button>
              </Col>
            </Row>
          </div>
        ) : null}
        {/* Standing / Seated checkbox option is currently hidden. Below code left in for future change of mind. */}
        {tickets.ticketType === 'STANDING_SEATED' ? (
          <div>
            <Row>
              <Col md={9}>
                <FormField label='Pre-sale Standing URL' id='preSaleStandingURL' placeholder='https://'
                           mandatory
                           value={tickets.preSaleStandingURL || ''}
                           onChange={updateField('tickets.preSaleStandingURL')}
                           disabled={formDisabled}
                           error={validationErrors.tickets.preSaleStandingURL}
                />
              </Col>
              <Col md={3} style={{ paddingTop: '25px' }}>
                {tickets.preSaleStandingSoldOut ? (
                  <label id='preSaleStandingURL-soldOut' className='text-danger' style={{ marginRight: '10px' }}>Sold Out</label>
                ) : null}
                <Button onClick={() => this.toggleSoldOut('preSaleStandingSoldOut')} disabled={dirty || !tickets.preSaleStandingURL}>
                  {tickets.preSaleStandingSoldOut ? 'Undo' : 'Sold Out'}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <FormField label='Pre-sale Seated URL' id='preSaleSeatedURL' placeholder='https://'
                           mandatory
                           value={tickets.preSaleSeatedURL || ''}
                           onChange={updateField('tickets.preSaleSeatedURL')}
                           disabled={formDisabled}
                           error={validationErrors.tickets.preSaleSeatedURL}
                />
              </Col>
              <Col md={3} style={{ paddingTop: '25px' }}>
                {tickets.preSaleSeatedSoldOut ? (
                  <label id='preSaleSeatedURL-soldOut' className='text-danger' style={{ marginRight: '10px' }}>Sold Out</label>
                ) : null}
                <Button onClick={() => this.toggleSoldOut('preSaleSeatedSoldOut')} disabled={dirty || !tickets.preSaleSeatedURL}>
                  {tickets.preSaleSeatedSoldOut ? 'Undo' : 'Sold Out'}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <FormField label='General sale standing URL' id='generalSaleStandingURL' placeholder='https://'
                           value={tickets.generalSaleStandingURL || ''}
                           onChange={updateField('tickets.generalSaleStandingURL')}
                           disabled={formDisabled}
                           error={validationErrors.tickets.generalSaleStandingURL}
                />
              </Col>
              <Col md={3} style={{ paddingTop: '25px' }}>
                {tickets.generalSaleStandingSoldOut ? (
                  <label id='generalSaleStandingURL-soldOut' className='text-danger' style={{ marginRight: '10px' }}>Sold
                    Out</label>
                ) : null}
                <Button onClick={() => this.toggleSoldOut('generalSaleStandingSoldOut')} disabled={dirty || !tickets.generalSaleStandingURL}>
                  {tickets.generalSaleStandingSoldOut ? 'Undo' : 'Sold Out'}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <FormField label='General sale seated URL' id='generalSaleSeatedURL' placeholder='https://'
                           value={tickets.generalSaleSeatedURL || ''}
                           onChange={updateField('tickets.generalSaleSeatedURL')}
                           disabled={formDisabled}
                           error={validationErrors.tickets.generalSaleSeatedURL}
                />
              </Col>
              <Col md={3} style={{ paddingTop: '25px' }}>
                {tickets.generalSaleSeatedSoldOut ? (
                  <label id='generalSaleSeatedURL-soldOut' className='text-danger' style={{ marginRight: '10px' }}>Sold
                    Out</label>
                ) : null}
                <Button onClick={() => this.toggleSoldOut('generalSaleSeatedSoldOut')} disabled={dirty || !tickets.generalSaleSeatedURL}>
                  {tickets.generalSaleSeatedSoldOut ? 'Undo' : 'Sold Out'}
                </Button>
              </Col>
            </Row>
          </div>
        ) : null}
      </Panel>
    )
  }
}

export default TicketsPanel
