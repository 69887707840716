import { push } from 'connected-react-router'
import { commonActions } from '../../../assets/js/common/commonActions'
import http, { config, GEO_CODE_LOCATION_V1, VENUE_DETAILS_V1, VENUE_LIST_V1 } from '../services/http'

const navigateToVenues = () => {
  return push('/venues')
}

const navigateToNewVenue = () => {
  return push('/venues/new')
}

const navigateToVenueDetails = (venueId) => {
  return push(`/venues/${venueId}/details`)
}

const venuesFetching = () => ({
  type: constants.VENUES_FETCHING
})

const venuesFetched = (venues) => ({
  type: constants.VENUES_FETCHED,
  venues
})

const fetchVenues = (navigate = false) => {
  return (dispatch) => {
    dispatch(venuesFetching())
    return http.get(dispatch, `${config.serverUrl}/venues`, null, {
      Accept: VENUE_LIST_V1
    }).then((resp) => {
      dispatch(venuesFetched(resp))
      if (navigate) {
        dispatch(navigateToVenues())
      }
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to retrieve the list of venues: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const newVenue = () => ({
  type: constants.VENUE_NEW,
  venue: { status: 'OPEN' }
})

const venueFetching = () => ({
  type: constants.VENUE_FETCHING
})

const venueFetched = (venue) => ({
  type: constants.VENUE_FETCHED,
  venue
})

const fetchVenue = (venueId, navigate = false) => {
  return (dispatch) => {
    dispatch(venueFetching())
    return http.get(dispatch, `${config.serverUrl}/venues/${venueId}`, null, {
      Accept: VENUE_DETAILS_V1
    }).then((resp) => {
      dispatch(venueFetched(resp))
      if (navigate) {
        dispatch(navigateToVenueDetails(venueId))
      }
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to retrieve venue: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const venueSaving = (venue) => ({
  type: constants.VENUE_SAVING,
  venue
})

const venueSaved = (venue) => ({
  type: constants.VENUE_SAVED,
  venue
})

const venueUpdateError = (validationErrors, venueDetails) => ({
  type: constants.VENUE_UPDATE_ERROR,
  validationErrors,
  venue: venueDetails
})

const saveVenue = (venue) => {
  if (!venue.id) {
    return createVenue(venue)
  } else {
    return updateVenue(venue)
  }
}

const createVenue = (venue, onSuccess) => {
  return (dispatch) => {
    dispatch(venueSaving(venue))
    return http.post(dispatch, `${config.serverUrl}/venues`, venue, {
      Accept: VENUE_DETAILS_V1
    }).then((venue) => {
      dispatch(venueSaved(venue))
      dispatch(navigateToVenueDetails(venue.id))
      dispatch(commonActions.displayFlash('Venue created', 'info'))
      return venue
    }).catch(handleErrors(dispatch, venue))
  }
}

const updateVenue = (venue, onSuccess) => {
  return (dispatch) => {
    dispatch(venueSaving(venue))
    return http.put(dispatch, `${config.serverUrl}/venues/${venue.id}`, venue, {
      Accept: VENUE_DETAILS_V1
    }).then((venue) => {
      dispatch(venueSaved(venue))
      dispatch(commonActions.displayFlash('Venue saved', 'info'))
      return venue
    }).catch(handleErrors(dispatch, venue))
  }
}

const handleErrors = (dispatch, venue) => {
  return (resp) => {
    if (resp.status === 400) {
      const validationErrors = JSON.parse(resp.body).validationErrors
      dispatch(venueUpdateError(validationErrors, venue))
    } else if (resp.status === 409) {
      const validationErrors = { name: 'This venue and postcode already exist.' }
      dispatch(venueUpdateError(validationErrors, venue))
    } else {
      dispatch(commonActions.displayFlash(`Unable to save venue: ${resp.status} (${resp.statusText})`, 'error'))
    }
  }
}

const geocode = (address1, city, postcode) => {
  return (dispatch) => {
    return http.get(dispatch, `${config.locationServerUrl}/geocode?line1=${address1}&city=${city}&postcode=${postcode}`, null, {
      Accept: GEO_CODE_LOCATION_V1
    }).then((response) => {
      dispatch(commonActions.displayFlash(`Successfully added: ${address1}, ${city}, ${postcode}`, 'info'))
      return response
    }).catch((err) => {
      if (err.status === 404 || err.status === 400) {
      dispatch(commonActions.displayFlash(`Unable to retrieve lat/long from Google API: ${address1}, ${city}, ${postcode}. Error: ${err.status} ${err.statusText}`, 'error'))
      }
    })
  }
}

export const constants = {
  VENUES_FETCHING: 'VENUES_FETCHING',
  VENUES_FETCHED: 'VENUES_FETCHED',
  VENUE_FETCHING: 'VENUE_FETCHING',
  VENUE_FETCHED: 'VENUE_FETCHED',
  VENUE_SAVING: 'VENUE_SAVING',
  VENUE_NEW: 'VENUE_NEW',
  VENUE_SAVED: 'VENUE_SAVED',
  VENUE_UPDATE_ERROR: 'VENUE_UPDATE_ERROR'
}

export const venueActions = {
  navigateToVenues,
  navigateToNewVenue,
  navigateToVenueDetails,
  fetchVenues,
  venuesFetched,
  fetchVenue,
  venueFetched,
  newVenue,
  venueSaving,
  venueSaved,
  venueUpdateError,
  saveVenue,
  geocode
}
