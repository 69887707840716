import { push } from 'connected-react-router'

import http, { BRAND_DETAILS_V1, BRANDS_LIST_V1, config, MERCHANT_DETAILS_V1 } from '../services/http'
import { commonActions } from '../common/commonActions'

const navigateToBrand = (merchantId, brandId, section = 'details') => {
  return push(`/merchants/${merchantId}/brands/${brandId}/${section}`)
}

const fetchBrands = () => {
  return (dispatch) => {
    return http.get(dispatch, `${config.serverUrl}/brands`, null, {
      Accept: BRANDS_LIST_V1
    }).then((brands) => {
      dispatch(brandsFetched(brands))
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to fetch brands : ${config.serverUrl}/brands - ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const brandsFetched = (brands) => ({
  type: constants.BRANDS_FETCHED,
  brands
})

const fetchBrand = (brandId) => {
  return (dispatch) => {
    return http.get(dispatch, `${config.serverUrl}/brands/${brandId}`, null, {
      Accept: BRAND_DETAILS_V1
    }).then((brand) => {
      dispatch(brandFetched(brand))
    }).catch((err) => {
      dispatch(brandError())
      dispatch(commonActions.displayFlash(`Unable to retrieve the brand for id ${brandId}: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const brandFetched = (brand) => ({
  type: constants.BRAND_FETCHED,
  brand
})

const createBrand = (merchantId, brand) => {
  return (dispatch) => {
    dispatch(brandSaving(brand))
    return http.post(dispatch, `${config.serverUrl}/merchants/${merchantId}/brands/`, brand, {
      Accept: MERCHANT_DETAILS_V1
    }).then((resp) => {
      dispatch(brandSaved(resp))
      return resp
    }).catch((error) => {
      dispatch(commonActions.displayFlash(`Unable to create the new brand: ${error.status} (${error.statusText})`, 'error'))
    })
  }
}

const updateBrand = (merchantId, brand) => {
  return (dispatch) => {
    dispatch(brandSaving(brand))
    return http.put(dispatch, `${config.serverUrl}/merchants/${merchantId}/brands/${brand.id}`, brand, {
      Accept: MERCHANT_DETAILS_V1
    }).then((resp) => {
      // TODO switch when a brand is returned rather than the merchant
      // dispatch(brandSaved(resp))
      dispatch(brandSaved(brand))
      dispatch(commonActions.clearFlash())
      return resp
    }).catch((err) => {
      const validationErrors = JSON.parse(err.body).validationErrors
      const brandWithErrors = { ...brand, validationErrors }
      dispatch(brandSaved(brandWithErrors))
      dispatch(commonActions.displayFlash('There were errors saving the brand', 'error'))
    })
  }
}

const saveBrand = (merchantId, brand) => {
  if (!brand.id) {
    return createBrand(merchantId, brand)
  } else {
    return updateBrand(merchantId, brand)
  }
}

const brandSaving = (brand) => ({
  type: constants.BRAND_SAVING,
  brand
})

const brandSaved = (brand) => ({
  type: constants.BRAND_SAVED,
  brand
})

const brandError = () => ({
  type: constants.BRAND_ERROR
})

export const constants = {
  BRANDS_FETCHED: 'BRANDS_FETCHED',
  BRAND_FETCHED: 'BRAND_FETCHED',
  BRAND_SAVING: 'BRAND_SAVING',
  BRAND_SAVED: 'BRAND_SAVED',
  BRAND_ERROR: 'BRAND_ERROR'
}

export const brandActions = {
  navigateToBrand,
  brandFetched,
  fetchBrands,
  fetchBrand,
  saveBrand,
  brandSaved,
  brandsFetched
}
