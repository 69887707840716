import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ReactCrop, { makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import AppFlash from './appFlash'

const initialState = {
  image: undefined,
  crop: {}
}

class ImageCropModal extends Component {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
  }

  componentDidUpdate(prevProps) {
    if (this.props.src && this.props.src !== prevProps.src) {
      const image = new window.Image()
      image.src = this.props.src
      image.onload = () => {
        const options = {
          x: 0,
          y: 0,
          aspect: this.props.width / this.props.height
        }
        const desiredRatio = this.props.width / this.props.height
        const imageRatio = image.width / image.height
        const scaleWarning = image.width < this.props.width || image.height < this.props.height

        if (desiredRatio > imageRatio) {
          options.width = 100
        } else {
          options.height = 100
        }

        const state = {
          image,
          crop: makeAspectCrop(options, image.width / image.height),
          scaleWarning
        }

        this.setState(state)
      }
    }
  }

  save() {
    const canvas = document.createElement('canvas')
    canvas.width = this.props.width
    canvas.height = this.props.height
    const ctx = canvas.getContext('2d')
    const scale = this.props.width / this.state.image.width * (100 / this.state.crop.width)

    const width = this.state.image.width * scale
    const height = this.state.image.height * scale
    let x = 0
    let y = 0
    if (this.state.crop.x) {
      x = 0 - (this.state.crop.x / 100) * width
    }
    if (this.state.crop.y) {
      y = 0 - (this.state.crop.y / 100) * height
    }
    ctx.drawImage(this.state.image, x, y, width, height)
    canvas.toBlob((blob) => {
      this.props.onCrop(blob)
      this.close()
    }, 'image/jpeg', 0.95)
  }

  close() {
    this.setState(initialState)
    this.props.onClose()
  }

  render() {
    return (
      <Modal show={this.props.visible} onHide={() => this.close()}>
        <Modal.Body>
          <h4>Crop Image</h4>
          {this.props.src ? (
            <div>
              {this.state.scaleWarning ? (
                <AppFlash flash={{
                  style: 'info',
                  message: `Upload an image larger than (${this.props.width} x ${this.props.height}) to prevent blurring.`
                }} />
              ) : null}
              <ReactCrop className={(this.props.shapeType === 'Circle') ? 'circle-crop' : ''}
                         src={this.props.src}
                         crop={this.state.crop}
                         onChange={(crop) => this.setState({ crop })}
              />
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button id='cancel' onClick={() => this.close()}>Cancel</Button>
          <Button id='save' bsStyle='primary'
                  onClick={() => this.save()}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ImageCropModal
