import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { confirmable, createConfirmation } from 'react-confirm'

const Confirmation = ({
  title,
  body,
  show,
  proceed,
  dismiss,
  cancel
}) => (
  <Modal show={show} onHide={dismiss}>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {body}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={cancel}>Cancel</Button>
      <Button bsStyle='primary' onClick={proceed}>OK</Button>
    </Modal.Footer>
  </Modal>
)

const confirmation = createConfirmation(confirmable(Confirmation))

const confirm = function confirm(title, body, options = {}) {
  return confirmation({ title, body, ...options })
}

export default confirm
