import { constants } from './prizedrawActions'

const initialState = {
  relatedContent: []
}
export default function prizedraws(state = initialState, action = {}) {
  switch (action.type) {
    case constants.PRIZEDRAW_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.PRIZEDRAW_SAVING:
      return Object.assign({}, state, {
        prizedraw: action.prizedraw,
        status: 'SAVING'
      })
    case constants.PRIZEDRAW_NEW:
      return Object.assign({}, state, {
        prizedraw: action.prizedraw,
        status: 'SUCCESS',
        error: null,
        relatedContent: [],
        prizeDrawState: null,
        liveEntrantsCount: 0
      })
    case constants.PRIZEDRAW_FETCHED:
      return Object.assign({}, state, {
        prizedraw: action.prizedraw,
        status: 'SUCCESS',
        error: null,
        prizeDrawState: null,
        liveEntrantsCount: 0
      })
    case constants.PRIZEDRAW_SAVED:
    case constants.PRIZEDRAW_SUBMITTED:
    case constants.PRIZEDRAW_REJECTED:
    case constants.PRIZEDRAW_PUBLISHED:
    case constants.PRIZEDRAW_PAUSED:
    case constants.PRIZEDRAW_RESUMED:
    case constants.PRIZEDRAW_EDITED:
      return Object.assign({}, state, {
        prizedraw: action.prizedraw,
        status: 'SUCCESS',
        error: null
      })
    case constants.PRIZEDRAW_RELATED_CONTENT_FETCHING:
      return Object.assign({}, state, {
        relatedContent: []
      })
    case constants.PRIZEDRAW_RELATED_CONTENT_FETCHED:
      return Object.assign({}, state, {
        relatedContent: action.relatedContent
      })
    case constants.PRIZEDRAW_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    case constants.PRIZEDRAW_LIVE_ENTRANTS_COUNT_FETCHED:
      return Object.assign({}, state, {
        status: 'SUCCESS',
        liveEntrantsCount: action.liveEntrantsCount
      })
    case constants.PRIZEDRAW_STATE_RECEIVED:
      return Object.assign({}, state, {
        status: 'SUCCESS',
        prizeDrawState: action.state
      })
    default:
      return state
  }
}
