import { push } from 'connected-react-router'
import http, { config, NOTIFICATION_PROFILE_DETAIL_V1, NOTIFICATION_PROFILE_LIST_V1 } from '../services/http'

const handleErrors = (dispatch) => {
  return (response) => {
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(createOrUpdateError(json.validationErrors))
    } else {
      if (response.body) {
        dispatch(serviceError(response.body))
      } else {
        dispatch(serviceError(response.statusText))
      }
    }
  }
}

const fetchingNotificationProfiles = () => ({
  type: constants.NOTIFICATION_PROFILES_FETCHING
})

const fetchNotificationProfiles = () => {
  return (dispatch) => {
    dispatch(fetchingNotificationProfiles())
    return http.get(dispatch, `${config.serverUrl}/notifications/profiles`, null, {
      Accept: NOTIFICATION_PROFILE_LIST_V1
    }).then((notificationProfilesList) => {
      dispatch(fetchedNotificationProfiles(notificationProfilesList))
    }).catch(handleErrors(dispatch))
  }
}

const fetchingNotificationProfile = () => ({
  type: constants.NOTIFICATION_PROFILE_FETCHING
})

const fetchNotificationProfile = (id) => {
  return (dispatch) => {
    dispatch(fetchingNotificationProfile())
    return http.get(dispatch, `${config.serverUrl}/notifications/profiles/${id}`, null, {
      Accept: NOTIFICATION_PROFILE_DETAIL_V1
    }).then((notificationProfile) => {
      dispatch(fetchedNotificationProfile(notificationProfile))
    }).catch(handleErrors(dispatch))
  }
}

const newNotificationProfile = () => ({
  type: constants.NEW_NOTIFICATION_PROFILE,
  notificationProfile: { emailEnabled: true, msisdnEnabled: true },
  validationErrors: {},
  error: ''
})

const savingNotificationProfile = () => ({
  type: constants.NOTIFICATION_PROFILE_SAVING
})

const createNotificationProfile = (payload) => {
  return (dispatch) => {
    dispatch(savingNotificationProfile())
    return http.post(dispatch, `${config.serverUrl}/notifications/profiles`, payload).then(() => {
      dispatch(navigateToNotificationProfilesList())
    }).catch(handleErrors(dispatch))
  }
}

const updateNotificationProfile = (payload) => {
  return (dispatch) => {
    dispatch(savingNotificationProfile())
    return http.put(dispatch, `${config.serverUrl}/notifications/profiles/${payload.id}`, payload)
      .then(() => {
        dispatch(navigateToNotificationProfilesList())
      }).catch(handleErrors(dispatch))
  }
}

const deleteNotificationProfile = (id) => {
  return (dispatch) => {
    dispatch(savingNotificationProfile())
    return http.del(dispatch, `${config.serverUrl}/notifications/profiles/${id}`)
      .then((response) => {
        dispatch(notificationProfileDeleted(id))
      }).catch(handleErrors(dispatch))
  }
}

const navigateToNotificationProfilesList = () => {
  return push('/notifications/profiles')
}

const navigateToEditNotificationProfile = (id) => {
  return push(`/notifications/profiles/${id}`)
}

const createOrUpdateError = (validationErrors) => ({
  type: constants.CREATE_OR_UPDATE_ERROR,
  validationErrors
})

const serviceError = (error) => ({
  type: constants.SERVICE_ERROR,
  error
})

const fetchedNotificationProfile = (notificationProfile) => ({
  type: constants.NOTIFICATION_PROFILE_FETCHED,
  notificationProfile: notificationProfile,
  error: ''
})

const fetchedNotificationProfiles = (notificationProfilesList) => ({
  type: constants.NOTIFICATION_PROFILES_FETCHED,
  notificationProfiles: notificationProfilesList,
  error: ''
})

const notificationProfileDeleted = (id) => ({
  type: constants.NOTIFICATION_PROFILE_DELETED,
  id
})

export const constants = {
  NOTIFICATION_PROFILES_FETCHING: 'NOTIFICATION_PROFILES_FETCHING',
  NOTIFICATION_PROFILES_FETCHED: 'NOTIFICATION_PROFILES_FETCHED',

  NOTIFICATION_PROFILE_FETCHING: 'NOTIFICATION_PROFILE_FETCHING',
  NOTIFICATION_PROFILE_FETCHED: 'NOTIFICATION_PROFILE_FETCHED',

  NEW_NOTIFICATION_PROFILE: 'NEW_NOTIFICATION_PROFILE',
  NOTIFICATION_PROFILE_SAVING: 'NOTIFICATION_PROFILE_SAVING',

  CREATE_OR_UPDATE_ERROR: 'CREATE_OR_UPDATE_ERROR',
  SERVICE_ERROR: 'SERVICE_ERROR',
  NOTIFICATION_PROFILE_DELETED: 'NOTIFICATION_PROFILE_DELETED'
}

export const notificationActions = {
  createNotificationProfile,
  navigateToNotificationProfilesList,
  updateNotificationProfile,
  navigateToEditNotificationProfile,
  fetchNotificationProfile,
  fetchNotificationProfiles,
  fetchedNotificationProfile,
  fetchedNotificationProfiles,
  deleteNotificationProfile,
  newNotificationProfile,
  notificationProfileDeleted
}
