import { connect } from 'react-redux'

import { featureFlagActions } from '../featureFlagActions'
import { dashboardActions } from '../../dashboard/dashboardActions'
import ManageFeatureFlags from '../components/manageFeatureFlags'

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchFeatureFlagHandler: () => {
      dispatch(featureFlagActions.fetchFeatureFlags())
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(dashboardActions.navigateToDashboard())
    },
    navigateToEdit: (e, featureFlag) => {
      e.preventDefault()
      dispatch(featureFlagActions.navigateToEditFeatureFlag(featureFlag))
    },
    setClickedFeatureFlag: (featureFlag) => {
      dispatch(featureFlagActions.setClickedFeatureFlag(featureFlag))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    featureFlags: state.featureFlags.featureFlags || {},
    status: state.featureFlags.featureFlagStatus,
    error: state.featureFlags.error,
    roles: state.common.roles,
    profile: state.common.profile
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageFeatureFlags)
