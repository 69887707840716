import { connect } from 'react-redux'

import NotificationProfilesList from '../components/notificationProfilesList'
import { notificationActions } from '../notificationActions'

const mapDispatchToProps = (dispatch) => {
  return {
    load: (e) => {
      dispatch(notificationActions.fetchNotificationProfiles())
    },
    navigateToEdit: (id) => {
      dispatch(notificationActions.navigateToEditNotificationProfile(id))
    },
    onDelete: (id) => {
      dispatch(notificationActions.deleteNotificationProfile(id))
    },
    navigateToList: () => {
      dispatch(notificationActions.navigateToNotificationProfilesList)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    notificationProfiles: state.notifications.notificationProfiles || {},
    status: state.notifications.status,
    error: state.notifications.error
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationProfilesList)
