import { connect } from 'react-redux'

import { commonActions } from '../../common/commonActions'
import Login from '../components/login'

const mapDispatchToProps = (dispatch) => {
  return {
    nextHandler: (e, credentials) => {
      e.preventDefault()
      dispatch(commonActions.validateUser(credentials))
    }
  }
}

const mapStateToProps = state => {
  return {
    validationErrors: state.common.validationErrors,
    error: state.common.error,
    credentials: state.common.credentials || {},
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
