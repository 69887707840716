export function validate(obj = {}, validations = {}) {
  for (const key in validations) {
    const rules = validations[key]
    if (rules.required) {
      if (!obj[key] || obj[key] === '') {
        return false
      }
    }
    if (rules.minLength) {
      if (!obj[key] || obj[key].length < rules.minLength) {
        return false
      }
    }
  }

  return true
}
