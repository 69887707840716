import React, { Component } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import _ from 'lodash'

require('react-bootstrap-typeahead/css/Typeahead.css')

export default class TypeAheadSelect extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value || '' }
    this._handleChange = this._handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value || '' })
    }
  }

  _handleChange(values) {
    const value = this.props.multiple ? values.map(v => v.value) : values
    const changedSingle = !this.props.multiple && value[0] && value[0].value !== this.props.value
    const changedMultiple = this.props.multiple && !_.isEqual(value, this.props.value)

    if (changedSingle || changedMultiple) {
      this.props.onChange(value)
      this.setState({ value })
    }
  }

  render() {
    const containerClasses = this.props.className || undefined
    const name = this.props.name
    const value = this.state.value || null
    const options = this.props.options || []
    const selectedValue = value ? options.filter(opt => value.includes(opt.value)) : []

    return (
      <div className={containerClasses}>
        <Typeahead inputProps={{ name }}
                   options={options}
                   multiple={this.props.multiple}
                   value={value}
                   selected={selectedValue}
                   onChange={this._handleChange}
                   disabled={this.props.disabled || false}
                   ref='input'
        />
      </div>
    )
  }
}
