import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { commonActions } from '../../common/commonActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import GroupForm from '../components/GroupForm'
import { groupActions } from '../groupActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      load: () => {
        const { id, auditId } = ownProps.match.params
        if (id) {
          dispatch(groupActions.fetchGroup(id))
          dispatch(groupActions.fetchContents(id))
        } else {
          dispatch(groupActions.newGroup())
        }
        dispatch(commonActions.fetchPreferenceCategories())
        if (auditId) {
          dispatch(auditActions.fetchAudit(auditId))
        }
      },
      save: (group) => {
        return dispatch(groupActions.saveGroup(group))
      },
      updateContents: (contents) => {
        return dispatch(groupActions.updateContents(ownProps.match.params.id, contents))
      },
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/groups/${id}/audits/${audit.id}`))
      },
      submit: (group) => {
        dispatch(groupActions.submitGroup(group))
      },
      reject: (group) => {
        dispatch(groupActions.rejectGroup(group))
      },
      publish: (group) => {
        dispatch(groupActions.publishGroup(group))
      },
      pause: (group) => {
        dispatch(groupActions.pauseGroup(group))
      },
      resume: (group) => {
        dispatch(groupActions.resumeGroup(group))
      },
      edit: (group) => {
        dispatch(groupActions.editGroup(group))
      },
      navigateToNewOurPicksTrigger: (group) => {
        let index = 0
        if (group.schedule && group.schedule.triggers) {
          index = group.schedule.triggers.length
        }
        dispatch(groupActions.navigateToOurPicksScheduler(group.id, index))
      },
      navigateToNewAlternateTitle: (group) => {
        let index = 0
        if (group.schedule && group.schedule.alternateTitles) {
          index = group.schedule.alternateTitles.length
        }
        dispatch(groupActions.navigateToNewAlternateTitle(group.id, index))
      },
      navigateToGroupAlternateTitle: (groupProps) => {
        dispatch(groupActions.navigateToGroupAlternateTitle(groupProps))
      },
      navigateToEditOurPicksTrigger: (groupId, index) => {
        dispatch(groupActions.navigateToOurPicksScheduler(groupId, index))
      }
    }
  )
}

const mapStateToProps = (state) => {
  return {
    group: state.groups.group,
    status: state.groups.status,
    error: state.groups.error,
    contentList: state.groups.contentList,
    roles: state.common.roles,
    audits: state.audits,
    preferenceCategories: state.common.preferenceCategories
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupForm)
