import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { commonActions } from '../../common/commonActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import MusicBannerForm from '../components/MusicBannerForm'
import { musicBannerActions } from '../musicBannerActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/musicBanners/${id}/audits/${audit.id}`))
      },
      load: () => {
        const { id, auditId } = ownProps.match.params
        if (id) {
          dispatch(musicBannerActions.fetchMusicBanner(id))
        } else {
          dispatch(musicBannerActions.newMusicBanner())
        }
        dispatch(commonActions.fetchPreferenceCategories())
        if (auditId) {
          dispatch(auditActions.fetchAudit(auditId))
        }
      },
      navigateToNewOurPicksTrigger: (musicBanner) => {
        let index = 0
        if (musicBanner.schedule && musicBanner.schedule.triggers) {
          index = musicBanner.schedule.triggers.length
        }
        dispatch(musicBannerActions.navigateToOurPicksScheduler(musicBanner.id, index))
      },
      navigateToEditOurPicksTrigger: (bannerId, index) => {
        dispatch(musicBannerActions.navigateToOurPicksScheduler(bannerId, index))
      },
      submit: (musicBanner) => {
        dispatch(musicBannerActions.submitMusicBanner(musicBanner))
      },
      reject: (musicBanner) => {
        dispatch(musicBannerActions.rejectMusicBanner(musicBanner))
      },
      publish: (musicBanner) => {
        dispatch(musicBannerActions.publishMusicBanner(musicBanner))
      },
      pause: (musicBanner) => {
        dispatch(musicBannerActions.pauseMusicBanner(musicBanner))
      },
      resume: (musicBanner) => {
        dispatch(musicBannerActions.resumeMusicBanner(musicBanner))
      },
      edit: (musicBanner) => {
        dispatch(musicBannerActions.editMusicBanner(musicBanner))
      },
      save: (musicBanner) => {
        return dispatch(musicBannerActions.saveMusicBanner(musicBanner))
      },
      navigateToSchedule: () => {
        dispatch(musicBannerActions.navigateToSchedule(ownProps.match.params.id))
      }
    }
  )
}

const mapStateToProps = (state) => {
  return {
    musicBanner: state.musicBanners.musicBanner || {},
    status: state.musicBanners.status,
    error: state.musicBanners.error,
    roles: state.common.roles,
    audits: state.audits,
    calendarContent: state.musicBanners.calendarContent,
    preferenceCategories: state.common.preferenceCategories
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MusicBannerForm)
