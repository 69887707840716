import React, { Component } from 'react'
import { Table } from 'react-bootstrap'
import Moment from 'moment'
import { formatDate, formatDateTime } from '../utils'
import classNames from 'classnames'
import Label from '../form/label'
import ValidationIndicator from './validationIndicator'

export default class AlternateTitlesTable extends Component {
  isValidAlternateTitle(index) {
    return this.props.error && this.props.error.schedule && this.props.error.schedule[`alternateTitles[${index}]`] ? 'INVALID' : 'VALID'
  }

  render() {
    const alternateTitleContent = this.props.alternateTitleContent || {}
    const formDisabled = this.props.formDisabled

    const validationError = this.props.error.schedule ? (
      <div>
        <Label className={classNames('input-error text-danger')}>
          {this.props.error.schedule.alternateTitles ? this.props.error.schedule.alternateTitles.toString() : ''}
        </Label>
      </div>
    ) : null

    if (alternateTitleContent.length > 0) {
      return (
        <div className='alternate-list'>
          {validationError}
          <Table id='alternate-titles-table' striped hover>
            <thead>
            <tr>
              <th>Valid</th>
              <th>Title</th>
              <th>Subtitle</th>
              {window.location.href.includes('offers') ? (
                <th>Short Title</th>
              ) : null}
              <th>From Date</th>
              <th>To Date</th>
              <th>Action</th>
              <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            {
              alternateTitleContent.map((alternateContentItem, key) => {
                const { title, subtitle, visibleFromDateTime, visibleToDateTime } = alternateContentItem
                return (
                  <tr className='cursor-pointer' key={key}>
                    <td className={this.isValidAlternateTitle(key) === 'VALID' ? 'text-good' : 'text-danger'}><ValidationIndicator status={this.isValidAlternateTitle(key)} /></td>
                    <td style={{ maxWidth: '120px', wordWrap: 'break-word' }}>{title}</td>
                    <td style={{ maxWidth: '120px', wordWrap: 'break-word' }}>{subtitle}</td>
                    {window.location.href.includes('offers') ? (
                      <td style={{ maxWidth: '120px', wordWrap: 'break-word' }}>{alternateContentItem.shortTitle}</td>
                    ) : null}
                    <td>{formatDateTime(visibleFromDateTime)}</td>
                    <td>{formatDateTime(visibleToDateTime)}</td>
                    <td>
                      <a className={formDisabled ? 'link disabled' : 'link'} id='delete-link' onClick={() => this.props.delete(key)} style={{ marginRight: '10px' }}>Delete</a>
                      <a className={formDisabled ? 'link disabled' : 'link'} id='edit-link' onClick={() => this.props.edit(key)}>Edit</a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </div>
      )
    } else {
      return (<p>No alternate titles to show</p>)
    }
  }
}
