import React, { Component } from 'react'
import { Alert, Glyphicon, Panel, Button } from 'react-bootstrap'
import { formatDateTime, formatNumber } from '../../common/utils'
import UploadMsisdnModal from './UploadMsisdnModal'
import ProcessMerkleModal from './ProcessMerkleModal'

class CustomersPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showSearchResult: false,
      uploadMsisdnModalOpen: false,
      processMerkleModalOpen: false
    }
  }

  keyDownHandler(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.props.searchMsisdn(e.target.value)
      this.setState({ showSearchResult: true })
    }
  }

  render() {
    const manifest = this.props.manifest
    const roles = this.props.roles
    const searchResult = this.props.searchResult

    return (
      <div>
        <Panel header="Customers Search" id="customersSearch">
          {roles.includes('ADMIN') ? (
            <div>
              <input id="searchMsisdn"
                     placeholder="447..."
                     onKeyPress={e => this.keyDownHandler(e)}
              />
            </div>
          ) : null}
          <p/>
          {!this.state.showSearchResult || searchResult === null ? '' : (
            <div>
              {searchResult.status === 'NOT_FOUND' ? (
                <Alert bsStyle={searchResult.style}>
                  <b>MSISDN is not associated with this Thank You</b>
                </Alert>
              ) : (
                <Alert bsStyle={searchResult.style}>
                  <b>MSISDN is associated with this Thank You</b><br/>
                  <b>Date & Time: {searchResult.result.insertionDate}</b><br/>
                  <b>File Name: {searchResult.result.manifestFilename}</b><br/>
                  <b>Status: {searchResult.result.status}</b>
                </Alert>
              )}
            </div>
          )}
        </Panel>

        <Panel header="Customers" id="customers">
          {roles.includes('ADMIN') ? (
            <div>
              <Button id="upload-msisdn-btn"
                      bsStyle="primary"
                      onClick={() => this.setState({ uploadMsisdnModalOpen: true })}
              >
                Upload Msisdns
              </Button>
              <Button id="process-merkle-file-btn"
                      style={{ marginLeft: '25px' }}
                      bsStyle="primary"
                      onClick={() => this.setState({ processMerkleModalOpen: true })}
              >
                Process Merkle Files
              </Button>
              <p/>
            </div>
          ) : null}
          <p>
            <strong>Upload History</strong>
          </p>
          {manifest === null ? (
            <Alert bsStyle="info">
              <Glyphicon glyph="info-sign"/>
              {' No manifest files have been uploaded yet.'}
            </Alert>
          ) : (
            <div>
              {manifest.manifests.map((manifest, index) => {
                const newUpload = !!(manifest.uploadDateTime)
                if (manifest.status === 'FAILED') {
                  return (
                    <Alert bsStyle={'danger'} key={`manifest-${index}`}>
                      <Glyphicon glyph={'exclamation-sign'}/>
                      <span>
                          {' There was an error processing'} <b>{manifest.name}</b>
                        {newUpload
                          ? <React.Fragment>
                            {' due to'} <b>{manifest.additionalInfo}</b> at <b>{formatDateTime(manifest.uploadDateTime)}</b>
                          </React.Fragment>
                          : null}
                        </span>
                    </Alert>
                  )
                } else if (manifest.status === 'SUCCESS') {
                  return (
                    <Alert bsStyle={'success'} key={`manifest-${index}`}>
                      <Glyphicon glyph={'ok-sign'}/>
                      <span>
                          {' Successfully processed'} <b>{manifest.name}</b>
                        {newUpload
                          ? <React.Fragment>
                            {' at'} <b>{formatDateTime(manifest.uploadDateTime)}</b> with <b>{formatNumber(manifest.processedEntries)}</b> processed entries and <b>{formatNumber(
                            manifest.duplicateEntries)}</b> duplicated entries
                          </React.Fragment>
                          : <React.Fragment>
                            {' with'} <b>{formatNumber(manifest.processedEntries)}</b> processed entries
                          </React.Fragment>}
                        </span>
                    </Alert>
                  )
                } else {
                  return (
                    <Alert bsStyle={'info'} key={`manifest-${index}`}>
                      <Glyphicon glyph={'info-sign'}/>
                      <span>
                          {' File'} <b>{manifest.name}</b>
                        {newUpload
                          ? <React.Fragment>
                            {' uploaded at'} <b>{formatDateTime(manifest.uploadDateTime)}</b>
                          </React.Fragment>
                          : ''}
                        {' is being processed'}
                        </span>
                    </Alert>
                  )
                }
              })}
            </div>
          )}
        </Panel>
        <UploadMsisdnModal visible={this.state.uploadMsisdnModalOpen}
                           onSave={(file) => this.props.uploadMsisdns(file)}
                           onClose={() => this.setState({ uploadMsisdnModalOpen: false })}
                           manifest={manifest}
        />
        {
          //conditionally rendering the modal to avoid fetching of files when not clicked on button
          this.state.processMerkleModalOpen &&
          (<ProcessMerkleModal visible={this.state.processMerkleModalOpen}
                               onSubmitFileForProcessing={(fileName) => this.props.onSubmitFileForProcessing(fileName)}
                               onClose={() => this.setState({ processMerkleModalOpen: false })}
                               s3Files={this.props.s3Files}
                               listS3Files={this.props.listS3Files}
          />)
        }
      </div>
    )
  }
}

export default CustomersPanel
