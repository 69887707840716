import React, { Component } from 'react'
import { Checkbox } from 'react-bootstrap'

class CheckBoxRadioList extends Component {
  onChange(e, option) {
    let value = option.value
    if (this.props.value === option.value) {
      value = undefined
    }

    this.props.onChange(value)
  }

  render() {
    const disabledOptions = this.props.disabledOptions || []

    return (
      <div>
        {this.props.options.map((option, index) => {
          const disabled = this.props.disabled || disabledOptions.indexOf(option.value) > -1

          return (
            <Checkbox key={`check-${index}`} value={option.value} inline
                      checked={this.props.value === option.value}
                      disabled={disabled}
                      onChange={(e) => this.onChange(e, option)}
            >
              {option.label}
            </Checkbox>
          )
        })}
      </div>
    )
  }
}

export default CheckBoxRadioList
