import { constants } from './campaignActions'

export default function campaigns(state = {}, action = {}) {
  switch (action.type) {
    case constants.CAMPAIGN_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.CAMPAIGN_SAVING:
      return Object.assign({}, state, {
        campaign: action.campaign,
        status: 'SAVING'
      })
    case constants.CAMPAIGN_NEW:
      return Object.assign({}, state, {
        campaign: action.campaign,
        relatedContent: [],
        status: 'SUCCESS',
        error: null
      })
    case constants.CAMPAIGN_FETCHED:
    case constants.CAMPAIGN_SAVED:
    case constants.CAMPAIGN_SUBMITTED:
    case constants.CAMPAIGN_REJECTED:
    case constants.CAMPAIGN_PUBLISHED:
    case constants.CAMPAIGN_PAUSED:
    case constants.CAMPAIGN_RESUMED:
    case constants.CAMPAIGN_EDITED:
      return Object.assign({}, state, {
        campaign: action.campaign,
        status: 'SUCCESS',
        error: null
      })
    case constants.CAMPAIGN_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    case constants.CAMPAIGN_CALENDAR_CONTENT_FETCHED:
      return Object.assign({}, state, {
        calendarContent: action.calendarContent
      })
    default:
      return state
  }
}
