import React, { Component } from 'react'
import Spinner from '../../common/components/spinner'
import Constants from '../../appConstants'
import AppError from '../../common/components/appError'
import Layout from './preferenceCategoryLayout'
import DataTable from '../../common/components/dataTable'
import { Col, Panel, Row } from 'react-bootstrap'
import PreferenceCategoryBreadcrumbs from './preferenceCategoryBreadcrumbs'

export default class ManagePreferenceCategories extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saveOrDiscardModalVisible: false
    }

    props.load()
  }

  render() {
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }
    let error = <div />
    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }

    var headers = {
      name: { name: 'Name', sortable: true },
      active: { name: 'Active' },
      actions: { name: 'Actions' }
    }
    let table = <div id='no-data'>No Preference categories to display</div>
    if (this.props.preferenceCategories && this.props.preferenceCategories.length > 0) {
      const tableRows = this.props.preferenceCategories.map(categoryIterator => {
        return {
          name: categoryIterator.name,
          active: <input type='checkbox' checked={categoryIterator.active} disabled readOnly />,
          actions: (
            <span>
              <a className='link' onClick={(e) => this.props.navigateToEdit(categoryIterator.id)}>Edit</a>
            </span>
          )
        }
      })
      table = <DataTable displayPageNo={Constants.DEFAULT_DISPLAY_PAGE_NO} rowsPerPage={Constants.DEFAULT_ROWS_PER_PAGE} headers={headers} data={tableRows} wrapperClassName='preference-categories-table' />
    }
    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <PreferenceCategoryBreadcrumbs match={this.props.match} />
          </Col>
          <Col sm={8} lg={9}>
            {error}
            <Panel header='Preference Categories'>
              <div id='preference-category-listing-panel'>
                {table}
              </div>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
