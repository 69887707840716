import { push } from 'connected-react-router'
import http, { config, FAQ_DETAIL_V1, FAQ_LIST_V1, FAQ_SECTION_DETAIL_V1, FAQ_SECTION_LIST_V1 } from '../services/http'

import { commonActions } from '../common/commonActions'

const navigateToCreateNewSection = () => {
  return push('/faq/createSection')
}

const navigateToFaqSectionListing = () => {
  return push('/faq')
}

const navigateToEditFaqSection = (faqSectionId) => {
  return push(`/faq/${faqSectionId}/edit`)
}

const navigateToCreateNewFaq = (faqSectionId) => {
  return push(`/faq/${faqSectionId}/faq/create`)
}

const navigateToFaqListing = (faqSectionId) => {
  return push(`/faq/${faqSectionId}/faqs`)
}

const navigateToEditFaq = (faqSectionId, faqId) => {
  return push(`/faq/${faqSectionId}/faq/${faqId}/edit`)
}

const faqSectionsFetching = () => ({
  type: constants.FAQ_SECTIONS_FETCHING
})

const faqSectionsFetched = (faqSections) => ({
  type: constants.FAQ_SECTIONS_FETCHED,
  faqSections
})

const faqSectionFetching = () => ({
  type: constants.FAQ_SECTION_FETCHING
})

const faqSectionFetched = (faqSection) => ({
  type: constants.FAQ_SECTION_FETCHED,
  faqSection
})

const faqSectionNew = () => ({
  type: constants.FAQ_SECTION_NEW,
  faqSection: { faqs: [], rank: 0 }
})

const faqSectionSaving = () => ({
  type: constants.FAQ_SECTION_SAVING
})

const faqSectionCreated = (faqSection) => ({
  type: constants.FAQ_SECTION_CREATED,
  faqSection
})

const faqSectionUpdated = (faqSection) => ({
  type: constants.FAQ_SECTION_UPDATED,
  faqSection
})

const faqSectionUpdateError = (validationErrors) => ({
  type: constants.FAQ_SECTION_UPDATE_ERROR,
  validationErrors
})

const faqSectionError = (error) => ({
  type: constants.FAQ_SECTION_ERROR,
  error
})

const faqsFetching = () => ({
  type: constants.FAQS_FETCHING
})

const faqsFetched = (faqs) => ({
  type: constants.FAQS_FETCHED,
  faqs
})

const faqFetching = () => ({
  type: constants.FAQ_FETCHING
})

const faqFetched = (faq) => ({
  type: constants.FAQ_FETCHED,
  faq
})

const faqNew = () => ({
  type: constants.FAQ_NEW,
  faq: {}
})

const faqSaving = () => ({
  type: constants.FAQ_SAVING
})

const faqCreated = (faq) => ({
  type: constants.FAQ_CREATED,
  faq
})

const faqUpdated = (faq) => ({
  type: constants.FAQ_UPDATED,
  faq
})

const faqUpdateError = (validationErrors) => ({
  type: constants.FAQ_UPDATE_ERROR,
  validationErrors
})

const faqError = (error) => ({
  type: constants.FAQ_ERROR,
  error
})

const faqsSortOrderSaved = (faqs) => ({
  type: constants.FAQS_SORT_ORDER_SAVED,
  faqs
})

const faqsSortOrderSaving = (faqs) => ({
  type: constants.FAQS_SORT_ORDER_SAVING,
  faqs,
  error: '',
  status: 'LOADING'
})

const handleFaqSectionErrors = (dispatch) => {
  return (response) => {
    dispatch(commonActions.clearFlash())
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(faqSectionUpdateError(json.validationErrors))
    } else {
      if (response.body) {
        dispatch(faqSectionError(response.body))
      } else {
        dispatch(faqSectionError(response.statusText))
      }
    }
  }
}

const handleFaqErrors = (dispatch) => {
  return (response) => {
    dispatch(commonActions.clearFlash())
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(faqUpdateError(json.validationErrors))
    } else {
      if (response.body) {
        dispatch(faqError(response.body))
      } else {
        dispatch(faqError(response.statusText))
      }
    }
  }
}

const fetchFaqSections = () => {
  return (dispatch) => {
    dispatch(faqSectionsFetching())
    return http.get(dispatch, `${config.serverUrl}/faq/section`, null, {
      Accept: FAQ_SECTION_LIST_V1
    }).then((faqSectionList) => {
      dispatch(faqSectionsFetched(faqSectionList))
    }).catch((err) => {
      dispatch(faqSectionError(`Unable to retrieve the list of FAQ Sections: ${err.status} (${err.statusText})`))
    })
  }
}

const fetchFaqSection = (faqSectionId) => {
  return (dispatch) => {
    dispatch(faqSectionFetching())
    return http.get(dispatch, `${config.serverUrl}/faq/section/${faqSectionId}`, null, {
      Accept: FAQ_SECTION_DETAIL_V1
    }).then((faqSection) => {
      dispatch(faqSectionFetched(faqSection))
    }).catch(handleFaqSectionErrors(dispatch))
  }
}

const createFaqSection = (payload) => {
  return (dispatch) => {
    dispatch(faqSectionSaving())
    return http.post(dispatch, `${config.serverUrl}/faq/section`, payload, {
      'Content-Type': FAQ_SECTION_DETAIL_V1,
      Accept: FAQ_SECTION_DETAIL_V1
    }).then((response) => {
      dispatch(faqSectionCreated(response))
      dispatch(navigateToEditFaqSection(response.id))
      dispatch(commonActions.displayFlash('FAQ Section Created Successfully.'))
    }).catch(handleFaqSectionErrors(dispatch))
  }
}

const updateFaqSection = (faqSectionId, faqSectionPayload) => {
  return (dispatch) => {
    dispatch(faqSectionSaving())
    return http.put(dispatch, `${config.serverUrl}/faq/section/${faqSectionId}`, faqSectionPayload, {
      'Content-Type': FAQ_SECTION_DETAIL_V1,
      Accept: FAQ_SECTION_DETAIL_V1
    }).then((response) => {
      dispatch(faqSectionUpdated(response))
      dispatch(commonActions.displayFlash('FAQ Section Updated Successfully.'))
    }).catch(handleFaqSectionErrors(dispatch))
  }
}

const fetchFaqs = (sectionId) => {
  return (dispatch) => {
    dispatch(faqsFetching())
    return http.get(dispatch, `${config.serverUrl}/faq/section/${sectionId}/faqs`, null, {
      Accept: FAQ_LIST_V1
    }).then((faqsList) => {
      dispatch(faqsFetched(faqsList))
    }).catch((err) => {
      dispatch(faqError(`Unable to retrieve the list of FAQs: ${err.status} (${err.statusText})`))
    })
  }
}

const fetchFaq = (faqSectionId, faqId) => {
  return (dispatch) => {
    dispatch(faqFetching())
    return http.get(dispatch, `${config.serverUrl}/faq/section/${faqSectionId}/faqs/${faqId}`, null, {
      Accept: FAQ_DETAIL_V1
    }).then((faq) => {
      dispatch(faqFetched(faq))
    }).catch(handleFaqErrors(dispatch))
  }
}

const createFaq = (faqSectionId, payload) => {
  return (dispatch) => {
    dispatch(faqSaving())
    return http.post(dispatch, `${config.serverUrl}/faq/section/${faqSectionId}/faqs`, payload, {
      'Content-Type': FAQ_DETAIL_V1,
      Accept: FAQ_DETAIL_V1
    }).then(() => {
      dispatch(navigateToFaqListing(faqSectionId))
      dispatch(faqCreated(payload)) // TODO Here it might be response
    }).catch(handleFaqErrors(dispatch))
  }
}

const updateFaq = (faqSectionId, faqPayload, faqId) => {
  return (dispatch) => {
    dispatch(faqSaving())
    return http.put(dispatch, `${config.serverUrl}/faq/section/${faqSectionId}/faqs/${faqId}`, faqPayload, {
      Accept: FAQ_DETAIL_V1,
      'Content-Type': FAQ_DETAIL_V1
    }).then(() => {
      dispatch(navigateToFaqListing(faqSectionId))
      dispatch(faqUpdated(faqPayload))
    }).catch(handleFaqErrors(dispatch))
  }
}

const saveFaqsSortOrder = (faqs, faqSectionId) => {
  return (dispatch) => {
    dispatch(faqsSortOrderSaving(faqs))
    return http.put(dispatch, `${config.serverUrl}/faq/section/${faqSectionId}/faqs`, faqs, {
      'Content-Type': FAQ_LIST_V1,
      Accept: FAQ_LIST_V1
    }).then((response) => {
      dispatch(faqsSortOrderSaved(response))
    }).catch(handleFaqErrors(dispatch))
  }
}

const updateFaqSectionRanks = (rankedIds, faqSections) => {
  return (dispatch) => {
    const url = `${config.serverUrl}/faq/section/ranking`
    return http.put(dispatch, url, rankedIds, {
      Accept: FAQ_SECTION_LIST_V1
    }).then((faqSectionList) => {
      dispatch(faqSectionsFetched(faqSectionList))
    }).catch(handleFaqSectionErrors(dispatch))
  }
}

export const constants = {
  FAQ_SECTIONS_FETCHING: 'FAQ_SECTIONS_FETCHING',
  FAQ_SECTIONS_FETCHED: 'FAQ_SECTIONS_FETCHED',

  FAQ_SECTION_FETCHING: 'FAQ_SECTION_FETCHING',
  FAQ_SECTION_FETCHED: 'FAQ_SECTION_FETCHED',
  FAQ_SECTION_NEW: 'FAQ_SECTION_NEW',
  FAQ_SECTION_SAVING: 'FAQ_SECTION_SAVING',
  FAQ_SECTION_CREATED: 'FAQ_SECTION_CREATED',
  FAQ_SECTION_UPDATED: 'FAQ_SECTION_UPDATED',
  FAQ_SECTION_UPDATE_ERROR: 'FAQ_SECTION_UPDATE_ERROR',
  FAQ_SECTION_ERROR: 'FAQ_SECTION_ERROR',

  FAQS_FETCHING: 'FAQS_FETCHING',
  FAQS_FETCHED: 'FAQS_FETCHED',

  FAQ_FETCHING: 'FAQ_FETCHING',
  FAQ_FETCHED: 'FAQ_FETCHED',
  FAQ_NEW: 'FAQ_NEW',
  FAQ_SAVING: 'FAQ_SAVING',
  FAQ_CREATED: 'FAQ_CREATED',
  FAQ_UPDATED: 'FAQ_UPDATED',
  FAQ_UPDATE_ERROR: 'FAQ_UPDATE_ERROR',
  FAQ_ERROR: 'FAQ_ERROR',

  FAQS_SORT_ORDER_SAVED: 'FAQS_SORT_ORDER_SAVED',
  FAQS_SORT_ORDER_SAVING: 'FAQS_SORT_ORDER_SAVING'
}

export const faqActions = {
  navigateToCreateNewSection,
  navigateToFaqSectionListing,
  navigateToEditFaqSection,
  navigateToCreateNewFaq,
  navigateToFaqListing,
  navigateToEditFaq,

  fetchFaqSections,
  faqSectionsFetching,
  faqSectionsFetched,
  fetchFaqSection,
  faqSectionFetching,
  faqSectionFetched,
  faqSectionNew,
  createFaqSection,
  updateFaqSection,
  faqSectionSaving,
  faqSectionCreated,
  faqSectionUpdated,

  fetchFaqs,
  faqsFetching,
  faqsFetched,
  fetchFaq,
  faqFetching,
  faqFetched,
  faqNew,
  createFaq,
  updateFaq,
  faqSaving,
  faqCreated,
  faqUpdated,

  saveFaqsSortOrder,
  faqsSortOrderSaved,
  faqsSortOrderSaving,
  updateFaqSectionRanks
}
