import { connect } from 'react-redux'

import { welcomePosterActions } from '../welcomePosterActions'
import EditWelcomePoster from '../components/editWelcomePosters'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      nextHandler: (e, welcomePosterDetails) => {
        e.preventDefault()
        dispatch(welcomePosterActions.updateWelcomePoster(welcomePosterDetails))
      },
      backHandler: e => {
        if (e) {
          e.preventDefault()
        }
        dispatch(welcomePosterActions.navigateToManageWelcomePosters())
      }
    }
  )
}

const mapStateToProps = state => {
  return {
    validationErrors: state.welcomePosters.validationErrors,
    error: state.welcomePosters.error,
    welcomePoster: state.welcomePosters.clickedWelcomePoster,
    roles: state.common.roles,
    profile: state.common.profile,
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditWelcomePoster)
