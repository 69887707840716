import { connect } from 'react-redux'

import ManageMsisdn from '../components/manageMsisdn'
import { msisdnActions } from '../msisdnActions'

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMsisdnsHandler: () => {
      dispatch(msisdnActions.fetchMsisdns())
    },
    onDelete: (msisdn) => {
      dispatch(msisdnActions.deleteMsisdn(msisdn))
    },
    navigateToEdit: (msisdn) => {
      dispatch(msisdnActions.navigateToEditMsisdn(msisdn))
    },
    navigateToManageMsisdn: () => {
      dispatch(msisdnActions.navigateToManageMsisdn)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    msisdns: state.msisdns.msisdns || {},
    status: state.msisdns.status,
    error: state.msisdns.error
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageMsisdn)
