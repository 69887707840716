import React, { Component } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'

export default class Form extends Component {
  update(property) {
    return (e) => {
      this.model[property] = e.target.value
    }
  }

  render() {
    const nextButtonText = this.props.nextButtonText || 'Next'
    const buttonBackText = this.props.backButtonText || 'Back'
    const linkText = this.props.linkText || 'Click here'

    const nextHandler = this.props.nextHandler
    const backHandler = this.props.backHandler
    const linkUrl = this.props.linkUrl

    return (
      <form role='form'>
        {this.props.children}
        <ButtonToolbar>
          {this.props.backHandler ? (
            <Button id='back-button'
                    onClick={backHandler}
            >
              {buttonBackText}
            </Button>
          ) : null}
          <div className='btn-tool-bar-left'>
            <Button id='next-button'
                    type='submit'
                    onClick={nextHandler}
                    bsStyle='primary'
                    disabled={this.props.nextButtonDisabled || false}
            >
              {nextButtonText}
            </Button>
          </div>
          {this.props.linkUrl ? (
            <div className='btn-tool-bar-right'>
              <a id='form-link'
                 href={linkUrl}
                 className='page-header--state-label link'
                 disabled={this.props.linkDisabled || false}
              >{linkText}</a></div>
          ) : null}
        </ButtonToolbar>
      </form>
    )
  }
}
