import React, { Component } from 'react'

import { Panel } from 'react-bootstrap'
import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import AppFlash from '../../common/components/appFlash'
import Spinner from '../../common/components/spinner'
import _ from 'lodash'

export default class FeaturedContentsTitleForm extends Component {
  constructor(props) {
    super(props)
    this.model = props.featuredContentsTitle
    this.updateField = this.updateField.bind(this)
    props.load()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.featuredContentsTitle, this.props.featuredContentsTitle)) {
      this.model = _.cloneDeep(this.props.featuredContentsTitle || {})
      this.forceUpdate()
    }
  }

  updateField(path, value) {
    return (e) => {
      _.set(this.model, path, e ? e.target.value : value)
      this.setState({ dirty: true })
    }
  }

  render() {
    const validationErrors = this.props.validationErrors
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>Featured Contents Title</h1>
          </div>
        </div>
        <div style={{ maxWidth: '600px' }}>
          {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
          <Panel header='Featured Contents Title'>
            <Form nextButtonText='Save'
                  nextHandler={(e) => {
                    this.props.save(e, this.model)
                  }}
                  backButtonText='Back'
                  backHandler={(e) => {
                    this.props.back(e)
                  }}
            >
              <FormField label='Title'
                         id='title'
                         type='text' maxLength='30'
                         value={this.model.title}
                         error={validationErrors.title}
                         onChange={this.updateField('title')}
              />
            </Form>
          </Panel>
        </div>
      </div>
    )
  }
}
