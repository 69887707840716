import React, { Component } from 'react'

export default class FileInput extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value || '' }
    this._handleChange = this._handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value || '' })
    }
  }

  _handleChange(e) {
    this.props.onChange(e)
    this.setState({ value: e.target.value })
    if(!this.props.showFileName){
      this.refs.input.value = null
    }
  }

  clear(e) {
    this.refs.input.value = null
    this.props.onClear(e)
  }

  render() {
    const containerClasses = this.props.className || undefined
    const type = 'file'
    const id = this.props.id
    const placeholder = this.props.placeholder || ''

    const element = (
      <input type={type} id={id} name={id}
             accept={this.props.acceptFileTypes}
             className='form-control'
             ref='input'
             min={this.props.min} max={this.props.max}
             onError={this._handleError}
             onChange={this._handleChange}
             disabled={this.props.disabled || false}
             placeholder={placeholder}
             style={this.props.style}
      />
    )

    return (
      <div className={containerClasses}>
        {this.props.value && !this.props.disabled ? (
          <a className='file-input-remove link' onClick={e => this.clear(e)}>Remove</a>
        ) : null}
        {element}
        {this.props.iconClassName ? (<span className={'file-component-icon glyphicon ' + this.props.iconClassName}>&nbsp;</span>) : null}
      </div>
    )
  }
}
