import React, { Component } from 'react'
import { Button, Panel } from 'react-bootstrap'
import AlternateTitlesTable from '../../common/components/AlternateTitlesTable'
import DeleteOrDiscardModal from '../../common/components/deleteOrDiscardModal'
import FormField from '../../common/form/formField'

class SchedulePanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      deleteOrDiscardModalVisible: false
    }

    this.editAlternateTitleEntry = this.editAlternateTitleEntry.bind(this)
    this.onConfirmationDeleteAlternateTitle = this.onConfirmationDeleteAlternateTitle.bind(this)
    this.navigateToCampaignAlternateTitleForm = this.navigateToCampaignAlternateTitleForm.bind(this)
  }

  navigateToCampaignAlternateTitleForm(props) {
    props.navigateToCampaignAlternateTitle(props)
  }

  editAlternateTitleEntry(id) {
    this.props.model.alternateTitleCampaignId = id
    this.props.navigateToCampaignAlternateTitle(this.props)
  }

  onConfirmationDeleteAlternateTitle(key) {
    this.showDeleteOrDiscard()
    this.setState({ indexToBeDeleted: key })
  }

  showDeleteOrDiscard() {
    this.setState({ deleteOrDiscardModalVisible: true })
  }

  onDelete(indexToBeDeleted) {
    this.model = this.props.model
    this.model.schedule.alternateTitles.splice(indexToBeDeleted, 1)
    this.props.saveCampaign(this.model)
  }

  render() {
    const schedule = this.props.model.schedule || {}
    const formDisabled = this.props.formDisabled
    const validationErrors = this.props.validationErrors
    const updateField = this.props.updateField

    return (
      <div>
        <Panel header='Schedule' id='schedule'>
          <FormField label='Visible from' type='datetime' id='display-from'
                     mandatory
                     value={schedule.displayFromDateTime || ''}
                     onChange={(value) => updateField('schedule.displayFromDateTime', value)()}
                     disabled={formDisabled}
                     error={validationErrors.schedule.displayFromDateTime}
          />
          <FormField label='Visible to' type='datetime' id='display-to'
                     mandatory
                     value={schedule.displayToDateTime || ''}
                     onChange={(value) => updateField('schedule.displayToDateTime', value)()}
                     disabled={formDisabled}
                     error={validationErrors.schedule.displayToDateTime}
          />
        </Panel>

        <Panel header='Alternate Titles' id='campaign-alternate-titles-table'>
          <Button disabled={formDisabled}
                  bsStyle='primary'
                  id='create-alternate'
                  onClick={() => this.props.newAlternateTitle(this.props.model)}>New Title</Button>
          <AlternateTitlesTable error={validationErrors}
                                alternateTitleContent={schedule.alternateTitles}
                                delete={this.onConfirmationDeleteAlternateTitle}
                                edit={this.editAlternateTitleEntry}
                                formDisabled={formDisabled}
          />
        </Panel>

        <DeleteOrDiscardModal message={'Do you want to delete this Alternate Title?'}
                              visible={this.state.deleteOrDiscardModalVisible}
                              onDelete={() => {
                                this.onDelete(this.state.indexToBeDeleted)
                                this.setState({ deleteOrDiscardModalVisible: false })
                              }}
                              onDiscard={() => {
                                this.setState({ deleteOrDiscardModalVisible: false })
                                this.props.navigateToSchedule(this.props.model.id)
                              }
                              }
        />
      </div>
    )
  }
}

export default SchedulePanel
