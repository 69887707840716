import React, { Component } from 'react'
import Spinner from '../../common/components/spinner'
import { Button, Col, Form, Panel, Row } from 'react-bootstrap'
import _ from 'lodash'

import FormField from '../../common/form/formField'
import Breadcrumbs from '../../brands/components/BrandBreadcrumb'
import { locationStatusOptions } from '../../common/enums'
import AppFlash from '../../common/components/appFlash'

export default class LocationForm extends Component {
  constructor(props) {
    super(props)

    this.model = _.cloneDeep(props.location || {})
    this.state = {
      dirty: false,
      geocoding: false
    }

    props.load()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.location, this.props.location)) {
      this.model = _.cloneDeep(this.props.location || {})
      this.forceUpdate()
    }
  }

  updateField(path, value) {
    return (e) => {
      _.set(this.model, path, e ? e.target.value : value)

      this.setState({ dirty: true })
    }
  }

  save() {
    this.props.save(this.model).then(() => {
      this.setState({ dirty: false })
    })
  }

  geocode() {
    const address1 = (this.model.location.addressLine1 || '')
    const city = (this.model.location.city || '')
    const postcode = (this.model.location.postcode || '').replace(/ /ig, '')
    this.setState({ geocoding: true })
    this.props.geocode(address1, city, postcode).then((location) => {
      this.model.location.latitude = location.latitude
      this.model.location.longitude = location.longitude

      this.model.validationErrors = this.model.validationErrors || {}
      if (this.model.validationErrors.postcode) {
        delete this.model.validationErrors.postcode
      }

      this.setState({ geocoding: false, dirty: true })
    }).catch(err => {
      const error = err.body
      this.model.validationErrors = this.model.validationErrors || {}
      this.model.validationErrors.postcode = error
      this.setState({ geocoding: false })
    })
  }

  navigateToSection(tab) {
    if (tab === 'back') {
      this.props.navigateToMerchant()
    } else {
      this.props.navigateToSection(tab)
    }
  }

  render() {
    const validationErrors = this.model.validationErrors || {}
    const locations = this.props.locations || []
    const location = this.model.location || {}
    const activeTab = 'locations'
    const brand = this.props.brand || {}
    const saveDisabled = !this.state.dirty
    const geocodeDisabled = this.state.geocoding || (location.postcode || '').length < 6 || (location.addressLine1 || '') === '' || (location.city || '') === ''
    const addStatus = locationStatusOptions.find(option => {
      return option.value === location.status
    }) === undefined && location.status

    if (addStatus) {
      locationStatusOptions.unshift({ label: location.status, value: location.status })
    }

    const loading = this.props.status === 'LOADING' || this.props.locationStatus === 'LOADING'
    if (loading) {
      return <Spinner />
    }

    const beacon = brand.beacon || {}

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>{brand.name}</h1>
          </div>
          <div className='page-header--tools'>
            <Button id='location-back-btn'
                    onClick={() => this.props.navigateToSection('locations')}
            >
              Cancel
            </Button>
            {' '}
            <Button id='location-save'
                    bsStyle='primary'
                    onClick={() => this.save()}
                    disabled={saveDisabled}
            >
              Save
            </Button>
          </div>
        </div>
        <Row>
          <Col sm={4} lg={3}>
            <Breadcrumbs activeTab={activeTab}
                         onNavigate={(tab) => this.navigateToSection(tab)}
                         brand={brand}
                         beacon={beacon}
                         locations={locations}
                         validationErrors={validationErrors}
            />
          </Col>
          <Col sm={8} lg={6}>
            {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
            <Panel header='Location details'>
              <Form>
                <Row>
                  <Col sm={6}>
                    <FormField label='Location Ref' id='text-location-ref' value={location.ref}
                               onChange={this.updateField('location.ref')}
                               error={validationErrors.ref}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField label='Location Status' id='text-location-status'
                               value={location.status}
                               onChange={this.updateField('location.status')}
                               type='dropdown' options={locationStatusOptions} mandatory
                               error={validationErrors.status}
                    />
                  </Col>
                </Row>
                <FormField label='Address Line 1' id='text-address-line-1'
                           value={location.addressLine1}
                           onChange={this.updateField('location.addressLine1')} mandatory
                           error={validationErrors.addressLine1}
                />
                <FormField label='Address Line 2' id='text-address-line-2'
                           value={location.addressLine2}
                           onChange={this.updateField('location.addressLine2')}
                />
                <FormField label='Address Line 3' id='text-address-line-3'
                           value={location.addressLine3}
                           onChange={this.updateField('location.addressLine3')}
                />
                <FormField label='Address Line 4' id='text-address-line-4'
                           value={location.addressLine4}
                           onChange={this.updateField('location.addressLine4')}
                />
                <Row>
                  <Col sm={6}>
                    <FormField label='City' id='text-city' value={location.city}
                               onChange={this.updateField('location.city')} mandatory
                               error={validationErrors.city}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField label='County' id='text-county' value={location.county}
                               onChange={this.updateField('location.county')}
                               error={validationErrors.county}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormField label='Postcode' id='text-postcode'
                               value={location.postcode}
                               onChange={this.updateField('location.postcode')} mandatory
                               error={validationErrors.postcode}
                    />
                  </Col>
                  <Col sm={6}>
                    <Button onClick={() => this.geocode()}
                            className='btn-block'
                            style={{ marginTop: '25px' }}
                            disabled={geocodeDisabled}
                    >
                      Geocode Latitude / Longitude
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormField label='Latitude' id='text-latitude' value={location.latitude}
                               type='number'
                               onChange={this.updateField('location.latitude')}
                               error={validationErrors.latitude}
                               disabled={this.state.geocoding}
                               mandatory
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField label='Longitude' id='text-longitude' value={location.longitude}
                               type='number'
                               onChange={this.updateField('location.longitude')}
                               error={validationErrors.longitude}
                               disabled={this.state.geocoding}
                               mandatory
                    />
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Col>
        </Row>
      </div>
    )
  }
}
