import _ from 'lodash'
import React from 'react'
import { Button, Glyphicon, Panel, Table } from 'react-bootstrap'
import ContentTypelabel from '../../common/components/contentTypeLabel'
import { SortableBody, SortableRow } from '../../common/components/sortableTable'

import StatusLabel from '../../common/components/statusLabel'
import { formatDateTime } from '../../common/utils'

const RelatedContent = ({
  relatedContentItems,
  onModify,
  onSort,
  ids,
  formDisabled,
  viewContent
}) => (
  <Panel header='Related Content' id='related-content'>
    {relatedContentItems.length > 0 ? (
      <Table striped hover>
        <thead>
        <tr>
          <th width='1' />
          <th>Type</th>
          <th>Status</th>
          <th>Brand</th>
          <th>Title</th>
          <th>Visible from</th>
          <th>Visible to</th>
        </tr>
        </thead>
        <SortableBody>
         {ids.map((identifier, index) => {
           const contentItem = relatedContentItems.find(item => item.id === identifier.id) || identifier
           const rowProps = {
             ids: ids,
             item: contentItem,
             onSort: onSort
           }

           return (
             <SortableRow key={`content-${index}`} {...rowProps} onClick={() => viewContent(identifier)} className='cursor-pointer'>
               <td>
                 <Glyphicon glyph='menu-hamburger'/>
               </td>
               <td><ContentTypelabel type={contentItem.type}/></td>
               <td><StatusLabel status={contentItem.state}/></td>
               <td>{_.get(contentItem, 'brand.name')}</td>
               <td>{_.get(contentItem, 'details.title')}</td>
               <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayFromDateTime) : null}</td>
               <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayToDateTime) : null}</td>
             </SortableRow>
           )
        })}
      </SortableBody>
      </Table>
    ) : (
      <p>There are no related items yet.</p>
    )}
    {formDisabled ? null : (
      <p>
        <Button id='modify-related-content' onClick={onModify}>Add or remove items</Button>
      </p>
    )}
  </Panel>
)

export default RelatedContent
