import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

class FaqBreadcrumbs extends Component {
  render() {
    const { match } = this.props
    const { faqSectionId } = this.props
    const { faqId } = this.props
    const path = match.path
    var details = 'faq/:faqSectionId/faq/create'
    var section = `/faq/${faqSectionId}/faqs`
    if (faqSectionId) {
      details = `/faq/${faqSectionId}/faq/create`
      section = `/faq/${faqSectionId}/faqs`
    }
    if (faqId) {
      details = `/faq/${faqSectionId}/faq/${faqId}/edit`
    }
    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <LinkContainer to={section} isActive={() => path === { section }}>
          <NavItem>
            <Glyphicon glyph='chevron-left' /> Back to FAQ Section
          </NavItem>
        </LinkContainer>
        <NavItem className='nav-item-divider' />
        <LinkContainer to={details} isActive={() => path === { details }}>
          <NavItem>Basic Details</NavItem>
        </LinkContainer>
      </Nav>
    )
  }
}

export default FaqBreadcrumbs
