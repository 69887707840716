import { push } from 'connected-react-router'

import http, { config, MSISDN_DETAIL_V1, MSISDN_LIST_V1 } from '../services/http'

const navigateToManageMsisdn = () => {
  return push('/msisdn')
}

const navigateToEditMsisdn = (msisdn) => {
  return push(`/msisdn/${msisdn}/edit`)
}

const msisdnsFetching = () => ({
  type: constants.MSISDNS_FETCHING,
  error: ''
})

const msisdnsFetched = (msisdns) => ({
  type: constants.MSISDNS_FETCHED,
  msisdns
})

const msisdnFetching = () => ({
  type: constants.MSISDN_FETCHING
})

const newMsisdn = () => ({
  type: constants.MSISDN_NEW,
  msisdn: {},
  validationErrors: {},
  error: ''
})

const savingMsisdn = () => ({
  type: constants.MSISDN_SAVING
})

const msisdnCreated = (msisdn) => ({
  type: constants.MSISDN_CREATED,
  msisdn,
  error: ''
})

const msisdnDeleted = (msisdn) => ({
  type: constants.MSISDN_DELETED
})

const msisdnFetched = (msisdn) => ({
  type: constants.MSISDN_FETCHED,
  msisdn
})

const msisdnUpdated = (msisdn) => ({
  type: constants.MSISDN_UPDATED,
  msisdn
})

const msisdnError = (error) => ({
  type: constants.MSISDN_ERROR,
  error
})

const msisdnUpdateError = (validationErrors) => ({
  type: constants.MSISDN_UPDATE_ERROR,
  validationErrors
})

const handleErrors = (dispatch) => {
  return (response) => {
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(msisdnUpdateError(json.validationErrors))
    } else {
      if (response.body) {
        dispatch(msisdnError(response.body))
      } else {
        dispatch(msisdnError(response.statusText))
      }
    }
  }
}

const fetchMsisdns = () => {
  return (dispatch) => {
    dispatch(msisdnsFetching())
    return http.get(dispatch, `${config.serverUrl}/msisdns`, null, {
      Accept: MSISDN_LIST_V1
    }).then((msisdnList) => {
      dispatch(msisdnsFetched(msisdnList))
    }).catch((err) => {
      dispatch(msisdnError(`Unable to retrieve the list of msisdns: ${err.status} (${err.statusText})`))
    })
  }
}

const fetchMsisdn = (msisdn) => {
  return (dispatch) => {
    dispatch(msisdnFetching())
    return http.get(dispatch, `${config.serverUrl}/msisdns/${msisdn}`, null, {
      Accept: MSISDN_DETAIL_V1
    })
      .then((msisdnDetail) => {
        dispatch(msisdnFetched(msisdnDetail))
      }).catch(handleErrors(dispatch))
  }
}

const createMsisdn = (payload) => {
  return (dispatch) => {
    dispatch(savingMsisdn())
    return http.post(dispatch, `${config.serverUrl}/msisdns`, payload)
      .then(() => {
        dispatch(msisdnCreated(payload))
        dispatch(navigateToManageMsisdn())
      }).catch(handleErrors(dispatch))
  }
}

const updateMsisdn = (msisdn, payload) => {
  return (dispatch) => {
    dispatch(savingMsisdn())
    return http.put(dispatch, `${config.serverUrl}/msisdns/${msisdn}`, payload)
      .then(() => {
        dispatch(msisdnUpdated(payload))
        dispatch(navigateToManageMsisdn())
      }).catch(handleErrors(dispatch))
  }
}

const deleteMsisdn = (msisdn) => {
  return (dispatch) => {
    dispatch(savingMsisdn())
    return http.del(dispatch, `${config.serverUrl}/msisdns/${msisdn}`)
      .then(() => {
        dispatch(msisdnDeleted(msisdn))
        dispatch(fetchMsisdns())
      }).catch(handleErrors(dispatch))
  }
}

export const constants = {
  MSISDNS_FETCHING: 'MSISDNS_FETCHING',
  MSISDNS_FETCHED: 'MSISDNS_FETCHED',

  MSISDN_FETCHED: 'MSISDN_FETCHED',
  MSISDN_FETCHING: 'MSISDN_FETCHING',

  MSISDN_NEW: 'MSISDN_NEW',
  MSISDN_SAVING: 'MSISDN_SAVING',
  MSISDN_CREATED: 'MSISDN_CREATED',
  MSISDN_UPDATED: 'MSISDN_UPDATED',
  MSISDN_DELETED: 'MSISDN_DELETED',

  MSISDN_ERROR: 'MSISDN_ERROR',
  MSISDN_UPDATE_ERROR: 'MSISDN_UPDATE_ERROR'
}

export const msisdnActions = {
  fetchMsisdns,
  msisdnsFetching,
  msisdnsFetched,

  fetchMsisdn,
  msisdnFetching,
  msisdnFetched,

  newMsisdn,
  createMsisdn,
  savingMsisdn,
  msisdnCreated,

  navigateToManageMsisdn,
  navigateToEditMsisdn,

  deleteMsisdn,
  msisdnDeleted,

  updateMsisdn,
  msisdnUpdated
}
