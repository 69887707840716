import { Component } from 'react'
import _ from 'lodash'

export default class InfiniteScroll extends Component {
  constructor(props) {
    super(props)
    this.onScroll = _.throttle(this.onScroll, 16).bind(this)
    this.model = {
      currentOffset: 0
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false)
  }

  onScroll() {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
    const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight
    const clientHeight = document.documentElement.clientHeight || window.innerHeight
    const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight
    if (scrolledToBottom) {
      if (this.props.nextOffset !== null && this.model.currentOffset !== this.props.nextOffset) {
        this.model.currentOffset = this.props.nextOffset
        this.props.selectPage()
      }
    }
  }
}
