import { connect } from 'react-redux'
import { offerActions } from '../offerActions'
import OurPicksLayout from '../../common/components/TriggersLayout'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const { id } = ownProps.match.params
      if (id) {
        dispatch(offerActions.fetchOffer(id))
      }
    },
    save: (offer) => {
      dispatch(offerActions.saveOffer(offer))
      dispatch(offerActions.navigateToSchedule(ownProps.match.params.id))
    },
    backHandler: () => {
      dispatch(offerActions.navigateToSchedule(ownProps.match.params.id))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contentId: ownProps.match.params.id,
    contentType: 'offers',
    status: state.offers.status,
    content: state.offers.offer,
    index: ownProps.match.params.index
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurPicksLayout)
