import { connect } from 'react-redux'

import { merchantActions } from '../merchantActions'
import Details from '../components/merchantDetails'

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    load: () => {
      dispatch(merchantActions.newMerchant())
    },
    saveAndNavigate: (model) => () => {
      dispatch(merchantActions.createMerchant(model))
    },
    discardChanges: () => {
      dispatch(merchantActions.discardMerchantChanges())
    },
    nextHandler: (e, model) => {
      e.preventDefault()
      dispatch(merchantActions.createMerchant(model))
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(merchantActions.navigateToMerchants())
    }
  }
}

const mapStateToProps = state => {
  const merchant = state.merchants.merchant

  // TODO: refactor this to make it more readable
  const businessDetails = {
    companyName: merchant ? merchant.companyName : '',
    vatRegistration: merchant ? merchant.vatRegistration : '',
    companyRegistration: merchant ? merchant.companyRegistration : '',
    notes: merchant ? merchant.notes : ''
  }

  return {
    merchant: merchant,
    status: state.merchants.merchantStatus,
    businessDetails: businessDetails,
    validationErrors: state.merchants.validationErrors,
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details)
