import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'

import AppError from '../../common/components/appError'
import AppFlash from '../../common/components/appFlash'
import BrandTile from '../../common/components/brandTile'
import AddBrandModal from './AddBrandModal'
import Breadcrumbs from './merchantBreadcrumbs'

export default class Brands extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalVisible: false
    }

    const staleMerchant = props.merchant && props.match.params && props.merchant.id !== props.match.params.merchantId
    if (!props.merchant || staleMerchant) {
      props.load()
    }
  }

  closeModal() {
    this.props.clearMessages()
    this.setState({ modalVisible: false })
  }

  render() {
    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <div>Loading...</div>
    }

    let brands = []
    if (this.props.merchant) {
      brands = this.props.merchant.brands || []
    }

    return (
      <div>
        <div className='page-header'>
          <h1>Merchant</h1>
        </div>
        <Row>
          <Col sm={4} lg={3}>
            <Breadcrumbs merchant={this.props.merchant}
                         match={this.props.match}
                         onNavigate={this.props.saveAndNavigate}
            />
          </Col>
          <Col sm={8} lg={6}>
            {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
            {this.props.error ? <AppError errorMessage={this.props.error} /> : null}

            <div style={{ marginBottom: '1em' }}>
              <Button id='add-brand'
                      bsStyle='primary'
                      onClick={() => this.setState({ modalVisible: true })}
              >
                Add Brand
              </Button>
            </div>
            <Row>
              {brands.map(brand => (
                <Col key={brand.id} sm={3} md={3} className='brand-thumbnail'>
                  <BrandTile brand={brand}
                             brandDetailsHandler={this.props.brandDetailsHandler}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <AddBrandModal validationErrors={this.props.validationErrors}
                       visible={this.state.modalVisible}
                       onClose={() => this.closeModal()}
                       uploadImage={this.props.uploadImage}
                       createBrand={this.props.createBrand}
        />
      </div>
    )
  }
}
