import React from 'react'
import { Col, Glyphicon, Row } from 'react-bootstrap'

const CopyPrizeDraw = ({
  sections,
  onToggleSection
}) => {
  return (
    <Row>
      <Col md={4}>
        <p>Select content to be copied:</p>
        <ul className='copy-list'>
          <li>
            <label>
              <input id='copy-details'
                     type='checkbox'
                     checked={sections.details}
                     onChange={() => onToggleSection('details')}
              />
              Basic Details
            </label>
          </li>
          <li>
            <label>
              <input id='copy-assets'
                     type='checkbox'
                     checked={sections.assets}
                     onChange={() => onToggleSection('assets')}
              />
              Assets
            </label>
          </li>
        </ul>
      </Col>
      <Col md={4}>
        <p>The following will not be copied:</p>
        <ul id='not-copied-sections' className='copy-list'>
          <li>
            <Glyphicon className='red' glyph='remove' />
            Schedule
          </li>
          <li>
            <Glyphicon className='red' glyph='remove' />
            Prizes
          </li>
          <li>
            <Glyphicon className='red' glyph='remove' />
            Related Content
          </li>
        </ul>
      </Col>
    </Row>
  )
}

export default CopyPrizeDraw
