import React from 'react'

const contentTypeTolabel = (type) => {
  switch (type) {
    case 'BANNER':
      return 'Banner'
    case 'OFFER':
      return 'Offer'
    case 'GROUP':
      return 'Group'
    case 'PRIZE_DRAW':
      return 'Prize Draw'
    case 'THANK_YOU':
      return 'Thank You'
    case 'EVENT':
      return 'Event'
    case 'TOUR':
      return 'Tour'
    case 'EVENT_DATE':
      return 'Event Date'
    case 'CAMPAIGN':
      return 'Campaign'
    case 'USER':
      return 'Account'
    case 'USER_ROLES':
      return 'Permissions'
    case 'STOCK':
      return 'Stock'
    case 'ARTICLE':
      return 'Article'
    case 'SERIES':
      return 'Series'
    case 'MUSIC_BANNER':
      return 'Music Banner'
    default:
      return type
  }
}

const ContentTypeLabel = ({
  type,
  className
}) => (
  <span className={className || undefined}>{contentTypeTolabel(type)}</span>
)

export default ContentTypeLabel
