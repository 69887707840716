import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import _ from 'lodash'

import FormField from '../../common/form/formField'
import GroupLink from '../../common/components/groupLink'
import PreferenceCategories from '../../common/components/preferenceCategories'
import ThankYouLink from '../../common/components/thankYouLink'
import CategoryTags from '../../common/components/categoryTags'
import { accountTypeOptions, channelOptions } from '../../common/enums'

class DetailsPanel extends Component {
  updateCategories(value) {
    const featuredTags = _.get(this.props.model, 'details.audience.featuredOverrides')
    if (!value.includes('FEATURED') && featuredTags) {
      this.props.updateField('details.audience.featuredOverrides', null)()
    }
    this.props.updateField('details.categories', value)()
  }

  mapBulletPointErrors(maxItems, validationErrors) {
    // Get individual bulletPoint errors
    const bulletPointErrors = Array.apply(null, Array(maxItems))
    _.forEach(validationErrors, function(error, item) {
      if (item.match(/bulletPoints\[\d\]/g) !== null) {
        bulletPointErrors[item.match(/\d/g)[0]] = error.bulletPoints
      }
    })
    return bulletPointErrors
  }

  render() {
    const {
      model,
      formDisabled,
      validationErrors,
      updateField,
      offer,
      brand,
      roles,
      preferenceCategories
    } = this.props

    return (
      <Panel header='Basic Details' id='details'>
        {offer.id ? (
          <div className='form-group form-group-inline' id='offer-id'>
            <label className='control-label'>ID:</label> {offer.id}
          </div>
        ) : null}
        {offer.shortId ? (
          <div className='form-group form-group-inline' id='offer-shortId'>
            <label className='control-label'>Short ID:</label> {offer.shortId}
          </div>
        ) : null}
        <div className='form-group form-group-inline' id='offer-brand'>
          <label className='control-label'>Brand:</label>
          {' '}
          {brand ? brand.name : null}
        </div>
        <GroupLink model={offer} />
        <ThankYouLink model={offer} />
        <FormField label='Title' id='title' value={model.details ? model.details.title : ''}
                   maxLength='60' mandatory
                   onChange={updateField('details.title')}
                   disabled={formDisabled}
                   error={validationErrors.details.title}
        />
        <FormField label='Subtitle' id='subtitle' value={model.details ? model.details.subtitle : ''}
                   maxLength='60'
                   onChange={updateField('details.subtitle')}
                   disabled={formDisabled}
                   error={validationErrors.details.subtitle}
        />
        <FormField label='Short Title' id='shortTitle' value={model.details ? model.details.shortTitle : ''}
                   maxLength='20' mandatory
                   onChange={updateField('details.shortTitle')}
                   disabled={formDisabled}
                   error={validationErrors.details.shortTitle}
        />
        <FormField label='Search tags (keywords)' id='searchTags'
                   value={model.details ? model.details.searchTags : ''}
                   onChange={updateField('details.searchTags')}
                   disabled={formDisabled}
        />
        <FormField label='Internal Reference' id='internalReference' value={model.details ? model.details.internalReference : ''}
                   onChange={updateField('details.internalReference')}
                   disabled={formDisabled}
                   error={validationErrors.details.internalReference}
        />
        <FormField label='At a Glance' id='bulletPoints[]' value={model.details ? model.details.bulletPoints : ['']}
                   type='addMultiple'
                   maxLength='100' maxItems='3' mandatory
                   onChange={(value) => updateField('details.bulletPoints', value)()}
                   disabled={formDisabled}
                   error={validationErrors.details.bulletPoints}
                   itemErrors={this.mapBulletPointErrors(3, validationErrors.details)}
        />
        <FormField label='Description' id='description' value={model.details ? model.details.description : ''}
                   type='textArea' maxLength='5000' mandatory
                   onChange={updateField('details.description')}
                   disabled={formDisabled}
                   error={validationErrors.details.description}
        />
        <FormField label='About the Brand Title' id='brandTitle' value={model.details ? model.details.brandTitle : ''}
                   maxLength='60'
                   onChange={updateField('details.brandTitle')}
                   disabled={formDisabled}
                   error={validationErrors.details.brandTitle}
        />
        <FormField label='About the Brand' id='brandDescription' value={model.details ? model.details.brandDescription : ''}
                   type='textArea' maxLength='5000'
                   onChange={updateField('details.brandDescription')}
                   disabled={formDisabled}
                   error={validationErrors.details.brandDescription}
        />
        <FormField label='T&C' id='termsAndConditions' value={model.details ? model.details.termsAndConditions : ''}
                   type='textArea' placeholder='Enter an offer terms and conditions' mandatory
                   maxLength='5000'
                   onChange={updateField('details.termsAndConditions')}
                   disabled={formDisabled}
                   error={validationErrors.details.termsAndConditions}
        />
        <CategoryTags model={model}
                      updateField={updateField}
                      formDisabled={formDisabled}
                      validationErrors={validationErrors}
                      pageType = {'OFFERS'}
        />
        <FormField label='Sort categories' id='categories'
                   value={_.get(model, 'details.categories') || []}
                   type='categorieslist'
                   offerContentType
                   featuredOnly
                   roles={roles}
                   onChange={(value) => this.updateCategories(value)}
                   disabled={formDisabled}
                   error={validationErrors.details.categories}
        />
        <FormField label='Apps' id='apps'
                   value={_.get(model, 'details.channels') || []}
                   options={channelOptions}
                   type='checkboxlist' mandatory
                   onChange={(value) => updateField('details.channels', value)()}
                   disabled={formDisabled}
                   error={validationErrors.details.channels}
        />
        <PreferenceCategories id='preferenceCategories'
                              value={_.get(model, 'details.preferenceCategories') || []}
                              updateField={updateField}
                              label='Preference Categories'
                              options={preferenceCategories}
                              disabled={formDisabled}
        />
        <FormField label='Account type' id='accountType'
                   value={_.get(model, 'details.myO2Audience.accountType')}
                   options={accountTypeOptions}
                   type='checkboxradiolist' inline
                   onChange={(value) => updateField('details.myO2Audience.accountType', value)()}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.myO2Audience.accountType')}
        />
        <FormField label='Staff only' id='staffOnly'
                   value={_.get(model, 'details.staffOnly')}
                   type='checkbox' inline
                   onChange={(e) => updateField('details.staffOnly', e.target.checked)()}
                   disabled={formDisabled}
        />
        <FormField label="18+ only" id='ageRestricted'
                   value={_.get(model, 'details.ageRestricted')}
                   type='checkbox' inline
                   onChange={(e) => updateField('details.ageRestricted', e.target.checked)()}
                   disabled={formDisabled}
        />
      </Panel>
    )
  }
}

export default DetailsPanel
