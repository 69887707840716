import React, { Component } from 'react'
import { Button, Panel } from 'react-bootstrap'
import DeleteOrDiscardModal from '../../common/components/deleteOrDiscardModal'
import TriggersTable from '../../common/components/triggersTable'
import AlternateTitlesTable from '../../common/components/AlternateTitlesTable'

class SchedulePanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saveOrDiscardModalVisible: false
    }

    this.onConfirmationDeleteTrigger = this.onConfirmationDeleteTrigger.bind(this)
    this.onConfirmationDeleteAlternateTitle = this.onConfirmationDeleteAlternateTitle.bind(this)
    this.onEditTrigger = this.onEditTrigger.bind(this)
    this.editAlternateTitleEntry = this.editAlternateTitleEntry.bind(this)

  }

  editAlternateTitleEntry(id) {
      this.props.model.alternateTitleGroupId = id
      this.props.navigateToGroupAlternateTitle(this.props)
  }

  onConfirmationDeleteAlternateTitle(key) {
      this.showDeleteOrDiscard('Alternate Title')
      this.setState({ indexToBeDeleted: key })
  }

  onDelete(indexToBeDeleted, type) {
      this.model = this.props.model
      type === 'Trigger' ? this.model.schedule.triggers.splice(indexToBeDeleted, 1) : this.model.schedule.alternateTitles.splice(indexToBeDeleted, 1)
      this.props.saveGroup(this.model)
    }

  onConfirmationDeleteTrigger(index) {
      this.showDeleteOrDiscard('Trigger')
      this.setState({ indexToBeDeleted: index })
  }

  onConfirmationDelete(index) {
    this.showDeleteOrDiscard()
    this.setState({ indexToBeDeleted: index })
  }

  showDeleteOrDiscard(type) {
      this.setState({ deleteOrDiscardModalVisible: true })
      this.deleteType = type
    }

  onEditTrigger(index) {
    this.props.navigateToEditOurPicksTrigger(this.props.model.id, index)
  }

  render() {
    const schedule = this.props.model.schedule || {}
    const validationErrors = this.props.validationErrors
    const formDisabled = this.props.formDisabled

    return (
      <div>
        <Panel header='Our Picks Trigger'>
          <div className='page-header--tools'>
            <Button disabled={formDisabled} bsStyle='primary' id='create-trigger' onClick={() => this.props.newTrigger(this.props.model)}>New Trigger</Button>
            <div className='triggers-list'>
              <TriggersTable triggers={schedule.triggers}
                             validationErrors={validationErrors}
                             onConfirmationDelete={this.onConfirmationDeleteTrigger}
                             onEditTrigger={this.onEditTrigger}
                             formDisabled={formDisabled}
              />
            </div>
          </div>
        </Panel>

        <Panel header='Alternate Titles' id='groups-alternate-titles-table'>
          <Button disabled={formDisabled} bsStyle='primary' id='create-alternate' onClick={() => this.props.newAlternateTitle(this.props.model)}>New Title</Button>
          <AlternateTitlesTable error={validationErrors}
                                alternateTitleContent={schedule.alternateTitles}
                                delete={this.onConfirmationDeleteAlternateTitle}
                                edit={this.editAlternateTitleEntry}
                                formDisabled={formDisabled}
           />
        </Panel>

        <DeleteOrDiscardModal message={'Do you want to delete this ' + this.deleteType + '?'}
                              visible={this.state.deleteOrDiscardModalVisible}
                              onDelete={() => {
                                this.onDelete(this.state.indexToBeDeleted, this.deleteType)
                                this.setState({ deleteOrDiscardModalVisible: false })
                              }}
                              onDiscard={() => {
                                this.setState({ deleteOrDiscardModalVisible: false })
                                this.props.navigateToSchedule(this.props.model.id)
                              }
                              }
        />
      </div>
    )
  }
}

export default SchedulePanel
