import { connect } from 'react-redux'
import { faqActions } from '../faqActions'
import Listing from '../components/faqSectionListing'

const mapDispatchToProps = (dispatch) => {
  return {
    newFaqSection: () => {
      dispatch(faqActions.faqSectionNew())
      dispatch(faqActions.navigateToCreateNewSection())
    },
    load: () => {
      dispatch(faqActions.fetchFaqSections())
    },
    onEdit: (faqSectionId) => {
      dispatch(faqActions.navigateToEditFaqSection(faqSectionId))
    },
    saveFaqSectionRanks: (ids, faqSections) => {
      dispatch(faqActions.updateFaqSectionRanks({ ids }, faqSections))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    faqSections: state.faqs.faqSections || [],
    error: state.faqs.error,
    status: state.faqs.faqSectionsStatus
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Listing)
