import { connect } from 'react-redux'
import { seriesActions } from '../../series/seriesActions'
import SelectSeriesItems from '../components/selectSeriesItems'
import { contentActions } from '../contentActions'

const mapDispatchToProps = (dispatch) => {
  return {
    load: (currentContent) => {
      dispatch(contentActions.clearContents())
      const allowedTypes = ['ARTICLE']

      dispatch(contentActions.setFilters('seriesItems', {
        type: allowedTypes,
        state: [],
        search: ''
      }))
    },
    backToContentItem: (contentItem) => {
      dispatch(contentActions.navigateToContentItem(contentItem, 'contentItems'))
    },
    updateContents: (currentContent, contents) => {
      dispatch(seriesActions.updateContents(currentContent.id, contents)).then(() => {
        dispatch(contentActions.navigateToContentItem(currentContent, 'contentItems'))
      })
    },
    toggleSelected: (contentItem) => {
      dispatch(contentActions.toggleSelected(contentItem))
    },
    updateFilters: (filters) => {
      dispatch(contentActions.setFilters('seriesItems', filters))
    },
    updateSearch: (query) => {
      dispatch(contentActions.setSearch('seriesItems', query))
    },
    selectPage: () => {
      dispatch(contentActions.fetchContents('seriesItems'))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contents: state.contents.contentList,
    nextOffset: state.contents.nextOffset,
    selectedFilters: state.contents.selectedFilters.seriesItems,
    selectedItems: state.contents.selectedItems,
    currentContent: state.contents.currentContent,
    status: state.contents.status,
    error: state.series.error,
    roles: state.common.roles
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSeriesItems)
