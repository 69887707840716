import { push } from 'connected-react-router'
import _ from 'lodash'
import { commonActions } from '../common/commonActions'
import http, { config, WELCOME_POSTER_DETAILS_V1, CONFIGURABLE_CONTENTS_LIST_V1 } from '../services/http'

const navigateToDashboard = () => {
  return push('/')
}

const navigateToManageWelcomePosters = () => {
  return push('/welcomePosters')
}

const navigateToEditWelcomePoster = (welcomePoster) => {
  return push(`/welcomePosters/${welcomePoster.subType}/edit`)
}

const welcomePosterFetching = () => ({
  type: constants.WELCOME_POSTERS_FETCHING
})

const welcomePosterFetched = (welcomePosters) => ({
  type: constants.WELCOME_POSTERS_FETCHED,
  welcomePosters
})

const welcomePosterUpdated = (welcomePoster) => ({
  type: constants.WELCOME_POSTER_UPDATED,
  welcomePoster
})

const setClickedWelcomePoster = (welcomePoster) => ({
  type: constants.CLICKED_WELCOME_POSTER,
  welcomePoster
})

const welcomePosterError = (error) => ({
  type: constants.WELCOME_POSTER_ERROR,
  error
})

const welcomePosterUpdateError = (validationErrors) => ({
  type: constants.WELCOME_POSTER_UPDATE_ERROR,
  validationErrors
})

const fetchWelcomePosters = () => {
  return (dispatch) => {
    dispatch(welcomePosterFetching())
    return http.get(dispatch, `${config.serverUrl}/configurableContents?type=WELCOME_POSTER`, null, {
      Accept: CONFIGURABLE_CONTENTS_LIST_V1
    }).then((welcomePosters) => {
      dispatch(welcomePosterFetched(welcomePosters))
      return welcomePosters
    }).catch((err) => {
      dispatch(welcomePosterError(`Unable to retrieve the list of Welcome Posters: ${err.status} (${err.statusText})`))
    })
  }
}

const updateWelcomePoster = (welcomePosterDetails) => {
  return (dispatch) => {
    const payload = _.pick(welcomePosterDetails, [
      'subType',
      'overline',
      'title',
      'subtitle',
      'ctaLabel',
      'contentId'
    ])

    return http.put(dispatch, `${config.serverUrl}/configurableContents/welcomePoster`, payload, {
      Accept: WELCOME_POSTER_DETAILS_V1
    }).then((welcomePoster) => {
      dispatch(welcomePosterUpdated(welcomePoster))
      dispatch(setClickedWelcomePoster(welcomePoster))
      dispatch(navigateToEditWelcomePoster(welcomePoster))
      dispatch(commonActions.displayFlash('Record has been updated.'))
    }).catch(handleErrors(dispatch))
  }
}

const handleErrors = (dispatch) => {
  return (response) => {
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(welcomePosterUpdateError(json.validationErrors))
    } else {
      if (response.body) {
        dispatch(welcomePosterError(response.body))
      } else {
        dispatch(welcomePosterError(response.statusText))
      }
    }
  }
}

export const constants = {
  WELCOME_POSTERS_FETCHING: 'WELCOME_POSTERS_FETCHING',
  WELCOME_POSTERS_FETCHED: 'WELCOME_POSTERS_FETCHED',
  WELCOME_POSTER_ERROR: 'WELCOME_POSTER_ERROR',
  WELCOME_POSTER_UPDATE_ERROR: 'WELCOME_POSTER_UPDATE_ERROR',
  WELCOME_POSTER_UPDATED: 'WELCOME_POSTER_UPDATED',
  WELCOME_POSTER_CONTENT_FETCHING: 'WELCOME_POSTER_CONTENT_FETCHING',
  WELCOME_POSTER_CONTENT_FETCHED: 'WELCOME_POSTER_CONTENT_FETCHED',
  CLICKED_WELCOME_POSTER: 'CLICKED_WELCOME_POSTER'
}

export const welcomePosterActions = {
  navigateToDashboard,
  navigateToManageWelcomePosters,
  welcomePosterFetching,
  welcomePosterFetched,
  welcomePosterError,
  welcomePosterUpdateError,
  fetchWelcomePosters,
  welcomePosterUpdated,
  navigateToEditWelcomePoster,
  updateWelcomePoster,
  setClickedWelcomePoster
}
