import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'

import ValidationIndicator from '../../common/components/validationIndicator'
import { config } from '../../services/http'

export default class OfferBreadcrumbs extends Component {
  getValidationStatus(model, property, validationErrors) {
    if (!model.id) {
      return 'NEW'
    } else if (Object.keys(model[property]).length === 0) {
      return 'NEW'
    } else if (Object.keys(validationErrors[property]).length === 0) {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  getLocationValidationStatus() {
    const { offer, validationErrors, locations } = this.props

    if (!offer.id) {
      return 'NEW'
    } else if (Object.keys(validationErrors.locations).length > 0) {
      return 'INVALID'
    } else if (locations.length === 0) {
      return 'NEW'
    } else {
      return 'VALID'
    }
  }

  getRedemptionValidationStatus() {
    const { offer, validationErrors } = this.props

    if (!offer.id) {
      return 'NEW'
    } else if (Object.keys(offer.redemption).length === 0) {
      return 'NEW'
    } else if (Object.keys(validationErrors.redemption).length > 0 || Object.keys(validationErrors.stock).length > 0) {
      return 'INVALID'
    } else {
      return 'VALID'
    }
  }

  getValidationStatuses() {
    const { offer, validationErrors } = this.props

    return {
      details: this.getValidationStatus(offer, 'details', validationErrors),
      schedule: this.getValidationStatus(offer, 'schedule', validationErrors),
      assets: this.getValidationStatus(offer, 'assets', validationErrors),
      redemption: this.getRedemptionValidationStatus(),
      relatedContent: this.getValidationStatus(offer, 'relatedContent', validationErrors),
      locations: this.getLocationValidationStatus()
    }
  }

  render() {
    const offer = this.props.offer
    const statuses = this.getValidationStatuses(offer)

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='offer-breadcrumbs-back'
                 onClick={() => this.props.onNavigate('back')}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Home
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='offer-breadcrumbs-details'
                 active={this.props.activeTab === 'details'}
                 onClick={() => this.props.onNavigate('details')}
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Basic Details
        </NavItem>
        <NavItem id='offer-breadcrumbs-schedule'
                 active={this.props.activeTab === 'schedule'}
                 onClick={() => this.props.onNavigate('schedule')}
        >
          <ValidationIndicator status={statuses.schedule} />
          {' '}
          Schedule
        </NavItem>
        <NavItem id='offer-breadcrumbs-assets'
                 active={this.props.activeTab === 'assets'}
                 onClick={() => this.props.onNavigate('assets')}
        >
          <ValidationIndicator status={statuses.assets} />
          {' '}
          Assets
        </NavItem>
        <NavItem id='offer-breadcrumbs-redemption'
                 active={this.props.activeTab === 'redemption'}
                 onClick={() => this.props.onNavigate('redemption')}
        >
          <ValidationIndicator status={statuses.redemption} />
          {' '}
          Redemption
        </NavItem>
        <NavItem id='offer-breadcrumbs-related-content'
                 active={this.props.activeTab === 'relatedContent'}
                 onClick={() => this.props.onNavigate('relatedContent')}
        >
          <ValidationIndicator status={statuses.relatedContent} />
          {' '}
          Related Content
        </NavItem>
        <NavItem id='offer-breadcrumbs-locations'
                 active={this.props.activeTab === 'locations'}
                 onClick={() => this.props.onNavigate('locations')}
        >
          <ValidationIndicator status={statuses.locations} />
          {' '}
          Locations
        </NavItem>
        {this.props.showCopy ? (
          <NavItem className='nav-item-divider' />
        ) : null}
        {this.props.showCopy ? (
          <NavItem id='offer-breadcrumbs-copy'
                   onClick={this.props.onCopy}
                   disabled={this.props.dirty}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Copy Offer
          </NavItem>
        ) : null}
        {config.auditsEnabled ? [
          <NavItem key='a' className='nav-item-divider' />,
          <NavItem key='b'
                   id='offer-breadcrumbs-audits'
                   active={this.props.activeTab === 'audits'}
                   onClick={() => this.props.onNavigate('audits')}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Audit trail
          </NavItem>
        ] : null}
      </Nav>
    )
  }
}
