import { connect } from 'react-redux'
import OurPicksLayout from '../../common/components/TriggersLayout'
import { articleActions } from '../articleActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const { id } = ownProps.match.params
      if (id) {
        dispatch(articleActions.fetchArticle(id))
      }
    },
    save: (article) => {
      dispatch(articleActions.saveArticle(article))
      dispatch(articleActions.navigateToSchedule(ownProps.match.params.id))
    },
    backHandler: () => {
      dispatch(articleActions.navigateToSchedule(ownProps.match.params.id))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contentId: ownProps.match.params.id,
    contentType: 'articles',
    status: state.articles.status,
    content: state.articles.article,
    index: ownProps.match.params.index
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurPicksLayout)
