import React, { Component } from 'react'
import _ from 'lodash'

import AppError from '../../common/components/appError'
import Spinner from '../../common/components/spinner'
import { SortableBody, SortableRow } from '../../common/components/sortableTable'
import { Button, ButtonToolbar, Glyphicon, Panel, Table } from 'react-bootstrap'

export default class FaqsListPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dirty: false,
      faqs: props.faqs,
      ids: props.faqs.map(faq => ({
        id: faq.id
      }))
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.faqs, this.props.faqs)) {
      this.setState({
        dirty: false,
        faqs: this.props.faqs,
        ids: this.props.faqs.map(faq => ({
          id: faq.id
        }))
      })
    }
  }

  onSort(ids) {
    this.setState({
      dirty: true,
      ids: ids
    })
  }

  onSave(faqItems) {
    this.props.onSortSave(faqItems, this.props.faqSectionId)
    this.setState({ dirty: false })
  }

  cancel() {
    this.props.onSortCancel(this.props.faqSectionId)
    this.setState({ dirty: false })
  }

  display(content) {
    if (content != null) {
      return content.length > 40 ? (content.slice(0, 40) + '...') : content
    }
  }

  render() {
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }
    const faqs = this.state.faqs
    const faqSectionId = this.props.faqSectionId

    const faqItems = []
    const faqIds = this.state.ids
    faqIds.forEach(faqId => {
      const faqItem = faqs.find(item => faqId.id === item.id)
      if (faqItem) {
        faqItems.push(faqItem)
      }
    })

    let error = <div />
    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }
    return (
      <Panel header='FAQs'>
        {error}
        <div className='faq-list'>
          <Table striped hover>
            <thead>
            <tr>
              <th />
              <th>Question</th>
              <th>Answer</th>
              <th>Platform</th>
              <th>Enabled</th>
            </tr>
            </thead>
            <SortableBody>
              {faqItems.map((faq, index) => {
                return (
                  <SortableRow className='cursor-pointer'
                               key={`faq-${index}`}
                               onClick={() => this.props.onEdit(faqSectionId, faq.id)}
                               ids={this.state.ids}
                               item={faq}
                               onSort={this.onSort.bind(this)}
                  >
                    <td>
                      <Glyphicon glyph='menu-hamburger' />
                    </td>
                    <td>{this.display(faq.question)}</td>
                    <td>{this.display(faq.answer).replace(/<[^>]+>/g, '')}</td> {/* HTML to plain text */}
                    <td>
                      {faq.platforms.map((platform, i) => <span key={i}>
                        {!!i && ', '}
                        <span>{platform}</span>
                      </span>)}
                    </td>
                    <td><input type='checkbox' checked={faq
                      .enabled} disabled
                               readOnly /></td>
                  </SortableRow>
                )
              })}
            </SortableBody>
          </Table>
          <div>
            <Button id='modify-faq-items'
                    bsStyle='default'
                    onClick={() => {
                      this.props.onCreateFaq(faqSectionId)
                    }}
            >
              Add a FAQ
            </Button>
            <ButtonToolbar className='pull-right'>
              <Button id='save-button'
                      bsStyle='primary'
                      onClick={() => this.onSave(faqItems)}
                      disabled={!this.state.dirty}>Save
              </Button>
              <Button id='cancel-button'
                      onClick={() => this.cancel()}
                      disabled={!this.state.dirty}>Cancel
              </Button>
            </ButtonToolbar>
          </div>
        </div>
      </Panel>
    )
  }
}
