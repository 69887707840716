import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { commonActions } from '../../common/commonActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import { bannerActions } from '../bannerActions'
import BannerForm from '../components/BannerForm'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/banners/${id}/audits/${audit.id}`))
      },
      load: () => {
        const { id, auditId } = ownProps.match.params
        if (id) {
          dispatch(bannerActions.fetchBanner(id)).then(() => {
            dispatch(bannerActions.fetchCalendarContent())
          })
        } else {
          dispatch(bannerActions.newBanner())
        }
        dispatch(commonActions.fetchPreferenceCategories())
        if (auditId) {
          dispatch(auditActions.fetchAudit(auditId))
        }
      },
      navigateToNewOurPicksTrigger: (banner) => {
        let index = 0
        if (banner.schedule && banner.schedule.triggers) {
          index = banner.schedule.triggers.length
        }
        dispatch(bannerActions.navigateToOurPicksScheduler(banner.id, index))
      },
      navigateToNewAlternateTitle: (banner) => {
        let index = 0
        if (banner.schedule && banner.schedule.alternateTitles) {
          index = banner.schedule.alternateTitles.length
        }
        dispatch(bannerActions.navigateToNewAlternateTitle(banner.id, index))
      },
      navigateToEditOurPicksTrigger: (bannerId, index) => {
        dispatch(bannerActions.navigateToOurPicksScheduler(bannerId, index))
      },
      navigateToBannerAlternateTitle: (bannerProps) => {
        dispatch(bannerActions.navigateToBannerAlternateTitle(bannerProps))
      },
      submit: (banner) => {
        dispatch(bannerActions.submitBanner(banner))
      },
      reject: (banner) => {
        dispatch(bannerActions.rejectBanner(banner))
      },
      publish: (banner) => {
        dispatch(bannerActions.publishBanner(banner))
      },
      pause: (banner) => {
        dispatch(bannerActions.pauseBanner(banner))
      },
      resume: (banner) => {
        dispatch(bannerActions.resumeBanner(banner))
      },
      edit: (banner) => {
        dispatch(bannerActions.editBanner(banner))
      },
      save: (banner) => {
        return dispatch(bannerActions.saveBanner(banner))
      },
      navigateToSchedule: () => {
        dispatch(bannerActions.navigateToSchedule(ownProps.match.params.id))
      }
    }
  )
}

const mapStateToProps = (state) => {
  return {
    banner: state.banners.banner || {},
    status: state.banners.status,
    error: state.banners.error,
    roles: state.common.roles,
    audits: state.audits,
    calendarContent: state.banners.calendarContent,
    preferenceCategories: state.common.preferenceCategories
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerForm)
