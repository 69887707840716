import _ from 'lodash'
import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'
import ValidationIndicator from '../../common/components/validationIndicator'
import { config } from '../../services/http'

export default class Breadcrumbs extends Component {
  isNew(model, property, validationErrors) {
    if (!model.id ||
      (Object.keys(model[property]).length === 0) ||
      (property === 'schedule' && (!model.schedule || _.isNil(model.schedule.triggers)) && _.isEmpty(validationErrors[property]))) {
      return true
    }
    return false
  }

  getValidationStatus(model, property, validationErrors) {
    if (this.isNew(model, property, validationErrors)) {
      return 'NEW'
    } else if (_.isEmpty(validationErrors[property])) {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  getValidationStatuses() {
    const { group, validationErrors } = this.props

    return {
      details: this.getValidationStatus(group, 'details', validationErrors),
      assets: this.getValidationStatus(group, 'assets', validationErrors),
      schedule: this.getValidationStatus(group, 'schedule', validationErrors),
      contentItems: this.getValidationStatus(group, 'contentItems', validationErrors)
    }
  }

  render() {
    var group = this.props.group
    var statuses = this.getValidationStatuses(group)

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='group-breadcrumbs-back'
                 onClick={() => this.props.onNavigate('back')}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Home
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='group-breadcrumbs-details'
                 active={this.props.activeTab === 'details'}
                 onClick={() => this.props.onNavigate('details')}
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Basic Details
        </NavItem>
        <NavItem id='group-breadcrumbs-assets'
                 active={this.props.activeTab === 'assets'}
                 onClick={() => this.props.onNavigate('assets')}
        >
          <ValidationIndicator status={statuses.assets} />
          {' '}
          Assets
        </NavItem>
        <NavItem id='group-breadcrumbs-schedule'
                 active={this.props.activeTab === 'schedule'}
                 onClick={() => this.props.onNavigate('schedule')}
        >
          <ValidationIndicator status={statuses.schedule} />
          {' '}
          Schedule
        </NavItem>
        <NavItem id='group-breadcrumbs-items'
                 active={this.props.activeTab === 'contentItems'}
                 onClick={() => this.props.onNavigate('contentItems')}
        >
          <ValidationIndicator status={statuses.contentItems} />
          {' '}
          Content
        </NavItem>
        {config.auditsEnabled ? [
          <NavItem key='a' className='nav-item-divider' />,
          <NavItem key='b'
                   id='group-breadcrumbs-audits'
                   active={this.props.activeTab === 'audits'}
                   onClick={() => this.props.onNavigate('audits')}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Audit trail
          </NavItem>
        ] : null}
      </Nav>
    )
  }
}
