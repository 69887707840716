import React from 'react'
import { Button, DropdownButton, MenuItem, Table } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Spinner from '../../common/components/spinner'
import Highlight from 'react-highlighter'
import _ from 'lodash'

import AppError from '../../common/components/appError'
import StatusLabel from '../../common/components/statusLabel'
import NoMatchesRow from '../../common/components/noMatchesRow'
import ContentTypelabel from '../../common/components/contentTypeLabel'
import { formatDateTime, formatNumber } from '../../common/utils'
import ContentFilters from './contentFilters'
import InfiniteScroll from './infiniteScroll'
import { contentTypes } from '../../common/enums.js'
import { config } from '../../services/http'
import AppFlash from '../../common/components/appFlash'

export default class ContentListing extends InfiniteScroll {
  constructor(props) {
    super(props)
    props.load()
  }

  displayStockOrEntrants(contentItem) {
    if (contentItem.stock != null) {
      return ((contentItem.stock.limit === 'UNLIMITED')
        ? 'Unlimited'
        : formatNumber(contentItem.stock.cumulativeRemainingStock) + ' / ' + formatNumber(contentItem.stock.cumulativeRemainingStock + contentItem.stock.redemptionCount))
    } else if (contentItem.type === 'PRIZE_DRAW' && (contentItem.state === 'LIVE' || contentItem.state === 'CLOSED' || contentItem.state === 'PAUSED')) {
      return (contentItem.numberOfEntrants != null) ? contentItem.numberOfEntrants : '-'
    } else {
      return ' '
    }
  }

  render() {
    const status = this.props.status || 'LOADING'
    const contentList = this.props.contentList
    const roles = this.props.roles
    const contentsCount = contentList.length

    return (
      <div>
        {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>Priority Content</h1>
            <p>Showing {contentsCount} contents</p>
          </div>
          <div className='page-header--tools'>
            {roles.some(role => ['ADMIN', 'INTERNAL'].includes(role)) ? (
              <DropdownButton id='new-content-dropdown' bsStyle='primary' title='New' pullRight>
                <LinkContainer to='/offers/new'>
                  <MenuItem id='new-content-offer'>New Offer</MenuItem>
                </LinkContainer>
                <LinkContainer to='/prizedraws/new'>
                  <MenuItem id='new-content-prizedraw'>New Prize Draw</MenuItem>
                </LinkContainer>
                <LinkContainer to='/banners/new'>
                  <MenuItem id='new-content-banner'>New Banner</MenuItem>
                </LinkContainer>
                {config.toursEnabled ? (
                  <LinkContainer to='/tours/new'>
                    <MenuItem id='new-content-tour'>New Tour</MenuItem>
                  </LinkContainer>
                ) : null}
                <LinkContainer to='/groups/new'>
                  <MenuItem id='new-content-group'>New Group</MenuItem>
                </LinkContainer>
                <LinkContainer to='/thankyous/new'>
                  <MenuItem id='new-content-thankyou'>New Thank You</MenuItem>
                </LinkContainer>
                <LinkContainer to='/campaigns/new'>
                  <MenuItem id='new-content-campaign'>New Campaign</MenuItem>
                </LinkContainer>
                <LinkContainer to='/articles/new'>
                  <MenuItem id='new-content-article'>New Article</MenuItem>
                </LinkContainer>
                <LinkContainer to='/series/new'>
                  <MenuItem id='new-content-series'>New Series</MenuItem>
                </LinkContainer>
              </DropdownButton>
            ) : config.toursEnabled ? (
              <LinkContainer to='/tours/new'>
                <Button bsStyle='primary' id='new-content-tour'>New Tour</Button>
              </LinkContainer>
            ) : null}
          </div>
        </div>
        <ContentFilters selectedFilters={this.props.selectedFilters}
                        updateFilters={this.props.updateFilters}
                        updateSearch={this.props.updateSearch}
                        contentTypes={contentTypes}
                        typesHidden={!roles.some(role => ['ADMIN', 'INTERNAL'].includes(role))}
        />
        {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
        <div className='content-list'>
          {status === 'LOADING' ? (
            <Spinner />
          ) : null}
          <Table striped hover>
            <thead>
            <tr>
              <th className='content-list--col__type'>Type</th>
              <th className='content-list--col__status'>Status</th>
              <th className='content-list--col__brand'>Brand</th>
              <th className='content-list--col__title'>Title</th>
              <th className='content-list--col__date'>Visible from</th>
              <th className='content-list--col__date'>Visible to</th>
              <th className='content-list--col__stock'>Stock/Entrants</th>
            </tr>
            </thead>
            <tbody>
            {contentList.map((contentItem, index) => {
              return (
                <tr className='cursor-pointer' key={`content-${index}`} onClick={(event) => {
                  this.props.viewContent(contentItem, event)
                }}>
                  <td><ContentTypelabel type={contentItem.type} /></td>
                  <td><StatusLabel status={contentItem.state} disabledContent={contentItem.details.disabled} /></td>
                  <td><Highlight matchClass='padding-zero' matchElement='mark' search={this.props.selectedFilters.search}>{_.get(contentItem, 'brand.name')}</Highlight></td>
                  <td><Highlight matchClass='padding-zero' matchElement='mark' search={this.props.selectedFilters.search}>{_.get(contentItem, 'details.title')}</Highlight></td>
                  <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayFromDateTime) : null}</td>
                  <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayToDateTime) : null}</td>
                  <td className='stock-or-entrants'>{this.displayStockOrEntrants(contentItem)}</td>
                </tr>
              )
            })}
            {status !== 'LOADING' && contentList.length === 0 ? (
              <NoMatchesRow colSpan='7' />
            ) : null}
            </tbody>
          </Table>
        </div>
      </div>
    )
  }
}
