import React from 'react'
import { Col, Panel, Row } from 'react-bootstrap'
import _ from 'lodash'
import AttachedBanners from './attachedBanners'
import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import AppFlash from '../../common/components/appFlash'
import { sectionHeaderTypeOptions } from '../../common/enums'
import Layout from './layout'
import ContentFormBase from '../../common/components/contentFormBase'

export default class SectionHeaderForm extends ContentFormBase {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.sectionHeader || {})
    this.model = this.props.sectionHeader
    this.state = {
      disableNextButton: this.props.sectionHeader.disableNextButton != null ? this.props.sectionHeader.disableNextButton : true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.sectionHeader, this.props.sectionHeader)) {
      this.model = this.props.sectionHeader
      this.forceUpdate()
    }
  }

  update(path, value) {
      return (e) => {
        _.set(this.model, path, e ? e.target.value : value)
        this.setState({ dirty: true })
        this.setState({
            disableNextButton: false
        })
      }
    }

  updateType() {
    return (e) => {
      this.model['type'] = e.target.value
      if (e.target.value.length > 0) {
        this.setState({
          disableNextButton: false
        })
      } else {
        this.setState({
          disableNextButton: true
        })
      }
    }
  }

  componentDidMount() {
    if (this.props.isEditOperation && !this.props.sectionHeader.subType) {
      this.props.backHandler()
    }
  }

  componentWillMount() {
       this.props.load(this.props.sectionHeader)
    }

  modifyAttachedBanner() {
    const updatedSectionHeader = Object.assign({}, this.props.sectionHeader)
    updatedSectionHeader.disableNextButton = this.state.disableNextButton
    this.props.navigateToSelectBanners(updatedSectionHeader)
  }

  render() {
    const {
      nextHandler,
      backHandler,
      flash
    } = this.props
    const validationErrors = this.props.validationErrors || {}
    const sectionHeaderDetails = this.props.sectionHeader || {}

    return (
      <Layout>
        <Row>
          <Col sm={12} lg={12}>
            {this.props.error || null}
            {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
            <Panel header='Section Header Details' id='sectionHeaderPanel'>
              <Form nextButtonText='Save'
                    nextButtonDisabled={this.props.isEditOperation && (this.state.disableNextButton)}
                    nextHandler={(e) => {
                      this.setState({ disableNextButton: true })
                      nextHandler(e, this.model)
                    }}
                    backHandler={(e) => {
                      backHandler(e)
                    }}
              >
                <FormField label='Title'
                           id='title'
                           value={sectionHeaderDetails.title}
                           maxLength='50'
                           onChange={this.update('title')}
                           error={validationErrors.title}
                           mandatory
                />
                <FormField label='Sub Title'
                           id='sub-title'
                           maxLength='120'
                           value={sectionHeaderDetails.subtitle}
                           onChange={this.update('subtitle')}
                           error={validationErrors.subtitle}
                />
                <AttachedBanners attachedBannerItem={this.props.sectionHeaderContent}
                                 onModify={() => this.modifyAttachedBanner()}
                                 viewContent={this.props.viewContent}
                />
                <FormField label="Type"
                           id="sectionHeader-type"
                           type="dropdown" options={sectionHeaderTypeOptions}
                           value={sectionHeaderDetails.subType}
                           error={validationErrors.type}
                           onChange={this.updateType()}
                           disabled={this.props.isEditOperation}
                           mandatory
                />
              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
