import { constants } from './locationActions'

const intitialState = {
  locations: [],
  location: null,
  pagination: {
    totalCount: 0,
    totalPages: 0,
    perPage: 5000,
    page: 1
  }
}

export default function locations(state = intitialState, action = {}) {
  switch (action.type) {
    case constants.LOCATIONS_FETCHING:
      return Object.assign({}, state, {
        locations: [],
        status: 'LOADING'
      })
    case constants.LOCATIONS_FETCHED:
      return Object.assign({}, state, {
        locations: action.locations,
        status: 'SUCCESS',
        pagination: {
          ...state.pagination,
          totalCount: action.totalCount,
          totalPages: Math.ceil(action.totalCount / state.pagination.perPage)
        }
      })
    case constants.LOCATIONS_SELECT_PAGE:
      return Object.assign({}, state, {
        pagination: {
          ...state.pagination,
          page: action.page
        }
      })
    case constants.LOCATIONS_ERROR:
      return Object.assign({}, state, {
        status: 'FAILED'
      })
    case constants.LOCATION_FETCHING:
      return Object.assign({}, state, {
        location: null,
        status: 'LOADING'
      })

    case constants.LOCATION_SAVING:
      return Object.assign({}, state, {
        location: null,
        status: 'LOADING'
      })

    case constants.LOCATION_FETCHED:
    case constants.LOCATION_SAVED:
    case constants.LOCATION_NEW: {
      return Object.assign({}, state, {
        location: action.location,
        status: 'SUCCESS'
      })
    }
    case constants.LOCATION_ERROR:
      return Object.assign({}, state, {
        status: 'FAILED'
      })
    default:
      return state
  }
}
