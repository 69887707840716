import React, { Component } from 'react'
import { Grid, MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap'
import { IndexLinkContainer, LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { config } from '../../services/http'
import ErrorBoundary from './errorBoundary'

function determineEnvironment() {
  switch (window.location.hostname) {
    case 'cms-web.priority.o2.co.uk':
      return 'PRODUCTION'
    case 'cms-web.dev.np.priority.o2.co.uk':
      return 'DEVELOPMENT'
    case 'cms-web.stage.np.priority.o2.co.uk':
      return 'STAGE'
    case 'cms-web.test.np.priority.o2.co.uk':
      return 'TEST'
    case 'cms-web.play.np.priority.o2.co.uk':
      return 'PLAY'
    case 'cms-web.perf.np.priority.o2.co.uk':
      return 'PERF'
    case 'localhost':
      return 'LOCAL'
    default :
      return 'UNKNOWN'
  }
}

export default class Dashboard extends Component {
  render() {
    const nonProd = window.location.hostname !== 'cms-web.priority.o2.co.uk'
    const { roles, profile } = this.props
    const envDisplayName = determineEnvironment()

    return (
      <div>
        <Navbar inverse fluid fixedTop className={classNames({ 'navbar-np': nonProd })} collapseOnSelect>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to='/'>
                <img src='/images/priority-logo.png' height='53' width='130' />
              </Link>
              {envDisplayName}
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          {this.props.loggedIn ? (
            <Navbar.Collapse>
              <Nav>
                {roles.some(role => ['ADMIN', 'INTERNAL'].includes(role)) ? (
                  <NavDropdown title='Content' id='content-menu'>
                    <LinkContainer to='/offers/new'>
                      <MenuItem id='createOffer'>New Offer</MenuItem>
                    </LinkContainer>
                    <LinkContainer to='/prizedraws/new'>
                      <MenuItem id='menu-prizedraws'>New Prize Draw</MenuItem>
                    </LinkContainer>
                    {config.toursEnabled ? (
                      <LinkContainer to='/tours/new'>
                        <MenuItem id='menu-tour'>New Tour</MenuItem>
                      </LinkContainer>
                    ) : null}
                    <LinkContainer to='/groups/new'>
                      <MenuItem id='menu-group'>New Group</MenuItem>
                    </LinkContainer>
                    <LinkContainer to='/thankyous/new'>
                      <MenuItem id='menu-thankyous'>New Thank You</MenuItem>
                    </LinkContainer>
                    <LinkContainer to='/campaigns/new'>
                      <MenuItem id='menu-campaign'>New Campaign</MenuItem>
                    </LinkContainer>
                    <LinkContainer to='/banners/new'>
                      <MenuItem id='menu-banners'>New Banner</MenuItem>
                    </LinkContainer>
                    <LinkContainer to='/articles/new'>
                      <MenuItem id='menu-articles'>New Article</MenuItem>
                    </LinkContainer>
                    <LinkContainer to='/series/new'>
                      <MenuItem id='menu-series'>New Series</MenuItem>
                    </LinkContainer>
                  </NavDropdown>
                ) : (
                  <IndexLinkContainer to='/'>
                    <NavItem id='menu-tour'>Content</NavItem>
                  </IndexLinkContainer>
                )}
                {roles.some(role => ['ADMIN', 'INTERNAL'].includes(role)) ? (
                  <NavDropdown title='Sort' id='sort-menu'>
                    <LinkContainer to='/sort/our-picks'>
                      <NavItem id='sort-our-picks'>
                        Our Picks
                      </NavItem>
                    </LinkContainer>
                    <LinkContainer to='/sort/tickets'>
                      <MenuItem id='sort-tickets'>
                        Tickets
                      </MenuItem>
                    </LinkContainer>
                    <LinkContainer to='/sort/my-o2'>
                      <MenuItem id='sort-my-o2'>
                        My O2
                      </MenuItem>
                    </LinkContainer>
                  </NavDropdown>
                ) : null}
                {roles.some(role => ['ADMIN', 'INTERNAL'].includes(role)) ? (
                  <LinkContainer to='/merchants'>
                    <NavItem id='merchantHome'>
                      Merchants
                    </NavItem>
                  </LinkContainer>
                ) : null}
                <LinkContainer to='/venues'>
                  <NavItem id='venueHome'>
                    Venues
                  </NavItem>
                </LinkContainer>
                {roles.includes('ADMIN') ? (
                  <LinkContainer to='/faq'>
                    <NavItem id='faq'>
                      FAQ
                    </NavItem>
                  </LinkContainer>
                ) : null}
                {roles.includes('ADMIN') ? (
                  <NavDropdown title='Configurable Content' id='configurable-content-menu'>
                     <LinkContainer to='/sectionHeaders'>
                      <MenuItem id='menu-sectionheaders'>Section Header Content</MenuItem>
                    </LinkContainer>
                    <LinkContainer to='/welcomePosters'>
                      <MenuItem id='menu-welcomeposter'>Welcome Poster Content</MenuItem>
                    </LinkContainer>
                    <LinkContainer to='/featureFlags'>
                      <MenuItem id='menu-featureFlags'>Feature Flags</MenuItem>
                    </LinkContainer>
                  </NavDropdown>
                ) : null}
              </Nav>
              {profile ? (
                <Nav pullRight>
                  {roles.includes('ADMIN') ? (
                    <NavDropdown title={profile ? `${profile.firstName} ${profile.lastName}` : 'Account'} id='user-menu'>
                      <LinkContainer to='/accounts' isActive={() => false}>
                        <MenuItem id='manageAccounts'>
                          Manage accounts
                        </MenuItem>
                      </LinkContainer>
                      <LinkContainer to='/msisdn' isActive={() => false}>
                        <MenuItem id='managePreviewMsisdns'>
                          Manage Preview MSISDNs
                        </MenuItem>
                      </LinkContainer>
                      <LinkContainer to='/notifications/profiles' isActive={() => false}>
                        <MenuItem id='manageNotificationProfiles'>
                          Manage Notification Profiles
                        </MenuItem>
                      </LinkContainer>
                      {config.auditsEnabled ? (
                        <LinkContainer to='/audits' isActive={() => false}>
                          <MenuItem id='user-nav-audits'>
                            Audit trail
                          </MenuItem>
                        </LinkContainer>
                      ) : null}
                      <LinkContainer to='/preferenceCategories' isActive={() => false}>
                      <MenuItem id='managePreferenceCategories'>
                          Manage Preference Categories
                        </MenuItem>
                      </LinkContainer>
                      <MenuItem className='nav-item-divider' />
                      <LinkContainer to={`/accounts/${profile.id}/password`} isActive={() => false}>
                        <MenuItem id='changePassword'>
                          Change password
                        </MenuItem>
                      </LinkContainer>
                      <MenuItem id='logout' onClick={this.props.onLogout} active={false}>
                        Logout
                      </MenuItem>
                    </NavDropdown>
                  ) : (
                    <NavDropdown title={profile ? `${profile.firstName} ${profile.lastName}` : 'Account'} id='user-menu'>
                      <LinkContainer to={`/accounts/${profile.id}/password`} isActive={() => false}>
                        <MenuItem>
                          Change password
                        </MenuItem>
                      </LinkContainer>
                      <MenuItem id='logout' onClick={this.props.onLogout} active={false}>
                        Logout
                      </MenuItem>
                    </NavDropdown>
                  )}
                </Nav>
              ) : null}
            </Navbar.Collapse>
          ) : null}
        </Navbar>
        <Grid>
          <ErrorBoundary>
            {this.props.children}
          </ErrorBoundary>
        </Grid>
      </div>
    )
  }
}
