import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { commonActions } from '../../common/commonActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import { eventDateActions } from '../../eventdates/eventDateActions'
import TourForm from '../components/TourForm'
import { tourActions } from '../tourActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      load: () => {
        const { id, auditId } = ownProps.match.params

        if (id) {
          dispatch(tourActions.fetchTour(id))
          dispatch(tourActions.fetchRelatedContent(id))
          dispatch(tourActions.fetchEventDates(id))
        } else {
          dispatch(tourActions.newTour())
        }
        if (auditId) {
          dispatch(auditActions.fetchAudit(auditId))
        }
        dispatch(eventDateActions.fetchShortVenues())
        dispatch(commonActions.fetchPreferenceCategories())
      },
      navigateToEventDate: (eventDate, event) => {
        const id = ownProps.match.params.id
        const url = `/tours/${id}/eventDates/${eventDate.id}`

        if (event && (event.ctrlKey || event.metaKey)) {
          event.preventDefault()
          window.open(url)
        } else {
          dispatch(eventDateActions.eventDateFetched(eventDate))
          dispatch(push(url))
        }
      },
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/tours/${id}/audits/${audit.id}`))
      },
      submit: (tour) => {
        dispatch(tourActions.submitTour(tour))
      },
      reject: (tour) => {
        dispatch(tourActions.rejectTour(tour))
      },
      publish: (tour) => {
        dispatch(tourActions.publishTour(tour))
      },
      pause: (tour) => {
        dispatch(tourActions.pauseTour(tour))
      },
      resume: (tour) => {
        dispatch(tourActions.resumeTour(tour))
      },
      edit: (tour) => {
        dispatch(tourActions.editTour(tour))
      },
      save: (tour) => {
        return dispatch(tourActions.saveTour(tour))
      },
      updateCancelled: (tour, cancel) => {
        dispatch(tourActions.updateCancelled(tour, cancel))
      },
      updateSoldOut: (tour, soldOut) => {
        dispatch(tourActions.updateSoldOut(tour, soldOut))
      },
      navigateToNewTicketsTrigger(tour) {
        return dispatch(tourActions.navigateToNewTicketsTrigger(tour))
      },
      navigateToEditTicketsTrigger(tour, index) {
        return dispatch(tourActions.navigateToEditTicketsTrigger(tour, index))
      }
    }
  )
}

const mapStateToProps = (state) => {
  return {
    tour: state.tours.tour,
    eventDates: state.tours.eventDates,
    venues: state.eventDates.shortVenues,
    relatedContent: state.tours.relatedContent,
    status: state.tours.status,
    error: state.tours.error,
    roles: state.common.roles,
    audits: state.audits,
    flash: state.common.flash,
    preferenceCategories: state.common.preferenceCategories
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TourForm)
