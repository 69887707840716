import React, { Component } from 'react'

var HTML5Backend = require('react-dnd-html5-backend')
var DragDropContext = require('react-dnd').DragDropContext
var DragSource = require('react-dnd').DragSource
var DropTarget = require('react-dnd').DropTarget

const sortRows = (ids, id, afterId) => {
  var rows = ids.slice()
  var a = rows.findIndex(r => r.id === id)
  var b = rows.findIndex(r => r.id === afterId)
  var row = rows.find(r => r.id === id)

  rows.splice(a, 1)
  rows.splice(b, 0, row)

  return rows
}

var rowSource = {
  beginDrag: function(props) {
    return { id: props.item.id }
  }
}

var rowTarget = {
  hover: function(props, monitor) {
    var draggedId = monitor.getItem().id

    if (draggedId !== props.item.id) {
      const rows = sortRows(props.ids, draggedId, props.item.id)
      props.onSort(rows)
    }
  }
}

var sourceCollect = function(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

var targetCollect = function(connect) {
  return {
    connectDropTarget: connect.dropTarget()
  }
}

const Row = class ContentItemRow extends Component {
  render() {
    var opacity = this.props.isDragging ? 0.3 : 1
    var connectDragSource = this.props.connectDragSource
    var connectDropTarget = this.props.connectDropTarget

    return connectDragSource(connectDropTarget(
      <tr style={{ opacity: opacity }} onClick={this.props.onClick} className='cursor-row-resize'>
        {this.props.children}
      </tr>
    ))
  }
}

const source = DragSource('row', rowSource, sourceCollect)(Row)
export const SortableRow = DropTarget('row', rowTarget, targetCollect)(source)

class Body extends Component {
  render() {
    return (
      <tbody>
      {this.props.children}
      </tbody>
    )
  }
}

export const SortableBody = DragDropContext(HTML5Backend)(Body)
