import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import _ from 'lodash'
import GroupLink from '../../common/components/groupLink'
import PreferenceCategories from '../../common/components/preferenceCategories'
import ThankYouLink from '../../common/components/thankYouLink'
import SeriesLink from '../../common/components/seriesLink'
import { articleSubtypes, themeOptions } from '../../common/enums'

import FormField from '../../common/form/formField'
import Label from '../../common/form/label'
import RichText from '../../common/form/richText'

class DetailsPanel extends Component {
  render() {
    const {
      model,
      formDisabled,
      validationErrors,
      updateField,
      article,
      brand,
      roles,
      preferenceCategories
    } = this.props

    return (
      <Panel header='Basic Details' id='details'>
        {article.id ? (
          <div className='form-group form-group-inline' id='article-id'>
            <label className='control-label'>ID:</label> {article.id}
          </div>
        ) : null}
        {article.shortId ? (
          <div className='form-group form-group-inline' id='article-shortId'>
            <label className='control-label'>Short ID:</label> {article.shortId}
          </div>
        ) : null}
        <div className='form-group form-group-inline' id='article-brand'>
          <label className='control-label'>Brand:</label>
          {' '}
          {brand ? brand.name : null}
        </div>
        <GroupLink model={article} />
        <ThankYouLink model={article} />
        <SeriesLink model={article} />
        <FormField label='Article Type' id='subtype'
                   mandatory
                   value={_.get(model, 'details.subtype')}
                   options={articleSubtypes}
                   type='checkboxradiolist' inline
                   onChange={(value) => updateField('details.subtype', value)()}
                   disabled={formDisabled}
                   error={validationErrors.details.subtype}
        />
        <FormField label='Theme'
                   type='radiolist' mandatory inline
                   value={model.details ? model.details.theme : ''}
                   id='theme'
                   options={themeOptions}
                   onChange={(value) => updateField('details.theme', value)()}
                   disabled={formDisabled}
                   error={validationErrors.details.theme}
        />
        <FormField label='Title' id='title' value={model.details ? model.details.title : ''}
                   maxLength='60' mandatory
                   onChange={updateField('details.title')}
                   disabled={formDisabled}
                   error={validationErrors.details.title}
        />
        <FormField label='Subtitle' id='subtitle' value={model.details ? model.details.subtitle : ''}
                   maxLength='60'
                   onChange={updateField('details.subtitle')}
                   disabled={formDisabled}
                   error={validationErrors.details.subtitle}
        />
        <FormField label='Search tags (keywords)' id='searchTags'
                   value={model.details ? model.details.searchTags : ''}
                   onChange={updateField('details.searchTags')}
                   disabled={formDisabled}
        />
        <FormField label='Overline' id='overline' value={model.details ? model.details.overline : ''}
                   maxLength='30'
                   onChange={updateField('details.overline')}
                   disabled={formDisabled}
                   error={validationErrors.details.overline}
        />
        <FormField label='Description' id='description' value={model.details ? model.details.description : ''}
                   type='textArea' maxLength='160' mandatory
                   onChange={updateField('details.description')}
                   disabled={formDisabled}
                   error={validationErrors.details.description}
        />
        <FormField label='Viewing Information' id='viewingInformation' value={model.details ? model.details.viewingInformation : ''}
                   maxLength='20'
                   onChange={updateField('details.viewingInformation')}
                   disabled={formDisabled}
                   error={validationErrors.details.viewingInformation}
        />
        <FormField label='Exclusive To Priority' type='checkbox' id='exclusiveToPriority'
                   value={article.details.exclusiveToPriority}
                   onChange={(e) => updateField('details.exclusiveToPriority', e.target.checked)()}
                   disabled={formDisabled}
        />
        <FormField label='Sort categories' id='categories'
                   value={_.get(model, 'details.categories') || []}
                   type='categorieslist'
                   roles={roles}
                   featuredOnly
                   onChange={(value) => updateField('details.categories', value)()}
                   disabled={formDisabled}
                   error={validationErrors.details.categories}
        />
        <FormField label="18+ only" id='ageRestricted'
                   value={_.get(model, 'details.ageRestricted')}
                   type='checkbox' inline
                   onChange={(e) => updateField('details.ageRestricted', e.target.checked)()}
                   disabled={formDisabled}
        />

        {_.get(model, 'details.subtype') === 'BLOG' ? (
          <div>
            <Label>
              Blog Editor
            </Label>
            <RichText value={article.details.richText || ''}
              id='article.blog.richText'
              onChange={content => updateField('details.richText', content)()}
              disabled={formDisabled}
              error={validationErrors.details.richText}
            />
          </div>
        ) : null}

        <PreferenceCategories id='preferenceCategories'
                              value={_.get(model, 'details.preferenceCategories')}
                              updateField={updateField}
                              label='Preference Categories'
                              options={preferenceCategories}
                              disabled={formDisabled}
        />

      </Panel>
    )
  }
}

export default DetailsPanel
