import React, { Component } from 'react'

export default class CheckBox extends Component {
  constructor(props) {
    super(props)
    this.state = { checked: props.value || false }
    this._handleChange = this._handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ checked: this.props.value || false })
    }
  }

  _handleChange(e) {
    this.props.onChange(e)

    this.setState({ checked: e.target.checked })
  }

  render() {
    const containerClasses = this.props.className || undefined
    const id = this.props.id

    return (
      <div className={containerClasses}>
        <input type='checkbox' id={id} name={id}
               onChange={this._handleChange}
               checked={this.state.checked}
               disabled={this.props.disabled || false}
        />
      </div>
    )
  }
}
