import moment from 'moment'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker'

import { dateFormat, dateFormatRegex, formatDate, formatTime, timeFormat, timeFormatRegex } from '../utils'

require('react-datepicker/dist/react-datepicker.css')

export default class DateTime extends Component {
  constructor(props) {
    super(props)

    this.state = {
      date: formatDate(props.value),
      time: formatTime(props.value)
    }
  }

  onChange(date, time) {
    if (this.props.dateOnly) {
      time = '12:00'
    }
    this.setState({ date, time })
    const validDate = dateFormatRegex.test(date)
    const validTime = timeFormatRegex.test(time)

    if (validDate && validTime) {
      const dateMoment = moment(date, dateFormat)
      const timeMoment = moment(time, timeFormat)
      const year = dateMoment.get('year')
      const month = dateMoment.get('month')
      const day = dateMoment.get('date')
      const hour = timeMoment.get('hour')
      const minute = timeMoment.get('minute')

      const dateTime = moment([year, month, day, hour, minute, 0, 0])
      if (dateTime.isValid()) {
        const updatedValue = dateTime.toDate()
        this.props.onChange ? this.props.onChange(updatedValue) : null
      }
    } else {
      this.props.onChange ? this.props.onChange(null) : null
    }
  }

  updateDate(date) {
    this.onChange(date ? date.format(dateFormat) : null, this.state.time)
  }

  updateTime(time) {
    if (/^\d{2}$/.test(time) && this.state.time.length !== 3) {
      // Add colon if not backspacing
      time = time + ':'
    } else if (/^\d{2}::$/.test(time)) {
      // Remove colon if they've doubled up
      time = time.slice(0, 3)
    }
    this.onChange(this.state.date, time)
  }

  render() {
    const id = this.props.id

    return (
      <div id={`${id}-datetime`} className='datetime-input'>
        <DatePicker name={`${id}-date`}
                    id={`${id}-date`}
                    className='form-control datetime-input-date'
                    selected={this.state.date ? moment(this.state.date, dateFormat) : null}
                    dateFormat={dateFormat}
                    placeholderText='dd/mm/yyyy'
                    disabled={this.props.disabled}
                    pattern={dateFormatRegex.source}
                    onChange={(value) => this.updateDate(value)}
        />
        {this.props.dateOnly ? null
          : (<input type='text'
                    name={`${id}-time`}
                    id={`${id}-time`}
                    className='form-control datetime-input-time'
                    value={this.state.time}
                    placeholder='hh:mm'
                    disabled={this.props.disabled}
                    pattern={timeFormatRegex.source}
                    onChange={(e) => this.updateTime(e.target.value)}
          />)
        }
      </div>
    )
  }
}
