import { connect } from 'react-redux'
import { groupActions } from '../../groups/groupActions'
import { thankYouActions } from '../../thankyous/thankYouActions'
import SelectGroupItems from '../components/selectGroupItems'
import { contentActions } from '../contentActions'

const mapDispatchToProps = (dispatch) => {
  return {
    load: (currentContent) => {
      const allowedTypes = ['BANNER', 'OFFER', 'ARTICLE', 'PRIZE_DRAW', 'MUSIC_BANNER']
      if(currentContent && currentContent.type === 'THANK_YOU') {
        allowedTypes.push('TOUR')
      }

      dispatch(contentActions.clearContents())
      dispatch(contentActions.setFilters('groupItems', {
        type: allowedTypes,
        state: [],
        search: ''
      }))
    },
    backToContentItem: (contentItem) => {
      dispatch(contentActions.navigateToContentItem(contentItem, 'contentItems'))
    },
    updateContents: (currentContent, contents) => {
      const actions = currentContent.type === 'GROUP' ? groupActions : thankYouActions

      dispatch(actions.updateContents(currentContent.id, contents)).then(() => {
        dispatch(contentActions.navigateToContentItem(currentContent, 'contentItems'))
      })
    },
    toggleSelected: (contentItem) => {
      dispatch(contentActions.toggleSelected(contentItem))
    },
    updateFilters: (filters) => {
      dispatch(contentActions.setFilters('groupItems', filters))
    },
    updateSearch: (query) => {
      dispatch(contentActions.setSearch('groupItems', query))
    },
    selectPage: () => {
      dispatch(contentActions.fetchContents('groupItems'))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contents: state.contents.contentList,
    nextOffset: state.contents.nextOffset,
    selectedFilters: state.contents.selectedFilters.groupItems,
    selectedItems: state.contents.selectedItems,
    currentContent: state.contents.currentContent,
    status: state.contents.status,
    error: state.groups.error,
    roles: state.common.roles
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectGroupItems)
