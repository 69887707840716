import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { Button, Col, Form, Panel, Row } from 'react-bootstrap'

import AppError from '../../common/components/appError'
import AppFlash from '../../common/components/appFlash'
import AuditsPanel from '../../common/components/auditsPanel'
import ContentFormBase from '../../common/components/contentFormBase'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import RelatedContent from '../../common/components/relatedContent'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import SelectBrandModal from '../../common/components/selectBrandModal'
import Spinner from '../../common/components/spinner'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import StatusToolbar from '../../common/components/statusToolbar'
import WebLink from '../../common/components/webLink'
import FormField from '../../common/form/formField'
import Label from '../../common/form/label'
import ImageSelector from '../../common/media/ImageSelector'
import { possibleNumberToString } from '../../common/utils'
import DetailsPanel from './DetailsPanel'
import Breadcrumbs from './PrizedrawBreadcrumbs'
import SchedulePanel from './SchedulePanel'

export default class PrizedrawForm extends ContentFormBase {
  constructor(props) {
    super(props)

    this.model = _.cloneDeep(props.prizedraw || {})
    this.state = {
      saveOrDiscardModalVisible: false,
      selectedBrand: null,
      dirty: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.prizedraw, this.props.prizedraw)) {
      this.model = _.cloneDeep(this.props.prizedraw || {})
      this.assignBrandLogoToModel(this.props)
      this.forceUpdate()
    }

    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
  }

  modifyRelatedContent() {
    this.props.navigateToSelectRelatedContent(this.props.prizedraw)
  }

  getLiveEntrants() {
    return (e) => {
      e.preventDefault()
      this.props.getLiveEntrants()
    }
  }

  downloadEntrants(fileType) {
    return (e) => {
      e.preventDefault()
      this.props.downloadEntrants(fileType)
    }
  };

  render() {
    const activeTab = this.props.match.params.section || 'details'
    const sections = ['details', 'schedule', 'assets', 'prizes', 'relatedContent']
    const requiredSections = ['details', 'schedule', 'assets', 'prizes']
    const status = this.props.status || 'LOADING'
    const roles = this.props.roles
    const ids = this.model.relatedContent && this.model.relatedContent.ids || []

    const prizeDraw = _.cloneDeep(this.props.prizedraw || {})
    const validationErrors = this.model.validationErrors || {}
    sections.forEach((section) => {
      prizeDraw[section] = prizeDraw[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })

    const formDisabled = this.isFormDisabled(prizeDraw.state, status)
    const liveEdit = this.isLiveEdit(prizeDraw)
    const isDrawn = prizeDraw.schedule && prizeDraw.schedule.drawDateTime && moment(prizeDraw.schedule.drawDateTime) < moment()
    const brands = this.props.brands

    const brand = this.model.brand
    const brandOptions = _.map(brands, b => ({ id: b.brandId, label: `${b.brandName} (${b.companyName})` }))

    if (status === 'LOADING') {
      return <Spinner />
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className='page-header'>
              <div className='page-header--heading'>
                <div className='page-header--top'>
                  <ContentTypeLabel className='page-header--type' type={prizeDraw.type} />
                  <StatusLabel className='page-header--state-label' status={prizeDraw.state} />
                  {prizeDraw.isPublished ? (
                    <WebLink content={prizeDraw} />
                  ) : null}
                  {liveEdit ? (
                    <StatusLabelCustom className='page-header--state-label' status='live_edit' text='Editing published content' />
                  ) : null}
                </div>
                <h1 className='content-heading'>{prizeDraw.details.title || 'New Prize Draw'}</h1>
              </div>
              <div className='page-header--tools'>
                <StatusToolbar status={prizeDraw.state}
                               contentComplete={this.isComplete(prizeDraw, validationErrors, sections, requiredSections)}
                               dirty={this.state.dirty}
                               isLiveEdit={liveEdit}
                               onSubmit={this.submit()}
                               onPublish={this.publish()}
                               onReject={this.reject()}
                               onSave={this.save()}
                               onPause={this.pause()}
                               onResume={this.resume()}
                               onEdit={this.edit()}
                               editDisabled={isDrawn}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <Breadcrumbs prizedraw={prizeDraw}
                             validationErrors={validationErrors}
                             activeTab={activeTab}
                             onNavigate={(tab) => this.onNavigate(tab)}
                             dirty={this.state.dirty}
                             showCopy={prizeDraw.id}
                             onCopy={this.copy()}
                />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
                {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
                <Form>
                  {activeTab === 'details' ? (
                    <DetailsPanel model={this.model}
                                  formDisabled={formDisabled}
                                  validationErrors={validationErrors}
                                  updateField={this.updateField.bind(this)}
                                  prizeDraw={prizeDraw}
                                  brand={brand}
                                  roles={roles}
                                  preferenceCategories={this.props.preferenceCategories}
                    />
                  ) : null}
                  {activeTab === 'prizes' ? (
                    <div>
                      <Panel header='Prizes' id='prizes'>
                        <FormField label='Number of winners to be drawn' id='mainPrizeNumberOfWinners'
                                   value={this.model.prizes ? possibleNumberToString(this.model.prizes.mainPrizeNumberOfWinners) : ''}
                                   type='number' mandatory
                                   onChange={this.updateField('prizes.mainPrizeNumberOfWinners')}
                                   disabled={formDisabled}
                                   error={validationErrors.prizes.mainPrizeNumberOfWinners}
                        />
                        <FormField label='Internal Notes' id='mainPrizeDescription'
                                   value={this.model.prizes ? this.model.prizes.mainPrizeDescription : ''}
                                   type='textArea'
                                   onChange={this.updateField('prizes.mainPrizeDescription')}
                                   disabled={formDisabled}
                                   error={validationErrors.prizes.mainPrizeDescription}
                        />
                      </Panel>
                      <Panel header='Draw Results Messaging' id='drawResultsMessaging'>
                        <FormField label='Winning Entrants Message' id='winnersMessage'
                                   value={this.model.prizes ? this.model.prizes.winnersMessage : ''}
                                   mandatory maxLength='150'
                                   onChange={this.updateField('prizes.winnersMessage')}
                                   disabled={formDisabled}
                                   error={validationErrors.prizes.winnersMessage}
                        />
                        <FormField label='Non-Winning Entrants Message' id='losersMessage'
                                   value={this.model.prizes ? this.model.prizes.losersMessage : ''}
                                   mandatory maxLength='150'
                                   onChange={this.updateField('prizes.losersMessage')}
                                   disabled={formDisabled}
                                   error={validationErrors.prizes.losersMessage}
                        />
                      </Panel>
                      <Panel header='Entrants' id='entrants'>
                        <FormField label='Live Count' id='liveCount'
                                   value={this.props.liveEntrantsCount !== undefined ? this.props.liveEntrantsCount + '' : ''}
                                   disabled
                        />
                        <Button id='refresh-btn' bsStyle='primary'
                                disabled={!prizeDraw.isPublished}
                                onClick={this.getLiveEntrants()}
                        >
                          Refresh
                        </Button>
                        <div className='margin-top-15'>
                          <Label id='download'>
                            Download
                          </Label>
                          {(this.props.prizeDrawState === 'DRAWN' && this.props.liveEntrantsCount >= 0) ? (
                            <div>
                              <FormField id='winning-entrants'
                                         value='Winning Entrants'
                                         type='link'
                                         onClick={this.downloadEntrants('WINNER_FILE')}
                              />
                              <FormField id='non-winning-entrants'
                                         value='Non-Winning Entrants'
                                         type='link'
                                         onClick={this.downloadEntrants('NON_WINNER_FILE')}
                              />
                            </div>
                          ) : (
                            <div>
                              Winners will be automatically drawn on the scheduled draw date.
                            </div>
                          )}
                        </div>
                      </Panel>
                    </div>
                  ) : null}
                  {activeTab === 'schedule' ? (
                    <SchedulePanel model={this.model}
                                   updateField={this.updateField.bind(this)}
                                   validationErrors={validationErrors}
                                   formDisabled={formDisabled}
                                   newTrigger={this.props.navigateToNewOurPicksTrigger}
                                   navigateToEditOurPicksTrigger={this.props.navigateToEditOurPicksTrigger}
                                   savePrizeDraw={this.props.save}
                                   navigateToSchedule={this.props.navigateToSchedule}
                    />
                  ) : null}
                  {activeTab === 'assets' ? (
                    <Panel header='Assets' id='assets'>

                      <ImageSelector label='Logo Image'
                                     type='logo'
                                     mediaTarget='BRAND_LOGO'
                                     mandatory
                                     defaultImageProperty='brand.logoImageUrl'
                                     width={320} height={320}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Landscape Image'
                                     type='landscape'
                                     mediaTarget='LANDSCAPE'
                                     mandatory
                                     width={1125} height={750}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Square Image'
                                     type='square'
                                     mediaTarget='SQUARE'
                                     mandatory
                                     width={750} height={750}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Full Screen Image'
                                     type='fullScreen'
                                     mediaTarget='FULL_SCREEN'
                                     mandatory
                                     width={1080} height={1920}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <FormField label='Youtube video ID (O2 channel only)' id='youtube-video-id'
                                 value={this.model.assets ? this.model.assets.youtubeVideoId || '' : ''}
                                 onChange={this.updateField('assets.youtubeVideoId')}
                                 disabled={formDisabled}
                                 error={validationErrors.assets.youtubeVideoId}
                      />
                    </Panel>
                  ) : null}
                  {activeTab === 'relatedContent' ? (
                    <RelatedContent relatedContentItems={this.props.relatedContent}
                                    onModify={() => this.modifyRelatedContent()}
                                    onSort={this.updateSortOf('relatedContent.ids')}
                                    ids={ids}
                                    formDisabled={formDisabled}
                                    viewContent={this.props.viewContent}
                    />
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onMount={this.props.fetchAudits}
                                 onViewAudit={this.props.onViewAudit}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        {!this.model.brand
          ? <div>
            <SelectBrandModal visible
                              onHide={this.props.navigateToDashboard}
                              options={brandOptions}
                              onChange={(brands) => this.selectBrand(brands[0])}
                              onCancel={() => this.props.navigateToDashboard()}
                              onSave={() => this.updateBrandId()}
                              selectedBrand={this.state.selectedBrand}
            />
            <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                                onSave={() => this.saveAndBack()}
                                onDiscard={() => this.props.navigateToDashboard()}
                                onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
            />
          </div>
          : null}
      </div>
    )
  }
}
