import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import FormField from '../../common/form/formField'

const initialState = {
  selectedFile: null,
  scheduledStartDateTime: null,
  scheduledEndDateTime: null,
  uploading: false,
  error: null
}

class UploadStockModal extends Component {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
  }

  selectFile(file) {
    this.setState({ selectedFile: file })
  }

  setScheduledStateDateTime(value) {
    this.setState({ scheduledStartDateTime: value })
  }

  setScheduledEndDateTime(value) {
    this.setState({ scheduledEndDateTime: value })
  }

  close() {
    this.setState(initialState)
    this.props.onClose()
  }

  save() {
    this.setState({ uploading: true })
    this.props.onSave(this.state.selectedFile, this.state.scheduledStartDateTime, this.state.scheduledEndDateTime).then(() => {
      this.props.onClose()
      this.setState(initialState)
    }).catch((err) => {
      this.setState({ uploading: false, error: err })
    })
  }

  render() {
    const disabled = !this.state.selectedFile || this.state.uploading || (this.props.scheduled && (!this.state.scheduledStartDateTime || !this.state.scheduledEndDateTime))

    return (
      <Modal show={this.props.visible} onHide={() => this.close()}>
        <Modal.Header>
          <h4>Upload Stock Codes</h4>
        </Modal.Header>
        <Modal.Body>
          <FormField label='Unique Codes' id='codes' mandatory
                     type='file'
                     onChange={(e) => this.selectFile(e.target.files[0])}
                     error={this.state.error}
                     uploading={this.state.uploading}
                     help='Upload a .txt or .csv with one unique code per line'
                     showFileName={true}
          />
          {this.props.scheduled ? (
            <div>
              <FormField label='Schedule Start' type='datetime'
                         id='schedule-start'
                         mandatory
                         onChange={(value) => this.setScheduledStateDateTime(value)}
              />
              <FormField label='Schedule End' type='datetime'
                         id='schedule-end'
                         mandatory
                         onChange={(value) => this.setScheduledEndDateTime(value)}
              />
            </div>
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button id='cancel' onClick={() => this.close()}>Cancel</Button>
          <Button id='save' bsStyle='primary'
                  onClick={() => this.save()}
                  disabled={disabled}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default UploadStockModal
