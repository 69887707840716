import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'

import ValidationIndicator from '../../common/components/validationIndicator'
import { config } from '../../services/http'

export default class Breadcrumbs extends Component {
  getValidationStatus(model, property, validationErrors) {
    if (!model.id) {
      return 'NEW'
    } else if (Object.keys(model[property]).length === 0) {
      return 'NEW'
    } else if (Object.keys(validationErrors[property]).length === 0) {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  getValidationStatuses() {
    const { prizedraw, validationErrors } = this.props

    return {
      details: this.getValidationStatus(prizedraw, 'details', validationErrors),
      prizes: this.getValidationStatus(prizedraw, 'prizes', validationErrors),
      schedule: this.getValidationStatus(prizedraw, 'schedule', validationErrors),
      assets: this.getValidationStatus(prizedraw, 'assets', validationErrors),
      relatedContent: this.getValidationStatus(prizedraw, 'relatedContent', validationErrors)
    }
  }

  render() {
    var prizedraw = this.props.prizedraw
    var statuses = this.getValidationStatuses(prizedraw)

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='prizedraw-breadcrumbs-back'
                 onClick={() => this.props.onNavigate('back')}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Home
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='prizedraw-breadcrumbs-details'
                 active={this.props.activeTab === 'details'}
                 onClick={() => this.props.onNavigate('details')}
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Basic Details
        </NavItem>
        <NavItem id='prizedraw-breadcrumbs-schedule'
                 active={this.props.activeTab === 'schedule'}
                 onClick={() => this.props.onNavigate('schedule')}
        >
          <ValidationIndicator status={statuses.schedule} />
          {' '}
          Schedule
        </NavItem>
        <NavItem id='prizedraw-breadcrumbs-assets'
                 active={this.props.activeTab === 'assets'}
                 onClick={() => this.props.onNavigate('assets')}
        >
          <ValidationIndicator status={statuses.assets} />
          {' '}
          Assets
        </NavItem>
        <NavItem id='prizedraw-breadcrumbs-prizes'
                 active={this.props.activeTab === 'prizes'}
                 onClick={() => this.props.onNavigate('prizes')}
        >
          <ValidationIndicator status={statuses.prizes} />
          {' '}
          Prizes
        </NavItem>
        <NavItem id='prizedraw-breadcrumbs-related-content'
                 active={this.props.activeTab === 'relatedContent'}
                 onClick={() => this.props.onNavigate('relatedContent')}
        >
          <ValidationIndicator status={statuses.relatedContent} />
          {' '}
          Related Content
        </NavItem>
        {this.props.showCopy ? (
          <NavItem className='nav-item-divider' />
        ) : null}
        {this.props.showCopy ? (
          <NavItem id='prizedraw-breadcrumbs-copy'
                   onClick={this.props.onCopy}
                   disabled={this.props.dirty}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Copy Prize Draw
          </NavItem>
        ) : null}
        {config.auditsEnabled ? [
          <NavItem key='a' className='nav-item-divider' />,
          <NavItem key='b'
                   id='prizedraw-breadcrumbs-audits'
                   active={this.props.activeTab === 'audits'}
                   onClick={() => this.props.onNavigate('audits')}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Audit trail
          </NavItem>
        ] : null}
      </Nav>
    )
  }
}
