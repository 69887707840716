import React, { Component } from 'react'
import { Button, ButtonToolbar, Checkbox, Table } from 'react-bootstrap'
import classNames from 'classnames'

import { locationStatusToLabel } from '../../common/enums'
import Pagination from '../../common/components/pagination'
import SearchMatchText from '../../common/components/searchMatchText'
import SelectAllPagesRow from '../../common/components/selectAllPagesRow'

export default class SelectLocations extends Component {
  constructor(props) {
    super(props)

    const brandId = props.currentContent.brand.id
    props.fetchLocations(brandId)
  }

  updateLocations() {
    this.props.updateLocations(this.props.currentContent, this.props.selectedItems)
  }

  selectPage(page) {
    const brandId = this.props.currentContent.brand.id
    this.props.selectPage(page, brandId)
  }

  selectAllPages() {
    const brandId = this.props.currentContent.brand.id
    this.props.selectAllPages(brandId)
  }

  render() {
    const status = this.props.status || 'LOADING'
    const locations = this.props.locations || []
    const ids = locations.map(l => l.id)
    const selectedItems = this.props.selectedItems || []
    const allSelected = ids.length > 0 && locations.length === selectedItems.filter(item => ids.indexOf(item.id) > -1).length
    const showSelectAllPages = allSelected && this.props.pagination.totalPages > 1 && selectedItems.length < this.props.pagination.totalCount
    const showDeselectAllPages = this.props.pagination.totalPages > 1 && selectedItems.length === this.props.pagination.totalCount

    if (status === 'LOADING') {
      return <div>Loading...</div>
    }

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>Select locations</h1>
            <SearchMatchText pagination={this.props.pagination}
                             displayCount={locations.length}
                             selectedCount={this.props.selectedItems.length}
            />
          </div>
          <div className='page-header--tools'>
            <ButtonToolbar>
              <Button id='cancel-group-items' onClick={() => this.props.backToContentItem(this.props.currentContent)}>Cancel</Button>
              <Button id='update-group-items' bsStyle='primary' onClick={() => this.updateLocations()}>Update</Button>
            </ButtonToolbar>
          </div>
        </div>
        {locations.length > 0 ? (
          <Table striped hover>
            <thead>
            <tr>
              <th>
                <Checkbox checked={allSelected}
                          onClick={(e) => e.stopPropagation()}
                          onChange={() => this.props.toggleAllSelected(locations, !allSelected)}
                />
              </th>
              <th>Reference</th>
              <th>Status</th>
              <th>Address</th>
              <th>City</th>
              <th width='90'>Postcode</th>
            </tr>
            </thead>
            <tbody>
            <SelectAllPagesRow colSpan='6'
                               showSelectAllPages={showSelectAllPages}
                               showDeselectAllPages={showDeselectAllPages}
                               rowCount={locations.length}
                               pagination={this.props.pagination}
                               onSelect={() => this.selectAllPages()}
                               onDeselect={() => this.props.deselectAllPages()}
            />
            {locations.map((location, index) => {
              const selected = !!selectedItems.find(item => item.id === location.id)
              const classes = classNames('cursor-pointer', selected ? 'content-list--row__selected' : null)

              return (
                <tr className={classes} key={`location-${index}`}
                    onClick={() => this.props.toggleSelected(location)}
                >
                  <td>
                    <Checkbox checked={selected}
                              onClick={(e) => e.stopPropagation()}
                              onChange={() => this.props.toggleSelected(location)}
                    />
                  </td>
                  <td>{location.location.ref || null}</td>
                  <td>{locationStatusToLabel(location.location.status)}</td>
                  <td>{location.location.addressLine1 || null}</td>
                  <td>{location.location.city || null}</td>
                  <td>{location.location.postcode || null}</td>
                </tr>
              )
            })}
            </tbody>
          </Table>
        ) : (
          <p>There are no locations yet.</p>
        )}
        {this.props.pagination.totalPages > 1 ? (
          <Pagination totalPages={this.props.pagination.totalPages}
                      page={this.props.pagination.page}
                      onSelect={(page) => this.selectPage(page)}
          />
        ) : null}
      </div>
    )
  }
}
