import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'

import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import AppError from '../../common/components/appError'

export default class ResetPasswordNotification extends Component {
  constructor(props) {
    super(props)
    this.model = {
      email: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.email !== this.props.email) {
      this.model = this.props.email
      this.forceUpdate()
    }
  }

  update(property) {
    return (e) => {
      this.model[property] = e.target.value
    }
  }

  render() {
    const nextHandler = this.props.nextHandler
    const validationErrors = this.props.validationErrors || {}

    return (
      <Panel header='Reset Password' style={{ maxWidth: '400px', margin: '30px auto' }}>
        <Form nextButtonText='Reset password' nextHandler={(e) => {
          nextHandler(e, this.model)
        }}>
          {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
          <FormField label='Please enter your email'
                     id='email'
                     value={this.props.email}
                     onChange={this.update('email')}
                     error={validationErrors.email}
                     mandatory
          />
        </Form>
      </Panel>
    )
  }
}
