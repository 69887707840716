import { connect } from 'react-redux'
import { sectionHeaderActions } from '../../sectionHeaders/sectionHeaderActions'
import { contentActions } from '../contentActions'
import SelectBannerContent from '../components/selectBannerContent'

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(contentActions.clearContents())
      dispatch(contentActions.setFilters('bannerContent', {
        type: ['BANNER'],
        state: [],
        search: ''
      }))
    },
    backToSectionHeader: (sectionHeader) => {
      dispatch(sectionHeaderActions.setClickedSectionHeader(sectionHeader))
      dispatch(sectionHeaderActions.navigateToEditSectionHeader(sectionHeader))
    },
    updateSectionHeaderContentId: (contentItem) => {
      dispatch(sectionHeaderActions.updateSectionHeader(contentItem))
    },
    toggleSelected: (contentItem) => {
      dispatch(contentActions.toggleSelected(contentItem))
    },
    updateFilters: (filters) => {
      dispatch(contentActions.setFilters('bannerContent', filters))
    },
    updateSearch: (query) => {
      dispatch(contentActions.setSearch('bannerContent', query))
    },
    selectPage: () => {
      dispatch(contentActions.fetchContents('bannerContent'))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contents: state.contents.contentList,
    nextOffset: state.contents.nextOffset,
    selectedFilters: state.contents.selectedFilters.bannerContent,
    selectedItems: state.contents.selectedItems,
    currentContent: state.contents.currentContent,
    status: state.contents.status,
    error: state.contents.error,
    roles: state.common.roles,
    maxSelectable: state.contents.maxSelectable
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectBannerContent)
