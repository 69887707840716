import { constants } from './seriesActions'

const initialState = {
  series: null,
  contentList: []
}

export default function series(state = initialState, action = {}) {
  switch (action.type) {
    case constants.SERIES_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.SERIES_SAVING:
      return Object.assign({}, state, {
        series: action.series,
        status: 'SAVING'
      })
    case constants.SERIES_FETCHED:
    case constants.SERIES_SAVED:
    case constants.SERIES_NEW:
    case constants.SERIES_SUBMITTED:
    case constants.SERIES_REJECTED:
    case constants.SERIES_PUBLISHED:
    case constants.SERIES_PAUSED:
    case constants.SERIES_RESUMED:
    case constants.SERIES_EDITED:
      return Object.assign({}, state, {
        series: action.series,
        status: 'SUCCESS',
        error: null
      })
    case constants.SERIES_CONTENTS_FETCHING:
      return Object.assign({}, state, {
        contentList: []
      })
    case constants.SERIES_CONTENTS_FETCHED:
      return Object.assign({}, state, {
        contentList: action.contentList
      })
    case constants.SERIES_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    default:
      return state
  }
}
