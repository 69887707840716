import React, { Component } from 'react'
import { Button, ButtonToolbar, Glyphicon, Table } from 'react-bootstrap'
import Spinner from '../../common/components/spinner'
import _ from 'lodash'

import { SortableBody, SortableRow } from '../../common/components/sortableTable'
import StatusLabel from '../../common/components/statusLabel'
import ContentTypelabel from '../../common/components/contentTypeLabel'
import { formatDateTime, formatNumber } from '../../common/utils'
import AppError from '../../common/components/appError'
import { contentTypes, voucherStoreTypes } from '../../common/enums'
import ContentFilters from './contentFilters'

export default class SortItems extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dirty: false,
      ids: props.contents.map(contentItem => ({
        id: contentItem.id,
        type: contentItem.type
      }))
    }
  }

  componentDidMount() {
    this.props.load(this.props.match.params.categoryPath)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.categoryPath !== this.props.match.params.categoryPath) {
      return this.props.load(this.props.match.params.categoryPath)
    }

    if (prevProps.contents.length !== this.props.contents.length) {
      this.setState({
        ids: this.props.contents.map(contentItem => ({
          id: contentItem.id,
          type: contentItem.type
        }))
      })
    }
  }

  onSort(ids) {
    this.setState({
      dirty: true,
      ids: ids
    })
  }

  onSave(contentItems) {
    const ids = this.state.ids.map(idType => idType.id)
    this.props.onSave(this.props.category.value, ids, contentItems)
    this.setState({ dirty: false })
  }

  editFeaturedContentsTitle() {
    this.props.navigateToFeaturedContentsTitle()
  }

  cancel() {
    this.setState({
      dirty: false,
      ids: this.props.contents.map(contentItem => ({
        id: contentItem.id,
        type: contentItem.type
      }))
    })
  }

  displayStock(stock) {
    return stock.limit === 'UNLIMITED' ? 'Unlimited' : (
      formatNumber(stock.cumulativeRemainingStock) + ' / ' + formatNumber(stock.cumulativeRemainingStock + stock.redemptionCount)
    )
  }

  render() {
    const status = this.props.status || 'LOADING'
    const contents = this.props.contents || []
    const category = this.props.category

    // sort by order of ids
    let contentItems = []
    const contentItemIds = this.state.ids
    contentItemIds.forEach(idType => {
      const contentItem = contents.find(item => idType.id === item.id)
      if (contentItem) {
        contentItems.push(contentItem)
      }
    })
    if (this.props.selectedFilters.state.length > 0) {
      contentItems = contentItems.filter(content => this.props.selectedFilters.state.includes(content.state))
    }

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>{category.label}</h1>
            <p>A total of {contents.length} items have been found.</p>
          </div>
          <div className='page-header--tools'>
            <ButtonToolbar>
              {this.state.dirty ? (
                <Button onClick={() => this.cancel()}>Cancel</Button>
              ) : null}
              <Button id='save-button'
                      bsStyle='primary'
                      onClick={() => this.onSave(contentItems)}
                      disabled={!this.state.dirty}
              >
                Save
              </Button>
              <Button id='featured-contents-title-button'
                      bsStyle='primary'
                      onClick={() => this.editFeaturedContentsTitle()}
              >
                Featured Contents Title
              </Button>
            </ButtonToolbar>
          </div>
        </div>
        <ContentFilters selectedFilters={this.props.selectedFilters}
                        updateFilters={this.props.updateFilters}
                        contentTypes={contentTypes}
                        searchHidden
                        typesHidden
        />
        {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
        <div className='content-list'>
          {status === 'LOADING' ? (
            <Spinner />
          ) : (
            <Table striped hover>
              <thead>
              <tr>
                <th width='1' />
                <th className='content-list--col__type'>Type</th>
                <th className='content-list--col__status'>Status</th>
                <th className='content-list--col__redeemable'>
                  Redeemable
                </th>
                <th className='content-list--col__brand'>Brand</th>
                <th className='content-list--col__title'>Title</th>
                <th className='content-list--col__date'>Visible from</th>
                <th className='content-list--col__date'>Visible to</th>
                <th className='content-list--col__stock'>
                  Stock (Remaining / Total)
                </th>
              </tr>
              </thead>
              <SortableBody>
                {contentItems.map((contentItem, index) => {
                  return (
                    <SortableRow className='cursor-pointer'
                                 key={`content-${index}`}
                                 onClick={() => this.props.viewContent(contentItem)}
                                 ids={this.state.ids}
                                 item={contentItem}
                                 onSort={this.onSort.bind(this)}
                    >
                      <td>
                        <Glyphicon glyph='menu-hamburger' />
                      </td>
                      <td><ContentTypelabel type={contentItem.type} /></td>
                      <td><StatusLabel status={contentItem.state} /></td>
                      <td>
                        {contentItem.redemption ? voucherStoreTypes[contentItem.redemption.voucher.storeType] : null}
                      </td>
                      <td>{_.get(contentItem, 'brand.name')}</td>
                      <td>{_.get(contentItem, 'details.title')}</td>
                      <td>
                        {contentItem.schedule ? formatDateTime(contentItem.schedule.displayFromDateTime) : null}
                      </td>
                      <td>
                        {contentItem.schedule ? formatDateTime(contentItem.schedule.displayToDateTime) : null}
                      </td>
                      <td>
                        {contentItem.stock ? this.displayStock(contentItem.stock) : 'N/A'}
                      </td>
                    </SortableRow>
                  )
                })}
                {status !== 'LOADING' && contents.length === 0 ? (
                  <tr>
                    <td colSpan='9'>
                      <div className='no-results'>
                        There are no items in {category.label}.
                      </div>
                    </td>
                  </tr>
                ) : null}
              </SortableBody>
            </Table>
          )}
        </div>
      </div>
    )
  }
}
