import React from 'react'
import { Link } from 'react-router-dom'

const SeriesLink = ({ model }) => {
  return model.series ? (
    <div className='form-group form-group-inline' id='series-link'>
      <label className='control-label'>Series:</label>
      {' '}
      <Link className='link' to={`/series/${model.series.id}`}>{model.series.title}</Link>
    </div>
  ) : null
}

export default SeriesLink
