import _ from 'lodash'
import React, { Component } from 'react'
import { Button, Panel, Table } from 'react-bootstrap'
import { stockLimitOptions, voucherCodeTypeOptions, voucherLimitOptions, voucherPresentationMethodOptions, voucherStoreTypeOptions } from '../../common/enums'

import FormField from '../../common/form/formField'
import { formatFullDateTime, formatDateTime, formatNumber, possibleNumberToString } from '../../common/utils'
import ConfirmStockDeleteModal from './ConfirmStockDeleteModal'
import ModifyStockModal from './ModifyStockModal'
import UploadStockModal from './UploadStockModal'

class RedemptionPanel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modifyStockModalOpen: false,
      uploadStockModalOpen: false,
      scheduledUpload: false,
      confirmStockDeleteModalOpen: false
    }
  }

  componentDidMount() {
    this.setState({ editRedemption: this.props.model.redemption })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.model.lastModified != null && prevProps.model.lastModified.at !== this.props.model.lastModified.at) {
      this.setState({ editRedemption: this.props.model.redemption })
    }
  }

  updateCodeType(value) {
    const stock = this.props.model.stock || {}
    let storeType = ''
    if (this.props.model.redemption) {
      storeType = this.props.model.redemption.voucher.storeType
      if (!this.state.editRedemption || !this.state.editRedemption.voucher.ctaLabel) {
        this.props.updateField('redemption.voucher.ctaLabel', storeType === 'ONLINE' ? 'Copy code and go to site' : '')()
        this.props.updateField('redemption.voucher.copyCode', storeType === 'ONLINE')()
      }
    }
    this.props.updateField('redemption.voucher.codeType', value)()

    if (value === 'NONE') {
      this.props.updateField('redemption.voucher.limit', 'MULTIPLE')()
      this.props.updateField('redemption.voucher.presentationMethod', 'VOUCHER')()
      if (!this.state.editRedemption || !this.state.editRedemption.voucher.ctaLabel) {
        this.props.updateField('redemption.voucher.ctaLabel', storeType === 'ONLINE' ? 'Go to site' : '')()
        this.props.updateField('redemption.voucher.copyCode', false)()
      }
      if (stock.limit !== 'UNLIMITED') {
        this.updateStockVolume('UNLIMITED', stock.threshold)
      }
    } else if (value === 'UNIQUE') {
      this.props.updateField('redemption.voucher.limit', 'SINGLE')()
      if (stock.limit !== 'LIMITED') {
        this.updateStockLimit('LIMITED', stock.threshold)
      }
    }
  }

  updateStoreType(value) {
    let codeType = ''
    if (this.props.model.redemption) {
      codeType = this.props.model.redemption.voucher.codeType
    }
    if (value == 'IN_STORE') {
      this.props.updateField('redemption.voucher.presentationMethod', 'BARCODE')()
      this.props.updateField('redemption.voucher.showOfferInPrizeDraw', 'false')()
    }
    this.props.updateField('redemption.voucher.storeType', value)()
    if (!this.state.editRedemption || !this.state.editRedemption.voucher.ctaLabel) {
      if (codeType === 'NONE') {
        this.props.updateField('redemption.voucher.ctaLabel', value === 'ONLINE' ? 'Go to site' : '')()
        this.props.updateField('redemption.voucher.copyCode', false)()
      } else if (codeType === 'GENERIC' || codeType === 'UNIQUE') {
        this.props.updateField('redemption.voucher.ctaLabel', value === 'ONLINE' ? 'Copy code and go to site' : '')()
        this.props.updateField('redemption.voucher.copyCode', value === 'ONLINE')()
      }
    }
  }

  updateCopyCode(value) {
    let codeType = ''
    let storeType = ''
    if (this.props.model.redemption) {
      codeType = this.props.model.redemption.voucher.codeType
      storeType = this.props.model.redemption.voucher.storeType
    }

    this.props.updateField('redemption.voucher.copyCode', value)()
    if ((!this.state.editRedemption || !this.state.editRedemption.voucher.ctaLabel) && (codeType === 'GENERIC' || codeType === 'UNIQUE')) {
      this.props.updateField('redemption.voucher.ctaLabel', storeType === 'ONLINE' && value ? 'Copy code and go to site' : 'Go to site')()
    }
  }

  updateStockVolume(limit, threshold) {
    this.props.updateField('stock.limit', limit)()
    this.props.updateStockVolume(this.props.model, limit, threshold, 0)
  }

  updateStockLimit(limit) {
    this.props.updateField('stock.limit', limit)()
    if (limit === 'UNLIMITED') {
      this.props.updateField('stock.threshold', null)()
    }
    this.props.updateStockVolume(this.props.model, limit, this.props.model.stock.threshold, 0)
  }

  updateStockThreshold(threshold) {
    this.props.updateField('stock.threshold', threshold.target.value)()
    this.props.updateStockVolume(this.props.model, this.props.model.stock.limit, threshold.target.value, 0)
  }

  formatHistoryEntryType(type) {
    switch (type) {
      case 'STOCK_TOPUP':
        return 'Topup'
      case 'SCHEDULED_STOCK':
        return 'Scheduled'
      case 'STOCK_DEPLETED':
        return 'Depleted'
      case 'STOCK_REMOVED':
        return 'Removed'
      default:
        return type
    }
  }

  calculateRedemptionCountUpTo(id) {
    const history = this.props.model.stock.history

    return history.filter(item => item.id <= id).reduce((acc, item) => {
      switch (item.type) {
        case 'STOCK_TOPUP':
        case 'SCHEDULED_STOCK':
          return acc + item.volume
        case 'STOCK_REMOVED':
          return item.id === id ? acc + item.volume : 0
        default:
          return 0
      }
    }, 0)
  }

  formatVolume(item) {
    switch (item.type) {
      case 'STOCK_TOPUP':
      case 'SCHEDULED_STOCK':
        return formatNumber(item.volume)
      case 'STOCK_REMOVED':
        return `${formatNumber(item.volume)} (${formatNumber(this.calculateRedemptionCountUpTo(item.id))} accepted)`
      default:
        return 0
    }
  }

  canDownloadCodes(id) {
    const history = this.props.model.stock.history
    const removedLater = history.findIndex(item => item.id >= id && item.type === 'STOCK_REMOVED') > -1
    return !removedLater
  }

  isOfferRedeemable() {
    return this.props.model.isPublished
  }

  render() {
    const { validationErrors, formDisabled, updateField, stockValidationErrors } = this.props
    const redemption = this.props.model.redemption || {}
    const voucher = redemption.voucher || {}
    const stock = this.props.model.stock || {}
    const brand = this.props.model.brand || {}
    const beacon = brand.beacon || {}
    const offerScheduleSet = this.props.model.schedule ? this.props.model.schedule.redeemFromDateTime && this.props.model.schedule.redeemToDateTime : false
    const stockHistory = stock.history || []
    const stockHistoryTotal = _.sum(stockHistory.map(item => item.volume))
    const presentationMethodOptions = voucherPresentationMethodOptions.slice(0)
    const barCodeOptionIndex = presentationMethodOptions.findIndex(opt => opt.value === 'BARCODE')
    const beaconOptionIndex = presentationMethodOptions.findIndex(opt => opt.value === 'BEACON')

    if (barCodeOptionIndex > -1) {
      presentationMethodOptions[barCodeOptionIndex].disabled = voucher.codeType === 'NONE'
    }
    if (beaconOptionIndex > -1) {
      presentationMethodOptions[beaconOptionIndex].disabled = !beacon.uuid || !beacon.major || beacon.uuid === '' || beacon.major === ''
    }

    return (
      <div>
        <Panel header='Voucher details' id='redemption'>
          <FormField label='Voucher Codes' id='codeType' mandatory
                     type='radiolist' inline
                     value={voucher.codeType}
                     options={voucherCodeTypeOptions}
                     onChange={(value) => this.updateCodeType(value)}
                     disabled={formDisabled || stock.cumulativeRemainingStock !== 0}
                     error={_.get(validationErrors, 'redemption.voucher.codeType')}
          />
          {voucher.codeType === 'GENERIC' ? (
            <FormField label='Generic Voucher Code' id='genericCode' mandatory
                       value={voucher.genericCode || ''}
                       onChange={updateField('redemption.voucher.genericCode')}
                       disabled={formDisabled}
                       error={_.get(validationErrors, 'redemption.voucher.genericCode')}
            />
          ) : null}
          <FormField label='Customer Limit' id='limit' mandatory
                     type='radiolist' inline
                     value={voucher.limit}
                     options={voucherLimitOptions}
                     onChange={(value) => updateField('redemption.voucher.limit', value)()}
                     disabled={formDisabled || voucher.codeType === 'NONE' || voucher.codeType === 'UNIQUE'}
                     error={_.get(validationErrors, 'redemption.voucher.limit')}
          />
          <FormField label='Redemption Method' id='storeType' mandatory
                     type='radiolist' inline
                     value={voucher.storeType}
                     options={voucherStoreTypeOptions}
                     onChange={(value) => this.updateStoreType(value)}
                     disabled={formDisabled}
                     error={_.get(validationErrors, 'redemption.voucher.storeType')}
          />
          {voucher.storeType === 'ONLINE' ? (
            <FormField label='Show offer in Prize draw' id='showOfferInPrizeDraw'
                       value={voucher.showOfferInPrizeDraw}
                       type='checkbox' inline
                       onChange={(e) => updateField('redemption.voucher.showOfferInPrizeDraw', e.target.checked)()}
                       disabled={formDisabled}
            />
            ) : null}
          {voucher.storeType === 'ONLINE' ? (
            <FormField label='URL' id='url' placeholder='https://'
                       mandatory
                       value={voucher.url || ''}
                       onChange={updateField('redemption.voucher.url')}
                       disabled={formDisabled}
                       error={_.get(validationErrors, 'redemption.voucher.url')}
            />
          ) : null}
          {voucher.codeType !== 'NONE' && voucher.storeType === 'ONLINE' ? (
            <div className='info-msg' id='infoMsg'>Remember to add query parameter in the URL</div>
          ) : null}
          {voucher.codeType && voucher.storeType === 'ONLINE' ? (
            <FormField label='CTA Label' id='ctaLabel' mandatory
                       maxLength='30'
                       value={voucher.ctaLabel}
                       onChange={updateField('redemption.voucher.ctaLabel')}
                       disabled={formDisabled}
                       error={_.get(validationErrors, 'redemption.voucher.ctaLabel')}
            />
          ) : null}
          {voucher.storeType === 'ONLINE' && (voucher.codeType === 'GENERIC' || voucher.codeType === 'UNIQUE') ? (
            <FormField label='Hide redemption code' id='hideRedemptionCode'
                       value={voucher.redemptionCodeHidden}
                       type='checkbox' inline
                       onChange={(e) => updateField('redemption.voucher.redemptionCodeHidden', e.target.checked)()}
                       disabled={formDisabled}
            />
          ) : null}
          {voucher.storeType === 'ONLINE' && (voucher.codeType === 'GENERIC' || voucher.codeType === 'UNIQUE') ? (
            <FormField label='Copy code' id='copyCode'
                       value={voucher.copyCode}
                       type='checkbox' inline
                       onChange={(e) => this.updateCopyCode(e.target.checked)}
                       disabled={formDisabled}
            />
          ) : null}

          <FormField label='Customer Instructions' id='customerInstructions'
                     type='textArea' rows='3' mandatory
                     maxLength='72'
                     value={voucher.customerInstructions || ''}
                     onChange={updateField('redemption.voucher.customerInstructions')}
                     disabled={formDisabled}
                     error={_.get(validationErrors, 'redemption.voucher.customerInstructions')}
          />
          {voucher.codeType !== 'NONE' ? (
            <FormField label='Voucher Life (minutes)' id='lifetime'
                       type='number'
                       value={possibleNumberToString(voucher.lifetimeInMins)}
                       mandatory={voucher.storeType === 'IN_STORE' && voucher.limit === 'SINGLE'}
                       onChange={updateField('redemption.voucher.lifetimeInMins')}
                       disabled={formDisabled}
                       error={_.get(validationErrors, 'redemption.voucher.lifetimeInMins')}
            />
          ) : null}
          {voucher.storeType === 'IN_STORE' ? (
            <FormField label='Presentation Method' id='presentationMethod' mandatory
                       type='radiolist' inline
                       value={voucher.presentationMethod}
                       options={presentationMethodOptions}
                       onChange={(value) => updateField('redemption.voucher.presentationMethod', value)()}
                       disabled={formDisabled}
                       error={_.get(validationErrors, 'redemption.voucher.presentationMethod')}
            />
          ) : null}
          {voucher.storeType === 'IN_STORE' && voucher.presentationMethod === 'BEACON' ? (
            <div>
              <label>Beacon details: </label> UUID: {beacon.uuid}, Major: {beacon.major}
            </div>
          ) : null}
        </Panel>
        <Panel header='Stock' id='stock'>
          {stock.limit === 'LIMITED' && voucher.codeType === 'GENERIC' ? (
            <Button id='modify-stock-btn'
                    bsStyle='primary'
                    onClick={() => this.setState({ modifyStockModalOpen: true })}
                    className='pull-right'
            >
              Modify Stock
            </Button>
          ) : null}
          {stock.limit === 'LIMITED' && voucher.codeType === 'UNIQUE' ? (
            <div className='btn-toolbar'>
              <Button id='upload-stock-btn'
                      bsStyle='primary'
                      disabled={stock.scheduleSet}
                      onClick={() => this.setState({ uploadStockModalOpen: true })}
                      className='pull-right'
              >
                Upload Codes
              </Button>

              <Button id='set-schedule-btn'
                      bsStyle='primary'
                      disabled={(!stock.scheduleSet && _.takeWhile(stock.history, { type: 'SCHEDULED_STOCK' }).length > 0) || !offerScheduleSet}
                      onClick={() => this.setState({ uploadStockModalOpen: true, scheduledUpload: true })}
                      className='pull-right'
              >
                Set Schedule
              </Button>
            </div>
          ) : null}
          <FormField label='Stock Level' id='stock-limit' mandatory
                     type='radiolist' inline
                     value={stock.limit}
                     options={stockLimitOptions}
                     onChange={(limit) => this.updateStockLimit(limit)}
                     disabled={formDisabled || voucher.codeType === 'NONE' || voucher.codeType === 'UNIQUE'}
          />
          {stock.limit === 'LIMITED' ? (
            <FormField label='Stock Threshold' id='threshold'
                       type='number'
                       min={0}
                       value={possibleNumberToString(stock.threshold)}
                       onChange={(threshold) => this.updateStockThreshold(threshold)}
                       disabled={formDisabled}
                       error={stockValidationErrors.threshold}
                       mandatory
            />
          ) : null}
          {validationErrors.stock.cumulativeRemainingStock ? (
            <label className='input-error text-danger'>{validationErrors.stock.cumulativeRemainingStock}</label>
          ) : null}
          {stock.limit === 'LIMITED' && stockHistory.length > 0 ? (
            <div>
              <p>
                <strong>Change History</strong>
              </p>
              <Table striped bordered id='offer-stock-history'>
                <thead>
                <tr>
                  <th>Time</th>
                  <th>Type</th>
                  {stock.scheduleSet ? (
                    <React.Fragment>
                      <th>FileName</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                    </React.Fragment>
                  ) : null}
                  <th className='text-right'>Volume</th>
                  {voucher.codeType === 'UNIQUE' ? (
                    <th />
                  ) : null}
                </tr>
                </thead>
                <tbody>
                {stockHistory.map((item, index) => (
                  <tr key={`stock-history-${index}`} id={`stock-history-${index}`}>
                    <td className='date'>{formatFullDateTime(item.date)}</td>
                    <td>{this.formatHistoryEntryType(item.type)}</td>
                    {stock.scheduleSet ? (
                      <React.Fragment>
                        <td>{item.filename}</td>
                        <td>{formatDateTime(item.scheduleStartDateTime)}</td>
                        <td>{formatDateTime(item.scheduleEndDateTime)}</td>
                      </React.Fragment>
                    ) : null}
                    <td className='volume text-right'>{this.formatVolume(item)}</td>
                    {voucher.codeType === 'UNIQUE' ? (
                      <td>
                        {item.id !== undefined && (item.type === 'STOCK_TOPUP' || item.type === 'SCHEDULED_STOCK') && this.canDownloadCodes(item.id) ? (
                          <a className='link' target='_blank' onClick={() => this.props.downloadUniqueCodes(item.id)}>Download</a>
                        ) : null}
                      </td>
                    ) : null}
                  </tr>
                ))}
                <tr>
                  <td className='text-right'>Total volume</td>
                  <td />
                  {stock.scheduleSet ? (
                    <React.Fragment>
                      <td />
                      <td />
                      <td />
                    </React.Fragment>
                  ) : null}
                  <td id='stock-history-total' className='volume text-right'>{formatNumber(stockHistoryTotal || 0)}</td>
                  {voucher.codeType === 'UNIQUE' && stock.cumulativeRemainingStock !== 0 ? (
                    <td id='stock-delete'>
                      {this.isOfferRedeemable() ? (
                        <div title='Please pause the offer first'>
                          <a className='link deleteUnusedCodes disabled' title='Please pause the offer first'>Delete unused codes</a>
                        </div>
                      ) : (
                        <a className='link deleteUnusedCodes' onClick={() => this.setState({ confirmStockDeleteModalOpen: true })}>Delete unused codes</a>
                      )
                      }
                      <a className='link downloadUnusedCodes' target='_blank' onClick={() => this.props.downloadUnusedCodes()}>Download unused stock codes</a>
                    </td>
                  ) : null}
                </tr>
                </tbody>
              </Table>
            </div>
          ) : null}
          <Table striped bordered>
            <thead>
            <tr>
              {stock.limit === 'LIMITED' ? (
                <th>Remaining</th>
              ) : null}
              <th>Accepted</th>
              {/* <th>Saved</th> */}
            </tr>
            </thead>
            <tbody>
            <tr>
              {stock.limit === 'LIMITED' ? (
                <td id='offer-remaining-stock-count'>{formatNumber(stock.cumulativeRemainingStock || 0)}</td>
              ) : null}
              <td id='offer-redemption-count'>{formatNumber(stock.redemptionCount || 0)}</td>
              {/* <td id = 'offer-saved-count'>{formatNumber(stock.savedCount || 0)}</td> */}
            </tr>
            </tbody>
          </Table>
          {(stock.cumulativeRemainingStock === 0 && stock.limit === 'LIMITED') ? (
            <label className='input-error text-danger'>Out of stock</label>
          ) : null}
          <ModifyStockModal visible={this.state.modifyStockModalOpen}
                            onSave={(volume) => this.props.updateStockVolume(this.props.model, stock.limit, stock.threshold, volume)}
                            onClose={() => this.setState({ modifyStockModalOpen: false })}
                            stock={stock}
                            validationErrors={validationErrors}
          />
          <UploadStockModal visible={this.state.uploadStockModalOpen}
                            scheduled={this.state.scheduledUpload}
                            onSave={(file, scheduledStartDateTime, scheduledEndDateTime) => this.props.uploadUniqueCodes(this.props.model, file, scheduledStartDateTime, scheduledEndDateTime)}
                            onClose={() => this.setState({ uploadStockModalOpen: false, scheduledUpload: false })}
                            stock={stock}
                            validationErrors={validationErrors}
          />
          <ConfirmStockDeleteModal visible={this.state.confirmStockDeleteModalOpen}
                                   onClose={() => this.setState({ confirmStockDeleteModalOpen: false })}
                                   onConfirm={this.props.deleteAllStock}
          />
        </Panel>
      </div>
    )
  }
}

export default RedemptionPanel
