import React, { Component } from 'react'
import { Panel, Table } from 'react-bootstrap'
import Spinner from './spinner'

import NoMatchesRow from './noMatchesRow'
import Pagination from './pagination'
import { formatDateTime } from '../utils'
import ContentTypeLabel from './contentTypeLabel'

class AuditList extends Component {
  render() {
    const {
      audits,
      pagination,
      status
    } = this.props.audits

    return (
      <Panel header='Audit trail'>
        <div className='content-list'>
          {status === 'LOADING' ? (
            <Spinner />
          ) : null}
          <Table id='audit-table' striped hover>
            <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            {audits.map((audit, index) => {
              return (
                <tr className='cursor-pointer' key={`audit-${index}`} onClick={() => {
                  this.props.onViewAudit(audit)
                }}>
                  <td>{formatDateTime(audit.createdAt)}</td>
                  <td>{`${audit.auditUser.firstName} ${audit.auditUser.lastName}`}</td>
                  <td>
                    <ContentTypeLabel type={audit.contentType} /> {audit.action}
                  </td>
                </tr>
              )
            })}
            {status !== 'LOADING' && audits.length === 0 ? (
              <NoMatchesRow colSpan='7' />
            ) : null}
            </tbody>
          </Table>
        </div>
        {pagination.totalPages > 1 ? (
          <Pagination totalPages={pagination.totalPages}
                      page={pagination.page}
                      onSelect={(page) => this.props.selectPage(page)}
          />
        ) : null}
      </Panel>
    )
  }
}

export default AuditList
