import React from 'react'
import classNames from 'classnames'
import { possibleNumberToString } from '../utils'
import _ from 'lodash'

const RemainingCount = ({
  value,
  maxLength
}) => {
  let stringValue = !_.isNil(value) && !_.isNaN(value) ? String(value) : ''
  if (Array.isArray(value) && document.activeElement.hasAttribute('elementindex')) {
    stringValue = String(value[document.activeElement.getAttribute('elementindex')])
  }
  const count = maxLength - (stringValue || '').length
  const classes = classNames('remaining-count', {
    'text-danger': count < 0
  })

  return (
    <span className={classes}>{possibleNumberToString(count)}</span>
  )
}

export default RemainingCount
