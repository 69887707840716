import _ from 'lodash'
import React from 'react'
import { Button, Col, Form, Panel, Row } from 'react-bootstrap'

import AppError from '../../common/components/appError'
import AuditsPanel from '../../common/components/auditsPanel'
import ContentFormBase from '../../common/components/contentFormBase'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import Spinner from '../../common/components/spinner'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import StatusToolbar from '../../common/components/statusToolbar'
import WebLink from '../../common/components/webLink'
import FormField from '../../common/form/formField'
import ContentItemsPanel from './ContentItemsPanel'
import Breadcrumbs from './SeriesBreadcrumbs'

export default class SeriesForm extends ContentFormBase {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.series || {})
    this.state = {
      saveOrDiscardModalVisible: false,
      dirty: false,
      sorted: false
    }
    this.setDefaults()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.series, this.props.series)) {
      this.model = _.cloneDeep(this.props.series || {})
      this.setDefaults()
      this.forceUpdate()
    }

    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
  }

  setDefaults() {
    this.model.contentItems = this.model.contentItems || {}
    // Setting a default for redemptionType. This is not relevant for Series.
    // But Series shares the same contentItems model as Group/ThankYou
    this.model.contentItems.redemptionType = this.model.contentItems.redemptionType || 'UNLIMITED'
  }

  onSort(rows) {
    const formDisabled = this.isFormDisabled(this.props.series.state, this.props.status)

    if (formDisabled) {
      // different behavior to dirty which allows saving the whole model
      _.set(this.model, 'contentItems.ids', rows)
      this.setState({ sorted: true })
    } else {
      this.updateField('contentItems.ids', rows)()
    }
  }

  updateContents(contents) {
    this.setState({ sorted: false })
    this.props.updateContents(contents).catch(() => {
      this.setState({ sorted: true })
    })
  }

  render() {
    const activeTab = this.props.match.params.section || 'details'
    const sections = ['details', 'contentItems']
    const requiredSections = ['details', 'contentItems']
    const status = this.props.status || 'LOADING'
    const roles = this.props.roles

    const series = _.cloneDeep(this.props.series || {})
    const validationErrors = this.model.validationErrors || {}
    sections.forEach((section) => {
      series[section] = series[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })
    const contentList = this.props.contentList || []
    const contentItems = []
    const contentItemIds = this.model.contentItems ? this.model.contentItems.ids || [] : []
    contentItemIds.forEach(idType => {
      const contentItem = contentList.find(item => idType.id === item.id)
      if (contentItem) {
        contentItems.push(contentItem)
      }
    })

    const formDisabled = this.isFormDisabled(series.state, status)
    const liveEdit = this.isLiveEdit(series)

    if (status === 'LOADING') {
      return <Spinner />
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className='page-header'>
              <div className='page-header--heading'>
                <div className='page-header--top'>
                  <ContentTypeLabel className='page-header--type' type={series.type} />
                  <StatusLabel className='page-header--state-label' status={series.state} />
                  {series.isPublished ? (
                    <WebLink content={series} />
                  ) : null}
                  {liveEdit ? (
                    <StatusLabelCustom className='page-header--state-label' status='live_edit' text='Editing published content' />
                  ) : null}
                </div>
                <h1 className='content-heading'>{series.details.title || 'New Series'}</h1>
              </div>
              <div className='page-header--tools'>
              {!this.state.dirty && this.state.sorted ? (
                  <Button bsStyle='primary'
                          onClick={() => this.updateContents(contentItemIds)}
                  >
                    Save
                  </Button>
                ) : (
                  <StatusToolbar status={series.state}
                                 contentComplete={this.isComplete(series, validationErrors, sections, requiredSections)}
                                 dirty={this.state.dirty}
                                 isLiveEdit={liveEdit}
                                 onSubmit={this.submit()}
                                 onPublish={this.publish()}
                                 onReject={this.reject()}
                                 onSave={this.save()}
                                 onPause={this.pause()}
                                 onResume={this.resume()}
                                 onEdit={this.edit()}
                  />
                )}
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <Breadcrumbs series={series}
                             validationErrors={validationErrors}
                             activeTab={activeTab}
                             onNavigate={(tab) => this.onNavigate(tab)}
                  />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
                <Form>
                {activeTab === 'details' ? (
                    <Panel header='Basic Details' id='details'>
                      {series.id ? (
                        <div className='form-group form-group-inline'>
                          <label className='control-label'>ID:</label> {series.id}
                        </div>
                      ) : null}
                      {series.shortId ? (
                        <div className='form-group form-group-inline' id='series-shortId'>
                          <label className='control-label'>Short ID:</label> {series.shortId}
                        </div>
                      ) : null}
                      <FormField label='Merchant Name' id='merchantName' value={this.model.details ? this.model.details.merchantName : ''}
                                 maxLength='60' mandatory
                                 onChange={this.updateField('details.merchantName')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.merchantName}
                      />
                      <FormField label='Title' id='title' value={this.model.details ? this.model.details.title : ''}
                                 maxLength='60' mandatory
                                 onChange={this.updateField('details.title')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.title}
                      />
                      <FormField label='Search tags (keywords)' id='searchTags'
                                 value={this.model.details ? this.model.details.searchTags : ''}
                                 onChange={this.updateField('details.searchTags')}
                                 disabled={formDisabled}
                      />
                      <FormField label="18+ only" id='ageRestricted'
                                 value={_.get(this.model, 'details.ageRestricted')}
                                 type='checkbox' inline
                                 onChange={(e) => this.updateField('details.ageRestricted', e.target.checked)()}
                                 disabled={formDisabled}
                      />
                    </Panel>
                  ) : null}
                  {activeTab === 'contentItems' ? (
                    <ContentItemsPanel model={this.model}
                                       updateField={this.updateField.bind(this)}
                                       validationErrors={validationErrors}
                                       contentItems={contentItems}
                                       ids={contentItemIds}
                                       onModify={() => this.props.navigateToSelectSeriesItems(this.props.series)}
                                       onSort={(rows) => this.onSort(rows)}
                                       formDisabled={formDisabled}
                                       viewContent={this.props.viewContent}
                    />
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onMount={this.props.fetchAudits}
                                 onViewAudit={this.props.onViewAudit}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                            onSave={() => this.saveAndBack()}
                            onDiscard={() => this.props.navigateToDashboard()}
                            onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
        />
      </div>
    )
  }
}
