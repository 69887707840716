import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'

import ValidationIndicator from '../../common/components/validationIndicator'
import { validate } from '../../common/validation'
import { merchantActions } from '../merchantActions'

export default class Breadcrumbs extends Component {
  getValidationStatuses() {
    // TODO return validationErrors from server for each section and remove client-side validations
    var merchant = this.props.merchant
    if (!merchant) {
      return {
        businessDetails: 'NEW',
        contactDetails: 'NEW',
        addressDetails: 'NEW',
        brands: 'NEW'
      }
    }

    var businessDetailsValid = validate(merchant, {
      companyName: { required: true }
    })
    var contactDetailsValid = validate(merchant, {
      firstName: { required: true },
      lastName: { required: true },
      jobTitle: { required: true },
      email: { required: true },
      phoneNumber: { required: true },
      billingContact: { required: true }
    })
    var addressDetailsValid = validate(merchant, {
      addressLine1: { required: true },
      postCode: { required: true }
    })
    var brandsValid = validate(merchant, {
      brands: { minLength: 1 }
    })

    return {
      businessDetails: businessDetailsValid ? 'VALID' : 'NEW',
      contactDetails: contactDetailsValid ? 'VALID' : 'NEW',
      addressDetails: addressDetailsValid ? 'VALID' : 'NEW',
      brands: brandsValid ? 'VALID' : 'NEW'
    }
  }

  render() {
    var merchant = this.props.merchant || {}
    var statuses = this.getValidationStatuses()
    var path = this.props.match ? this.props.match.path : null

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='merchant-breadcrumbs-back'
                 onClick={() => this.props.onNavigate(merchantActions.navigateToMerchants())}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Merchants
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem disabled={!merchant.id}
                 id='merchant-breadcrumbs-details'
                 active={path === '/merchants/:merchantId/details' || path === '/merchants/new'}
                 onClick={() => this.props.onNavigate(merchantActions.navigateToMerchantBusinessDetails(merchant.id))}
        >
          <ValidationIndicator status={statuses.businessDetails} />
          {' '}
          Business Details
        </NavItem>
        <NavItem id='merchant-breadcrumbs-brands'
                 disabled={!merchant.id}
                 active={path === '/merchants/:merchantId/brands' || path === '/merchants/:merchantId/brands/:brandId/locations'}
                 onClick={() => this.props.onNavigate(merchantActions.navigateToBrands(merchant.id))}
        >
          <ValidationIndicator status={statuses.brands} />
          {' '}
          Brands
        </NavItem>
      </Nav>
    )
  }
}
