import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'

import AuditsPanel from '../../common/components/auditsPanel'
import AppFlash from '../../common/components/appFlash'
import Breadcrumbs from '../../accounts/components/breadcrumbs'

export default class AuditListing extends Component {
  constructor(props) {
    super(props)
    props.load()
  }

  componentWillUnmount() {
    this.props.unload()
  }

  render() {
    return (
      <div>
        <div className='page-header'>
          <h1>Accounts</h1>
        </div>
        <Row>
          <Col sm={4} lg={3}>
            <Breadcrumbs roles={this.props.roles}
                         profile={this.props.profile}
                         match={this.props.match}
            />
          </Col>
          <Col sm={8} lg={9}>
            {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
            <AuditsPanel audits={this.props.audits}
                         onViewAudit={this.props.onViewAudit}
                         match={this.props.match}
                         selectPage={this.props.selectPage}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
