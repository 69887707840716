import React from 'react'
import { Link } from 'react-router-dom'

const ThankYouLink = ({ model }) => {
  return model.thankYou ? (
    <div className='form-group form-group-inline' id='thankyou-link'>
      <label className='control-label'>Thank You:</label>
      {' '}
      <Link className='link' to={`/thankyous/${model.thankYou.id}`}>{model.thankYou.title}</Link>
    </div>
  ) : null
}

export default ThankYouLink
