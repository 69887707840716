import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import FormField from '../../common/form/formField'

const initialState = {
  selectedFile: null,
  uploading: false,
  error: null
}

class UploadMsisdnModal extends Component {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
  }

  selectFile(file) {
    this.setState({ selectedFile: file })
  }

  close() {
    this.setState(initialState)
    this.props.onClose()
  }

  save() {
    this.setState({ uploading: true })
    this.props.onSave(this.state.selectedFile).then(() => {
      this.props.onClose()
      this.setState(initialState)
    }).catch((err) => {
      this.setState({ uploading: false, error: err })
    })
  }

  render() {
    const disabled = !this.state.selectedFile || this.state.uploading

    return (
      <Modal show={this.props.visible} onHide={() => this.close()}>
        <Modal.Header>
          <h4>Upload Thank You Msisdns</h4>
        </Modal.Header>
        <Modal.Body>
          <FormField label='Thank You Msisdns' id='codes' mandatory
                     type='file'
                     onChange={(e) => this.selectFile(e.target.files[0])}
                     error={this.state.error}
                     uploading={this.state.uploading}
                     help='Upload a .txt or .csv with one msisdn per line'
                     showFileName={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button id='cancel' onClick={() => this.close()}>Cancel</Button>
          <Button id='save' bsStyle='primary'
                  onClick={() => this.save()}
                  disabled={disabled}
          >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default UploadMsisdnModal
