import { connect } from 'react-redux'
import { faqActions } from '../faqActions'
import FaqListing from '../components/faqListing'

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateFaq: (faqSectionId) => {
      dispatch(faqActions.faqNew())
      dispatch(faqActions.navigateToCreateNewFaq(faqSectionId))
    },
    load: (faqSectionId) => {
      dispatch(faqActions.fetchFaqs(faqSectionId))
    },
    onEdit: (faqSectionId, faqId) => {
      dispatch(faqActions.navigateToEditFaq(faqSectionId, faqId))
    },
    onSortSave: (faqsItems, faqSectionId) => {
      dispatch(faqActions.saveFaqsSortOrder(faqsItems, faqSectionId))
    },
    onSortCancel: (faqSectionId) => {
      dispatch(faqActions.fetchFaqs(faqSectionId))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    faqs: state.faqs.faqs || [],
    faqSectionId: ownProps.match.params.faqSectionId,
    status: state.faqs.faqsStatus,
    error: state.faqs.error || ''
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqListing)
