import { constants } from './thankYouActions'

const initialState = {
  thankYou: null,
  manifest: null,
  searchResult: null,
  contentList: []
}

export default function thankYous(state = initialState, action = {}) {
  switch (action.type) {
    case constants.THANK_YOU_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.THANK_YOU_SAVING:
      return Object.assign({}, state, {
        thankYou: action.thankYou,
        status: 'SAVING'
      })
    case constants.THANK_YOU_NEW:
      return Object.assign({}, state, {
        thankYou: action.thankYou,
        manifest: null,
        status: 'SUCCESS'
      })
    case constants.THANK_YOU_FETCHED:
    case constants.THANK_YOU_SAVED:
    case constants.THANK_YOU_SUBMITTED:
    case constants.THANK_YOU_REJECTED:
    case constants.THANK_YOU_PUBLISHED:
    case constants.THANK_YOU_PAUSED:
    case constants.THANK_YOU_RESUMED:
    case constants.THANK_YOU_EDITED:
      return Object.assign({}, state, {
        thankYou: action.thankYou,
        status: 'SUCCESS'
      })
    case constants.THANK_YOU_MANIFEST_FETCHING:
      return Object.assign({}, state, {
        manifest: null
      })
    case constants.THANK_YOU_MANIFEST_FETCHED:
      return Object.assign({}, state, {
        manifest: action.manifest
      })
    case constants.THANK_YOU_CONTENTS_FETCHING:
      return Object.assign({}, state, {
        contentList: []
      })
    case constants.THANK_YOU_CONTENTS_FETCHED:
      return Object.assign({}, state, {
        contentList: action.contentList
      })
    case constants.THANK_YOU_MSISDNS_UPLOADING:
      return Object.assign({}, state, {
        status: 'SAVING'
      })
    case constants.THANK_YOU_MSISDNS_UPLOADED:
      return Object.assign({}, state, {
        status: 'SUCCESS',
        manifest: action.manifest
      })
    case constants.THANK_YOU_MSISDN_ASSOCIATION_SEARCH:
      return Object.assign({}, state, {
        searchResult: {
          status: action.status,
          result: action.profileAssociation,
          style: action.style
        }
      })
    case constants.THANK_YOU_LIST_S3_FILES:
      return Object.assign({}, state, {
        listS3Files: {
          status: 'SUCCESS',
          s3Files: action.s3Files
        }
      })

    default:
      return state
  }
}
