/* global FileReader */
import _ from 'lodash'
import { Component } from 'react'

export default class MediaComponentBase extends Component {
  constructor(props) {
    super(props)
    this.mediaType = props.type
    this.mediaTypeUcf = _.upperFirst(props.type)
    this.state = {
      saveOrDiscardModalVisible: false,
      dirty: false,
      assets: this._getAssetsFromModel(props),
      errors: this._getValidationErrorsFromModel(props)
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps, this.props)) {
      this.setState({
        assets: this._getAssetsFromModel(this.props),
        errors: this._getValidationErrorsFromModel(this.props)
      })
    }
  }

  updateField(path, value) {
    return (e) => {
      _.set(this.state, path, e ? e.target.value : value)
      this.props.updateParentField((!_.isEmpty(this.props.parentMediaProperty) ? this.props.parentMediaProperty : path), value)()
    }
  }

  addValidationError(field, err) {
    const errors = _.cloneDeep(this.state.errors)
    errors[field.replace(/\[[0-9]]/g, '')] = err
    this.setState({
      dirty: true,
      errors
    })
  }

  uploadMedia(file, mediaTarget, field, asset) {
    if (this.state.errors[field]) {
      delete this.state.errors[field]
      delete this.props.model.validationErrors.assets[field]
    }
    this.setState({ uploading: field })
    let mediaFormat
    if (field.toLowerCase().includes('video')) {
      mediaFormat = 'VIDEO'
    } else if (field.toLowerCase().includes('audio')) {
      mediaFormat = 'AUDIO'
    } else {
      mediaFormat = 'IMAGE'
    }
    this.props.uploadMedia(mediaTarget, file, mediaFormat, asset || false)
      .then((image) => {
        this.updateField('assets.' + field, image.url).bind(this)()
      })
      .catch(err => {
        this.addValidationError(field, err)
      })
      .then(() => {
        this.setState({ uploading: false })
      })
  }

  _getAssetsFromModel(props) {
    if (props.model) {
      const assets = props.model.assets ? _.cloneDeep(props.model.assets) : {}
      if (_.isEmpty(assets[props.type + 'ImageUrl']) && !_.isEmpty(props.defaultImageProperty)) {
        // Default properties are managed by the CMS service itself.
        // Here, we simply get the property from the model and add it to the `assets` state key of this components.
        // For all content types which derive default logo from the brand, the CMS service
        // must stake care to "attach" the brand logo URL to the logo URL in the assets
        const defaultImageUrl = _.get(props.model, props.defaultImageProperty)
        if (defaultImageUrl) {
          assets[props.type + 'ImageUrl'] = defaultImageUrl
        }
      }
      return assets
    }
    return {}
  }

  _getValidationErrorsFromModel(props) {
    if (!_.isEmpty(props.model.validationErrors)) {
      if (!_.isEmpty(props.model.validationErrors.assets)) {
        return _.cloneDeep(props.model.validationErrors.assets)
      } else {
        return _.cloneDeep(props.model.validationErrors)
      }
    }
    return {}
  }
}
