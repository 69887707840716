import { commonActions } from '../common/commonActions'
import queryString from 'query-string'

import http, { AUDIT_DETAILS_V1, AUDIT_LIST_V1, config } from '../services/http'

const auditFetching = () => ({
  type: constants.AUDIT_FETCHING
})
const auditFetched = (audit) => ({
  type: constants.AUDIT_FETCHED,
  audit
})
const auditFetchError = (err) => ({
  type: constants.AUDIT_FETCH_ERROR,
  err
})
const fetchAudit = (id) => {
  return (dispatch) => {
    dispatch(auditFetching())
    return http.get(dispatch, `${config.serverUrl}/trails/${id}`, null, {
      Accept: AUDIT_DETAILS_V1
    }).then((resp) => {
      dispatch(auditFetched(resp))
      return resp
    }).catch((err) => {
      dispatch(auditFetchError(err))
      dispatch(commonActions.displayFlash(`Unable to retrieve the audit with id ${id}: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const auditsFetching = () => ({
  type: constants.AUDITS_FETCHING
})
const auditsFetched = (resp) => ({
  type: constants.AUDITS_FETCHED,
  audits: resp.audits,
  totalCount: resp.totalCount
})
const auditsFetchError = (err) => ({
  type: constants.AUDITS_FETCH_ERROR,
  err
})
const fetchAudits = (query) => {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(auditsFetching())
    query = query || queryString.parse(state.router.location.search).query
    const params = {
      query: query,
      limit: state.audits.pagination.perPage,
      offset: state.audits.pagination.perPage * (state.audits.pagination.page - 1)
    }

    return http.get(dispatch, `${config.serverUrl}/trails`, params, {
      Accept: AUDIT_LIST_V1
    }).then((resp) => {
      dispatch(auditsFetched(resp))
      return resp
    }).catch((err) => {
      dispatch(auditsFetchError(err))
      dispatch(commonActions.displayFlash(`Unable to retrieve audits: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const selectPage = (page) => ({
  type: constants.AUDITS_SELECT_PAGE,
  page
})

const clearAudits = () => ({
  type: constants.AUDITS_CLEAR
})

export const auditActions = {
  fetchAudits,
  fetchAudit,
  auditFetched,
  clearAudits,
  selectPage
}

export const constants = {
  AUDITS_FETCHING: 'AUDITS_FETCHING',
  AUDITS_FETCHED: 'AUDITS_FETCHED',
  AUDITS_FETCH_ERROR: 'AUDITS_FETCH_ERROR',
  AUDIT_FETCHING: 'AUDIT_FETCHING',
  AUDIT_FETCHED: 'AUDIT_FETCHED',
  AUDIT_FETCH_ERROR: 'AUDIT_FETCH_ERROR',
  AUDITS_CLEAR: 'AUDITS_CLEAR',
  AUDITS_SELECT_PAGE: 'AUDITS_SELECT_PAGE'
}
