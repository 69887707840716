import { connect } from 'react-redux'
import { contentActions } from '../contentActions'
import SortItems from '../components/sortItems'
import { categoryOptions } from '../../common/enums'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: (categoryPath) => {
      const category = categoryOptions.find((opt) => opt.path === categoryPath)
      dispatch(contentActions.clearContents())
      dispatch(contentActions.fetchCategoryContents(category.value))
    },
    updateFilters: (filters) => {
      dispatch(contentActions.setFilters('sort', filters, false))
    },
    viewContent: (contentItem = {}, section = 'details') => {
      dispatch(contentActions.navigateToContentItem(contentItem, section))
    },
    onSave: (category, ids, contentItems) => {
      dispatch(contentActions.updateCategory(category, { ids }, contentItems))
    },
    navigateToFeaturedContentsTitle: () => {
      dispatch(contentActions.navigateToFeaturedContentsTitle())
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const categoryPath = ownProps.match.params.categoryPath
  const category = categoryOptions.find((opt) => opt.path === categoryPath)

  const ids = state.contents.contentList.map(contentItem => ({
    id: contentItem.id,
    type: contentItem.type
  }))

  return {
    contents: state.contents.contentList,
    ids: ids,
    category: category,
    status: state.contents.status,
    error: state.contents.error,
    selectedFilters: state.contents.selectedFilters.sort
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SortItems)
