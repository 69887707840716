import { connect } from 'react-redux'
import AlternateTitlesLayout from '../../common/components/AlternateTitlesLayout'
import { campaignActions } from '../campaignActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const id = ownProps.match.params.id
      dispatch(campaignActions.fetchCampaign(id))
    },
    save: (campaign) => {
      dispatch(campaignActions.saveCampaign(campaign))
      dispatch(campaignActions.navigateToSchedule(ownProps.match.params.id))
    },
    backHandler: () => {
      dispatch(campaignActions.navigateToSchedule(ownProps.match.params.id))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let loadedContent = {}
  if (state.campaigns.campaign) {
    loadedContent = state.campaigns.campaign
  }
  return {
    contentId: ownProps.match.params.id,
    content: loadedContent,
    contentType: 'campaigns',
    index: ownProps.match.params.index,
    status: state.campaigns
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlternateTitlesLayout)
