import { connect } from 'react-redux'

import { contentActions } from '../../content/contentActions'
import CopyContent from '../components/copyContent'

const mapDispatchToProps = (dispatch) => {
  return {
    onCopy: (contentItem, sections, selectedItems) => {
      dispatch(contentActions.copyContent(contentItem, sections, selectedItems))
    },
    backToContentItem: (contentItem) => {
      dispatch(contentActions.navigateToContentItem(contentItem))
    },
    toggleSelected: (contentItem) => {
      dispatch(contentActions.toggleSelected(contentItem))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    currentContent: state.contents.currentContent,
    selectedItems: state.contents.selectedItems,
    groupsContentList: state.groups.contentList,
    status: state.contents.status,
    error: state.contents.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyContent)
