import { constants } from './msisdnActions'

const initialState = {}
export default function msisdn(state = initialState, action = {}) {
  switch (action.type) {
    case constants.MSISDNS_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING',
        validationErrors: {},
        error: ''
      })
    case constants.MSISDNS_FETCHED:
      return Object.assign({}, state, {
        msisdns: action.msisdns,
        status: 'SUCCESS'
      })

    case constants.MSISDN_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.MSISDN_FETCHED:
      return Object.assign({}, state, {
        msisdn: action.msisdn,
        status: 'SUCCESS',
        validationErrors: {}
      })

    case constants.MSISDN_NEW:
      return Object.assign({}, state, {
        msisdn: action.msisdn,
        error: action.error,
        validationErrors: action.validationErrors,
        status: 'SUCCESS'
      })
    case constants.MSISDN_SAVING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })

    case constants.MSISDN_CREATED:
      return Object.assign({}, state, {
        msisdn: action.msisdn,
        error: action.error,
        validationErrors: {},
        createStatus: 'SUCCESS'
      })
    case constants.MSISDN_UPDATED:
      return Object.assign({}, state, {
        msisdn: action.msisdn,
        status: 'SUCCESS'
      })
    case constants.MSISDN_DELETED:
      return Object.assign({}, {
        status: 'SUCCESS'
      })

    case constants.MSISDN_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        status: 'FAILED'
      })
    case constants.MSISDN_UPDATE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        status: 'FAILED'
      })

    default:
      return state
  }
}
