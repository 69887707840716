import _ from 'lodash'
import React from 'react'
import { Col, Form, Panel, Row } from 'react-bootstrap'

import AppError from '../../common/components/appError'
import AppFlash from '../../common/components/appFlash'
import AuditsPanel from '../../common/components/auditsPanel'
import ContentFormBase from '../../common/components/contentFormBase'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import RelatedContent from '../../common/components/relatedContent'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import SelectBrandModal from '../../common/components/selectBrandModal'
import Spinner from '../../common/components/spinner'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import StatusToolbar from '../../common/components/statusToolbar'
import WebLink from '../../common/components/webLink'
import FormField from '../../common/form/formField'
import ImageSelector from '../../common/media/ImageSelector'
import DetailsPanel from './DetailsPanel'
import LocationsPanel from './LocationsPanel'
import OfferBreadcrumbs from './OfferBreadcrumbs'
import RedemptionPanel from './RedemptionPanel'
import SchedulePanel from './SchedulePanel'

export default class OfferForm extends ContentFormBase {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.offer || {})
    this.setDefaults()
    this.state = {
      saveOrDiscardModalVisible: false,
      selectedBrand: null,
      dirty: false,
      hideErrorMessageForYTID: false
    }
  }

  setDefaults() {
    this.model.stock = this.model.stock || {}
    this.model.stock.limit = this.model.stock.limit || 'UNLIMITED'
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.offer, this.props.offer)) {
      this.model = _.cloneDeep(this.props.offer || {})
      this.setDefaults()
      this.forceUpdate()
    }

    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
  }

  modifyRelatedContent() {
    this.props.navigateToSelectRelatedContent(this.props.offer)
  }

  render() {
    const locations = this.props.locations || []
    const locationsPage = this.props.locationsPage || []
    const activeTab = this.props.match.params.section || 'details'
    const sections = ['details', 'schedule', 'assets', 'redemption', 'stock', 'relatedContent', 'locations']
    const requiredSections = ['details', 'schedule', 'assets', 'redemption']
    const status = this.props.status || 'LOADING'

    const offer = _.cloneDeep(this.props.offer || {})

    const validationErrors = this.model.validationErrors || {}
    const stockValidationErrors = this.props.stockValidationErrors || {}
    sections.forEach((section) => {
      offer[section] = offer[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })

    const formDisabled = this.isFormDisabled(offer.state, status)
    const liveEdit = this.isLiveEdit(offer)

    if (offer.redemption && offer.redemption.voucher && offer.redemption.voucher.storeType === 'IN_STORE' && locations.length === 0) {
      validationErrors.locations.locations = '"In Store" vouchers require at least one location.'
    } else if (validationErrors.locations) {
      validationErrors.locations = {}
    }

    if (offer.stock && offer.stock.limit === 'LIMITED' && offer.stock.cumulativeRemainingStock === 0) {
      validationErrors.stock.cumulativeRemainingStock = 'Limited stock requires Remaining stock.'
    } else if (validationErrors.stock) {
      validationErrors.stock = {}
    }

    if (offer.stock.limit === 'LIMITED' && offer.stock.threshold === null){
      validationErrors.stock.threshold = 'Stock Threshold is required'
      stockValidationErrors.threshold = 'Stock Threshold is required'
    }

    const hasActiveGallery = !(this.model.assets && (!this.isEmpty(this.model.assets.squareImageUrlGallery) || !this.isEmpty(this.model.assets.landscapeImageUrlGallery)))
    const brands = this.props.brands
    const brandOptions = _.map(brands, b => ({ id: b.brandId, label: `${b.brandName} (${b.companyName})` }))
    const brand = this.model.brand
    const brandModalVisible = status !== 'LOADING' && !brand
    const ids = this.model.relatedContent && this.model.relatedContent.ids || []

    if (status === 'LOADING') {
      return <Spinner />
    }

    let disabledStateInEffect // by default, this is 'undefined' and will not render the 'Disable/Enable' buttons
    if (offer.state === 'LIVE' || offer.state === 'DISABLED') {
      disabledStateInEffect = offer.details.disabled === true
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className='page-header'>
              <div className='page-header--heading'>
                <div className='page-header--top'>
                  <ContentTypeLabel className='page-header--type' type={offer.type} />
                  <StatusLabel className='page-header--state-label' status={offer.state} disabledContent={disabledStateInEffect} />
                  {offer.isPublished ? (
                    <WebLink content={offer} />
                  ) : null}
                  {liveEdit ? (
                    <StatusLabelCustom className='page-header--state-label' status='live_edit' text='Editing published content' />
                  ) : null}
                </div>
                <h1 className='content-heading'>{offer.details.title || 'New Offer'}</h1>
              </div>
              <div className='page-header--tools'>
                <StatusToolbar status={offer.state}
                               contentComplete={this.isComplete(offer, validationErrors, sections, requiredSections)}
                               dirty={this.state.dirty}
                               isLiveEdit={liveEdit}
                               onSubmit={this.submit()}
                               onPublish={this.publish()}
                               onReject={this.reject()}
                               onSave={this.save()}
                               onPause={this.pause()}
                               onResume={this.resume()}
                               onEdit={this.edit()}
                               disabledStateInEffect={disabledStateInEffect}
                               onDisable={this.disable()}
                               onEnable={this.enable()}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <OfferBreadcrumbs offer={offer}
                                  locations={locations}
                                  validationErrors={validationErrors}
                                  activeTab={activeTab}
                                  onNavigate={(tab) => this.onNavigate(tab)}
                                  dirty={this.state.dirty}
                                  showCopy={offer.id}
                                  onCopy={this.copy()}
                />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
                {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
                <Form>
                  {activeTab === 'details' ? (
                    <DetailsPanel model={this.model}
                                  updateField={this.updateField.bind(this)}
                                  validationErrors={validationErrors}
                                  formDisabled={formDisabled}
                                  offer={offer}
                                  brand={brand}
                                  roles={this.props.roles}
                                  preferenceCategories={this.props.preferenceCategories}
                    />
                  ) : null}
                  {activeTab === 'schedule' ? (
                    <SchedulePanel model={this.model}
                                   updateField={this.updateField.bind(this)}
                                   validationErrors={validationErrors}
                                   saveOffer={this.props.save}
                                   navigateToNewOurPicksTrigger={this.props.navigateToNewOurPicksTrigger}
                                   navigateToEditOurPicksTrigger={this.props.navigateToEditOurPicksTrigger}
                                   navigateToOfferAlternateTitle={this.props.navigateToOfferAlternateTitle}
                                   newAlternateTitle={this.props.navigateToNewAlternateTitle}
                                   formDisabled={formDisabled}
                    />
                  ) : null}
                  {activeTab === 'assets' ? (
                    <Panel header='Assets' id='assets'>

                      <ImageSelector label='Logo Image'
                                     type='logo'
                                     mediaTarget='BRAND_LOGO'
                                     mandatory
                                     defaultImageProperty='brand.logoImageUrl'
                                     width={320} height={320}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Landscape Image'
                                     type='landscape'
                                     mediaTarget='LANDSCAPE'
                                     mandatory
                                     width={1125} height={750}
                                     withGallery withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Square Image'
                                     type='square'
                                     mediaTarget='SQUARE'
                                     mandatory
                                     width={750} height={750}
                                     withGallery withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Full Screen Image'
                                     type='fullScreen'
                                     mediaTarget='FULL_SCREEN'
                                     mandatory
                                     width={1080} height={1920}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Circular Image'
                                     type='circular'
                                     mediaTarget='CIRCULAR'
                                     mandatory
                                     width={320} height={320}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <FormField label='Youtube video ID (O2 channel only)' id='youtube-video-id'
                                 value={this.model.assets ? this.model.assets.youtubeVideoId || '' : ''}
                                 onChange={this.updateField('assets.youtubeVideoId')}
                                 disabled={formDisabled || !hasActiveGallery}
                                 error={validationErrors.assets.youtubeVideoId}
                      />
                    </Panel>
                  ) : null}
                  {activeTab === 'redemption' ? (
                    <RedemptionPanel model={this.model}
                                     updateField={this.updateField.bind(this)}
                                     validationErrors={validationErrors}
                                     formDisabled={formDisabled}
                                     updateStockLimit={this.props.updateStockLimit}
                                     updateStockVolume={this.props.updateStockVolume}
                                     uploadUniqueCodes={this.props.uploadUniqueCodes}
                                     downloadUniqueCodes={this.props.downloadUniqueCodes}
                                     deleteAllStock={this.props.deleteAllStock}
                                     stockValidationErrors={stockValidationErrors}
                                     downloadUnusedCodes={this.props.downloadUnusedCodes}
                    />
                  ) : null}
                  {activeTab === 'relatedContent' ? (
                    <RelatedContent relatedContentItems={this.props.relatedContent}
                                    onModify={() => this.modifyRelatedContent()}
                                    onSort={this.updateSortOf('relatedContent.ids')}
                                    ids={ids}
                                    formDisabled={formDisabled}
                                    viewContent={this.props.viewContent}
                    />
                  ) : null}
                  {activeTab === 'locations' ? (
                    <LocationsPanel validationErrors={validationErrors}
                                    locations={locationsPage}
                                    pagination={this.props.pagination}
                                    onSelectPage={(page) => this.props.selectPage(page)}
                                    onModify={() => this.props.navigateToSelectLocations(this.props.offer, this.props.locations)}
                    />
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onMount={this.props.fetchAudits}
                                 onViewAudit={this.props.onViewAudit}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <SelectBrandModal visible={brandModalVisible}
                          onHide={this.props.navigateToDashboard}
                          options={brandOptions}
                          onChange={(brands) => this.selectBrand(brands[0])}
                          onCancel={() => this.props.navigateToDashboard()}
                          onSave={() => this.updateBrandId()}
                          selectedBrand={this.state.selectedBrand}
        />
        <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                            onSave={() => this.saveAndBack()}
                            onDiscard={() => this.props.navigateToDashboard()}
                            onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
        />
      </div>
    )
  }
}
