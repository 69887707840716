import _ from 'lodash'
import React from 'react'
import { Col, Form, Panel, Row } from 'react-bootstrap'
import AppError from '../../common/components/appError'
import AuditsPanel from '../../common/components/auditsPanel'
import CallToAction from '../../common/components/CallToAction'
import ContentFormBase from '../../common/components/contentFormBase'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import GroupLink from '../../common/components/groupLink'
import PreferenceCategories from '../../common/components/preferenceCategories'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import Spinner from '../../common/components/spinner'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import StatusToolbar from '../../common/components/statusToolbar'
import ThankYouLink from '../../common/components/thankYouLink'
import { accountTypeOptions, bannerAndCampaignTypeOptions } from '../../common/enums'
import FormField from '../../common/form/formField'
import ImageSelector from '../../common/media/ImageSelector'
import Breadcrumbs from './BannerBreadcrumbs'
import SchedulePanel from './SchedulePanel'

export default class BannerForm extends ContentFormBase {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.banner || {})
    this.state = {
      saveOrDiscardModalVisible: false,
      dirty: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.banner, this.props.banner)) {
      this.model = _.cloneDeep(this.props.banner || {})
      this.forceUpdate()
    }

    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
  }

  render() {
    const activeTab = this.props.match.params.section || 'details'
    const sections = ['details', 'schedule', 'assets', 'callToAction']
    const requiredSections = ['details', 'schedule', 'assets', 'callToAction']
    const status = this.props.status || 'LOADING'
    const roles = this.props.roles
    const banner = _.cloneDeep(this.props.banner || {})
    const preferenceCategories = this.props.preferenceCategories

    const validationErrors = this.model.validationErrors || {}
    sections.forEach((section) => {
      banner[section] = banner[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })

    const formDisabled = this.isFormDisabled(banner.state, status)
    const liveEdit = this.isLiveEdit(banner)

    if (status === 'LOADING') {
      return <Spinner />
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className='page-header'>
              <div className='page-header--heading'>
                <div className='page-header--top'>
                  <ContentTypeLabel className='page-header--type' type={banner.type} />
                  <StatusLabel className='page-header--state-label' status={banner.state} />
                  {liveEdit ? (
                    <StatusLabelCustom className='page-header--state-label' status='live_edit' text='Editing published content' />
                  ) : null}
                </div>
                <h1 className='content-heading'>{banner.details.title || 'New Banner'}</h1>
              </div>
              <div className='page-header--tools'>
                <StatusToolbar status={banner.state}
                               contentComplete={this.isComplete(banner, validationErrors, sections, requiredSections)}
                               dirty={this.state.dirty}
                               isLiveEdit={liveEdit}
                               onSubmit={this.submit()}
                               onPublish={this.publish()}
                               onReject={this.reject()}
                               onSave={this.save()}
                               onPause={this.pause()}
                               onResume={this.resume()}
                               onEdit={this.edit()}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <Breadcrumbs banner={banner}
                             validationErrors={validationErrors}
                             activeTab={activeTab}
                             onNavigate={(tab) => this.onNavigate(tab)}
                             showCopy={banner.id}
                             onCopy={this.copy()}
                />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
                <Form>
                  {activeTab === 'details' ? (
                    <Panel header='Basic Details' id='details'>
                      {banner.id ? (
                        <div className='form-group form-group-inline'>
                          <label className='control-label'>ID:</label>
                          {' '}
                          {banner.id}
                        </div>
                      ) : null}
                      {banner.shortId ? (
                        <div className='form-group form-group-inline' id='banner-shortId'>
                          <label className='control-label'>Short ID:</label> {banner.shortId}
                        </div>
                      ) : null}
                      <GroupLink model={banner} />
                      <ThankYouLink model={banner} />
                      <FormField label='Title' id='title' value={this.model.details ? this.model.details.title : ''}
                                 maxLength='60'
                                 onChange={this.updateField('details.title')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.title}
                      />
                      <FormField label='Subtitle' id='subtitle' value={this.model.details ? this.model.details.subtitle : ''}
                                 maxLength='60'
                                 onChange={this.updateField('details.subtitle')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.subtitle}
                      />
                      <FormField label='Search tags (keywords)' id='searchTags'
                                 value={this.model.details ? this.model.details.searchTags : ''}
                                 onChange={this.updateField('details.searchTags')}
                                 disabled={formDisabled}
                      />
                      <FormField label='Internal Reference' id='internalReference' value={this.model.details ? this.model.details.internalReference : ''}
                                 maxLength='30'
                                 mandatory
                                 onChange={this.updateField('details.internalReference')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.internalReference}
                      />
                      <FormField label='Sort categories' id='categories'
                                 value={this.model.details ? this.model.details.categories || [] : []}
                                 type='categorieslist'
                                 offerContentType
                                 roles={roles}
                                 onChange={(value) => this.updateField('details.categories', value)()}
                                 disabled={formDisabled}
                                 error={validationErrors.details.categories}
                      />
                      <PreferenceCategories id='preferenceCategories'
                                            value={_.get(this.model, 'details.preferenceCategories') || []}
                                            updateField={this.updateField.bind(this)}
                                            label='Preference Categories'
                                            options={preferenceCategories}
                                            disabled={formDisabled}
                      />
                      <FormField label='Account type' id='accountType'
                                 value={_.get(this.model, 'details.myO2Audience.accountType')}
                                 options={accountTypeOptions}
                                 type='checkboxradiolist' inline
                                 onChange={(value) => this.updateField('details.myO2Audience.accountType', value)()}
                                 disabled={formDisabled}
                                 error={_.get(validationErrors, 'details.myO2Audience.accountType')}
                      />
                      <FormField label="18+ only" id='ageRestricted'
                                 value={_.get(this.model, 'details.ageRestricted')}
                                 type='checkbox' inline
                                 onChange={(e) => this.updateField('details.ageRestricted', e.target.checked)()}
                                 disabled={formDisabled}
                      />
                    </Panel>
                  ) : null}
                  {activeTab === 'schedule' ? (
                    <SchedulePanel model={this.model}
                                   updateField={this.updateField.bind(this)}
                                   validationErrors={validationErrors}
                                   formDisabled={formDisabled}
                                   newTrigger={this.props.navigateToNewOurPicksTrigger}
                                   newAlternateTitle={this.props.navigateToNewAlternateTitle}
                                   navigateToEditOurPicksTrigger={this.props.navigateToEditOurPicksTrigger}
                                   navigateToBannerAlternateTitle={this.props.navigateToBannerAlternateTitle}
                                   saveBanner={this.props.save}
                                   navigateToSchedule={this.props.navigateToSchedule}
                    />
                  ) : null}
                  {activeTab === 'assets' ? (
                    <Panel header='Assets' id='assets'>
                      <ImageSelector label='Landscape Image'
                                     type='landscape'
                                     mediaTarget='LANDSCAPE'
                                     mandatory
                                     width={1125} height={750}
                                     model={this.model}
                                     withBlend
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />
                      <ImageSelector label='Square Image'
                                     type='square'
                                     mediaTarget='SQUARE'
                                     mandatory
                                     width={750} height={750}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />
                    </Panel>
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onMount={this.props.fetchAudits}
                                 onViewAudit={this.props.onViewAudit}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                  {activeTab === 'callToAction' ? (
                    <CallToAction model={this.model}
                                  disabled={formDisabled}
                                  typeOptions={bannerAndCampaignTypeOptions}
                                  calendarContent={this.props.calendarContent}
                                  onSelectCalendarContent={() => this.props.navigateToSelectCalendarItems(this.props.banner)}
                                  validationErrors={validationErrors}
                                  updateParentField={this.updateField.bind(this)}
                                  viewContent={this.props.viewContent}
                                  onUrlTypeChange={this.onUrlTypeChange}
                    />
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                            onSave={() => this.saveAndBack()}
                            onDiscard={() => this.props.navigateToDashboard()}
                            onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
        />
      </div>
    )
  }
}
