import { connect } from 'react-redux'
import { offerActions } from '../offerActions'
import AlternateTitlesLayout from '../../common/components/AlternateTitlesLayout'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const id = ownProps.match.params.id
      dispatch(offerActions.fetchOffer(id))
    },
    save: (offer) => {
      dispatch(offerActions.saveOffer(offer))
      dispatch(offerActions.navigateToSchedule(ownProps.match.params.id))
    },
    backHandler: () => {
      dispatch(offerActions.navigateToSchedule(ownProps.match.params.id))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let loadedContent = {}
  if (state.offers.offer) {
    loadedContent = state.offers.offer
  }
  return {
    contentId: ownProps.match.params.id,
    content: loadedContent,
    contentType: 'offers',
    shortTitle: true,
    index: ownProps.match.params.index,
    status: state.offers.status
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlternateTitlesLayout)
