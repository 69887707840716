import React, { Component } from 'react'
import { Button, Panel } from 'react-bootstrap'
import FormField from '../../common/form/formField'
import Label from '../../common/form/label'
import DateTime from '../../common/form/dateTime'
import TriggersTable from '../../common/components/triggersTable'
import DeleteOrDiscardModal from '../../common/components/deleteOrDiscardModal'
import AlternateTitlesTable from '../../common/components/AlternateTitlesTable'

class SchedulePanel extends Component {
  constructor(props) {
    super(props)

    const schedule = props.model.schedule || {}
    this.state = {
      comingSoon: schedule.displayFromDateTime !== schedule.redeemFromDateTime,
      extendVisibility: schedule.displayToDateTime !== schedule.redeemToDateTime
    }

    this.onConfirmationDeleteTrigger = this.onConfirmationDeleteTrigger.bind(this)
    this.onConfirmationDeleteAlternateTitle = this.onConfirmationDeleteAlternateTitle.bind(this)
    this.onEditTrigger = this.onEditTrigger.bind(this)
    this.editAlternateTitleEntry = this.editAlternateTitleEntry.bind(this)
    this.navigateToOfferAlternateTitleForm = this.navigateToOfferAlternateTitleForm.bind(this)
  }

  navigateToOfferAlternateTitleForm(props) {
    props.navigateToOfferAlternateTitle(props)
  }

  editAlternateTitleEntry(id) {
    this.props.model.alternateTitleOfferId = id
    this.props.navigateToOfferAlternateTitle(this.props)
  }

  toggleComingSoon() {
    const schedule = this.props.model.schedule || {}
    if (this.state.comingSoon) {
      const redeemFromDateTime = schedule.redeemFromDateTime
      this.props.updateField('schedule.displayFromDateTime', redeemFromDateTime)()
    }
    this.setState({ comingSoon: !this.state.comingSoon })
  }

  updateRedeemFromDateTime(value) {
    this.props.updateField('schedule.redeemFromDateTime', value)()
    if (!this.state.comingSoon) {
      this.props.updateField('schedule.displayFromDateTime', value)()
    }
  }

  toggleExtendVisibility() {
    const schedule = this.props.model.schedule || {}
    if (this.state.extendVisibility) {
      const redeemToDateTime = schedule.redeemToDateTime
      this.props.updateField('schedule.displayToDateTime', redeemToDateTime)()
    }
    this.setState({ extendVisibility: !this.state.extendVisibility })
  }

  updateRedeemToDateTime(value) {
    this.props.updateField('schedule.redeemToDateTime', value)()
    if (!this.state.extendVisibility) {
      this.props.updateField('schedule.displayToDateTime', value)()
    }
  }

  onConfirmationDeleteTrigger(index) {
    this.showDeleteOrDiscard('Trigger')
    this.setState({ indexToBeDeleted: index })
  }

  onConfirmationDeleteAlternateTitle(key) {
    this.showDeleteOrDiscard('Alternate Title')
    this.setState({ indexToBeDeleted: key })
  }

  showDeleteOrDiscard(type) {
    this.setState({ deleteOrDiscardModalVisible: true })
    this.deleteType = type
  }

  onDelete(indexToBeDeleted, type) {
    this.model = this.props.model
    type === 'Trigger' ? this.model.schedule.triggers.splice(indexToBeDeleted, 1) : this.model.schedule.alternateTitles.splice(indexToBeDeleted, 1)
    this.props.saveOffer(this.model)
  }

  onEditTrigger(index) {
    this.props.navigateToEditOurPicksTrigger(this.props.model, index)
  }

  render() {
    const schedule = this.props.model.schedule || {}
    const formDisabled = this.props.formDisabled
    const validationErrors = this.props.validationErrors
    const updateField = this.props.updateField

    return (
      <div>
        <Panel header='Schedule' id='schedule'>
          <div className='form-group form-group-checkbox'>
            <label className='control-label' htmlFor='coming-soon'>
              Coming Soon
            </label>
            <div>
              <input type='checkbox' id='coming-soon'
                     checked={this.state.comingSoon}
                     onChange={this.toggleComingSoon.bind(this)}
                     disabled={formDisabled}
              />
            </div>
          </div>
          <div className='form-group form-group-datetime'>
            {this.state.comingSoon ? (
              <DateTime id='display-from' mandatory
                        value={schedule.displayFromDateTime || ''}
                        onChange={(value) => updateField('schedule.displayFromDateTime', value)()}
                        disabled={formDisabled}
              />
            ) : null}
            {this.state.comingSoon && validationErrors.schedule.displayFromDateTime ? (
              <Label className='input-error text-danger'>
                {validationErrors.schedule.displayFromDateTime}
              </Label>
            ) : null}
          </div>
          <FormField label='Redeem Start' type='datetime' id='redeem-from'
                     mandatory
                     value={schedule.redeemFromDateTime || ''}
                     onChange={(value) => this.updateRedeemFromDateTime(value)}
                     disabled={formDisabled}
                     error={validationErrors.schedule.redeemFromDateTime}
          />
          <FormField label='Redeem End' type='datetime' id='redeem-to'
                     mandatory
                     value={schedule.redeemToDateTime || ''}
                     onChange={(value) => this.updateRedeemToDateTime(value)}
                     disabled={formDisabled}
                     error={validationErrors.schedule.redeemToDateTime}
          />
          <div className='form-group form-group-checkbox'>
            <label className='control-label' htmlFor='extend-visibility'>
              Extend Visibility
            </label>
            <div>
              <input type='checkbox' id='extend-visibility'
                     checked={this.state.extendVisibility}
                     onChange={this.toggleExtendVisibility.bind(this)}
                     disabled={formDisabled}
              />
            </div>
          </div>
          <div className='form-group form-group-datetime'>
            {this.state.extendVisibility ? (
              <DateTime id='display-to' mandatory
                        value={schedule.displayToDateTime || ''}
                        onChange={(value) => updateField('schedule.displayToDateTime', value)()}
                        disabled={formDisabled}
              />
            ) : null}
            {this.state.extendVisibility && validationErrors.schedule.displayToDateTime ? (
              <Label className='input-error text-danger'>
                {validationErrors.schedule.displayToDateTime}
              </Label>
            ) : null}
          </div>
        </Panel>
        <Panel header='Our Picks Trigger'>
          <div className='page-header--tools'>
            <Button disabled={formDisabled} bsStyle='primary' id='create-trigger' onClick={() => this.props.navigateToNewOurPicksTrigger(this.props.model)}>New Trigger</Button>
            <div className='triggers-list'>
              <TriggersTable triggers={schedule.triggers}
                             validationErrors={validationErrors}
                             onConfirmationDelete={this.onConfirmationDeleteTrigger}
                             onEditTrigger={this.onEditTrigger}
                             formDisabled={formDisabled}
              />
            </div>
          </div>
        </Panel>
        <Panel header='Alternate Titles' id='offers-alternate-titles-table'>
          <Button disabled={formDisabled}
                  bsStyle='primary'
                  id='create-alternateTitle'
                  onClick={() => this.props.newAlternateTitle(this.props.model)}>New Title</Button>
          <AlternateTitlesTable alternateTitleContent={schedule.alternateTitles}
                                delete={this.onConfirmationDeleteAlternateTitle}
                                error={validationErrors}
                                edit={this.editAlternateTitleEntry}
                                formDisabled={formDisabled}
          />
        </Panel>
        <DeleteOrDiscardModal visible={this.state.deleteOrDiscardModalVisible}
                              message={'Do you want to delete this ' + this.deleteType + '?'}
                              onDelete={() => {
                                this.onDelete(this.state.indexToBeDeleted, this.deleteType)
                                this.setState({ deleteOrDiscardModalVisible: false })
                              }}
                              onDiscard={() => {
                                this.setState({ deleteOrDiscardModalVisible: false })
                                this.props.navigateToSchedule(this.props.model.id)
                              }
                              }
        />
      </div>
    )
  }
}

export default SchedulePanel
