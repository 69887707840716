import React, { Component } from 'react'

export default class TextBox extends Component {
  constructor(props) {
    super(props)
    this.state = { value: (props.value === null || props.value === undefined) ? '' : props.value }
    this._handleChange = this._handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: (this.props.value === null || this.props.value === undefined) ? '' : this.props.value })
    }
  }

  _handleChange(e) {
    this.props.onChange(e)

    this.setState({ value: e.target.value })
  }

  render() {
    const containerClasses = this.props.className || undefined
    const type = this.props.type || 'text'
    const id = this.props.id
    const placeholder = this.props.placeholder || ''
    const value = this.state.value

    const element = (
      <input type={type} id={id} name={id}
             className='form-control'
             value={value}
             min={this.props.min} max={this.props.max}
             onChange={this._handleChange}
             disabled={this.props.disabled || false}
             placeholder={placeholder}
             style={this.props.style}
      />
    )

    return (
      <div className={containerClasses}>
        {element}
      </div>
    )
  }
}
