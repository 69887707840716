import http, { config, CONTENT_LIST_V1, EVENT_DATE_LIST_V1, TOUR_DETAILS_V1 } from '../services/http'

import { push } from 'connected-react-router'

const createTour = (tour) => {
  return (dispatch) => {
    dispatch(tourSaving(tour))
    return http.post(dispatch, `${config.serverUrl}/tours/`, tour, {
      Accept: TOUR_DETAILS_V1
    }).then((resp) => {
      dispatch(tourAdded(resp))
      return resp
    }).catch((error) => {
      dispatch(tourError(`Unable to create the new tour: ${error.status} (${error.statusText})`))
    })
  }
}

const updateTour = (tour) => {
  return (dispatch) => {
    delete tour.eventDates
    dispatch(tourSaving(tour))
    return http.put(dispatch, `${config.serverUrl}/tours/${tour.id}`, tour, {
      Accept: TOUR_DETAILS_V1
    }).then((resp) => {
      dispatch(tourSaved(resp))
      return resp
    }).catch((error) => {
      dispatch(tourError(`Unable to update the tour: ${error.status} (${error.statusText})`))
    })
  }
}

const saveTour = (tour) => {
  if (!tour.id) {
    return createTour(tour)
  } else {
    return updateTour(tour)
  }
}

const tourAdded = (tour) => ({
  type: constants.TOUR_SAVED,
  tour
})

const newTour = () => ({
  type: constants.TOUR_NEW,
  tour: {
    type: 'TOUR',
    details: {
      categories: []
    }
  }
})

const fetchTour = (id) => {
  return (dispatch) => {
    dispatch(tourFetching(id))
    return http.get(dispatch, `${config.serverUrl}/tours/${id}`, null, {
      Accept: TOUR_DETAILS_V1
    }).then((tour) => {
      dispatch(tourFetched(tour))
    }).catch((error) => {
      dispatch(tourError(`Unable to retrieve the tour with id ${id}: ${error.status} (${error.statusText})`))
    })
  }
}

const relatedContentFetching = () => ({
  type: constants.TOUR_RELATED_CONTENT_FETCHING
})
const relatedContentFetched = (relatedContent) => ({
  type: constants.TOUR_RELATED_CONTENT_FETCHED,
  relatedContent
})
const fetchRelatedContent = (id) => {
  return (dispatch) => {
    dispatch(relatedContentFetching())
    return http.get(dispatch, `${config.serverUrl}/tours/${id}/relatedContent`, null, {
      Accept: CONTENT_LIST_V1
    }).then((relatedContent) => {
      dispatch(relatedContentFetched(relatedContent))
    }).catch((error) => {
      dispatch(tourError(`Unable to fetch the tour related content: ${error.status} (${error.statusText})`))
    })
  }
}

const eventDatesFetching = () => ({
  type: constants.TOUR_EVENT_DATES_FETCHING
})
const eventDatesFetched = (eventDates) => ({
  type: constants.TOUR_EVENT_DATES_FETCHED,
  eventDates
})
const fetchEventDates = (id) => {
  return (dispatch) => {
    dispatch(eventDatesFetching())

    return http.get(dispatch, `${config.serverUrl}/tours/${id}/eventDates`, null, {
      Accept: EVENT_DATE_LIST_V1
    }).then((eventDates) => {
      dispatch(eventDatesFetched(eventDates))
    }).catch((error) => {
      dispatch(tourError(`Unable to fetch the tour eventDates: ${error.status} (${error.statusText})`))
    })
  }
}

const tourSaving = (tour) => ({
  type: constants.TOUR_SAVING,
  tour
})
const tourSaved = (tour) => ({
  type: constants.TOUR_SAVED,
  tour
})
const tourFetching = (tourId) => ({
  type: constants.TOUR_FETCHING,
  tourId
})
const tourFetched = (tour) => ({
  type: constants.TOUR_FETCHED,
  tour
})
const tourError = (error) => ({
  type: constants.TOUR_ERROR,
  error: error
})

const tourSubmitted = (tour) => ({
  type: constants.TOUR_SUBMITTED,
  tour
})
const tourRejected = (tour) => ({
  type: constants.TOUR_REJECTED,
  tour
})
const tourPublished = (tour) => ({
  type: constants.TOUR_PUBLISHED,
  tour
})
const tourPaused = (tour) => ({
  type: constants.TOUR_PAUSED,
  tour
})
const tourResumed = (tour) => ({
  type: constants.TOUR_RESUMED,
  tour
})
const tourEdited = (tour) => ({
  type: constants.TOUR_EDITED,
  tour
})

const updateTourStatus = (tour, status, successAction) => {
  return (dispatch) => {
    dispatch(tourSaving(tour))
    return http.post(dispatch, `${config.serverUrl}/tours/${tour.id}/${status}`, null, {
      Accept: TOUR_DETAILS_V1
    }).then((resp) => {
      dispatch(successAction(resp))
      if (status === 'pause' || status === 'resume' || status === 'publish') {
        dispatch(fetchEventDates(tour.id))
      }
    }).catch((error) => {
      dispatch(tourError(`Unable to ${status} tour: ${error.status} (${error.statusText})`))
    })
  }
}

const submitTour = (tour) => updateTourStatus(tour, 'submit', tourSubmitted)
const rejectTour = (tour) => updateTourStatus(tour, 'reject', tourRejected)
const publishTour = (tour) => updateTourStatus(tour, 'publish', tourPublished)
const pauseTour = (tour) => updateTourStatus(tour, 'pause', tourPaused)
const resumeTour = (tour) => updateTourStatus(tour, 'resume', tourResumed)
const editTour = (tour) => updateTourStatus(tour, 'draft', tourEdited)

const updateSoldOut = (tour, props) => {
  return (dispatch) => {
    dispatch(tourSaving(tour))
    return http.post(dispatch, `${config.serverUrl}/tours/${tour.id}/soldOut`, props, {
      Accept: TOUR_DETAILS_V1
    }).then((resp) => {
      dispatch(tourSaved(resp))
    }).catch((error) => {
      dispatch(tourError(`Unable to sell out tour: ${error.status} (${error.statusText})`))
    })
  }
}

const updateCancelled = (tour, props) => {
  return (dispatch) => {
    dispatch(tourSaving(tour))
    return http.post(dispatch, `${config.serverUrl}/tours/${tour.id}/cancel`, props, {
      Accept: TOUR_DETAILS_V1
    }).then((resp) => {
      dispatch(tourSaved(resp))
    }).catch((error) => {
      dispatch(tourError(`Unable to cancel tour: ${error.status} (${error.statusText})`))
    })
  }
}

const navigateToNewTicketsTrigger = (tour) => {
  const index = tour.schedule && tour.schedule.triggers ? tour.schedule.triggers.length : 0
  return push(`/tours/${tour.id}/triggers/${index}`)
}

const navigateToEditTicketsTrigger = (tour, index) => {
  return push(`/tours/${tour.id}/triggers/${index}`)
}

const navigateToSchedule = (tourId) => {
  return push(`/tours/${tourId}/schedule`)
}

export const constants = {
  TOUR_ERROR: 'TOUR_ERROR',
  TOUR_NEW: 'TOUR_NEW',
  TOUR_FETCHING: 'TOUR_FETCHING',
  TOUR_FETCHED: 'TOUR_FETCHED',
  TOUR_SAVING: 'TOUR_SAVING',
  TOUR_SAVED: 'TOUR_SAVED',
  TOUR_SUBMITTED: 'TOUR_SUBMITTED',
  TOUR_REJECTED: 'TOUR_REJECTED',
  TOUR_PUBLISHED: 'TOUR_PUBLISHED',
  TOUR_PAUSED: 'TOUR_PAUSED',
  TOUR_RESUMED: 'TOUR_RESUMED',
  TOUR_EDITED: 'TOUR_EDITED',
  TOUR_UPLOAD_IMAGE_ERROR: 'TOUR_UPLOAD_IMAGE_ERROR',
  TOUR_RELATED_CONTENT_FETCHING: 'TOUR_RELATED_CONTENT_FETCHING',
  TOUR_RELATED_CONTENT_FETCHED: 'TOUR_RELATED_CONTENT_FETCHED',
  TOUR_EVENT_DATES_FETCHING: 'TOUR_EVENT_DATES_FETCHING',
  TOUR_EVENT_DATES_FETCHED: 'TOUR_EVENT_DATES_FETCHED'
}

export const tourActions = {
  newTour,
  saveTour,
  tourSaving,
  tourSaved,
  fetchTour,
  tourFetching,
  tourFetched,
  submitTour,
  rejectTour,
  publishTour,
  pauseTour,
  resumeTour,
  editTour,
  tourError,
  fetchRelatedContent,
  fetchEventDates,
  updateSoldOut,
  updateCancelled,
  updateTourStatus,
  navigateToNewTicketsTrigger,
  navigateToEditTicketsTrigger,
  navigateToSchedule
}
