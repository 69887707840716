import { connect } from 'react-redux'

import { sectionHeaderActions } from '../sectionHeaderActions'
import EditSectionHeader from '../components/edit'
import { contentActions } from '../../content/contentActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      load: (sectionHeaderDetails) => {
          dispatch(sectionHeaderActions.fetchSectionHeaderContent(sectionHeaderDetails))
        },
      nextHandler: (e, sectionHeaderDetails) => {
        e.preventDefault()
        dispatch(sectionHeaderActions.updateSectionHeader(sectionHeaderDetails))
      },
      backHandler: e => {
        if (e) {
          e.preventDefault()
        }
        dispatch(sectionHeaderActions.navigateToManageSectionHeaders())
      },
      navigateToSelectBanners: (sectionHeader) => {
        dispatch(contentActions.navigateToSelectBannerItems(sectionHeader))
      }
    }
  )
}

const mapStateToProps = state => {
  return {
    validationErrors: state.sectionHeaders.validationErrors,
    error: state.sectionHeaders.error,
    sectionHeader: state.sectionHeaders.clickedSectionHeader,
    roles: state.common.roles,
    profile: state.common.profile,
    sectionHeaderContent: state.sectionHeaders.sectionHeaderContent,
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSectionHeader)
