import { constants } from './accountActions'

export default function accounts(state = {}, action = {}) {
  switch (action.type) {
    case constants.ACCOUNTS_FETCHING:
      return Object.assign({}, state, {
        account: {},
        accounts: [],
        accountsStatus: 'LOADING'
      })
    case constants.ACCOUNTS_FETCHED:
      return Object.assign({}, state, {
        accounts: action.accounts,
        accountsStatus: 'SUCCESS'
      })

    case constants.ACCOUNT_FETCHING_BY_EMAIL:
      return Object.assign({}, state, {
        accountStatus: 'LOADING'
      })
    case constants.ACCOUNT_FETCHED_BY_EMAIL:
      return Object.assign({}, state, {
        account: action.account,
        accountStatus: 'SUCCESS'
      })

    case constants.ACCOUNT_FETCHING:
      return Object.assign({}, state, {
        accountStatus: 'LOADING'
      })
    case constants.ACCOUNT_FETCHED:
      return Object.assign({}, state, {
        account: action.account,
        accountStatus: 'SUCCESS'
      })
    case constants.ACCOUNT_NEW:
      return Object.assign({}, state, {
        account: action.account,
        error: action.error,
        validationErrors: action.validationErrors,
        accountStatus: 'SUCCESS'
      })
    case constants.ACCOUNT_CREATED:
      return Object.assign({}, state, {
        account: action.account,
        error: action.error,
        validationErrors: action.validationErrors,
        accountStatus: 'SUCCESS'
      })
    case constants.ACCOUNT_UPDATED:
      return Object.assign({}, state, {
        account: action.account,
        accountStatus: 'SUCCESS'
      })
    case constants.ACCOUNT_UPDATE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        accountStatus: 'FAILED'
      })
    case constants.ACCOUNT_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        accountStatus: 'FAILED'
      })

    case constants.RESET_PWD_NOTIFICATION_ERROR:
      return Object.assign({}, state, {
        validationErrors: action.validationErrors,
        status: 'FAILED'
      })
    case constants.EXTRACT_RESET_PWD_TOKEN:
    case constants.VALIDATING_RESET_PWD_TOKEN:
      return Object.assign({}, state, {
        token: action.token,
        status: 'LOADING'
      })
    case constants.VALIDATE_RESET_PWD_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        token: action.token,
        status: 'SUCCESS'
      })
    default:
      return state
  }
}
