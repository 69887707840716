/* global FormData localStorage */

import fetch from 'isomorphic-fetch'
import qs from 'qs'

import { commonActions, constants } from '../common/commonActions'
import utils from '../common/utils'

export const config = {}

export const VENUE_LIST_V1 = 'application/vnd.o2.priority.venue-list-v1+json'
export const VENUE_DETAILS_V1 = 'application/vnd.o2.priority.venue-v1+json'
export const MERCHANT_LIST_V1 = 'application/vnd.o2.priority.merchant-list-v1+json'
export const MERCHANT_DETAILS_V1 = 'application/vnd.o2.priority.merchant-v1+json'
export const BRAND_DETAILS_V1 = 'application/vnd.o2.priority.brand-v1+json'
export const BRANDS_LIST_V1 = 'application/vnd.o2.priority.brands-list-v1+json'
export const OFFER_DETAILS_V1 = 'application/vnd.o2.priority.offer-detail-v1+json'
export const GROUP_DETAILS_V1 = 'application/vnd.o2.priority.group-detail-v1+json'
export const SERIES_DETAILS_V1 = 'application/vnd.o2.priority.series-detail-v1+json'
export const THANK_YOU_DETAILS_V1 = 'application/vnd.o2.priority.thankyou-detail-v1+json'
export const THANK_YOU_MANIFEST_V1 = 'application/vnd.o2.priority.group-v1+json'
export const THANK_YOU_PROFILE_ASSOCIATION_DETAIL_V1 = 'application/vnd.o2.priority.thank-you-profile-association-detail-v1+json'
export const BANNER_DETAILS_V1 = 'application/vnd.o2.priority.banner-detail-v1+json'
export const MUSIC_BANNER_DETAILS_V1 = 'application/vnd.o2.priority.music-banner-detail-v1+json'
export const CAMPAIGN_DETAILS_V2 = 'application/vnd.o2.priority.campaign-detail-v2+json'
export const PRIZEDRAW_DETAILS_V1 = 'application/vnd.o2.priority.prizedraw-detail-v1+json'
export const TOUR_DETAILS_V1 = 'application/vnd.o2.priority.tour-detail-v1+json'
export const EVENT_DATE_DETAILS_V1 = 'application/vnd.o2.priority.eventdate-detail-v1+json'
export const EVENT_DATE_LIST_V1 = 'application/vnd.o2.priority.eventdate-list-v1+json'
export const USER_DETAILS_V1 = 'application/vnd.o2.priority.user-v1+json'
export const USER_UPDATE_PASSWORD_V1 = 'application/vnd.o2.priority.user-update-password-v1+json'
export const USER_LIST_V1 = 'application/vnd.o2.priority.user-list-v1+json'
export const GEO_CODE_LOCATION_V1 = 'application/vnd.o2.priority.geo-code-location-v1+json'
export const CONTENT_LIST_V1 = 'application/vnd.o2.priority.content-list-v1+json'
export const CONTENT_LIST_V2 = 'application/vnd.o2.priority.content-list-v2+json'
export const CONTENT_DETAIL_V1 = 'application/vnd.o2.priority.content-detail-v1+json'
export const BRAND_LOCATION_LIST_V1 = 'application/vnd.o2.priority.brand-location-list-v1+json'
export const BRAND_LOCATION_PAGE_V1 = 'application/vnd.o2.priority.brand-location-page-v1+json'
export const BRAND_LOCATION_V1 = 'application/vnd.o2.priority.brand-location-v1+json'
export const STOCK_V1 = 'application/vnd.o2.priority.stock-v1+json'
export const AUDIT_LIST_V1 = 'application/vnd.o2.priority.audit-trail-list-v1+json'
export const AUDIT_DETAILS_V1 = 'application/vnd.o2.priority.audit-trail-detail-v1+json'
export const MSISDN_LIST_V1 = 'application/vnd.o2.priority.preview-msisdn-list-v1+json'
export const MSISDN_DETAIL_V1 = 'application/vnd.o2.priority.preview-msisdn-detail-v1+json'
export const FAQ_SECTION_LIST_V1 = 'application/vnd.o2.priority.faq-section-list-v1+json'
export const FAQ_SECTION_DETAIL_V1 = 'application/vnd.o2.priority.faq-section-detail-v1+json'
export const FAQ_DETAIL_V1 = 'application/vnd.o2.priority.faq-detail-v1+json'
export const FAQ_LIST_V1 = 'application/vnd.o2.priority.faq-List-v1+json'
export const NOTIFICATION_PROFILE_DETAIL_V1 = 'application/vnd.o2.priority.notification-profile-detail-v1+json'
export const NOTIFICATION_PROFILE_LIST_V1 = 'application/vnd.o2.priority.notification-profile-list-v1+json'
export const CONTENT_FEATURED_TITLE_V1 = 'application/vnd.o2.priority.content-featured-title-v1+json'
export const ARTICLE_DETAILS_V1 = 'application/vnd.o2.priority.article-detail-v1+json'
export const LIST_S3_FILES_V1 = 'application/vnd.o2.priority.thank-you-s3-files-list-v1+json'
export const INTEREST_CATEGORIES_LIST_V1 = 'application/vnd.o2.priority.interest-categories-list-v1+json'
export const PROCESS_S3_FILE_V1 = '*/*'
export const SECTION_HEADER_DETAILS_V1 = 'application/vnd.o2.priority.section-header-detail-v1+json'
export const WELCOME_POSTER_DETAILS_V1 = 'application/vnd.o2.priority.welcome-poster-detail-v1+json'
export const FEATURE_FLAG_DETAILS_V1 = 'application/vnd.o2.priority.feature-flag-detail-v1+json'
export const CONFIGURABLE_CONTENTS_LIST_V1 = 'application/vnd.o2.priority.configurable-contents-list-v1+json'


const populateAuthorizationHeader = (params) => {
  let token = localStorage.getItem(constants.AUTH_TOKEN)
  token = token ? JSON.parse(token) : null
  if (token) {
    params.headers = Object.assign({}, params.headers, {
      Authorization: `${token.token_type} ${token.access_token}`
    })
  }

  return params
}

const interceptedFetch = (dispatch, uri, params) => {
  return fetch(uri, populateAuthorizationHeader(params))
    .then(resp => {
      if (!dispatch) return resp

      const refererRoute = utils.getPathName()
      if (resp.status === 401 && refererRoute !== '/login') {
        dispatch(commonActions.updateRefererRoute(refererRoute))
        dispatch(commonActions.navigateToLogin())
      }

      return resp
    })
}

function trimAllStrings(obj) {
  for (const key in obj) {
    if (obj[key] && obj[key].constructor === Object) {
      trimAllStrings(obj[key])
    } else if (obj[key] && obj[key].constructor === String) {
      obj[key] = obj[key].trim()
    }
  }
}

const request = (dispatch, method, uri, params, headers = {}) => {
  const multipart = params && params.constructor === FormData

  if (!multipart) {
    headers['Accept'] = headers['Accept'] || 'application/json'
  }

  if (['POST', 'PUT', 'PATCH'].includes(method) && !multipart) {
    headers['Content-Type'] = headers['Content-Type'] || 'application/json'
  }

  // Query string
  if (method === 'GET' && params) {
    const query = qs.stringify(params, { encode: false, arrayFormat: 'brackets' })
    uri += query ? '?' + query : ''
  }

  // JSON body
  const options = { method, headers }
  if (method !== 'GET' && !multipart) {
    let json = params
    if (!(params instanceof Array)) {
      json = params ? { ...params } : {}
      trimAllStrings(json)
    }
    options.body = JSON.stringify(json)
  } else if (method !== 'GET' && multipart) {
    options.body = params
  }

  return interceptedFetch(dispatch, uri, options).then((resp) => {
    if (resp.status === 204) {
      return Promise.resolve()
    } else if (resp.ok) {
      return resp.json().catch(() => ({}))
    } else {
      return resp.text().then(body => {
        throw Object({
          status: resp.status,
          statusText: resp.statusText,
          body
        })
      })
    }
  })
}

const get = (dispatch, uri, params, headers) => {
  return request(dispatch, 'GET', uri, params, headers)
}

const post = (dispatch, uri, payload, headers) => {
  return request(dispatch, 'POST', uri, payload, headers)
}

const put = (dispatch, uri, payload, headers) => {
  return request(dispatch, 'PUT', uri, payload, headers)
}

const patch = (dispatch, uri, payload, headers) => {
  return request(dispatch, 'PATCH', uri, payload, headers)
}

const del = (dispatch, uri, headers) => {
  return request(dispatch, 'DELETE', uri, null, headers)
}

const download = (url, fileName) => {
  const xhr = new window.XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'arraybuffer'
  const token = JSON.parse(localStorage['AUTH_TOKEN'])
  xhr.setRequestHeader('Authorization', `${token.token_type} ${token.access_token}`)
  xhr.onload = function() {
    if (this.status === 200) {
      const blob = new window.Blob([this.response], { type: 'plain/text' })
      const blobURL = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.style = 'display: none'
      document.body.appendChild(a)
      a.href = blobURL
      a.download = fileName
      a.click()
      window.URL.revokeObjectURL(blobURL)
    }
  }

  xhr.send(true)
}

export default {
  get,
  post,
  put,
  patch,
  del,
  download
}
