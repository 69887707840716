import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const ConfirmStockDeleteModal = ({ visible, onClose, onConfirm }) => (
  <Modal show={visible} onHide={onClose}>
    <Modal.Header>
      <h4>Delete unused codes</h4>
    </Modal.Header>
    <Modal.Body>
      <p>Are you sure you want to delete the unused codes associated to this offer?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button id='confirmStockDeleteModal-cancel' onClick={onClose}>Cancel</Button>
      <Button id='confirmStockDeleteModal-save' bsStyle='primary' onClick={() => {
        onConfirm()
        onClose()
      }}>
        Yes, delete
      </Button>
    </Modal.Footer>
  </Modal>
)

export default ConfirmStockDeleteModal
