import { constants } from './notificationActions'

const initialState = {}

export default function notification(state = initialState, action = {}) {
  switch (action.type) {
    case constants.NOTIFICATION_PROFILES_FETCHING:
      return Object.assign({}, state, {
        notificationProfiles: [],
        error: '',
        validationErrors: {},
        status: 'LOADING'
      })
    case constants.NOTIFICATION_PROFILES_FETCHED:
      return Object.assign({}, state, {
        notificationProfiles: action.notificationProfiles,
        error: action.error,
        validationErrors: {},
        status: 'SUCCESS'
      })

    case constants.NOTIFICATION_PROFILE_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.NOTIFICATION_PROFILE_FETCHED:
      return Object.assign({}, state, {
        notificationProfile: action.notificationProfile,
        error: action.error,
        validationErrors: {},
        status: 'SUCCESS'
      })

    case constants.NEW_NOTIFICATION_PROFILE:
      return Object.assign({}, state, {
        notificationProfile: action.notificationProfile,
        error: action.error,
        validationErrors: action.validationErrors,
        status: 'SUCCESS'
      })
    case constants.NOTIFICATION_PROFILE_SAVING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.CREATE_OR_UPDATE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        status: 'FAILED'
      })

    case constants.NOTIFICATION_PROFILE_DELETED:
      return Object.assign({}, {
        notificationProfiles: state.notificationProfiles.filter((profileIterator) => profileIterator.id !== action.id),
        status: 'SUCCESS'
      })

    case constants.SERVICE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: {},
        status: 'FAILED'
      })

    default:
      return state
  }
}
