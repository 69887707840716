import React, { Component } from 'react'
import { Alert, Glyphicon } from 'react-bootstrap'

const styleToClassMap = {
  info: 'info',
  error: 'danger'
}

const styleToGlyphMap = {
  info: 'info-sign',
  error: 'exclamation-sign'
}

export default class AppFlash extends Component {
  render() {
    const { style, message } = this.props.flash

    return (
      <Alert bsStyle={styleToClassMap[style]} className='application-error'>
        <p>
          <Glyphicon glyph={styleToGlyphMap[style]} />
          {message}
        </p>
      </Alert>
    )
  }
}
