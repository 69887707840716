import { constants } from './sectionHeaderActions'

export default function sectionHeaders(state = {}, action = {}) {
  switch (action.type) {
    case constants.SECTION_HEADERS_FETCHING:
      return Object.assign({}, state, {
        sectionHeader: {},
        sectionHeaders: [],
        sectionHeadersStatus: 'LOADING'
      })
    case constants.SECTION_HEADERS_FETCHED:
      return Object.assign({}, state, {
        sectionHeaders: action.sectionHeaders,
        sectionHeadersStatus: 'SUCCESS'
      })
    case constants.SECTION_HEADER_UPDATED:
      return Object.assign({}, state, {
        sectionHeader: action.sectionHeader,
        validationErrors: {},
        sectionHeaderStatus: 'SUCCESS'
      })
    case constants.SECTION_HEADER_UPDATE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        sectionHeaderStatus: 'FAILED'
      })
    case constants.SECTION_HEADER_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        sectionHeaderStatus: 'FAILED'
      })
    case constants.CLICKED_SECTION_HEADER:
      return Object.assign({}, state, {
        clickedSectionHeader: action.sectionHeader
      })
    case constants.SECTION_HEADER_CONTENT_FETCHING:
      return Object.assign({}, state, {
        sectionHeaderStatus: 'LOADING'
      })
    case constants.SECTION_HEADER_CONTENT_FETCHED:
      return Object.assign({}, state, {
        sectionHeaderContent: action.sectionHeaderContent,
        sectionHeaderStatus: 'SUCCESS'
      })
    default:
      return state
  }
}
