import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

class TriggerBreadcrumbs extends Component {
  render() {
    var backLink = this.props.backUrl || ''
    var detailsLink = this.props.detailsUrl || ''
    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <LinkContainer to={backLink}>

          <NavItem>
            <Glyphicon glyph='chevron-left' />
            {' '}
            Back to Schedule
          </NavItem>
        </LinkContainer>
        <NavItem className='nav-item-divider' />
        <LinkContainer id='our-picks-breadcrumbs-details'
                       to={detailsLink}
                       active={this.props.activeTab === 'details'}>

          <NavItem>
            Basic Details
          </NavItem>
        </LinkContainer>
      </Nav>
    )
  }
}

export default TriggerBreadcrumbs
