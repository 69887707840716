import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import FormField from '../../common/form/formField'
import LinkPreview from '../components/linkPreview'
import MediaComponentBase from './MediaComponentBase'
import { mapDispatchToProps, mapStateToProps } from './mediaContainer'

class VideoSelector extends MediaComponentBase {
  render() {
    return (
      <div>
        <FormField label={this.props.label} type='file' id={this.mediaType + 'Video'}
                   mandatory={this.props.mandatory}
                   value={this.state.assets[this.mediaType + 'VideoUrl']}
                   onChange={(event) => {
                     this.uploadMedia(event.target.files[0], this.props.mediaTarget, this.mediaType + 'VideoUrl')
                   }}
                   onError={(err) => {
                     this.addValidationError(this.mediaType + 'VideoUrl', err)
                   }}
                   onClear={() => {
                     this.updateField('assets.' + this.mediaType + 'VideoUrl', null).bind(this)()
                   }}
                   uploading={this.state.uploading === this.mediaType + 'VideoUrl'}
                   error={this.state.errors[this.mediaType + 'VideoUrl']}
                   disabled={this.props.disabled}
                   iconClassName='glyphicon-facetime-video'
                   acceptFileTypes='video/mp4'
        />
        {this.state.assets[this.mediaType + 'VideoUrl'] ? (
          <LinkPreview linkId={this.mediaType + 'VideoLink'} url={this.state.assets[this.mediaType + 'VideoUrl']} />
        ) : null}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSelector)

VideoSelector.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'desktopBackground',
    'mobileBackground',
    'webMobileBackground',
    'landscapeBackground',
    'squareBackground',
    'tallMobileBackground',
    'tallWebMobileBackground'
  ]).isRequired,
  mediaTarget: PropTypes.oneOf([
    'SQUARE',
    'SUPER_CAMPAIGN_DESKTOP',
    'LANDSCAPE_BACKGROUND',
    'TALL_MOBILE_VIDEO'
  ]).isRequired,
  model: PropTypes.object,
  updateParentField: PropTypes.func,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool
}

VideoSelector.defaultProps = {
  disabled: false
}
