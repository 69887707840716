import { constants } from './tourActions'

const initialState = {
  eventDates: [],
  relatedContent: []
}
export default function tours(state = initialState, action = {}) {
  switch (action.type) {
    case constants.TOUR_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.TOUR_SAVING:
      return Object.assign({}, state, {
        tour: action.tour,
        status: 'SAVING'
      })
    case constants.TOUR_NEW:
      return Object.assign({}, state, {
        tour: action.tour,
        eventDates: [],
        relatedContent: [],
        status: 'SUCCESS',
        error: null
      })
    case constants.TOUR_FETCHED:
    case constants.TOUR_SAVED:
    case constants.TOUR_SUBMITTED:
    case constants.TOUR_REJECTED:
    case constants.TOUR_PUBLISHED:
    case constants.TOUR_PAUSED:
    case constants.TOUR_RESUMED:
    case constants.TOUR_EDITED:
      return Object.assign({}, state, {
        tour: action.tour,
        status: 'SUCCESS',
        error: null
      })
    case constants.TOUR_RELATED_CONTENT_FETCHING:
      return Object.assign({}, state, {
        relatedContent: []
      })
    case constants.TOUR_RELATED_CONTENT_FETCHED:
      return Object.assign({}, state, {
        relatedContent: action.relatedContent
      })
    case constants.TOUR_EVENT_DATES_FETCHING:
      return Object.assign({}, state, {
        eventDates: []
      })
    case constants.TOUR_EVENT_DATES_FETCHED:
      return Object.assign({}, state, {
        eventDates: action.eventDates
      })
    case constants.TOUR_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    default:
      return state
  }
}
