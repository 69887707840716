import { connect } from 'react-redux'

import ResetPasswordForm from '../components/resetPasswordForm'
import { accountActions } from '../accountActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const token = ownProps.match.params.token
      dispatch(accountActions.extractResetPasswordToken(token))
      dispatch(accountActions.validateResetPasswordToken(token))
    },
    nextHandler: (e, model) => {
      e.preventDefault()
      dispatch(accountActions.resetPassword(model.password, model.token))
    }
  }
}

const mapStateToProps = state => {
  return {
    validationErrors: state.accounts.validationErrors || {},
    error: state.accounts.error,
    token: state.accounts.token,
    status: state.accounts.status,
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
