import classNames from 'classnames'

import React, { Component } from 'react'
import Label from './label'
import http, {config} from '../../services/http'
import { Editor } from '@tinymce/tinymce-react'

export default class RichText extends Component {
  _handleChange = (content, editor) => {
      this.props.onChange(content)
  }

  render () {

    const validationError = this.props.error ? (
      <Label className={classNames('input-error text-danger')}>
        {this.props.error}
      </Label>
    ) : null

    const uploadImage = (blobInfo, success, failure, progress) => {
      const formData = new FormData()

      formData.append('mediaTarget', 'GENERAL_IMAGE')
      formData.append('mediaFormat', 'IMAGE')
      formData.append('media', blobInfo.blob())

      return http.post(null, `${config.serverUrl}/media/`, formData).then(resp => {
        success(resp.url)
      }).catch(err => {
        console.log(err)
      })
    }

    return (
      <div className={`form-group`}>
        <Editor apiKey='evzdythxl8jt5sdwu39zfsmiaem4z2sc0c7npthtwmal79u2'
          disabled={this.props.disabled || false}
          id={this.props.id}
          value={this.props.value || ''}
          init={{
          height: 500,
          menubar: 'edit insert view table tools',
          images_upload_handler: uploadImage,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'image imagetools'
          ],
          toolbar:
            'undo redo | formatselect | image | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help',
            default_link_target: '_blank'
          }}
          onEditorChange={this._handleChange}
        />
        {validationError}
      </div>
    )
  }
}
