import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'
import ValidationIndicator from '../../common/components/validationIndicator'
import { config } from '../../services/http'

export default class Breadcrumbs extends Component {
  getValidationStatus(model, property, validationErrors) {
    if (!model.id) {
      return 'NEW'
    } else if (Object.keys(model[property]).length === 0) {
      return 'NEW'
    } else if (Object.keys(validationErrors[property]).length === 0) {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  getValidationStatuses() {
    const { banner, validationErrors } = this.props

    return {
      details: this.getValidationStatus(banner, 'details', validationErrors),
      schedule: this.getValidationStatus(banner, 'schedule', validationErrors),
      assets: this.getValidationStatus(banner, 'assets', validationErrors),
      callToAction: this.getValidationStatus(banner, 'callToAction', validationErrors)
    }
  }

  render() {
    var banner = this.props.banner
    var statuses = this.getValidationStatuses(banner)

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='banner-breadcrumbs-back'
                 onClick={() => this.props.onNavigate('back')}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Home
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='banner-breadcrumbs-details'
                 active={this.props.activeTab === 'details'}
                 onClick={() => this.props.onNavigate('details')}
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Basic Details
        </NavItem>
        <NavItem id='banner-breadcrumbs-schedule'
                 active={this.props.activeTab === 'schedule'}
                 onClick={() => this.props.onNavigate('schedule')}
        >
          <ValidationIndicator status={statuses.schedule} />
          {' '}
          Schedule
        </NavItem>
        <NavItem id='banner-breadcrumbs-assets'
                 active={this.props.activeTab === 'assets'}
                 onClick={() => this.props.onNavigate('assets')}
        >
          <ValidationIndicator status={statuses.assets} />
          {' '}
          Assets
        </NavItem>
        <NavItem key='banner-breadcrumbs-cta'
                   id='banner-breadcrumbs-cta'
                   active={this.props.activeTab === 'callToAction'}
                   onClick={() => this.props.onNavigate('callToAction')}
          >
            <ValidationIndicator status={statuses.callToAction} />
            {' '}
            CTA Details
        </NavItem>
        {this.props.showCopy ? (
          <NavItem className='nav-item-divider' />
        ) : null}
        {this.props.showCopy ? (
          <NavItem id='banner-breadcrumbs-copy'
                   onClick={this.props.onCopy}
                   disabled={this.props.dirty}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Copy Banner
          </NavItem>
        ) : null}
        {config.auditsEnabled ? [
          <NavItem key='a' className='nav-item-divider' />,
          <NavItem key='b'
                   id='banner-breadcrumbs-audits'
                   active={this.props.activeTab === 'audits'}
                   onClick={() => this.props.onNavigate('audits')}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Audit trail
          </NavItem>
        ] : null}
      </Nav>
    )
  }
}
