import React from 'react'
import { Col, Panel, Row } from 'react-bootstrap'
import _ from 'lodash'
import { welcomePosterTypeOptions } from '../../common/enums'
import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import AppFlash from '../../common/components/appFlash'
import Layout from './welcomePosterLayout'
import ContentFormBase from '../../common/components/contentFormBase'

export default class welcomePosterForm extends ContentFormBase {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.welcomePoster || {})
    this.model = this.props.welcomePoster
    this.state = {
      disableNextButton: this.props.welcomePoster.disableNextButton != null ? this.props.welcomePoster.disableNextButton : true
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.welcomePoster, this.props.welcomePoster)) {
      this.model = this.props.welcomePoster
      this.forceUpdate()
    }
  }

  update(path, value) {
    return (e) => {
      _.set(this.model, path, e ? e.target.value : value)
      this.setState({ dirty: true })
      this.setState({
        disableNextButton: false
      })
    }
  }

  updateType() {
    return (e) => {
      this.model['type'] = e.target.value
      if (e.target.value.length > 0) {
        this.setState({
          disableNextButton: false
        })
      } else {
        this.setState({
          disableNextButton: true
        })
      }
    }
  }

  componentDidMount() {
    if (this.props.isEditOperation && !this.props.welcomePoster.subType) {
      this.props.backHandler()
    }
  }

  render() {
    const {
      nextHandler,
      backHandler,
      flash
    } = this.props
    const validationErrors = this.props.validationErrors || {}
    const welcomePosterDetails = this.props.welcomePoster || {}

    return (
      <Layout>
        <Row>
          <Col sm={12} lg={12}>
            {this.props.error || null}
            {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
            <Panel header="Welcome Poster Details" id="welcomePosterPanel">
              <Form nextButtonText="Save"
                    nextButtonDisabled={this.props.isEditOperation && (this.state.disableNextButton)}
                    nextHandler={(e) => {
                      this.setState({ disableNextButton: true })
                      nextHandler(e, this.model)
                    }}
                    backHandler={(e) => {
                      backHandler(e)
                    }}
              >
                <FormField label="Type"
                           id="welcomePoster-type"
                           type="dropdown" options={welcomePosterTypeOptions}
                           value={welcomePosterDetails.subType}
                           error={validationErrors.subType}
                           onChange={this.updateType()}
                           disabled={this.props.isEditOperation}
                           mandatory
                />
                <FormField label="Overline"
                           id="overline"
                           value={welcomePosterDetails.overline}
                           maxLength="50"
                           onChange={this.update('overline')}
                           error={validationErrors.overline}
                           mandatory
                />
                <FormField label="Title"
                           id="title"
                           value={welcomePosterDetails.title}
                           maxLength="55"
                           onChange={this.update('title')}
                           error={validationErrors.title}
                           mandatory
                />
                <FormField label="Subtitle"
                           id="subtitle"
                           maxLength="170"
                           value={welcomePosterDetails.subtitle}
                           onChange={this.update('subtitle')}
                           error={validationErrors.subtitle}
                           mandatory
                />
                <FormField label="CTA label"
                           id="ctaLabel"
                           maxLength="170"
                           value={welcomePosterDetails.ctaLabel}
                           onChange={this.update('ctaLabel')}
                           error={validationErrors.ctaLabel}
                />
              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
