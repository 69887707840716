import _ from 'lodash'
import { Component } from 'react'

export default class ContentFormBase extends Component {
  componentDidMount() {
    this.load(this.props.match.params.id)
  }

  componentWillUnmount() {
    if (!this.props.unload) return

    this.props.unload()
  }

  componentDidUpdate() {
    if (this.props.match.params && this.props.match.params.id !== this._previousID) {
      this.load(this.props.match.params.id)
    }
  }

  load(id) {
    this.props.load()
    this._previousID = id
  }

  isEmpty(value) {
    return (!value || value.length === 0)
  }

  assignBrandLogoToModel(props) {
    if (props.match.params.section === 'assets' && this.model.brand && (!this.model.assets || !this.model.assets.logoImageUrl)) {
      this.model.assets = this.model.assets || {}
      this.model.assets.logoImageUrl = this.model.brand.logoImageUrl
    }
  }

  isFormDisabled(state, status) {
    return state && (state !== 'DRAFT' || status === 'LOADING')
  }

  isLiveEdit(content) {
    return content.isPublished && ['DRAFT', 'PENDING_APPROVAL', 'REJECTED'].indexOf(content.state) > -1
  }

  updateField(path, value) {
    return (e) => {
      _.set(this.model, path, e ? e.target.value : value)
      this.setState({ dirty: true })
    }
  }

  selectBrand(option) {
    if (option) {
      this.setState({ selectedBrand: { id: option.id, name: option.label } })
    } else {
      this.setState({ selectedBrand: null })
    }
  }

  updateBrandId() {
    if (this.state.selectedBrand) {
      this.model.brand = { ...this.state.selectedBrand }
      this.setState({ dirty: true })
    }
  }

  save() {
    return (e) => {
      e.preventDefault()
      this.props.save(this.model).then(() => {
        this.setState({ dirty: false, hideErrorMessageForYTID: true })
      })
    }
  }

  saveAndBack() {
    this.props.save(this.model).then(() => {
      this.props.navigateToDashboard()
    })
  }

  submit() {
    return (e) => {
      e.preventDefault()
      this.props.submit(this.model)
    }
  }

  reject() {
    return (e) => {
      e.preventDefault()
      this.props.reject(this.model)
    }
  }

  publish() {
    return (e) => {
      e.preventDefault()
      this.props.publish(this.model)
    }
  }

  pause() {
    return (e) => {
      e.preventDefault()
      this.props.pause(this.model)
    }
  }

  resume() {
    return (e) => {
      e.preventDefault()
      this.props.resume(this.model)
    }
  }

  copy() {
    return (e) => {
      e.preventDefault()
      this.props.copy(this.model)
    }
  }

  edit() {
    return (e) => {
      e.preventDefault()
      this.props.edit(this.model)
    }
  }

  onNavigate(tab) {
    if (tab === 'back' && !this.state.dirty) {
      this.props.navigateToDashboard()
    } else if (tab === 'back' && this.state.dirty) {
      this.showSaveOrDiscard()
    } else {
      this.changeTab(tab)
    }
  }

  changeTab(tab) {
    if (this.state.dirty || !this.model.id) {
      this.setState({ dirty: false })
      this.props.save(this.model).then((contentItem) => {
        this.props.navigateToSection(contentItem, tab)
      })
    } else {
      this.props.navigateToSection(this.model, tab)
    }
  }

  showSaveOrDiscard() {
    this.setState({ saveOrDiscardModalVisible: true })
  }

  isComplete(obj, validationErrors, sections, requiredSections) {
    for (let i = 0; i < requiredSections.length; i++) {
      const key = requiredSections[i]
      if (Object.keys(obj[key]).length === 0 || Object.keys(validationErrors[key]).length > 0) {
        return false
      }
    }
    for (let i = 0; i < sections.length; i++) {
      const key = sections[i]
      if (Object.keys(validationErrors[key]).length > 0) {
        return false
      }
    }
    return true
  }

  disable() {
    return (e) => {
      e.preventDefault()
      this.props.disable(this.model)
    }
  }

  enable() {
    return (e) => {
      e.preventDefault()
      this.props.enable(this.model)
    }
  }

  updateSortOf(fieldName) {
    return (rows) => {
      const formDisabled = this.isFormDisabled(this.model.state, this.props.status)

      if (formDisabled) {
        // different behavior to dirty which allows saving the whole model
        _.set(this.model, fieldName, rows)
        this.setState({ sorted: true })
      } else {
        this.updateField(fieldName, rows)()
      }
    }
  }
}
