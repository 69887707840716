import _ from 'lodash'
import React from 'react'
import { Button, Col, Form, Panel, Row } from 'react-bootstrap'
import AppFlash from '../../common/components/appFlash'
import AuditsPanel from '../../common/components/auditsPanel'
import ContentFormBase from '../../common/components/contentFormBase'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import Spinner from '../../common/components/spinner'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import StatusToolbar from '../../common/components/statusToolbar'
import FormField from '../../common/form/formField'
import ImageSelector from '../../common/media/ImageSelector'
import ContentItemsPanel from './ContentItemsPanel'
import CustomersPanel from './CustomersPanel'
import Breadcrumbs from './ThankYouBreadcrumbs'
import { themeOptions } from '../../common/enums'

export default class ThankYouForm extends ContentFormBase {
  constructor(props) {
    super(props)

    this.model = _.cloneDeep(props.thankYou || {})
    this.state = {
      saveOrDiscardModalVisible: false,
      dirty: false,
      sorted: false
    }
    this.setDefaults()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.thankYou, this.props.thankYou)) {
      this.model = _.cloneDeep(this.props.thankYou || {})
      this.setDefaults()
      this.forceUpdate()
    }

    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
  }

  setDefaults() {
    this.model.contentItems = this.model.contentItems || {}
    this.model.contentItems.redemptionType = this.model.contentItems.redemptionType || 'LIMITED'
    this.model.contentItems.maxNumberOfRedemptions = this.model.contentItems.maxNumberOfRedemptions || 1
  }

  onSort(rows) {
    const formDisabled = this.isFormDisabled(this.props.thankYou.state, this.props.status)

    if (formDisabled) {
      // different behavior to dirty which allows saving the whole model
      _.set(this.model, 'contentItems.ids', rows)
      this.setState({ sorted: true })
    } else {
      this.updateField('contentItems.ids', rows)()
    }
  }

  updateContents(contents) {
    this.setState({ sorted: false })
    this.props.updateContents(contents).catch(() => {
      this.setState({ sorted: true })
    })
  }

  updateThemeOption(property) {
    return (val) => {
      this.model.details.theme = val
      this.setState({ dirty: true })
    }
  }

  render() {
    const activeTab = this.props.match.params.section || 'details'
    const sections = ['details', 'assets', 'contentItems', 'customers']
    const requiredSections = ['details', 'assets', 'contentItems']
    const status = this.props.status || 'LOADING'

    const thankYou = _.cloneDeep(this.props.thankYou || {})
    const validationErrors = this.model.validationErrors || {}
    sections.forEach((section) => {
      thankYou[section] = thankYou[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })
    const contentList = this.props.contentList || []
    const contentItems = []
    const contentItemIds = this.model.contentItems ? this.model.contentItems.ids || [] : []
    contentItemIds.forEach(idType => {
      const contentItem = contentList.find(item => idType.id === item.id)
      if (contentItem) {
        contentItems.push(contentItem)
      }
    })

    const formDisabled = this.isFormDisabled(thankYou.state, status)
    const liveEdit = this.isLiveEdit(thankYou)

    if (status === 'LOADING') {
      return <Spinner />
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className="page-header">
              <div className="page-header--heading">
                <div className="page-header--top">
                  <ContentTypeLabel className="page-header--type" type={thankYou.type} />
                  <StatusLabel className="page-header--state-label" status={thankYou.state} />
                  {liveEdit ? (
                    <StatusLabelCustom className="page-header--state-label" status="live_edit" text="Editing published content" />
                  ) : null}
                </div>
                <h1 className="content-heading">{thankYou.details.title || 'Thank You'}</h1>
              </div>
              <div className="page-header--tools">
                {!this.state.dirty && this.state.sorted ? (
                  <Button bsStyle="primary"
                          onClick={() => this.updateContents(contentItemIds)}
                  >
                    Save
                  </Button>
                ) : (
                  <StatusToolbar status={thankYou.state}
                                 contentComplete={this.isComplete(thankYou, validationErrors, sections, requiredSections)}
                                 dirty={this.state.dirty}
                                 onSubmit={this.submit()}
                                 onPublish={this.publish()}
                                 onReject={this.reject()}
                                 onSave={this.save()}
                                 onPause={this.pause()}
                                 onResume={this.resume()}
                                 onEdit={this.edit()}
                  />
                )}
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <Breadcrumbs thankYou={thankYou}
                             validationErrors={validationErrors}
                             activeTab={activeTab}
                             onNavigate={(tab) => this.onNavigate(tab)}
                             manifest={this.props.manifest}
                />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
                <Form>
                  {activeTab === 'details' ? (
                    <Panel header="Basic Details" id="details">
                      {thankYou.id ? (
                        <div className="form-group form-group-inline">
                          <label className="control-label">ID:</label> {thankYou.id}
                        </div>
                      ) : null}
                      {thankYou.shortId ? (
                        <div className="form-group form-group-inline" id="thankYou-shortId">
                          <label className="control-label">Short ID:</label> {thankYou.shortId}
                        </div>
                      ) : null}
                      <FormField label="Theme"
                                 type="radiolist" mandatory inline
                                 value={this.model.details && this.model.details.theme ? this.model.details.theme : ''}
                                 id="theme"
                                 options={themeOptions}
                                 onChange={this.updateThemeOption('theme')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.theme}
                      />
                      <FormField label="Title" id="title" value={this.model.details ? this.model.details.title : ''}
                                 maxLength="60" mandatory
                                 onChange={this.updateField('details.title')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.title}
                      />
                      <FormField label="Splash Title" id="splashTitle" value={this.model.details ? this.model.details.splashTitle : ''}
                                 maxLength="60" mandatory
                                 onChange={this.updateField('details.splashTitle')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.splashTitle}
                      />
                      <FormField label="Subtitle" id="subtitle" value={this.model.details ? this.model.details.subtitle : ''}
                                 maxLength="60"
                                 onChange={this.updateField('details.subtitle')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.subtitle}
                      />
                      <FormField label="Internal Reference" id="internalReference" value={this.model.details ? this.model.details.internalReference : ''}
                                 onChange={this.updateField('details.internalReference')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.internalReference}
                      />
                      <FormField label="Description" id="description" value={this.model.details ? this.model.details.description : ''}
                                 type="textArea" maxLength="150"
                                 onChange={this.updateField('details.description')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.description}
                      />
                      <FormField label="18+ only" id='ageRestricted'
                                 value={_.get(this.model, 'details.ageRestricted')}
                                 type='checkbox' inline
                                 onChange={(e) => this.updateField('details.ageRestricted', e.target.checked)()}
                                 disabled={formDisabled}
                      />
                    </Panel>
                  ) : null}
                  {activeTab === 'assets' ? (
                    <Panel header="Assets" id="assets">
                      <ImageSelector label="Portrait Image"
                                     type="portrait"
                                     mediaTarget="PORTRAIT"
                                     mandatory
                                     width={750} height={1125}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled}
                      />
                      <ImageSelector label="Landscape Image"
                                     type="landscape"
                                     mediaTarget="LANDSCAPE"
                                     mandatory
                                     width={1125} height={750}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled}
                      />
                      <ImageSelector label="Square Image"
                                     type="square"
                                     mediaTarget="SQUARE"
                                     mandatory
                                     width={750} height={750}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled}
                      />
                      <ImageSelector label='Desktop Background'
                                     type='desktopBackground'
                                     mediaTarget='DESKTOP_BACKGROUND'
                                     width={1920} height={720}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled}
                      />
                      <div className='info-msg' id='infoMsg'>This is the appropriate image for web desktop display</div>
                    </Panel>
                  ) : null}
                  {activeTab === 'contentItems' ? (
                    <ContentItemsPanel model={this.model}
                                       updateField={this.updateField.bind(this)}
                                       validationErrors={validationErrors}
                                       contentItems={contentItems}
                                       ids={contentItemIds}
                                       onModify={() => this.props.navigateToSelectContentItems(this.props.thankYou)}
                                       onSort={(rows) => this.onSort(rows)}
                                       formDisabled={formDisabled}
                                       viewContent={this.props.viewContent}
                    />
                  ) : null}
                  {activeTab === 'customers' ? (
                    <CustomersPanel manifest={this.props.manifest || null}
                                    uploadMsisdns={this.props.uploadMsisdns}
                                    searchMsisdn={this.props.searchMsisdn}
                                    searchResult={this.props.searchResult || null}
                                    roles={this.props.roles}
                                    s3Files={this.props.s3Files}
                                    listS3Files={this.props.listS3Files}
                                    onSubmitFileForProcessing={this.props.onSubmitFileForProcessing}
                    />
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onMount={this.props.fetchAudits}
                                 onViewAudit={this.props.onViewAudit}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                            onSave={() => this.saveAndBack()}
                            onDiscard={() => this.props.navigateToDashboard()}
                            onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
        />
      </div>
    )
  }
}
