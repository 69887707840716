import { connect } from 'react-redux'
import { faqActions } from '../faqActions'
import FaqDetails from '../components/faqDetails'

const mapDispatchToProps = (dispatch, ownProps) => {
  const faqSectionId = ownProps.match.params.faqSectionId
  const faqId = ownProps.match.params.faqId
  const isEdit = !!faqId
  return {
    load: () => {
      if (isEdit) {
        dispatch(faqActions.fetchFaq(faqSectionId, faqId))
      }
    },
    nextHandler: (e, faqSectionId, faqDetails) => {
      e.preventDefault()
      if (isEdit) {
        const updatedFaqDetails = {
          question: faqDetails.question,
          faqId: faqDetails.faqId,
          answer: faqDetails.answer,
          platforms: faqDetails.platforms,
          enabled: faqDetails.enabled,
          creationDate: faqDetails.creationDate
        }
        dispatch(faqActions.updateFaq(faqSectionId, updatedFaqDetails, ownProps.match.params.faqId))
      } else {
        dispatch(faqActions.createFaq(faqSectionId, faqDetails))
      }
    },
    backHandler: (e, faqSectionId) => {
      e.preventDefault()
      dispatch(faqActions.navigateToFaqListing(faqSectionId))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    faqSectionId: ownProps.match.params.faqSectionId,
    faq: state.faqs.faq || {},
    validationErrors: state.faqs.validationErrors || {},
    error: state.faqs.error || '',
    status: state.faqs.faqStatus
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqDetails)
