import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import FormField from '../../common/form/formField'

const initialState = {
  selectedMerkleFile: null
}

const mapToObject = (file) => ({
  label: file,
  value: file
})

class ProcessMerkleModal extends Component {
  constructor(props) {
    super(props)
    this.state = { ...initialState }
  }

  componentDidMount() {
    this.props.listS3Files()
  }

  close() {
    this.setState(initialState)
    this.props.onClose()
  }

  onSubmitClick() {
    this.props.onSubmitFileForProcessing(this.state.selectedMerkleFile).then(() => {
      this.close()
    }).catch((err) => {
      this.setState({ error: err })
    })
  }

  selectFile(file) {
    this.setState({ selectedMerkleFile: file })
  }

  render() {
    const isDisabled = this.state.selectedMerkleFile === null
    return (
      //don't render the modal if s3 files is undefined (happens on intermediate rendering until files are being fetched)
      this.props.s3Files ? (
        <Modal scrollable={'true'} show={this.props.visible} onHide={() => this.close()}>
          <Modal.Header>
            <h4>Process Merkle Files</h4>
          </Modal.Header>

          <Modal.Body style={{
            maxHeight: '400px',
            overflowY: 'auto'
          }}>

            <FormField id="merkleFilesOptions"
                       label={this.props.s3Files.s3Files.length === 0 ? 'No files have been uploaded by Merkle' : 'Merkle files*'}
                       type="radiolist"
                       value={this.state.selectedMerkleFile}
                       options={this.props.s3Files.s3Files.map(mapToObject) || []}
                       onChange={(file) => this.selectFile(file)}
                       disabled={false}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button id="process" onClick={() => this.onSubmitClick()} disabled={isDisabled}>Process</Button>
            <Button id="cancel" onClick={() => this.close()}>Cancel</Button>
          </Modal.Footer>

        </Modal>
      ) : <React.Fragment/>

    )

  }

}

export default ProcessMerkleModal