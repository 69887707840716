import React, { Component } from 'react'
import { Button, Panel } from 'react-bootstrap'
import FormField from '../../common/form/formField'
import Label from '../../common/form/label'
import DateTime from '../../common/form/dateTime'
import DeleteOrDiscardModal from '../../common/components/deleteOrDiscardModal'
import TriggersTable from '../../common/components/triggersTable'

class SchedulePanel extends Component {
  constructor(props) {
    super(props)

    const schedule = props.model.schedule || {}
    this.state = {
      comingSoon: schedule.displayFromDateTime !== schedule.enterFromDateTime,
      extendVisibility: schedule.displayToDateTime !== schedule.drawDateTime,
      saveOrDiscardModalVisible: false
    }

    this.onConfirmationDelete = this.onConfirmationDelete.bind(this)
    this.onEditTrigger = this.onEditTrigger.bind(this)
  }

  toggleComingSoon() {
    const schedule = this.props.model.schedule || {}
    if (this.state.comingSoon) {
      const enterFromDateTime = schedule.enterFromDateTime
      this.props.updateField('schedule.displayFromDateTime', enterFromDateTime)()
    }
    this.setState({ comingSoon: !this.state.comingSoon })
  }

  updateEnterFromDateTime(value) {
    this.props.updateField('schedule.enterFromDateTime', value)()
    if (!this.state.comingSoon) {
      this.props.updateField('schedule.displayFromDateTime', value)()
    }
  }

  toggleExtendVisibility() {
    const schedule = this.props.model.schedule || {}
    if (this.state.extendVisibility) {
      const drawDateTime = schedule.drawDateTime
      this.props.updateField('schedule.displayToDateTime', drawDateTime)()
    }
    this.setState({ extendVisibility: !this.state.extendVisibility })
  }

  updateEnterToDateTime(value) {
    this.props.updateField('schedule.enterToDateTime', value)()
  }

  updateDrawDateTime(value) {
    this.props.updateField('schedule.drawDateTime', value)()
    if (!this.state.extendVisibility) {
      this.props.updateField('schedule.displayToDateTime', value)()
    }
  }

  onConfirmationDelete(index) {
    this.showDeleteOrDiscard()
    this.setState({ indexToBeDeleted: index })
  }

  showDeleteOrDiscard() {
    this.setState({ deleteOrDiscardModalVisible: true })
  }

  onDelete(indexToBeDeleted) {
    this.model = this.props.model
    this.model.schedule.triggers.splice(indexToBeDeleted, 1)
    this.props.savePrizeDraw(this.model)
  }

  onEditTrigger(index) {
    this.props.navigateToEditOurPicksTrigger(this.props.model.id, index)
  }

  render() {
    const schedule = this.props.model.schedule || {}
    const formDisabled = this.props.formDisabled
    const validationErrors = this.props.validationErrors
    const updateField = this.props.updateField

    return (
      <div>
        <Panel header='Schedule' id='schedule'>
          <div className='form-group form-group-checkbox'>
            <label className='control-label' htmlFor='coming-soon'>
              Coming Soon
            </label>
            <div>
              <input type='checkbox' id='coming-soon'
                     checked={this.state.comingSoon}
                     onChange={this.toggleComingSoon.bind(this)}
                     disabled={formDisabled}
              />
            </div>
          </div>
          <div className='form-group form-group-datetime'>
            {this.state.comingSoon ? (
              <DateTime id='display-from' mandatory
                        value={schedule.displayFromDateTime || ''}
                        onChange={(value) => updateField('schedule.displayFromDateTime', value)()}
                        disabled={formDisabled}
              />
            ) : null}
            {this.state.comingSoon && validationErrors.schedule.displayFromDateTime ? (
              <Label className='input-error text-danger'>
                {validationErrors.schedule.displayFromDateTime}
              </Label>
            ) : null}
          </div>
          <FormField label='Enter from' type='datetime' id='enter-from'
                     mandatory
                     value={schedule.enterFromDateTime || ''}
                     onChange={(value) => this.updateEnterFromDateTime(value)}
                     disabled={formDisabled}
                     error={validationErrors.schedule.enterFromDateTime}
          />
          <FormField label='Enter to' type='datetime' id='enter-to'
                     mandatory
                     value={schedule.enterToDateTime || ''}
                     onChange={(value) => this.updateEnterToDateTime(value)}
                     disabled={formDisabled}
                     error={validationErrors.schedule.enterToDateTime}
          />
          <FormField label='Draw on' type='datetime' id='draw-time'
                     mandatory
                     value={schedule.drawDateTime || ''}
                     onChange={(value) => this.updateDrawDateTime(value)}
                     disabled={formDisabled}
                     error={validationErrors.schedule.drawDateTime}
          />
          <div className='form-group form-group-checkbox'>
            <label className='control-label' htmlFor='extend-visibility'>
              Extend Visibility
            </label>
            <div>
              <input type='checkbox' id='extend-visibility'
                     checked={this.state.extendVisibility}
                     onChange={this.toggleExtendVisibility.bind(this)}
                     disabled={formDisabled}
              />
            </div>
          </div>
          <div className='form-group form-group-datetime'>
            {this.state.extendVisibility ? (
              <DateTime id='display-to' mandatory
                        value={schedule.displayToDateTime || ''}
                        onChange={(value) => updateField('schedule.displayToDateTime', value)()}
                        disabled={formDisabled}
              />
            ) : null}
            {this.state.extendVisibility && validationErrors.schedule.displayToDateTime ? (
              <Label className='input-error text-danger'>
                {validationErrors.schedule.displayToDateTime}
              </Label>
            ) : null}
          </div>
        </Panel>
        <Panel header='Our Picks Trigger'>
          <div className='page-header--tools'>
            <Button disabled={formDisabled} bsStyle='primary' id='create-trigger' onClick={() => this.props.newTrigger(this.props.model)}>New Trigger</Button>
            <div className='triggers-list'>
              <TriggersTable triggers={schedule.triggers}
                             validationErrors={validationErrors}
                             onConfirmationDelete={this.onConfirmationDelete}
                             onEditTrigger={this.onEditTrigger}
                             formDisabled={formDisabled}
              />
            </div>
          </div>
        </Panel>
        <DeleteOrDiscardModal message='Do you want to delete this Trigger?'
                              visible={this.state.deleteOrDiscardModalVisible}
                              onDelete={() => {
                                this.onDelete(this.state.indexToBeDeleted)
                                this.setState({ deleteOrDiscardModalVisible: false })
                              }}
                              onDiscard={() => {
                                this.setState({ deleteOrDiscardModalVisible: false })
                                this.props.navigateToSchedule(this.props.model.id)
                              }
                              }
        />
      </div>
    )
  }
}

export default SchedulePanel
