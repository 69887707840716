import { constants } from './offerActions'
import { constants as stockConstants } from '../stock/stockActions'
import { constants as locationConstants } from '../locations/locationActions'

const initialState = {
  // TODO locations / pagination should move the locationsReducer
  locations: [],
  pagination: {
    totalCount: 0,
    totalPages: 0,
    perPage: 5000,
    page: 1
  },
  relatedContent: [],
  stock: {}
}

export default function offers(state = initialState, action = {}) {
  switch (action.type) {
    case constants.OFFER_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.OFFER_NEW:
      return Object.assign({}, state, {
        offer: action.offer,
        status: 'SUCCESS',
        error: null,
        locations: [],
        relatedContent: []
      })
    case constants.OFFER_SAVED:
    case constants.OFFER_FETCHED:
    case constants.OFFER_SUBMITTED:
    case constants.OFFER_REJECTED:
    case constants.OFFER_PUBLISHED:
    case constants.OFFER_PAUSED:
    case constants.OFFER_RESUMED:
    case constants.OFFER_EDITED:
    case constants.OFFER_DISABLED:
    case constants.OFFER_ENABLED:
      return Object.assign({}, state, {
        offer: action.offer,
        status: 'SUCCESS',
        error: null
      })
    case constants.LOCATIONS_FETCHING: {
      return Object.assign({}, state, {
        locations: [],
        status: 'LOADING',
        error: null
      })
    }
    case constants.LOCATIONS_FETCHED: {
      const perPage = state.pagination.perPage
      const totalCount = action.locations.length
      const totalPages = Math.ceil(totalCount / perPage)

      const pagination = {
        totalCount,
        totalPages,
        perPage,
        page: 1
      }
      return Object.assign({}, state, {
        locations: action.locations,
        pagination,
        validationErrors: {},
        status: 'SUCCESS',
        error: action.error
      })
    }
    case locationConstants.LOCATIONS_SELECT_PAGE:
      return Object.assign({}, state, {
        pagination: {
          ...state.pagination,
          page: action.page
        }
      })
    case constants.OFFER_RELATED_CONTENT_FETCHING:
      return Object.assign({}, state, {
        relatedContent: []
      })
    case constants.OFFER_RELATED_CONTENT_FETCHED:
      return Object.assign({}, state, {
        relatedContent: action.relatedContent
      })
    case constants.OFFER_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    case stockConstants.STOCK_VOLUME_SAVING:
    case stockConstants.STOCK_UNIQUE_CODES_UPLOADING:
      return Object.assign({}, state, {
        status: 'SUCCESS',
        offer: action.model
      })
    case stockConstants.STOCK_VOLUME_SAVED:
    case stockConstants.STOCK_UNIQUE_CODES_UPLOADED:
    case stockConstants.STOCK_DELETE_COMPLETED:
      return Object.assign({}, state, {
        status: 'SUCCESS',
        offer: {
          ...state.offer,
          stock: action.stock
        }
      })
    default:
      return state
  }
}
