import React from 'react'

const NoMatchesRow = ({ colSpan }) => (
  <tr>
    <td colSpan={colSpan}>
      <div className='no-results'>No results matched the current search.</div>
    </td>
  </tr>
)
export default NoMatchesRow
