import React from 'react'
import _ from 'lodash'
import { Col, Form, Panel, Row } from 'react-bootstrap'
import Spinner from '../../common/components/spinner'
import { Link } from 'react-router-dom'

import AppError from '../../common/components/appError'
import AppFlash from '../../common/components/appFlash'
import confirm from '../../common/components/confirm'
import FormField from '../../common/form/formField'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import StatusToolbar from '../../common/components/statusToolbar'
import WebLink from '../../common/components/webLink'
import ContentFormBase from '../../common/components/contentFormBase'
import AuditsPanel from '../../common/components/auditsPanel'
import { eventRestrictionsOptions } from '../../common/enums'
import { formatDate } from '../../common/utils'
import Breadcrumbs from './eventDateBreadcrumbs'
import SchedulePanel from './schedulePanel'
import TicketsPanel from './ticketsPanel'
import ContentIdentifier from '../../common/components/contentIdentifier'

export default class EventDateForm extends ContentFormBase {
  constructor(props) {
    super(props)

    this.model = _.cloneDeep(props.eventDate || {})
    this.state = {
      saveOrDiscardModalVisible: false,
      dirty: false
    }

    props.loadTour()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.eventDate, this.props.eventDate)) {
      this.model = _.cloneDeep(this.props.eventDate || {})
      this.forceUpdate()
    }
  }

  toggleCancel(eventDate) {
    const title = `${eventDate.details.cancelled ? 'Reopen' : 'Cancel'} Event`
    const body = `Are you sure you wish to ${eventDate.details.cancelled ? 'reopen' : 'cancel'} this event now?`

    confirm(title, body).then(() => {
      this.props.updateCancelled(eventDate, { cancelled: !eventDate.details.cancelled })
    }).catch(() => '')
  }

  updateVenue(selectedVenue) {
    const selectedVenueOption = selectedVenue[0] || { label: '', value: null }
    this.updateField('details.venueId', selectedVenueOption.value)()
  }

  render() {
    const activeTab = this.props.match.params.section || 'details'
    const sections = ['details', 'schedule', 'tickets']
    const requiredSections = ['details', 'schedule', 'tickets']
    const status = this.props.status || 'LOADING'

    const tour = _.cloneDeep(this.props.tour)
    const eventDate = _.cloneDeep(this.props.eventDate || {})
    const validationErrors = this.model.validationErrors || {}
    sections.forEach((section) => {
      eventDate[section] = eventDate[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })

    const formDisabled = this.isFormDisabled(eventDate.state, status)
    const liveEdit = this.isLiveEdit(eventDate)

    if (!tour || !eventDate) {
      return <Spinner />
    }

    const title = eventDate.id ? tour.details.title : 'New Event Date'

    const venueOptions = this.props.venues ? this.props.venues.sort((a, b) => {
      return `${a.label}`.toLowerCase() > `${b.label}`.toLowerCase() ? 1 : -1
    }) : []
    const selectedVenue = this.props.venues.find(venue => venue.value === eventDate.details.venueId)

    let disabledStateInEffect // by default, this is 'undefined' and will not render the 'Disable/Enable' buttons
    if (eventDate.state === 'LIVE' || eventDate.state === 'DISABLED') {
      disabledStateInEffect = eventDate.details.disabled === true
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className='page-header'>
              <div className='page-header--heading'>
                <div className='page-header--top'>
                  <ContentTypeLabel className='page-header--type' type={eventDate.type} />
                  <StatusLabel className='page-header--state-label' status={eventDate.state} disabledContent={disabledStateInEffect} />
                  {eventDate.details.cancelled ? (
                    <StatusLabelCustom className='page-header--state-label margin-left-5' status='cancelled' text='Cancelled' />
                  ) : null}
                  {tour.isPublished ? (
                    <WebLink content={eventDate} />
                  ) : null}
                  {liveEdit ? (
                    <StatusLabelCustom className='page-header--state-label' status='live_edit' text='Editing published content' />
                  ) : null}
                </div>
                <h1 className='content-heading'>{title}</h1>
                <p>
                  {selectedVenue ? `${selectedVenue.label}, ` : null}
                  {eventDate.details.eventDateTime ? formatDate(eventDate.details.eventDateTime) : null}
                </p>
              </div>
              <div className='page-header--tools'>
                <StatusToolbar status={eventDate.state}
                               contentComplete={this.isComplete(eventDate, validationErrors, sections, requiredSections)}
                               dirty={this.state.dirty}
                               isLiveEdit={liveEdit}
                               onSubmit={this.submit()}
                               onPublish={this.publish()}
                               onReject={this.reject()}
                               onSave={this.save()}
                               onPause={this.pause()}
                               onResume={this.resume()}
                               onEdit={this.edit()}
                               disabledStateInEffect={disabledStateInEffect}
                               onDisable={this.disable()}
                               onEnable={this.enable()}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <Breadcrumbs eventDate={eventDate}
                             validationErrors={validationErrors}
                             activeTab={activeTab}
                             onNavigate={(tab) => this.onNavigate(tab)}
                             showCancel={['PUBLISHED', 'LIVE', 'PAUSED'].indexOf(eventDate.state) > -1}
                             onCancel={() => this.toggleCancel(eventDate)}
                />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
                {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
                <Form>
                  {activeTab === 'details' ? (
                    <Panel header='Basic Details' id='details'>
                      <ContentIdentifier content={eventDate} />
                      {tour.id ? (
                        <div className='form-group form-group-inline' id='offer-group'>
                          <label className='control-label'>Tour:</label>
                          {' '}
                          <Link className='link' to={`/tours/${tour.id}`}>{tour.details.title}</Link>
                        </div>
                      ) : null}
                      <FormField label='Event Start (doors)' type='datetime' id='eventDateTime'
                                 mandatory
                                 value={this.model.details ? this.model.details.eventDateTime : ''}
                                 onChange={(value) => this.updateField('details.eventDateTime', value)()}
                                 disabled={formDisabled}
                                 error={validationErrors.details.eventDateTime}
                      />
                      <FormField label='Venue' type='typeAheadSelect'
                                 name='venue'
                                 value={this.model.details ? this.model.details.venueId : ''}
                                 options={venueOptions}
                                 mandatory
                                 onChange={(selectedOption) => this.updateVenue(selectedOption)}
                                 disabled={formDisabled}
                                 error={validationErrors.details.venueId}
                      />
                      <FormField label='Title' id='title'
                                 value={this.model.details ? this.model.details.title : ''}
                                 maxLength='60'
                                 onChange={this.updateField('details.title')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.title}
                      />
                      <FormField label='Event Restrictions' type='dropdown' id='age-restriction'
                                 options={eventRestrictionsOptions}
                                 value={this.model.details ? this.model.details.ageRestriction : ''}
                                 onChange={(event) => this.updateField('details.ageRestriction', event.target.value)()}
                                 disabled={formDisabled}
                                 error={validationErrors.details.ageRestriction}
                      />
                    </Panel>
                  ) : null}
                  {activeTab === 'schedule' ? (
                    <SchedulePanel model={this.model}
                                   updateField={this.updateField.bind(this)}
                                   validationErrors={validationErrors}
                                   formDisabled={formDisabled}
                    />
                  ) : null}
                  {activeTab === 'tickets' ? (
                    <TicketsPanel model={this.model}
                                  dirty={this.state.dirty}
                                  updateField={this.updateField.bind(this)}
                                  validationErrors={validationErrors}
                                  formDisabled={formDisabled}
                                  updateSoldOut={this.props.updateSoldOut}
                    />
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onViewAudit={this.props.onViewAudit}
                                 onMount={this.props.fetchAudits}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                            onSave={() => this.saveAndBack()}
                            onDiscard={() => this.props.navigateToDashboard()}
                            onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
        />
      </div>
    )
  }
}
