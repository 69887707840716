import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { brandActions } from '../../brands/brandActions'
import { commonActions } from '../../common/commonActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import { articleActions } from '../articleActions.js'
import ArticleForm from '../components/ArticleForm'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      load: () => {
        const { id, auditId } = ownProps.match.params
        if (id) {
          dispatch(articleActions.fetchArticle(id))
          dispatch(articleActions.fetchRelatedContent(id))
        } else {
          dispatch(brandActions.fetchBrands())
          dispatch(articleActions.newArticle())
        }
        dispatch(commonActions.fetchPreferenceCategories())
        if (auditId) {
          dispatch(auditActions.fetchAudit(auditId))
        }
      },
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/articles/${id}/audits/${audit.id}`))
      },
      submit: (article) => {
        dispatch(articleActions.submitArticle(article))
      },
      reject: (article) => {
        dispatch(articleActions.rejectArticle(article))
      },
      publish: (article) => {
        dispatch(articleActions.publishArticle(article))
      },
      pause: (article) => {
        dispatch(articleActions.pauseArticle(article))
      },
      resume: (article) => {
        dispatch(articleActions.resumeArticle(article))
      },
      edit: (article) => {
        dispatch(articleActions.editArticle(article))
      },
      save: (article) => {
        return dispatch(articleActions.saveArticle(article))
      },
      onConfirmationDelete(index) {
        this.setState({ deleteOrDiscardModalVisible: true })
        this.setState({ indexToBeDeleted: index })
      },
      navigateToNewOurPicksTrigger(article) {
        return dispatch(articleActions.navigateToNewOurPicksTrigger(article))
      },
      navigateToEditOurPicksTrigger(article, index) {
        return dispatch(articleActions.navigateToEditOurPicksTrigger(article, index))
      }
    }
  )
}

const mapStateToProps = (state) => {
  return {
    contentType: 'article',
    article: state.articles.article || {},
    relatedContent: state.articles.relatedContent,
    brands: state.brands.brands,
    preferenceCategories: state.common.preferenceCategories,
    status: state.articles.status,
    error: state.articles.error,
    roles: state.common.roles,
    audits: state.audits
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleForm)
