import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      error: {
        message: error.message,
        callStack: info.componentStack
      }
    })
  }

  render() {
    const error = this.state.error

    return error ? (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <div className='page-header--top'>
              <h1>Page error 😢</h1>
            </div>
          </div>
        </div>
        <p>This shouldn't occur, sorry about that.</p>
        <p>Show this to the development team, we'll get it fixed in a jiffy.</p>
        <p><strong>{error.message}</strong></p>
        <pre>{error.callStack}</pre>
      </div>
    ) : this.props.children || null
  }
}

export default ErrorBoundary
