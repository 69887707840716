import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { commonActions } from '../../common/commonActions'
import { accountActions } from '../../accounts/accountActions'
import DashBoard from '../components/dashboard'

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (e) => {
      e.preventDefault()
      dispatch(commonActions.logoutUser())
    },

    changePassword: (e, accountId) => {
      e.preventDefault()
      dispatch(accountActions.navigateToChangePassword(accountId))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: !!state.common.token,
    profile: state.common.profile,
    roles: state.common.roles
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashBoard))
