import React, { Component } from 'react'

export default class FeatureFlagLayout extends Component {
  render() {
    return (
      <div>
        <div className='page-header'>
          <h1>Feature Flags</h1>
        </div>
        {this.props.children}
      </div>
    )
  }
}
