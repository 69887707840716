import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import Listing from '../components/brandListing'

import { merchantActions } from '../merchantActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveAndNavigate: (action) => {
      dispatch(action)
    },
    navigateToMerchants: (e) => {
      e.preventDefault()
      dispatch(merchantActions.navigateToMerchants())
    },
    createBrand: (brand) => {
      return dispatch(merchantActions.createBrand(ownProps.match.params.merchantId, brand))
    },
    brandDetailsHandler: (e, brandId) => {
      e.preventDefault()
      dispatch(push(`/merchants/${ownProps.match.params.merchantId}/brands/${brandId}/locations`))
    },
    clearMessages: () => {
      dispatch(merchantActions.clearMessages())
    },
    load: () => {
      dispatch(merchantActions.fetchMerchant(ownProps.match.params.merchantId))
    }
  }
}

const mapStateToProps = state => {
  return {
    merchant: state.merchants.merchant,
    status: state.merchants.merchantStatus,
    flash: state.common.flash,
    validationErrors: state.merchants.validationErrors,
    displayBrands: state.merchants.displayBrands,
    model: { name: '' }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Listing)
