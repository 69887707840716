import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'

import DataTable from '../../common/components/dataTable'
import Constants from '../../appConstants'
import AppError from '../../common/components/appError'
import Layout from './layout'
import Spinner from '../../common/components/spinner'
import { sectionHeaderTypeOptions } from '../../common/enums'

export default class ManageSectionHeaders extends Component {
  constructor(props) {
    super(props)
    props.fetchSectionHeadersHandler()
  }

  render() {
    let error = <div />
    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }

    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    const headers = {
      type: { name: 'Type', sortable: true },
      title: { name: 'Title', sortable: true },
      isBannerAttached: { name: 'Is Banner Attached?', sortable: false },
      edit: { name: 'Actions', sortable: false }
    }

    let table = <div id="no-data">No Section Headers to display</div>
    if (this.props.sectionHeaders && this.props.sectionHeaders.length > 0) {
      const tableRows = this.props.sectionHeaders.map(sectionHeader => {
        return {
          type: sectionHeaderTypeOptions.filter(option => option.value === sectionHeader.subType).map(option => option.label),
          title: sectionHeader.title,
          isBannerAttached: <input type='checkbox' checked={!!sectionHeader.contentId} disabled readOnly />,
          edit: (
            <span>
              <a className="link"
                 onClick={(e) => {
                   this.props.setClickedSectionHeader(sectionHeader)
                   this.props.navigateToEdit(e, sectionHeader)
                 }}
                 style={{ marginRight: '10px' }}>
                  Edit
              </a>
            </span>
          )
        }
      })
      table = <DataTable displayPageNo={Constants.DEFAULT_DISPLAY_PAGE_NO}
                         rowsPerPage={Constants.DEFAULT_ROWS_PER_PAGE}
                         headers={headers}
                         data={tableRows}
                         wrapperClassName='sectionHeaders-table'
              />
    }

    return (
      <Layout>
        <Row>
          <Col sm={12} lg={12}>
            {error}
            <Panel header="Section Headers">
              <div id="sectionHeader-listing-panel">
                {table}
              </div>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
