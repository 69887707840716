import React, { Component } from 'react'

import AuditDetail from './auditDetail'
import AuditList from './auditList'

class AuditsPanel extends Component {
  constructor(props) {
    super(props)

    if (!props.onMount) return

    props.onMount()
  }

  render() {
    return this.props.match.params.auditId ? (
      <AuditDetail audits={this.props.audits}
      />
    ) : (
      <AuditList audits={this.props.audits}
                 onViewAudit={this.props.onViewAudit}
                 selectPage={this.props.selectPage}
      />
    )
  }
}

export default AuditsPanel
