import React, { Component } from 'react'

import AccountForm from './accountForm'
import AppError from '../../common/components/appError'
import Spinner from '../../common/components/spinner'

export default class CreateAccount extends Component {
  constructor(props) {
    super(props)
    props.newAccount()
  }

  render() {
    const status = this.props.status
    if (status === 'LOADING') {
      return <Spinner />
    }

    const error = this.props.error ? <AppError errorMessage={this.props.error} /> : null

    const validationErrors = this.props.validationErrors || {}
    const nextHandler = this.props.nextHandler
    const backHandler = this.props.backHandler

    const accountDetails = this.props.account || {}

    return (
      <AccountForm account={accountDetails}
                   roles={this.props.roles}
                   profile={this.props.profile}
                   isEditOperation={false}
                   error={error}
                   nextHandler={nextHandler}
                   backHandler={backHandler}
                   validationErrors={validationErrors}
                   match={this.props.match}
      />
    )
  }
}
