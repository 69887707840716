import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Button, Modal } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'

class SelectBrandModal extends Component {
  componentDidMount() {
    const el = ReactDOM.findDOMNode(this.refs.input)

    if (el) {
      el.querySelector('input').select()
      el.querySelector('input').addEventListener('keyup', this.keyPress.bind(this), false)
    }
  }

  keyPress(e) {
    if (e.keyCode === 13) {
      this.props.onSave()
    }
  }

  render() {
    const {
      visible,
      onHide,
      options,
      onChange,
      onCancel,
      onSave,
      selectedBrand
    } = this.props

    return (
      <Modal show={visible} onHide={onHide}>
        <Modal.Body>
          <h4>Select a brand</h4>
          <Typeahead inputProps={{ name: 'brand-search-txt' }}
                     options={options}
                     onChange={onChange}
                     submitFormOnEnter
            // autoFocus
                     ref='input'
          />
        </Modal.Body>
        <Modal.Footer>
          <Button id='cancel-btn' bsStyle='default' onClick={onCancel}>Cancel</Button>
          <Button type='submit' id='continue-btn' bsStyle='primary' disabled={!selectedBrand} onClick={onSave}>Continue</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default SelectBrandModal
