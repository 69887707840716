import React, { Component } from 'react'
import _ from 'lodash'

import Pagination from './pagination'

class DataTable extends Component {
  constructor(props) {
    super(props)
    this.keys = Object.keys(props.headers)
    this.sortOrderMap = {}
    this.keys.forEach((key) => {
      if (props.headers[key].sortable) {
        this.sortOrderMap[key] = {
          isDescending: false,
          className: 'glyphicon glyphicon-sort-by-alphabet'
        }
      }
    })
    this.state = {
      data: props.data,
      pageNo: 0
    }
    this._handleClick = this._handleClick.bind(this)
    this._getPageForSegment = this._getPageForSegment.bind(this)
    this._sortData = this._sortData.bind(this)
  }

  _getPageForSegment(segment, pageNo) {
    var data = segment.map((row, index) => {
      var rowElements = this.keys.map((key, indx) => <td key={indx}>{row[key]}</td>)
      return <tr key={index}> {rowElements} </tr>
    }, this)

    this.setState({
      data: data,
      pageNo: pageNo
    })
  }

  _handleClick(segment, pageNo) {
    this._getPageForSegment(segment, pageNo)
  }

  _sortData(e, header, isDescending) {
    e.preventDefault()
    const { data } = this.props
    const multiplier = isDescending ? -1 : 1
    data.sort((a, b) => {
      let aVal, bVal
      if (typeof a[header] === 'string') {
        aVal = a[header].toLowerCase() || 0
      } else if (typeof a[header] === 'object') {
        aVal = a[header].props.checked || a[header].props.value || 0
      }
      if (typeof b[header] === 'string') {
        bVal = b[header].toLowerCase() || 0
      } else if (typeof a[header] === 'object') {
        bVal = b[header].props.checked || b[header].props.value || 0
      }
      return aVal > bVal ? multiplier : (aVal < bVal ? -multiplier : 0)
    })

    this.sortOrderMap[header].className = this.sortOrderMap[header].isDescending ? 'glyphicon glyphicon-sort-by-alphabet-alt' : 'glyphicon glyphicon-sort-by-alphabet'
    this.sortOrderMap[header].isDescending = !isDescending

    this.setState({
      data: data,
      pageNo: this.props.displayPageNo
    })
  }

  render() {
    this.headers = this.keys.map((key, index) => {
      if (this.props.headers[key].sortable) {
        return (
          <th key={index}
              className='sortable'
              onClick={(e) => {
                this._sortData(e, key, this.sortOrderMap[key].isDescending)
              }}>
            {this.props.headers[key].name}
            <span className={this.sortOrderMap[key].className} />
          </th>
        )
      } else {
        return <th key={index}>{this.props.headers[key].name}</th>
      }
    })

    var paginatedData = this.props.data
    var noOfRowsPerPage = this.props.rowsPerPage || 5

    if (noOfRowsPerPage) {
      paginatedData = _.chunk(this.props.data, noOfRowsPerPage)
    }

    let rows

    if (paginatedData && paginatedData.length > 0) {
      rows = paginatedData[this.state.pageNo].map((row, index) => {
        var rowElements = this.keys.map((key, indx) => <td key={indx}>{row[key]}</td>)
        return <tr key={index}>{rowElements}</tr>
      }, this)
    }

    return (
      <div id='dataTable' className={this.props.wrapperClassName}>
        <table id='data-table' className='table table-striped' cellSpacing='0'>
          <thead>
          <tr id='headers'>
            {this.headers}
          </tr>
          </thead>
          <tbody>
          {rows}
          </tbody>
        </table>
        {paginatedData.length > 1 ? (
          <Pagination totalPages={paginatedData.length}
                      page={this.state.pageNo + 1}
                      onSelect={(pageNo) => this._handleClick(paginatedData[pageNo - 1], pageNo - 1)}
          />
        ) : null}
      </div>
    )
  }
}

export default DataTable
