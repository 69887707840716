import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import moment from 'moment'

import FormField from '../../common/form/formField'
import Label from '../../common/form/label'
import DateTime from '../../common/form/dateTime'

class SchedulePanel extends Component {
  constructor(props) {
    super(props)

    const schedule = props.model.schedule || {}
    this.state = {
      comingSoon: schedule.displayFromDateTime !== schedule.redeemFromDateTime,
      waitingRoom: !!schedule.waitingRoomFromDateTime,
      extendVisibility: schedule.displayToDateTime !== schedule.redeemToDateTime
    }
  }

  toggleWaitingRoom() {
    const schedule = this.props.model.schedule || {}
    if (!this.state.waitingRoom) {
      let waitingRoomFromDateTime = schedule.redeemFromDateTime
      if (waitingRoomFromDateTime) {
        waitingRoomFromDateTime = moment(waitingRoomFromDateTime).subtract(30, 'minutes').toDate()
      }
      this.updateWaitingRoomFromDateTime(waitingRoomFromDateTime)
    } else if (!this.state.comingSoon) {
      this.props.updateField('schedule.displayFromDateTime', schedule.redeemFromDateTime)()
      this.props.updateField('schedule.waitingRoomFromDateTime', null)()
    }
    if (this.state.waitingRoom) {
      this.props.updateField('schedule.waitingRoomFromDateTime', null)()
    }
    this.setState({ waitingRoom: !this.state.waitingRoom })
  }

  toggleComingSoon() {
    const schedule = this.props.model.schedule || {}
    if (this.state.comingSoon) {
      const redeemFromDateTime = this.state.waitingRoom ? schedule.waitingRoomFromDateTime : schedule.redeemFromDateTime
      this.props.updateField('schedule.displayFromDateTime', redeemFromDateTime)()
    }
    this.setState({ comingSoon: !this.state.comingSoon })
  }

  updateRedeemFromDateTime(value) {
    const schedule = this.props.model.schedule || {}
    this.props.updateField('schedule.redeemFromDateTime', value)()
    if (this.state.waitingRoom && !this.state.comingSoon) {
      const waitingRoomFromDateTime = schedule.redeemFromDateTime
      this.props.updateField('schedule.waitingRoomFromDateTime', waitingRoomFromDateTime)()
    }
    if (!this.state.comingSoon) {
      this.props.updateField('schedule.displayFromDateTime', value)()
    }
  }

  updateWaitingRoomFromDateTime(value) {
    this.props.updateField('schedule.waitingRoomFromDateTime', value)()
    if (!this.state.comingSoon) {
      this.props.updateField('schedule.displayFromDateTime', value)()
    }
  }

  toggleExtendVisibility() {
    const schedule = this.props.model.schedule || {}
    if (this.state.extendVisibility) {
      const redeemToDateTime = schedule.redeemToDateTime
      this.props.updateField('schedule.displayToDateTime', redeemToDateTime)()
    }
    this.setState({ extendVisibility: !this.state.extendVisibility })
  }

  updateRedeemToDateTime(value) {
    this.props.updateField('schedule.redeemToDateTime', value)()
    if (!this.state.extendVisibility) {
      this.props.updateField('schedule.displayToDateTime', value)()
    }
  }

  render() {
    const schedule = this.props.model.schedule || {}
    const formDisabled = this.props.formDisabled
    const validationErrors = this.props.validationErrors
    const updateField = this.props.updateField

    return (
      <Panel header='Schedule' id='schedule'>
        <div className='form-group form-group-checkbox'>
          <label className='control-label'>
            Coming Soon
            {' '}
            <div>
              <input type='checkbox' id='coming-soon'
                     checked={this.state.comingSoon}
                     onChange={this.toggleComingSoon.bind(this)}
                     disabled={formDisabled}
              />
            </div>
          </label>
        </div>
        <div className='form-group form-group-datetime'>
          {this.state.comingSoon ? (
            <DateTime id='display-from' mandatory
                      value={schedule.displayFromDateTime || ''}
                      onChange={(value) => updateField('schedule.displayFromDateTime', value)()}
                      disabled={formDisabled}
            />
          ) : null}
          {this.state.comingSoon && validationErrors.schedule.displayFromDateTime ? (
            <Label className='input-error text-danger'>
              {validationErrors.schedule.displayFromDateTime}
            </Label>
          ) : null}
        </div>
        <div className='form-group form-group-checkbox'>
          <label className='control-label'>
            Waiting Room
            {' '}
            <div>
              <input type='checkbox' id='waiting-room'
                     checked={this.state.waitingRoom}
                     onChange={this.toggleWaitingRoom.bind(this)}
                     disabled={formDisabled}
              />
            </div>
          </label>
        </div>
        <div className='form-group form-group-datetime'>
          {this.state.waitingRoom ? (
            <DateTime id='waiting-room-from' mandatory
                      value={schedule.waitingRoomFromDateTime || ''}
                      onChange={(value) => this.updateWaitingRoomFromDateTime(value)}
                      disabled={formDisabled}
            />
          ) : null}
          {this.state.waitingRoom && validationErrors.schedule.waitingRoomFromDateTime ? (
            <Label className='input-error text-danger'>
              {validationErrors.schedule.waitingRoomFromDateTime}
            </Label>
          ) : null}
        </div>
        <FormField label='Presale Start' type='datetime' id='redeem-from'
                   mandatory
                   value={schedule.redeemFromDateTime || ''}
                   onChange={(value) => this.updateRedeemFromDateTime(value)}
                   disabled={formDisabled}
                   error={validationErrors.schedule.redeemFromDateTime}
        />
        <FormField label='Presale End' type='datetime' id='redeem-to'
                   mandatory
                   value={schedule.redeemToDateTime || ''}
                   onChange={(value) => this.updateRedeemToDateTime(value)}
                   disabled={formDisabled}
                   error={validationErrors.schedule.redeemToDateTime}
        />
        <div className='form-group form-group-checkbox'>
          <label className='control-label'>
            Extend Visibility
            {' '}
            <div>
              <input type='checkbox' id='extend-visibility'
                     checked={this.state.extendVisibility}
                     onChange={this.toggleExtendVisibility.bind(this)}
                     disabled={formDisabled}
              />
            </div>
          </label>
        </div>
        <div className='form-group form-group-datetime'>
          {this.state.extendVisibility ? (
            <DateTime id='display-to' mandatory
                      value={schedule.displayToDateTime || ''}
                      onChange={(value) => updateField('schedule.displayToDateTime', value)()}
                      disabled={formDisabled}
            />
          ) : null}
          {this.state.extendVisibility && validationErrors.schedule.displayToDateTime ? (
            <Label className='input-error text-danger'>
              {validationErrors.schedule.displayToDateTime}
            </Label>
          ) : null}
        </div>
      </Panel>
    )
  }
}

export default SchedulePanel
