import { constants } from './articleActions'

export default function articles(state = {}, action = {}) {
  switch (action.type) {
    case constants.ARTICLE_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.ARTICLE_NEW:
      return Object.assign({}, state, {
        article: action.article,
        status: 'SUCCESS',
        error: null,
        relatedContent: []
      })
    case constants.ARTICLE_SAVED:
    case constants.ARTICLE_FETCHED:
    case constants.ARTICLE_SUBMITTED:
    case constants.ARTICLE_REJECTED:
    case constants.ARTICLE_PUBLISHED:
    case constants.ARTICLE_PAUSED:
    case constants.ARTICLE_RESUMED:
    case constants.ARTICLE_EDITED:
      return Object.assign({}, state, {
        article: action.article,
        status: 'SUCCESS',
        error: null
      })
    case constants.ARTICLE_RELATED_CONTENT_FETCHING:
      return Object.assign({}, state, {
        relatedContent: []
      })
    case constants.ARTICLE_RELATED_CONTENT_FETCHED:
      return Object.assign({}, state, {
        relatedContent: action.relatedContent
      })
    case constants.ARTICLE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    default:
      return state
  }
}
