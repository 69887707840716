import { constants } from './auditActions'

const initialState = {
  audits: [],
  audit: null,
  pagination: {
    totalCount: 0,
    totalPages: 0,
    perPage: 50,
    page: 1
  }
}
export default function audits(state = initialState, action = {}) {
  switch (action.type) {
    case constants.AUDITS_CLEAR:
      return Object.assign({}, state, {
        audits: [],
        pagination: {
          ...state.pagination,
          page: 1
        }
      })
    case constants.AUDITS_FETCHING:
      return Object.assign({}, state, {
        audits: [],
        status: 'LOADING'
      })
    case constants.AUDITS_FETCHED:
      return Object.assign({}, state, {
        audits: action.audits,
        status: 'SUCCESS',
        pagination: {
          ...state.pagination,
          totalCount: action.totalCount,
          totalPages: Math.ceil(action.totalCount / state.pagination.perPage)
        }
      })
    case constants.AUDIT_FETCHING: {
      return Object.assign({}, state, {
        audit: null,
        status: 'LOADING'
      })
    }
    case constants.AUDIT_FETCHED:
      return Object.assign({}, state, {
        audit: action.audit,
        status: 'SUCCESS'
      })
    case constants.AUDITS_FETCH_ERROR:
    case constants.AUDIT_FETCH_ERROR:
      return Object.assign({}, state, {
        status: 'ERROR'
      })
    case constants.AUDITS_SELECT_PAGE:
      return Object.assign({}, state, {
        pagination: {
          ...state.pagination,
          page: action.page
        }
      })
    default:
      return state
  }
}
