import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { locationActions } from '../locationActions'
import { brandActions } from '../../brands/brandActions'
import LocationForm from '../components/LocationForm'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load() {
      const { brandId, locationId } = ownProps.match.params
      dispatch(brandActions.fetchBrand(brandId))
      if (locationId) {
        dispatch(locationActions.fetchLocation(brandId, locationId))
      } else {
        dispatch(locationActions.newLocation())
      }
    },
    navigateToSection(section) {
      const { merchantId, brandId } = ownProps.match.params
      dispatch(brandActions.navigateToBrand(merchantId, brandId, section))
    },
    navigateToMerchant() {
      const { merchantId } = ownProps.match.params
      dispatch(push(`/merchants/${merchantId}/brands`))
    },
    geocode(address1, city, postcode) {
      return dispatch(locationActions.geocode(address1, city, postcode))
    },
    save(location) {
      const { brandId } = ownProps.match.params
      return dispatch(locationActions.saveLocation(brandId, location, () => {
        this.navigateToSection('locations')
      }))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    flash: state.common.flash,
    brand: state.brands.brand,
    locations: state.locations.locations,
    location: state.locations.location,
    status: state.brands.status,
    locationStatus: state.locations.status
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationForm)
