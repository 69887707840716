import React, { Component } from 'react'
import { Button, Col, Form, Panel, Row } from 'react-bootstrap'
import _ from 'lodash'

import Spinner from '../../common/components/spinner'
import FormField from '../../common/form/formField'
import { locationStatusOptions } from '../../common/enums'
import AppFlash from '../../common/components/appFlash'
import Breadcrumbs from './venueBreadcrumbs'

export default class VenueForm extends Component {
  constructor(props) {
    super(props)
    this.model = props.venue || {}
    this.validationErrors = props.validationErrors || {}
    this.state = {
      dirty: false,
      geocoding: false
    }
    this.mounted = false
  }

  componentDidMount() {
    this.mounted = true
    this.props.load()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.venue, this.props.venue)) {
      this.model = this.props.venue || {}
      this.forceUpdate()
    }
  }

  update(property) {
    return (e) => {
      this.model[property] = e.target.value
      this.setState({ dirty: true })
    }
  }

  updateCheckbox(property) {
    return (e) => {
      this.model[property] = e.target.checked
      this.setState({ dirty: true })
    }
  }

  save(event) {
    event.preventDefault()
    this.props.save(this.model).then(() => {
      if (this.mounted) {
        // Avoid a warning if the component is not mounted anymore
        this.setState({ dirty: false })
      }
    })
  }

  geocode() {
    const address1 = (this.model.addressLine1 || '')
    const city = (this.model.city || '')
    const postcode = (this.model.postcode || '').replace(/ /ig, '')
    this.setState({ geocoding: true })
    this.props.geocode(address1, city, postcode).then((location) => {
      this.model.latitude = location.latitude
      this.model.longitude = location.longitude

      this.validationErrors = this.validationErrors || {}
      if (this.model.validationErrors.postcode) {
        delete this.model.validationErrors.postcode
      }

      this.setState({ geocoding: false, dirty: true })
    }).catch(err => {
      const error = err.body
      this.validationErrors = this.validationErrors || {}
      this.validationErrors.postcode = error
      this.setState({ geocoding: false })
    })
  }

  navigateToSection(tab) {
    this.props.navigateToVenue()
  }

  render() {
    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }
    const venue = this.model
    if (!venue) {
      return <Spinner />
    }

    const validationErrors = this.props.validationErrors || {}
    const saveDisabled = !this.state.dirty
    const geocodeDisabled = this.state.geocoding || (venue.postcode || '').length < 6 || (venue.addressLine1 || '') === '' || (venue.city || '') === ''
    const title = venue.id ? venue.name : 'New Venue'

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>{title}</h1>
          </div>
          <div className='page-header--tools'>
            <Button id='venue-back-btn'
                    onClick={() => this.navigateToSection('venues')}
            >
              Cancel
            </Button>
            {' '}
            <Button id='venue-save'
                    bsStyle='primary'
                    onClick={(event) => this.save(event)}
                    disabled={saveDisabled}
            >
              Save
            </Button>
          </div>
        </div>
        <Row>
          <Col sm={4} lg={3}>
            <Breadcrumbs venue={this.props.venue}
                         match={this.props.match}
                         onNavigate={(tab) => this.navigateToSection(tab)}
                         validationErrors={validationErrors}
            />
          </Col>
          <Col sm={8} lg={6}>
            {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
            <Panel header='Location details'>
              <Form>
                <FormField label='Venue Name' id='text-venue-name' value={venue.name}
                           onChange={this.update('name')} mandatory
                           error={validationErrors.name}
                />
                <Row>
                  <Col sm={4}>
                    <FormField label='Status' id='select-venue-location-status'
                               value={venue.status}
                               onChange={this.update('status')}
                               type='dropdown' options={locationStatusOptions} mandatory
                               error={validationErrors.status}
                    />
                  </Col>
                </Row>
                <FormField label='O2 Venue' id='venue-o2'
                           value={venue.o2}
                           onChange={this.updateCheckbox('o2')}
                           type='checkbox'
                />
                <FormField label='Address Line 1' id='text-address-line-1'
                           value={venue.addressLine1}
                           onChange={this.update('addressLine1')} mandatory
                           error={validationErrors.addressLine1}
                />
                <FormField label='Address Line 2' id='text-address-line-2'
                           value={venue.addressLine2}
                           onChange={this.update('addressLine2')}
                />
                <FormField label='Address Line 3' id='text-address-line-3'
                           value={venue.addressLine3}
                           onChange={this.update('addressLine3')}
                />
                <FormField label='Address Line 4' id='text-address-line-4'
                           value={venue.addressLine4}
                           onChange={this.update('addressLine4')}
                />
                <Row>
                  <Col sm={6}>
                    <FormField label='City' id='text-city' value={venue.city}
                               onChange={this.update('city')} mandatory
                               error={validationErrors.city}
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField label='County' id='text-county' value={venue.county}
                               onChange={this.update('county')}
                               error={validationErrors.county}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormField label='Postcode' id='text-postcode'
                               value={venue.postcode}
                               onChange={this.update('postcode')} mandatory
                               error={validationErrors.postcode}
                    />
                  </Col>
                  <Col sm={6}>
                    <Button onClick={() => this.geocode()}
                            className='btn-block'
                            style={{ marginTop: '25px' }}
                            disabled={geocodeDisabled}
                    >
                      Geocode Latitude / Longitude
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <FormField label='Latitude' id='text-latitude' value={venue.latitude}
                               type='number'
                               onChange={this.update('latitude')}
                               error={validationErrors.latitude}
                               disabled={this.state.geocoding}
                               mandatory
                    />
                  </Col>
                  <Col sm={6}>
                    <FormField label='Longitude' id='text-longitude' value={venue.longitude}
                               type='number'
                               onChange={this.update('longitude')}
                               error={validationErrors.longitude}
                               disabled={this.state.geocoding}
                               mandatory
                    />
                  </Col>
                </Row>
              </Form>
            </Panel>
          </Col>
        </Row>
      </div>
    )
  }
}
