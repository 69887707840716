import { push } from 'connected-react-router'
import http, { BANNER_DETAILS_V1, ARTICLE_DETAILS_V1, config, OFFER_DETAILS_V1, PRIZEDRAW_DETAILS_V1, TOUR_DETAILS_V1 } from '../services/http'

const navigateToNewAlternateTitle = (bannerId, index) => {
  return push(`/banners/${bannerId}/alternateBannerTitleForm/${index}`)
}

const navigateToOurPicksScheduler = (bannerId, index) => {
  return push(`/banners/${bannerId}/ourPicksTrigger/${index}`)
}

const navigateToSchedule = (bannerId) => {
  return push(`/banners/${bannerId}/schedule`)
}

const navigateToBannerAlternateTitle = (props) => {
  const bannerId = props.model.id
  const alternateTitleBannerId = props.model.alternateTitleBannerId || 0
  return push(`/banners/${bannerId}/alternateBannerTitleForm/${alternateTitleBannerId}`)
}

const createBanner = (banner) => {
  return (dispatch) => {
    dispatch(bannerSaving(banner))
    return http.post(dispatch, `${config.serverUrl}/banners`, banner, {
      Accept: BANNER_DETAILS_V1
    }).then((resp) => {
      dispatch(bannerAdded(resp))
      return resp
    }).catch((error) => {
      dispatch(bannerError(`Unable to create the new banner: ${error.status} (${error.statusText})`))
    })
  }
}

const updateBanner = (banner) => {
  return (dispatch) => {
    dispatch(bannerSaving(banner))
    return http.put(dispatch, `${config.serverUrl}/banners/${banner.id}`, banner, {
      Accept: BANNER_DETAILS_V1
    }).then((resp) => {
      dispatch(bannerSaved(resp))
      return resp
    }).catch((error) => {
      dispatch(bannerError(`Unable to update the banner: ${error.status} (${error.statusText})`))
    })
  }
}

const saveBanner = (banner) => {
  if (!banner.id) {
    return createBanner(banner)
  } else {
    return updateBanner(banner)
  }
}

const bannerAdded = (banner) => ({
  type: constants.BANNER_SAVED,
  banner: banner
})

const newBanner = () => ({
  type: constants.BANNER_NEW,
  banner: {
    type: 'BANNER',
    details: {
      platformFilters: []
    },
    callToAction: {
      urlType: 'NONE'
    }
  }
})

const fetchBanner = (bannerId) => {
  return (dispatch) => {
    dispatch(bannerFetching(bannerId))
    return http.get(dispatch, `${config.serverUrl}/banners/${bannerId}`, null, {
      Accept: BANNER_DETAILS_V1
    }).then((banner) => {
      dispatch(bannerFetched(banner))
    }).catch((error) => {
      dispatch(bannerError(`Unable to retrieve the banner with id ${bannerId}: ${error.status} (${error.statusText})`))
    })
  }
}

const bannerSaving = (banner) => ({
  type: constants.BANNER_SAVING,
  banner: banner
})
const bannerSaved = (banner) => ({
  type: constants.BANNER_SAVED,
  banner: banner
})
const bannerFetching = (bannerId) => ({
  type: constants.BANNER_FETCHING,
  bannerId: bannerId
})
const bannerFetched = (banner) => ({
  type: constants.BANNER_FETCHED,
  banner: banner
})
const bannerError = (error) => ({
  type: constants.BANNER_ERROR,
  error: error
})

const bannerSubmitted = (banner) => ({
  type: constants.BANNER_SUBMITTED,
  banner: banner
})
const bannerRejected = (banner) => ({
  type: constants.BANNER_REJECTED,
  banner: banner
})
const bannerPublished = (banner) => ({
  type: constants.BANNER_PUBLISHED,
  banner: banner
})
const bannerPaused = (banner) => ({
  type: constants.BANNER_PAUSED,
  banner: banner
})
const bannerResumed = (banner) => ({
  type: constants.BANNER_RESUMED,
  banner: banner
})
const bannerEdited = (banner) => ({
  type: constants.BANNER_EDITED,
  banner
})

const updateBannerStatus = (banner, status, successAction) => {
  return (dispatch) => {
    dispatch(bannerSaving(banner))
    return http.post(dispatch, `${config.serverUrl}/banners/${banner.id}/${status}`, null, {
      Accept: BANNER_DETAILS_V1
    }).then((resp) => {
      dispatch(successAction(resp))
    }).catch((error) => {
      dispatch(bannerError(`Unable to ${status} banner: ${error.status} (${error.statusText})`))
    })
  }
}

const submitBanner = (banner) => updateBannerStatus(banner, 'submit', bannerSubmitted)
const rejectBanner = (banner) => updateBannerStatus(banner, 'reject', bannerRejected)
const publishBanner = (banner) => updateBannerStatus(banner, 'publish', bannerPublished)
const pauseBanner = (banner) => updateBannerStatus(banner, 'pause', bannerPaused)
const resumeBanner = (banner) => updateBannerStatus(banner, 'resume', bannerResumed)
const editBanner = (banner) => updateBannerStatus(banner, 'draft', bannerEdited)

const calendarContentFetching = () => ({
  type: constants.BANNER_CALENDAR_CONTENT_FETCHING
})
const calendarContentFetched = (calendarContent) => ({
  type: constants.BANNER_CALENDAR_CONTENT_FETCHED,
  calendarContent
})
const fetchCalendarContent = () => {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.banners.banner.callToAction || !state.banners.banner.callToAction.calendarId) {
      return Promise.resolve(dispatch(calendarContentFetched(null)))
    }
    const calendarId = state.banners.banner.callToAction.calendarId
    dispatch(calendarContentFetching())
    let configUrl = ''
    let header
    switch (calendarId.type) {
      case 'ARTICLE':
        configUrl = `${config.serverUrl}/articles/${calendarId.id}`
        header = ARTICLE_DETAILS_V1
        break
      case 'OFFER':
        configUrl = `${config.serverUrl}/offers/${calendarId.id}`
        header = OFFER_DETAILS_V1
        break
      case 'PRIZE_DRAW':
        configUrl = `${config.serverUrl}/prizedraws/${calendarId.id}`
        header = PRIZEDRAW_DETAILS_V1
        break
      case 'TOUR':
        configUrl = `${config.serverUrl}/tours/${calendarId.id}`
        header = TOUR_DETAILS_V1
        break
      default:
        dispatch(bannerError(`Unable to support calendar item type ${calendarId.type}`))
    }
    return http.get(dispatch, configUrl, null, {
      Accept: header
    }).then((resp) => {
      dispatch(calendarContentFetched(resp))
    }).catch((err) => {
      dispatch(bannerError(`Unable to retrieve the calendar item with id ${calendarId.id}: ${err.status} (${err.statusText})`))
    })
  }
}

export const constants = {
  BANNER_ERROR: 'BANNER_ERROR',
  BANNER_NEW: 'BANNER_NEW',
  BANNER_FETCHING: 'BANNER_FETCHING',
  BANNER_FETCHED: 'BANNER_FETCHED',
  BANNER_SAVING: 'BANNER_SAVING',
  BANNER_SAVED: 'BANNER_SAVED',
  BANNER_SUBMITTED: 'BANNER_SUBMITTED',
  BANNER_REJECTED: 'BANNER_REJECTED',
  BANNER_PUBLISHED: 'BANNER_PUBLISHED',
  BANNER_PAUSED: 'BANNER_PAUSED',
  BANNER_RESUMED: 'BANNER_RESUMED',
  BANNER_EDITED: 'BANNER_EDITED',
  BANNER_UPLOAD_IMAGE_ERROR: 'BANNER_UPLOAD_IMAGE_ERROR',
  BANNER_CALENDAR_CONTENT_FETCHING: 'BANNER_CALENDAR_CONTENT_FETCHING',
  BANNER_CALENDAR_CONTENT_FETCHED: 'BANNER_CALENDAR_CONTENT_FETCHED'
}

export const bannerActions = {
  newBanner,
  saveBanner,
  bannerSaving,
  bannerSaved,
  fetchBanner,
  bannerFetching,
  bannerFetched,
  submitBanner,
  rejectBanner,
  publishBanner,
  pauseBanner,
  resumeBanner,
  editBanner,
  bannerError,
  updateBannerStatus,
  navigateToOurPicksScheduler,
  navigateToNewAlternateTitle,
  navigateToSchedule,
  navigateToBannerAlternateTitle,
  fetchCalendarContent
}
