/* global localStorage */
import '../scss/all'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { getRoutes } from './routes'
import configureStore from './configureStore'
import http, { config } from './services/http'
import { commonActions, constants } from './common/commonActions'
import './polyfills'

let token = null
let profile = null
let roles = []

try {
  token = localStorage.getItem(constants.AUTH_TOKEN)
  token = token ? JSON.parse(token) : null

  profile = localStorage.getItem(constants.PROFILE)
  profile = profile ? JSON.parse(profile) : null

  roles = localStorage.getItem(constants.ROLES)
  roles = roles ? JSON.parse(roles) : []
} catch (err) {
}

http.get(undefined, '/config/config.json').then((resp) => {
  Object.assign(config, resp)
  const initialState = { common: { roles: [] } }
  if (token) {
    initialState.common = { token, profile, roles }
  }
  const history = createBrowserHistory()
  const store = configureStore(initialState, history)

  render(
    <Provider store={store}>
      <div>
        <ConnectedRouter history={history}>
          {getRoutes(store)}
        </ConnectedRouter>
      </div>
    </Provider>,
    document.getElementById('main')
  )

  setInterval(() => {
    commonActions.checkRefreshToken(store)
  }, 60000)
}).catch((err) => {
  console.error(err)
  const message = `Something went wrong, ${err.statusCode}:${err.statusText}`
  render(<div>{message}</div>, document.getElementById('main'))
})
