import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'

import Breadcrumbs from './breadcrumbs'
import DataTable from '../../common/components/dataTable'
import Constants from '../../appConstants'
import AppError from '../../common/components/appError'
import Layout from './layout'
import { config } from '../../services/http'
import Spinner from '../../common/components/spinner'
import { formatDateTime } from '../../common/utils'

export default class ManageAccounts extends Component {
  constructor(props) {
    super(props)
    props.fetchAccountsHandler()
  }

  render() {
    let error = <div />
    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }

    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    var headers = {
      name: { name: 'Name', sortable: true },
      email: { name: 'Email', sortable: true },
      enabled: { name: 'Enabled', sortable: true },
      edit: { name: 'Actions', sortable: false },
      lastLoggedInAt: { name: 'last logged-in date', sortable: false }
    }

    let table = <div id='no-data'>No Accounts to display</div>
    if (this.props.accounts && this.props.accounts.length > 0) {
      const tableRows = this.props.accounts.map(account => {
        return {
          name: account.firstName + ' ' + account.lastName,
          email: account.email,
          enabled: <input type='checkbox' checked={account.enabled} disabled readOnly />,
          edit: (
            <span>
              <a className='link' onClick={(e) => this.props.navigateToEdit(e, account.id)} style={{ marginRight: '10px' }}>Edit</a>
              {config.auditsEnabled ? (
                <a className='link' onClick={(e) => this.props.onAudit(e, account)}>Audit trail</a>
              ) : null}
            </span>
          ),
          lastLoggedInAt: <p>{formatDateTime(account.lastLoggedInAt)}</p>
        }
      })
      table = <DataTable displayPageNo={Constants.DEFAULT_DISPLAY_PAGE_NO} rowsPerPage={Constants.DEFAULT_ROWS_PER_PAGE} headers={headers} data={tableRows} wrapperClassName='accounts-table' />
    }

    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <Breadcrumbs roles={this.props.roles}
                         profile={this.props.profile}
                         match={this.props.match}
            />
          </Col>
          <Col sm={8} lg={9}>
            {error}
            <Panel header='Accounts'>
              <div id='account-listing-panel'>
                {table}
              </div>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
