import React, { Component } from 'react'

export default class Dropdown extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value || (props.multiple ? [] : '') }
    this._handleChange = this._handleChange.bind(this)
    this._handleMultiChange = this._handleMultiChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value || (this.props.multiple ? [] : '') })
    }
  }

  _handleChange(e) {
    this.props.onChange(e)
    this.setState({ value: e.target.value })
  }

  _handleMultiChange(e) {
    this.props.onChange(e)

    const options = e.target.options
    const selectedOptions = options.filter(option => option.selected).map(option => option.value)

    this.setState({ value: selectedOptions })
  }

  render() {
    return (
      <div className={this.props.className}>
        <select id={this.props.id}
                className='form-control'
                value={this.state.value}
                onChange={this.props.multiple ? this._handleMultiChange : this._handleChange}
                multiple={this.props.multiple}
                disabled={this.props.disabled}
        >
          {this.props.options.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))}
        </select>
      </div>
    )
  }
}
