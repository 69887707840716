import { connect } from 'react-redux'
import { tourActions } from '../tourActions'
import TicketsLayout from '../../common/components/TriggersLayout'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const { id } = ownProps.match.params
      if (id) {
        dispatch(tourActions.fetchTour(id))
      }
    },
    save: (tour) => {
      dispatch(tourActions.saveTour(tour))
      dispatch(tourActions.navigateToSchedule(ownProps.match.params.id))
    },
    backHandler: () => {
      dispatch(tourActions.navigateToSchedule(ownProps.match.params.id))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contentId: ownProps.match.params.id,
    status: state.tours.status,
    content: state.tours.tour,
    contentType: 'tours',
    index: ownProps.match.params.index
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketsLayout)
