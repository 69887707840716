import React from 'react'

const ImagePreview = ({
  imageId,
  blendImageId,
  url,
  blendUrl,
  width,
  height
}) => {
  if (!url || !width || !height) return null

  return (
    <div className='thumbnail'>
      <img className={(imageId === 'circularImageThumbnail') ? 'circle-thumb' : ''} id={imageId} src={url} width={width} height={height} />
      {blendUrl ? (
        <img className={(imageId === 'circularImageThumbnail') ? 'circle-thumb' : ''} id={blendImageId} src={blendUrl} width={width} height={height} />
      ) : null}
    </div>
  )
}

export default ImagePreview
