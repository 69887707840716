import { constants } from './eventDateActions'

const initialState = {}

export default function eventDates(state = initialState, action = {}) {
  switch (action.type) {
    case constants.EVENT_DATE_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.EVENT_DATE_SAVING:
      return Object.assign({}, state, {
        eventDate: action.eventDate,
        status: 'SAVING'
      })
    case constants.EVENT_DATE_NEW:
      return Object.assign({}, state, {
        eventDate: action.eventDate,
        status: 'SUCCESS',
        error: null
      })
    case constants.EVENT_DATE_FETCHED:
    case constants.EVENT_DATE_SAVED:
    case constants.EVENT_DATE_SUBMITTED:
    case constants.EVENT_DATE_REJECTED:
    case constants.EVENT_DATE_PUBLISHED:
    case constants.EVENT_DATE_PAUSED:
    case constants.EVENT_DATE_RESUMED:
    case constants.EVENT_DATE_EDITED:
    case constants.EVENT_DATE_DISABLED:
    case constants.EVENT_DATE_ENABLED:
      return Object.assign({}, state, {
        eventDate: action.eventDate,
        status: 'SUCCESS',
        error: null
      })
    case constants.EVENT_DATE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    case constants.VENUES_FETCHING:
      return Object.assign({}, state, {
        shortVenues: []
      })
    case constants.VENUES_FETCHED:
      return Object.assign({}, state, {
        shortVenues: action.shortVenues,
        status: 'SUCCESS',
        error: null
      })
    default:
      return state
  }
}
