import React, { Component } from 'react'
import { Spinner as Spin } from 'spin.js'
import 'spin.js/spin.css'

export default class Spinner extends Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()

    this.config = {
      color: 'black',
      length: 7,
      radius: 10,
      width: 2
    }
  }

  componentDidMount() {
    this.spinner = new Spin(this.config)
    this.spinner.spin(this.container.current)
  }

  componentWillUnmount() {
    this.spinner.stop()
  }

  render() {
    return <span ref={this.container} />
  }
}
