import { constants } from './stockActions'

const initialState = {}

export default function stock(state = initialState, action = {}) {
  if (!action.type) {
    return state
  }

  switch (action.type) {
    case constants.STOCK_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING',
        validationErrors: {}
      })
    case constants.STOCK_FETCHED:
      return Object.assign({}, state, {
        stock: action.stock,
        status: 'SUCCESS',
        error: null,
        validationErrors: {}
      })
    case constants.STOCK_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR',
        validationErrors: {}
      })
    case constants.STOCK_VALIDATION_ERROR:
      return Object.assign({}, state, {
        validationErrors: action.validationErrors,
        status: 'ERROR'
      })
    case constants.STOCK_LIMIT_SAVING:
    case constants.STOCK_VOLUME_SAVING:
    case constants.STOCK_UNIQUE_CODES_UPLOADING:
    case constants.STOCK_DELETE_REQUESTED:
      return Object.assign({}, state, {
        status: 'SAVING'
      })
    case constants.STOCK_LIMIT_SAVED:
    case constants.STOCK_VOLUME_SAVED:
    case constants.STOCK_UNIQUE_CODES_UPLOADED:
      return Object.assign({}, state, {
        status: 'SUCCESS',
        stock: action.stock,
        validationErrors: {}
      })
    default:
      return state
  }
}
