import _ from 'lodash'
import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'
import ValidationIndicator from '../../common/components/validationIndicator'
import { config } from '../../services/http'

export default class Breadcrumbs extends Component {
  getValidationStatus(model, property, validationErrors) {
    if (!model.id) {
      return 'NEW'
    } else if (Object.keys(model[property]).length === 0) {
      return 'NEW'
    } else if (Object.keys(validationErrors[property]).length === 0) {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  getContentItemsValidationStatus(model, validationErrors) {
    if (!model.id || !model.contentItems || !model.contentItems.ids) {
      return 'NEW'
    } else if (Object.keys(validationErrors['contentItems']).length === 0) {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  getManifestValidationStatus(manifest, validationErrors) {
    const results = manifest ? manifest.manifests.filter(m => m.status === 'SUCCESS' || m.status === 'FAILED') : []

    if (results.length === 0) {
      return 'NEW'
    } else if (_.last(results).status === 'SUCCESS') {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  getValidationStatuses() {
    const { thankYou, validationErrors, manifest } = this.props

    return {
      details: this.getValidationStatus(thankYou, 'details', validationErrors),
      assets: this.getValidationStatus(thankYou, 'assets', validationErrors),
      contentItems: this.getContentItemsValidationStatus(thankYou, validationErrors),
      customers: this.getManifestValidationStatus(manifest, validationErrors)
    }
  }

  render() {
    var thankYou = this.props.thankYou
    var statuses = this.getValidationStatuses(thankYou)

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='thankyou-breadcrumbs-back'
                 onClick={() => this.props.onNavigate('back')}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Home
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='thankyou-breadcrumbs-details'
                 active={this.props.activeTab === 'details'}
                 onClick={() => this.props.onNavigate('details')}
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Basic Details
        </NavItem>
        <NavItem id='thankyou-breadcrumbs-assets'
                 active={this.props.activeTab === 'assets'}
                 onClick={() => this.props.onNavigate('assets')}
        >
          <ValidationIndicator status={statuses.assets} />
          {' '}
          Assets
        </NavItem>
        <NavItem id='thankyou-breadcrumbs-items'
                 active={this.props.activeTab === 'contentItems'}
                 onClick={() => this.props.onNavigate('contentItems')}
        >
          <ValidationIndicator status={statuses.contentItems} />
          {' '}
          Content
        </NavItem>
        <NavItem id='thankyou-breadcrumbs-customers'
                 active={this.props.activeTab === 'customers'}
                 onClick={() => this.props.onNavigate('customers')}
        >
          <ValidationIndicator status={statuses.customers} />
          {' '}
          Customers
        </NavItem>
        {config.auditsEnabled ? [
          <NavItem key='a' className='nav-item-divider' />,
          <NavItem key='b'
                   id='thankyou-breadcrumbs-audits'
                   active={this.props.activeTab === 'audits'}
                   onClick={() => this.props.onNavigate('audits')}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Audit trail
          </NavItem>
        ] : null}
      </Nav>
    )
  }
}
