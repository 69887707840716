import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import _ from 'lodash'

import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import AppError from '../../common/components/appError'
import AppFlash from '../../common/components/appFlash'

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.model = {
      username: '',
      password: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.credentials, this.props.credentials)) {
      this.model = this.props.credentials
      this.forceUpdate()
    }
  }

  update(property) {
    return (e) => {
      this.model[property] = e.target.value
    }
  }

  render() {
    const nextHandler = this.props.nextHandler
    const validationErrors = this.props.validationErrors || {}

    return (
      <Panel header={(() => {
        switch (window.location.hostname) {
          case 'cms-web.priority.o2.co.uk':
            return 'Login to PRODUCTION environment'
          case 'cms-web.dev.np.priority.o2.co.uk':
            return 'Login to DEVELOPMENT environment'
          case 'cms-web.stage.np.priority.o2.co.uk':
            return 'Login to STAGE environment'
          case 'cms-web.test.np.priority.o2.co.uk':
            return 'Login to TEST environment'
          case 'cms-web.perf.np.priority.o2.co.uk':
            return 'Login to PERF environment'
          case 'localhost':
            return 'Login to LOCAL environment'
          default :
            return 'Please login'
        }
      })()} style={{ maxWidth: '400px', margin: '30px auto' }}>
        <Form nextButtonText='Login' nextHandler={(e) => {
          nextHandler(e, this.model)
        }} linkUrl='/login/resetPasswordNotification'
              linkText='Forgot Password'>

          {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
          {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}

          <FormField label='Email'
                     id='username'
                     value={this.props.credentials.username}
                     onChange={this.update('username')}
                     error={validationErrors.username}
                     mandatory
          />
          <FormField label='Password'
                     id='password'
                     type='password'
                     value={this.props.credentials.password}
                     onChange={this.update('password')}
                     error={validationErrors.password}
                     mandatory
          />

        </Form>
      </Panel>
    )
  }
}
