import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import FormField from '../form/formField'
import Form from '../form/form'
import AppError from './appError'
import _ from 'lodash'

class TriggerForm extends Component {
  constructor(props) {
    super(props)
    this.flash = false
    this.content = this.props.content
    this.index = this.props.index

    this.model = (this.content.schedule && this.content.schedule.triggers) ? { trigger: this.content.schedule.triggers[this.index], length: this.content.schedule.triggers.length } : {
      trigger: {},
      length: 0
    }

    if (this.props.index < 0 || this.props.index > this.model.length) {
      this.flash = true
    }

    this.validationErrors = this.content.validationErrors || {}
    this.validationErrors.schedule = (this.validationErrors.schedule && !_.isString(this.validationErrors.schedule)) ? this.validationErrors.schedule : {}
    this.validationErrors.schedule.triggers = this.validationErrors.schedule[`triggers[${this.index}]`] || {}

    this.saveTrigger = this.saveTrigger.bind(this)
  }

  updateField(path, value) {
    return (e) => {
      _.set(this.model.trigger, path, e ? e.target.value : value)
    }
  }

  _onChangeCheckboxValue(checkboxValue) {
    this.model.trigger['featured'] = checkboxValue.target.checked
  }

  saveTrigger() {
    this.content.schedule = this.content.schedule || {}
    this.content.schedule.triggers = this.content.schedule.triggers || []
    this.content.schedule.triggers[this.index] = this.model.trigger
    this.content.schedule.triggers[this.index].type = this.content.type === 'TOUR' ? 'TICKET' : 'FEATURED'
    this.props.save(this.content)
  }

  render() {
    this.model = this.content.schedule && this.content.schedule.triggers && this.content.schedule.triggers[this.index] ? { trigger: this.content.schedule.triggers[this.index] } : { trigger: {} }

    return (
      <div>
        {this.flash ? <AppError id='trigger-error' errorMessage='The Trigger index is invalid.' /> : null}
        <Panel header={this.content.type === 'TOUR' ? 'Tickets Trigger' : 'Our Picks Trigger'} id='schedule'>
          <Form nextButtonText='Save'
                nextButtonDisabled={!!this.flash}
                nextHandler={this.saveTrigger}
                backHandler={this.props.backHandler}
          >
            <FormField label='Date' type='datetime' id='dateTime'
                       mandatory
                       value={this.model.trigger.dateTime || ''}
                       onChange={(value) => this.updateField('dateTime', value)()}
                       error={this.validationErrors.schedule.triggers.dateTime}
            />
            <FormField label='Rank' type='number' id='rank'
                       value={_.isNil(this.model.trigger.rank) ? '' : this.model.trigger.rank}
                       onChange={this.updateField('rank')}
                       error={this.validationErrors.schedule.triggers.rank}
            />
            {this.content.type !== 'TOUR' ? (
              <FormField label='Our Picks' type='checkbox' id='featured'
                value={this.model.trigger.featured}
                onChange={(e) => {
                  this._onChangeCheckboxValue(e)
                }}
                error={this.validationErrors.schedule.triggers.featured}
              />
            ) : null}
          </Form>

        </Panel>
      </div>
    )
  }
}

export default TriggerForm
