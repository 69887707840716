import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import FormField from '../../common/form/formField'
import { formatNumber } from '../../common/utils'

const initialState = {
  volume: 0
}

class ModifyStockModal extends Component {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
  }

  close() {
    this.setState(initialState)
    this.props.onClose()
  }

  save() {
    this.props.onSave(this.state.volume).then(() => {
      this.props.onClose()
      this.setState(initialState)
    }).catch(() => {
    })
  }

  render() {
    const validationErrors = this.props.validationErrors || {}
    const remainingStock = this.props.stock.cumulativeRemainingStock || 0
    let minVolume = 0
    if (remainingStock > 0) {
      minVolume = 0 - remainingStock
    }
    const disabled = !this.state.volume || this.state.volume < minVolume

    return (
      <Modal show={this.props.visible} onHide={() => this.close()}>
        <Modal.Header>
          <h4>Modify Stock</h4>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Stock remaining:</strong>
            {' '}
            <span>{formatNumber(remainingStock)}</span>
          </p>
          <FormField label="Volume" id="volume" mandatory
                     type="number"
                     min={minVolume}
                     onChange={(e) => this.setState({ volume: e.target.value })}
                     value={this.state.volume}
                     error={validationErrors.volume}
                     help="Add a positive/negative number to add or remove stock"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button id="cancel" onClick={() => this.close()}>Cancel</Button>
          <Button id="save" bsStyle="primary"
                  onClick={() => this.save()}
                  disabled={disabled}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModifyStockModal
