import React, { Component } from 'react'

import ImageCropModal from '../../common/components/imageCropModal'

export default class ImageInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.value || '',
      imageToCrop: null
    }
    this._handleChange = this._handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value || '' })
    }
  }

  static fileToImage(file) {
    return new Promise(function(resolve, reject) {
      const reader = new window.FileReader()
      reader.addEventListener('load', () => {
        var src = reader.result
        var img = document.createElement('img')
        img.src = src
        img.onload = () => {
          resolve(img)
        }
        img.onerror = () => {
          reject(new Error('Please select an image file (gif, png, jpg).'))
        }
      }, false)
      if (file) {
        reader.readAsDataURL(file)
      }
    })
  }

  _handleChange(e) {
    const file = e.target.files[0]
    ImageInput.fileToImage(file).then(image => {
      if (image.width === this.props.width && image.height === this.props.height) {
        this.props.onChange(file)
      } else {
        this.setState({ imageToCrop: image.src })
      }
    }).catch(err => {
      this.refs.input.value = null
      this.props.onError(err.message)
    })
  }

  cancel() {
    this.refs.input.value = null
    this.setState({ imageToCrop: null })
  }

  clear(e) {
    this.refs.input.value = null
    this.props.onClear(e)
  }

  render() {
    const containerClasses = this.props.className || undefined
    const id = this.props.id

    const element = (
      <input type='file' id={id} name={id}
             className='form-control'
             ref='input'
             onChange={this._handleChange}
             disabled={this.props.disabled || this.props.innerDisable || false}
             style={this.props.style}
             accept={this.props.acceptFileTypes}
      />
    )

    return (
      <div className={containerClasses}>
        {this.props.value && this.props.enableRemoval ? (
          <a className='file-input-remove link' onClick={e => this.clear(e)}>Remove</a>
        ) : null}
        {element}
        <span className='file-component-icon glyphicon glyphicon-picture'>&nbsp;</span>
        {this.props.galleryMode ? (<span className='file-component-icon glyphicon glyphicon-picture' style={{ right: -25 + 'px' }}>&nbsp;</span>) : null}
        <ImageCropModal visible={!!this.state.imageToCrop}
                        src={this.state.imageToCrop}
                        width={this.props.width}
                        height={this.props.height}
                        shapeType={(this.props.id === 'circularImage') ? 'Circle' : ' '}
                        onClose={() => this.cancel()}
                        onCrop={(file) => this.props.onChange(file)}
        />
      </div>
    )
  }
}
