import React from 'react'
import { formatNumber } from '../utils'

const SelectAllPagesRow = ({
  showSelectAllPages,
  showDeselectAllPages,
  colSpan,
  rowCount,
  pagination,
  onSelect,
  onDeselect
}) => {
  if (showSelectAllPages) {
    return (
      <tr id='select-all-row'>
        <td colSpan={colSpan} className='text-center'>
          All <b>{formatNumber(rowCount)}</b> items on this page are selected.
          {' '}
          <a id='select-all-pages-link' className='link' onClick={onSelect}>Select all <b>{formatNumber(pagination.totalCount)}</b> items</a>
        </td>
      </tr>
    )
  } else if (showDeselectAllPages) {
    return (
      <tr id='deselect-all-row'>
        <td colSpan={colSpan} className='text-center'>
          All <b>{formatNumber(pagination.totalCount)}</b> items are selected.
          {' '}
          <a id='deselect-all-pages-link' className='link' onClick={onDeselect}>Deselect all items</a>
        </td>
      </tr>
    )
  }

  return null
}

export default SelectAllPagesRow
