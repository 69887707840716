import { push } from 'connected-react-router'
import http, { config, MUSIC_BANNER_DETAILS_V1 } from '../services/http'

const navigateToOurPicksScheduler = (bannerId, index) => {
  return push(`/musicBanners/${bannerId}/ourPicksTrigger/${index}`)
}

const navigateToSchedule = (bannerId) => {
  return push(`/musicBanners/${bannerId}/schedule`)
}

const createMusicBanner = (musicBanner) => {
  return (dispatch) => {
    dispatch(musicBannerSaving(musicBanner))
    return http.post(dispatch, `${config.serverUrl}/musicbanners`, musicBanner, {
      Accept: MUSIC_BANNER_DETAILS_V1
    }).then((resp) => {
      dispatch(musicBannerAdded(resp))
      return resp
    }).catch((error) => {
      dispatch(musicBannerError(`Unable to create the new music banner: ${error.status} (${error.statusText})`))
    })
  }
}

const updateMusicBanner = (musicBanner) => {
  return (dispatch) => {
    dispatch(musicBannerSaving(musicBanner))
    return http.put(dispatch, `${config.serverUrl}/musicbanners/${musicBanner.id}`, musicBanner, {
      Accept: MUSIC_BANNER_DETAILS_V1
    }).then((resp) => {
      dispatch(musicBannerSaved(resp))
      return resp
    }).catch((error) => {
      dispatch(musicBannerError(`Unable to update the music banner: ${error.status} (${error.statusText})`))
    })
  }
}

const saveMusicBanner = (musicBanner) => {
  if (!musicBanner.id) {
    return createMusicBanner(musicBanner)
  } else {
    return updateMusicBanner(musicBanner)
  }
}

const musicBannerAdded = (musicBanner) => ({
  type: constants.MUSIC_BANNER_SAVED,
  musicBanner: musicBanner
})

const newMusicBanner = () => ({
  type: constants.MUSIC_BANNER_NEW,
  musicBanner: {
    type: 'MUSIC_BANNER',
    details: {},
    callToAction: {
      urlType: 'NONE'
    }
  }
})

const fetchMusicBanner = (id) => {
  return (dispatch) => {
    dispatch(musicBannerFetching(id))
    return http.get(dispatch, `${config.serverUrl}/musicbanners/${id}`, null, {
      Accept: MUSIC_BANNER_DETAILS_V1
    }).then((banner) => {
      dispatch(musicBannerFetched(banner))
    }).catch((error) => {
      dispatch(musicBannerError(`Unable to retrieve the music banner with id ${id}: ${error.status} (${error.statusText})`))
    })
  }
}

const musicBannerSaving = (musicBanner) => ({
  type: constants.MUSIC_BANNER_SAVING,
  musicBanner: musicBanner
})
const musicBannerSaved = (musicBanner) => ({
  type: constants.MUSIC_BANNER_SAVED,
  musicBanner: musicBanner
})
const musicBannerFetching = (id) => ({
  type: constants.MUSIC_BANNER_FETCHING,
  musicBannerId: id
})
const musicBannerFetched = (musicBanner) => ({
  type: constants.MUSIC_BANNER_FETCHED,
  musicBanner: musicBanner
})
const musicBannerError = (error) => ({
  type: constants.MUSIC_BANNER_ERROR,
  error: error
})

const musicBannerSubmitted = (musicBanner) => ({
  type: constants.MUSIC_BANNER_SUBMITTED,
  musicBanner: musicBanner
})
const musicBannerRejected = (musicBanner) => ({
  type: constants.MUSIC_BANNER_REJECTED,
  musicBanner: musicBanner
})
const musicBannerPublished = (musicBanner) => ({
  type: constants.MUSIC_BANNER_PUBLISHED,
  musicBanner: musicBanner
})
const musicBannerPaused = (musicBanner) => ({
  type: constants.MUSIC_BANNER_PAUSED,
  musicBanner: musicBanner
})
const musicBannerResumed = (musicBanner) => ({
  type: constants.MUSIC_BANNER_RESUMED,
  musicBanner: musicBanner
})
const musicBannerEdited = (musicBanner) => ({
  type: constants.MUSIC_BANNER_EDITED,
  musicBanner: musicBanner
})

const updateMusicBannerStatus = (musicBanner, status, successAction) => {
  return (dispatch) => {
    dispatch(musicBannerSaving(musicBanner))
    return http.post(dispatch, `${config.serverUrl}/musicbanners/${musicBanner.id}/${status}`, null, {
      Accept: MUSIC_BANNER_DETAILS_V1
    }).then((resp) => {
      dispatch(successAction(resp))
    }).catch((error) => {
      dispatch(musicBannerError(`Unable to ${status} music banner: ${error.status} (${error.statusText})`))
    })
  }
}

const submitMusicBanner = (musicBanner) => updateMusicBannerStatus(musicBanner, 'submit', musicBannerSubmitted)
const rejectMusicBanner = (musicBanner) => updateMusicBannerStatus(musicBanner, 'reject', musicBannerRejected)
const publishMusicBanner = (musicBanner) => updateMusicBannerStatus(musicBanner, 'publish', musicBannerPublished)
const pauseMusicBanner = (musicBanner) => updateMusicBannerStatus(musicBanner, 'pause', musicBannerPaused)
const resumeMusicBanner = (musicBanner) => updateMusicBannerStatus(musicBanner, 'resume', musicBannerResumed)
const editMusicBanner = (musicBanner) => updateMusicBannerStatus(musicBanner, 'draft', musicBannerEdited)

export const constants = {
  MUSIC_BANNER_ERROR: 'MUSIC_BANNER_ERROR',
  MUSIC_BANNER_NEW: 'MUSIC_BANNER_NEW',
  MUSIC_BANNER_FETCHING: 'MUSIC_BANNER_FETCHING',
  MUSIC_BANNER_FETCHED: 'MUSIC_BANNER_FETCHED',
  MUSIC_BANNER_SAVING: 'MUSIC_BANNER_SAVING',
  MUSIC_BANNER_SAVED: 'MUSIC_BANNER_SAVED',
  MUSIC_BANNER_SUBMITTED: 'MUSIC_BANNER_SUBMITTED',
  MUSIC_BANNER_REJECTED: 'MUSIC_BANNER_REJECTED',
  MUSIC_BANNER_PUBLISHED: 'MUSIC_BANNER_PUBLISHED',
  MUSIC_BANNER_PAUSED: 'MUSIC_BANNER_PAUSED',
  MUSIC_BANNER_RESUMED: 'MUSIC_BANNER_RESUMED',
  MUSIC_BANNER_EDITED: 'MUSIC_BANNER_EDITED',
  MUSIC_BANNER_UPLOAD_IMAGE_ERROR: 'MUSIC_BANNER_UPLOAD_IMAGE_ERROR'
}

export const musicBannerActions = {
  newMusicBanner,
  saveMusicBanner,
  musicBannerSaving,
  musicBannerSaved,
  fetchMusicBanner,
  musicBannerFetching,
  musicBannerFetched,
  submitMusicBanner,
  rejectMusicBanner,
  publishMusicBanner,
  pauseMusicBanner,
  resumeMusicBanner,
  editMusicBanner,
  musicBannerError,
  updateMusicBannerStatus,
  navigateToOurPicksScheduler,
  navigateToSchedule
}
