import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import _ from 'lodash'

import FaqSectionBreadcrumbs from './faqSectionBreadcrumbs'
import Layout from './faqSectionLayout'
import FaqsListPanel from './faqsListPanel'
import Spinner from '../../common/components/spinner'

export default class FaqListing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      faqsList: props.faqs
    }
  }

  componentDidMount() {
    const sectionId = this.props.faqSectionId
    this.props.load(sectionId)
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.faqs, this.props.faqs)) {
      this.setState({ faqsList: this.props.faqs })
    }
  }

  render() {
    const status = this.props.status || 'LOADING'
    const faqs = this.state.faqsList

    if (status === 'LOADING') {
      return <Spinner />
    }

    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <FaqSectionBreadcrumbs match={this.props.match}
                                   faqSectionId={this.props.faqSectionId}
            />
          </Col>
          <Col sm={8} lg={9}>
            <FaqsListPanel faqs={faqs}
                           faqSectionId={this.props.faqSectionId}
                           status={this.props.status}
                           error={this.props.error}
                           onSortSave={this.props.onSortSave}
                           onSortCancel={this.props.onSortCancel}
                           onEdit={this.props.onEdit}
                           onCreateFaq={this.props.onCreateFaq}
            />
          </Col>
        </Row>
      </Layout>
    )
  }
}
