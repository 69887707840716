
import { push } from 'connected-react-router'
import http, { ARTICLE_DETAILS_V1, config, OFFER_DETAILS_V1, PRIZEDRAW_DETAILS_V1, TOUR_DETAILS_V1 } from '../services/http'

const navigateToNewAlternateTitle = (campaignId, index) => {
  return push(`/campaigns/${campaignId}/alternateCampaignTitleForm/${index}`)
}

const navigateToSchedule = (campaignId) => {
  return push(`/campaigns/${campaignId}/schedule`)
}

const navigateToCampaignAlternateTitle = (props) => {
  const campaignId = props.model.id
  const alternateTitleCampaignId = props.model.alternateTitleCampaignId || 0
  return push(`/campaigns/${campaignId}/alternateCampaignTitleForm/${alternateTitleCampaignId}`)
}

const createCampaign = (campaign) => {
  return (dispatch) => {
    dispatch(campaignSaving(campaign))
    return http.post(dispatch, `${config.serverUrl}/campaigns`, campaign, {
      Accept: config.campaignDetailsSchema
    }).then((resp) => {
      dispatch(campaignAdded(resp))
      return resp
    }).catch((error) => {
      dispatch(campaignError(`Unable to create the new campaign: ${error.status} (${error.statusText})`))
    })
  }
}

const updateCampaign = (campaign) => {
  return (dispatch) => {
    dispatch(campaignSaving(campaign))
    return http.put(dispatch, `${config.serverUrl}/campaigns/${campaign.id}`, campaign, {
      Accept: config.campaignDetailsSchema
    }).then((resp) => {
      dispatch(campaignSaved(resp))
      return resp
    }).catch((error) => {
      dispatch(campaignError(`Unable to update the campaign: ${error.status} (${error.statusText})`))
    })
  }
}

const calendarContentFetching = () => ({
  type: constants.CAMPAIGN_CALENDAR_CONTENT_FETCHING
})
const calendarContentFetched = (calendarContent) => ({
  type: constants.CAMPAIGN_CALENDAR_CONTENT_FETCHED,
  calendarContent
})
const fetchCalendarContent = () => {
  return (dispatch, getState) => {
    const state = getState()
    if (!state.campaigns.campaign.callToAction || !state.campaigns.campaign.callToAction.calendarId) {
      return Promise.resolve(dispatch(calendarContentFetched(null)))
    }
    const calendarId = state.campaigns.campaign.callToAction.calendarId
    dispatch(calendarContentFetching())
    let configUrl = ''
    let header
    switch (calendarId.type) {
      case 'ARTICLE':
        configUrl = `${config.serverUrl}/articles/${calendarId.id}`
        header = ARTICLE_DETAILS_V1
        break
      case 'OFFER':
        configUrl = `${config.serverUrl}/offers/${calendarId.id}`
        header = OFFER_DETAILS_V1
        break
      case 'PRIZE_DRAW':
        configUrl = `${config.serverUrl}/prizedraws/${calendarId.id}`
        header = PRIZEDRAW_DETAILS_V1
        break
      case 'TOUR':
        configUrl = `${config.serverUrl}/tours/${calendarId.id}`
        header = TOUR_DETAILS_V1
        break
      default:
        dispatch(campaignError(`Unable to support calendar item type ${calendarId.type}`))
    }
    return http.get(dispatch, configUrl, null, {
      Accept: header
    }).then((resp) => {
      dispatch(calendarContentFetched(resp))
    }).catch((err) => {
      dispatch(campaignError(`Unable to retrieve the calendar item with id ${calendarId.id}: ${err.status} (${err.statusText})`))
    })
  }
}

const saveCampaign = (campaign) => {
  if (!campaign.id) {
    return createCampaign(campaign)
  } else {
    return updateCampaign(campaign)
  }
}

const campaignAdded = (campaign) => ({
  type: constants.CAMPAIGN_SAVED,
  campaign: campaign
})

const newCampaign = () => ({
  type: constants.CAMPAIGN_NEW,
  campaign: {
    type: 'CAMPAIGN',
    details: {
      categories: ['FEATURED']
    }
  }
})

const newCampaignV2 = () => ({
  type: constants.CAMPAIGN_NEW,
  campaign: {
    type: 'CAMPAIGN',
    details: {
      categories: ['FEATURED'],
      caption: 'NONE',
      theme: "NONE"
    },
    callToAction: {
      urlType: 'NONE'
    }
  }
})

const fetchCampaign = (campaignId) => {
  return (dispatch) => {
    dispatch(campaignFetching(campaignId))
    return http.get(dispatch, `${config.serverUrl}/campaigns/${campaignId}`, null, {
      Accept: config.campaignDetailsSchema
    }).then((campaign) => {
      dispatch(campaignFetched(campaign))
    }).catch((error) => {
      dispatch(campaignError(`Unable to retrieve the campaign with id ${campaignId}: ${error.status} (${error.statusText})`))
    })
  }
}

const campaignSaving = (campaign) => ({
  type: constants.CAMPAIGN_SAVING,
  campaign: campaign
})
const campaignSaved = (campaign) => ({
  type: constants.CAMPAIGN_SAVED,
  campaign: campaign
})
const campaignFetching = (campaignId) => ({
  type: constants.CAMPAIGN_FETCHING,
  campaignId: campaignId
})
const campaignFetched = (campaign) => ({
  type: constants.CAMPAIGN_FETCHED,
  campaign: campaign
})
const campaignError = (error) => ({
  type: constants.CAMPAIGN_ERROR,
  error: error
})

const campaignSubmitted = (campaign) => ({
  type: constants.CAMPAIGN_SUBMITTED,
  campaign: campaign
})
const campaignRejected = (campaign) => ({
  type: constants.CAMPAIGN_REJECTED,
  campaign: campaign
})
const campaignPublished = (campaign) => ({
  type: constants.CAMPAIGN_PUBLISHED,
  campaign: campaign
})
const campaignPaused = (campaign) => ({
  type: constants.CAMPAIGN_PAUSED,
  campaign: campaign
})
const campaignResumed = (campaign) => ({
  type: constants.CAMPAIGN_RESUMED,
  campaign: campaign
})
const campaignEdited = (campaign) => ({
  type: constants.CAMPAIGN_EDITED,
  campaign
})

const updateCampaignStatus = (campaign, status, successAction) => {
  return (dispatch) => {
    dispatch(campaignSaving(campaign))
    return http.post(dispatch, `${config.serverUrl}/campaigns/${campaign.id}/${status}`, null, {
      Accept: config.campaignDetailsSchema
    }).then((resp) => {
      dispatch(successAction(resp))
    }).catch((error) => {
      dispatch(campaignError(`Unable to ${status} campaign: ${error.status} (${error.statusText})`))
    })
  }
}

const submitCampaign = (campaign) => updateCampaignStatus(campaign, 'submit', campaignSubmitted)
const rejectCampaign = (campaign) => updateCampaignStatus(campaign, 'reject', campaignRejected)
const publishCampaign = (campaign) => updateCampaignStatus(campaign, 'publish', campaignPublished)
const pauseCampaign = (campaign) => updateCampaignStatus(campaign, 'pause', campaignPaused)
const resumeCampaign = (campaign) => updateCampaignStatus(campaign, 'resume', campaignResumed)
const editCampaign = (campaign) => updateCampaignStatus(campaign, 'draft', campaignEdited)

export const constants = {
  CAMPAIGN_ERROR: 'CAMPAIGN_ERROR',
  CAMPAIGN_NEW: 'CAMPAIGN_NEW',
  CAMPAIGN_FETCHING: 'CAMPAIGN_FETCHING',
  CAMPAIGN_FETCHED: 'CAMPAIGN_FETCHED',
  CAMPAIGN_SAVING: 'CAMPAIGN_SAVING',
  CAMPAIGN_SAVED: 'CAMPAIGN_SAVED',
  CAMPAIGN_SUBMITTED: 'CAMPAIGN_SUBMITTED',
  CAMPAIGN_REJECTED: 'CAMPAIGN_REJECTED',
  CAMPAIGN_PUBLISHED: 'CAMPAIGN_PUBLISHED',
  CAMPAIGN_PAUSED: 'CAMPAIGN_PAUSED',
  CAMPAIGN_RESUMED: 'CAMPAIGN_RESUMED',
  CAMPAIGN_EDITED: 'CAMPAIGN_EDITED',
  CAMPAIGN_UPLOAD_IMAGE_ERROR: 'CAMPAIGN_UPLOAD_IMAGE_ERROR',
  CAMPAIGN_CALENDAR_CONTENT_FETCHING: 'CAMPAIGN_CALENDAR_CONTENT_FETCHING',
  CAMPAIGN_CALENDAR_CONTENT_FETCHED: 'CAMPAIGN_CALENDAR_CONTENT_FETCHED'
}

export const campaignActions = {
  newCampaign,
  newCampaignV2,
  saveCampaign,
  campaignSaving,
  campaignSaved,
  fetchCampaign,
  campaignFetching,
  campaignFetched,
  fetchCalendarContent,
  calendarContentFetched,
  submitCampaign,
  rejectCampaign,
  publishCampaign,
  pauseCampaign,
  resumeCampaign,
  editCampaign,
  campaignError,
  updateCampaignStatus,
  navigateToNewAlternateTitle,
  navigateToSchedule,
  navigateToCampaignAlternateTitle
}
