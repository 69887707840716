import { push } from 'connected-react-router'
import _ from 'lodash'
import { commonActions } from '../common/commonActions'

import http, { config, USER_DETAILS_V1, USER_LIST_V1, USER_UPDATE_PASSWORD_V1 } from '../services/http'

const navigateToDashboard = () => {
  return push('/')
}

const navigateToLogin = () => {
  return push('/login')
}

const navigateToManageAccounts = () => {
  return push('/accounts')
}

const navigateToCreateAccount = () => {
  return push('/accounts/create')
}

const navigateToEditAccount = (accountId) => {
  return push(`/accounts/${accountId}/edit`)
}

const accountsFetching = () => ({
  type: constants.ACCOUNTS_FETCHING
})
const accountsFetched = (accounts) => ({
  type: constants.ACCOUNTS_FETCHED,
  accounts
})

const accountFetchingByEmail = () => ({
  type: constants.ACCOUNT_FETCHING_BY_EMAIL
})
const accountFetchedByEmail = (account) => ({
  type: constants.ACCOUNT_FETCHED_BY_EMAIL,
  account
})

const accountFetching = () => ({
  type: constants.ACCOUNT_FETCHING
})
const accountFetched = (account) => ({
  type: constants.ACCOUNT_FETCHED,
  account
})
const accountCreated = (account) => ({
  type: constants.ACCOUNT_CREATED,
  account,
  error: ''
})

const accountUpdated = (account) => ({
  type: constants.ACCOUNT_UPDATED,
  account
})

const accountError = (error) => ({
  type: constants.ACCOUNT_ERROR,
  error
})

const accountUpdateError = (validationErrors) => ({
  type: constants.ACCOUNT_UPDATE_ERROR,
  validationErrors
})

const resetPasswordNotificationError = (validationErrors) => ({
  type: constants.RESET_PWD_NOTIFICATION_ERROR,
  validationErrors
})

const extractResetPasswordToken = (tokenValue) => ({
  type: constants.EXTRACT_RESET_PWD_TOKEN,
  token: tokenValue
})

const validatingResetPasswordToken = (tokenValue) => ({
  type: constants.VALIDATING_RESET_PWD_TOKEN,
  token: tokenValue
})

const validateResetPasswordTokenSuccess = (tokenValue) => ({
  type: constants.VALIDATE_RESET_PWD_TOKEN_SUCCESS,
  token: tokenValue
})

const newAccount = () => ({
  type: constants.ACCOUNT_NEW,
  validationErrors: {},
  error: '',
  account: {
    roles: ['EXTERNAL'],
     role: 'EXTERNAL',
    enabled: true
  }
})

const fetchAccounts = () => {
  return (dispatch) => {
    dispatch(accountsFetching())
    return http.get(dispatch, `${config.serverUrl}/users/all`, null, {
      Accept: USER_LIST_V1
    }).then((accounts) => {
      dispatch(accountsFetched(accounts))
      return accounts
    }).catch((err) => {
      dispatch(accountError(`Unable to retrieve the list of accounts: ${err.status} (${err.statusText})`))
    })
  }
}

const fetchAccount = (accountId) => {
  return (dispatch) => {
    dispatch(accountFetching())
    return http.get(dispatch, `${config.serverUrl}/users/${accountId}`, null, {
      Accept: USER_DETAILS_V1
    }).then((account) => {
      dispatch(accountFetched(account))
      return http.get(dispatch, `${config.serverUrl}/users/${account.email}`, null)
        .then((cmsAccount) => {
          // roles is [] in DB, but frontend currently treats it as a single value.
          if (cmsAccount && cmsAccount.roles && cmsAccount.roles.length >= 1) {
            account.role = cmsAccount.roles[0].toUpperCase()
          }
          dispatch(accountFetched(account))
        }).catch((error) => {
          dispatch(accountFetched(account))
          dispatch(accountError(`Unable to retrieve the CMS account: ${error.status} (${error.statusText})`))
        })
    }).catch((error) => {
      dispatch(accountError(`Unable to retrieve the account: ${error.status} (${error.statusText})`))
    })
  }
}

const fetchAccountByEmail = (email) => {
  return (dispatch) => {
    dispatch(accountFetchingByEmail())
    return http.get(dispatch, `${config.serverUrl}/users/${email}`, null, {
      Accept: USER_DETAILS_V1
    }).then((account) => {
      dispatch(accountFetchedByEmail(account))
      return account
    }).catch((err) => {
      dispatch(accountError(`Unable to retrieve my account details: ${err.status} (${err.statusText})`))
    })
  }
}

const createAccount = (accountDetails) => {
  accountDetails = {...accountDetails, ...{'roles': [accountDetails.role]}}
  return (dispatch) => {
    dispatch(accountFetching())
      return http.post(dispatch, `${config.serverUrl}/users`, _.omit(accountDetails, 'role'), {
        Accept: USER_DETAILS_V1
      }).then((account) => {
          dispatch(accountCreated(account))
          dispatch(navigateToManageAccounts())
        }).catch(handleErrors(dispatch))
  }
}

const updateAccount = (accountId, accountDetails) => {
 accountDetails = {...accountDetails, ...{'roles': [accountDetails.role]}}
  return (dispatch) => {
    dispatch(accountFetching())
    return http.patch(dispatch, `${config.serverUrl}/users/${accountId}`, _.omit(accountDetails, 'role'), {
      Accept: USER_DETAILS_V1
    }).then((account) => {
      dispatch(accountUpdated(account))
      dispatch(navigateToManageAccounts())
    }).catch(handleErrors(dispatch))
  }
}

const updatePassword = (accountId, payload) => {
  return (dispatch) => {
    return http.put(dispatch, `${config.serverUrl}/users/${accountId}/password`, payload, {
      Accept: USER_UPDATE_PASSWORD_V1
    }).then(() => {
      dispatch(navigateToDashboard())
      dispatch(commonActions.displayFlash('Your password has been updated.'))
    }).catch(handleErrors(dispatch))
  }
}

const sendResetPasswordNotification = (payload) => {
  return (dispatch) => {
    return http.post(dispatch, `${config.serverUrl}/login/sendResetPasswordNotification`, payload, {}).then(() => {
      dispatch(navigateToLogin())
      dispatch(commonActions.displayFlash(`An email to reset your password will be sent to ${payload.email} if it matches an account in our system.`))
    }).catch(handleResetPasswordNotificationErrors(dispatch))
  }
}

const validateResetPasswordToken = (token) => {
  return (dispatch) => {
    dispatch(validatingResetPasswordToken(token))
    return http.get(dispatch, `${config.serverUrl}/login/validateResetPasswordToken/${token}`, token, {}).then((data) => {
      dispatch(validateResetPasswordTokenSuccess(data.token))
    }).catch(() => {
      dispatch(navigateToLogin())
      dispatch(commonActions.displayFlash('The reset password link has expired or is invalid.', 'error'))
    })
  }
}

const resetPassword = (password, token) => {
  const payload = {
    password: password
  }
  return (dispatch) => {
    return http.put(dispatch, `${config.serverUrl}/login/resetPassword/${token}`, payload, {
      Accept: USER_UPDATE_PASSWORD_V1
    }).then(() => {
      dispatch(navigateToLogin())
      dispatch(commonActions.displayFlash('The password has been reset, please login with the new password.'))
    }).catch(handleResetPasswordErrors(dispatch))
  }
}

const handleErrors = (dispatch) => {
  return (response) => {
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(accountUpdateError(json.validationErrors))
    } else {
      if (response.body) {
        dispatch(accountError(response.body))
      } else {
        dispatch(accountError(response.statusText))
      }
    }
  }
}

const handleResetPasswordNotificationErrors = (dispatch) => {
  return (response) => {
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(resetPasswordNotificationError(json.validationErrors))
    }
  }
}

const handleResetPasswordErrors = (dispatch) => {
  return (response) => {
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(accountUpdateError(json.validationErrors))
    } else {
      dispatch(commonActions.displayFlash(`Unable to reset the password: ${response.status} (${response.statusText})`, 'error'))
    }
  }
}

export const constants = {
  ACCOUNTS_FETCHING: 'ACCOUNTS_FETCHING',
  ACCOUNTS_FETCHED: 'ACCOUNTS_FETCHED',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  ACCOUNT_ERROR: 'ACCOUNT_ERROR',
  ACCOUNT_UPDATE_ERROR: 'ACCOUNT_UPDATE_ERROR',
  ACCOUNT_UPDATED: 'ACCOUNT_UPDATED',
  ACCOUNT_NEW: 'ACCOUNT_NEW',
  ACCOUNT_FETCHING: 'ACCOUNT_FETCHING',
  ACCOUNT_FETCHED: 'ACCOUNT_FETCHED',
  ACCOUNT_FETCHING_BY_EMAIL: 'ACCOUNT_FETCHING_BY_EMAIL',
  ACCOUNT_FETCHED_BY_EMAIL: 'ACCOUNT_FETCHED_BY_EMAIL',
  RESET_PWD_NOTIFICATION_ERROR: 'RESET_PWD_NOTIFICATION_ERROR',
  EXTRACT_RESET_PWD_TOKEN: 'EXTRACT_RESET_PWD_TOKEN',
  VALIDATING_RESET_PWD_TOKEN: 'VALIDATING_RESET_PWD_TOKEN',
  VALIDATE_RESET_PWD_TOKEN_SUCCESS: 'VALIDATE_RESET_PWD_TOKEN_SUCCESS'
}

export const accountActions = {
  navigateToDashboard,
  navigateToManageAccounts,
  navigateToCreateAccount,
  navigateToLogin,
  accountsFetching,
  accountsFetched,
  createAccount,
  accountCreated,
  accountError,
  accountUpdateError,
  fetchAccounts,
  fetchAccountByEmail,
  accountFetchingByEmail,
  accountFetchedByEmail,
  accountUpdated,
  newAccount,
  navigateToEditAccount,
  updatePassword,
  fetchAccount,
  accountFetched,
  updateAccount,
  sendResetPasswordNotification,
  resetPasswordNotificationError,
  validateResetPasswordToken,
  extractResetPasswordToken,
  validatingResetPasswordToken,
  validateResetPasswordTokenSuccess,
  resetPassword
}
