import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'

import ValidationIndicator from '../../common/components/validationIndicator'
import { config } from '../../services/http'

export default class SeriesBreadcrumbs extends Component {

  getValidationStatus(model, property, validationErrors) {
    if (!model.id) {
      return 'NEW'
    } else if (Object.keys(model[property]).length === 0) {
      return 'NEW'
    } else if (Object.keys(validationErrors[property]).length === 0) {
      return 'VALID'
    } else {
      return 'INVALID'
    }
  }

  getValidationStatuses() {
    const { series, validationErrors } = this.props

    return {
      details: this.getValidationStatus(series, 'details', validationErrors),
      contentItems: this.getValidationStatus(series, 'contentItems', validationErrors)
    }
  }

  render() {
    var series = this.props.series
    var statuses = this.getValidationStatuses(series)

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='series-breadcrumbs-back'
                 onClick={() => this.props.onNavigate('back')}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Home
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='series-breadcrumbs-details'
                 active={this.props.activeTab === 'details'}
                 onClick={() => this.props.onNavigate('details')}
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Basic Details
        </NavItem>
        <NavItem id='series-breadcrumbs-items'
                 active={this.props.activeTab === 'contentItems'}
                 onClick={() => this.props.onNavigate('contentItems')}
        >
          <ValidationIndicator status={statuses.contentItems} />
          {' '}
          Content
        </NavItem>
        {config.auditsEnabled ? [
          <NavItem key='a' className='nav-item-divider' />,
          <NavItem key='b'
                   id='series-breadcrumbs-audits'
                   active={this.props.activeTab === 'audits'}
                   onClick={() => this.props.onNavigate('audits')}
          >
            <Glyphicon glyph='circle-arrow-right' />
            {' '}
            Audit trail
          </NavItem>
        ] : null}
      </Nav>
    )
  }
}
