import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ImagePreview from '../../common/components/imagePreview'
import FormField from '../../common/form/formField'
import AppError from '../components/appError'
import ImageGallery from '../components/imageGallery'
import Label from '../form/label'
import MediaComponentBase from './MediaComponentBase'
import { mapDispatchToProps, mapStateToProps } from './mediaContainer'

const MAX_IMAGES_IN_GALLERY = 4

class ImageSelector extends MediaComponentBase {
  constructor(props) {
    super(props)
    // do some sanity checks for the usage of `parentMediaProperty`:
    if (!_.isEmpty(props.parentMediaProperty)) {
      if (_.isEmpty(props.defaultImageProperty)) {
        throw new Error('"parentMediaProperty" must always be used together with "defaultImageProperty"')
      } else if (this.props.withBlend) {
        throw new Error('Use of "parentMediaProperty" with blended images is currently unsupported')
      }
    }
  }

  galleryModeActive() {
    return this.props.withGallery && !this.hasYotubeLink()
  }

  handleOnFileChange(image) {
    if (this.galleryModeActive()) {
      this.indexAndUploadImageForGallery(image)
    } else if (this.props.withBlend) {
      this.imageWithBlend(image)
    } else if (this.props.asset) {
      this.assetImage(image)
    } else {
      this.normalImage(image)
    }
  }

  normalImage(image, nameSuffix) {
    this.uploadMedia(image, this.props.mediaTarget, this.mediaType + (nameSuffix || 'ImageUrl'))
  }

  assetImage(image, nameSuffix) {
    this.uploadMedia(image, this.props.mediaTarget, this.mediaType + (nameSuffix || 'ImageUrl'), true)
  }

  imageWithBlend(image) {
    this.blendImage(image, this.mediaType + 'ImageUrl', (blendImage) => {
      this.uploadMedia(image, this.props.mediaTarget, this.mediaType + 'ImageUrl')
      this.uploadMedia(blendImage, this.props.mediaTarget, this.mediaType + 'ImageBlendUrl')
    }, 'default')
  }

  indexAndUploadImageForGallery(image) {
    this.displayYoutubeError = false
    if (this.hasFirstImage()) {
      // treat the file selection as part of the gallery
      const galleryImages = this.getGalleryImages()
      if (this.hasYotubeLink()) {
        this.displayYoutubeError = true
        this.forceUpdate()
      } else if (galleryImages.length < MAX_IMAGES_IN_GALLERY) {
        // add images to gallery
        this.normalImage(image, 'ImageUrlGallery[' + (galleryImages.length) + ']')
      }
    } else {
      // add first/default image
      if (this.props.withBlend) {
        this.imageWithBlend(image)
      } else {
        this.normalImage(image)
      }
    }
  }

  renderRemoveControls() {
    return <a className='image-gallery-custom-action' onClick={this.removeAction.bind(this)} />
  }

  removeAction() {
    const updatedAssets = _.cloneDeep(this.state.assets)
    delete updatedAssets[this.mediaType + 'ImageUrlGallery'][this.refs['fromChild' + this.mediaTypeUcf].getCurrentIndex()]
    const imageArray = updatedAssets[this.mediaType + 'ImageUrlGallery'].filter(url => url !== null)
    this.updateField('assets.' + this.mediaType + 'ImageUrlGallery', imageArray).bind(this)()
    updatedAssets[this.mediaType + 'ImageUrlGallery'] = imageArray
    this.setState({ assets: updatedAssets })
  }

  jsonToGalleryFormatter() {
    const imageArray = []

    function urlToArray(imageURL) {
      if (imageURL != null) {
        imageArray.push({ original: imageURL, thumbnail: imageURL, id: imageArray.length })
      }
    }

    this.getGalleryImages().forEach(urlToArray)
    return imageArray
  }

  handleSort(event) {
    const reorderedGallery = _.flatMap(event.to.children, entry => entry.getElementsByTagName('img')[0].getAttribute('src'))
    _.forEach(reorderedGallery, (imagePath, index) => this.updateField('assets.' + this.mediaType + 'ImageUrlGallery[' + index + ']', imagePath)())
    this.setState({ dirty: true })
  }

  getGalleryImages() {
    return this.state.assets[this.mediaType + 'ImageUrlGallery'] ? this.state.assets[this.mediaType + 'ImageUrlGallery'].filter(url => url !== null) : []
  }

  hasYotubeLink() {
    return (this.state.assets.youtubeVideoId !== null && this.state.assets.youtubeVideoId !== '')
  }

  hasFirstImage() {
    return this.state.assets[this.mediaType + 'ImageUrl'] !== null && this.state.assets[this.mediaType + 'ImageUrl'] !== ''
  }

  isUploadDisabled() {
    // disable the file selector control if:
    // 1) the form itself is disabled, OR
    // 2) the gallery is full AND the main image is present
    return this.props.disabled || (this.getGalleryImages().length === MAX_IMAGES_IN_GALLERY && this.hasFirstImage())
  }

  isRemovalEnabled() {
    // allow main image to be removed if ALL of the following is true:
    // 1) there is no default image URL property
    // 2) the form itself is not disabled
    return _.isEmpty(this.props.defaultImageProperty) && !this.props.disabled
  }

  defaultBlend(ctx, image) {
    const width = image.width
    const height = image.height

    // bg
    ctx.globalCompositeOperation = 'source-over'
    ctx.globalAlpha = 1
    ctx.fillStyle = '#3f3e5e'
    ctx.fillRect(0, 0, width, height)

    // image
    ctx.globalCompositeOperation = 'overlay'
    ctx.globalAlpha = 0.8
    ctx.drawImage(image, 0, 0, width, height)

    // pink gradient
    ctx.globalAlpha = 0.25
    let gradient = ctx.createLinearGradient(0, height / 2, width, 0)
    gradient.addColorStop(0, '#e837b4')
    gradient.addColorStop(1, 'transparent')
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, width, height)

    // blue gradient
    gradient = ctx.createLinearGradient(0, height / 2, width, 0)
    gradient.addColorStop(0, 'transparent')
    gradient.addColorStop(1, '#0077ee')
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, width, height)

    // darken
    ctx.globalCompositeOperation = 'source-over'
    ctx.globalAlpha = 0.05
    ctx.fillStyle = '#000000'
    ctx.fillRect(0, 0, width, height)
  }

  darkerBlend(ctx, image) {
    const width = image.width
    const height = image.height

    // image
    ctx.globalAlpha = 1
    ctx.drawImage(image, 0, 0, width, height)

    // bg
    ctx.globalCompositeOperation = 'multiply'
    ctx.globalAlpha = 0.8
    ctx.fillStyle = '#000033'
    ctx.fillRect(0, 0, width, height)

    // pink gradient
    ctx.globalAlpha = 0.7
    ctx.globalCompositeOperation = 'overlay'
    let gradient = ctx.createLinearGradient(0, height / 2, width, 0)
    gradient.addColorStop(0, '#e837b4')
    gradient.addColorStop(1, 'transparent')
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, width, height)

    // blue gradient
    gradient = ctx.createLinearGradient(0, height / 2, width, 0)
    gradient.addColorStop(0, 'transparent')
    gradient.addColorStop(1, '#0077ee')
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, width, height)
  }

  blendImage(file, field, callback, variant) {
    if (file.size > 500000) {
      return this.addValidationError(field, 'Unable to upload the image, please reduce file size to less than 500kb')
    }

    const reader = new FileReader()
    reader.addEventListener('load', () => {
      var src = reader.result
      const canvas = document.createElement('canvas')

      const image = new window.Image()
      image.crossOrigin = 'anonymous'
      image.src = src
      image.onload = () => {
        canvas.width = image.width
        canvas.height = image.height
        const ctx = canvas.getContext('2d')

        if (variant === 'darker') {
          this.darkerBlend(ctx, image)
        } else {
          this.defaultBlend(ctx, image)
        }

        canvas.toBlob((blob) => {
          callback(blob)
        }, 'image/jpeg', 0.75)
      }
    }, false)
    if (file) {
      reader.readAsDataURL(file)
    }
  }

  render() {
    return (
      <div>
        <FormField label={this.props.label} type='image' id={this.mediaType + 'Image'}
                   mandatory={this.props.mandatory}
                   width={this.props.width} height={this.props.height}
                   value={this.state.assets && this.state.assets[this.mediaType + 'ImageUrl']}
                   onChange={this.handleOnFileChange.bind(this)}
                   onError={(err) => {
                     this.addValidationError(this.mediaType + 'ImageUrl', err)
                   }}
                   onClear={() => {
                     this.updateField('assets.' + this.mediaType + 'ImageUrl', null).bind(this)()
                     if (this.props.withBlend) {
                       this.updateField('assets.' + this.mediaType + 'ImageBlendUrl', null).bind(this)()
                     }
                   }}
                   uploading={this.state.uploading === this.mediaType + 'ImageUrl'}
                   error={this.state.errors[this.mediaType + 'ImageUrl'] || this.state.errors[this.mediaType + 'ImageBlendUrl']}
                   disabled={this.isUploadDisabled()}
                   galleryMode={this.galleryModeActive()}
                   enableRemoval={this.isRemovalEnabled()}
                   acceptFileTypes='.jpeg,.jpg,image/jpeg,image/png'
        />
        {this.displayYoutubeError ? <AppError errorMessage={'Cannot have Gallery and YouTube video at the same time'} /> : null}
        {this.state.errors[this.mediaType + 'ImageUrlGallery']
          ? <Label className={classNames('input-error text-danger')}>
            {this.state.errors[this.mediaType + 'ImageUrlGallery']}
          </Label> : null}
        <ImagePreview imageId={this.mediaType + 'ImageThumbnail'}
                      blendImageId={this.mediaType + 'ImageBlendThumbnail'}
                      url={this.state.assets[this.mediaType + 'ImageUrl']}
                      blendUrl={this.state.assets[this.mediaType + 'ImageBlendUrl']}
                      width={this.props.width} height={this.props.height}
        />
        {this.getGalleryImages().length > 0 ? (
          <div className='galleryImageSize'>
            <ImageGallery items={this.jsonToGalleryFormatter()}
                          renderCustomControls={this.props.disabled ? null : this.renderRemoveControls.bind(this)}
                          ref={'fromChild' + this.mediaTypeUcf}
                          showIndex
                          disableSort={this.props.disabled}
                          onDragDrop={(event) => {
                            this.handleSort(event)
                          }}
                          id={this.mediaType + 'GalleryImages'}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageSelector)

ImageSelector.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'logo',
    'landscape',
    'fullScreen',
    'square',
    'circular',
    'portrait',
    'brand',
    'landscapeBackground',
    'squareBackground',
    'desktopBackground',
    'mobileBackground',
    'tallMobileBackground'
  ]).isRequired,
  mediaTarget: PropTypes.oneOf([
    'BRAND_LOGO',
    'CAMPAIGN_DESKTOP',
    'CAMPAIGN_MOBILE',
    'CAMPAIGN_MOBILE_WEB',
    'CIRCULAR',
    'DESKTOP_BACKGROUND',
    'FULL_SCREEN',
    'GROUP_FEATURED',
    'GROUP_HEADER',
    'GROUP_LARGE_HEADER',
    'GROUP_LIST',
    'GROUP_SPLASH',
    'ITEM_DETAIL',
    'ITEM_FEATURED',
    'LANDSCAPE',
    'PORTRAIT',
    'SQUARE',
    'SUPER_CAMPAIGN_DESKTOP',
    'LANDSCAPE_BACKGROUND',
    'TALL_MOBILE_IMAGE'
  ]).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  withGallery: PropTypes.bool,
  withBlend: PropTypes.bool,
  asset: PropTypes.bool,
  model: PropTypes.object,
  updateParentField: PropTypes.func,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool,
  defaultImageProperty: PropTypes.string,
  parentMediaProperty: PropTypes.string
}

ImageSelector.defaultProps = {
  withGallery: false,
  withBlend: false,
  asset: false,
  disabled: false
}
