import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import Spinner from '../../common/components/spinner'

import AppError from '../../common/components/appError'
import SelectContentTable from './_selectContentTable'
import ContentFilters from './contentFilters'
import InfiniteScroll from './infiniteScroll'
import { selectBannerContentOnly } from '../../common/enums.js'

export default class SelectBannerContent extends InfiniteScroll {
  constructor(props) {
    super(props)
    props.load()
  }

  updateBannerContent() {
    this.props.updateSectionHeaderContentId({
      ...this.props.currentContent,
      contentId: this.props.selectedItems[0] ? this.props.selectedItems[0] : { id: null, type: null }
    })
  }

  render() {
    const status = this.props.status || 'LOADING'
    const contents = this.props.contents.filter((contentItem) => {
      return contentItem.type === 'BANNER'
    })
    const roles = this.props.roles
    const disabledTypes = ['GROUP', 'THANK_YOU', 'SERIES', 'OFFER', 'ARTICLE', 'CAMPAIGN', 'PRIZE_DRAW', 'EVENT']

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>Select a Banner Content Item</h1>
          </div>
          <div className='page-header--tools'>
            <ButtonToolbar>
              <Button id='cancel-banner-content' onClick={() => this.props.backToSectionHeader(this.props.currentContent)}>Cancel</Button>
              <Button id='update-banner-content' bsStyle='primary' onClick={() => this.updateBannerContent()}>Update</Button>
            </ButtonToolbar>
          </div>
        </div>
        <ContentFilters selectedFilters={this.props.selectedFilters}
                        updateFilters={this.props.updateFilters}
                        updateSearch={this.props.updateSearch}
                        contentTypes={selectBannerContentOnly}
                        typesHidden={!roles.some(role => ['ADMIN', 'INTERNAL'].includes(role))}
        />
        {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
        <div className='content-list'>
          {status === 'LOADING' ? (
            <Spinner />
          ) : null}
          <SelectContentTable items={contents}
                              disabledTypes={disabledTypes}
                              selectedItems={this.props.selectedItems}
                              maxSelectable={this.props.maxSelectable}
                              onToggleSelected={this.props.toggleSelected}
                              selectedFilters={this.props.selectedFilters}
          />
        </div>
      </div>
    )
  }
}
