import _ from 'lodash'
import React from 'react'
import { Col, Form, Panel, Row } from 'react-bootstrap'

import AppError from '../../common/components/appError'
import AppFlash from '../../common/components/appFlash'
import AuditsPanel from '../../common/components/auditsPanel'
import CallToAction from '../../common/components/CallToAction'
import ContentFormBase from '../../common/components/contentFormBase'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import RelatedContent from '../../common/components/relatedContent'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import SelectBrandModal from '../../common/components/selectBrandModal'
import Spinner from '../../common/components/spinner'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import StatusToolbar from '../../common/components/statusToolbar'
import WebLink from '../../common/components/webLink'
import { ctaTypeOptions } from '../../common/enums'
import ImageSelector from '../../common/media/ImageSelector'
import VideoSelector from '../../common/media/VideoSelector'
import AudioSelector from '../../common/media/AudioSelector'
import { ctaDefaultUrlType } from '../articleActions'
import ArticleBreadcrumbs from './ArticleBreadcrumbs'
import DetailsPanel from './DetailsPanel'
import SchedulePanel from './SchedulePanel'

export default class ArticleForm extends ContentFormBase {
  constructor(props) {
    super(props)
    this.updateModel(props)
    this.state = {
      saveOrDiscardModalVisible: false,
      selectedBrand: null,
      dirty: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.article, this.props.article)) {
      this.updateModel(this.props)
      this.forceUpdate()
    }
    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
  }

  updateModel(props) {
    this.model = _.cloneDeep(props.article || {})
  }

  modifyRelatedContent() {
    this.props.navigateToSelectRelatedContent(this.props.article)
  }

  render() {
    const activeTab = this.props.match.params.section || 'details'
    const sections = ['details', 'schedule', 'assets', 'callToAction', 'relatedContent']
    const requiredSections = ['details', 'schedule', 'assets']
    const status = this.props.status || 'LOADING'
    const article = _.cloneDeep(this.props.article || {})
    const ids = this.model.relatedContent && this.model.relatedContent.ids || []

    const validationErrors = this.model.validationErrors || {}
    sections.forEach((section) => {
      article[section] = article[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })

    const formDisabled = this.isFormDisabled(article.state, status)
    const liveEdit = this.isLiveEdit(article)
    const brands = this.props.brands
    const brandOptions = _.map(brands, b => ({ id: b.brandId, label: `${b.brandName} (${b.companyName})` }))
    const brand = this.model.brand
    const brandModalVisible = status !== 'LOADING' && !brand && !article.brand

    if (status === 'LOADING') {
      return <Spinner />
    }

    if (!this.model.callToAction) {
      this.model.callToAction = { urlType: ctaDefaultUrlType }
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className='page-header'>
              <div className='page-header--heading'>
                <div className='page-header--top'>
                  <ContentTypeLabel className='page-header--type' type={article.type} />
                  <StatusLabel className='page-header--state-label' status={article.state} />
                  {article.isPublished ? (
                    <WebLink content={article} />
                  ) : null}
                  {liveEdit ? (
                    <StatusLabelCustom className='page-header--state-label' status='live_edit' text='Editing published content' />
                  ) : null}
                </div>
                <h1 className='content-heading'>{article.details.title || 'New Article'}</h1>
              </div>
              <div className='page-header--tools'>
                <StatusToolbar status={article.state}
                               contentComplete={this.isComplete(article, validationErrors, sections, requiredSections)}
                               dirty={this.state.dirty}
                               isLiveEdit={liveEdit}
                               onSubmit={this.submit()}
                               onPublish={this.publish()}
                               onReject={this.reject()}
                               onSave={this.save()}
                               onPause={this.pause()}
                               onResume={this.resume()}
                               onEdit={this.edit()}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <ArticleBreadcrumbs article={article}
                                    validationErrors={validationErrors}
                                    activeTab={activeTab}
                                    onNavigate={(tab) => this.onNavigate(tab)}
                                    dirty={this.state.dirty}
                                    showCopy={article.id}
                                    onCopy={this.copy()}
                />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
                {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
                <Form>
                  {activeTab === 'details' ? (
                    <DetailsPanel model={this.model}
                                  updateField={this.updateField.bind(this)}
                                  validationErrors={validationErrors}
                                  formDisabled={formDisabled}
                                  article={article}
                                  brand={brand}
                                  roles={this.props.roles}
                                  preferenceCategories={this.props.preferenceCategories}
                    />
                  ) : null}
                  {activeTab === 'schedule' ? (
                    <SchedulePanel model={this.model}
                                   updateField={this.updateField.bind(this)}
                                   validationErrors={validationErrors}
                                   saveArticle={this.props.save}
                                   navigateToNewOurPicksTrigger={this.props.navigateToNewOurPicksTrigger}
                                   navigateToEditOurPicksTrigger={this.props.navigateToEditOurPicksTrigger}
                                   formDisabled={formDisabled}
                    />
                  ) : null}
                  {activeTab === 'assets' ? (
                    <Panel header='Assets' id='assets'>

                      <ImageSelector label='Logo Image'
                                     type='logo'
                                     mediaTarget='BRAND_LOGO'
                                     mandatory
                                     defaultImageProperty='brand.logoImageUrl'
                                     width={320} height={320}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Article Background'
                                     type='fullScreen'
                                     mediaTarget='FULL_SCREEN'
                                     mandatory
                                     width={1080} height={1920}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Landscape Background'
                                     type='landscapeBackground'
                                     mediaTarget='LANDSCAPE_BACKGROUND'
                                     mandatory
                                     width={1920} height={720}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Square Background'
                                     type='squareBackground'
                                     mediaTarget='SQUARE'
                                     mandatory
                                     width={750} height={750}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Tall Mobile / App Background'
                                     type='tallMobileBackground'
                                     mediaTarget='TALL_MOBILE_IMAGE'
                                     mandatory
                                     width={750} height={1060}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      {article.details.subtype === 'VIDEO' ? (
                        <div>
                          <VideoSelector label='Landscape Video Background'
                                         type='landscapeBackground'
                                         mediaTarget='LANDSCAPE_BACKGROUND'
                                         width={1920} height={720}
                                         model={this.model}
                                         updateParentField={this.updateField.bind(this)}
                                         disabled={formDisabled} />

                          <VideoSelector label='Tall App Video Background (750 x 1060)'
                                         type='tallMobileBackground'
                                         mediaTarget='TALL_MOBILE_VIDEO'
                                         width={750} height={1060}
                                         model={this.model}
                                         updateParentField={this.updateField.bind(this)}
                                         disabled={formDisabled} />
                        </div>
                      ) : null}

                      {article.details.subtype === 'AUDIO' ? (
                        <AudioSelector label='Audio'
                                       type='audio'
                                       mediaTarget='AUDIO'
                                       model={this.model}
                                       updateParentField={this.updateField.bind(this)}
                                       disabled={formDisabled || article.details.subtype != 'AUDIO'} />
                      ) : null}

                    </Panel>
                  ) : null}
                  {activeTab === 'callToAction' ? (
                    <CallToAction model={this.model}
                                  disabled={formDisabled}
                                  validationErrors={validationErrors}
                                  updateParentField={this.updateField.bind(this)}
                                  typeOptions={ctaTypeOptions}
                                  defaultUrlType={ctaDefaultUrlType}
                    />
                  ) : null}
                  {activeTab === 'relatedContent' ? (
                    <RelatedContent relatedContentItems={this.props.relatedContent}
                                    onModify={() => this.modifyRelatedContent()}
                                    onSort={this.updateSortOf('relatedContent.ids')}
                                    ids={ids}
                                    formDisabled={formDisabled}
                                    viewContent={this.props.viewContent}
                    />
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onMount={this.props.fetchAudits}
                                 onViewAudit={this.props.onViewAudit}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <SelectBrandModal visible={brandModalVisible}
                          onHide={this.props.navigateToDashboard}
                          options={brandOptions}
                          onChange={(brands) => this.selectBrand(brands[0])}
                          onCancel={() => this.props.navigateToDashboard()}
                          onSave={() => this.updateBrandId()}
                          selectedBrand={this.state.selectedBrand}
        />
        <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                            onSave={() => this.saveAndBack()}
                            onDiscard={() => this.props.navigateToDashboard()}
                            onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
        />
      </div>
    )
  }
}
