import _ from 'lodash'
import React from 'react'
import { Button, Glyphicon, Panel, Table } from 'react-bootstrap'
import ContentTypelabel from '../../common/components/contentTypeLabel'
import { SortableBody, SortableRow } from '../../common/components/sortableTable'
import StatusLabel from '../../common/components/statusLabel'
import { formatDateTime } from '../../common/utils'

const AttachedBanners = ({
  attachedBannerItem,
  onModify,
  formDisabled,
  viewContent
}) => (
  <Panel header='Attached Banner' id='attached-banner'>
    {attachedBannerItem ? (
      <Table striped hover>
        <thead>
        <tr>
          <th width='1' />
          <th>Type</th>
          <th>Status</th>
          <th>Title</th>
          <th>Visible from</th>
          <th>Visible to</th>
        </tr>
        </thead>
        <SortableBody>
              <SortableRow key={'banner-content'} onClick={() => viewContent(attachedBannerItem)} className='cursor-pointer'>
                <td>
                  <Glyphicon glyph='menu-hamburger' />
                </td>
                <td><ContentTypelabel type={attachedBannerItem.type} /></td>
                <td><StatusLabel status={attachedBannerItem.state} /></td>
                <td>{_.get(attachedBannerItem, 'details.title')}</td>
                <td>{attachedBannerItem.schedule ? formatDateTime(attachedBannerItem.schedule.displayFromDateTime) : null}</td>
                <td>{attachedBannerItem.schedule ? formatDateTime(attachedBannerItem.schedule.displayToDateTime) : null}</td>
              </SortableRow>
        </SortableBody>
      </Table>
    ) : (
      <p>There are no attached banners yet.</p>
    )}
    {formDisabled ? null : (
      <p>
        <Button id='modify-attached-banner' onClick={onModify}>Add or remove items</Button>
      </p>
    )}
  </Panel>
)

export default AttachedBanners
