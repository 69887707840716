import React, { Component } from 'react'
import { Checkbox } from 'react-bootstrap'
import { offerCategoryOptions, ticketCategoryOptions } from '../enums'
import Label from './label'

const offerCategoryKeys = offerCategoryOptions.map(option => option.value)
const ticketCategoryKeys = ticketCategoryOptions.map(option => option.value)

function includeInArrayIf(arr, item, shouldInclude) {
  const items = arr.slice()
  const inArray = items.includes(item)
  if (inArray && !shouldInclude) {
    var index = items.indexOf(item)
    items.splice(index, 1)
  } else if (!inArray && shouldInclude) {
    items.push(item)
  }

  return items
}

class CategoriesList extends Component {
  onChange(e, option) {
    var value = this.props.value
    if (value.includes(option.value)) {
      var index = value.indexOf(option.value)
      value.splice(index, 1)
    } else {
      value.push(option.value)
    }
    const offersChecked = value.some((element) => {
      return offerCategoryKeys.includes(element)
    })
    const ticketsChecked = value.some((element) => {
      return ticketCategoryKeys.includes(element)
    })

    const isFeatured = value.includes('FEATURED')
    value = includeInArrayIf(value, 'OFFERS', offersChecked || (isFeatured && this.props.offerContentType))
    value = includeInArrayIf(value, 'TICKETS', ticketsChecked || (isFeatured && this.props.ticketContentType))

    this.props.onChange(value)
  }

  render() {
    const roles = this.props.roles || []
    const offersChecked = this.props.value.indexOf('OFFERS') > -1
    const ticketsChecked = this.props.value.indexOf('TICKETS') > -1
    return (
      <div>
        {roles.some(role => ['ADMIN', 'INTERNAL'].includes(role)) ? (
          <div>
            <div className='checkbox-list-group'>
              <Checkbox value='FEATURED' inline
                        checked={this.props.value.indexOf('FEATURED') > -1}
                        disabled={this.props.featuredAlwaysDisabled || this.props.disabled}
                        onChange={(e) => this.onChange(e, { value: 'FEATURED' })}
              >
                Our Picks
              </Checkbox>
            </div>
            <Label htmlFor={this.props.id}>
              {this.props.notMandatory ? 'Interest categories' : 'Interest categories *'}
            </Label>
            <div className='checkbox-list-group'>
              <Checkbox value='OFFERS' inline
                        checked={offersChecked}
                        disabled
              >
                All offers
              </Checkbox>
              {offerCategoryOptions.map((option, index) => {
                return (
                  <Checkbox key={`check-${index}`} value={option.value} inline
                            checked={this.props.value.indexOf(option.value) > -1}
                            disabled={this.props.disabled}
                            onChange={(e) => this.onChange(e, option)}
                  >
                    {option.label}
                  </Checkbox>
                )
              })}
            </div>
          </div>
        ) : null}
        <div className='checkbox-list-group'>
          <Checkbox value='TICKETS' inline
                    checked={ticketsChecked}
                    disabled
          >
            All tickets
          </Checkbox>
          {ticketCategoryOptions.map((option, index) => {
            return (
              <Checkbox key={`check-${index}`} value={option.value} inline
                        checked={this.props.value.indexOf(option.value) > -1}
                        disabled={this.props.disabled}
                        onChange={(e) => this.onChange(e, option)}
              >
                {option.label}
              </Checkbox>
            )
          })}
        </div>
      </div>
    )
  }
}

export default CategoriesList
