import { connect } from 'react-redux'

import EditMsisdn from '../components/editMsisdn'
import { msisdnActions } from '../msisdnActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    nextHandler: (e, msisdnDetails) => {
      const updatedMsisdnDetails = {}
      updatedMsisdnDetails.name = msisdnDetails.name
      updatedMsisdnDetails.enabled = msisdnDetails.enabled
      e.preventDefault()

      dispatch(msisdnActions.updateMsisdn(ownProps.match.params.msisdn, updatedMsisdnDetails))
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(msisdnActions.navigateToManageMsisdn())
    },
    fetchMsisdnHandler: (e) => {
      dispatch(msisdnActions.fetchMsisdn(ownProps.match.params.msisdn))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    msisdn: state.msisdns.msisdn || {},
    status: state.msisdns.status,
    validationErrors: state.msisdns.validationErrors || {},
    error: state.msisdns.error
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMsisdn)
