import _ from 'lodash'
import React, { Component } from 'react'

import FeatureFlagForm from './featureFlagForm'
import AppError from '../../common/components/appError'
import Spinner from '../../common/components/spinner'

export default class EditFeatureFlag extends Component {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.featureFlag || {})
  }

  render() {
    const status = this.props.status
    if (status === 'LOADING') {
      return <Spinner />
    }

    const error = this.props.error ? <AppError errorMessage={this.props.error} /> : null

    const validationErrors = this.props.validationErrors || {}
    const nextHandler = this.props.nextHandler
    const backHandler = this.props.backHandler

    return (
      <FeatureFlagForm featureFlag={this.model}
                       roles={this.props.roles}
                       profile={this.props.profile}
                       isEditOperation
                       error={error}
                       nextHandler={nextHandler}
                       backHandler={backHandler}
                       validationErrors={validationErrors}
                       match={this.props.match}
                       load={this.props.load}
                       viewContent={this.props.viewContent}
                       flash={this.props.flash}
      />
    )
  }
}
