/* global FormData */
import http, { BRAND_LOCATION_PAGE_V1, BRAND_LOCATION_V1, config, GEO_CODE_LOCATION_V1 } from '../services/http'

import { commonActions } from '../common/commonActions'

const fetchLocations = (brandId) => {
  return (dispatch, getState) => {
    const state = getState()
    const params = {
      limit: state.locations.pagination.perPage,
      offset: state.locations.pagination.perPage * (state.locations.pagination.page - 1)
    }
    dispatch(locationsFetching())
    return http.get(dispatch, `${config.locationServerUrl}/brands/${brandId}/locations`, params, {
      Accept: BRAND_LOCATION_PAGE_V1
    }).then((resp) => {
      dispatch(locationsFetched(resp))
    }).catch((err) => {
      dispatch(locationsError())
      dispatch(commonActions.displayFlash(`Unable to retrieve the locations: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const fetchAllLocations = (brandId) => {
  return (dispatch) => {
    const params = {
      limit: 5000,
      offset: 0
    }

    return http.get(dispatch, `${config.locationServerUrl}/brands/${brandId}/locations`, params, {
      Accept: BRAND_LOCATION_PAGE_V1
    })
  }
}

const locationsFetching = () => ({
  type: constants.LOCATIONS_FETCHING
})

const locationsFetched = (locations) => ({
  type: constants.LOCATIONS_FETCHED,
  locations: locations.items,
  totalCount: locations.total
})

const locationsError = () => ({
  type: constants.LOCATIONS_ERROR
})

const fetchLocation = (brandId, locationId) => {
  return (dispatch) => {
    dispatch(locationFetching())
    return http.get(dispatch, `${config.locationServerUrl}/brands/${brandId}/locations/${locationId}`, null, {
      Accept: BRAND_LOCATION_V1
    }).then((location) => {
      dispatch(locationFetched(location))
    }).catch((err) => {
      dispatch(locationError())
      dispatch(commonActions.displayFlash(`Unable to retrieve the location: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const locationFetching = () => ({
  type: constants.LOCATION_FETCHING
})

const locationFetched = (location) => ({
  type: constants.LOCATION_FETCHED,
  location
})

const newLocation = () => ({
  type: constants.LOCATION_NEW,
  location: {
    location: {
      status: 'OPEN'
    }
  }
})

const createLocation = (brandId, location, onSuccess) => {
  return (dispatch) => {
    dispatch(locationSaving(location))
    return http.post(dispatch, `${config.locationServerUrl}/brands/${brandId}/locations`, location.location, {
      Accept: BRAND_LOCATION_V1
    }).then((resp) => {
      onSuccess()
      dispatch(commonActions.displayFlash('Location added', 'info'))
      return resp
    }).catch((err) => {
      if (err.status === 400) {
        const validationErrors = JSON.parse(err.body).validationErrors
        const locationWithErrors = { ...location, validationErrors }
        dispatch(locationSaved(locationWithErrors))
        dispatch(commonActions.displayFlash('There were errors saving the location', 'error'))
      } else {
        dispatch(locationError())
        dispatch(commonActions.displayFlash(`Unable to create the new location: ${err.status} (${err.statusText})`, 'error'))
      }
    })
  }
}

const updateLocation = (brandId, location, onSuccess) => {
  return (dispatch) => {
    dispatch(locationSaving(location))
    return http.put(dispatch, `${config.locationServerUrl}/brands/${brandId}/locations/${location.id}`, location.location, {
      Accept: BRAND_LOCATION_V1
    }).then(() => {
      dispatch(locationSaved(location))
      onSuccess()
      dispatch(commonActions.displayFlash('Location saved', 'info'))
      return location
    }).catch((err) => {
      if (err.status === 400) {
        const validationErrors = JSON.parse(err.body).validationErrors
        const locationWithErrors = { ...location, validationErrors }
        dispatch(locationSaved(locationWithErrors))
        dispatch(commonActions.displayFlash('There were errors saving the location', 'error'))
      } else {
        dispatch(locationError())
        dispatch(commonActions.displayFlash(`Unable to update the location: ${err.status} (${err.statusText})`, 'error'))
      }
    })
  }
}

const saveLocation = (brandId, location, onSuccess) => {
  if (!location.id) {
    return createLocation(brandId, location, onSuccess)
  } else {
    return updateLocation(brandId, location, onSuccess)
  }
}

const locationSaving = (location) => ({
  type: constants.LOCATION_SAVING,
  location
})

const locationSaved = (location) => ({
  type: constants.LOCATION_SAVED,
  location
})

const locationError = () => ({
  type: constants.LOCATION_ERROR
})

const geocode = (address1, city, postcode) => {
  return (dispatch) => {
    return http.get(dispatch, `${config.locationServerUrl}/geocode?line1=${address1}&city=${city}&postcode=${postcode}`, null, {
      Accept: GEO_CODE_LOCATION_V1
    })
  }
}

const uploadLocations = (brandId, file) => {
  return (dispatch) => {
    const formData = new FormData()
    formData.append('locations', file)

    return http.post(dispatch, `${config.locationServerUrl}/brands/${brandId}/locations`, formData)
      .then((resp) => {
        let additionalInfo = ''
        if (resp.errors) {
          resp.errors.forEach(error => {
            additionalInfo += ` ${error}`
          })
        }
        dispatch(commonActions.displayFlash(resp.message + additionalInfo, 'info'))
        dispatch(fetchLocations(brandId))
      })
      .catch((err) => {
        const errors = JSON.parse(err.body)
        const error = new Error('Unable to upload locations')
        error.errors = errors

        throw error
      })
  }
}

const selectPage = (page) => ({
  type: constants.LOCATIONS_SELECT_PAGE,
  page
})

export const constants = {
  LOCATIONS_SELECT_PAGE: 'LOCATIONS_SELECT_PAGE',
  LOCATIONS_FETCHING: 'LOCATIONS_FETCHING',
  LOCATIONS_FETCHED: 'LOCATIONS_FETCHED',
  LOCATIONS_ERROR: 'LOCATIONS_ERROR',
  LOCATION_FETCHING: 'LOCATION_FETCHING',
  LOCATION_FETCHED: 'LOCATION_FETCHED',
  LOCATION_NEW: 'LOCATION_NEW',
  LOCATION_SAVING: 'LOCATION_SAVING',
  LOCATION_SAVED: 'LOCATION_SAVED',
  LOCATION_ERROR: 'LOCATION_ERROR'
}

export const locationActions = {
  fetchLocations,
  fetchLocation,
  fetchAllLocations,
  newLocation,
  saveLocation,
  uploadLocations,
  geocode,
  selectPage,
  locationsFetching,
  locationsFetched,
  locationFetching,
  locationFetched
}
