import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import { accountActions } from '../accountActions'
import { dashboardActions } from '../../dashboard/dashboardActions'
import ManageAccounts from '../components/manageAccounts'

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAccountsHandler: () => {
      dispatch(accountActions.fetchAccounts())
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(dashboardActions.navigateToDashboard())
    },
    navigateToEdit: (e, accountId) => {
      e.preventDefault()
      dispatch(accountActions.navigateToEditAccount(accountId))
    },
    onAudit: (e, account) => {
      e.preventDefault()

      dispatch(push(`/audits?query=${account.id}`))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    accounts: state.accounts.accounts || {},
    status: state.accounts.accountsStatus,
    error: state.accounts.error,
    roles: state.common.roles,
    profile: state.common.profile
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageAccounts)
