import { connectRouter, routerMiddleware } from 'connected-react-router'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'

import thunk from 'redux-thunk'
import accounts from './accounts/accountReducer'
import articles from './articles/articleReducer'
import audits from './audits/auditReducer'
import banners from './banners/bannerReducer'
import brands from './brands/brandReducer'
import campaigns from './campaigns/campaignReducer'
import common from './common/commonReducer'
import contents from './content/contentReducer'
import eventDates from './eventdates/eventDateReducer'
import faqs from './faq/faqReducer'
import groups from './groups/groupReducer'
import locations from './locations/locationReducer'
import merchants from './merchants/merchantReducer'
import musicBanners from './musicBanners/musicBannerReducer'
import notifications from './notification/notificationReducer'
import offers from './offers/offerReducer'
import msisdns from './preview/msisdnReducer'
import prizedraws from './prizedraws/prizedrawReducer'
import preference from './preferences/preferenceReducer'
import sectionHeaders from './sectionHeaders/sectionHeaderReducer'
import welcomePosters from './welcomeposter/welcomePosterReducer'
import featureFlags from './featureFlags/featureFlagReducer'
import series from './series/seriesReducer'
import stock from './stock/stockReducer'
import thankYous from './thankyous/thankYouReducer'
import tours from './tours/tourReducer'
import venues from './venues/venueReducer'

export default function configureStore(initialState, browserHistory) {
  const rootReducer = combineReducers({
    router: connectRouter(browserHistory),
    brands,
    locations,
    common,
    merchants,
    offers,
    prizedraws,
    banners,
    accounts,
    contents,
    groups,
    series,
    stock,
    tours,
    eventDates,
    thankYous,
    campaigns,
    articles,
    venues,
    audits,
    msisdns,
    faqs,
    notifications,
    musicBanners,
    preference,
    sectionHeaders,
    welcomePosters,
    featureFlags
  })

  const composeEnhancers = window.location.hostname === 'localhost' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(thunk, routerMiddleware(browserHistory))
    )
  )
}
