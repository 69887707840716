import React from 'react'
import { Link } from 'react-router-dom'

const GroupLink = ({ model }) => {
  return model.group ? (
    <div className='form-group form-group-inline' id='offer-group'>
      <label className='control-label'>Group:</label>
      {' '}
      <Link className='link' to={`/groups/${model.group.id}`}>{model.group.title}</Link>
    </div>
  ) : null
}

export default GroupLink
