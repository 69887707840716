import { push } from 'connected-react-router'

import http, { config, INTEREST_CATEGORIES_LIST_V1 } from '../services/http'

const navigateToManagePreferenceCategories = () => {
  return push('/preferenceCategories')
}

const navigateToEditPreferenceCategory = (categoryId) => {
  return push(`/preferenceCategory/${categoryId}/edit`)
}

const fetchingPreferenceCategory = () => ({
  type: constants.PREFERENCE_CATEGORY_FETCHING,
  error: ''
})

const fetchingPreferenceCategories = () => ({
  type: constants.PREFERENCE_CATEGORIES_FETCHING,
  error: ''
})

const preferenceCategoryFetched = (preferenceCategory) => ({
  type: constants.PREFERENCE_CATEGORY_FETCHED,
  preferenceCategory
})

const preferenceCategoriesFetched = (preferenceCategories) => ({
  type: constants.PREFERENCE_CATEGORIES_FETCHED,
  preferenceCategories
})

const newPreferenceCategory = () => ({
  type: constants.PREFERENCE_CATEGORY_NEW,
  validationErrors: {},
  preferenceCategory: {},
  error: ''
})

const savingPreferenceCategory = (preferenceCategory) => ({
  type: constants.PREFERENCE_CATEGORY_SAVING,
  preferenceCategory
})

const preferenceCategorySaved = (preferenceCategory) => ({
  type: constants.PREFERENCE_CATEGORY_SAVED,
  preferenceCategory
})

const preferenceCategoryError = (error) => ({
  type: constants.PREFERENCE_CATEGORY_ERROR,
  error
})

const createPreferenceCategory = (payload) => {
  return (dispatch) => {
    dispatch(savingPreferenceCategory(payload))
    return http.post(dispatch, `${config.serverUrl}/preferenceCategories`, payload)
      .then(() => {
        dispatch(preferenceCategorySaved(payload))
        dispatch(navigateToManagePreferenceCategories())
      })
      .catch((err) => {
        dispatch(preferenceCategoryError(`Unable to create the new preference category: ${err.status} (${err.statusText})`))
      })
  }
}

const updatePreferenceCategory = (preferenceCategory) => {
  return (dispatch) => {
    dispatch(savingPreferenceCategory(preferenceCategory))
    return http.put(dispatch, `${config.serverUrl}/preferenceCategories/${preferenceCategory.id}`, preferenceCategory)
      .then((resp) => {
        dispatch(preferenceCategorySaved(resp))
        dispatch(navigateToManagePreferenceCategories())
      }).catch((err) => {
        dispatch(preferenceCategoryError(`Unable to update the preference category: ${err.status} (${err.statusText})`))
      })
  }
}

const fetchPreferenceCategories = () => {
  return (dispatch) => {
    return http.get(dispatch, `${config.serverUrl}/preferenceCategories`, null, {
      Accept: INTEREST_CATEGORIES_LIST_V1
    }).then((resp) => {
      dispatch(preferenceCategoriesFetched(resp))
    }).catch((err) => {
      dispatch(preferenceCategoryError(`Unable to fetch preference categories : ${config.serverUrl}/preferenceCategories - ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const fetchPreferenceCategory = (categoryId) => {
  return (dispatch) => {
    return http.get(dispatch, `${config.serverUrl}/preferenceCategories/${categoryId}`, null)
      .then((resp) => {
        dispatch(preferenceCategoryFetched(resp))
        return resp
      }).catch((err) => {
        dispatch(preferenceCategoryError(`Unable to retrieve the preference category with id ${categoryId}: ${err.status} (${err.statusText})`))
      })
  }
}

export const constants = {
  PREFERENCE_CATEGORIES_FETCHING: 'PREFERENCE_CATEGORIES_FETCHING',
  PREFERENCE_CATEGORIES_FETCHED: 'PREFERENCE_CATEGORIES_FETCHED',
  PREFERENCE_CATEGORY_FETCHING: 'PREFERENCE_CATEGORY_FETCHING',
  PREFERENCE_CATEGORY_FETCHED: 'PREFERENCE_CATEGORY_FETCHED',

  PREFERENCE_CATEGORY_NEW: 'PREFERENCE_CATEGORY_NEW',
  PREFERENCE_CATEGORY_SAVING: 'PREFERENCE_CATEGORY_SAVING',
  PREFERENCE_CATEGORY_SAVED: 'PREFERENCE_CATEGORY_SAVED',

  PREFERENCE_CATEGORY_ERROR: 'PREFERENCE_CATEGORY_ERROR'
}

export const preferenceActions = {
  newPreferenceCategory,
  createPreferenceCategory,
  updatePreferenceCategory,
  preferenceCategorySaved,
  savingPreferenceCategory,
  preferenceCategoryError,
  navigateToManagePreferenceCategories,
  navigateToEditPreferenceCategory,
  fetchPreferenceCategory,
  fetchingPreferenceCategory,
  fetchPreferenceCategories,
  fetchingPreferenceCategories,
  preferenceCategoriesFetched,
  preferenceCategoryFetched
}
