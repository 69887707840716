import React, { Component } from 'react'
import { Checkbox, Table } from 'react-bootstrap'
import classNames from 'classnames'
import Highlight from 'react-highlighter'
import _ from 'lodash'

import StatusLabel from '../../common/components/statusLabel'
import ContentTypelabel from '../../common/components/contentTypeLabel'
import NoMatchesRow from '../../common/components/noMatchesRow'
import { formatDateTime } from '../../common/utils'
import { contentStatuses, contentTypes } from '../../common/enums'

export default class SelectContentTable extends Component {
  validate(contentItem, previouslySelected) {
    const disabledStatuses = this.props.disabledStatuses || []
    const disabledTypes = this.props.disabledTypes || []

    if (!previouslySelected && disabledStatuses.indexOf(contentItem.state) > -1) {
      return `Cannot add ${contentStatuses[contentItem.state]} items to a ${this.props.parentContentType}`
    }

    if (!previouslySelected && disabledTypes.indexOf(contentItem.type) > -1) {
      return `Cannot add ${contentTypes[contentItem.type]}s to a ${this.props.parentContentType}`
    }

    if (this.props.disableWasPublished && contentItem.state !== 'PAUSED' && contentItem.wasPublished) {
      return previouslySelected ? (
        `Cannot remove previously published items from a ${this.props.parentContentType}`
      ) : (
        `Cannot add previously published items to a ${this.props.parentContentType}`
      )
    }

    if (this.props.disableRemovingPaused && previouslySelected && contentItem.state === 'PAUSED') {
      return 'Cannot remove Paused items'
    }

    // Items added to a Group/ThankYou can be added to a Series and vice-versa.
    // But, items added to a Series cannot be added to another Series and items added to a
    // Group/ThankYou cannot be added to another Group/ThankYou.
    if (this.props.parentContentType === 'series') {
      if (this.props.disableRemovingInGroup && !previouslySelected && !!contentItem.series) {
        return `Cannot add items that are in other ${this.props.parentContentType}`
      }
    } else if (this.props.disableRemovingInGroup && !previouslySelected && (!!contentItem.group || !!contentItem.thankYou)) {
      return 'Cannot add items that are in other groups'
    }

    return null
  }

  render() {
    const persistedIds = this.props.persistedIds || []

    return (
      <Table striped hover>
        <thead>
        <tr>
          <th />
          <th>Type</th>
          <th>Status</th>
          <th>Brand</th>
          <th>Title</th>
          <th>Visible from</th>
          <th>Visible to</th>
        </tr>
        </thead>
        <tbody>
        {this.props.items.map((contentItem, index) => {
          const maxSelectableVal = this.props.maxSelectable === undefined ? 0 : this.props.maxSelectable
          const selected = !!this.props.selectedItems.find(item => item.id === contentItem.id)
          const previouslySelected = persistedIds.find(item => item.id === contentItem.id)
          const error = this.validate(contentItem, previouslySelected)
          const disabled = !!error || (maxSelectableVal > 0 && this.props.selectedItems.length >= maxSelectableVal && !selected)
          const search = this.props.selectedFilters ? this.props.selectedFilters.search : ''

          const classes = classNames({
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
            'content-list--row__selected': selected,
            'content-list--row__disabled': disabled
          })

          return (
            <tr id={`select-row-${contentItem.id}`}
                title={error}
                className={classes} key={`content-${index}`}
                onClick={() => !disabled && this.props.onToggleSelected(contentItem)}
            >
              <td>
                <Checkbox checked={selected}
                          onClick={(e) => e.stopPropagation()}
                          onChange={() => this.props.onToggleSelected(contentItem)}
                          disabled={disabled}
                />
              </td>
              <td><ContentTypelabel type={contentItem.type} /></td>
              <td><StatusLabel status={contentItem.state} /></td>
              <td><Highlight matchClass='padding-zero' matchElement='mark' search={search}>{_.get(contentItem, 'brand.name')}</Highlight></td>
              <td><Highlight matchClass='padding-zero' matchElement='mark' search={search}>{_.get(contentItem, 'details.title')}</Highlight></td>
              <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayFromDateTime) : null}</td>
              <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayToDateTime) : null}</td>
            </tr>
          )
        })}
        {this.props.status !== 'LOADING' && this.props.items.length === 0 ? (
          <NoMatchesRow colSpan='7' />
        ) : null}
        </tbody>
      </Table>

    )
  }
}
