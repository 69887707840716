import React, { Component } from 'react'
import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import ImageSelector from '../../common/media/ImageSelector'
import Layout from './preferenceCategoryLayout'
import AppError from '../../common/components/appError'
import { Col, Panel, Row } from 'react-bootstrap'
import PreferenceCategoryBreadcrumbs from './preferenceCategoryBreadcrumbs'
import Spinner from '../../common/components/spinner'
import _ from 'lodash'

export default class PreferenceCategoryForm extends Component {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.preferenceCategory || {})
    props.load()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
    if (!_.isEqual(prevProps.preferenceCategory, this.props.preferenceCategory)) {
      this.model = _.cloneDeep(this.props.preferenceCategory || {})
      this.forceUpdate()
    }
  }

  updateField(path, value) {
    return (e) => {
      _.set(this.model, path, e ? e.target.value : value)
      this.setState({ dirty: true })
    }
  }

  _onChangeCheckboxValue(path, checkbox) {
    this.model[path] = checkbox.target.checked
    this.setState({ dirty: true })
  }

  render() {
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }

    const validationErrors = this.props.validationErrors || {}
    const error = this.props.error ? <AppError errorMessage={this.props.error} /> : null

    return (
      <Layout>
        <Col sm={4} lg={3}>
          <PreferenceCategoryBreadcrumbs match={this.props.match} />
        </Col>
        <Col sm={8} lg={6}>
          {error}
          <Panel header='Create Preference Category'>
            <Form nextButtonText='Save'
                  nextHandler={(e) => {
                    this.props.nextHandler(e, this.model)
                  }}
                  backButtonText='Back'
                  backHandler={(e) => {
                    this.props.backHandler(e)
                  }}
            >
              <FormField label='Name'
                         id='name'
                         mandatory
                         value={this.model.name}
                         error={validationErrors.name}
                         onChange={this.updateField('name')}
              />
              <ImageSelector label='Logo Image'
                             type='logo'
                             mediaTarget='BRAND_LOGO'
                             mandatory
                             asset
                             defaultImageProperty='imageUrl'
                             parentMediaProperty='imageUrl'
                             width={320} height={320}
                             model={this.model}
                             updateParentField={this.updateField.bind(this)}
               />
               <FormField label='Active'
                          id='active'
                          type='checkbox'
                          value={this.model.active}
                          onChange={(e) => {
                            this._onChangeCheckboxValue('active', e)
                          }}
               />
            </Form>
          </Panel>
        </Col>
      </Layout>
    )
  }
}
