import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { commonActions } from '../../common/commonActions'
import { contentActions } from '../../content/contentActions'
import { tourActions } from '../../tours/tourActions'
import EventDateForm from '../components/eventDateForm'

import { eventDateActions } from '../eventDateActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const { tourId, id, auditId } = ownProps.match.params
      if (id) {
        dispatch(eventDateActions.fetchEventDate(tourId, id))
      } else {
        dispatch(eventDateActions.newEventDate())
      }
      if (auditId) {
        dispatch(auditActions.fetchAudit(auditId))
      }
      dispatch(commonActions.fetchPreferenceCategories())
      dispatch(eventDateActions.fetchShortVenues())
    },
    unload: () => {
      dispatch(eventDateActions.eventDateFetched(null))
    },
    fetchAudits: () => {
      const { id } = ownProps.match.params
      dispatch(auditActions.fetchAudits(id))
    },
    selectPage: (page) => {
      const { id } = ownProps.match.params
      dispatch(auditActions.clearAudits())
      dispatch(auditActions.selectPage(page))
      dispatch(auditActions.fetchAudits(id))
    },
    loadTour: () => {
      dispatch(tourActions.fetchTour(ownProps.match.params.tourId))
    },
    navigateToSection: (contentItem = {}, section) => {
      dispatch(contentActions.navigateToContentItem(contentItem, section))
    },
    navigateToDashboard: () => {
      dispatch(push(`/tours/${ownProps.match.params.tourId}/eventDates`))
    },
    onViewAudit: (audit) => {
      const id = ownProps.match.params.id
      dispatch(auditActions.auditFetched(audit))
      dispatch(push(`/tours/${ownProps.match.params.tourId}/eventDates/${id}/audits/${audit.id}`))
    },
    submit: (eventDate) => {
      const { tourId } = ownProps.match.params
      dispatch(eventDateActions.submitEventDate(tourId, eventDate))
    },
    reject: (eventDate) => {
      const { tourId } = ownProps.match.params
      dispatch(eventDateActions.rejectEventDate(tourId, eventDate))
    },
    publish: (eventDate) => {
      const { tourId } = ownProps.match.params
      dispatch(eventDateActions.publishEventDate(tourId, eventDate))
    },
    pause: (eventDate) => {
      const { tourId } = ownProps.match.params
      dispatch(eventDateActions.pauseEventDate(tourId, eventDate))
    },
    resume: (eventDate) => {
      const { tourId } = ownProps.match.params
      dispatch(eventDateActions.resumeEventDate(tourId, eventDate))
    },
    edit: (eventDate) => {
      const { tourId } = ownProps.match.params
      dispatch(eventDateActions.editEventDate(tourId, eventDate))
    },
    save: (eventDate) => {
      const { tourId } = ownProps.match.params
      return dispatch(eventDateActions.saveEventDate(tourId, eventDate))
    },
    updateCancelled: (eventDate, cancel) => {
      const { tourId } = ownProps.match.params
      dispatch(eventDateActions.updateCancelled(tourId, eventDate, cancel))
    },
    updateSoldOut: (eventDate, soldOut) => {
      const { tourId } = ownProps.match.params
      dispatch(eventDateActions.updateSoldOut(tourId, eventDate, soldOut))
    },
    copy: (eventDate) => {
      dispatch(contentActions.navigateToCopyContent(eventDate))
    },
    disable: (eventDate) => {
      const { tourId } = ownProps.match.params
      return dispatch(eventDateActions.disableEventDate(tourId, eventDate))
    },
    enable: (eventDate) => {
      const { tourId } = ownProps.match.params
      return dispatch(eventDateActions.enableEventDate(tourId, eventDate))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    tour: state.tours.tour,
    eventDate: state.eventDates.eventDate,
    venues: state.eventDates.shortVenues,
    relatedContent: state.eventDates.relatedContent,
    status: state.eventDates.status,
    error: state.eventDates.error,
    audits: state.audits,
    flash: state.common.flash,
    preferenceCategories: state.common.preferenceCategories
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDateForm)
