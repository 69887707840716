import { connect } from 'react-redux'

import { sectionHeaderActions } from '../sectionHeaderActions'
import { dashboardActions } from '../../dashboard/dashboardActions'
import ManageSectionHeaders from '../components/manageSectionHeaders'

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchSectionHeadersHandler: () => {
      dispatch(sectionHeaderActions.fetchSectionHeaders())
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(dashboardActions.navigateToDashboard())
    },
    navigateToEdit: (e, sectionHeader) => {
      e.preventDefault()
      dispatch(sectionHeaderActions.navigateToEditSectionHeader(sectionHeader))
    },
    setClickedSectionHeader: (sectionHeader) => {
      dispatch(sectionHeaderActions.setClickedSectionHeader(sectionHeader))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    sectionHeaders: state.sectionHeaders.sectionHeaders || {},
    status: state.sectionHeaders.sectionHeadersStatus,
    error: state.sectionHeaders.error,
    roles: state.common.roles,
    profile: state.common.profile
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSectionHeaders)
