import React from 'react'
import { Glyphicon } from 'react-bootstrap'

const statusMap = {
  VALID: 'ok-circle',
  INVALID: 'remove-circle',
  NEW: 'record'
}

export default function ValidationIndicator(props) {
  var glyph = statusMap[props.status]

  return glyph ? <Glyphicon glyph={glyph} /> : null
}
