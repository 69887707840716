import { connect } from 'react-redux'

import { merchantActions } from '../merchantActions'
import Listing from '../components/merchantListing'

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(merchantActions.fetchMerchants())
    },
    newMerchant: () => {
      dispatch(merchantActions.navigateToNewMerchant())
    },
    editMerchantHandler: merchantId => {
      dispatch(merchantActions.navigateToMerchantBusinessDetails(merchantId))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    merchants: state.merchants.merchants || [],
    status: state.merchants.merchantsStatus,
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Listing)
