import React, { Component } from 'react'
import Select from 'react-select'
import _ from 'lodash'

import Label from '../form/label'
import { element } from 'prop-types'

export default class PreferenceCategories extends Component {
  componentDidUpdate() {
    document.getElementsByName(this.props.id).forEach(element => element.setAttribute('id', this.props.id))
  }

  render() {
    const {
      id,
      options,
      value,
      updateField,
      disabled
    } = this.props

    return (
        <div className='form-group'>
          <Label>
            Preference categories
          </Label>
          <Select name={id} isMulti
            options={options}
            value={options && value ? options.filter(option => value.includes(option.name)) : []}
            getOptionValue={(option) => option.name}
            getOptionLabel={(option) => option.name}
            onChange={(values) => updateField('details.preferenceCategories', _.map(values, 'name'))()}
            className="basic-multi-select"
            classNamePrefix="select"
            isDisabled={disabled}
          />
        </div>
    )
  }
}
