import { connect } from 'react-redux'
import { faqActions } from '../faqActions'
import FaqSectionDetails from '../components/faqSectionDetails'

const mapDispatchToProps = (dispatch, ownProps) => {
  const faqSectionId = ownProps.match.params.faqSectionId
  const isEdit = !!faqSectionId
  return {
    load: () => {
      if (isEdit) {
        dispatch(faqActions.fetchFaqSection(faqSectionId))
      }
    },
    nextHandler: (e, faqSectionDetails) => {
      e.preventDefault()
      if (isEdit) {
        const updatedSectionDetails = {
          title: faqSectionDetails.title,
          faqSectionId: faqSectionDetails.faqSectionId,
          platforms: faqSectionDetails.platforms,
          category: faqSectionDetails.category,
          enabled: faqSectionDetails.enabled,
          creationDate: faqSectionDetails.creationDate,
          faqs: faqSectionDetails.faqs,
          rank: faqSectionDetails.rank
        }
        dispatch(faqActions.updateFaqSection(faqSectionId, updatedSectionDetails))
      } else {
        dispatch(faqActions.createFaqSection(faqSectionDetails))
      }
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(faqActions.navigateToFaqSectionListing())
    }
  }
}

const mapStateToProps = (state) => {
  return {
    error: state.faqs.error,
    faqSection: state.faqs.faqSection || {},
    validationErrors: state.faqs.validationErrors || {},
    flash: state.common.flash,
    status: state.faqs.faqSectionStatus
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqSectionDetails)
