import { push } from 'connected-react-router'
import http, { BRAND_LOCATION_LIST_V1, config, CONTENT_LIST_V1, OFFER_DETAILS_V1 } from '../services/http'

import { stockActions } from '../stock/stockActions'

const offerError = (error) => ({
  type: constants.OFFER_ERROR,
  error
})

const offerAdded = (offer) => ({
  type: constants.OFFER_SAVED,
  offer
})

const navigateToOfferAlternateTitle = (props) => {
  const offerId = props.model.id
  const alternateTitleOfferId = props.model.alternateTitleOfferId || 0
  return push(`/offers/${offerId}/alternateOfferTitleForm/${alternateTitleOfferId}`)
}

const navigateToNewAlternateTitle = (offerId, index) => {
  return push(`/offers/${offerId}/alternateOfferTitleForm/${index}`)
}

const createOffer = (offer) => {
  return (dispatch) => {
    return http.post(dispatch, `${config.serverUrl}/offers/`, offer, {
      Accept: OFFER_DETAILS_V1
    }).then((resp) => {
      dispatch(offerAdded(resp))
      return resp
    }).catch((err) => {
      dispatch(offerError(`Unable to create the new offer: ${err.status} (${err.statusText})`))
    })
  }
}

const offerSaving = (offer) => ({
  type: constants.OFFER_SAVING,
  offer
})
const offerSaved = (offer) => ({
  type: constants.OFFER_SAVED,
  offer
})

const updateOffer = (offer) => {
  return (dispatch) => {
    return http.put(dispatch, `${config.serverUrl}/offers/${offer.id}`, offer, {
      Accept: OFFER_DETAILS_V1
    }).then((resp) => {
      dispatch(offerSaved(resp))
      return resp
    }).catch((err) => {
      dispatch(offerError(`Unable to update the offer: ${err.status} (${err.statusText})`))
    })
  }
}

const offerFetching = (offerId) => ({
  type: constants.OFFER_FETCHING,
  offerId
})

const offerFetched = (offer) => ({
  type: constants.OFFER_FETCHED,
  offer
})

const fetchOffer = (offerId) => {
  return (dispatch) => {
    dispatch(offerFetching(offerId))
    return http.get(dispatch, `${config.serverUrl}/offers/${offerId}`, null, {
      Accept: OFFER_DETAILS_V1
    }).then((resp) => {
      dispatch(offerFetched(resp))
      return resp
    }).catch((err) => {
      dispatch(offerError(`Unable to retrieve the offer with id ${offerId}: ${err.status} (${err.statusText})`))
    })
  }
}

const locationsFetching = () => ({
  type: constants.LOCATIONS_FETCHING
})

const locationsFetched = (locations) => ({
  type: constants.LOCATIONS_FETCHED,
  locations
})

const fetchOfferLocations = (brandId, offerId) => {
  return (dispatch) => {
    dispatch(locationsFetching())
    return http.get(dispatch, `${config.locationServerUrl}/brands/${brandId}/offers/${offerId}/locations`, null, {
      Accept: BRAND_LOCATION_LIST_V1
    }).then((resp) => {
      dispatch(locationsFetched(resp))
    }).catch((err) => {
      dispatch(offerError(`Unable to retrieve the locations: ${err.status} (${err.statusText})`))
    })
  }
}

const relatedContentFetching = () => ({
  type: constants.OFFER_RELATED_CONTENT_FETCHING
})
const relatedContentFetched = (relatedContent) => ({
  type: constants.OFFER_RELATED_CONTENT_FETCHED,
  relatedContent
})
const fetchRelatedContent = (offerId) => {
  return (dispatch) => {
    dispatch(relatedContentFetching())
    return http.get(dispatch, `${config.serverUrl}/offers/${offerId}/relatedContent`, null, {
      Accept: CONTENT_LIST_V1
    }).then((resp) => {
      dispatch(relatedContentFetched(resp))
    }).catch((err) => {
      dispatch(offerError(`Unable to fetch the offer related content: ${err.status} (${err.statusText})`))
    })
  }
}

const offerSubmitted = (offer) => ({
  type: constants.OFFER_SUBMITTED,
  offer
})
const offerRejected = (offer) => ({
  type: constants.OFFER_REJECTED,
  offer
})
const offerPublished = (offer) => ({
  type: constants.OFFER_PUBLISHED,
  offer
})
const offerPaused = (offer) => ({
  type: constants.OFFER_PAUSED,
  offer
})
const offerResumed = (offer) => ({
  type: constants.OFFER_RESUMED,
  offer
})
const offerEdited = (offer) => ({
  type: constants.OFFER_EDITED,
  offer
})
const offerDisabled = (offer) => ({
  type: constants.OFFER_DISABLED,
  offer
})
const offerEnabled = (offer) => ({
  type: constants.OFFER_ENABLED,
  offer
})

const updateOfferStatus = (offer, status, successAction) => {
  return (dispatch) => {
    dispatch(offerSaving(offer))
    return http.post(dispatch, `${config.serverUrl}/offers/${offer.id}/${status}`, null, {
      Accept: OFFER_DETAILS_V1
    }).then((resp) => {
      dispatch(successAction(resp))
    }).catch((err) => {
      dispatch(offerError(`Unable to ${status} offer: ${err.status} (${err.statusText})`))
    })
  }
}

const submitOffer = (offer) => updateOfferStatus(offer, 'submit', offerSubmitted)
const rejectOffer = (offer) => updateOfferStatus(offer, 'reject', offerRejected)
const publishOffer = (offer) => updateOfferStatus(offer, 'publish', offerPublished)
const pauseOffer = (offer) => updateOfferStatus(offer, 'pause', offerPaused)
const resumeOffer = (offer) => updateOfferStatus(offer, 'resume', offerResumed)
const editOffer = (offer) => updateOfferStatus(offer, 'draft', offerEdited)
const disableOffer = (offer) => updateOfferStatus(offer, 'disable', offerDisabled)
const enableOffer = (offer) => updateOfferStatus(offer, 'enable', offerEnabled)

const newOffer = () => ({
  type: constants.OFFER_NEW,
  offer: {
    type: 'OFFER',
    details: {
      platformFilters: [],
      channels: ['PRIORITY'],
      audience: {}
    }
  }
})

const saveOffer = (offer) => {
  if (!offer.id) {
    var offerToBeSaved = offer
    return (dispatch) => {
      return createOffer(offer)(dispatch).then((resp) => {
        if (typeof (resp) === 'undefined') {
          return resp
        }
        return stockActions.updateVolumeRequest(dispatch, resp.id, offerToBeSaved.stock.limit, offerToBeSaved.stock.threshold, 0).then(() => {
          return resp
        })
      })
    }
  }

  return updateOffer(offer)
}

const addLocations = (brandId, offerId, selectedLocations) => {
  return (dispatch) => {
    const payload = selectedLocations.map(location => location.id)
    return http.put(dispatch, `${config.locationServerUrl}/brands/${brandId}/offers/${offerId}/locations`, payload, {
      Accept: BRAND_LOCATION_LIST_V1
    }).catch((err) => {
      dispatch(offerError(`Unable add locations for an offer: ${err.status} (${err.statusText})`))
    })
  }
}

const navigateToNewOurPicksTrigger = (offer) => {
  const index = offer.schedule && offer.schedule.triggers ? offer.schedule.triggers.length : 0
  return push(`/offers/${offer.id}/triggers/${index}`)
}

const navigateToEditOurPicksTrigger = (offer, index) => {
  return push(`/offers/${offer.id}/triggers/${index}`)
}

const navigateToSchedule = (offerId) => {
  return push(`/offers/${offerId}/schedule`)
}

export const constants = {
  OFFER_ERROR: 'OFFER_ERROR',
  OFFER_NEW: 'OFFER_NEW',
  OFFER_SAVING: 'OFFER_SAVING',
  OFFER_SAVED: 'OFFER_SAVED',
  OFFER_FETCHING: 'OFFER_FETCHING',
  OFFER_FETCHED: 'OFFER_FETCHED',
  OFFER_SUBMITTED: 'OFFER_SUBMITTED',
  OFFER_REJECTED: 'OFFER_REJECTED',
  OFFER_PUBLISHED: 'OFFER_PUBLISHED',
  OFFER_PAUSED: 'OFFER_PAUSED',
  OFFER_RESUMED: 'OFFER_RESUMED',
  OFFER_EDITED: 'OFFER_EDITED',
  OFFER_DISABLED: 'OFFER_DISABLED',
  OFFER_ENABLED: 'OFFER_ENABLED',
  LOCATIONS_FETCHING: 'LOCATIONS_FETCHING',
  LOCATIONS_FETCHED: 'LOCATIONS_FETCHED',
  OFFER_RELATED_CONTENT_FETCHING: 'OFFER_RELATED_CONTENT_FETCHING',
  OFFER_RELATED_CONTENT_FETCHED: 'OFFER_RELATED_CONTENT_FETCHED'
}

export const offerActions = {
  newOffer,
  createOffer,
  fetchOffer,
  saveOffer,
  offerSaving,
  offerError,
  submitOffer,
  rejectOffer,
  publishOffer,
  pauseOffer,
  resumeOffer,
  editOffer,
  addLocations,
  fetchOfferLocations,
  fetchRelatedContent,
  updateOfferStatus,
  navigateToNewOurPicksTrigger,
  navigateToEditOurPicksTrigger,
  navigateToOfferAlternateTitle,
  navigateToNewAlternateTitle,
  navigateToSchedule,
  disableOffer,
  enableOffer
}
