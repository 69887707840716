import React, { Component } from 'react'

export default class BrandTile extends Component {
  render() {
    let { brand, brandDetailsHandler, allowThumbnailPointer } = this.props

    allowThumbnailPointer = allowThumbnailPointer === undefined ? true : allowThumbnailPointer

    return (
      <div className='thumbnail'>
        <a className={allowThumbnailPointer ? 'cursor-pointer' : ''}
           onClick={(e) => {
             brandDetailsHandler(e, brand.id)
           }}
        >
          <img src={brand.logoImageUrl} alt={brand.name} width='200' height='200' />
        </a>
        <h4 className='text-center'>{brand.name}</h4>
      </div>
    )
  }
}
