import { connect } from 'react-redux'

import { welcomePosterActions } from '../welcomePosterActions'
import { dashboardActions } from '../../dashboard/dashboardActions'
import ManageWelcomePosters from '../components/manageWelcomePosters'

export const mapDispatchToProps = (dispatch) => {
  return {
    fetchWelcomePosterHandler: () => {
      dispatch(welcomePosterActions.fetchWelcomePosters())
    },
    backHandler: (e) => {
      e.preventDefault()
      dispatch(dashboardActions.navigateToDashboard())
    },
    navigateToEdit: (e, welcomePoster) => {
      e.preventDefault()
      dispatch(welcomePosterActions.navigateToEditWelcomePoster(welcomePoster))
    },
    setClickedWelcomePoster: (welcomePoster) => {
      dispatch(welcomePosterActions.setClickedWelcomePoster(welcomePoster))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    welcomePosters: state.welcomePosters.welcomePosters || {},
    status: state.welcomePosters.welcomePosterStatus,
    error: state.welcomePosters.error,
    roles: state.common.roles,
    profile: state.common.profile
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageWelcomePosters)
