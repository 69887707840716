import { connect } from 'react-redux'
import { groupActions } from '../groupActions'
import OurPicksLayout from '../../common/components/TriggersLayout'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const id = ownProps.match.params.id
      dispatch(groupActions.fetchGroup(id))
    },
    save: (group) => {
      dispatch(groupActions.saveGroup(group))
      dispatch(groupActions.navigateToSchedule(ownProps.match.params.id))
    },
    backHandler: () => {
      dispatch(groupActions.navigateToSchedule(ownProps.match.params.id))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contentId: ownProps.match.params.id,
    content: state.groups.group,
    contentType: 'groups',
    index: ownProps.match.params.index,
    status: state.groups.status
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurPicksLayout)
