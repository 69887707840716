import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import AlternateTitleBreadcrumbs from './AlternateTitleBreadcrumbs'
import AlternateTitlesForm from './AlternateTitlesForm'
import Spinner from './spinner'

export default class AlternateTitlesLayout extends Component {
  constructor(props) {
    super(props)
    props.load()
  }

  render() {
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }

    const backUrl = `/${this.props.contentType}/${this.props.contentId}/schedule`
    const detailsUrl = `/${this.props.contentType}/${this.props.contentId}/alternateTitles/${this.props.index}`

    return (
      <div>
        <div className='page-header'>
          <h1>Alternate Titles</h1>
        </div>
        <Row>
          <Col sm={4} lg={3}>
            <AlternateTitleBreadcrumbs backUrl={backUrl}
                                       detailsUrl={detailsUrl}
            />
          </Col>
          <Col sm={8} lg={6}>
            <AlternateTitlesForm {...this.props} />
          </Col>
        </Row>
      </div>
    )
  }
}
