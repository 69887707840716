import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import SeriesForm from '../components/SeriesForm'
import { seriesActions } from '../seriesActions'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      load: () => {
        const { id, auditId } = ownProps.match.params
          if (id) {
            dispatch(seriesActions.fetchSeries(id))
            dispatch(seriesActions.fetchContents(id))
          } else {
            dispatch(seriesActions.newSeries())
          }
          if (auditId) {
            dispatch(auditActions.fetchAudit(auditId))
          }
        },
      save: (series) => {
        return dispatch(seriesActions.saveSeries(series))
      },
      updateContents: (contents) => {
        return dispatch(seriesActions.updateContents(ownProps.match.params.id, contents))
      },
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/series/${id}/audits/${audit.id}`))
      },
      submit: (series) => {
        dispatch(seriesActions.submitSeries(series))
      },
      reject: (series) => {
        dispatch(seriesActions.rejectSeries(series))
      },
      publish: (series) => {
        dispatch(seriesActions.publishSeries(series))
      },
      pause: (series) => {
        dispatch(seriesActions.pauseSeries(series))
      },
      resume: (series) => {
        dispatch(seriesActions.resumeSeries(series))
      },
      edit: (series) => {
        dispatch(seriesActions.editSeries(series))
      }
    }
  )
}

const mapStateToProps = (state) => {
  return {
    series: state.series.series,
    status: state.series.status,
    error: state.series.error,
    contentList: state.series.contentList,
    roles: state.common.roles,
    audits: state.audits
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SeriesForm)
