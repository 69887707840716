import React, { Component } from 'react'
import { Button, ButtonToolbar, Glyphicon, Table } from 'react-bootstrap'
import _ from 'lodash'

import AppError from '../../common/components/appError'
import { SortableBody, SortableRow } from '../../common/components/sortableTable'
import Spinner from '../../common/components/spinner'

export default class FaqSectionListing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dirty: false,
      ids: props.faqSections.map(faqSection => ({
        id: faqSection.id
      }))
    }

    props.load()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.faqSections, this.props.faqSections)) {
      this.setState({
        dirty: false,
        ids: this.props.faqSections.map(faqSection => ({
          id: faqSection.id
        }))
      })
    }
  }

  onSort(ids) {
    this.setState({
      dirty: true,
      ids: ids
    })
  }

  cancel() {
    this.setState({
      dirty: false,
      ids: this.props.faqSections.map(faqSection => ({
        id: faqSection.id
      }))
    })
  }

  onSave(faqSections) {
    const ids = this.state.ids.map(idType => idType.id)
    this.props.saveFaqSectionRanks(ids, faqSections)
    this.setState({ dirty: false })
  }

  displayTitle(title) {
    return title.length > 50 ? (title.slice(0, 50) + '...') : title
  }

  render() {
    const faqSectionList = this.props.faqSections || []
    const status = this.props.status || 'LOADING'
    let error = <div />

    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }

    if (status === 'LOADING') {
      return <Spinner />
    }

    // sort by order of ids
    const faqSections = []
    const faqSectionIds = this.state.ids
    faqSectionIds.forEach(idType => {
      const faqSection = faqSectionList.find(item => idType.id === item.id)
      if (faqSection) {
        faqSections.push(faqSection)
      }
    })

    let tableRows = <div id='no-data'>No FAQ Section to display</div>
    if (faqSections && faqSections.length > 0) {
      const tableRowsData = faqSections.map((section, index) => {
        return (
          <SortableRow className='cursor-pointer'
                       key={`faqSection-${index}`}
                       onClick={() => this.props.onEdit(section.id)}
                       ids={this.state.ids}
                       item={section}
                       onSort={this.onSort.bind(this)}
          >
            <td>
              <Glyphicon glyph='menu-hamburger' />
            </td>
            <td>{this.displayTitle(section.title)}</td>
            <td>
              {section.platforms.map((platform, i) => <span key={i}>
                {!!i && ', '}
                <span>{platform}</span>
              </span>)}
            </td>
            <td>{section.category}</td>
            <td><input type='checkbox' checked={section
              .enabled} disabled
                       readOnly /></td>
          </SortableRow>
        )
      })

      tableRows = <Table striped hover>
        <thead>
        <tr>
          <th />
          <th>Title</th>
          <th>Platforms</th>
          <th>Category</th>
          <th>Enabled</th>
        </tr>
        </thead>
        <SortableBody>
          {tableRowsData}
        </SortableBody>
      </Table>
    }
    return (
      <div>
        {error}
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>FAQ Section</h1>
          </div>
          <div className='page-header--tools'>
            <ButtonToolbar>
              {this.state.dirty ? (
                <Button id='cancel' onClick={() => this.cancel()}>Cancel</Button>
              ) : null}
              <Button id='save'
                      bsStyle='primary'
                      onClick={() => this.onSave()}
                      disabled={!this.state.dirty}
              >
                Save
              </Button>
              <Button id='newFaqSection' bsStyle='primary' onClick={() => this.props.newFaqSection()}>New FAQ Section</Button>
            </ButtonToolbar>
          </div>
        </div>
        <div className='faq-section-list'>
          {tableRows}
        </div>
      </div>
    )
  }
}
