import React, { Component } from 'react'
import classNames from 'classnames'

import Label from './label'
import TextBox from './textBox'
import TextArea from './textArea'
import FileInput from './fileInput'
import ImageInput from './ImageInput'
import Dropdown from './dropdown'
import DateTime from './dateTime'
import CheckBox from './checkBox'
import CheckBoxList from './checkBoxList'
import CheckBoxRadioList from './checkBoxRadioList'
import CategoriesList from './categoriesList'
import Hyperlink from './hyperlink'
import RadioList from './radioList'
import SwitchedMultiCheckBox from './switchedMultiCheckBox'
import TypeAheadSelect from './typeAheadSelect'
import RemainingCount from './remainingCount'
import AddMultiple from './addMultiple'

export default class FormField extends Component {
  render() {
    let { label, ...inputProps } = this.props

    if (this.props.width && this.props.height) {
      label = `${label} (${this.props.width} x ${this.props.height})`
    }
    if (this.props.mandatory) {
      label = `${label} *`
    }

    const type = this.props.type || 'text'
    const inputField = this.inputTypes[type](inputProps)

    const validationError = this.props.error ? (
      <Label className={classNames('input-error text-danger')}>
        {this.props.error}
      </Label>
    ) : null

    const helpText = this.props.help ? (
      <span className='help-block'>{this.props.help}</span>
    ) : null
    const uploadingText = this.props.uploading ? (
      <span className='help-block'>Uploading...</span>
    ) : null

    return (
      <div className={`form-group form-group-${type}`}
           hidden={this.props.hidden || false}
      >
        <Label htmlFor={this.props.id}
               className={classNames(this.props.labelClass || '')}
        >
          {this.props.maxLength && this.props.maxItems === undefined ? (
            <RemainingCount value={inputProps.value} maxLength={this.props.maxLength} />
          ) : null}
          {label}
        </Label>
        {inputField}
        {uploadingText || helpText}
        {this.props.type === 'checkbox' && this.props.error ? (<br />) : null}
        {validationError}
      </div>
    )
  }
}

FormField.prototype.inputTypes = {
  text: (props) => <TextBox type='text' {...props} />,
  file: (props) => <FileInput {...props} />,
  image: (props) => <ImageInput {...props} />,
  password: (props) => <TextBox type='password' {...props} />,
  checkbox: (props) => <CheckBox {...props} />,
  textArea: (props) => <TextArea {...props} />,
  dropdown: (props) => <Dropdown {...props} />,
  datetime: (props) => <DateTime {...props} />,
  date: (props) => <TextBox type='date' {...props} />,
  time: (props) => <TextBox type='time' {...props} />,
  number: (props) => <TextBox type='number' {...props} />,
  checkboxlist: (props) => <CheckBoxList {...props} />,
  checkboxradiolist: (props) => <CheckBoxRadioList {...props} />,
  categorieslist: (props) => <CategoriesList {...props} />,
  radiolist: (props) => <RadioList {...props} />,
  switchedmulticheckbox: (props) => <SwitchedMultiCheckBox {...props} />,
  typeAheadSelect: (props) => <TypeAheadSelect {...props} />,
  link: (props) => <Hyperlink {...props} />,
  addMultiple: (props) => <AddMultiple {...props} />
}
