import { constants } from './preferenceActions'

const initialState = {}
export default function preference(state = initialState, action = {}) {
  switch (action.type) {
    case constants.PREFERENCE_CATEGORIES_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING',
        validationErrors: {},
        error: ''
      })
    case constants.PREFERENCE_CATEGORIES_FETCHED:
      return Object.assign({}, state, {
        preferenceCategories: action.preferenceCategories,
        status: 'SUCCESS'
      })
    case constants.PREFERENCE_CATEGORY_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.PREFERENCE_CATEGORY_FETCHED:
      return Object.assign({}, state, {
        preferenceCategory: action.preferenceCategory,
        status: 'SUCCESS',
        validationErrors: {}
      })
    case constants.PREFERENCE_CATEGORY_NEW:
      return Object.assign({}, state, {
        preferenceCategory: action.preferenceCategory,
        error: action.error,
        validationErrors: action.validationErrors,
        status: 'SUCCESS'
      })
    case constants.PREFERENCE_CATEGORY_SAVING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.PREFERENCE_CATEGORY_SAVED:
      return Object.assign({}, state, {
        preferenceCategory: action.preferenceCategory,
        validationErrors: {},
        status: 'SUCCESS'
      })
    case constants.PREFERENCE_CATEGORY_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        status: 'FAILED'
      })
    default:
      return state
  }
}
