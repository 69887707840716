import { constants } from './featureFlagActions'

export default function featureFlags(state = {}, action = {}) {
  switch (action.type) {
    case constants.FEATURE_FLAGS_FETCHING:
      return Object.assign({}, state, {
        featureFlag: {},
        featureFlags: [],
        featureFlagStatus: 'LOADING'
      })
    case constants.FEATURE_FLAGS_FETCHED:
      return Object.assign({}, state, {
        featureFlags: action.featureFlags,
        featureFlagStatus: 'SUCCESS'
      })
    case constants.FEATURE_FLAG_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        featureFlagStatus: 'FAILED'
      })
    case constants.CLICKED_FEATURE_FLAG:
      return Object.assign({}, state, {
        clickedFeatureFlag: action.featureFlag
      })
    case constants.FEATURE_FLAG_UPDATED:
      return Object.assign({}, state, {
        featureFlag: action.featureFlag,
        validationErrors: {},
        featureFlagStatus: 'SUCCESS'
      })
    case constants.FEATURE_FLAG_UPDATE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        featureFlagStatus: 'FAILED'
      })
    default:
      return state
  }
}
