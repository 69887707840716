import React, { Component } from 'react'

export default class ContentIdentifier extends Component {
  render() {
    const contentId = this.props.content.id
    const contentShortId = this.props.content.shortId
    const contentType = this.props.content.type

    return (
      <>
        {contentId ? (
          <div className='form-group' id={contentType + '_id'}>
            <label className='control-label'>ID:</label> {contentId}
          </div>
        ) : null}

        {contentShortId ? (
          <div className='form-group' id={contentType + '_shortId'}>
            <label className='control-label'>Short ID:</label> {contentShortId}
          </div>
        ) : null}
      </>
    )
  }
}
