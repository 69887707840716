import { constants } from './brandActions'

const intitialState = {
  brands: [],
  brand: null
}

export default function brands(state = intitialState, action = {}) {
  switch (action.type) {
    case constants.BRANDS_FETCHED:
      return Object.assign({}, state, {
        brands: action.brands,
        status: 'SUCCESS'
      })
    case constants.BRAND_FETCHED:
    case constants.BRAND_SAVED: {
      return Object.assign({}, state, {
        brand: action.brand,
        status: 'SUCCESS'
      })
    }
    case constants.BRAND_ERROR: {
      return Object.assign({}, state, {
        status: 'FAILED'
      })
    }
    default:
      return state
  }
}
