import React from 'react'

const NotFound = () => (
  <div>
    <div className='page-header'>
      <div className='page-header--heading'>
        <div className='page-header--top'>
          <h1>Page not found 😢</h1>
        </div>
      </div>
    </div>
    <p>Double check the URL or try going back to the previous page.</p>
  </div>
)

export default NotFound
