import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'

import ValidationIndicator from '../../common/components/validationIndicator'

export default class Breadcrumbs extends Component {
  getValidationStatuses() {
    return {
      details: this.getDetailsStatus(),
      locations: this.getLocationsStatus(),
      beacon: this.getBeaconStatus()
    }
  }

  getDetailsStatus() {
    const { brand, validationErrors } = this.props

    if (validationErrors && (validationErrors.name || validationErrors.logoImageUrl)) {
      return 'INVALID'
    } else if (brand && brand.name && brand.logoImageUrl) {
      return 'VALID'
    } else {
      return 'NEW'
    }
  }

  getLocationsStatus() {
    const { locations } = this.props

    return locations && locations.length === 0 ? 'NEW' : 'VALID'
  }

  getBeaconStatus() {
    const { beacon, validationErrors } = this.props

    if (validationErrors && (validationErrors.uuid || validationErrors.major)) {
      return 'INVALID'
    } else if (beacon && beacon.uuid && beacon.major) {
      return 'VALID'
    } else {
      return 'NEW'
    }
  }

  render() {
    const statuses = this.getValidationStatuses()

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='brand-breadcrumbs-back'
                 onClick={() => this.props.onNavigate('back')}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Merchant
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='brand-breadcrumbs-details'
                 active={this.props.activeTab === 'details'}
                 onClick={() => this.props.onNavigate('details')}
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Details
        </NavItem>
        <NavItem id='brand-breadcrumbs-locations'
                 active={this.props.activeTab === 'locations'}
                 onClick={() => this.props.onNavigate('locations')}
        >
          <ValidationIndicator status={statuses.locations} />
          {' '}
          Locations
        </NavItem>
        <NavItem id='brand-breadcrumbs-beacon'
                 active={this.props.activeTab === 'beacon'}
                 onClick={() => this.props.onNavigate('beacon')}
        >
          <ValidationIndicator status={statuses.beacon} />
          {' '}
          Beacon
        </NavItem>
      </Nav>
    )
  }
}
