import { connect } from 'react-redux'

import ManagePreferenceCategories from '../components/managePreferenceCategories'
import { preferenceActions } from '../preferenceActions'

const mapDispatchToProps = (dispatch) => {
  return {
    load: (e) => {
      dispatch(preferenceActions.fetchPreferenceCategories())
    },
    navigateToEdit: (id) => {
      dispatch(preferenceActions.navigateToEditPreferenceCategory(id))
    },
    navigateToList: () => {
      dispatch(preferenceActions.navigateToManagePreferenceCategories)
    }
  }
}

const mapStateToProps = (state) => {
  return {
    preferenceCategories: state.preference.preferenceCategories || {},
    status: state.preference.status,
    error: state.preference.error
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePreferenceCategories)
