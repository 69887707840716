import classNames from 'classnames'
import _ from 'lodash'
import React, { Component } from 'react'
import { Button, Col, Glyphicon, Panel, Row, Table } from 'react-bootstrap'
import ContentTypelabel from '../../common/components/contentTypeLabel'
import { SortableBody, SortableRow } from '../../common/components/sortableTable'
import StatusLabel from '../../common/components/statusLabel'
import { redemptionTypeOptions } from '../../common/enums'
import FormField from '../../common/form/formField'
import Label from '../../common/form/label'
import { formatDateTime, possibleNumberToString } from '../../common/utils'

class ContentItemsPanel extends Component {
  updateRedemptionType(value) {
    this.props.updateField('contentItems.redemptionType', value)()
    if (value === 'LIMITED') {
      this.props.updateField('contentItems.maxNumberOfRedemptions', 1)()
    } else {
      this.props.updateField('contentItems.maxNumberOfRedemptions', null)()
    }
  }

  render() {
    const contentItems = this.props.model.contentItems || {}
    const validationErrors = this.props.validationErrors
    const formDisabled = this.props.formDisabled
    const eventsOnly = _.every(this.props.contentItems, ['type', 'EVENT'])

    return (
      <div>
        <Row>
          <Col lg={4} md={4} style={{ height: '80px' }}>
            <FormField label='Group Redemption' id='redemptionType'
                       type='radiolist' inline
                       value={contentItems.redemptionType}
                       options={redemptionTypeOptions}
                       onChange={(value) => this.updateRedemptionType(value)}
                       error={validationErrors.contentItems.redemptionType}
                       disabled={formDisabled}
            />
          </Col>
          <Col lg={4} md={8}>
            {contentItems.redemptionType === 'LIMITED' ? (
              <FormField label='Max Offer Redemption' id='maxNumberOfRedemptions' min='0'
                         type='number'
                         value={possibleNumberToString(contentItems.maxNumberOfRedemptions)}
                         onChange={this.props.updateField('contentItems.maxNumberOfRedemptions')}
                         error={validationErrors.contentItems.maxNumberOfRedemptions}
                         disabled
                         style={{ width: '100px' }}
              />
            ) : null}
          </Col>
        </Row>
        <Panel header='Content' id='group-items'>
          <div>
            {validationErrors.contentItems.ids ? (
              <Label className={classNames('input-error text-danger')}>
                {validationErrors.contentItems.ids}
              </Label>
            ) : null}
            {this.props.contentItems.length > 0 ? (
              <Table striped hover>
                <thead>
                <tr>
                  <th width='1' />
                  <th>Type</th>
                  <th>Status</th>
                  <th>Title</th>
                  <th id='event-start-or-brand-header'>{eventsOnly ? 'Event start' : 'Brand'}</th>
                  <th>Visible from</th>
                  <th>Visible to</th>
                </tr>
                </thead>
                <SortableBody>
                  {this.props.contentItems.map((contentItem, index) => {
                    const rowProps = {
                      ids: this.props.ids,
                      item: contentItem,
                      onSort: this.props.onSort
                    }

                    return (
                      <SortableRow key={`content-item=${index}`} {...rowProps} onClick={() => this.props.viewContent(contentItem)}>
                        <td>
                          <Glyphicon glyph='menu-hamburger' />
                        </td>
                        <td><ContentTypelabel type={contentItem.type} /></td>
                        <td><StatusLabel status={contentItem.state} /></td>
                        <td>{contentItem.details.title || null}</td>
                        {eventsOnly ? (
                          <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.eventDateTime) : null}</td>
                        ) : (
                          <td>{contentItem.brand ? contentItem.brand.name : null}</td>
                        )}
                        <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayFromDateTime) : null}</td>
                        <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayToDateTime) : null}</td>
                      </SortableRow>
                    )
                  })}
                </SortableBody>
              </Table>
            ) : (
              <p>There are no items yet.</p>
            )}
            <p>
              <Button id='modify-group-items'
                      bsStyle='default'
                      onClick={this.props.onModify}
              >
                Add or remove items
              </Button>
            </p>
          </div>
        </Panel>
      </div>
    )
  }
}

export default ContentItemsPanel
