import React, { Component } from 'react'
import FaqSectionForm from './faqSectionForm'
import AppError from '../../common/components/appError'
import AppFlash from '../../common/components/appFlash'
import Spinner from '../../common/components/spinner'
import _ from 'lodash'

export default class FaqSectionDetails extends Component {
  constructor(props) {
    super(props)

    this.state = { model: _.cloneDeep(props.faqSection) }
    props.load()
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.faqSection, this.props.faqSection)) {
      this.setState({ model: _.cloneDeep(this.props.faqSection) })
    }
  }

  onSave(e, model) {
    this.setState({ model: _.cloneDeep(model) })
    this.props.nextHandler(e, model)
  }

  render() {
    const error = this.props.error ? <AppError errorMessage={this.props.error} /> : null
    const flash = this.props.flash ? <AppFlash flash={this.props.flash} /> : null

    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    const validationErrors = this.props.validationErrors || {}
    const backHandler = this.props.backHandler

    return (
      <FaqSectionForm error={error}
                      flash={flash}
                      faqSection={this.state.model}
                      nextHandler={this.onSave.bind(this)}
                      backHandler={backHandler}
                      validationErrors={validationErrors}
                      match={this.props.match}
      />
    )
  }
}
