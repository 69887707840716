/* global localStorage */

import http, { config, INTEREST_CATEGORIES_LIST_V1 } from '../services/http'
import { push } from 'connected-react-router'

import { accountActions } from '../accounts/accountActions'

const displayFlash = (message, style = 'info') => ({
  type: constants.DISPLAY_FLASH,
  message,
  style
})

const clearFlash = () => ({
  type: constants.CLEAR_FLASH
})

const userLoggedIn = (token) => ({
  type: constants.USER_LOGGED_IN,
  token
})

const userLoggedOut = () => {
  return {
    type: constants.USER_LOGGED_OUT
  }
}

const missingCredentials = (validationErrors) => ({
  type: constants.MISSING_CREDENTIALS,
  validationErrors
})

const loginError = (error) => ({
  type: constants.LOGIN_ERROR,
  error
})

const navigateToLogin = () => {
  return push('/login')
}

const navigateToPage = (route) => {
  return push(route)
}

const updateRefererRoute = (refererRoute) => ({
  type: constants.UPDATE_REFERER_ROUTE,
  refererRoute
})

const logoutUser = () => {
  return (dispatch) => {
    localStorage.removeItem(constants.AUTH_TOKEN)
    localStorage.removeItem(constants.PROFILE)
    localStorage.removeItem(constants.ROLES)
    dispatch(userLoggedOut())
    dispatch(navigateToLogin())
  }
}

const credentialsUpdated = (credentials) => ({
  type: constants.CREDENTIALS_UPDATED,
  credentials
})

const validateUser = (credentials) => {
  return (dispatch, getState) => {
    dispatch(credentialsUpdated(credentials))
    credentials.username = credentials.username ? credentials.username.toLowerCase().trim() : ''
    return http.post(dispatch, `${config.serverUrl}/login`, credentials)
      .then((token) => {
        token.created_at = token.created_at || Date.now()
        localStorage.setItem(constants.AUTH_TOKEN, JSON.stringify(token))
        dispatch(userLoggedIn(token))
        const refererRoute = getState().common.refererRoute || '/'
        dispatch(navigateToPage(refererRoute))
        dispatch(fetchProfile(credentials.username))
        dispatch(fetchRoles())
      }).catch((err) =>
        handleLoginError(dispatch, err)
      )
  }
}

const profileFetched = (profile) => ({
  type: constants.PROFILE_FETCHED,
  profile
})

const fetchProfile = (email) => {
  return (dispatch) => {
    return dispatch(accountActions.fetchAccountByEmail(email)).then(profile => {
      localStorage.setItem(constants.PROFILE, JSON.stringify(profile))
      dispatch(profileFetched(profile))
    })
  }
}

const preferenceCategoriesFetched = (preferenceCategories) => ({
  type: constants.PREFERENCE_CATEGORIES_FETCHED,
  preferenceCategories
})

const fetchPreferenceCategories = () => {
  return (dispatch) => {
    return http.get(dispatch, `${config.serverUrl}/preferenceCategories`, null, {
      Accept: INTEREST_CATEGORIES_LIST_V1
    }).then((resp) => {
      dispatch(preferenceCategoriesFetched(resp))
    }).catch((err) => {
      dispatch(commonActions.displayFlash(`Unable to fetch preference categories : ${config.serverUrl}/preferenceCategories - ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const rolesFetched = (roles) => ({
  type: constants.ROLES_FETCHED,
  roles
})

const fetchRoles = () => {
  return (dispatch) => {
    return http.get(dispatch, `${config.serverUrl}/users/me`).then((resp) => {
      const roles = resp.roles.map(r => r.toUpperCase())
      localStorage.setItem(constants.ROLES, JSON.stringify(roles))
      dispatch(rolesFetched(roles))
    })
  }
}

const handleLoginError = (dispatch, resp) => {
  if (resp.status === 400) {
    dispatch(missingCredentials(JSON.parse(resp.body).validationErrors))
  } else if (resp.status === 401) {
    dispatch(loginError('Invalid username or password'))
  } else {
    dispatch(loginError('Something went wrong, please try again later'))
  }
}

const tokenRefreshing = () => ({
  type: constants.AUTH_TOKEN_REFRESHING
})

const tokenRefreshed = (token) => ({
  type: constants.AUTH_TOKEN_REFRESHED,
  token
})

const refreshToken = (email) => {
  return (dispatch, getState) => {
    dispatch(tokenRefreshing())
    const token = JSON.parse(localStorage.getItem(constants.AUTH_TOKEN))
    return http.post(dispatch, `${config.serverUrl}/users/${email}/refreshToken`, {
      refresh_token: token.refresh_token
    }).then((newToken) => {
      newToken.created_at = newToken.created_at || Date.now()
      localStorage.setItem(constants.AUTH_TOKEN, JSON.stringify(newToken))
      dispatch(tokenRefreshed(newToken))
    }).catch(() => {
      dispatch(tokenRefreshed(token))
    })
  }
}

function checkRefreshToken(store) {
  const {
    token,
    profile
  } = store.getState().common

  // refresh token if it was created more than 30m ago
  const refreshable = profile && token && token.created_at + (30 * 60 * 1000) < Date.now()
  if (refreshable) {
    store.dispatch(commonActions.refreshToken(profile.email))
  }
}

export const constants = {
  DISPLAY_FLASH: 'DISPLAY_FLASH',
  CLEAR_FLASH: 'CLEAR_FLASH',
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  MISSING_CREDENTIALS: 'MISSING_CREDENTIALS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  CREDENTIALS_UPDATED: 'CREDENTIALS_UPDATED',
  UPDATE_REFERER_ROUTE: 'UPDATE_REFERER_ROUTE',
  AUTH_TOKEN: 'AUTH_TOKEN',
  AUTH_TOKEN_REFRESHING: 'AUTH_TOKEN_REFRESHING',
  AUTH_TOKEN_REFRESHED: 'AUTH_TOKEN_REFRESHED',
  PROFILE: 'PROFILE',
  ROLES: 'ROLES',
  ROLES_FETCHED: 'ROLES_FETCHED',
  PROFILE_FETCHED: 'PROFILE_FETCHED',
  PREFERENCE_CATEGORIES_FETCHED: 'PREFERENCE_CATEGORIES_FETCHED'
}

export const commonActions = {
  displayFlash,
  clearFlash,
  validateUser,
  userLoggedIn,
  userLoggedOut,
  missingCredentials,
  loginError,
  credentialsUpdated,
  navigateToLogin,
  navigateToPage,
  updateRefererRoute,
  logoutUser,
  fetchProfile,
  fetchRoles,
  fetchPreferenceCategories,
  profileFetched,
  refreshToken,
  checkRefreshToken
}
