import React, { Component } from 'react'
import DataTable from '../../common/components/dataTable'
import Spinner from '../../common/components/spinner'
import Constants from '../../appConstants'
import NotificationBreadcrumbs from './notificationBreadcrumbs'
import { Col, Panel, Row } from 'react-bootstrap'
import Layout from './notificationLayout'
import AppError from '../../common/components/appError'
import DeleteOrDiscardModal from '../../common/components/deleteOrDiscardModal'

export default class NotificationProfilesList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saveOrDiscardModalVisible: false
    }

    props.load()
  }

  showDeleteConfirmation(profileIdToBeDeleted) {
    this.showDeleteOrDiscard()
    this.setState({ profileIdToBeDeleted: profileIdToBeDeleted })
  }

  showDeleteOrDiscard() {
    this.setState({ deleteOrDiscardModalVisible: true })
  }

  display(content) {
    if (content != null) {
      return content.length > 20 ? (content.slice(0, 20) + '...') : content
    }
  }

  render() {
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }
    let error = <div />
    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }
    let table = <div id='no-data'>No Profiles to display</div>

    if (this.props.notificationProfiles && this.props.notificationProfiles.length > 0) {
      var headers = {
        name: { name: 'Name' },
        msisdn: { name: 'MSISDN' },
        msisdnEnabled: { name: 'MSISDN Enabled' },
        email: { name: 'Email' },
        emailEnabled: { name: 'Email Enabled' },
        actions: { name: 'Actions' }
      }
      const tableRows = this.props.notificationProfiles.map(profileIterator => {
        return {
          name: this.display(profileIterator.name),
          msisdn: profileIterator.msisdn,
          msisdnEnabled: <input className='msisdnEnabled' type='checkbox' checked={profileIterator.msisdnEnabled} disabled readOnly />,
          email: profileIterator.email,
          emailEnabled: <input className='emailEnabled' type='checkbox' checked={profileIterator.emailEnabled} disabled readOnly />,
          actions: (
            <span>
              <a id='delete-link' className='link' style={{ marginRight: '10px' }} onClick={(e) => this.showDeleteConfirmation(profileIterator.id)}>Delete</a>
              <a id='edit-link' className='link' onClick={(e) => this.props.navigateToEdit(profileIterator.id)}>Edit</a>
            </span>
          )
        }
      })
      table = <DataTable displayPageNo={Constants.DEFAULT_DISPLAY_PAGE_NO} rowsPerPage={Constants.DEFAULT_ROWS_PER_PAGE} headers={headers} data={tableRows} wrapperClassName='profiles-table' />
    }

    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <NotificationBreadcrumbs match={this.props.match}
            />
          </Col>
          <Col sm={8} lg={9}>
            {error}
            <Panel header='Profiles'>
              <div id='msisdn-listing-panel'>
                {table}
              </div>
            </Panel>
          </Col>
        </Row>
        <DeleteOrDiscardModal message='Do you want to delete this Notification Profile?'
                              visible={this.state.deleteOrDiscardModalVisible}
                              onDelete={() => {
                                this.props.onDelete(this.state.profileIdToBeDeleted)
                                this.setState({ deleteOrDiscardModalVisible: false })
                              }}
                              onDiscard={() => {
                                this.setState({ deleteOrDiscardModalVisible: false })
                                this.props.navigateToList()
                              }}
        />
      </Layout>
    )
  }
}
