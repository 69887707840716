import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'

import Form from '../../common/form/form'
import MsisdnBreadcrumbs from './msisdnBreadcrumbs'
import FormField from '../../common/form/formField'
import Layout from './msisdnLayout'
import AppError from '../../common/components/appError'
import Spinner from '../../common/components/spinner'

export default class CreateMsisdn extends Component {
  constructor(props) {
    super(props)
    this.model = {}
    props.newMsisdn()
  }

  _onChangeMsisdn(msisdn) {
    this.model['msisdn'] = msisdn.target.value
  }

  _onChangeName(name) {
    this.model['name'] = name.target.value
  }

  _onChangeCheckboxValue(checkboxValue) {
    this.model['enabled'] = checkboxValue.target.checked
  }

  render() {
    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    const validationErrors = this.props.validationErrors || {}
    const error = this.props.error ? <AppError errorMessage={this.props.error} /> : null
    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <MsisdnBreadcrumbs match={this.props.match}
            />
          </Col>
          <Col sm={8} lg={6}>
            {error}
            <Panel header='Create Preview MSISDN'>
              <Form nextButtonText='Create'
                    nextHandler={(e) => {
                      this.props.nextHandler(e, this.model)
                    }}
                    backButtonText='Back'
                    backHandler={(e) => {
                      this.props.backHandler(e)
                    }}
              >
                <FormField label='MSISDN'
                           id='msisdn'
                           type='number'
                           maxLength='12'
                           value={this.model.msisdn}
                           error={validationErrors.msisdn}
                           onChange={(e) => {
                             this._onChangeMsisdn(e)
                           }}
                           mandatory
                />
                <FormField label='Name'
                           id='name'
                           value={this.model.name}
                           maxLength='40'
                           error={validationErrors.name}
                           onChange={(e) => {
                             this._onChangeName(e)
                           }}
                           mandatory
                />
                <FormField label='Enabled'
                           id='enabled'
                           type='checkbox'
                           value={this.model.enabled}
                           error={validationErrors.enabled}
                           onChange={(e) => {
                             this._onChangeCheckboxValue(e)
                           }}
                />
              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
