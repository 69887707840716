import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import _ from 'lodash'

import FormField from '../../common/form/formField'
import GroupLink from '../../common/components/groupLink'
import PreferenceCategories from '../../common/components/preferenceCategories'
import ThankYouLink from '../../common/components/thankYouLink'
import CategoryTags from '../../common/components/categoryTags'
import { accountTypeOptions, channelOptions } from '../../common/enums'

class DetailsPanel extends Component {
  updateCategories(value) {
    const featuredTags = _.get(this.props.model, 'details.audience.featuredOverrides')
    if (!value.includes('FEATURED') && featuredTags) {
      this.props.updateField('details.audience.featuredOverrides', null)()
    }
    this.props.updateField('details.categories', value)()
  }

  render() {
    const {
      model,
      formDisabled,
      validationErrors,
      updateField,
      prizeDraw,
      brand,
      roles,
      preferenceCategories
    } = this.props

    return (
      <Panel header='Basic Details' id='details'>
        {prizeDraw.id ? (
          <div className='form-group form-group-inline'>
            <label className='control-label'>ID:</label>
            {' '}
            {prizeDraw.id}
          </div>
        ) : null}
        {prizeDraw.shortId ? (
          <div className='form-group form-group-inline' id='prizeDraw-shortId'>
            <label className='control-label'>Short ID:</label> {prizeDraw.shortId}
          </div>
        ) : null}
        <div className='form-group form-group-inline'>
          <label className='control-label'>Brand:</label>
          {' '}
          {brand ? brand.name : ''}
        </div>
        <GroupLink model={prizeDraw} />
        <ThankYouLink model={prizeDraw} />
        <FormField label='Title' id='title' value={_.get(model, 'details.title', '')}
                   maxLength='60' mandatory
                   onChange={updateField('details.title')}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.title')}
        />
        <FormField label='Subtitle' id='subtitle' value={_.get(model, 'details.subtitle', '')}
                   maxLength='60'
                   onChange={updateField('details.subtitle')}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.subtitle')}
        />
        <FormField label='Search tags (keywords)' id='searchTags'
                   value={_.get(model, 'details.searchTags', '')}
                   onChange={updateField('details.searchTags')}
                   disabled={formDisabled}
        />
        <FormField label='Description' id='description' value={_.get(model, 'details.description', '')}
                   maxLength='5000' type='textArea' mandatory
                   onChange={updateField('details.description')}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.description')}
        />
        <FormField label='Terms & Conditions' id='termsAndConditions' value={_.get(model, 'details.termsAndConditions', '')}
                   type='textArea' rows='6' placeholder='Enter an offer terms and conditions'
                   maxLength='5000' mandatory
                   onChange={updateField('details.termsAndConditions')}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.termsAndConditions')}
        />
        <FormField label='Post-entry Title' id='postEntryTitle' value={_.get(model, 'details.postEntryTitle', '')}
                   maxLength='12' mandatory
                   onChange={updateField('details.postEntryTitle')}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.postEntryTitle')}
        />
        <FormField label='Post-entry Message' id='postEntryMessage' value={_.get(model, 'details.postEntryMessage', '')}
                   maxLength='54' type='textArea' mandatory
                   onChange={updateField('details.postEntryMessage')}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.postEntryMessage')}
        />
        <CategoryTags model={model}
                      updateField={updateField}
                      formDisabled={formDisabled}
                      validationErrors={validationErrors}
                      pageType = {'PRIZE_DRAW'}
        />
        <FormField label='Sort categories' id='categories'
                   value={_.get(model, 'details.categories') || []}
                   type='categorieslist'
                   offerContentType
                   featuredOnly
                   roles={roles}
                   onChange={(value) => this.updateCategories(value)}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.categories')}
        />
        <FormField label='Apps' id='apps'
                   value={_.get(model, 'details.channels') || []}
                   options={channelOptions}
                   type='checkboxlist' mandatory
                   onChange={(value) => updateField('details.channels', value)()}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.channels')}
        />
        <PreferenceCategories id='preferenceCategories'
                              value={_.get(model, 'details.preferenceCategories') || []}
                              updateField={updateField}
                              label='Preference Categories'
                              options={preferenceCategories}
                              disabled={formDisabled}
        />
        <FormField label='Account type' id='accountType'
                   value={_.get(model, 'details.myO2Audience.accountType')}
                   options={accountTypeOptions}
                   type='checkboxradiolist' inline
                   onChange={(value) => updateField('details.myO2Audience.accountType', value)()}
                   disabled={formDisabled}
                   error={_.get(validationErrors, 'details.myO2Audience.accountType')}
        />
        <FormField label='Staff only' id='staffOnly'
                   value={_.get(model, 'details.staffOnly')}
                   type='checkbox' inline
                   onChange={(e) => updateField('details.staffOnly', e.target.checked)()}
                   disabled={formDisabled}
        />
        <FormField label="18+ only" id='ageRestricted'
                   value={_.get(model, 'details.ageRestricted')}
                   type='checkbox' inline
                   onChange={(e) => updateField('details.ageRestricted', e.target.checked)()}
                   disabled={formDisabled}
        />
      </Panel>
    )
  }
}

export default DetailsPanel
