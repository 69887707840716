import React, { Component } from 'react'

export default class SectionHeadersLayout extends Component {
  render() {
    return (
      <div>
        <div className='page-header'>
          <h1>Section Headers</h1>
        </div>
        {this.props.children}
      </div>
    )
  }
}
