import React from 'react'
import { Col, Glyphicon, Row } from 'react-bootstrap'

export const CopyBanner = ({
  sections,
  onToggleSection
}) => {
  return (
    <Row>
      <Col md={4}>
        <p>Select content to be copied:</p>
        <ul className='copy-list'>
          <li>
            <label>
              <input id='copy-details'
                     type='checkbox'
                     checked={sections.details}
                     onChange={() => onToggleSection('details')}
              />
              Basic Details
            </label>
          </li>
          <li>
            <label>
              <input id='copy-schedule'
                     type='checkbox'
                     checked={sections.alternateTitles}
                     onChange={() => onToggleSection('alternateTitles')}
              />
              Schedule
            </label>
            <ul className='list-styled'>
              <li>Alternate Titles</li>
            </ul>
          </li>
          <li>
            <label>
              <input id='copy-assets'
                     type='checkbox'
                     checked={sections.assets}
                     onChange={() => onToggleSection('assets')}
              />
              Assets
            </label>
          </li>
        </ul>
      </Col>
      <Col md={4}>
        <p>The following will not be copied:</p>
        <ul id='not-copied-sections' className='copy-list'>
          <li>
            <Glyphicon className='red' glyph='remove' />
            Schedule
          </li>
        </ul>
      </Col>
    </Row>
  )
}
