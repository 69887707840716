import React from 'react'
import { Button, Table } from 'react-bootstrap'

import Spinner from '../../common/components/spinner'
import AppFlash from '../../common/components/appFlash'

export default class Listing extends React.Component {
  componentDidMount() {
    this.props.load()
  }

  render() {
    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    const merchants = this.props.merchants || []

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>Merchants</h1>
            <p>A total of {merchants.length} merchants have been found.</p>
          </div>
          <div className='page-header--tools'>
            <Button bsStyle='primary' onClick={() => this.props.newMerchant()}>New Merchant</Button>
          </div>
        </div>
        {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
        <Table striped hover>
          <thead>
          <tr>
            <th>Company Name</th>
            <th>Contact</th>
          </tr>
          </thead>
          <tbody>
          {merchants.map(merchant => (
            <tr className='cursor-pointer' key={merchant.id} onClick={() => this.props.editMerchantHandler(merchant.id)}>
              <td>
                {merchant.companyName}
              </td>
              <td>
                {merchant.firstName && merchant.lastName ? `${merchant.firstName} ${merchant.lastName}` : null}
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </div>
    )
  }
}
