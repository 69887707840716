import { constants } from './bannerActions'

export default function banners(state = {}, action = {}) {
  switch (action.type) {
    case constants.BANNER_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.BANNER_SAVING:
      return Object.assign({}, state, {
        banner: action.banner,
        status: 'SAVING'
      })
    case constants.BANNER_NEW:
    case constants.BANNER_FETCHED:
    case constants.BANNER_SAVED:
    case constants.BANNER_SUBMITTED:
    case constants.BANNER_REJECTED:
    case constants.BANNER_PUBLISHED:
    case constants.BANNER_PAUSED:
    case constants.BANNER_RESUMED:
    case constants.BANNER_EDITED:
      return Object.assign({}, state, {
        banner: action.banner,
        status: 'SUCCESS',
        error: null
      })
    case constants.BANNER_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    case constants.BANNER_CALENDAR_CONTENT_FETCHED:
      return Object.assign({}, state, {
        calendarContent: action.calendarContent
      })
    default:
      return state
  }
}
