import { constants } from './welcomePosterActions'

export default function welcomePosters(state = {}, action = {}) {
  switch (action.type) {
    case constants.WELCOME_POSTERS_FETCHING:
      return Object.assign({}, state, {
        welcomePoster: {},
        welcomePosters: [],
        welcomePosterStatus: 'LOADING'
      })
    case constants.WELCOME_POSTERS_FETCHED:
      return Object.assign({}, state, {
        welcomePosters: action.welcomePosters,
        welcomePosterStatus: 'SUCCESS'
      })
    case constants.WELCOME_POSTER_UPDATED:
      return Object.assign({}, state, {
        welcomePoster: action.welcomePoster,
        validationErrors: {},
        welcomePosterStatus: 'SUCCESS'
      })
    case constants.WELCOME_POSTER_UPDATE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        welcomePosterStatus: 'FAILED'
      })
    case constants.WELCOME_POSTER_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        welcomePosterStatus: 'FAILED'
      })
    case constants.CLICKED_WELCOME_POSTER:
      return Object.assign({}, state, {
        clickedWelcomePoster: action.welcomePoster
      })
    default:
      return state
  }
}
