import React, { Component } from 'react'

export default class Hyperlink extends Component {
  constructor(props) {
    super(props)
    this._handleClick = this._handleClick.bind(this)
  }

  _handleClick(e) {
    this.props.onClick(e)
  }

  render() {
    const containerClasses = this.props.className || undefined
    const id = this.props.id

    return (
      <div className={containerClasses}>
        <a id={id} name={id}
           href={this.props.href}
           onClick={this._handleClick}
           disabled={this.props.disabled || false}
        >
          {this.props.value}
        </a>
      </div>
    )
  }
}
