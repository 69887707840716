import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { brandActions } from '../../brands/brandActions'
import { commonActions } from '../../common/commonActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import { stockActions } from '../../stock/stockActions'
import OfferForm from '../components/OfferForm'
import { offerActions } from '../offerActions.js'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      load: () => {
        const { id, auditId } = ownProps.match.params
        if (id) {
          dispatch(offerActions.fetchOffer(id)).then((offer) => {
            if (!offer) return
            dispatch(offerActions.fetchOfferLocations(offer.brand.id, id))
          })
          dispatch(offerActions.fetchRelatedContent(id))
        } else {
          dispatch(brandActions.fetchBrands())
          dispatch(offerActions.newOffer())
        }
        dispatch(commonActions.fetchPreferenceCategories())
        if (auditId) {
          dispatch(auditActions.fetchAudit(auditId))
        }
      },
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/offers/${id}/audits/${audit.id}`))
      },
      submit: (offer) => {
        dispatch(offerActions.submitOffer(offer))
      },
      reject: (offer) => {
        dispatch(offerActions.rejectOffer(offer))
      },
      publish: (offer) => {
        dispatch(offerActions.publishOffer(offer))
      },
      pause: (offer) => {
        dispatch(offerActions.pauseOffer(offer))
      },
      resume: (offer) => {
        dispatch(offerActions.resumeOffer(offer))
      },
      edit: (offer) => {
        dispatch(offerActions.editOffer(offer))
      },
      save: (offer) => {
        return dispatch(offerActions.saveOffer(offer))
      },
      updateStockVolume: (model, limit, threshold, volume) => {
        const id = ownProps.match.params.id
        return dispatch(stockActions.updateVolume(id, model, limit, threshold, volume))
      },
      deleteAllStock: () => {
        const id = ownProps.match.params.id
        return dispatch(stockActions.deleteAllStock(id))
      },
      uploadUniqueCodes: (model, file, scheduleStartDateTime, scheduleEndDateTime) => {
        const id = ownProps.match.params.id
        return dispatch(stockActions.uploadUniqueCodes(id, model, file, scheduleStartDateTime, scheduleEndDateTime))
      },
      downloadUniqueCodes: (historyId) => {
        const id = ownProps.match.params.id
        stockActions.downloadUniqueCodes(id, historyId)
      },
      downloadUnusedCodes: () => {
        const id = ownProps.match.params.id
        stockActions.downloadUnusedCodes(id)
      },
      onConfirmationDelete(index) {
        this.setState({ deleteOrDiscardModalVisible: true })
        this.setState({ indexToBeDeleted: index })
      },
      navigateToNewOurPicksTrigger(offer) {
        return dispatch(offerActions.navigateToNewOurPicksTrigger(offer))
      },
      navigateToEditOurPicksTrigger(offer, index) {
        return dispatch(offerActions.navigateToEditOurPicksTrigger(offer, index))
      },
      navigateToOfferAlternateTitle: (offerProps) => {
        dispatch(offerActions.navigateToOfferAlternateTitle(offerProps))
      },
      navigateToNewAlternateTitle: (offer) => {
        let index = 0
        if (offer.schedule && offer.schedule.alternateTitles) {
          index = offer.schedule.alternateTitles.length
        }
        dispatch(offerActions.navigateToNewAlternateTitle(offer.id, index))
      },
      disable: (offer) => {
        return dispatch(offerActions.disableOffer(offer))
      },
      enable: (offer) => {
        return dispatch(offerActions.enableOffer(offer))
      }
    }
  )
}

const mapStateToProps = (state) => {
  const { perPage, page } = state.offers.pagination
  const locationsPage = state.offers.locations.slice(perPage * (page - 1), perPage * page)

  return {
    offer: state.offers.offer || {},
    relatedContent: state.offers.relatedContent,
    locations: state.offers.locations,
    locationsPage,
    pagination: state.offers.pagination,
    brands: state.brands.brands,
    status: state.offers.status,
    error: state.offers.error,
    roles: state.common.roles,
    audits: state.audits,
    flash: state.common.flash,
    stockValidationErrors: state.stock.validationErrors || {},
    preferenceCategories: state.common.preferenceCategories
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferForm)
