import { connect } from 'react-redux'
import { bannerActions } from '../bannerActions'
import AlternateTitlesLayout from '../../common/components/AlternateTitlesLayout'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const id = ownProps.match.params.id
      dispatch(bannerActions.fetchBanner(id))
    },
    save: (banner) => {
      dispatch(bannerActions.saveBanner(banner))
      dispatch(bannerActions.navigateToSchedule(ownProps.match.params.id))
    },
    backHandler: () => {
      dispatch(bannerActions.navigateToSchedule(ownProps.match.params.id))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let loadedContent = {}
  if (state.banners.banner) {
    loadedContent = state.banners.banner
  }
  return {
    contentId: ownProps.match.params.id,
    content: loadedContent,
    contentType: 'banners',
    index: ownProps.match.params.index,
    status: state.banners.status
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlternateTitlesLayout)
