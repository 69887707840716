import http, { config } from '../../services/http'

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    uploadMedia: (mediaTarget, file, mediaFormat, asset) => {
      return dispatch(uploadMediaInternal(mediaTarget, file, mediaFormat, asset || false))
    }
  }
}

export const mapStateToProps = (state, ownProps) => {
  return {
    dirty: false,
    hasActiveYoutubeLink: ownProps.model.assets && ownProps.model.assets.youtubeVideoId && ownProps.model.assets.youtubeVideoId.length > 0
  }
}

const uploadMediaInternal = (mediaTarget, media, mediaFormat, asset) => {
  var formData = new FormData()
  formData.append('mediaTarget', mediaTarget)
  formData.append('mediaFormat', mediaFormat || 'IMAGE')
  formData.append('media', media)
  asset ? formData.append('asset', true) : null

  return (dispatch) => {
    return http.post(dispatch, `${config.serverUrl}/media/`, formData).catch(err => {
      if (err.status === 400) {
        const message = JSON.parse(err.body)
        throw String(message)
      } else {
        throw String(`Unable to upload the media: ${err.status} (${err.statusText})`)
      }
    })
  }
}
