import _ from 'lodash'
import React from 'react'
import { Col, Form, Panel, Row } from 'react-bootstrap'

import AppError from '../../common/components/appError'
import AppFlash from '../../common/components/appFlash'
import AuditsPanel from '../../common/components/auditsPanel'
import ContentFormBase from '../../common/components/contentFormBase'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import GroupLink from '../../common/components/groupLink'
import PreferenceCategories from '../../common/components/preferenceCategories'
import RelatedContent from '../../common/components/relatedContent'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import Spinner from '../../common/components/spinner'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import StatusToolbar from '../../common/components/statusToolbar'
import ThankYouLink from '../../common/components/thankYouLink'
import WebLink from '../../common/components/webLink'
import { accountTypeOptions } from '../../common/enums'
import FormField from '../../common/form/formField'
import ImageSelector from '../../common/media/ImageSelector'
import EventDatesPanel from './EventDatesPanel'
import SchedulePanel from './SchedulePanel'
import Breadcrumbs from './TourBreadcrumbs'

export default class TourForm extends ContentFormBase {
  constructor(props) {
    super(props)

    this.model = _.cloneDeep(props.tour || {})
    this.state = {
      saveOrDiscardModalVisible: false,
      dirty: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.tour, this.props.tour)) {
      this.model = this.props.tour || {}
      this.forceUpdate()
    }

    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
  }

  modifyRelatedContent() {
    this.props.navigateToSelectRelatedContent(this.props.tour)
  }

  render() {
    const activeTab = this.props.match.params.section || 'details'
    const sections = ['details', 'assets', 'schedule', 'eventDates', 'relatedContent']
    const requiredSections = ['details', 'assets']
    const status = this.props.status || 'LOADING'
    const roles = this.props.roles
    const venues = this.props.venues
    const ids = this.model.relatedContent && this.model.relatedContent.ids || []
    const preferenceCategories = this.props.preferenceCategories

    const tour = _.cloneDeep(this.props.tour || {})
    const eventDates = this.props.eventDates || []
    const validationErrors = this.model.validationErrors || {}
    sections.forEach((section) => {
      tour[section] = tour[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })

    const formDisabled = this.isFormDisabled(tour.state, status)
    const liveEdit = this.isLiveEdit(tour)

    if (status === 'LOADING') {
      return <Spinner />
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className='page-header'>
              <div className='page-header--heading'>
                <div className='page-header--top'>
                  <ContentTypeLabel className='page-header--type' type={tour.type} />
                  <StatusLabel className='page-header--state-label' status={tour.state} />
                  {tour.details.cancelled ? (
                    <StatusLabelCustom className='page-header--state-label margin-left-5' status='cancelled' text='Cancelled' />
                  ) : null}
                  {tour.isPublished ? (
                    <WebLink content={tour} />
                  ) : null}
                  {liveEdit ? (
                    <StatusLabelCustom className='page-header--state-label' status='live_edit' text='Editing published content' />
                  ) : null}
                </div>
                <h1 className='content-heading'>{tour.details.title || 'New Tour'}</h1>
              </div>
              <div className='page-header--tools'>
                <StatusToolbar status={tour.state}
                               contentComplete={this.isComplete(tour, validationErrors, sections, requiredSections) && eventDates.length > 0}
                               dirty={this.state.dirty}
                               isLiveEdit={liveEdit}
                               onSubmit={this.submit()}
                               onPublish={this.publish()}
                               onReject={this.reject()}
                               onSave={this.save()}
                               onPause={this.pause()}
                               onResume={this.resume()}
                               onEdit={this.edit()}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <Breadcrumbs tour={tour}
                             eventDates={eventDates}
                             validationErrors={validationErrors}
                             activeTab={activeTab}
                             onNavigate={(tab) => this.onNavigate(tab)}
                             showCopy={tour.id}
                             onCopy={this.copy()}
                />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
                {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
                <Form>
                  {activeTab === 'details' ? (
                    <Panel header='Basic Details' id='details'>
                      {tour.id ? (
                        <div className='form-group'>
                          <label className='control-label'>ID:</label>
                          {' '}
                          {tour.id}
                        </div>
                      ) : null}
                      {tour.shortId ? (
                        <div className='form-group' id='tour-shortId'>
                          <label className='control-label'>Short ID:</label>
                          {' '}
                          {tour.shortId}
                        </div>
                      ) : null}
                      <GroupLink model={tour} />
                      <ThankYouLink model={tour} />
                      <FormField label='Title' id='title'
                                 value={this.model.details ? this.model.details.title : ''}
                                 maxLength='60' mandatory
                                 onChange={this.updateField('details.title')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.title}
                      />
                      <FormField label='Sub Title' id='subtitle'
                                 value={this.model.details ? this.model.details.subtitle : ''}
                                 maxLength='60'
                                 onChange={this.updateField('details.subtitle')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.subtitle}
                      />
                      <FormField label='Short Title' id='shortTitle' mandatory
                                 value={this.model.details ? this.model.details.shortTitle : ''}
                                 maxLength='20'
                                 onChange={this.updateField('details.shortTitle')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.shortTitle}
                      />
                      <FormField label='Search tags (keywords)' id='searchTags'
                                 value={this.model.details ? this.model.details.searchTags : ''}
                                 onChange={this.updateField('details.searchTags')}
                                 disabled={formDisabled}
                      />
                      <FormField label='Description' id='description'
                                 value={this.model.details ? this.model.details.description : ''}
                                 type='textArea' maxLength='2000'
                                 onChange={this.updateField('details.description')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.description}
                      />
                      <FormField label='Artists' id='artists'
                                 value={this.model.details ? this.model.details.artists : ''}
                                 maxLength='60' mandatory
                                 onChange={this.updateField('details.artists')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.artists}
                      />
                      <FormField label='Apple Music Title' id='musicTitle'
                                 value={this.model.details ? this.model.details.musicTitle : ''}
                                 maxLength='40'
                                 onChange={this.updateField('details.musicTitle')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.musicTitle}
                      />
                      <FormField label='Apple Music Subtitle' id='musicSubtitle'
                                 value={this.model.details ? this.model.details.musicSubtitle : ''}
                                 maxLength='70'
                                 onChange={this.updateField('details.musicSubtitle')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.musicSubtitle}
                      />
                      <FormField label='Apple Artist Id' id='artistId'
                                 value={this.model.details ? this.model.details.artistId : ''}
                                 maxLength='60'
                                 onChange={this.updateField('details.artistId')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.artistId}
                      />
                      <FormField label='Sort categories' id='categories'
                                 value={this.model.details ? this.model.details.categories || [] : []}
                                 type='categorieslist'
                                 ticketContentType
                                 roles={roles}
                                 onChange={(value) => this.updateField('details.categories', value)()}
                                 disabled={formDisabled}
                                 error={validationErrors.details.categories}
                      />
                      <PreferenceCategories id='preferenceCategories'
                                            value={this.model.details ? this.model.details.preferenceCategories || [] : []}
                                            updateField={this.updateField.bind(this)}
                                            label='Preference Categories'
                                            options={preferenceCategories}
                                            disabled={formDisabled}
                      />
                      <FormField label='Account type' id='accountType'
                                 value={_.get(this.model, 'details.myO2Audience.accountType')}
                                 options={accountTypeOptions}
                                 type='checkboxradiolist' inline
                                 onChange={(value) => this.updateField('details.myO2Audience.accountType', value)()}
                                 disabled={formDisabled}
                                 error={_.get(validationErrors, 'details.myO2Audience.accountType')}
                      />
                      <FormField label="18+ only" id='ageRestricted'
                                 value={_.get(this.model, 'details.ageRestricted')}
                                 type='checkbox' inline
                                 onChange={(e) => this.updateField('details.ageRestricted', e.target.checked)()}
                                 disabled={formDisabled}
                      />
                    </Panel>
                  ) : null}
                  {activeTab === 'assets' ? (
                    <Panel header='Assets' id='assets'>

                      <ImageSelector label='Landscape Image'
                                     type='landscape'
                                     mediaTarget='LANDSCAPE'
                                     mandatory
                                     width={1125} height={750}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Square Image'
                                     type='square'
                                     mediaTarget='SQUARE'
                                     mandatory
                                     width={750} height={750}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Full Screen Image'
                                     type='fullScreen'
                                     mediaTarget='FULL_SCREEN'
                                     mandatory
                                     width={1080} height={1920}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Circular Image'
                                     type='circular'
                                     mediaTarget='CIRCULAR'
                                     mandatory
                                     width={320} height={320}
                                     withBlend
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <FormField label='Youtube video ID (O2 channel only)' id='youtube-video-id'
                                 value={this.model.assets ? this.model.assets.youtubeVideoId || '' : ''}
                                 onChange={this.updateField('assets.youtubeVideoId')}
                                 disabled={formDisabled}
                                 error={validationErrors.assets.youtubeVideoId}
                      />
                    </Panel>
                  ) : null}
                  {activeTab === 'schedule' ? (
                    <SchedulePanel model={this.model}
                                   updateField={this.updateField.bind(this)}
                                   validationErrors={validationErrors}
                                   save={this.props.save}
                                   navigateToNewTicketsTrigger={this.props.navigateToNewTicketsTrigger}
                                   navigateToEditTicketsTrigger={this.props.navigateToEditTicketsTrigger}
                                   formDisabled={formDisabled}
                    />
                  ) : null}
                  {activeTab === 'eventDates' ? (
                    <EventDatesPanel tour={tour}
                                     eventDates={eventDates}
                                     venues={venues}
                                     formDisabled={formDisabled}
                                     onClick={this.props.navigateToEventDate}
                    />
                  ) : null}
                  {activeTab === 'relatedContent' ? (
                    <RelatedContent relatedContentItems={this.props.relatedContent}
                                    onModify={() => this.modifyRelatedContent()}
                                    onSort={this.updateSortOf('relatedContent.ids')}
                                    ids={ids}
                                    formDisabled={formDisabled}
                                    viewContent={this.props.viewContent}
                    />
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onMount={this.props.fetchAudits}
                                 onViewAudit={this.props.onViewAudit}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                            onSave={() => this.saveAndBack()}
                            onDiscard={() => this.props.navigateToDashboard()}
                            onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
        />
      </div>
    )
  }
}
