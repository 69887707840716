import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import classNames from 'classnames'
import RemainingCount from './remainingCount'

import Label from './label'

export default class AddMultiple extends Component {
    constructor(props) {
        super(props)
        this.state = {
            values: props.value ? props.value : [''],
            itemErrors: props.itemErrors ? props.itemErrors : null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ values: (this.props.value === null || this.props.value === undefined) ? [''] : this.props.value })
        }
    }

    _handleChange(i, event) {
        const values = [...this.state.values]
        values[i] = event.target.value
        this.setState({ values })
        this.props.onChange(values)
     }

     _handleAddClick() {
        this.setState(prevState => ({ values: [...this.state.values, ''] }))
    }

    _handleRemoveClick(index) {
        const values = [...this.state.values]
        values.splice(index, 1)
        this.setState({ values })
        this.props.onChange(values)
    }

    _getFormElement() {
        return this.state.values.map((value, index) =>
            <span className='multi-element' key={'span-multi-element-' + index}>
                <div className='multi-element-field'>
                    <RemainingCount value={value} maxLength={this.props.maxLength} />
                    <Label>Bullet {index+1}</Label>
                    <input id={this.props.id} name={this.props.id} type='text'
                        elementindex={index}
                        className='form-control'
                        value={value || ''}
                        onChange={this._handleChange.bind(this, index)}
                        disabled={this.props.disabled || false}
                        placeholder={this.props.placeholder || ''}
                        style={this.props.style}
                    />
                    <Label className={classNames('input-error text-danger')}>
                        {this.props.itemErrors[index]}
                    </Label>
                </div>
                <div className='multi-element-button' key={'btn-rmv-' + index}>
                    {index > 0 && !this.props.disabled && <Button onClick={this._handleRemoveClick.bind(this, index)}>Remove</Button>}
                </div>
            </span>
        )
    }

    render() {
        return (
            <div className={this.props.className}>
                {this._getFormElement()}
                {this.state.values.length < this.props.maxItems && !this.props.disabled && <Button onClick={this._handleAddClick.bind(this)}>Add another</Button>}
            </div>
        )
    }
}
