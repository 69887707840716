import { connect } from 'react-redux'
import { bannerActions } from '../bannerActions'
import OurPicksLayout from '../../common/components/TriggersLayout'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      const id = ownProps.match.params.id
      dispatch(bannerActions.fetchBanner(id))
    },
    save: (banner) => {
      dispatch(bannerActions.saveBanner(banner))
      dispatch(bannerActions.navigateToSchedule(ownProps.match.params.id))
    },
    backHandler: () => {
      dispatch(bannerActions.navigateToSchedule(ownProps.match.params.id))
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    contentId: ownProps.match.params.id,
    content: state.banners.banner,
    contentType: 'banners',
    index: ownProps.match.params.index,
    status: state.banners.status
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurPicksLayout)
