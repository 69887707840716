import { connect } from 'react-redux'

import { accountActions } from '../accountActions'
import EditAccount from '../components/edit'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAccountHandler: () => {
      dispatch(accountActions.fetchAccount(ownProps.match.params.accountId))
    },
    nextHandler: (e, accountDetails) => {
      e.preventDefault()
      dispatch(accountActions.updateAccount(ownProps.match.params.accountId, accountDetails))
    },
    backHandler: e => {
      e.preventDefault()
      dispatch(accountActions.navigateToManageAccounts())
    }
  }
}

const mapStateToProps = state => {
  return {
    status: state.accounts.accountStatus,
    validationErrors: state.accounts.validationErrors,
    error: state.accounts.error,
    account: state.accounts.account,
    roles: state.common.roles,
    profile: state.common.profile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount)
