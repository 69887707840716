import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'
import FormField from '../form/formField'
import Form from '../form/form'
import _ from 'lodash'
import AppError from './appError'

export default class AlternateTitlesForm extends Component {
  constructor(props) {
    super(props)

    this.flash = false
    this.content = this.props.content || {}
    this.content.schedule = this.content.schedule || {}
    this.index = this.props.index

    this.model = (this.content.schedule.alternateTitles) ? {
      alternateTitles: this.content.schedule.alternateTitles[this.index],
      length: this.content.schedule.alternateTitles.length
    } : { alternateTitles: '', length: this.index }

    if (this.props.index < 0 || this.props.index > this.model.length) {
      this.flash = true
    }

    this.validationErrors = this.content.validationErrors || {}
    this.validationErrors.schedule = (this.validationErrors.schedule && !_.isString(this.validationErrors.schedule)) ? this.validationErrors.schedule : {}
    this.validationErrors.schedule.alternateTitles = this.validationErrors.schedule[`alternateTitles[${this.index}]`] || {}

    this.saveAlternateTitles = this.saveAlternateTitles.bind(this)
    // Restrict to one Out of stock alternate title.
    this.checkShowOutOfStock = _.some(_.get(this.content, 'schedule.alternateTitles'), ['outOfStockAlternateTitle', true])
  }

  updateField(path, value) {
    return (e) => {
      _.set(this.model.alternateTitles, path, e ? e.target.value : value)
      this.forceUpdate()
    }
  }

  saveAlternateTitles() {
    this.content.schedule = this.content.schedule || {}
    this.content.schedule.alternateTitles = this.content.schedule.alternateTitles || []
    this.content.schedule.alternateTitles[this.index] = this.model.alternateTitles
    this.props.save(this.content)
  }

  titleAvailableAtIndex(index) {
    return this.content.schedule && this.content.schedule.alternateTitles && this.content.schedule.alternateTitles[index]
  }

  render() {
    if (!this.flash && !this.titleAvailableAtIndex(this.index)) {
      if (this.content.schedule.alternateTitles == null) {
        this.content.schedule.alternateTitles = []
      }
      this.content.schedule.alternateTitles[this.index] = {
        title: '',
        subtitle: '',
        shortTitle: '',
        visibleFromDateTime: '',
        visibleToDateTime: '',
        outOfStockAlternateTitle: false
      }
    }
    this.model = { alternateTitles: this.content.schedule.alternateTitles[this.index] }
    let isOutOfStockAlternateTitle = false
    if (this.model && this.model.alternateTitles && this.model.alternateTitles.outOfStockAlternateTitle !== 'undefined') {
      isOutOfStockAlternateTitle = this.model.alternateTitles.outOfStockAlternateTitle
      if (isOutOfStockAlternateTitle) {
        this.checkShowOutOfStock = false
      }
    }
    return (
      <div>
        {this.flash
          ? (<AppError id="alternateIndex-error" errorMessage="The alternate title index is invalid."/>)
          : (<Panel header="Alternate Titles" id="schedule">
            <Form nextButtonText="Save"
                  nextButtonDisabled={!!this.flash}
                  nextHandler={this.saveAlternateTitles}
                  backHandler={this.props.backHandler}
            >
              {(this.content.type === 'OFFER') ? (
                <FormField label="Display alternate values when vouchers are out of stock" id="outOfStockAlternateTitle" name="outOfStockAlternateTitle"
                           value={isOutOfStockAlternateTitle}
                           type="checkbox"
                           checked={isOutOfStockAlternateTitle}
                           disabled={this.checkShowOutOfStock}
                           onChange={(e) => this.updateField('outOfStockAlternateTitle', e.target.checked)()}
                />
              ) : ''}
              <FormField label="Alternate Title"
                         id="title"
                         type="text"
                         maxLength="60"
                         value={this.model.alternateTitles.title}
                         disabled={''}
                         onChange={this.updateField('title')}
                         error={this.validationErrors.schedule.alternateTitles.title}
              />
              <FormField label="Alternate Subtitle"
                         id="subtitle"
                         type="text"
                         value={_.isNil(this.model.alternateTitles.subtitle) ? '' : this.model.alternateTitles.subtitle}
                         maxLength="60"
                         disabled={''}
                         onChange={this.updateField('subtitle')}
                         error={this.validationErrors.schedule.alternateTitles.subtitle}
              />
              {this.props.shortTitle ? (
                <FormField label="Alternate Short title"
                           id="shortTitle"
                           type="text"
                           value={_.isNil(this.model.alternateTitles.shortTitle) ? '' : this.model.alternateTitles.shortTitle}
                           maxLength="20"
                           disabled={''}
                           onChange={this.updateField('shortTitle')}
                           error={this.validationErrors.schedule.alternateTitles.shortTitle}
                />
              ) : null}
              <FormField label="Visible From Date & Time"
                         type="datetime"
                         id="visibleFromDateTime"
                         mandatory
                         style={{ minWidth: '80pt', maxWidth: '120pt' }}
                         value={this.model.alternateTitles.visibleFromDateTime || ''}
                         disabled={isOutOfStockAlternateTitle}
                         onChange={(value) => this.updateField('visibleFromDateTime', value)()}
                         error={!isOutOfStockAlternateTitle ? this.validationErrors.schedule.alternateTitles.visibleFromDateTime : ''}
              />
              <FormField label="Visible To Date & Time"
                         type="datetime"
                         id="visibleToDateTime"
                         mandatory
                         style={{ minWidth: '80pt', maxWidth: '120pt' }}
                         value={this.model.alternateTitles.visibleToDateTime || ''}
                         disabled={isOutOfStockAlternateTitle}
                         onChange={(value) => this.updateField('visibleToDateTime', value)()}
                         error={!isOutOfStockAlternateTitle ? this.validationErrors.schedule.alternateTitles.visibleToDateTime : ''}
              />
            </Form>
          </Panel>)
        }
      </div>
    )
  }
}
