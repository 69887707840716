import { parameterize, titleCase } from './utils'

export const topCategoryOptions = [{
  value: 'FEATURED',
  label: 'Our Picks',
  path: 'our-picks', // Used for sorting path /sort/our-picks
  appUrl: '/'
}, {
  value: 'TICKETS',
  label: 'Tickets',
  path: 'tickets',
  appUrl: '/tickets'
}, {
  value: 'OFFERS',
  label: 'Offers',
  path: 'offers',
  appUrl: '/offers'
}, {
  value: 'MY_O2',
  label: 'My O2',
  path: 'my-o2',
  appUrl: '/my-o2'
}]

export const faqCategoryOptions = [{
  value: 'TICKETS',
  label: 'Tickets'

}, {
  value: 'REWARDS',
  label: 'Rewards'
}]

export const ticketCategoryOptions = [{
  value: 'TICKETS_POP',
  label: 'Pop'
}, {
  value: 'TICKETS_ROCK',
  label: 'Rock'
}, {
  value: 'TICKETS_INDIE',
  label: 'Indie'
}, {
  value: 'TICKETS_URBAN_RNB',
  label: 'Rap & R&B'
}, {
  value: 'TICKETS_DANCE',
  label: 'Dance'
}, {
  value: 'TICKETS_METAL',
  label: 'Metal'
}, {
  value: 'TICKETS_COUNTRY',
  label: 'Country'
}, {
  value: 'TICKETS_CLASSICAL',
  label: 'Classical'
}, {
  value: 'TICKETS_OTHER_MUSIC',
  label: 'Other music'
}, {
  value: 'TICKETS_SPORT',
  label: 'Sports Tickets'
}, {
  value: 'TICKETS_COMEDY',
  label: 'Comedy'
}, {
  value: 'TICKETS_FAMILY',
  label: 'Family'
}, {
  value: 'TICKETS_OTHER',
  label: 'Other'
}].map(category => {
  return Object.assign(category, {
    appUrl: `/tickets?category=${parameterize(category.value)}`
  })
})

export const offerCategoryOptions = [{
  value: 'FOOD_AND_DRINK',
  label: 'Food & Drink'
}, {
  value: 'SHOPPING',
  label: 'Shopping'
}, {
  value: 'PLAY',
  label: 'Out & About'
}, {
  value: 'UNWIND',
  label: 'Health & Wellbeing '
}, {
  value: 'SPORTS',
  label: 'Sports'
  }, {
  value: 'TRAVEL',
  label: 'Travel'
}, {
  value: 'PRIZES',
  label: 'Prizes'
}, {
  value: 'GAMING',
  label: 'Gaming'
}, {
  value: 'MUSIC',
  label: 'Music'
}, {
  value: 'AT_HOME',
  label: 'At Home'
}].map(category => {
  return Object.assign(category, {
    appUrl: `/offers?category=${parameterize(category.value)}`
  })
})

export const categoryOptions = [].concat(topCategoryOptions, ticketCategoryOptions, offerCategoryOptions)

export const channelOptions = [{
  value: 'PRIORITY',
  label: 'Priority'
}]

export const accountTypeOptions = [{
  value: 'PAYG',
  label: 'PAYG'
}, {
  value: 'PAYM',
  label: 'PAYM'
}]

export const platformOptions = [{
  value: 'IOS',
  label: 'IOS'
}, {
  value: 'ANDROID',
  label: 'Android'
}, {
  value: 'WEB',
  label: 'Web'
}]

export const contentStatuses = {
  DRAFT: 'Draft',
  PENDING_APPROVAL: 'Pending Approval',
  PUBLISHED: 'Published',
  LIVE: 'Live',
  PAUSED: 'Paused',
  REJECTED: 'Rejected',
  CLOSED: 'Closed',
  DISABLED: 'Disabled'
}

export const preSelectedFilterTypeCheckboxes = ['DRAFT', 'PENDING_APPROVAL', 'PUBLISHED', 'LIVE', 'PAUSED', 'DISABLED']

export const contentTypes = {
  OFFER: 'Offer',
  PRIZE_DRAW: 'Prize Draw',
  TOUR: 'Tour',
  GROUP: 'Group',
  THANK_YOU: 'Thank You',
  CAMPAIGN: 'Campaign',
  BANNER: 'Banner',
  ARTICLE: 'Article',
  SERIES: 'Series',
  MUSIC_BANNER: 'Music Banner'
}

export const relatedContentFilteredContentTypes = {
  OFFER: 'Offer',
  PRIZE_DRAW: 'Prize Draw',
  TOUR: 'Tour',
  GROUP: 'Group',
  ARTICLE: 'Article'
}

export const selectBannerContentOnly = {
  BANNER: 'Banner'
}

export const redemptionTypeOptions = [{
  label: 'Unlimited',
  value: 'UNLIMITED'
}, {
  label: 'Limited',
  value: 'LIMITED'
}]

export const voucherCodeTypeOptions = [{
  value: 'NONE',
  label: 'No Code'
}, {
  value: 'GENERIC',
  label: 'Generic Code'
}, {
  value: 'UNIQUE',
  label: 'Unique Code'
}]

export const voucherStoreTypes = {
  IN_STORE: 'In Store',
  ONLINE: 'Online'
}

export const voucherStoreTypeOptions = [{
  value: 'IN_STORE',
  label: 'In Store'
}, {
  value: 'ONLINE',
  label: 'Online'
}]

export const voucherLimitOptions = [{
  value: 'SINGLE',
  label: 'Single voucher'
}, {
  value: 'MULTIPLE',
  label: 'Multiple vouchers'
}]

export const voucherPresentationMethodOptions = [{
  value: 'VOUCHER',
  label: 'Voucher'
}, {
  value: 'BARCODE',
  label: 'QR Code'
}, {
  value: 'BEACON',
  label: 'Beacon'
}]

export const locationStatuses = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  TEMPORARILY_CLOSED: 'Temporarily Closed'
}

export const locationStatusToLabel = (status) => {
  return locationStatuses[status] || titleCase(status)
}

export const locationStatusOptions = [{
  value: 'OPEN',
  label: 'Open'
}, {
  value: 'CLOSED',
  label: 'Closed'
}, {
  value: 'TEMPORARILY_CLOSED',
  label: 'Temporary closed'
}]

export const stockLimitOptions = [{
  label: 'Unlimited',
  value: 'UNLIMITED'
}, {
  label: 'Limited',
  value: 'LIMITED'
}]

export const eventRestrictionsOptions = [{
  label: '',
  value: null
}, {
  label: 'Over 18\'s only. ID may be required.',
  value: 'ABOVE_18'
}, {
     label: 'Over 16\'s only. ID may be required.',
     value: 'ABOVE_16'
}, {
  label: 'Over 14\'s only. ID may be required.',
  value: 'ABOVE_14'
}, {
  label: 'Under 18\'s only. ID may be required.',
  value: 'UNDER_18'
}]

export const ticketTypes = [{
  label: 'General Entry',
  value: 'GENERAL'
}]

export const roleOptions = [{
  label: 'Priority Team (Campaigns and Tours)',
  value: 'INTERNAL'
}, {
  label: 'Ticket Vendor (Tours only)',
  value: 'EXTERNAL'
}, {
  label: 'Admin (All access and Account management)',
  value: 'ADMIN'
}]

export const ctaTypeOptions = [{
  label: 'URL',
  value: 'URL'
}, {
  label: 'Youtube Video',
  value: 'VIDEO'
}, {
  label: 'None',
  value: 'NONE'
}]

export const bannerAndCampaignTypeOptions = [{
  label: 'URL',
  value: 'URL'
}, {
  label: 'Youtube Video',
  value: 'VIDEO'
}, {
  label: 'Add to Calendar',
  value: 'CALENDAR'
}, {
  label: 'None',
  value: 'NONE'
}]

export const musicBannerTypeOptions = [{
  label: 'URL',
  value: 'URL'
}, {
  label: 'Youtube Video',
  value: 'VIDEO'
}, {
  label: 'None',
  value: 'NONE'
}]

export const captionOptions = [{
  label: 'Group',
  value: 'GROUP'
}, {
  label: 'Online',
  value: 'ONLINE'
}, {
  label: 'In-Store',
  value: 'IN_STORE'
}, {
  label: 'Prize Draw',
  value: 'PRIZE_DRAW'
}, {
  label: 'None',
  value: 'NONE'
}]

export const audienceFeaturedOptions = [{
  label: 'Splash campaign',
  value: 'SPLASH_CAMPAIGN'
}, {
  label: 'Contractual',
  value: 'CONTRACTUAL'
}]

export const articleSubtypes = [{
  label: 'Blog',
  value: 'BLOG'
}, {
  label: 'Audio',
  value: 'AUDIO'
}, {
  label: 'Video',
  value: 'VIDEO'
}]

export const themeOptions = [{
  label: 'Dark',
  value: 'DARK'
}, {
  label: 'Light',
  value: 'LIGHT'
}, {
  label: 'None',
  value: 'NONE'
}]

export const sectionHeaderTypeOptions = [{
  label: 'Select',
  value: ''
}, {
  label: 'Thank you',
  value: 'THANK_YOU'
}, {
  label: 'Our Picks',
  value: 'OUR_PICKS'
}, {
  label: 'What\'s new',
  value: 'WHATS_NEW'
}, {
  label: 'Tickets',
  value: 'TICKETS'
}, {
  label: 'Prizes',
  value: 'PRIZES'
}]

export const isBannerAttached = [{
  label: 'Is Banner Attached?',
  value: 'IS_BANNER_ATTACHED'
}]

export const welcomePosterTypeOptions = [{
  label: 'Welcome Poster',
  value: 'OFFERS_ONLY'
}, {
  label: 'No Preferences',
  value: 'NO_PREFERENCE'
}, {
  label: 'No Offers',
  value: 'NO_OFFERS'
}]

export const featureFlagTypeOptions = [{
  label: 'Queue It',
  value: 'QUEUE_IT'
},{
  label: 'In-app rating',
  value: 'IN_APP_RATING'
}]
