import React, { Component } from 'react'
import { Button, Col, Panel, Row } from 'react-bootstrap'

import AppFlash from '../../common/components/appFlash'
import FormField from '../../common/form/formField'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import Spinner from '../../common/components/spinner'
import Breadcrumbs from './merchantBreadcrumbs'
import _ from 'lodash'

export default class Details extends Component {
  constructor(props) {
    super(props)

    this.model = _.cloneDeep(props.businessDetails)

    this.state = {
      modalVisible: false,
      dirty: false
    }
    this._combineWithTime = this._combineWithTime.bind(this)
    this._displayWithoutTime = this._displayWithoutTime.bind(this)

    const staleMerchant = props.merchant && props.match.params && props.merchant.id !== props.match.params.merchantId
    if (!props.merchant || staleMerchant) {
      props.load()
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.businessDetails, this.props.businessDetails)) {
      this.model = _.cloneDeep(this.props.businessDetails)
      this.forceUpdate()
    }
  }

  update(property) {
    return (e) => {
      this.model[property] = e.target.value
      this.setState({ dirty: true })
    }
  }

  launchSaveOrDiscardModal(navigateAction) {
    if (this.state.dirty) {
      this.setState({
        modalVisible: true,
        navigateAction: navigateAction
      })
    } else {
      this.props.dispatch(navigateAction)
    }
  }

  onSave() {
    this.onClose()
    this.props.saveAndNavigate(this.model)(this.state.navigateAction)
  }

  onDiscard() {
    this.onClose()
    this.props.discardChanges()
    this.props.dispatch(this.state.navigateAction)
  }

  onClose() {
    this.setState({ modalVisible: false })
  }

  _combineWithTime(e) {
    var combinedDateTime = new Date(e.target.value)
    combinedDateTime.setUTCHours(0)
    combinedDateTime.setUTCMinutes(0)
    this.model['ioExpiry'] = combinedDateTime
  }

  _displayWithoutTime(date) {
    let dateString = ''

    if (date) {
      const dateObj = new Date(date)
      dateString = dateObj.getFullYear() + '-' + (dateObj.getMonth() + 1) + '-' + dateObj.getDate()
    }

    return dateString
  }

  render() {
    const status = this.props.status || 'LOADING'
    if (status === 'LOADING') {
      return <Spinner />
    }

    const businessDetails = this.model
    const validationErrors = this.props.validationErrors || {}

    const nextHandler = this.props.nextHandler

    return (
      <div>
        <div className='page-header'>
          <h1>Merchant</h1>
        </div>
        <Row>
          <Col sm={4} lg={3}>
            <Breadcrumbs merchant={this.props.merchant}
                         match={this.props.match}
                         onNavigate={(a) => this.launchSaveOrDiscardModal(a)}
            />
          </Col>
          <Col sm={8} lg={6}>
            {this.props.flash ? <AppFlash flash={this.props.flash} /> : null}
            <Panel header='Business Details'>
              <FormField label='Company Name' id='company-name' value={businessDetails.companyName}
                         onChange={this.update('companyName')} error={validationErrors.companyName} mandatory />
            </Panel>

            <Button id='next-button' bsStyle='primary' onClick={(e) => {
              nextHandler(e, this.model)
            }}>Next</Button>
          </Col>
        </Row>
        <SaveOrDiscardModal visible={this.state.modalVisible}
                            onSave={() => this.onSave()}
                            onDiscard={() => this.onDiscard()}
                            onClose={() => this.onClose()}
        />
      </div>
    )
  }
}
