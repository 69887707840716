import _ from 'lodash'
import React from 'react'
import { Col, Form, Panel, Row } from 'react-bootstrap'
import AppError from '../../common/components/appError'
import AuditsPanel from '../../common/components/auditsPanel'
import CallToAction from '../../common/components/CallToAction'
import ContentFormBase from '../../common/components/contentFormBase'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import Spinner from '../../common/components/spinner'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import StatusToolbar from '../../common/components/statusToolbar'
import { bannerAndCampaignTypeOptions, captionOptions, categoryOptions, themeOptions } from '../../common/enums'
import FormField from '../../common/form/formField'
import ImageSelector from '../../common/media/ImageSelector'
import VideoSelector from '../../common/media/VideoSelector'
import { config } from '../../services/http'
import Breadcrumbs from './CampaignBreadcrumbs'
import SchedulePanel from './SchedulePanel'

export default class CampaignForm extends ContentFormBase {
  constructor(props) {
    super(props)

    this.model = _.cloneDeep(props.campaign || {})
    this.state = {
      saveOrDiscardModalVisible: false,
      selectedBrand: null,
      dirty: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.campaign, this.props.campaign)) {
      this.model = _.cloneDeep(this.props.campaign || {})
      this.forceUpdate()
    }

    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
  }

  updateCaptionOption(property) {
    return (val) => {
      this.model.details.caption = val
      this.setState({ dirty: true })
    }
  }

  updateThemeOption(property) {
    return (val) => {
      this.model.details.theme = val
      this.setState({ dirty: true })
    }
  }

  render() {
    const activeTab = this.props.match.params.section || 'details'
    const sections = config.superCampaignEnabled ? ['details', 'schedule', 'assets', 'callToAction'] : ['details', 'schedule', 'assets']
    const requiredSections = config.superCampaignEnabled ? ['details', 'schedule', 'assets', 'callToAction'] : ['details', 'schedule', 'assets']
    const status = this.props.status || 'LOADING'
    const campaign = _.cloneDeep(this.props.campaign || {})

    const validationErrors = this.model.validationErrors || {}
    const publishDisabled = !_.isEmpty(validationErrors)
    const resumeDisabled = !_.isEmpty(validationErrors)

    sections.forEach((section) => {
      campaign[section] = campaign[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })

    const formDisabled = this.isFormDisabled(campaign.state, status)
    const liveEdit = this.isLiveEdit(campaign)
    let ctaOptions = categoryOptions.slice()

    ctaOptions = ctaOptions.filter(option => {
      return option.value !== 'FEATURED'
    })
    ctaOptions.unshift({ value: '', label: 'None' })

    if (status === 'LOADING') {
      return <Spinner />
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className='page-header'>
              <div className='page-header--heading'>
                <div className='page-header--top'>
                  <ContentTypeLabel className='page-header--type' type={campaign.type} />
                  <StatusLabel className='page-header--state-label' status={campaign.state} />
                  {liveEdit ? (
                    <StatusLabelCustom className='page-header--state-label' status='live_edit' text='Editing published content' />
                  ) : null}
                </div>
                <h1 className='content-heading'>{campaign.details.title || 'New Campaign'}</h1>
              </div>
              <div className='page-header--tools'>
                <StatusToolbar status={campaign.state}
                               contentComplete={this.isComplete(campaign, validationErrors, sections, requiredSections)}
                               dirty={this.state.dirty}
                               isLiveEdit={liveEdit}
                               publishDisabled={publishDisabled}
                               resumeDisabled={resumeDisabled}
                               onSubmit={this.submit()}
                               onPublish={this.publish()}
                               onReject={this.reject()}
                               onSave={this.save()}
                               onPause={this.pause()}
                               onResume={this.resume()}
                               onEdit={this.edit()}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <Breadcrumbs campaign={campaign}
                             validationErrors={validationErrors}
                             activeTab={activeTab}
                             onNavigate={(tab) => this.onNavigate(tab)}
                             showCopy={campaign.id}
                             onCopy={this.copy()}
                />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
                <Form>
                  {activeTab === 'details' ? (
                    <Panel header='Basic Details' id='details'>
                      {campaign.id ? (
                        <div className='form-group form-group-inline'>
                          <label className='control-label'>ID:</label>
                          {' '}
                          {campaign.id}
                        </div>
                      ) : null}
                      {campaign.shortId ? (
                        <div className='form-group form-group-inline' id='campaign-shortId'>
                          <label className='control-label'>Short ID:</label> {campaign.shortId}
                        </div>
                      ) : null}
                      <FormField label='Caption'
                                 type='radiolist' mandatory inline
                                 value={this.model.details && this.model.details.caption ? this.model.details.caption : 'NONE'}
                                 id='caption'
                                 options={captionOptions}
                                 onChange={this.updateCaptionOption('caption')}
                                 hidden={!config.superCampaignEnabled}
                                 disabled={formDisabled}
                      />
                      <FormField label='Theme'
                                 type='radiolist' mandatory inline
                                 value={this.model.details && this.model.details.theme ? this.model.details.theme : ''}
                                 id='theme'
                                 options={themeOptions}
                                 onChange={this.updateThemeOption('theme')}
                                 hidden={!config.superCampaignEnabled}
                                 disabled={formDisabled}
                                 error={validationErrors.details.theme}
                      />
                      <FormField label='Overline' id='overline' value={this.model.details ? this.model.details.overline : ''}
                                 maxLength='30'
                                 onChange={this.updateField('details.overline')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.overline}
                                 hidden={!config.superCampaignEnabled}
                      />
                      <FormField label='Title' id='title' value={this.model.details ? this.model.details.title : ''}
                                 maxLength='60'
                                 onChange={this.updateField('details.title')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.title}
                      />
                      <FormField label='Subtitle' id='subtitle' value={this.model.details ? this.model.details.subtitle : ''}
                                 maxLength='60'
                                 onChange={this.updateField('details.subtitle')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.subtitle}
                      />
                      <FormField label='Internal Reference' id='internalReference' value={this.model.details ? this.model.details.internalReference : ''}
                                 maxLength='30'
                                 mandatory
                                 onChange={this.updateField('details.internalReference')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.internalReference}
                      />
                      <FormField label="18+ only" id='ageRestricted'
                                value={_.get(this.model, 'details.ageRestricted')}
                                type='checkbox' inline
                                onChange={(e) => this.updateField('details.ageRestricted', e.target.checked)()}
                                disabled={formDisabled}
                      />
                      <div className='form-group form-group-categorieslist'>
                        <label className='control-label' htmlFor='categories'>Categories *</label>
                        <div>
                          <div className='checkbox-list-group'>
                            <label className='checkbox-inline disabled'>
                              <input id='categoriesCheckbox' disabled type='checkbox' value='FEATURED' checked />
                              Our Picks
                            </label>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  ) : null}
                  {activeTab === 'schedule' ? (
                    <SchedulePanel model={this.model}
                                   updateField={this.updateField.bind(this)}
                                   validationErrors={validationErrors}
                                   formDisabled={formDisabled}
                                   newAlternateTitle={this.props.navigateToNewAlternateTitle}
                                   navigateToCampaignAlternateTitle={this.props.navigateToCampaignAlternateTitle}
                                   saveCampaign={this.props.save}
                                   navigateToSchedule={this.props.navigateToSchedule}
                    />
                  ) : null}
                  {activeTab === 'assets' ? (
                    <Panel header='Assets' id='assets'>
                      <ImageSelector label='Brand Image'
                                     type='brand'
                                     mediaTarget='BRAND_LOGO'
                                     width={320} height={320}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Desktop Background'
                                     type='desktopBackground'
                                     mediaTarget='SUPER_CAMPAIGN_DESKTOP'
                                     mandatory
                                     width={1920} height={720}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <VideoSelector label='Desktop Video Background (1920 X 720)'
                                     type='desktopBackground'
                                     mediaTarget='SUPER_CAMPAIGN_DESKTOP'
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Portrait Image'
                                     type='portrait'
                                     mediaTarget='PORTRAIT'
                                     width={750} height={1125}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Landscape Image'
                                     type='landscape'
                                     mediaTarget='LANDSCAPE'
                                     width={1125} height={750}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <ImageSelector label='Tall Mobile / App Background'
                                     type='tallMobileBackground'
                                     mediaTarget='TALL_MOBILE_IMAGE'
                                     mandatory
                                     width={750} height={1060}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <VideoSelector label='Tall App Video Background (750 x 1060)'
                                     type='tallMobileBackground'
                                     mediaTarget='TALL_MOBILE_VIDEO'
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                      <VideoSelector label='Tall Web Mobile Video Background (750 x 1060)'
                                     type='tallWebMobileBackground'
                                     mediaTarget='TALL_MOBILE_VIDEO'
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled} />

                    </Panel>
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onMount={this.props.fetchAudits}
                                 onViewAudit={this.props.onViewAudit}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                  {activeTab === 'callToAction' ? (
                    <CallToAction model={this.model}
                                  disabled={formDisabled}
                                  typeOptions={bannerAndCampaignTypeOptions}
                                  calendarContent={this.props.calendarContent}
                                  onSelectCalendarContent={() => this.props.navigateToSelectCalendarItems(this.props.campaign)}
                                  validationErrors={validationErrors}
                                  updateParentField={this.updateField.bind(this)}
                                  viewContent={this.props.viewContent} />
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                            onSave={() => this.saveAndBack()}
                            onDiscard={() => this.props.navigateToDashboard()}
                            onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
        />
      </div>
    )
  }
}
