import React, { Component } from 'react'
import { Panel } from 'react-bootstrap'

import Diff from './diff'
import Spinner from './spinner'
import Label from '../form/label'
import { formatDateTime } from '../utils'
import ContentTypeLabel from './contentTypeLabel'

class AuditDetail extends Component {
  render() {
    const {
      audit,
      status
    } = this.props.audits

    return (
      <Panel header='Audit trail'>
        {status === 'LOADING' ? (
          <Spinner />
        ) : null}
        {audit ? (
          <div>
            <Label>Date</Label>
            <p>{formatDateTime(audit.createdAt)}</p>
            <Label>Name</Label>
            <p>{`${audit.auditUser.firstName || ''} ${audit.auditUser.lastName || ''}`}</p>
            <Label>Action</Label>
            <p><ContentTypeLabel type={audit.contentType} /> {audit.action}</p>
            <Label>Content</Label>
            <p>Please note, all times are audited in UTC timezone</p>
            <Diff id='audit-content'
                  type='json'
                  inputA={JSON.stringify(audit.previousContent || undefined, null, 2) || ''}
                  inputB={JSON.stringify(audit.content, null, 2)}
            />
          </div>
        ) : null}
      </Panel>
    )
  }
}

export default AuditDetail
