import React from 'react'
import { Button, Panel } from 'react-bootstrap'
import LocationsTable from '../../common/components/locationsTable'
import Pagination from '../../common/components/pagination'
import SearchMatchText from '../../common/components/searchMatchText'

const LocationsPanel = ({
  validationErrors,
  locations,
  pagination,
  onSelectPage,
  onModify
}) => (
  <Panel header='Locations' id='locations'>
    {locations.length > 0 ? (
      <SearchMatchText pagination={pagination}
                       displayCount={locations.length}
      />
    ) : null}
    <p>
      <Button onClick={onModify}>Add or remove locations</Button>
    </p>
    <LocationsTable locations={locations} />
    {pagination.totalPages > 1 ? (
      <Pagination totalPages={pagination.totalPages}
                  page={pagination.page}
                  onSelect={(page) => onSelectPage(page)}
      />
    ) : null}
    {validationErrors.locations.locations ? (
      <label className='input-error text-danger'>{validationErrors.locations.locations}</label>
    ) : null}
  </Panel>
)

export default LocationsPanel
