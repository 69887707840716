import { auditActions } from '../audits/auditActions'
import { contentActions } from '../content/contentActions'
import { dashboardActions } from '../dashboard/dashboardActions'

export const commonMapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAudits: () => {
      const { id } = ownProps.match.params
      dispatch(auditActions.fetchAudits(id))
    },
    selectPage:
      (page) => {
        const { id } = ownProps.match.params
        dispatch(auditActions.clearAudits())
        dispatch(auditActions.selectPage(page))
        dispatch(auditActions.fetchAudits(id))
      },
    navigateToSection:
      (contentItem = {}, section) => {
        dispatch(contentActions.navigateToContentItem(contentItem, section))
      },
    navigateToDashboard:
      () => {
        dispatch(dashboardActions.navigateToDashboard())
      },
    viewContent: (contentItem = {}, section = 'details') => {
      dispatch(contentActions.navigateToContentItem(contentItem, section))
    },
    navigateToSelectRelatedContent: (content) => {
      dispatch(contentActions.navigateToSelectRelatedContent(content))
    },
    navigateToSelectLocations: (content, locations) => {
      dispatch(contentActions.navigateToSelectLocations(content, locations))
    },
    copy: (content) => {
      dispatch(contentActions.navigateToCopyContent(content))
    },
    navigateToSelectContentItems(content) {
      dispatch(contentActions.navigateToSelectContentItems(content))
    },
    navigateToSelectSeriesItems(content) {
      dispatch(contentActions.navigateToSelectSeriesItems(content))
    },
    navigateToSelectCalendarItems(content) {
      dispatch(contentActions.navigateToSelectCalendarItems(content))
    }
  }
}
