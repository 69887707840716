import React, { Component } from 'react'

export default class TextArea extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.value || '' }
    this._handleChange = this._handleChange.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value || '' })
    }
  }

  _handleChange(e) {
    this.props.onChange(e)
    this.setState({ value: e.target.value })
  }

  render() {
    return (
      <div className={this.props.className || ''}>
        <textarea rows={this.props.rows || 4}
                  className='form-control'
                  id={this.props.id}
                  name={this.props.id}
                  onChange={this._handleChange}
                  value={this.state.value}
                  disabled={this.props.disabled}
        />
      </div>
    )
  }
}
