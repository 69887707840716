import React from 'react'

import { Button, ButtonToolbar } from 'react-bootstrap'
import Spinner from '../../common/components/spinner'
import AppError from '../../common/components/appError'
import { contentTypes } from '../../common/enums'
import SelectContentTable from './_selectContentTable'
import ContentFilters from './contentFilters'
import InfiniteScroll from './infiniteScroll'

export default class SelectCalendarItems extends InfiniteScroll {
  constructor(props) {
    super(props)
    props.load(props.currentContent)
  }

  updateCalendarContent() {
    const calendarId = this.props.selectedItems.length > 0 ? this.props.selectedItems[0] : null

    const updatedContent = {
      ...this.props.currentContent,
      callToAction: {
        ...this.props.currentContent.callToAction,
        calendarId,
        urlType: 'CALENDAR'
      }
    }

    this.props.updateContent(updatedContent)
  }

  render() {
    const status = this.props.status || 'LOADING'
    const disabledTypes = ['GROUP', 'THANK_YOU', 'BANNER', 'CAMPAIGN', 'EVENT']
    const persistedIds = this.props.currentContent ? this.props.currentContent.contentId : []
    const contents = this.props.contents.filter((contentItem) => {
      return contentItem.type !== 'BANNER' &&
             contentItem.type !== 'CAMPAIGN' &&
             contentItem.type !== 'THANK_YOU' &&
             contentItem.type !== 'GROUP'
    })
    const roles = this.props.roles

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>Select calendar items</h1>
          </div>
          <div className='page-header--tools'>
            <ButtonToolbar>
              <Button id='cancel-calendar-items' onClick={() => this.props.backToContentItem(this.props.currentContent)}>Cancel</Button>
              <Button id='update-calendar-items' onClick={() => this.updateCalendarContent() }>Update</Button>
            </ButtonToolbar>
          </div>
        </div>
        <ContentFilters selectedFilters={this.props.selectedFilters}
                        updateFilters={this.props.updateFilters}
                        updateSearch={this.props.updateSearch}
                        contentTypes={contentTypes}
                        typesHidden={!roles.some(role => ['ADMIN', 'INTERNAL'].includes(role))}
                        searchVisible={false}
        />
        {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
        <div className='content-list'>
          {status === 'LOADING' ? (
            <Spinner />
          ) : null}
          <SelectContentTable items={contents}
                              selectedItems={this.props.selectedItems}
                              persistedIds={persistedIds}
                              maxSelectable={this.props.maxSelectable}
                              disableWasPublished
                              disableRemovingPaused
                              disableRemovingInGroup
                              disabledTypes={disabledTypes}
                              disabledStatuses={['LIVE', 'PUBLISHED', 'CLOSED']}
                              onToggleSelected={this.props.onToggleSelected}
                              selectedFilters={this.props.selectedFilters}
          />
        </div>
      </div>
    )
  }
}
