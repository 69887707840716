import React, { Component } from 'react'
import { Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

class PreferenceCategoryBreadcrumbs extends Component {
  render() {
    const { match } = this.props
    const path = match.path

    return (
        <Nav bsStyle='pills' stacked className='form-navigation'>
          <LinkContainer to='/preferenceCategories'
                       isActive={() => path === '/preferenceCategories'}
                       id='breadcrumbs-manage-preference-categories'
          >
            <NavItem>
              Manage Preference Categories
            </NavItem>
          </LinkContainer>
          <LinkContainer to='/preferenceCategory/create'
                       isActive={() => path === '/preferenceCategory/create'}
                       id='breadcrumbs-new-preference-category'
          >
            <NavItem>
              Create Preference Category
            </NavItem>
          </LinkContainer>
        </Nav>
    )
  }
}

export default PreferenceCategoryBreadcrumbs
