import { connect } from 'react-redux'

import Login from '../components/resetPasswordNotification'
import { accountActions } from '../accountActions'

const mapDispatchToProps = (dispatch) => {
  return {
    nextHandler: (e, email) => {
      e.preventDefault()
      dispatch(accountActions.sendResetPasswordNotification(email))
    }
  }
}

const mapStateToProps = state => {
  return {
    validationErrors: state.accounts.validationErrors || {},
    error: state.accounts.error,
    email: state.accounts.email || ''
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
