import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'
import _ from 'lodash'

import Form from '../../common/form/form'
import FormField from '../../common/form/formField'
import Password from '../../common/form/password'
import Breadcrumbs from '../../accounts/components/breadcrumbs'
import AppError from '../../common/components/appError'
import Layout from './layout'

export default class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.model = {}
    this.state = {
      validationErrors: this.props.validationErrors,
      disableNextButton: true
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.validationErrors, this.props.validationErrors)) {
      this.setState({
        validationErrors: this.props.validationErrors
      })
    }
  }

  _onChangePassword(password) {
    this.model['password'] = password.value
    this.setState({
      validationErrors: {},
      disableNextButton: !password.isMatching
    })
  }

  _onChangeExistingPassword(existingPassword) {
    this.model['existingPassword'] = existingPassword.target.value
  }

  render() {
    let error = <div />

    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }

    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <Breadcrumbs roles={this.props.roles}
                         profile={this.props.profile}
                         match={this.props.match}
            />
          </Col>
          <Col sm={8} lg={6}>
            {error}
            <Panel header='Change Password'>
              <Form nextButtonText='Reset'
                    nextHandler={(e) => {
                      this.props.nextHandler(e, this.model)
                    }}
                    nextButtonDisabled={this.state.disableNextButton}
                    backButtonText='Back'
                    backHandler={(e) => {
                      this.props.backHandler(e)
                    }}
              >
                <FormField label='Existing Password'
                           id='existingPassword'
                           type='password'
                           error={this.state.validationErrors.existingPassword}
                           value={this.model.existingPassword}
                           onChange={(e) => {
                             this._onChangeExistingPassword(e)
                           }}
                           mandatory
                />
                <Password error={this.state.validationErrors.password}
                          onChange={(e) => {
                            this._onChangePassword(e)
                          }}
                />
              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
