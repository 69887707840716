import React, { Component } from 'react'
import { Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import { config } from '../../services/http'

class Breadcrumbs extends Component {
  render() {
    const { profile, roles, match } = this.props
    const path = match.path

    return roles.includes('ADMIN') ? (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <LinkContainer to='/accounts'
                       isActive={() => path === '/accounts' || path === '/accounts/:accountId/edit'}
                       id='breadcrumbs-manage-account'
        >
          <NavItem>
            Manage accounts
          </NavItem>
        </LinkContainer>
        <LinkContainer to='/accounts/create'
                       isActive={() => path === '/accounts/create'}
                       id='breadcrumbs-new-account'
        >
          <NavItem>
            New account
          </NavItem>
        </LinkContainer>
        {config.auditsEnabled ? (
          <LinkContainer to='/audits'
                         id='breadcrumbs-audits'
          >
            <NavItem>
              Audit trail
            </NavItem>
          </LinkContainer>
        ) : null}
        <NavItem className='nav-item-divider' />
        <LinkContainer to={`/accounts/${profile.id}/password`}
                       isActive={() => path === '/accounts/:accountId/password'}
                       id='breadcrumbs-change-password'
        >
          <NavItem>
            Change password
          </NavItem>
        </LinkContainer>
      </Nav>
    ) : (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <LinkContainer to={`/accounts/${profile.id}/password`}
                       isActive={() => path === `/accounts/${profile.id}/password`}
                       id='breadcrumbs-change-password'
        >
          <NavItem>
            Change password
          </NavItem>
        </LinkContainer>
      </Nav>
    )
  }
}

export default Breadcrumbs
