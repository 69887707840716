import { constants } from './groupActions'

const initialState = {
  group: null,
  contentList: []
}

export default function groups(state = initialState, action = {}) {
  switch (action.type) {
    case constants.GROUP_FETCHING:
      return Object.assign({}, state, {
        status: 'LOADING'
      })
    case constants.GROUP_SAVING:
      return Object.assign({}, state, {
        group: action.group,
        status: 'SAVING'
      })
    case constants.GROUP_FETCHED:
    case constants.GROUP_SAVED:
    case constants.GROUP_NEW:
    case constants.GROUP_SUBMITTED:
    case constants.GROUP_REJECTED:
    case constants.GROUP_PUBLISHED:
    case constants.GROUP_PAUSED:
    case constants.GROUP_RESUMED:
    case constants.GROUP_EDITED:
      return Object.assign({}, state, {
        group: action.group,
        status: 'SUCCESS',
        error: null
      })
    case constants.GROUP_CONTENTS_FETCHING:
      return Object.assign({}, state, {
        contentList: []
      })
    case constants.GROUP_CONTENTS_FETCHED:
      return Object.assign({}, state, {
        contentList: action.contentList
      })
    case constants.GROUP_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        status: 'ERROR'
      })
    default:
      return state
  }
}
