import { push } from 'connected-react-router'
import _ from 'lodash'
import { preSelectedFilterTypeCheckboxes } from '../common/enums'

import http, { config, CONTENT_DETAIL_V1, CONTENT_FEATURED_TITLE_V1, CONTENT_LIST_V1, CONTENT_LIST_V2 } from '../services/http'
import { bannerActions } from '../banners/bannerActions'
import { offerActions } from '../offers/offerActions'
import { prizedrawActions } from '../prizedraws/prizedrawActions'
import { tourActions } from '../tours/tourActions'
import { groupActions } from '../groups/groupActions'
import { seriesActions } from '../series/seriesActions'
import { thankYouActions } from '../thankyous/thankYouActions'
import { articleActions } from '../articles/articleActions'
import { stockActions } from '../stock/stockActions'
import { commonActions } from '../common/commonActions'
import { campaignActions } from '../campaigns/campaignActions'

const navigateToContentList = () => {
  return push('/')
}

const navigateToContentItem = (contentItem, section = 'details', event) => {
  const url = `/${contentToRoute(contentItem)}/${contentItem.id}/${section}`
  if (event && (event.ctrlKey || event.metaKey)) {
    event.preventDefault()
    window.open(url)
    return () => ''
  } else {
    return push(url)
  }
}

const navigateToContentSelector = (contentItem, field, pathname, maxSelectableItems = 0) => {
  return (dispatch) => {
    const ids = contentItem[field] ? contentItem[field].ids || [] : []
    dispatch(selectCurrentContent(contentItem, ids, maxSelectableItems))
    dispatch(push(pathname))
  }
}

const navigateToSelectRelatedContent = (banner) => {
  return navigateToContentSelector(banner, 'relatedContent', '/select/related-content')
}

const navigateToSelectContentItems = (group) => {
  return navigateToContentSelector(group, 'contentItems', '/select/group-items')
}

const navigateToSelectSeriesItems = (series) => {
  return navigateToContentSelector(series, 'contentItems', '/select/series-items')
}

const navigateToSelectCalendarItems = (campaign) => {
  return navigateToContentSelector(campaign, 'contentId', '/select/calendar-items', 1)
}

const navigateToSelectBannerItems = (sectionHeader) => {
  return (dispatch) => {
      const ids = sectionHeader['contentId'] ?  [sectionHeader['contentId']] : []
      dispatch(selectCurrentContent(sectionHeader, ids, 1))
      dispatch(push('/select/banner-content'))
    }
}

const navigateToFeaturedContentsTitle = () => {
  return push('/contents/featured/title')
}

const navigateToOurPicks = () => {
  return push('/sort/our-picks')
}

const navigateToSelectLocations = (offer, offerLocations) => {
  return (dispatch) => {
    dispatch(selectCurrentContent(offer, offerLocations))
    dispatch(push('/select/locations'))
  }
}

export const contentToRoute = (contentItem) => {
  switch (contentItem.type) {
    case 'BANNER':
      return 'banners'
    case 'OFFER':
      return 'offers'
    case 'PRIZE_DRAW':
      return 'prizedraws'
    case 'EVENT':
      return 'events'
    case 'TOUR':
      return 'tours'
    case 'EVENT_DATE':
      return `tours/${contentItem.group.id}/eventDates`
    case 'GROUP':
      return 'groups'
    case 'SERIES':
      return 'series'
    case 'THANK_YOU':
      return 'thankyous'
    case 'CAMPAIGN':
      return 'campaigns'
    case 'MUSIC_BANNER':
      return 'musicBanners'
    case 'ARTICLE':
      return 'articles'
    default:
      return '404'
  }
}

const categorySaving = (contentList) => ({
  type: constants.CATEGORY_SAVING,
  contentList
})
const categorySaved = (contentList) => ({
  type: constants.CATEGORY_SAVED,
  contentList
})

const contentsFetching = (params) => ({
  type: constants.CONTENTS_FETCHING,
  params: params
})

const debouncedFetchContents = _.debounce((view, dispatch) => {
  dispatch(selectPage(1))
  dispatch(fetchContents(view))
}, 300)

const selectPage = (page) => ({
  type: constants.CONTENTS_SELECT_PAGE,
  page
})

const clearContents = () => ({
  type: constants.CONTENTS_CLEAR
})

const contentCopying = () => ({
  type: constants.CONTENTS_COPYING
})

const contentCopied = () => ({
  type: constants.CONTENTS_COPIED
})

const featuredContentsTitleFetching = () => ({
  type: constants.FEATURED_CONTENTS_TITLE_FETCHING
})

const featuredContentsTitleFetched = (featuredContentsTitle) => ({
  type: constants.FEATURED_CONTENTS_TITLE_FETCHED,
  featuredContentsTitle
})

const featuredContentsTitleSaving = () => ({
  type: constants.FEATURED_CONTENTS_TITLE_SAVING
})

const savedFeaturedContentsTile = (featuredContentsTitle) => ({
  type: constants.FEATURED_CONTENTS_TITLE_SAVED,
  featuredContentsTitle
})

const featuredContentsTitleValidationError = () => ({
  type: constants.FEATURED_CONTENTS_TITLE_ERROR
})

const featuredContentsTitleValidationErrors = (validationErrors) => ({
  type: constants.FEATURED_CONTENTS_TITLE_ERRORS,
  validationErrors
})

const fetchContents = (view, filters) => {
  return (dispatch, getState) => {
    const state = getState()
    const selectedFilters = state.contents.selectedFilters[view] || {}
    if (view === 'content' && state.contents.selectedFilters.content.state.length === 0) {
      selectedFilters.state = preSelectedFilterTypeCheckboxes
    }

    const params = _.cloneDeep(filters || {
      query: selectedFilters.search ? selectedFilters.search : undefined,
      type: selectedFilters.type,
      state: selectedFilters.state,
      limit: state.contents.limit,
      offset: state.contents.nextOffset
    })

    dispatch(contentsFetching(params))
    return http.get(dispatch, `${config.serverUrl}/contents`, params, {
      Accept: CONTENT_LIST_V2
    }).then((contents) => {
      if (state.contents.nextOffset <= 0) {
        dispatch(clearContents())
      }
      dispatch(contentsFetched(contents, params))
    }).catch((err) => {
      dispatch(clearContents())
      dispatch(contentError(`Unable to retrieve the content list: ${err.status} (${err.statusText})`))
    })
  }
}

const fetchCategoryContents = (category, view = 'sort', filters) => {
  return (dispatch) => {
    dispatch(contentsFetching({}))
    const url = `${config.serverUrl}/categories/${category}/contents`

    return http.get(dispatch, url, null, {
      Accept: CONTENT_LIST_V1
    }).then((contents) => {
      contents = contents.filter((content) => {
        return content.type !== 'CAMPAIGN' && !content.group && !content.thankYou
      })
      dispatch(contentsCategoryFetched({ contents, totalCount: contents.length }))
    }).catch((err) => {
      dispatch(contentError(`Unable to retrieve the content list: ${err.status} (${err.statusText})`))
    })
  }
}

const updateCategory = (categoryId, category, contentItems) => {
  return (dispatch) => {
    dispatch(categorySaving(contentItems))
    const url = `${config.serverUrl}/categories/${categoryId}`
    return http.put(dispatch, url, category, {
      Accept: CONTENT_LIST_V1
    }).then((contents) => {
      contents = contents.filter((content) => {
        return content.type !== 'CAMPAIGN' && !content.group && !content.thankYou
      })
      dispatch(categorySaved(contents))
    }).catch((err) => {
      dispatch(contentError(`Unable to update the category: ${err.status} (${err.statusText})`))
    })
  }
}

const contentsFetched = (contents, params) => {
  return {
    type: constants.CONTENTS_FETCHED,
    contentList: contents.contents,
    nextOffset: contents.nextOffset,
    params: params
  }
}

const contentsCategoryFetched = (contents) => {
  return {
    type: constants.CONTENTS_CATEGORY_FETCHED,
    contentList: contents.contents
  }
}

const setFilters = (view, filters, fetch = true) => {
  return (dispatch) => {
    dispatch({
      type: constants.CONTENT_SET_FILTERS,
      filters,
      view
    })

    if (!fetch) return
    dispatch(clearContents())
    dispatch(fetchContents(view))
  }
}

const setSearch = (view, search) => {
  return (dispatch) => {
    dispatch({
      type: constants.CONTENT_SET_SEARCH,
      search,
      view
    })
    debouncedFetchContents(view, dispatch)
  }
}

const contentError = (error) => {
  return {
    type: constants.CONTENT_ERROR,
    error: error
  }
}

const selectCurrentContent = (currentContent, ids = [], maxSelectableVal = 0) => {
  return {
    type: constants.CONTENT_SELECT_CURRENT_CONTENT,
    currentContent: currentContent,
    selectedItems: ids,
    maxSelectable: maxSelectableVal
  }
}

const toggleSelected = (contentItem) => {
  return {
    type: constants.CONTENT_TOGGLE_SELECTED,
    contentItem
  }
}

const toggleAllSelected = (items, selected) => {
  return {
    type: constants.CONTENT_TOGGLE_ALL_SELECTED,
    items,
    selected
  }
}

const setSelected = (selectedItems) => {
  return {
    type: constants.CONTENT_SET_SELECTED,
    selectedItems
  }
}

const saveContent = (contentItem) => {
  return (dispatch) => {
    switch (contentItem.type) {
      case 'BANNER':
        return dispatch(bannerActions.saveBanner(contentItem))
      case 'OFFER':
        return dispatch(offerActions.saveOffer(contentItem))
      case 'PRIZE_DRAW':
        return dispatch(prizedrawActions.savePrizeDraw(contentItem))
      case 'TOUR':
        return dispatch(tourActions.saveTour(contentItem))
      case 'GROUP':
        return dispatch(groupActions.saveGroup(contentItem))
      case 'SERIES':
        return dispatch(seriesActions.saveSeries(contentItem))
      case 'THANK_YOU':
        return dispatch(thankYouActions.saveThankYou(contentItem))
      case 'ARTICLE':
        return dispatch(articleActions.saveArticle(contentItem))
      case 'CAMPAIGN':
        return dispatch(campaignActions.saveCampaign(contentItem))
    }
  }
}

const navigateToCopyContent = (contentItem) => {
  return (dispatch, getState) => {
    let ids = []
    if (contentItem.type === 'GROUP') {
      ids = getState().groups.contentList.map(contentItem => _.pick(contentItem, ['id', 'type']))
    }
    dispatch(selectCurrentContent(contentItem, ids))
    dispatch(push('/copy'))
  }
}

const copyContent = (contentItem, sections, selectedItems = []) => {
  return (dispatch) => {
    const body = {
      sections,
      ids: selectedItems
    }
    dispatch(contentCopying(contentItem))
    var copyContentRequestPromise = http.post(dispatch, `${config.serverUrl}/contents/${contentItem.id}/copy`, body, {
      Accept: CONTENT_DETAIL_V1
    })
    if (contentItem.type === 'OFFER') {
      copyContentRequestPromise = copyContentRequestPromise.then((resp) => {
        if (typeof (resp) === 'undefined') {
          return resp
        }
        return stockActions.updateVolumeRequest(dispatch, resp.id, contentItem.stock.limit, contentItem.stock.threshold, 0).then(() => {
          return resp
        })
      })
    }
    copyContentRequestPromise = copyContentRequestPromise.then((resp) => {
      dispatch(contentCopied(resp))
      dispatch(navigateToContentItem(resp))
    }).catch((err) => {
      dispatch(contentError(`Unable to copy: ${err.status} (${err.statusText})`))
    })
    return copyContentRequestPromise
  }
}

const fetchFeaturedContentsTitle = () => {
  return (dispatch) => {
    dispatch(featuredContentsTitleFetching())
    const url = `${config.serverUrl}/contents/featured/title`
    return http.get(dispatch, url, null, {
      Accept: CONTENT_FEATURED_TITLE_V1
    }).then((featuredContentsTitle) => {
      dispatch(featuredContentsTitleFetched(featuredContentsTitle))
    }).catch((err) => {
      dispatch(featuredContentsTitleValidationError())
      dispatch(commonActions.displayFlash(`Unable to fetch Featured Contents Title: ${err.status} (${err.statusText})`, 'error'))
    })
  }
}

const saveFeaturedContentsTitle = (payload) => {
  return (dispatch) => {
    dispatch(featuredContentsTitleSaving())
    const url = `${config.serverUrl}/contents/featured/title`
    return http.post(dispatch, url, payload, {
      Accept: CONTENT_FEATURED_TITLE_V1
    }).then((featuredContentsTitle) => {
      dispatch(savedFeaturedContentsTile(featuredContentsTitle))
      dispatch(commonActions.displayFlash('Title has been saved.'))
    }).catch(handleFeaturedContentsErrors(dispatch))
  }
}

const handleFeaturedContentsErrors = (dispatch) => {
  return (response) => {
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(featuredContentsTitleValidationErrors(json.validationErrors))
    } else {
      dispatch(featuredContentsTitleValidationError())
      dispatch(commonActions.displayFlash(`Unable to save Featured Contents Title: ${response.status} (${response.statusText})`, 'error'))
    }
  }
}

const fetchCalendarContent = (contentItem) => {
  return (dispatch) => {
    switch (contentItem.type) {
      case 'CAMPAIGN':
        return dispatch(campaignActions.fetchCalendarContent())
      case 'BANNER':
        return dispatch(bannerActions.fetchCalendarContent())
    }
  }
}

export const constants = {
  CONTENT_ERROR: 'CONTENT_ERROR',
  CONTENTS_FETCHING: 'CONTENTS_FETCHING',
  CONTENTS_FETCHED: 'CONTENTS_FETCHED',
  CONTENTS_CATEGORY_FETCHED: 'CONTENTS_CATEGORY_FETCHED',
  CONTENTS_COPYING: 'CONTENTS_COPYING',
  CONTENTS_COPIED: 'CONTENTS_COPIED',
  CONTENT_TOGGLE_SELECTED: 'CONTENT_TOGGLE_SELECTED',
  CONTENT_TOGGLE_ALL_SELECTED: 'CONTENT_TOGGLE_ALL_SELECTED',
  CONTENT_SET_SELECTED: 'CONTENT_SET_SELECTED',
  CONTENT_TOGGLE_ALL: 'CONTENT_TOGGLE_ALL',
  CONTENT_SELECT_CURRENT_CONTENT: 'CONTENT_SELECT_CURRENT_CONTENT',
  CONTENT_SET_FILTERS: 'CONTENT_SET_FILTERS',
  CONTENT_SET_SEARCH: 'CONTENT_SET_SEARCH',
  CATEGORY_SAVING: 'CATEGORY_SAVING',
  CATEGORY_SAVED: 'CATEGORY_SAVED',
  CONTENTS_SELECT_PAGE: 'CONTENTS_SELECT_PAGE',
  CONTENTS_CLEAR: 'CONTENTS_CLEAR',
  FEATURED_CONTENTS_TITLE_FETCHING: 'FEATURED_CONTENTS_TITLE_FETCHING',
  FEATURED_CONTENTS_TITLE_FETCHED: 'FEATURED_CONTENTS_TITLE_FETCHED',
  FEATURED_CONTENTS_TITLE_SAVED: 'FEATURED_CONTENTS_TITLE_SAVED',
  FEATURED_CONTENTS_TITLE_SAVING: 'FEATURED_CONTENTS_TITLE_SAVING',
  FEATURED_CONTENTS_TITLE_ERROR: 'FEATURED_CONTENTS_TITLE_ERROR',
  FEATURED_CONTENTS_TITLE_ERRORS: 'FEATURED_CONTENTS_TITLE_ERRORS'
}

export const contentActions = {
  navigateToContentList,
  navigateToContentItem,
  navigateToSelectRelatedContent,
  navigateToSelectContentItems,
  navigateToSelectLocations,
  navigateToSelectSeriesItems,
  navigateToSelectCalendarItems,
  navigateToSelectBannerItems,
  navigateToCopyContent,
  fetchContents,
  fetchCategoryContents,
  updateCategory,
  contentError,
  toggleSelected,
  toggleAllSelected,
  setSelected,
  selectCurrentContent,
  saveContent,
  copyContent,
  setFilters,
  setSearch,
  selectPage,
  clearContents,
  fetchFeaturedContentsTitle,
  featuredContentsTitleFetched,
  featuredContentsTitleFetching,
  navigateToFeaturedContentsTitle,
  saveFeaturedContentsTitle,
  savedFeaturedContentsTile,
  navigateToOurPicks,
  fetchCalendarContent
}
