import { push } from 'connected-react-router'
import http, { ARTICLE_DETAILS_V1, CONTENT_LIST_V1, config } from '../services/http'

export const ctaDefaultUrlType = 'URL'

const articleError = (error) => ({
  type: constants.ARTICLE_ERROR,
  error
})

const articleAdded = (article) => ({
  type: constants.ARTICLE_SAVED,
  article
})

const createArticle = (article) => {
  return (dispatch) => {
    return http.post(dispatch, `${config.serverUrl}/articles/`, article, {
      Accept: ARTICLE_DETAILS_V1
    }).then((resp) => {
      dispatch(articleAdded(resp))
      return resp
    }).catch((err) => {
      dispatch(articleError(`Unable to create the new article: ${err.status} (${err.statusText})`))
    })
  }
}

const articleSaving = (article) => ({
  type: constants.ARTICLE_SAVING,
  article
})
const articleSaved = (article) => ({
  type: constants.ARTICLE_SAVED,
  article
})

const updateArticle = (article) => {
  return (dispatch) => {
    return http.put(dispatch, `${config.serverUrl}/articles/${article.id}`, article, {
      Accept: ARTICLE_DETAILS_V1
    }).then((resp) => {
      dispatch(articleSaved(resp))
      return resp
    }).catch((err) => {
      dispatch(articleError(`Unable to update the article: ${err.status} (${err.statusText})`))
    })
  }
}

const articleFetching = (articleId) => ({
  type: constants.ARTICLE_FETCHING,
  articleId
})

const articleFetched = (article) => ({
  type: constants.ARTICLE_FETCHED,
  article
})

const fetchArticle = (articleId) => {
  return (dispatch) => {
    dispatch(articleFetching(articleId))
    return http.get(dispatch, `${config.serverUrl}/articles/${articleId}`, null, {
      Accept: ARTICLE_DETAILS_V1
    }).then((resp) => {
      dispatch(articleFetched(resp))
      return resp
    }).catch((err) => {
      dispatch(articleError(`Unable to retrieve the article with id ${articleId}: ${err.status} (${err.statusText})`))
    })
  }
}

const relatedContentFetching = () => ({
  type: constants.ARTICLE_RELATED_CONTENT_FETCHING
})
const relatedContentFetched = (relatedContent) => ({
  type: constants.ARTICLE_RELATED_CONTENT_FETCHED,
  relatedContent
})
const fetchRelatedContent = (articleId) => {
  return (dispatch) => {
    dispatch(relatedContentFetching())
    return http.get(dispatch, `${config.serverUrl}/articles/${articleId}/relatedContent`, null, {
      Accept: CONTENT_LIST_V1
    }).then((resp) => {
      dispatch(relatedContentFetched(resp))
    }).catch((err) => {
      dispatch(articleError(`Unable to fetch the article related content: ${err.status} (${err.statusText})`))
    })
  }
}

const articleSubmitted = (article) => ({
  type: constants.ARTICLE_SUBMITTED,
  article
})
const articleRejected = (article) => ({
  type: constants.ARTICLE_REJECTED,
  article
})
const articlePublished = (article) => ({
  type: constants.ARTICLE_PUBLISHED,
  article
})
const articlePaused = (article) => ({
  type: constants.ARTICLE_PAUSED,
  article
})
const articleResumed = (article) => ({
  type: constants.ARTICLE_RESUMED,
  article
})
const articleEdited = (article) => ({
  type: constants.ARTICLE_EDITED,
  article
})

const updateArticleStatus = (article, status, successAction) => {
  return (dispatch) => {
    dispatch(articleSaving(article))
    return http.post(dispatch, `${config.serverUrl}/articles/${article.id}/${status}`, null, {
      Accept: ARTICLE_DETAILS_V1
    }).then((resp) => {
      dispatch(successAction(resp))
    }).catch((err) => {
      dispatch(articleError(`Unable to ${status} article: ${err.status} (${err.statusText})`))
    })
  }
}

const submitArticle = (article) => updateArticleStatus(article, 'submit', articleSubmitted)
const rejectArticle = (article) => updateArticleStatus(article, 'reject', articleRejected)
const publishArticle = (article) => updateArticleStatus(article, 'publish', articlePublished)
const pauseArticle = (article) => updateArticleStatus(article, 'pause', articlePaused)
const resumeArticle = (article) => updateArticleStatus(article, 'resume', articleResumed)
const editArticle = (article) => updateArticleStatus(article, 'draft', articleEdited)

const newArticle = () => ({
  type: constants.ARTICLE_NEW,
  article: {
    type: 'ARTICLE',
    details: {
      subtype: 'BLOG',
      channels: ['PRIORITY'],
      theme: 'NONE'
    },
    schedule: {},
    callToAction: {
      urlType: ctaDefaultUrlType
    }
  }
})

const saveArticle = (article) => {
  if (!article.id) {
    return createArticle(article)
  } else {
    return updateArticle(article)
  }
}

const navigateToNewOurPicksTrigger = (article) => {
  const index = article.schedule && article.schedule.triggers ? article.schedule.triggers.length : 0
  return push(`/articles/${article.id}/triggers/${index}`)
}

const navigateToEditOurPicksTrigger = (article, index) => {
  return push(`/articles/${article.id}/triggers/${index}`)
}

const navigateToSchedule = (articleId) => {
  return push(`/articles/${articleId}/schedule`)
}

export const constants = {
  ARTICLE_ERROR: 'ARTICLE_ERROR',
  ARTICLE_NEW: 'ARTICLE_NEW',
  ARTICLE_SAVING: 'ARTICLE_SAVING',
  ARTICLE_SAVED: 'ARTICLE_SAVED',
  ARTICLE_FETCHING: 'ARTICLE_FETCHING',
  ARTICLE_FETCHED: 'ARTICLE_FETCHED',
  ARTICLE_SUBMITTED: 'ARTICLE_SUBMITTED',
  ARTICLE_REJECTED: 'ARTICLE_REJECTED',
  ARTICLE_PUBLISHED: 'ARTICLE_PUBLISHED',
  ARTICLE_PAUSED: 'ARTICLE_PAUSED',
  ARTICLE_RESUMED: 'ARTICLE_RESUMED',
  ARTICLE_EDITED: 'ARTICLE_EDITED',
  ARTICLE_RELATED_CONTENT_FETCHING: 'ARTICLE_RELATED_CONTENT_FETCHING',
  ARTICLE_RELATED_CONTENT_FETCHED: 'ARTICLE_RELATED_CONTENT_FETCHED'
}

export const articleActions = {
  newArticle,
  createArticle,
  fetchArticle,
  saveArticle,
  articleSaving,
  articleError,
  submitArticle,
  rejectArticle,
  publishArticle,
  pauseArticle,
  resumeArticle,
  editArticle,
  fetchRelatedContent,
  updateArticleStatus,
  navigateToNewOurPicksTrigger,
  navigateToEditOurPicksTrigger,
  navigateToSchedule
}
