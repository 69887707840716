import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'

const StatusToolbar = ({
  status,
  dirty,
  contentComplete,
  editDisabled,
  publishDisabled,
  resumeDisabled,
  isLiveEdit,
  onSave,
  onSubmit,
  onPublish,
  onReject,
  onEdit,
  onPause,
  onResume,
  disabledStateInEffect,
  onDisable,
  onEnable
}) => {
  const showSave = status === 'DRAFT' || dirty
  const showSubmit = status === 'DRAFT'
  const showPause = isLiveEdit || status === 'PUBLISHED' || status === 'LIVE'
  const showEdit = status && status !== 'DRAFT'
  const showResume = status === 'PAUSED' && !dirty
  const showApproval = status === 'PENDING_APPROVAL'
  const saveDisabled = !dirty
  const submitDisabled = dirty || !contentComplete

  return (
    <ButtonToolbar className='status-toolbar'>
      {showSave ? (
        <Button id='status-toolbar-save' bsStyle='primary' onClick={onSave} disabled={saveDisabled}>Save</Button>
      ) : null}
      {showSubmit ? (
        <Button id='status-toolbar-submit' bsStyle='primary' onClick={onSubmit} disabled={submitDisabled}>Submit for approval</Button>
      ) : null}
      {showEdit ? (
        <Button id='status-toolbar-edit' bsStyle='default' onClick={onEdit} disabled={editDisabled}>Edit</Button>
      ) : null}
      {showApproval ? (
        <Button id='status-toolbar-publish' bsStyle='success' onClick={onPublish} disabled={publishDisabled}>Publish</Button>
      ) : null}
      {showApproval ? (
        <Button id='status-toolbar-reject' bsStyle='danger' onClick={onReject}>Reject</Button>
      ) : null}
      {showPause ? (
        <Button id='status-toolbar-pause' bsStyle='danger' onClick={onPause}>Pause</Button>
      ) : null}
      {showResume ? (
        <Button id='status-toolbar-resume' bsStyle='success' onClick={onResume} disabled={resumeDisabled}>Resume</Button>
      ) : null}
      {disabledStateInEffect === false ? (
        <Button id='status-toolbar-disable' className='btn-disable' onClick={onDisable}>Disable</Button>
      ) : null}
      {disabledStateInEffect === true ? (
        <Button id='status-toolbar-enable' className='btn-enable' onClick={onEnable}>Enable</Button>
      ) : null}
    </ButtonToolbar>
  )
}

export default StatusToolbar
