class AppConstants {
  constructor() {
    this.DEFAULT_DISPLAY_PAGE_NO = 0
    this.DEFAULT_ROWS_PER_PAGE = 30
    this.WINNER_FILE_NAME = 'Winners_&id&.xlsx'
    this.NON_WINNER_FILE_NAME = 'Non_Winning_Entrants_&id&.xlsx'
  }
}

export default (new AppConstants())
