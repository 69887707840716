import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import FormField from '../../common/form/formField'
import ImageSelector from '../../common/media/ImageSelector'

const initialState = {
  brandName: '',
  logoImageUrl: null,
  uploading: false,
  imageError: null
}

class AddBrandModal extends Component {
  constructor(props) {
    super(props)

    this.state = { ...initialState }
  }

  uploadFile(file) {
    this.setState({ uploading: true })
    this.props.uploadImage('BRAND_LOGO', file).then((image) => {
      this.setState({ logoImageUrl: image.url, uploading: false, imageError: null })
    }).catch(err => {
      this.setState({ imageError: err, uploading: false })
    })
  }

  updateField(path, value) {
    return (e) => {
      this.setState({ [path]: value, uploading: false, imageError: null })
    }
  }

  close() {
    this.setState(initialState)
    this.props.onClose()
  }

  save() {
    this.props.createBrand({
      name: this.state.brandName,
      logoImageUrl: this.state.logoImageUrl
    }).then(() => {
      this.close()
    }).catch(() => {
    })
  }

  render() {
    const validationErrors = this.props.validationErrors || {}
    const disabled = !this.state.brandName

    return (
      <Modal show={this.props.visible} onHide={() => this.close()}>
        <Modal.Body>
          <h4>Add brand</h4>
          <FormField label='Name' id='name' mandatory
                     onChange={(e) => this.setState({ brandName: e.target.value })}
                     value={this.state.brandName}
                     error={validationErrors.brandName}
          />

          <ImageSelector label='Logo Image'
                         type='logo'
                         mediaTarget='BRAND_LOGO'
                         mandatory
                         defaultImageProperty='logoImageUrl'
                         parentMediaProperty='logoImageUrl'
                         width={320} height={320}
                         model={{ logoImageUrl: this.state.logoImageUrl, validationErrors }}
                         updateParentField={this.updateField.bind(this)}
                         disabled={disabled} />

        </Modal.Body>
        <Modal.Footer>
          <Button id='cancel' onClick={() => this.close()}>Cancel</Button>
          <Button id='save' bsStyle='primary'
                  onClick={() => this.save()}
                  disabled={disabled}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default AddBrandModal
