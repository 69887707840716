import _ from 'lodash'
import React, { Component } from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'

import AppError from '../../common/components/appError'
// import SelectContentTable from '../../content/components/_selectContentTable'
import { contentTypes } from '../../common/enums'
import { CopyBanner } from './copyBanner'
import CopyCampaign from './copyCampaign'
import CopyEvent from './copyEvent'
import CopyOffer from './copyOffer'
import CopyPrizeDraw from './copyPrizeDraw'

const CopyContentForm = {
  OFFER: CopyOffer,
  PRIZE_DRAW: CopyPrizeDraw,
  EVENT: CopyEvent,
  CAMPAIGN: CopyCampaign,
  BANNER: CopyBanner
}

const defaults = {
  OFFER: {
    details: true,
    alternateTitles: true,
    assets: true,
    redemption: true,
    locations: true
  },
  PRIZE_DRAW: {
    details: true,
    assets: true
  },
  EVENT: {
    details: true,
    assets: true,
    schedule: true,
    relatedContent: true
  },
  // TODO check why groups are not being copied and cleanup code here and elsewhere
  // GROUP: {
  //   details: true,
  //   assets: true,
  //   redemption: true,
  //   locations: true
  // },
  CAMPAIGN: {
    details: true,
    alternateTitles: true,
    assets: true,
    callToAction: true
  },
  BANNER: {
    details: true,
    alternateTitles: true,
    assets: true
  }
}

export default class CopyContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: defaults[props.currentContent.type]
    }
  }

  toggleSection(section) {
    const sections = { ...this.state.sections }
    sections[section] = !sections[section]

    this.setState({ sections })
  }

  noSectionSelected() {
    return _.findIndex(_.values(this.state.sections), (v) => {
      return v === true
    }) === -1
  }

  toggleSelectedItem(contentItem) {
    this.props.toggleSelected(contentItem)
  }

  render() {
    const contentItem = this.props.currentContent
    // const contentList = this.props.groupsContentList
    const disabled = this.props.status === 'SAVING' || this.noSectionSelected()

    const CopyForm = CopyContentForm[contentItem.type]

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1 className='content-heading'>Copy {contentTypes[contentItem.type]}</h1>
          </div>
          <div className='page-header--tools'>
            <ButtonToolbar>
              <Button id='copy-content-cancel'
                      onClick={() => this.props.backToContentItem(this.props.currentContent)}
              >
                Cancel
              </Button>
              <Button id='copy-content-button'
                      bsStyle='primary'
                      onClick={() => this.props.onCopy(this.props.currentContent, this.state.sections, this.props.selectedItems)}
                      disabled={disabled}
              >
                Copy
              </Button>
            </ButtonToolbar>
          </div>
        </div>
        {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
        <CopyForm sections={this.state.sections}
                  onToggleSection={(section) => this.toggleSection(section)}
        />
        {
          // Groups are not currently copyable
          // {contentItem.type === 'GROUP' ? (
          //   <div>
          //     <p>Which group items do you want to copy?</p>
          //     <SelectContentTable
          //       items={contentList}
          //       selectedItems={this.props.selectedItems}
          //       onToggleSelected={(contentItem) => this.toggleSelectedItem(contentItem)}
          //     />
          //   </div>
          // ) : null}
        }
      </div>
    )
  }
}
