import { constants } from './faqActions'

const initialState = {}

export default function faq(state = initialState, action = {}) {
  switch (action.type) {
    case constants.FAQ_SECTIONS_FETCHING:
      return Object.assign({}, state, {
        faqSections: [],
        error: '',
        faqSectionsStatus: 'LOADING'
      })
    case constants.FAQ_SECTIONS_FETCHED:
      return Object.assign({}, state, {
        faqSections: action.faqSections,
        validationErrors: {},
        error: '',
        faqSectionsStatus: 'SUCCESS'
      })

    case constants.FAQ_SECTION_FETCHING:
      return Object.assign({}, state, {
        faqSection: {},
        validationErrors: {},
        error: '',
        faqSectionStatus: 'LOADING'
      })
    case constants.FAQ_SECTION_FETCHED:
      return Object.assign({}, state, {
        faqSection: action.faqSection,
        validationErrors: {},
        error: '',
        faqSectionStatus: 'SUCCESS'
      })
    case constants.FAQ_SECTION_NEW:
      return Object.assign({}, state, {
        faqSection: action.faqSection,
        validationErrors: {},
        error: '',
        faqSectionStatus: 'SUCCESS'
      })
    case constants.FAQ_SECTION_SAVING:
      return Object.assign({}, state, {
        error: '',
        faqSectionStatus: 'LOADING'
      })
    case constants.FAQ_SECTION_CREATED:
      return Object.assign({}, state, {
        faqSection: action.faqSection,
        error: '',
        validationErrors: {},
        faqSectionStatus: 'SUCCESS'
      })
    case constants.FAQ_SECTION_UPDATED:
      return Object.assign({}, state, {
        faqSection: action.faqSection,
        error: '',
        validationErrors: {},
        faqSectionStatus: 'SUCCESS'
      })
    case constants.FAQ_SECTION_UPDATE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        faqSectionsStatus: 'FAILED',
        faqSectionStatus: 'FAILED'
      })
    case constants.FAQ_SECTION_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        faqSectionsStatus: 'FAILED',
        faqSectionStatus: 'FAILED'
      })

    case constants.FAQS_FETCHING:
      return Object.assign({}, state, {
        faqs: [],
        error: '',
        faqsStatus: 'LOADING'
      })
    case constants.FAQS_FETCHED:
      return Object.assign({}, state, {
        faqs: action.faqs,
        validationErrors: {},
        error: '',
        faqsStatus: 'SUCCESS'
      })

    case constants.FAQ_FETCHING:
      return Object.assign({}, state, {
        faq: {},
        validationErrors: {},
        error: '',
        faqStatus: 'LOADING'
      })
    case constants.FAQ_FETCHED:
      return Object.assign({}, state, {
        faq: action.faq,
        validationErrors: {},
        error: '',
        faqStatus: 'SUCCESS'
      })
    case constants.FAQ_NEW:
      return Object.assign({}, state, {
        faq: action.faq,
        validationErrors: {},
        error: '',
        faqStatus: 'SUCCESS'
      })
    case constants.FAQ_SAVING:
      return Object.assign({}, state, {
        error: '',
        faqStatus: 'LOADING'
      })
    case constants.FAQ_CREATED:
      const faqSection = state.faqSection
      faqSection.faqs.push(action.faq)
      return Object.assign({}, state, {
        faqSection: faqSection,
        error: '',
        validationErrors: {},
        faqStatus: 'SUCCESS'
      })
    case constants.FAQ_UPDATED:
      return Object.assign({}, state, {
        faq: action.faq,
        error: '',
        validationErrors: {},
        faqStatus: 'SUCCESS'
      })
    case constants.FAQ_UPDATE_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        faqStatus: 'FAILED',
        faqsStatus: 'FAILED'
      })
    case constants.FAQ_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: action.validationErrors,
        faqStatus: 'FAILED',
        faqsStatus: 'FAILED'
      })

    case constants.FAQS_SORT_ORDER_SAVING:
      return Object.assign({}, state, {
        error: action.error,
        faqsStatus: action.status,
        faqs: action.faqs
      })
    case constants.FAQS_SORT_ORDER_SAVED:
      return Object.assign({}, state, {
        faqs: action.faqs,
        faqsStatus: 'SUCCESS',
        validationErrors: {}
      })
    default:
      return state
  }
}
