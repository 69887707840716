import { connect } from 'react-redux'

import { venueActions } from '../venueActions'
import Listing from '../components/venueListing'

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(venueActions.fetchVenues())
    },
    newVenue: () => {
      dispatch(venueActions.navigateToNewVenue())
    },
    onVenueClick: (venue) => {
      dispatch(venueActions.navigateToVenueDetails(venue.id))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    venues: state.venues.venues,
    flash: state.common.flash,
    status: state.venues.venuesStatus
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Listing)
