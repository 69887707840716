import http, { config, EVENT_DATE_DETAILS_V1, VENUE_LIST_V1 } from '../services/http'

const createEventDate = (tourId, eventDate) => {
  return (dispatch) => {
    dispatch(eventDateSaving(eventDate))
    return http.post(dispatch, `${config.serverUrl}/tours/${tourId}/eventDates/`, eventDate, {
      Accept: EVENT_DATE_DETAILS_V1
    }).then((resp) => {
      dispatch(eventDateAdded(resp))
      return resp
    }).catch((error) => {
      dispatch(eventDateError(`Unable to create the new eventDate: ${error.status} (${error.statusText})`))
    })
  }
}

const updateEventDate = (tourId, eventDate) => {
  return (dispatch) => {
    dispatch(eventDateSaving(eventDate))
    return http.put(dispatch, `${config.serverUrl}/tours/${tourId}/eventDates/${eventDate.id}`, eventDate, {
      Accept: EVENT_DATE_DETAILS_V1
    }).then((resp) => {
      dispatch(eventDateSaved(resp))
      return resp
    }).catch((error) => {
      dispatch(eventDateError(`Unable to update the eventDate: ${error.status} (${error.statusText})`))
    })
  }
}

const saveEventDate = (tourId, eventDate) => {
  if (!eventDate.id) {
    return createEventDate(tourId, eventDate)
  } else {
    return updateEventDate(tourId, eventDate)
  }
}

const eventDateAdded = (eventDate) => ({
  type: constants.EVENT_DATE_SAVED,
  eventDate
})

const newEventDate = () => {
  return (dispatch, getState) => {
    const eventDates = getState().tours.eventDates
    const lastEventDate = eventDates[eventDates.length - 1] || {}

    return dispatch({
      type: constants.EVENT_DATE_NEW,
      eventDate: {
        type: 'EVENT_DATE',
        details: {},
        schedule: lastEventDate.schedule || undefined
      }
    })
  }
}

const fetchEventDate = (tourId, id) => {
  return (dispatch) => {
    dispatch(eventDateFetching(id))
    return http.get(dispatch, `${config.serverUrl}/tours/${tourId}/eventDates/${id}`, null, {
      Accept: EVENT_DATE_DETAILS_V1
    }).then((eventDate) => {
      dispatch(eventDateFetched(eventDate))
    }).catch((error) => {
      dispatch(eventDateError(`Unable to retrieve the eventDate with id ${id}: ${error.status} (${error.statusText})`))
    })
  }
}

const fetchShortVenues = () => {
  return (dispatch) => {
    dispatch(venuesFetching())
    const params = {
      short: true,
      'status[]': 'OPEN'
    }
    return http.get(dispatch, `${config.serverUrl}/venues`, params, {
      Accept: VENUE_LIST_V1
    }).then((shortVenues) => {
      const venueOptions = extractVenueNameAndIds(shortVenues)
      dispatch(venuesFetched(venueOptions))
    }).catch((error) => {
      dispatch(eventError(`Unable to retrieve the venues : ${error.status} (${error.statusText})`))
    })
  }
}

const extractVenueNameAndIds = (shortVenues) => {
  return shortVenues.reduce(function(result, venue) {
    return result.concat({ label: venue.name + ', ' + venue.city, value: venue.id })
  }, [])
}
const venuesFetching = () => ({
  type: constants.VENUES_FETCHING
})
const venuesFetched = (shortVenues) => ({
  type: constants.VENUES_FETCHED,
  shortVenues
})

const eventDateSaving = (eventDate) => ({
  type: constants.EVENT_DATE_SAVING,
  eventDate
})
const eventDateSaved = (eventDate) => ({
  type: constants.EVENT_DATE_SAVED,
  eventDate
})
const eventDateFetching = (eventDateId) => ({
  type: constants.EVENT_DATE_FETCHING,
  eventDateId
})
const eventDateFetched = (eventDate) => ({
  type: constants.EVENT_DATE_FETCHED,
  eventDate
})
const eventDateError = (error) => ({
  type: constants.EVENT_DATE_ERROR,
  error: error
})

const eventDateSubmitted = (eventDate) => ({
  type: constants.EVENT_DATE_SUBMITTED,
  eventDate
})
const eventDateRejected = (eventDate) => ({
  type: constants.EVENT_DATE_REJECTED,
  eventDate
})
const eventDatePublished = (eventDate) => ({
  type: constants.EVENT_DATE_PUBLISHED,
  eventDate
})
const eventDatePaused = (eventDate) => ({
  type: constants.EVENT_DATE_PAUSED,
  eventDate
})
const eventDateResumed = (eventDate) => ({
  type: constants.EVENT_DATE_RESUMED,
  eventDate
})
const eventDateEdited = (eventDate) => ({
  type: constants.EVENT_DATE_EDITED,
  eventDate
})
const eventDateDisabled = (eventDate) => ({
  type: constants.EVENT_DATE_DISABLED,
  eventDate
})
const eventDateEnabled = (eventDate) => ({
  type: constants.EVENT_DATE_ENABLED,
  eventDate
})

const updateEventDateStatus = (tourId, eventDate, status, successAction) => {
  return (dispatch) => {
    dispatch(eventDateSaving(eventDate))
    return http.post(dispatch, `${config.serverUrl}/tours/${tourId}/eventDates/${eventDate.id}/${status}`, null, {
      Accept: EVENT_DATE_DETAILS_V1
    }).then((resp) => {
      dispatch(successAction(resp))
    }).catch((error) => {
      dispatch(eventDateError(`Unable to ${status} eventDate: ${error.status} (${error.statusText})`))
    })
  }
}

const submitEventDate = (tourId, eventDate) => updateEventDateStatus(tourId, eventDate, 'submit', eventDateSubmitted)
const rejectEventDate = (tourId, eventDate) => updateEventDateStatus(tourId, eventDate, 'reject', eventDateRejected)
const publishEventDate = (tourId, eventDate) => updateEventDateStatus(tourId, eventDate, 'publish', eventDatePublished)
const pauseEventDate = (tourId, eventDate) => updateEventDateStatus(tourId, eventDate, 'pause', eventDatePaused)
const resumeEventDate = (tourId, eventDate) => updateEventDateStatus(tourId, eventDate, 'resume', eventDateResumed)
const editEventDate = (tourId, eventDate) => updateEventDateStatus(tourId, eventDate, 'draft', eventDateEdited)
const disableEventDate = (tourId, eventDate) => updateEventDateStatus(tourId, eventDate, 'disable', eventDateDisabled)
const enableEventDate = (tourId, eventDate) => updateEventDateStatus(tourId, eventDate, 'enable', eventDateEnabled)

const updateSoldOut = (tourId, eventDate, props) => {
  return (dispatch) => {
    dispatch(eventDateSaving(eventDate))
    return http.post(dispatch, `${config.serverUrl}/tours/${tourId}/eventDates/${eventDate.id}/soldOut`, props, {
      Accept: EVENT_DATE_DETAILS_V1
    }).then((resp) => {
      dispatch(eventDateSaved(resp))
    }).catch((error) => {
      dispatch(eventDateError(`Unable to sell out eventDate: ${error.status} (${error.statusText})`))
    })
  }
}

const updateCancelled = (tourId, eventDate, props) => {
  return (dispatch) => {
    dispatch(eventDateSaving(eventDate))
    return http.post(dispatch, `${config.serverUrl}/tours/${tourId}/eventDates/${eventDate.id}/cancel`, props, {
      Accept: EVENT_DATE_DETAILS_V1
    }).then((resp) => {
      dispatch(eventDateSaved(resp))
    }).catch((error) => {
      dispatch(eventDateError(`Unable to cancel eventDate: ${error.status} (${error.statusText})`))
    })
  }
}

export const constants = {
  EVENT_DATE_ERROR: 'EVENT_DATE_ERROR',
  EVENT_DATE_NEW: 'EVENT_DATE_NEW',
  EVENT_DATE_FETCHING: 'EVENT_DATE_FETCHING',
  EVENT_DATE_FETCHED: 'EVENT_DATE_FETCHED',
  EVENT_DATE_SAVING: 'EVENT_DATE_SAVING',
  EVENT_DATE_SAVED: 'EVENT_DATE_SAVED',
  EVENT_DATE_SUBMITTED: 'EVENT_DATE_SUBMITTED',
  EVENT_DATE_REJECTED: 'EVENT_DATE_REJECTED',
  EVENT_DATE_PUBLISHED: 'EVENT_DATE_PUBLISHED',
  EVENT_DATE_PAUSED: 'EVENT_DATE_PAUSED',
  EVENT_DATE_RESUMED: 'EVENT_DATE_RESUMED',
  EVENT_DATE_EDITED: 'EVENT_DATE_EDITED',
  EVENT_DATE_UPLOAD_IMAGE_ERROR: 'EVENT_DATE_UPLOAD_IMAGE_ERROR',
  EVENT_DATE_DISABLED: 'EVENT_DATE_DISABLED',
  EVENT_DATE_ENABLED: 'EVENT_DATE_ENABLED',
  VENUES_FETCHING: 'VENUES_FETCHING',
  VENUES_FETCHED: 'VENUES_FETCHED'
}

export const eventDateActions = {
  newEventDate,
  saveEventDate,
  eventDateSaving,
  eventDateSaved,
  fetchEventDate,
  eventDateFetching,
  eventDateFetched,
  submitEventDate,
  rejectEventDate,
  publishEventDate,
  pauseEventDate,
  resumeEventDate,
  editEventDate,
  eventDateError,
  updateSoldOut,
  updateCancelled,
  updateEventDateStatus,
  disableEventDate,
  enableEventDate,
  fetchShortVenues,
  venuesFetching,
  venuesFetched
}
