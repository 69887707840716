import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { auditActions } from '../../audits/auditActions'
import { commonMapDispatchToProps } from '../../common/commonDispatchToProps'
import { contentActions } from '../../content/contentActions'
import { config } from '../../services/http'
import { campaignActions } from '../campaignActions'
import CampaignForm from '../components/CampaignForm'

const mapDispatchToProps = (dispatch, ownProps) => {
  return Object.assign(
    commonMapDispatchToProps(dispatch, ownProps),
    {
      load: () => {
        const { id, auditId } = ownProps.match.params
        if (id) {
          dispatch(campaignActions.fetchCampaign(id)).then(() => {
            dispatch(campaignActions.fetchCalendarContent())
          })
        } else {
          if (config.superCampaignEnabled) {
            dispatch(campaignActions.newCampaignV2())
          } else {
            dispatch(campaignActions.newCampaign())
          }
        }
        if (auditId) {
          dispatch(auditActions.fetchAudit(auditId))
        }
      },
      navigateToNewAlternateTitle: (campaign) => {
        let index = 0
        if (campaign.schedule && campaign.schedule.alternateTitles) {
          index = campaign.schedule.alternateTitles.length
        }
        dispatch(campaignActions.navigateToNewAlternateTitle(campaign.id, index))
      },
      navigateToCampaignAlternateTitle: (campaignProps) => {
        dispatch(campaignActions.navigateToCampaignAlternateTitle(campaignProps))
      },
      onViewAudit: (audit) => {
        const id = ownProps.match.params.id
        dispatch(auditActions.auditFetched(audit))
        dispatch(push(`/campaigns/${id}/audits/${audit.id}`))
      },
      submit: (campaign) => {
        dispatch(campaignActions.submitCampaign(campaign))
      },
      reject: (campaign) => {
        dispatch(campaignActions.rejectCampaign(campaign))
      },
      publish: (campaign) => {
        dispatch(campaignActions.publishCampaign(campaign))
      },
      pause: (campaign) => {
        dispatch(campaignActions.pauseCampaign(campaign))
      },
      resume: (campaign) => {
        dispatch(campaignActions.resumeCampaign(campaign))
      },
      edit: (campaign) => {
        dispatch(campaignActions.editCampaign(campaign))
      },
      copy: (campaign) => {
        dispatch(contentActions.navigateToCopyContent(campaign))
      },
      save: (campaign) => {
        return dispatch(campaignActions.saveCampaign(campaign))
      },
      navigateToSchedule: () => {
        dispatch(campaignActions.navigateToSchedule(ownProps.match.params.id))
      }
    }
  )
}

const mapStateToProps = (state) => {
  return {
    campaign: state.campaigns.campaign || {},
    status: state.campaigns.status,
    error: state.campaigns.error,
    audits: state.audits,
    calendarContent: state.campaigns.calendarContent
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignForm)
