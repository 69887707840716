import React from 'react'
import { Label } from 'react-bootstrap'
import classNames from 'classnames'

const StatusLabelCustom = ({
  className,
  status,
  text
}) => {
  if (!status) return null

  return (
    <Label className={classNames('status-label', className, `status-label-${status}`)}>
      {text}
    </Label>
  )
}

export default StatusLabelCustom
