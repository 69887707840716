import React, { Component } from 'react'

export default class Label extends Component {
  render() {
    const labelClass = this.props.className || 'control-label'
    const htmlFor = this.props.htmlFor

    return (
      <label id={this.props.id} htmlFor={htmlFor} className={labelClass}>
        {this.props.children}
      </label>
    )
  }
}
