import { connect } from 'react-redux'
import { contentActions } from '../contentActions'
import SelectRelatedContent from '../components/selectRelatedContent'

const mapDispatchToProps = (dispatch) => {
  return {
    load: () => {
      dispatch(contentActions.clearContents())
      dispatch(contentActions.setFilters('relatedContent', {
        type: [],
        state: [],
        search: ''
      }))
    },
    backToContentItem: (contentItem) => {
      dispatch(contentActions.navigateToContentItem(contentItem, 'relatedContent'))
    },
    updateContent: (contentItem) => {
      dispatch(contentActions.saveContent(contentItem)).then(() => {
        dispatch(contentActions.navigateToContentItem(contentItem, 'relatedContent'))
      })
    },
    toggleSelected: (contentItem) => {
      dispatch(contentActions.toggleSelected(contentItem))
    },
    updateFilters: (filters) => {
      dispatch(contentActions.setFilters('relatedContent', filters))
    },
    updateSearch: (query) => {
      dispatch(contentActions.setSearch('relatedContent', query))
    },
    selectPage: () => {
      dispatch(contentActions.fetchContents('relatedContent'))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    contents: state.contents.contentList,
    nextOffset: state.contents.nextOffset,
    selectedFilters: state.contents.selectedFilters.relatedContent,
    selectedItems: state.contents.selectedItems,
    currentContent: state.contents.currentContent,
    status: state.contents.status,
    error: state.contents.error,
    roles: state.common.roles
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectRelatedContent)
