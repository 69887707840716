import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Checkbox, DropdownButton, FormControl, Label, Well } from 'react-bootstrap'
import StatusLabel from '../../common/components/statusLabel'
import { contentStatuses, preSelectedFilterTypeCheckboxes } from '../../common/enums.js'

export default class ContentFilters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFilters : this.props.selectedFilters
    }
    this.state.selectedFilters.state = this.props.selectedFilters.state.length === 0 ? preSelectedFilterTypeCheckboxes : this.props.selectedFilters.state
  }

  componentDidMount() {
    const input = ReactDOM.findDOMNode(this.refs.searchInput)
    if (!input) return

    input.select()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState && nextProps.selectedFilters !== prevState.selectedFilters) {
      return {
        selectedFilters: nextProps.selectedFilters
      };
    }
    return null;
  }

  render() {
    const {
      contentTypes,
      typesHidden,
      searchHidden
    } = this.props

    const updateFilters = (filter, value) => {
      if (this.state.selectedFilters[filter].includes(value)) {
        var index = this.state.selectedFilters[filter].indexOf(value)
        this.state.selectedFilters[filter].splice(index, 1)
      } else {
        this.state.selectedFilters[filter].push(value)
      }
      this.props.updateFilters(this.state.selectedFilters)
    }

    return (
      <Well className='content-list--filters'>
        <strong>Filters:</strong>
        {!typesHidden ? (
          <DropdownButton bsStyle='link' title='Type' id='filter-type'>
            {Object.keys(contentTypes).map((type, index) => {
              return (
                <li key={`menu-filter-type-${index}`}
                    onClick={(e) => {
                      e.preventDefault()
                      updateFilters('type', type)
                    }}
                >
                  <a className='cursor-pointer'>
                    <Checkbox key={`filter-type-${index}`}
                              checked={this.state.selectedFilters.type.includes(type)}
                              onChange={() => ''}
                              onClick={(e) => {
                                e.stopPropagation()
                                updateFilters('type', type)
                              }}
                    >
                      {contentTypes[type]}
                    </Checkbox>
                  </a>
                </li>
              )
            })}
          </DropdownButton>
        ) : null}
        {this.state.selectedFilters.type.map((type, index) => (
          <Label key={`type-${index}`}
                 bsStyle='primary'
                 className='type-label content-list--filters--type-label'
          >
            {contentTypes[type]}
          </Label>
        ))}
        <DropdownButton bsStyle='link'
                        title='Status'
                        id='filter-state'
                        className='content-list--filters--dropdownbutton'
        >
          {Object.keys(contentStatuses).map((state, index) => {
            return (
              <li key={`menu-filter-state-${index}`}
                  id={`menu-filter-state-${index}`}
                  onClick={(e) => {
                    e.preventDefault()
                    updateFilters('state', state)
                  }}
              >
                <a className='cursor-pointer'>
                  <Checkbox key={`filter-state-${index}`}
                            id={`filter-state-${index}`}
                            checked={this.state.selectedFilters.state.includes(state)}
                            onChange={() => ''}
                            onClick={(e) => {
                              e.stopPropagation()
                              updateFilters('state', state)
                            }}
                  >
                    {contentStatuses[state]}
                  </Checkbox>
                </a>
              </li>
            )
          })
          }
        </DropdownButton>
        {this.state.selectedFilters.state.map((state, index) => (
          <StatusLabel key={`selected-state-${index}`}
                       status={state}
                       className='content-list--filters--state-label'
          />
        ))}
        {!searchHidden ? (
          <FormControl type='search'
                       id='filter-search'
                       ref='searchInput'
                       className='content-list--filters--search'
                       placeholder='Search'
                       value={this.state.selectedFilters.search}
                       onChange={(e) => this.props.updateSearch(e.target.value)}
          />
        ) : null}
      </Well>
    )
  }
}
