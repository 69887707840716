import React, { Component } from 'react'
import { Checkbox } from 'react-bootstrap'

class CheckBoxList extends Component {
  onChange(e, option) {
    var value = this.props.value
    if (value.includes(option.value)) {
      var index = value.indexOf(option.value)
      value.splice(index, 1)
    } else {
      value.push(option.value)
    }

    this.props.onChange(value)
  }

  render() {
    const disabledOptions = this.props.disabledOptions || []

    return (
      <div>
        {this.props.options.map((option, index) => {
          const disabled = this.props.disabled || disabledOptions.indexOf(option.value) > -1

          return (
            <Checkbox key={`check-${index}`} value={option.value} inline
                      checked={this.props.value.indexOf(option.value) > -1}
                      disabled={disabled}
                      onChange={(e) => this.onChange(e, option)}
            >
              {option.label}
            </Checkbox>
          )
        })}
      </div>
    )
  }
}

export default CheckBoxList
