import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'

import MsisdnBreadcrumbs from './msisdnBreadcrumbs'
import DeleteOrDiscardModal from './deleteOrDiscardModal'
import DataTable from '../../common/components/dataTable'
import Constants from '../../appConstants'
import AppError from '../../common/components/appError'
import Layout from './msisdnLayout'
import Spinner from '../../common/components/spinner'

export default class ManageMsisdn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saveOrDiscardModalVisible: false
    }

    props.fetchMsisdnsHandler()
  }

  onConfirmationDelete(msisdnToBeDeleted) {
    this.showDeleteOrDiscard()
    this.setState({ msisdnToBeDeleted: msisdnToBeDeleted })
  }

  showDeleteOrDiscard() {
    this.setState({ deleteOrDiscardModalVisible: true })
  }

  render() {
    const status = this.props.status || 'LOADING'

    if (status === 'LOADING') {
      return <Spinner />
    }

    let error = <div />
    if (this.props.error) {
      error = <AppError errorMessage={this.props.error} />
    }
    var headers = {
      msisdn: { name: 'MSISDN' },
      name: { name: 'Name', sortable: true },
      enabled: { name: 'Enabled' },
      actions: { name: 'Actions' }
    }
    let table = <div id='no-data'>No MSISDNs to display</div>
    if (this.props.msisdns && this.props.msisdns.length > 0) {
      const tableRows = this.props.msisdns.map(msisdnIterator => {
        return {
          msisdn: msisdnIterator.msisdn,
          name: msisdnIterator.name,
          enabled: <input type='checkbox' checked={msisdnIterator.enabled} disabled readOnly />,
          actions: (
            <span>
              <a id='delete-link' className='link' onClick={(e) => this.onConfirmationDelete(msisdnIterator.msisdn)} style={{ marginRight: '10px' }}>Delete</a>
              <a className='link' onClick={(e) => this.props.navigateToEdit(msisdnIterator.msisdn)}>Edit</a>
            </span>
          )
        }
      })
      table = <DataTable displayPageNo={Constants.DEFAULT_DISPLAY_PAGE_NO} rowsPerPage={Constants.DEFAULT_ROWS_PER_PAGE} headers={headers} data={tableRows} wrapperClassName='msisdns-table' />
    }
    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <MsisdnBreadcrumbs match={this.props.match} />
          </Col>
          <Col sm={8} lg={9}>
            {error}
            <Panel header='MSISDNs'>
              <div id='msisdn-listing-panel'>
                {table}
              </div>
            </Panel>
          </Col>
        </Row>
        <DeleteOrDiscardModal visible={this.state.deleteOrDiscardModalVisible}
                              onDelete={() => {
                                this.props.onDelete(this.state.msisdnToBeDeleted)
                                this.setState({ deleteOrDiscardModalVisible: false })
                              }}
                              onDiscard={() => {
                                this.setState({ deleteOrDiscardModalVisible: false })
                                this.props.navigateToManageMsisdn()
                              }}
        />
      </Layout>
    )
  }
}
