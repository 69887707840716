import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'

import Form from '../../common/form/form'
import FaqSectionBreadcrumbs from './faqSectionBreadcrumbs'
import FormField from '../../common/form/formField'
import Layout from './faqSectionLayout'
import { faqCategoryOptions, platformOptions } from '../../common/enums'
import _ from 'lodash'

export default class FaqSectionForm extends Component {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(this.props.faqSection)
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.faqSection, this.props.faqSection)) {
      this.model = _.cloneDeep(this.props.faqSection)
      this.forceUpdate()
    }
  }

  updateField(path, value) {
    return (e) => {
      if (path === 'title' && e.target.value != null) {
        const noSpecialCharacters = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '')
        this.model.faqSectionId = noSpecialCharacters.trim().replace(/\s+/g, '-').toLowerCase()
      }
      _.set(this.model, path, e ? e.target.value : value)
      this.setState({ dirty: true })
    }
  }

  _onChangeCheckboxValue(checkboxValue) {
    this.model['enabled'] = checkboxValue.target.checked
    this.setState({ dirty: true })
  }

  render() {
    const validationErrors = this.props.validationErrors || {}
    return (
      <Layout>
        <Row>
          <Col sm={4} lg={3}>
            <FaqSectionBreadcrumbs match={this.props.match}
                                   faqSectionId={this.props.faqSection.id}
            />
          </Col>
          <Col sm={8} lg={6}>
            {this.props.error || null}
            {this.props.flash || null}
            <Panel header='FAQ Section'>
              <Form nextButtonText='Save'
                    nextHandler={(e) => {
                      this.state.dirty = false
                      this.props.nextHandler(e, this.model)
                    }}
                    nextButtonDisabled={this.state ? !this.state.dirty : true}
                    backButtonText='Back'
                    backHandler={(e) => {
                      this.props.backHandler(e)
                    }}
              >
                <FormField label='Title'
                           id='title'
                           type='text'
                           value={this.model.title ? this.model.title : ''}
                           error={validationErrors.title}
                           maxLength='200'
                           mandatory
                           onChange={this.updateField('title')}
                />
                <FormField label='FAQ Section ID'
                           id='faqSectionId'
                           value={this.model.faqSectionId}
                           readOnly
                />
                <FormField label='Platforms'
                           id='platforms'
                           options={platformOptions}
                           value={this.model.platforms ? this.model.platforms || [] : []}
                           type='checkboxlist'
                           checked
                           disabled={false}
                           error={validationErrors.platforms}
                           onChange={(value) => this.updateField('platforms', value)()}
                           mandatory
                />
                <FormField label='Category'
                           id='category'
                           options={faqCategoryOptions}
                           value={this.model.category ? this.model.category || [] : []}
                           type='checkboxradiolist'
                           checked
                           disabled={false}
                           onChange={(value) => this.updateField('category', value)()}
                />
                <FormField label='Enabled'
                           id='enabled'
                           type='checkbox'
                           error={validationErrors.enabled}
                           value={this.model.enabled}
                           onChange={(e) => {
                             this._onChangeCheckboxValue(e)
                           }}
                />
              </Form>
            </Panel>
          </Col>
        </Row>
      </Layout>
    )
  }
}
