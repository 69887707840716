import { push } from 'connected-react-router'
import http, { config, FEATURE_FLAG_DETAILS_V1, CONFIGURABLE_CONTENTS_LIST_V1 } from '../services/http'
import { commonActions } from '../common/commonActions'

const navigateToDashboard = () => {
  return push('/')
}

const navigateToManageFeatureFlags = () => {
  return push('/featureFlags')
}

const navigateToEditFeatureFlag = (featureFlag) => {
  return push(`/featureFlags/${featureFlag.subType}/edit`)
}

const featureFlagFetching = () => ({
  type: constants.FEATURE_FLAGS_FETCHING
})

const featureFlagFetched = (featureFlags) => ({
  type: constants.FEATURE_FLAGS_FETCHED,
  featureFlags
})

const setClickedFeatureFlag = (featureFlag) => ({
  type: constants.CLICKED_FEATURE_FLAG,
  featureFlag
})

const featureFlagUpdated = (featureFlag) => ({
  type: constants.FEATURE_FLAG_UPDATED,
  featureFlag
})

const featureFlagUpdateError = (validationErrors) => ({
  type: constants.FEATURE_FLAG_UPDATE_ERROR,
  validationErrors
})

const featureFlagError = (error) => ({
  type: constants.FEATURE_FLAG_ERROR,
  error
})

const updateFeatureFlag = (featureFlagDetails) => {
  return (dispatch) => {
    let payload = "";
    if(featureFlagDetails.subType === "QUEUE_IT"){
      payload = _.pick(featureFlagDetails, [
        'title',
        'subtitle',
        'subType',
        'enabled',
        'viewOfferCTALabel',
        'ticketsQueueCTALabel'
      ])
    }else{
      payload = _.pick(featureFlagDetails, [
        'title',
        'subType',
        'enabled',
        'platform'
      ])
    }
    return http.put(dispatch, `${config.serverUrl}/configurableContents/featureFlag`, payload, {
      Accept: FEATURE_FLAG_DETAILS_V1
    }).then((featureFlag) => {
      dispatch(featureFlagUpdated(featureFlag))
      dispatch(setClickedFeatureFlag(featureFlag))
      dispatch(navigateToEditFeatureFlag(featureFlag))
      dispatch(commonActions.displayFlash('Record has been updated.'))
    }).catch(handleErrors(dispatch))
  }
}


const handleErrors = (dispatch) => {
  return (response) => {
    if (response.status === 400) {
      const json = JSON.parse(response.body)
      dispatch(featureFlagUpdateError(json.validationErrors))
    } else {
      if (response.body) {
        dispatch(featureFlagError(response.body))
      } else {
        dispatch(featureFlagError(response.statusText))
      }
    }
  }
}

const fetchFeatureFlags = () => {
  return (dispatch) => {
    dispatch(featureFlagFetching())
    return http.get(dispatch, `${config.serverUrl}/configurableContents?type=FEATURE_FLAG`, null, {
      Accept: CONFIGURABLE_CONTENTS_LIST_V1
    }).then((featureFlags) => {
      dispatch(featureFlagFetched(featureFlags))
      return featureFlags
    }).catch((err) => {
      dispatch(featureFlagError(`Unable to retrieve the list of Feature Flags: ${err.status} (${err.statusText})`))
    })
  }
}

export const constants = {
  FEATURE_FLAGS_FETCHING: 'FEATURE_FLAGS_FETCHING',
  FEATURE_FLAGS_FETCHED: 'FEATURE_FLAGS_FETCHED',
  FEATURE_FLAG_ERROR: 'FEATURE_FLAG_ERROR',
  CLICKED_FEATURE_FLAG: 'CLICKED_FEATURE_FLAG',
  FEATURE_FLAG_UPDATE_ERROR: 'FEATURE_FLAG_UPDATE_ERROR',
  FEATURE_FLAG_UPDATED: 'FEATURE_FLAG_UPDATED'
}

export const featureFlagActions = {
  navigateToDashboard,
  navigateToManageFeatureFlags,
  featureFlagFetching,
  featureFlagFetched,
  featureFlagError,
  fetchFeatureFlags,
  navigateToEditFeatureFlag,
  setClickedFeatureFlag,
  updateFeatureFlag
}
