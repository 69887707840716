import React from 'react'
import { Button, ButtonToolbar } from 'react-bootstrap'
import Spinner from '../../common/components/spinner'

import AppError from '../../common/components/appError'
import SelectContentTable from './_selectContentTable'
import ContentFilters from './contentFilters'
import InfiniteScroll from './infiniteScroll'

export default class SelectSeriesItems extends InfiniteScroll {
  constructor(props) {
    super(props)
    props.load(props.currentContent)
  }

  updateSeriesItems() {
    this.props.updateContents(this.props.currentContent, this.props.selectedItems)
  }

  render() {
    const status = this.props.status || 'LOADING'
    const disabledTypes = ['GROUP', 'THANK_YOU', 'SERIES', 'OFFER', 'BANNER', 'CAMPAIGN', 'PRIZE_DRAW', 'EVENT']
    const persistedIds = this.props.currentContent.contentItems ? this.props.currentContent.contentItems.ids : []
    const contents = this.props.contents.filter((contentItem) => {
      return contentItem.id !== this.props.currentContent.id
    })
    const contentTypes = {
      ARTICLE: 'Article'
    }

    return (
      <div>
        <div className='page-header'>
          <div className='page-header--heading'>
            <h1>Select series items</h1>
          </div>
          <div className='page-header--tools'>
            <ButtonToolbar>
              <Button id='cancel-series-items' onClick={() => this.props.backToContentItem(this.props.currentContent)}>Cancel</Button>
              <Button id='update-series-items' bsStyle='primary' onClick={() => this.updateSeriesItems()}>Update</Button>
            </ButtonToolbar>
          </div>
        </div>
        <ContentFilters selectedFilters={this.props.selectedFilters}
                        updateFilters={this.props.updateFilters}
                        updateSearch={this.props.updateSearch}
                        contentTypes={contentTypes}
                        searchVisible={false}
        />
        {this.props.error ? <AppError errorMessage={this.props.error} /> : null}
        <div className='content-list'>
          {status === 'LOADING' ? (
            <Spinner />
          ) : null}
          <SelectContentTable items={contents}
                              selectedItems={this.props.selectedItems}
                              persistedIds={persistedIds}
                              disableWasPublished
                              parentContentType={'series'}
                              disableRemovingPaused
                              disableRemovingInGroup
                              disabledTypes={disabledTypes}
                              disabledStatuses={['LIVE', 'PUBLISHED', 'CLOSED', 'PAUSED']}
                              onToggleSelected={this.props.toggleSelected}
                              selectedFilters={this.props.selectedFilters}
          />
        </div>
      </div>
    )
  }
}
