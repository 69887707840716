import React from 'react'
import { formatNumber } from '../utils'

const SearchMatchText = ({
  pagination,
  displayCount,
  selectedCount
}) => (
  <p>
    <span>Showing </span>
    {pagination.totalPages > 1 ? (
      <span>
        <b>{formatNumber(displayCount)}</b>
        {' of '}
        <b>{formatNumber(pagination.totalCount)}</b>
      </span>
    ) : (
      <b>{formatNumber(displayCount)}</b>
    )}
    <span> {displayCount === 1 ? 'item' : 'items'} matching this search</span>
    {selectedCount ? (
      <span>
        {', '}
        <b>{formatNumber(selectedCount)}</b>
        {' '}
        {selectedCount === 1 ? 'item' : 'items'}
        {' selected.'}
      </span>
    ) : '.'}
  </p>
)

export default SearchMatchText
