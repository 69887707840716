import _ from 'lodash'
import React, { Component } from 'react'
import { Button, Col, Glyphicon, Panel, Row, Table } from 'react-bootstrap'
import ContentTypelabel from '../../common/components/contentTypeLabel'
import { SortableBody, SortableRow } from '../../common/components/sortableTable'
import StatusLabel from '../../common/components/statusLabel'
import { redemptionTypeOptions } from '../../common/enums'
import FormField from '../../common/form/formField'
import { formatDateTime, possibleNumberToString } from '../../common/utils'

class ContentItemsPanel extends Component {
  render() {
    const contentItems = this.props.model.contentItems || {}
    const validationErrors = this.props.validationErrors
    const eventsOnly = _.every(this.props.contentItems, ['type', 'EVENT'])

    return (
      <div>
        <Panel header='Content' id='group-items'>
          <div>
            {this.props.contentItems.length > 0 ? (
              <Table striped hover>
                <thead>
                <tr>
                  <th width='1' />
                  <th>Type</th>
                  <th>Status</th>
                  <th>Title</th>
                  <th id='event-start-or-brand-header'>{eventsOnly ? 'Event start' : 'Brand'}</th>
                  <th>Visible from</th>
                  <th>Visible to</th>
                </tr>
                </thead>
                <SortableBody>
                  {this.props.contentItems.map((contentItem, index) => {
                    const rowProps = {
                      ids: this.props.ids,
                      item: contentItem,
                      onSort: this.props.onSort
                    }

                    return (
                      <SortableRow key={`content-item=${index}`} {...rowProps} onClick={() => this.props.viewContent(contentItem)}>
                        <td>
                          <Glyphicon glyph='menu-hamburger' />
                        </td>
                        <td><ContentTypelabel type={contentItem.type} /></td>
                        <td><StatusLabel status={contentItem.state} /></td>
                        <td>{contentItem.details.title || null}</td>
                        {eventsOnly ? (
                          <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.eventDateTime) : null}</td>
                        ) : (
                          <td>{contentItem.brand ? contentItem.brand.name : null}</td>
                        )}
                        <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayFromDateTime) : null}</td>
                        <td>{contentItem.schedule ? formatDateTime(contentItem.schedule.displayToDateTime) : null}</td>
                      </SortableRow>
                    )
                  })}
                </SortableBody>
              </Table>
            ) : (
              <p>There are no items yet.</p>
            )}
            <p>
              <Button id='modify-thankyou-items'
                      bsStyle='default'
                      onClick={this.props.onModify}
              >
                Add or remove items
              </Button>
            </p>
          </div>
        </Panel>
      </div>
    )
  }
}

export default ContentItemsPanel
