import _ from 'lodash'
import React from 'react'
import { Col, Form, Panel, Row } from 'react-bootstrap'
import AppError from '../../common/components/appError'
import AuditsPanel from '../../common/components/auditsPanel'
import CallToAction from '../../common/components/CallToAction'
import ContentFormBase from '../../common/components/contentFormBase'
import ContentTypeLabel from '../../common/components/contentTypeLabel'
import GroupLink from '../../common/components/groupLink'
import PreferenceCategories from '../../common/components/preferenceCategories'
import SaveOrDiscardModal from '../../common/components/saveOrDiscardModal'
import Spinner from '../../common/components/spinner'
import StatusLabel from '../../common/components/statusLabel'
import StatusLabelCustom from '../../common/components/statusLabelCustom'
import StatusToolbar from '../../common/components/statusToolbar'
import ThankYouLink from '../../common/components/thankYouLink'
import { accountTypeOptions, musicBannerTypeOptions } from '../../common/enums'
import FormField from '../../common/form/formField'
import ImageSelector from '../../common/media/ImageSelector'
import Breadcrumbs from './MusicBannerBreadcrumbs'
import SchedulePanel from './SchedulePanel'

export default class MusicBannerForm extends ContentFormBase {
  constructor(props) {
    super(props)
    this.model = _.cloneDeep(props.musicBanner || {})
    this.state = {
      saveOrDiscardModalVisible: false,
      dirty: false
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.musicBanner, this.props.musicBanner)) {
      this.model = _.cloneDeep(this.props.musicBanner || {})
      this.forceUpdate()
    }

    if (prevProps.match.path !== this.props.match.path) {
      this.props.load()
    }
  }

  render() {
    const activeTab = this.props.match.params.section || 'details'
    const sections = ['details', 'schedule', 'assets', 'callToAction']
    const requiredSections = ['details', 'schedule', 'assets', 'callToAction']
    const status = this.props.status || 'LOADING'
    const roles = this.props.roles
    const musicBanner = _.cloneDeep(this.props.musicBanner || {})
    const preferenceCategories = this.props.preferenceCategories

    const validationErrors = this.model.validationErrors || {}
    sections.forEach((section) => {
      musicBanner[section] = musicBanner[section] || {}
      validationErrors[section] = validationErrors[section] || {}
    })

    const formDisabled = this.isFormDisabled(musicBanner.state, status)
    const liveEdit = this.isLiveEdit(musicBanner)

    if (status === 'LOADING') {
      return <Spinner/>
    }

    return (
      <div>
        <Row>
          <Col sm={12} md={12} lg={12}>
            <div className="page-header">
              <div className="page-header--heading">
                <div className="page-header--top">
                  <ContentTypeLabel className="page-header--type" type={musicBanner.type}/>
                  <StatusLabel className="page-header--state-label" status={musicBanner.state}/>
                  {liveEdit ? (
                    <StatusLabelCustom className="page-header--state-label" status="live_edit" text="Editing published content"/>
                  ) : null}
                </div>
                <h1 className="content-heading">{musicBanner.details.title || 'New Music Banner'}</h1>
              </div>
              <div className="page-header--tools">
                <StatusToolbar status={musicBanner.state}
                               contentComplete={this.isComplete(musicBanner, validationErrors, sections, requiredSections)}
                               dirty={this.state.dirty}
                               isLiveEdit={liveEdit}
                               onSubmit={this.submit()}
                               onPublish={this.publish()}
                               onReject={this.reject()}
                               onSave={this.save()}
                               onPause={this.pause()}
                               onResume={this.resume()}
                               onEdit={this.edit()}
                />
              </div>
            </div>
            <Row>
              <Col sm={12} md={3} lg={3}>
                <Breadcrumbs musicBanner={musicBanner}
                             validationErrors={validationErrors}
                             activeTab={activeTab}
                             onNavigate={(tab) => this.onNavigate(tab)}
                />
              </Col>
              <Col sm={12} md={9} lg={9}>
                {this.props.error ? <AppError errorMessage={this.props.error}/> : null}
                <Form>
                  {activeTab === 'details' ? (
                    <Panel header="Basic Details" id="details">
                      {musicBanner.id ? (
                        <div className="form-group form-group-inline">
                          <label className="control-label">ID:</label>
                          {' '}
                          {musicBanner.id}
                        </div>
                      ) : null}
                      {musicBanner.shortId ? (
                        <div className="form-group form-group-inline" id="banner-shortId">
                          <label className="control-label">Short ID:</label> {musicBanner.shortId}
                        </div>
                      ) : null}
                      <GroupLink model={musicBanner}/>
                      <ThankYouLink model={musicBanner}/>
                      <FormField label="Apple Music Title" id="title" value={this.model.details ? this.model.details.title : ''}
                                 maxLength="40"
                                 onChange={this.updateField('details.title')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.title}
                      />
                      <FormField label="Apple Music Subtitle" id="subtitle" value={this.model.details ? this.model.details.subtitle : ''}
                                 maxLength="70"
                                 onChange={this.updateField('details.subtitle')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.subtitle}
                      />
                      <FormField label="Apple Artist ID" id="artistId" value={this.model.details ? this.model.details.artistId : ''}
                                 maxLength="60"
                                 onChange={this.updateField('details.artistId')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.artistId}
                      />
                      <FormField label="Internal Reference" id="internalReference" value={this.model.details ? this.model.details.internalReference : ''}
                                 maxLength="30"
                                 mandatory
                                 onChange={this.updateField('details.internalReference')}
                                 disabled={formDisabled}
                                 error={validationErrors.details.internalReference}
                      />
                      <FormField label="Sort categories" id="categories"
                                 value={this.model.details ? this.model.details.categories || [] : []}
                                 type="categorieslist"
                                 offerContentType
                                 roles={roles}
                                 onChange={(value) => this.updateField('details.categories', value)()}
                                 disabled={formDisabled}
                                 error={validationErrors.details.categories}
                      />
                      <PreferenceCategories id="preferenceCategories"
                                            value={_.get(this.model, 'details.preferenceCategories') || []}
                                            updateField={this.updateField.bind(this)}
                                            label="Preference Categories"
                                            options={preferenceCategories}
                                            disabled={formDisabled}
                      />
                      <FormField label="Account type" id="accountType"
                                 value={_.get(this.model, 'details.myO2Audience.accountType')}
                                 options={accountTypeOptions}
                                 type="checkboxradiolist" inline
                                 onChange={(value) => this.updateField('details.myO2Audience.accountType', value)()}
                                 disabled={formDisabled}
                                 error={_.get(validationErrors, 'details.myO2Audience.accountType')}
                      />
                      <FormField label="18+ only" id="ageRestricted"
                                 value={_.get(this.model, 'details.ageRestricted')}
                                 type="checkbox" inline
                                 onChange={(e) => this.updateField('details.ageRestricted', e.target.checked)()}
                                 disabled={formDisabled}
                      />
                    </Panel>
                  ) : null}
                  {activeTab === 'schedule' ? (
                    <SchedulePanel model={this.model}
                                   updateField={this.updateField.bind(this)}
                                   validationErrors={validationErrors}
                                   formDisabled={formDisabled}
                                   newTrigger={this.props.navigateToNewOurPicksTrigger}
                                   newAlternateTitle={this.props.navigateToNewAlternateTitle}
                                   navigateToEditOurPicksTrigger={this.props.navigateToEditOurPicksTrigger}
                                   saveMusicBanner={this.props.save}
                                   navigateToSchedule={this.props.navigateToSchedule}
                    />
                  ) : null}
                  {activeTab === 'assets' ? (
                    <Panel header="Assets" id="assets">
                      <ImageSelector label="Landscape Image"
                                     type="landscape"
                                     mediaTarget="LANDSCAPE"
                                     mandatory
                                     width={1125} height={750}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled}/>
                      <ImageSelector label="Square Image"
                                     type="square"
                                     mediaTarget="SQUARE"
                                     mandatory
                                     width={750} height={750}
                                     model={this.model}
                                     updateParentField={this.updateField.bind(this)}
                                     disabled={formDisabled}/>
                    </Panel>
                  ) : null}
                  {activeTab === 'audits' ? (
                    <AuditsPanel audits={this.props.audits}
                                 onMount={this.props.fetchAudits}
                                 onViewAudit={this.props.onViewAudit}
                                 match={this.props.match}
                                 selectPage={this.props.selectPage}
                    />
                  ) : null}
                  {activeTab === 'callToAction' ? (
                    <CallToAction model={this.model}
                                  disabled={formDisabled}
                                  typeOptions={musicBannerTypeOptions}
                                  calendarContent={this.props.calendarContent}
                                  onSelectCalendarContent={() => this.props.navigateToSelectCalendarItems(this.props.musicBanner)}
                                  validationErrors={validationErrors}
                                  updateParentField={this.updateField.bind(this)}
                                  viewContent={this.props.viewContent}
                                  onUrlTypeChange={this.onUrlTypeChange}/>
                  ) : null}
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
        <SaveOrDiscardModal visible={this.state.saveOrDiscardModalVisible}
                            onSave={() => this.saveAndBack()}
                            onDiscard={() => this.props.navigateToDashboard()}
                            onClose={() => this.setState({ saveOrDiscardModalVisible: false })}
        />
      </div>
    )
  }
}
