import { connect } from 'react-redux'

import { venueActions } from '../venueActions'
import VenueForm from '../components/venueForm'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch: dispatch,
    load: () => {
      const venueId = ownProps.match.params.id
      if (venueId) {
        dispatch(venueActions.fetchVenue(venueId))
      } else {
        dispatch(venueActions.newVenue())
      }
    },
    save: (model) => {
      return dispatch(venueActions.saveVenue(model))
    },
    geocode: (address1, city, postcode) => {
      return dispatch(venueActions.geocode(address1, city, postcode))
    },
    navigateToVenue: () => {
      dispatch(venueActions.navigateToVenues())
    }
  }
}

const mapStateToProps = state => {
  return {
    venue: state.venues.venue,
    validationErrors: state.venues.validationErrors,
    flash: state.common.flash,
    status: state.venues.venueStatus
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VenueForm)
