import classNames from 'classnames'
import React from 'react'
import { Label } from 'react-bootstrap'

import { contentStatuses } from '../enums'

const StatusLabel = ({
  status,
  className,
  disabledContent
}) => {
  if (!status) return null

  if (disabledContent === true) {
    status = 'DISABLED'
  }

  return (
    <Label className={classNames('status-label', className, `status-label-${status.toLowerCase()}`)}>
      {contentStatuses[status] || status}
    </Label>
  )
}

export default StatusLabel
