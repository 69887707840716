import React from 'react'

const jsdiff = require('diff')

var fnMap = {
  chars: jsdiff.diffChars,
  lines: jsdiff.diffLines,
  json: jsdiff.diffJson
}

const Diff = ({
  id,
  inputA,
  inputB,
  type
}) => {
  const diff = fnMap[type](inputA, inputB)

  return (
    <pre className={`diff-result diff-result-${type}`} id={id}>
      {diff.map(function(part, index) {
        var style = {
          backgroundColor: part.added ? '#e6ffed' : part.removed ? '#ffeef0' : undefined
        }
        var operator = part.added ? '+' : part.removed ? '-' : ' '
        var lines = part.value.replace(/\s+$/, '').split('\n')

        return (
          <span key={index} style={style}>
            {`${operator} ${lines.join('\n' + operator + ' ')}`}
          </span>
        )
      })}
    </pre>
  )
}

export default Diff
