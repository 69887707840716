import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const DeleteOrDiscardModal = ({
  visible,
  message,
  onDelete,
  onDiscard
}) => (
  <Modal show={visible}>
    <Modal.Body>
      <h4 className='modalBodyHeader'>{message}</h4>
    </Modal.Body>
    <Modal.Footer>
      <Button id='discard-changes-button' bsStyle='default' onClick={onDiscard}>Do Not Delete</Button>
      <Button id='delete-button' bsStyle='primary' onClick={onDelete}>Delete</Button>
    </Modal.Footer>
  </Modal>
)

export default DeleteOrDiscardModal
