import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { locationActions } from '../../locations/locationActions'
import { brandActions } from '../brandActions'
import BrandForm from '../components/BrandForm'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load() {
      const brandId = ownProps.match.params.brandId
      dispatch(brandActions.fetchBrand(brandId))
      dispatch(locationActions.fetchLocations(brandId))
    },
    navigateToSection(section) {
      const merchantId = ownProps.match.params.merchantId
      const brandId = ownProps.match.params.brandId
      dispatch(push(`/merchants/${merchantId}/brands/${brandId}/${section}`))
    },
    navigateToMerchant() {
      const merchantId = ownProps.match.params.merchantId
      dispatch(push(`/merchants/${merchantId}/brands`))
    },
    save(brand) {
      const merchantId = ownProps.match.params.merchantId
      return dispatch(brandActions.saveBrand(merchantId, brand))
    },
    uploadLocations(file) {
      const brandId = ownProps.match.params.brandId
      return dispatch(locationActions.uploadLocations(brandId, file))
    },
    selectPage(page) {
      const brandId = ownProps.match.params.brandId
      dispatch(locationActions.selectPage(page))
      dispatch(locationActions.fetchLocations(brandId))
    },
    navigateToLocation(locationId) {
      const merchantId = ownProps.match.params.merchantId
      const brandId = ownProps.match.params.brandId
      dispatch(push(`/merchants/${merchantId}/brands/${brandId}/locations/${locationId}`))
    }
  }
}

const mapStateToProps = (state) => {
  return {
    flash: state.common.flash,
    brand: state.brands.brand,
    locations: state.locations.locations,
    pagination: state.locations.pagination,
    status: state.brands.status,
    locationsStatus: state.locations.status
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandForm)
