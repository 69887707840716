import { connect } from 'react-redux'

import { accountActions } from '../accountActions'
import ChangePassword from '../components/changePassword'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    backHandler: e => {
      e.preventDefault()
      dispatch(accountActions.navigateToDashboard())
    },
    nextHandler: (e, password) => {
      e.preventDefault()
      dispatch(accountActions.updatePassword(ownProps.match.params.accountId, password))
    }
  }
}

const mapStateToProps = state => {
  return {
    validationErrors: state.accounts.validationErrors || {},
    error: state.accounts.error,
    roles: state.common.roles,
    profile: state.common.profile
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
