import { constants } from './commonActions'

const initialState = {
  roles: []
}
export default function common(state = initialState, action = {}) {
  switch (action.type) {
    case constants.DISPLAY_FLASH:
      return Object.assign({}, state, {
        flash: {
          message: action.message,
          style: action.style
        }
      })
    case '@@router/LOCATION_CHANGE':
    case constants.CLEAR_FLASH:
      return Object.assign({}, state, {
        flash: null
      })
    case constants.MISSING_CREDENTIALS:
      return Object.assign({}, state, {
        validationErrors: action.validationErrors,
        error: ''
      })
    case constants.LOGIN_ERROR:
      return Object.assign({}, state, {
        error: action.error,
        validationErrors: {}
      })
    case constants.CREDENTIALS_UPDATED:
      return Object.assign({}, state, {
        credentials: action.credentials
      })
    case constants.USER_LOGGED_IN:
      return Object.assign({}, state, {
        token: action.token,
        credentials: {},
        validationErrors: {},
        error: ''
      })
    case constants.USER_LOGGED_OUT:
      return Object.assign({}, state, {
        token: null,
        credentials: {},
        profile: null,
        roles: []
      })
    case constants.UPDATE_REFERER_ROUTE:
      return Object.assign({}, state, {
        refererRoute: action.refererRoute,
        credentials: {},
        validationErrors: {},
        error: ''
      })
    case constants.PROFILE_FETCHED:
      return Object.assign({}, state, {
        profile: action.profile
      })
    case constants.ROLES_FETCHED:
      return Object.assign({}, state, {
        roles: action.roles
      })
    case constants.PREFERENCE_CATEGORIES_FETCHED:
      return Object.assign({}, state, {
        preferenceCategories: action.preferenceCategories,
        status: 'SUCCESS'
      })
    case constants.AUTH_TOKEN_REFRESHED:
      return Object.assign({}, state, {
        token: action.token
      })
    default:
      return state
  }
}
