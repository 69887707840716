import { connect } from 'react-redux'

import { merchantActions } from '../merchantActions'
import Details from '../components/merchantDetails'

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch: dispatch,
    load: () => {
      const merchantId = ownProps.match.params.merchantId
      if (merchantId) {
        dispatch(merchantActions.fetchMerchant(merchantId))
      } else {
        dispatch(merchantActions.newMerchant())
      }
    },
    saveAndNavigate: (model) => (action) => {
      dispatch(merchantActions.updateMerchantBusinessDetails(ownProps.match.params.merchantId, model, action))
    },
    discardChanges: () => {
      dispatch(merchantActions.discardMerchantChanges())
    },
    createMerchant: (businessDetails) => {
      dispatch(merchantActions.createMerchant(businessDetails))
    },
    nextHandler: (e, model) => {
      e.preventDefault()
      var action = merchantActions.navigateToBrands(ownProps.match.params.merchantId)
      dispatch(merchantActions.updateMerchantBusinessDetails(ownProps.match.params.merchantId, model, action))
    }
  }
}

const mapStateToProps = state => {
  const merchant = state.merchants.merchant

  const businessDetails = {
    companyName: merchant ? merchant.companyName : ''
  }

  return {
    merchant: merchant,
    status: state.merchants.merchantStatus,
    businessDetails: businessDetails,
    validationErrors: state.merchants.validationErrors,
    flash: state.common.flash
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details)
