import React, { Component } from 'react'
import { Glyphicon, Nav, NavItem } from 'react-bootstrap'

import ValidationIndicator from '../../common/components/validationIndicator'
import { venueActions } from '../venueActions'

export default class Breadcrumbs extends Component {
  getValidationStatuses() {
    return {
      details: this.getVenueDetailsStatus()
    }
  }

  getVenueDetailsStatus() {
    const { venue, validationErrors } = this.props
    if (validationErrors && Object.keys(validationErrors).length !== 0) {
      return 'INVALID'
    } else if (venue && venue.name && venue.addressLine1 && venue.city && venue.postcode && venue.latitude && venue.longitude) {
      return 'VALID'
    } else {
      return 'NEW'
    }
  }

  render() {
    const statuses = this.getValidationStatuses()

    return (
      <Nav bsStyle='pills' stacked className='form-navigation'>
        <NavItem id='brand-breadcrumbs-back'
                 onClick={() => this.props.onNavigate(venueActions.navigateToVenues())}
        >
          <Glyphicon glyph='chevron-left' />
          {' '}
          Back to Venues
        </NavItem>
        <NavItem className='nav-item-divider' />
        <NavItem id='venue-breadcrumbs-details'
                 active
        >
          <ValidationIndicator status={statuses.details} />
          {' '}
          Details
        </NavItem>
        {
          // <NavItem
          //   disabled={!venue.id}
          //   id='venue-breadcrumbs-assets'
          // >
          //   Assets
          // </NavItem>
        }
      </Nav>
    )
  }
}
